import {Attachment} from "@/language/modules/component/support/v2/worker/history/attachment/zhTW"

export const History: {
    [key: string]: any
} = {

    attachment: Attachment,

    field: {
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        attachment: {
            subject: "附件"
        },
        creator: {
            subject: "建立人員",
            holder: "",
            stamp: {
                subject: "建立時間",
                holder: ""
            }
        },
        editor: {
            subject: "上一次編輯人",
            holder: "",
            stamp: {
                subject: "上一次編輯時間",
                holder: ""
            }
        }
    },
    help: {
        freeze: {
            subject: "無法編輯",
            text: `非目前狀態或者您不是建立人員<br/>不能進行編輯`
        },
        edit: {
            subject: "編輯",
        },
        release: {
            subject: "需要發佈App",
        },
        invalid: {
            subject: "作廢",
            text: "紀錄已作廢"
        }
    }
}
