export const Search: {
    [key: string]: any
} = {

    field: {
        belong: {
            subject: "所屬教會",
            holder: "請選擇所屬教會",
            help: "僅總教會"
        },
        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            help: "同時搜尋名字/郵件/會友編號"
        },
        on: {
            subject: "開始時間(起)",
            holder: "請選擇開始時間(起)",
            end: {
                subject: "開始時間(迄)",
                holder: "請選擇開始時間(迄)"
            }
        },
        off: {
            subject: "結束時間(起)",
            holder: "請選擇結束時間(起)",
            end: {
                subject: "結束時間(迄)",
                holder: "請選擇結束時間(迄)"
            }
        },
        submit: {
            subject: "建立時間(起)",
            holder: "請選擇建立時間(起)",
            end: {
                subject: "建立時間(迄)",
                holder: "請選擇建立時間(迄)"
            }
        }
    },
    help: {
        begin: {
            subject: "(≥)指定時間"
        },
        end: {
            subject: "(≤)指定時間"
        }
    }

}
