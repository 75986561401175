import {v2} from "@/language/modules/component/lightbox/v2/zhCN"

export default {
    v2: v2,
    zoom: {
        in: "放大",
        out: "缩小"
    },
    resize: "还原缩放",
    download: "下载",
    rotate: {
        left: "逆时针旋转",
        right: "顺时针旋转"
    }
}
