/**
 * 鉤子
 * 請求
 * ❗️❗️ 危險 ❗️❗️
 * 在 hook 裡面的, 所有的 t(), 都沒問題, 可以用的
 * 但是檢查器會一直抓錯, 都加上 @ts-ignore
 * 沒加build的時候還會出現, 加了就全都沒這問題
 * error TS2589: Type instantiation is excessively deep and possibly infinite.
 * @author J
 * @since 2024-04-30 07:34:25
 */

//注意先用v1的
import i18n from "@/language";
import {ReturnCodeEnum} from "@/v2/enumerate/return/code";
import {FromEditStatistics} from "@/v2/api/interface";
import {useAuthorizationSession} from "@/v2/hooks/authorization/session";
import {useSweetAlert} from "@/v2/hooks/sweetalert";
import {useSetting} from "@/v2/hooks/setting";

/**
 * 請求類型
 */
export enum RequestEnum {

    /**
     * 擷取
     * 僅ajax取回資料
     */
    FETCH,

    /**
     * 注入
     */
    GET,

    /**
     * 選項
     */
    OPTION,

    /**
     * 統計
     */
    STATISTICS,

    /**
     * 創建
     */
    CREATE,

    /**
     * 更新
     */
    UPDATE,

    /**
     * 提交 (不提問)
     */
    SUBMIT,

    /**
     * 教會 - 三項 - 標題
     */
    CAPTION,

    /**
     * 授權 - 權杖
     */
    SESSION

}

export const useRequest = (
    //文字前置
    configure: {
        i18n?: string,
        submit?: string
    },
    //調整內容
    content: {
        field?: any
        option?: any
        statistics?: any
    },
    api: {
        //擷取 (僅ajax取回資料)
        fetch?: (
            ...param: any[]
        ) => Promise<any>,
        //注入
        get?: (
            ...param: any[]
        ) => Promise<any>,
        //選項
        option?: (
            ...param: any[]
        ) => Promise<any>,
        //統計
        statistics?: (
            ...param: any[]
        ) => Promise<any>,
        //創建
        create?: (
            ...param: any[]
        ) => Promise<any>,
        //更新
        update?: (
            ...param: any[]
        ) => Promise<any>,
        //提交 - 不整合 - SweetAlert
        submit?: (
            ...param: any[]
        ) => Promise<any>,
        //教會 - 三項 - 標題
        caption?: (
            ...param: any[]
        ) => Promise<any>,
        //授權 - 權杖
        session?: (
            ...param: any[]
        ) => Promise<any>
    },
    //回調
    completion?: {
        //統一回調 - 開始前
        before?: (e: RequestEnum) => void,
        //統一回調 - 完成後
        after?: (e: RequestEnum) => void,
        //失敗
        failure?: (e: RequestEnum, respond?: any) => void,
        //不論成功或失敗
        final?: (e: RequestEnum, respond?: any) => void,
        //擷取 (僅ajax取回資料)
        fetch?: (e: any) => void,
        //注入
        get?: (e: any) => void,
        //選項
        option?: (e: any) => void,
        //統計
        statistics?: (e: any) => void,
        //創建
        create?: (e: any) => void,
        //更新
        update?: (e: any) => void,
        //提交 - 不整合 - SweetAlert
        submit?: (e: any) => void,
        //教會 - 三項 - 標題
        caption?: (e: any) => void,
        //授權 - 權杖
        session?: (e: any) => void
    }
) => {

    //導入i18n
    const {
        // @ts-ignore
        t
    } = i18n.global

    const {
        showHandler
    } = useSweetAlert()

    const {
        setData
    } = useAuthorizationSession()

    const {
        setMenuCaption
    } = useSetting()

    /**
     * 統一呼叫判斷
     * @param type 回調類型
     * @param respond 返回資料
     */
    const onCompletion = (
        type: RequestEnum,
        respond?: any
    ) => {

        if (type === RequestEnum.FETCH && completion?.fetch) completion?.fetch(respond)
        else if (type === RequestEnum.GET && completion?.get) completion?.get(respond)
        else if (type === RequestEnum.OPTION && completion?.option) completion?.option(respond)
        else if (type === RequestEnum.STATISTICS && completion?.statistics) completion?.statistics(respond)
        else if (type === RequestEnum.CREATE && completion?.create) completion?.create(respond)
        else if (type === RequestEnum.UPDATE && completion?.update) completion?.update(respond)
        else if (type === RequestEnum.SUBMIT && completion?.submit) completion?.submit(respond)
        else if (type === RequestEnum.CAPTION && completion?.caption) completion?.caption(respond)
        else if (type === RequestEnum.SESSION && completion?.session) completion?.session(respond)

        //執行後指令
        if (completion?.after) completion?.after(
            type
        )

        //不論成功或失敗
        if (completion?.final) completion?.final(
            type,
            respond
        )

    }

    /**
     * 擷取
     */
    const onFetch = async (
        ...param: any[]
    ) => {

        //執行前指令
        if (completion?.before) completion?.before(
            RequestEnum.GET
        )

        //執行傳輸
        const {
            data
        } = await api.fetch!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.FETCH,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.FETCH,
                data
            )

            return

        }

        //完成回調
        onCompletion(
            RequestEnum.FETCH,
            data.data
        )

    }

    /**
     * 注入
     */
    const onGet = async (
        ...param: any[]
    ): Promise<void> => {

        //執行前
        if (completion?.before) completion?.before(
            RequestEnum.GET
        )

        //執行傳輸
        const {
            data
        } = await api.get!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK || data?.data === null) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.GET,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.GET,
                data
            )

            return

        }

        //批量注入field
        if (content.field) onInject(
            content.field,
            data.data
        )

        //完成回調
        onCompletion(
            RequestEnum.GET,
            data.data
        )

    }

    /**
     * 選項
     */
    const onOption = async (
        ...param: any[]
    ): Promise<void> => {

        //執行前
        if (completion?.before) completion?.before(
            RequestEnum.OPTION
        )

        //執行傳輸
        const {
            data
        } = await api.option!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK || !(data?.data)) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.OPTION,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.OPTION,
                data
            )

            return

        }

        //批量投入option
        if (content.option) onInject(
            content.option,
            data.data
        )

        //完成回調
        onCompletion(
            RequestEnum.OPTION,
            data.data
        )

    }

    /**
     * 統計
     */
    const onStatistics = async (
        ...param: any[]
    ): Promise<void> => {

        //執行前
        if (completion?.before) completion?.before(
            RequestEnum.STATISTICS
        )

        //執行傳輸
        const {
            data
        } = await api.statistics!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK || !(data?.data)) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.STATISTICS,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.STATISTICS,
                data
            )

            return

        }

        //投入 - 統計 - 數字
        if (content.statistics) Object.keys(data.data).forEach((i: string) => {
            const r: FromEditStatistics | undefined = content.statistics?.find((j: any) => j.key === i)
            if (r && typeof data.data[i] === 'number') r.quantity = data.data[i]
        })

        //完成回調
        onCompletion(
            RequestEnum.STATISTICS,
            data.data
        )

    }

    /**
     * 創建
     * 如果要指定提示詞, 請放最後一個傳入 - 參數
     */
    const onCreate = async (
        ...param: any[]
    ): Promise<void> => {

        const handler: {
            subject?: string,
            text?: string
        } = (param.length > 1 && param[param.length - 1].subject) ? param.pop() : {}

        showHandler({
            //@ts-ignore
            subject: handler?.subject ?? t(`${configure.i18n}.handler.store.subject`),
            //@ts-ignore
            text: handler?.text ?? t(`${configure.i18n}.handler.store.text`),
            completion: {
                //呼叫儲存回調
                success: async () => {

                    //執行前指令
                    if (completion?.before) completion?.before(
                        RequestEnum.CREATE
                    )

                    //執行傳輸
                    const {
                        data
                    } = await api.create!(
                        param,
                        (e: any) => onCompletion(
                            RequestEnum.CREATE,
                            e.data
                        )
                    )

                    //返回錯誤不往下進行
                    if (data?.code !== ReturnCodeEnum.OK) {

                        //失敗
                        if (completion?.failure) completion?.failure(
                            RequestEnum.SUBMIT,
                            data
                        )

                        //不論成功或失敗
                        if (completion?.final) completion?.final(
                            RequestEnum.SUBMIT,
                            data
                        )

                    }

                }
            }
        })

    }

    /**
     * 更新
     * 如果要指定提示詞, 請放最後一個傳入 - 參數
     */
    const onUpdate = async (
        ...param: any[]
    ): Promise<void> => {

        const handler: {
            subject?: string,
            text?: string
        } = (param.length > 1 && param[param.length - 1].subject) ? param.pop() : {}

        showHandler({
            //@ts-ignore
            subject: handler.subject ?? t(`${configure.i18n}.handler.update.subject`),
            //@ts-ignore
            text: handler.text ?? t(`${configure.i18n}.handler.update.text`),
            completion: {
                //呼叫儲存回調
                success: async () => {

                    //執行前指令
                    if (completion?.before) completion?.before(
                        RequestEnum.UPDATE
                    )

                    //執行傳輸
                    const {
                        data
                    } = await api.update!(
                        param,
                        (e: any) => onCompletion(
                            RequestEnum.UPDATE,
                            e.data
                        )
                    )

                    if (data?.code !== ReturnCodeEnum.OK ) {

                        //失敗
                        if (completion?.failure) completion?.failure(
                            RequestEnum.GET,
                            data
                        )

                        //不論成功或失敗
                        if (completion?.final) completion?.final(
                            RequestEnum.GET,
                            data
                        )

                    }

                }
            }
        })

    }

    /**
     * 提交 - 不整合 - SweetAlert
     */
    const onSubmit = async (
        ...param: any[]
    ): Promise<void> => {

        //執行前
        if (completion?.before) completion?.before(
            RequestEnum.SUBMIT
        )

        //執行傳輸
        const {
            data
        } = await api.submit!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.SUBMIT,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.SUBMIT,
                data
            )

            return

        }

        //完成回調
        onCompletion(
            RequestEnum.SUBMIT,
            data.data
        )

    }

    /**
     * 教會 - 三項 - 標題
     */
    const onCaption = async (
        ...param: any[]
    ) => {

        //執行前指令
        if (completion?.before) completion?.before(
            RequestEnum.CAPTION
        )

        //執行傳輸
        const {
            data
        } = await api.caption!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK || !(data?.data)) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.CAPTION,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.CAPTION,
                data
            )

            return

        }

        //注入 - 教會 - 三項 - 標題
        setMenuCaption({
            my: data.data.my ?? t(`caption.v2.my`),
            soul: data.data.soul ?? t(`caption.v2.soul`),
            daily: data.data.daily ?? t(`caption.v2.daily`),
        })

        //完成回調
        onCompletion(
            RequestEnum.CAPTION,
            data.data
        )

    }

    /**
     * 授權 - 權杖
     */
    const onSession = async (
        ...param: any[]
    ) => {

        //執行前指令
        if (completion?.before) completion?.before(
            RequestEnum.SESSION
        )

        //執行傳輸
        const {
            data
        } = await api.session!(param)

        //返回錯誤不往下進行
        if (data?.code !== ReturnCodeEnum.OK || !(data?.data)) {

            //失敗
            if (completion?.failure) completion?.failure(
                RequestEnum.SESSION,
                data
            )

            //不論成功或失敗
            if (completion?.final) completion?.final(
                RequestEnum.SESSION,
                data
            )

            return

        }

        //注入用戶資訊
        setData(data.data)

        //完成回調
        onCompletion(
            RequestEnum.SESSION,
            data.data
        )

    }

    /**
     * 批量注入field
     * @param source 來源
     * @param data 目的
     */
    const onInject = (
        source: any,
        data: any
    ) => {

        // 陣列先清空, 用 source = [] 無法清空, 必須要用 source.length = 0
        if (Array.isArray(source) && Array.isArray(data)) source.length = 0
        Object.keys(data).forEach((i: string) => source[i] = data[i])

    }

    return {
        onGet,
        onFetch,
        onCreate,
        onOption,
        onStatistics,
        onUpdate,
        onSubmit,
        onCaption,
        onSession
    }

}