export default {
    panel: {
        year: "选择年",
        month: "选择月"
    },
    prev: {
        year: "往前 1 年",
        month: "往前 1 个月",
        ten: {
            year: "往前 10 年"
        }
    },
    next: {
        year: "往后 1 年",
        month: "往后 1 个月",
        ten: {
            year: "往后 10 年"
        }
    },
    timer: {
        subject: "点击使用滑杆模式",
        increase: {
            hour: "增加 1 小时",
            minute: "增加 %s 分钟"
        },
        decrease: {
            hour: "减少 1 小时",
            minute: "减少 %s 分钟"
        }
    },
    error: {
        1001: {
            "subject": "小于最早时间限制",
            "text": '最早时间限制为 <b class="ft-20 text-danger">%s</b><br />请您重新选择',
            "description": ""
        },
        1002: {
            "subject": "大于最晚时间限制",
            "text": '最晚时间限制为 <b class="ft-20 text-danger">%s</b><br />请您重新选择',
            "description": ""
        }
    },
    day: [
        "日",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
    ],
    month: [
        "01月",
        "02月",
        "03月",
        "04月",
        "05月",
        "06月",
        "07月",
        "08月",
        "09月",
        "10月",
        "11月",
        "12月",
    ]
}
