<template>
  <div class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static">
    <!-- BEGIN: Content-->
    <div class="app-content content m-b-0">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <a class="brand-logo">
                <img class="logo-img"
                     alt="image"
                     :src="getLogo">
              </a>
              <div class="zx d-flex d-flex-column justify-content-center align-items-center auth-bg px-2">
                <div class="col-12 mx-auto">
                  <div class="d-flex d-flex-column">
                    <h4 class="text-center ft-24 card-title fw-bold mb-1 text-dark"
                        v-html="$t(`${configure.prefix}.subject`)" />
                    <small class="m-b-5 ft-14 text-secondary text-center"
                           v-html="$t(`${configure.prefix}.text`)" />
                    <small class="ft-14 text-secondary text-center"
                           v-html="$t(`${configure.prefix}.description`)" />
                  </div>
                  <!-- 掃碼登入 -->
                  <j-qrcode-login
                      is-mapping
                      :margin-top="0"
                      :margin-bottom="0"
                      :data="active.data" />
                </div>
                <div class="hh d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <el-tooltip
                        v-if="isDarkTheme"
                        class="box-item"
                        :content="$t(`component.theme.dark`)"
                        placement="top-start">
                      <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                class="d-inline-block cursor hand ft-20"
                                type="sun" />
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                        class="box-item"
                        :content="$t(`component.theme.light`)"
                        placement="top-start">
                      <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                class="text-secondary d-inline-block cursor hand ft-20"
                                type="moon" />
                    </el-tooltip>
                    <el-dropdown popper-class="light-language"
                                 placement="top-start">
                      <icon-sax class="ur text-secondary d-inline-block cursor hand m-l-10 ft-20"
                                type="global" />
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                              @click="setRegion(j.id)"
                              v-for="j in getAllRegion">
                            <a class="d-flex align-items-center">
                              <country-flag :iso="j.id" />
                              <span :class="getCurrentRegion.id === j.id ? `text-dark` : `text-muted`"
                                    class="m-l-10 ft-12 selected-language"
                                    v-html="j.value" />
                            </a>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                  <el-tooltip
                      class="box-item"
                      :content="$t(`nav.logout`)"
                      placement="top-start">
                    <icon-sax @click="logoutIntent"
                              class="text-secondary d-inline-block cursor hand ft-22"
                              type="logout" />
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"
import JQrcodeLogin from "@/component/qrocde/Login.vue"
import CountryFlag from "@/component/CountryFlag.vue"

import {computed, onMounted, reactive} from "vue"
import {useUser} from "@/hook/useUser"
import {useRoute, useRouter} from "vue-router"
import {createLogin} from "@/api/module/session"
import {Session} from "@/api/interface/session"
import {Configure} from "@/api/interface/configure"
import {useIntent} from "@/hook/useIntent"
import {useRegion} from "@/hook/useRegion"
import {useSetting} from "@/hook/useSetting"
import {EnumTheme} from "@/enum/theme";

const {
  getUser
} = useUser()

const {
  isDarkTheme,
  setTheme
} = useSetting()

const {
  setRegion,
  getAllRegion,
  getCurrentRegion
} = useRegion()

const {
  logoutIntent
} = useIntent()

const route = useRoute()
const router = useRouter()

/**
 * 參數 - 頁面 - 配置
 */
const configure = reactive<Configure.Page.Base>({
  //i18n代碼前置
  prefix: `page.mapping`,
})

/**
 * 參數 - 本地
 */
const active = reactive<{
  data: Session.Login
}>({
  data: {
    organization: {
      seq: null,
      uuid: null,
      name: null
    },
    available: true,
    name: null,
    uuid: null,
    avatarUrl: null
  }
})

//獲取Logo
const getLogo = computed((): string => {
  if (active.data.organization.seq) return `/img/organization/${active.data.organization.seq}/logo.png`
  return "/img/organization/1/logo.png"
})

//存取API
const fetch = async () => {

  const {
    data
  } = await createLogin(
      route.params.id as string
  )

  //返回錯誤不往下進行
  if (data?.data === undefined) return false

  active.data.organization = data.data.organization
  active.data.available = data.data.available
  active.data.name = data.data.name
  active.data.uuid = data.data.uuid
  active.data.avatarUrl = data.data.avatarUrl

}

onMounted(()=>{
  //如果已經對應好了, 返回到控制面板
  if (getUser.value.mapping) router.replace({
    name: `AdminDashboard`
  })
  //獲取畫面資訊
  else fetch()
})

</script>

<style scoped lang="sass">
@import '@/style/core/authentication.css'

.zx
  position: relative
  .hh
    text-align: right
    position: absolute
    padding: 1.5rem
    bottom: 0
    left: 0
    right: 0

.auth-inner
  overflow: hidden
  position: relative
  .kp
    width: calc(100% - 33.33333%)
  .xp
    z-index: 1
    position: absolute
    top: 0
    bottom: 0
    right: 0
    padding: 2.5rem !important
    width: 33.33333%
  .ff
    padding: 1.5rem 2.5rem !important
    position: absolute
    left: 0
    right: 0
    bottom: 0
    text-align: right

@media (max-width: 1500px)
  .auth-inner
    .kp
      width: calc(100% - 40%)
    .xp
      width: 40%

@media (max-width: 1200px)
  .auth-inner
    .kp
      width: calc(100% - 45%)
    .xp
      width: 45%

@media (max-width: 991px)
  .auth-inner
    .kp
      width: 0
    .xp
      width: 100%

.ur
  position: relative
  top: 1px

.qrcode
  height: 377.85px

.brand-logo
  img.logo-img
    width: 48px
    height: 48px
    border-radius: 0.375rem

.auth-wrapper
  .auth-bg
    background-color: #FFF
  &.auth-cover
    .brand-logo
      z-index: 4
      left: 1rem !important
.d-none
  position: relative
  .x1, .x2
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
  .x1
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    z-index: 1
  .x2
    background-color: rgba(255, 255, 255, 0.55)
    z-index: 2


.dark-layout
  .auth-wrapper
    .auth-bg
      background-color: #343d55 !important
  .d-none
    .x2
      background-color: rgba(255, 255, 255, 0.25)
</style>
