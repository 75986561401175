/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 管理員 - 個人設定 - 認證方式
 */
export enum EnumUserSettingAuthorized {

    /**
     * 掃碼
     */
    QRCODE = 1,

    /**
     * 帳號密碼
     */
    PASSWD = 2

}

/**
 * 管理員 - 個人設定 - 開啟列表模式
 */
export enum EnumUserSettingOpen {

    /**
     * 原始模式
     */
    SELF = "_self",

    /**
     * 新頁籤開啟
     */
    NEW_TAB = "_blank"

}
