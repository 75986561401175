/**
 * 我的教會文章管理
 */
export default {
    index: "我的教會文章管理",
    create: "我的教會文章新增",
    edit: "我的教會文章內容",
    copy: "文章複製",
    mindcatcher: "文章導入",
    permission: "我的教會文章管理",
    comment: {
        index: "留言紀錄",
        permission: "我的教會文章管理 - 留言紀錄"
    },
    notification: {
        index: "推播紀錄",
        create: "推播新增",
        edit: "推播內容",
        permission: "我的教會文章管理 - 推播紀錄"
    },
}
