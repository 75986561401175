import dashboard from "@/language/modules/page/shepherd/dashboard/zhCN";
import friend from "@/language/modules/page/shepherd/friend/zhCN";
import client from "@/language/modules/page/shepherd/client/zhCN";
import attend from "@/language/modules/page/shepherd/attend/zhCN";
import team from "@/language/modules/page/shepherd/team/zhCN";

export default {

    /**
     * 控制面板
     */
    dashboard: dashboard,

    /**
     * 新朋友
     */
    friend: friend,

    /**
     * 会友
     */
    client: client,

    /**
     * 聚会
     */
    attend: attend,

    /**
     * 族群
     */
    team: team

}
