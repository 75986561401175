export const Attachment: {
    [key: string]: any
} = {
    editor: {
        subject: "附件內容",
        field: {
            mime: {
                subject: "類型",
            },
            subject: {
                subject: "標題",
                holder: ""
            },
            description: {
                subject: "描述",
                holder: ""
            },
            url: {
                subject: "網址",
                holder: ""
            }
        }
    }
}
