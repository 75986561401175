export default {
    category: {
        index: "分類管理",
        edit: "分類內容",
        create: "分類新增"
    },
    index : {
        index: "文章管理",
        edit: "文章內容",
        create: "文章新增"
    }
}
