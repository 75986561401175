import statistics from "@/language/modules/page/admin/dashboard/statistics/zhTW";

/**
 * 我的教會
 */
export default {
    index: "控制面板",
    permission: "控制面板",
    statistics: statistics
}
