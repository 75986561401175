import {Progress} from "@/language/modules/component/support/v2/worker/progress/zhCN"
import {Account} from "@/language/modules/component/support/v2/worker/account/zhCN"
import {History} from "@/language/modules/component/support/v2/worker/history/zhCN"
import {Attachment} from "@/language/modules/component/support/v2/worker/attachment/zhCN"
import {Status} from "@/language/modules/component/support/v2/worker/status/zhCN"

export const Worker: {
    [key: string]: any
} = {

    /**
     * 进度
     */
    progress: Progress,

    /**
     * 帐户
     */
    account: Account,

    /**
     * 附件
     */
    attachment: Attachment,

    /**
     * 历史纪录
     */
    history: History,

    /**
     * 状态
     */
    status: Status

}
