export default {
    clone: {
        "subject": "资料复制成功",
        "text": "右键贴上即可以直接使用",
        "description": ""
    },
    download: {
        "subject": "文档下载成功",
        "text": "",
        "description": ""
    }
}
