export const Code: {
    [key: string]: any
} = {

    200: {
        subject: "操作成功",
        text: "",
        description: ""
    },
    201: {
        subject: "服务降级保护中",
        text: "服务目前处于降级保护的状态, 请稍后再试",
        description: ""
    },
    202: {
        subject: "服务流控管制中",
        text: "服务目前处于流控管制的状态, 请稍后再试",
        description: ""
    },
    203: {
        subject: "服务热点限制中",
        text: "服务目前处于热点限制的状态, 请稍后再试",
        description: ""
    },
    204: {
        subject: "系统规则不满足要求",
        text: "请稍后再试",
        description: ""
    },
    205: {
        subject: "授权规则不通过",
        text: "请稍后再试",
        description: ""
    },
    401: {
        subject: "连线权杖逾时或不正确",
        text: "Oops! 🤕🤕 请您重新登入系统",
        description: "请稍后再试或请联络管理人员"
    },
    402: {
        subject: "公司授权已经到期",
        text: "请向我们的销售人员联络, 谢谢",
        description: ""
    },
    403: {
        subject: "无存取权限",
        text: "Oops! 😳😳 您可能没有存取的权限",
        description: "请稍后再试或请联络管理人员"
    },
    404: {
        subject: "页面不存在",
        text: "Oops! 😱😱 您访问的页面不存在或暂时不可用",
        description: "请稍后再试或请联络管理人员"
    },
    //METHOD不允许
    405: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 无法正确连结至伺服器",
        description: "请稍后再试或请联络管理人员"
    },
    413: {
        subject: "这出了点小问题",
        text: "Oops! 🙁🙁 您上传的档案容量超出限制",
        description: "请稍后再试或请联络管理人员"
    },
    500: {
        subject: "这出了点小问题",
        text: "Oops! 🥺🥺 伺服器好像罢工了",
        description: "请稍后再试或请联络管理人员"
    },
    //CORS错误
    502: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 无法正确连结至伺服器",
        description: "请稍后再试或请联络管理人员"
    },
    //服务不存在
    503: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 存取的服务存在异常",
        description: "请稍后再试或请联络管理人员"
    },
    //连线超时
    504: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 连结到伺服器超出预期时间",
        description: "请稍后再试或请联络管理人员"
    },
    //Feign异常
    700: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 系统模组连接异常",
        description: "请稍后再试或请联络管理人员"
    },
    //单档案上传大小限制
    901: {
        subject: "上传档案发生错误",
        text: `单个档案超过 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    //合计档案上传大小限制
    902: {
        subject: "上传档案发生错误",
        text: `档案合计超过 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    903: {
        subject: "产生预览图失败",
        text: "上传档案中包含不支援的图片编码",
        description: ""
    },
    904: {
        subject: "分享连结出现错误",
        text: "请确认您的分享连结是否正确",
        description: ""
    },
    998: {
        subject: "二级验证失败",
        text: "请确认您的登入密码是否正确",
        description: "请确认您的登入密码是否正确"
    },
    999: {
        subject: "这出了点小问题",
        text: "Oops! 😲😲 发生未知错误",
        description: "请稍后再试或请联络管理人员"
    }

}