<script setup lang="ts">

import JQrcodeRect from "@/v2/components/qrcode/rect/index.vue";
import JQrcodePasswd from "@/v2/components/qrcode/passwd/index.vue";
import JQrcodeDescription from "@/v2/components/qrcode/description/index.vue";
import {computed, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {QrcodeEnum, QrcodeFactoryCommandEnum, QrcodeRequestEnum, QrcodeStatusEnum} from "@/v2/enumerate/qrcode";
import {AuthorizationLogin} from "@/v2/api/interface/authorization";
import {Basic} from "@/v2/api/interface";
import {useRouter} from "vue-router";
import {useAuthorizationSession} from "@/v2/hooks/authorization/session";
import {useAuthorization} from "@/v2/hooks/authorization";
import {useSetting} from "@/v2/hooks/setting";
import {SettingLoginEnum} from "@/v2/enumerate/setting";
import {sprintf} from "sprintf-js";
import {useUtils} from "@/v2/hooks/utils";
import {useWebsocket} from "@/v2/hooks/websocket";

const router = useRouter()

const component = ref()

const {
  getUuid
} = useUtils()

const {
  setConnect,
  setDisconnect
} = useWebsocket()

const {
  setAuthorized: setUserAuthorized
} = useSetting()

const {
  isLogin: isUserLogin,
  getToken: getUserToken,
  setToken: setUserToken
} = useAuthorization()

const {
  getUuid: getUserUuid,
  getChurch: getUserChurch,
  setMapping: setUserMapping
} = useAuthorizationSession()

/**
 * 轉入 - env - 參數
 */
const meta = {
  //網址
  url: {
    //websocket
    websocket: (import.meta.env.VITE_SOCKET_URL as string).replaceAll(/\/$/gi, ""),
    //二維碼
    qrcode: (import.meta.env.VITE_BASE_URL as string).replaceAll(/\/$/gi, "")
  },
  //超時
  expire: {
    //持續時間
    duration: parseInt(import.meta.env.VITE_FACTORY_QRCODE_EXPIRE)
  }
}

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  //提交
  (e: "submit", passwd: string): void
  //取消
  (e: "cancel"): void
  //授權
  (e: "authorize"): void
  //鍵盤事件
  (e: "keyup", passwd: string): void
  //切換模式
  (e: "changeMode", mode: boolean): void
}>()

/**
 * 傳入 - 參數
 */
const props = withDefaults(defineProps<{
  //模式
  mode: QrcodeEnum
  //傳入前置參數
  data?: AuthorizationLogin
  //授權完成延遲關閉 (為了看特效, 不需要可以設定0)
  delay?: number
  //取消時順便關閉swal
  isCancelClose?: boolean
  //頂部加塞
  marginTop?: number
  //底部加塞
  marginBottom?: number
  //改為密碼輸入時的占位符
  placeholder?: string
}>(), {
  delay: 550,
  isCancelClose: false,
  marginTop: 0,
  marginBottom: 2,
  placeholder: ""
})

/**
 * 本地 - 參數
 */
const active = reactive<{
  //語言
  i18n: string
  //狀態
  status: QrcodeStatusEnum | null
  //是否為密碼輸入模式, 需要 props.mode === QrcodeEnum.FACTORY 才有效
  passwd: boolean
  //超時計數器
  timer: any
  //本次連線權證
  token: string | null
  //二維碼超時時間
  expire: number
  //二維碼base64內容
  blob: string | null,
  is: {
    qrcode: {
      icon: {
        size: number
      }
    }
  }
}>({
  i18n: "component.qrcode.v2",
  status: null,
  passwd: false,
  timer: null,
  token: null,
  expire: meta.expire.duration,
  blob: null,
  is: {
    qrcode: {
      icon: {
        size: 28
      }
    }
  }
})

/**
 * 欄位
 */
const field = reactive<{
  passwd: string
}>({
  passwd: ""
})

/**
 * 開啟
 */
const onOpen = () => {

  //填入本次執行的唯一識別碼
  active.token = getUuid(32, true)

  setConnect(
      sprintf(
          "%s/websocket/notify/%s",
          meta.url.websocket,
          active.token
      ),
      {
      },
      {

        message: (e: Basic.Websocket) => {

          //嘗試轉換指令
          const command: QrcodeFactoryCommandEnum = e.content.command as QrcodeFactoryCommandEnum

          //掃碼檢驗成功, UI顯示掃碼成功
          if ([QrcodeFactoryCommandEnum.INSPECTION].includes(command)) onInspection()
          //用戶端在App上按確認授權
          else if ([QrcodeFactoryCommandEnum.AUTHORIZED].includes(command)) onAuthorize(e)
          //用戶端在App上取消
          else if ([QrcodeFactoryCommandEnum.CANCEL].includes(command)) onCancel()

        }
      }
  )

  //首次獲取驗證二維碼
  setTimeout(() => onFetch(), 300)

}

/**
 * 關閉
 */
const onClose = () => {

  //清除計時器
  if (active.timer) clearInterval(active.timer)

  //切斷連線
  setDisconnect()

}

/**
 * 切換 - 模式
 */
const onChangeMode = () => emit("changeMode", !active.passwd)

/**
 * 掃碼檢驗成功
 * UI顯示掃碼成功
 */
const onInspection = () => active.status = QrcodeStatusEnum.INSPECTION

/**
 * 用戶端在App上按確認授權
 * 完成準備跳轉
 * @param e websocket 下傳值
 */
const onAuthorize = (e: Basic.Websocket) => {

  //顯示登入成功
  active.status = QrcodeStatusEnum.AUTHORIZED

  setTimeout(()=>{

    // 登入
    if (props.mode === QrcodeEnum.LOGIN) {

      //注入傳下來的token
      setUserToken(e.content.token ?? null)

    }
    // 安全性綁定
    else if (props.mode === QrcodeEnum.MAPPING) {

        //強迫登入改為Qrcode預設
        setUserAuthorized(SettingLoginEnum.QRCODE)

        //修改為用戶與管理員對應成功
        setUserMapping(true)

    }

    // 二級驗證 呼叫 emit
    if (props.mode === QrcodeEnum.FACTORY) emit(`authorize`)
    // 登入或安全性綁定
    else router.push({
      name: `AdminDashboard`,
    })

  }, props.delay)

}

/**
 * 取消
 */
const onCancel = () => {

  // 二級驗證 呼叫 emit
  if (props.mode === QrcodeEnum.FACTORY) emit(`cancel`)
  //其他狀況, 呼叫超時即可
  else onExpire()

}

/**
 * 超時
 */
const onExpire = () => {

  //清除計時器
  clearInterval(active.timer)

  //狀態 - 已經超時
  active.status = QrcodeStatusEnum.EXPIRE

}

/**
 * 取得二維碼圖
 */
const onFetch = () => {

  //清除blob (讓讀取轉動重轉)
  active.blob = null

  //清除狀態
  active.status = null

  //清除計時器
  clearInterval(active.timer)

  //啟動xhr
  const xhr = new XMLHttpRequest()

  //當xhr載入時
  xhr.onload = () => {

    //初始化檔案讀取
    const reader = new FileReader()

    //檔案讀取嘗試讀取xhr回應內容
    reader.readAsDataURL(xhr.response)

    //當檔案讀取完成時
    reader.onloadend = () => {

      //填入二維碼的blob
      active.blob = reader.result as string

      //監控超時
      active.timer = setInterval(()=>onExpire(), active.expire)

    }

  }

  //指定開啟方式與路徑
  xhr.open('POST', sprintf(
      "%s/qrcode/factory",
      meta.url.qrcode
  ))

  //指定回應類型
  xhr.responseType = 'blob'

  //指定請求header格式
  xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8")

  //如果有登入, 投入JWT
  if (isUserLogin.value) xhr.setRequestHeader("Authorization", getUserToken.value as string)

  //指定請求參數
  xhr.send(JSON.stringify({
    //請求方式
    "method": props.mode === QrcodeEnum.MAPPING ? QrcodeRequestEnum.MAPPING : QrcodeRequestEnum.FACTORY,
    //本次連線權證
    "token": active.token,
    //用戶uuid
    "uuid": props.data?.uuid ?? getUserUuid.value,
    //組織uuid
    "organization": getOrganization.value.uuid
  }))

}

/**
 * 取得 - 外觀
 */
const getVariant = computed((): string[] => [
  `mt-${props.marginTop}`,
  `mb-${props.marginBottom}`
])

/**
 * 取得 - 機構 - 內容
 */
const getOrganization = computed((): {
  [key: string]: any
} =>{
  return {
    seq: props.data?.organization.seq ?? 1,
    uuid: props.data?.organization.uuid ?? getUserChurch.value?.organization.uuid,
    name: props.data?.organization.name ?? getUserChurch.value?.organization.name,
  }
})

//切換到密碼輸入的時候, 自動focus密碼框
watch(()=>active.passwd, async (e: boolean) => {

  //非密碼模式, 不往下執行
  if (!e) {
    return
  }

  //等待渲染完成
  await nextTick()

  //focus到密碼框
  component.value?.querySelector(`.el-input__inner`)?.focus()

})

/**
 * 生命週期 - 掛起
 */
onMounted(() => onOpen())

/**
 * 生命週期 - 銷毀
 */
onUnmounted(() => onClose())

</script>

<template>
  <div :class="getVariant"
       ref="component"
       class="qrcode d-flex-column d-flex justify-content-center align-items-center">
    <!-- 二維碼顯示區 (含狀態圖) -->
    <j-qrcode-rect v-if="!active.passwd"
                   @refresh="onFetch"
                   :mode="props.mode"
                   :active="active" />
    <!-- 密碼輸入框 -->
    <j-qrcode-passwd v-else
                     @update="emit('keyup', field.passwd)"
                     @submit="emit('submit', field.passwd)"
                     :field="field"
                     :placeholder="placeholder" />
    <!-- 描述區 - 下載App/切換模式 -->
    <j-qrcode-description @change="onChangeMode"
                          :mode="props.mode"
                          :organization="getOrganization"
                          :active="active" />
  </div>
</template>

<style scoped lang="scss">

</style>