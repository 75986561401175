import {NavigationItemRaw} from '@/navigation/interface'

const question: NavigationItemRaw[] = [{
    id: 14,
    right: true,
    subject: 'admin.question.subject',
    route: null,
    icon: 'picture-frame',
    children: [{
        id: 14001,
        right: true,
        subject: 'admin.question.group.index',
        route: 'AdminQuestionGroup',
        attached: [
            'AdminQuestionGroupCreate',
            'AdminQuestionGroupEdit',
        ]
    }, {
        id: 14011,
        right: true,
        subject: 'admin.question.question.index',
        route: 'AdminQuestionQuestion',
        attached: [
            'AdminQuestionQuestionCreate',
            'AdminQuestionQuestionEdit'
        ]
    }, {
        id: 14021,
        right: true,
        subject: 'admin.question.option.index',
        route: 'AdminQuestionOption',
        attached: [
            'AdminQuestionOptionCreate',
            'AdminQuestionOptionEdit'
        ]
    }, {
        id: 14031,
        right: true,
        subject: 'admin.question.form.index',
        route: 'AdminQuestionForm',
        attached: [
            'AdminQuestionFormEdit'
        ]
    }],
    attached: []
}]

export default question
