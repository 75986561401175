import {computed} from "vue";
import {AuthStore} from "@/store/modules/auth";
import {UserStore} from "@/store/modules/user";
import {Permission} from "@/api/interface/permission";
import {EnumRightSetRole} from "@/enum/right";
import {AuthorizationStore} from "@/v2/store/modules/authorization";
import {useMenu} from "@/hook/useMenu";
import router from "@/router";

/**
 * 判斷權限
 */
export const usePermission = () => {

    const userStore = UserStore()

    const authStore = AuthStore()

    /**
     * 是否為模擬登入
     */
    const isSimulation = computed((): boolean=>{
        return !["", null].includes(userStore.getAll.church.simulation)
    })

    /**
     * 是否為總教會登入
     */
    const isHead = computed((): boolean=>{
        return userStore.getAll.church.head
    })

    /**
     * 是否具有權限 (單個權限)
     */
    const isHasRight = computed((): (e: number) => boolean => {
        return (e) => {
            return authStore.getPermission.includes(e)
        }
    })

    /**
     * 是否登入 (登入權杖不為null)
     */
    const isLogin = computed((): boolean => {
        return !["", null].includes(authStore.getToken)
        // return navigator.userAgent.includes("Chrome") ? true: !["", null].includes(authStore.getToken)
    })

    /**
     * 返回登入權杖 axios 用
     */
    const getToken = computed((): string | null => {
        return authStore.getToken
    })

    /**
     * 注入登入權杖
     */
    const setToken = (e: string | null) => {

        authStore.setToken(e)

        // 暫時先寫兩邊
        AuthorizationStore().setToken(e)

    }

    /**
     * 更新 - 登入 - 權杖
     * @param e
     */
    const updateToken = (e: string | null) => {

        const {
            setOverlay
        } = useMenu()

        //如果在最小視窗, 縮起
        setOverlay(false)

        //注入新的JWT
        setToken(e)

        //故意丟回登入頁面, 他會跳轉回控制面板, 順便重連websocket
        setTimeout(async () => await router.replace({
            name: `Login`,
        }), 0)

    }

    /**
     * 返回目前的權限
     */
    const getPermission = (): number[] => {
        return authStore.getPermission
    }

    /**
     * 一次性注入權限資料
     */
    const setPermission = (e: number[]) => authStore.setPermission(e)

    /**
     * 設定用的權限表
     * @param head 是否為總教會
     * @param group 傳下來可用的權限群組
     * Permission.role 總教會抓 [HEAD, ALL], 一般教會抓 [NORMAL, ALL]
     */
    const getAvailableRight = (head: boolean,
                               group: number[]) => {

        const r = [{
            //我的教會 - 控制面板
            seq: 100,
            portal: 1,
            group: 1,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.home.permission`,
            feature: [1001, -1, -1, -1, -1, -1, -1]
        }, {
            //我的教會 - 公告管理
            portal: 1,
            group: 17,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.billboard.permission`,
            feature: [17001, 17002, 17003, 17004, 17005, 17006, -1]
        }, {
            //我的教會 - 管理員帳號管理
            portal: 1,
            group: 2,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.user.permission`,
            feature: [2001, 2002, 2003, 2004, 2005, 2006, -1]
        }, {
            //我的教會 - 教會管理
            portal: 1,
            group: 3,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.church.permission`,
            feature: !head ?
                [-1, -1, -1, 3004, 3005, -1, -1]:
                [3001, 3002, 3003, 3004, 3005, -1, -1]
        }, {
            //我的教會 - 會友管理
            portal: 1,
            group: 4,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.client.permission`,
            feature: [4001, -1, -1, 4004, 4005, 4006, 4007]
        }, {
            //我的教會 - 會友管理 - 奉獻紀錄
            portal: 1,
            group: 4,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.client.donate.permission`,
            feature: [4011, -1, -1, 4014, -1, -1, 4017]
        }, {
            //我的教會 - 我的教會文章管理
            portal: 1,
            group: 5,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.my.permission`,
            feature: [5001, 5002, 5003, 5004, 5005, 5006, -1]
        }, {
            //我的教會 - 我的教會文章管理 - 留言紀錄
            portal: 1,
            group: 5,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.my.comment.permission`,
            feature: [5011, -1, -1, -1, -1, 5016, -1]
        }, {
            //我的教會 - 我的教會文章管理 - 訊息通知
            portal: 1,
            group: 5,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.my.notification.permission`,
            feature: [5021, 5022, 5023, 5024, 5025, 5026, -1]
        }, {
            //我的教會 - 心靈捕手文章管理
            portal: 1,
            group: 6,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.soul.permission`,
            feature: [6001, 6002, 6003, 6004, 6005, 6006, -1]

        }, {
            //我的教會 - 心靈捕手文章管理 - 分類管理
            portal: 1,
            group: 6,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.soul.category.permission`,
            feature: [6011, 6012, 6013, 6014, 6015, 6016, -1]
        }, {
            //我的教會 - 心靈捕手文章管理 - 留言紀錄
            portal: 1,
            group: 6,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.soul.comment.permission`,
            feature: [6021, -1, -1, -1, -1, 6026, -1]
        }, {
            //我的教會 - 心靈捕手文章管理 - 訊息通知
            portal: 1,
            group: 6,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.soul.notification.permission`,
            feature: [6031, 6032, 6033, 6034, 6035, 6036, -1]
        }, {
            //我的教會 - 每日靈糧文章管理
            portal: 1,
            group: 7,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.daily.permission`,
            feature: [7001, 7002, 7003, 7004, 7005, 7006, -1]
        }, {
            //我的教會 - 每日靈糧文章管理 - 留言紀錄
            portal: 1,
            group: 7,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.daily.comment.permission`,
            feature: [7011, -1, -1, -1, -1, 7016, -1]
        }, {
            //我的教會 - 每日靈糧文章管理 - 訊息通知
            portal: 1,
            group: 7,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.daily.notification.permission`,
            feature: [7021, 7022, 7023, 7024, 7025, 7026, -1]
        }, {
            //我的教會 - 圖庫管理 - 愛無界圖庫
            portal: 1,
            group: 8,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.gallery.permission.index`,
            feature: [8001, -1, -1, -1, 8005, -1, -1]
        }, {
            //我的教會 - 圖庫管理 - 圖庫核准
            portal: 1,
            group: 8,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.gallery.permission.accept`,
            feature: [8011, -1, -1, -1, 8015, -1, -1]
        }, {
            //我的教會 - 圖庫管理 - 圖庫檢舉
            portal: 1,
            group: 8,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.gallery.permission.alarm`,
            feature: [8021, -1, -1, -1, 8025, -1, -1]
        }, {
            //我的教會 - 推播管理
            portal: 1,
            group: 9,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.notification.permission`,
            feature: [9001, 9002, 9003, 9004, 9005, 9006, -1]
        }, {
            //我的教會 - 服事管理
            portal: 1,
            group: 10,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.event.permission`,
            feature: [10001, 10002, 10003, 10004, 10005, 10006, -1]
        }, {
            //我的教會 - 服事管理 - 服事群組
            portal: 1,
            group: 10,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.event.team.permission`,
            feature: [10011, 10012, 10013, 10014, 10015, 10016, -1]
        }, {
            //我的教會 - 奉獻管理
            portal: 1,
            group: 11,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.donate.permission`,
            feature: [11001, -1, -1, 11004, -1, -1, 11007]
        }, {
            //我的教會 - 教學管理
            portal: 1,
            group: 12,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.teach.permission`,
            feature: [12001, 12002, 12003, 12004, 12005, 12006, -1]
        }, {
            //我的教會 - 循環管理
            portal: 1,
            group: 13,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.cycle.permission`,
            feature: [13001, 13002, 13003, 13004, 13005, 13006, -1]
        }, {
            //我的教會 - 活動管理
            portal: 1,
            group: 18,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.activity.permission`,
            feature: [18001, 18002, 18003, 18004, 18005, 18006, -1]
        }, {
            //我的教會 - 活動管理 - 分類管理
            portal: 1,
            group: 18,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.activity.category.permission`,
            feature: [18011, 18012, 18013, 18014, 18015, 18016, -1]
        }, {
            //我的教會 - 直播管理
            portal: 1,
            group: 19,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.live.permission`,
            feature: [19001, 19002, 19003, 19004, 19005, 19006, -1]
        }, {
            //我的教會 - 直播管理 - 分類管理
            portal: 1,
            group: 19,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.live.category.permission`,
            feature: [19011, 19012, 19013, 19014, 19015, 19016, -1]
        }, {
            //我的教會 - 問答集管理
            portal: 1,
            group: 14,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.question.group.permission`,
            feature: [14001, 14002, 14003, 14004, 14005, 14006, -1]
        }, {
            //我的教會 - 問答集管理 - 問題設置
            portal: 1,
            group: 14,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.question.question.permission`,
            feature: [14011, 14012, 14013, 14014, 14015, 14016, -1]
        }, {
            //我的教會 - 問答集管理 - 回覆選項
            portal: 1,
            group: 14,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.question.option.permission`,
            feature: [14021, 14022, 14023, 14024, 14025, 14026, -1]
        }, {
            //我的教會 - 問答集管理 - 表單紀錄
            portal: 1,
            group: 14,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.question.form.permission`,
            feature: [14031, -1, -1, 14034, -1, -1, -1]
        }, {
            //我的教會 - 客服管理 - 帳號紀錄
            portal: 1,
            group: 15,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.support.history.permission`,
            feature: [15001, -1, -1, 15004, -1, -1, -1]
        }, {
            //我的教會 - 客服管理 - 意見與建議
            portal: 1,
            group: 15,
            role: EnumRightSetRole.ALL,
            t: `nav.admin.support.feedback.permission`,
            feature: [15011, -1, -1, 15014, 15015, -1, -1]
        }, {
            //我的教會 - 客服管理 - 請我們喝咖啡
            portal: 1,
            group: 15,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.support.coffee.permission`,
            feature: [15021, -1, -1, 15024, -1, -1, 15027]
        }, {
            //我的教會 - 歷程管理 - 攔截紀錄
            portal: 1,
            group: 16,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.course.exception.permission`,
            feature: [16001, -1, -1, 16004, -1, -1, -1]
        }, {
            //我的教會 - 歷程管理 - 存取紀錄
            portal: 1,
            group: 16,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.course.access.permission`,
            feature: [16011, -1, -1, 16014, -1, -1, -1]
        }, {
            //我的教會 - 歷程管理 - 授權紀錄
            portal: 1,
            group: 16,
            role: EnumRightSetRole.HEAD,
            t: `nav.admin.course.authorize.permission`,
            feature: [16021, -1, -1, 16024, -1, -1, -1]
        }, {
            //牧養系統 - 控制面板
            portal: 2,
            group: 51,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.home.permission`,
            feature: [51001, -1, -1, -1, -1, -1, -1]
        }, {
            //牧養系統 - 新朋友管理
            portal: 2,
            group: 52,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.friend.permission`,
            feature: [52001, 52002, 52003, 52004, 52005, 52006, -1]
        }, {
            //牧養系統 - 會友管理
            portal: 2,
            group: 53,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.client.permission`,
            feature:  [53001, 53002, 53003, 53004, 53005, 53006, 53007]
        }/*, {
                //牧養系統 - 會友管理 - 進階功能
                portal: 2,
                group: 53,
                role: EnumRightSetRole.ALL,
                t: `nav.shepherd.client.advance.permission`,
                feature: [53011, -1, -1, -1, 53006, 53007, -1]
        }*/, {
            //牧養系統 - 族群管理
            portal: 2,
            group: 54,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.team.permission`,
            feature: [54001, 54002, 54003, 54004, 54005, 54006, -1]
        }, {
            //牧養系統 - 聚會管理
            portal: 2,
            group: 55,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.attend.permission`,
            feature: [55001, 55002, 55003, 55004, 55005, 55006, -1]
        }, {
            //牧養系統 - 聚會管理 - 分類管理
            portal: 2,
            group: 55,
            role: EnumRightSetRole.ALL,
            t: `nav.shepherd.attend.category.permission`,
            feature: [55011, 55012, 55013, 55014, 55015, 55016, -1]
        }, {
            //牧養系統 - 參數管理
            portal: 2,
            group: 56,
            role: EnumRightSetRole.NORMAL,
            t: `nav.shepherd.setting.permission`,
            feature: !head ?
                [-1, -1, -1, 56004, 56005, -1, -1]:
                [-1, -1, -1, -1, -1, -1, -1]
        }]

        //總教會抓 [1, 3], 一般教會抓 [2, 3]
        //const role = ( isHead.value ? [EnumRightSetRole.HEAD] : [EnumRightSetRole.NORMAL] ).concat([EnumRightSetRole.ALL])

        //篩掉個別才有的功能表
        return r
            .filter(i=>{

                if (i.group === 15) console.log(head, i)

                //不在下傳的權限群組中
                if (!group.includes(i.group)) return false
                //如果標示全部都有
                else if (i.role === EnumRightSetRole.ALL) return true
                //如果標示總教會才有
                else if (head && i.role === EnumRightSetRole.HEAD) return true
                //如果標示非總教會才有
                else if (!head && i.role === EnumRightSetRole.NORMAL) return true

                return false

            })
            .map(i=>{
                return Object.assign(i, {
                    seq: i.group * 100
                }) as Permission
            })

    }

    return {
        isSimulation,
        isHead,
        isLogin,
        isHasRight,
        getToken,
        setToken,
        updateToken,
        getPermission,
        setPermission,
        getAvailableRight
    }

}
