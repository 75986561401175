/**
 * 鉤子
 * 語系
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {computed} from "vue";
import {SettingStore} from "@/store/modules/setting";
import i18n from "@/v2/i18n";

/**
 * 實例
 */
export interface Region {

    /**
     * 代號簡寫
     */
    id: string

    /**
     * axios header 要灌的參數
     */
    key: string

    /**
     * 顯示的語言名稱
     */
    value: string

}

export const useRegion = () => {

    //設定庫
    const settingStore = SettingStore()

    //https://nucleoapp.com/svg-flag-icons
    //https://github.com/lipis/flag-icons

    //可用語言
    //spring boot 的語系要用 zz-YY, 不能用zz_YY
    const availableRegion: Region[] = [{
        id: "zhTW",
        key: "zh-TW",
        value: "繁體中文",
    }, {
        id: "zhCN",
        key: "zh-CN",
        value: "简体中文"
    }]

    /**
     * 設置 - 語系
     */
    const setRegion = (
        region: string
    ) => {

        //確認語系存在
        const r: Region | undefined = availableRegion.find((i: Region) => i.id === region)

        //如果找不到正確語系, 不往下處理
        if (r === undefined) {
            return false
        }

        settingStore.setRegion(region)

        //修改App的原始属性
        //@ts-ignore
        i18n.global.locale.value = region

        //修改頁面屬性
        document.querySelector('html')!.setAttribute('lang', getCurrentRegion.value.key);

    }

    /**
     * 獲取現在的語系
     */
    const getCurrentRegion = computed((): Region => {
        const r: Region | undefined = availableRegion.find((i: Region)=>i.id === settingStore.getRegion)
        if (r === undefined) {
            return availableRegion[0]
        }
        return r
    })

    /**
     * 返回可用語系 (除了現在的以外)
     */
    const getAvailableRegion = computed((): Region[] => availableRegion.filter((i: Region)=>i.id !== settingStore.getRegion))

    /**
     * 返回全部語系
     */
    const getRegion = computed((): Region[] => availableRegion)

    return {
        getCurrentRegion,
        getAvailableRegion,
        getRegion,
        setRegion
    }

}