import {RouteRecordRaw} from "vue-router";

const client: RouteRecordRaw[] = [
	{
		//會友管理
		path: "members",
        name: "AdminClient",
		meta: {
			permission: 4001,
			breadcrumb: [{
				key: 'admin.client.index',
				value: null
			}]
		},
        component: () => import("@/page/content/admin/client/index.vue"),
	}, {
		//會友編輯
		path: "members/edit/:uuid",
		name: "AdminClientEdit",
		meta: {
			permission: 4004,
			breadcrumb: [{
				key: 'admin.client.index',
				value: "AdminClient"
			}, {
				key: 'admin.client.edit',
				value: null
			}]
		},
		component: () => import("@/page/content/admin/client/edit.vue"),
	}, {
		//會友奉獻紀錄 - 列表
		path: "members/edit/:uuid/donate",
		name: "AdminClientDonate",
		meta: {
			permission: 4011,
			breadcrumb: [{
				key: 'admin.client.index',
				value: "AdminClient"
			}, {
				key: 'admin.client.edit',
				value: "AdminClientEdit",
			}, {
				key: 'admin.client.donate.index',
				value: null
			}]
		},
		component: () => import("@/page/content/admin/client/donate/index.vue"),
	}, {
		//會友奉獻紀錄 - 內容
		path: "members/edit/:uuid/donate/:uid",
		name: "AdminClientDonateEdit",
		meta: {
			permission: 4014,
			breadcrumb: [{
				key: 'admin.client.index',
				value: "AdminClient"
			}, {
				key: 'admin.client.edit',
				value: "AdminClientEdit",
			}, {
				key: 'admin.client.donate.index',
				value: "AdminClientDonate"
			}, {
				key: 'admin.client.donate.edit',
				value: null
			}]
		},
		component: () => import("@/page/content/admin/client/donate/edit.vue"),
	}
];

export default client;
