import mitt from 'mitt'

import {
    computed,
    reactive
} from "vue";

import {
    Configure
} from "@/api/interface/configure";

const VueEvent = mitt();

/**
 * 控件互通 (但不跨瀏覽器的頁籤)
 */
export const useMitt = () => {

    /**
     * 參數 - 本地
     */
    const active = reactive<{
        //讀取遮罩
        loadingMask: boolean;
        //右滑選單
        offCanvas: boolean;
        //刷新頁面
        reloadPage: boolean;
        //dialog
        dialog: boolean;
        //文章預覽
        preview: boolean;
        //幻燈箱
        lightbox: Configure.LightBox;
        //element可見變化
        visible: {
            change: {
                select: boolean
            }
        }
    }>({
        loadingMask: false,
        offCanvas: false,
        reloadPage: false,
        dialog: false,
        preview: true,
        lightbox: {
            index: 0,
            show: false,
            download: false,
            images: []
        },
        visible: {
            change: {
                select: false
            }
        }
    })

    /**
     * 將body捲軸鎖起來
     */
    const lockBody = (e: boolean) => {
        const body = document.getElementsByTagName("BODY")[0];
        e ? body.classList.add("dropdown-lockout") : body.classList.remove("dropdown-lockout")
    }

    /**
     * 回傳讀取遮罩
     */
    const isLoadingMask = computed((): boolean => active.loadingMask)

    /**
     * 設定讀取遮罩
     */
    const setLoadingMask = (enable: boolean) => VueEvent.emit("loadingMask", enable)

    /**
     * 回傳右滑選單
     */
    const isOffCanvas = computed((): boolean => active.loadingMask)

    /**
     * 設定右滑選單
     */
    const setOffCanvas = (enable: boolean) => {
        lockBody(enable)
        VueEvent.emit("offCanvas", enable)
    }

    /**
     * 回傳重刷頁面
     */
    const isReloadPage = computed((): boolean => active.reloadPage)

    /**
     * 設定重刷頁面
     */
    const setReloadPage = (enable: boolean) => {
        VueEvent.emit("reloadPage", enable)
    }

    /**
     * 回傳文章是否產生可以預覽
     */
    const isPreview = computed((): boolean => {
        return active.preview
    })

    /**
     * 設定文章是否產生可以預覽
     */
    const setPreview = (enable: boolean) => {
        VueEvent.emit("preview", enable)
    }

    /**
     * 回傳dialog
     */
    const isDialog = computed((): boolean => {
        return active.dialog
    })

    /**
     * 設定dialog
     */
    const setDialog = (enable: boolean) => {
        lockBody(enable)
        VueEvent.emit("dialog", enable)
    }

    /**
     * 回傳幻燈箱參數
     */
    const getLightBox = computed((): Configure.LightBox => {
        return active.lightbox
    })

    /**
     * 設定幻燈箱參數
     */
    const setLightBox = (
        show: boolean = false,
        images: any[],
        index?: number,
        download?: boolean
    ) => {
        if ( show ) VueEvent.emit("lightBox", {
            show: true,
            images: images,
            index: index,
            download: download
        })
        //如果是關閉的話, 順便關閉下載
        else VueEvent.emit("lightBox", {
            show: false,
            images: [],
            index: 0,
            download: false
        })
    }

    /**
     * 設定element下拉選單
     */
    const setVisibleChangeSelect = (e: boolean) => {
        VueEvent.emit("selectVisibleChange", e)
    }

    /**
     * 回傳element下拉選單
     */
    const isVisibleChangeSelect = computed((): boolean => {
        return active.visible.change.select
    })

    /**
     * 監控遮罩變化
     */
    VueEvent.on("loadingMask", (enable)=>{
        if (typeof enable === "boolean") active.loadingMask = enable
    })

    /**
     * 監控右滑選單
     */
    VueEvent.on("offCanvas", (enable)=>{
        if (typeof enable === "boolean") active.offCanvas = enable
    })

    /**
     * 監控重刷頁面
     */
    VueEvent.on("reloadPage", (enable)=>{
        if (typeof enable === "boolean") active.reloadPage = enable
    })

    /**
     * 監控dialog
     */
    VueEvent.on("dialog", (enable)=>{
        if (typeof enable === "boolean") active.dialog = enable
    })

    /**
     * 文章是否產生可以預覽
     */
    VueEvent.on("preview", (enable)=>{
        if (typeof enable === "boolean") active.preview = enable
    })

    /**
     * 下拉選單變化
     */
    VueEvent.on("selectVisibleChange", (e)=>{
        if (typeof e === "boolean") active.visible.change.select = e
    })

    /**
     * 監控幻燈箱參數
     */
    VueEvent.on("lightBox", (data)=>{
        const r = data as Configure.LightBox
        active.lightbox.show = r.show
        active.lightbox.images = r.images
        active.lightbox.index = r.index
        active.lightbox.download = r.download
    })

    return {
        isLoadingMask,
        setLoadingMask,
        isOffCanvas,
        setOffCanvas,
        isReloadPage,
        setReloadPage,
        isDialog,
        setDialog,
        isPreview,
        setPreview,
        getLightBox,
        setLightBox,
        isVisibleChangeSelect,
        setVisibleChangeSelect,
    }

}
