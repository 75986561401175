/**
 * 活动管理 - 分类
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建分类"
            },
        },
        field: {
            head: {
                church: "指定教会",
            },
            name: "名称",
            quantity: "活动数量",
            sort: "排序",
            created: "建立时间"
        },
        holder: {
            head: {
                church: "请选择教会",
            },
            name: "请输入名称",
        },
        handler: {
            enable: {
                subject: "即将启用分类",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用分类",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除分类, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        help: {
            church: "请谨慎使用指定教会功能",
        },
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            church: "指定教会",
            name: "名称",
            status: "状态"
        },
        holder: {
            church: "请选择指定教会",
            name: "请输入名称",
            status: "请选择状态"
        },
        handler: {
            store: {
                subject: "即将创建分类",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            disabled: "请注意, 这个分类已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            belong: {
                no: "所属教会编号",
                name: "所属教会名称"
            },
            name: "名称",
            status: "状态"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            name: "请输入名称",
            status: "请选择状态"
        },
        handler: {
            update: {
                subject: "即将更新分类内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            }
        }
    }

}
