export const Search: {
    [key: string]: any
} = {

    field: {
        belong: {
            subject: "所属教会",
            holder: "请选择所属教会",
            help: "仅总教会"
        },
        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            help: "同时搜索名字/邮件/会友编号"
        },
        on: {
            subject: "开始时间(起)",
            holder: "请选择开始时间(起)",
            end: {
                subject: "开始时间(迄)",
                holder: "请选择开始时间(迄)"
            }
        },
        off: {
            subject: "结束时间(起)",
            holder: "请选择结束时间(起)",
            end: {
                subject: "结束时间(迄)",
                holder: "请选择结束时间(迄)"
            }
        },
        submit: {
            subject: "建立时间(起)",
            holder: "请选择建立时间(起)",
            end: {
                subject: "建立时间(迄)",
                holder: "请选择建立时间(迄)"
            }
        }
    },
    help: {
        begin: {
            subject: "(≥)指定时间"
        },
        end: {
            subject: "(≤)指定时间"
        }
    }

}
