/**
 * 本地資料庫
 * 授權 - 權杖
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {defineStore} from "pinia"
import {PiniaEnum} from "@/v2/enumerate/pinia";
import PiniaPersistConfiguration from "@/v2/configuration/pinia"
import {AuthorizationSession, AuthorizationSessionPermission} from "@/v2/api/interface/authorization/session";
import {SummaryPeriodEnum} from "@/v2/enumerate/summary/period";

export const AuthorizationSessionStore = defineStore({
    id: PiniaEnum.AUTHORIZATION_SESSION,
    state: (): AuthorizationSession => ({
        uuid: null,
        root: false,
        mapping: false,
        account: null,
        name: null,
        avatarUrl: null,
        email: null,
        expireAt: 0,
        permission: [],
        church: {
            head: false,
            simulation: {
                uuid: null
            },
            uuid: null,
            no: null,
            name: null,
            invitation: null,
            caption: {
                my: null,
                soul: null,
                daily: null
            },
            delegate: {
                soul: false,
                daily: false
            },
            query: {
                day: 90,
                begin: -30,
                end: 0,
                period: [
                    SummaryPeriodEnum.DAY,
                    SummaryPeriodEnum.WEEK,
                    SummaryPeriodEnum.MONTH,
                    SummaryPeriodEnum.SEASON
                ]
            },
            organization: {
                uuid: null,
                name: null,
            }
        },
        software: {
            version: null,
            build: null
        },
        validator: {
            church: {
                newest: {
                    offset: {
                        minimum: parseInt(import.meta.env.VITE_SITE_CHURCH_NEWEST_OFFSET_MINIMUM),
                        maximum: parseInt(import.meta.env.VITE_SITE_CHURCH_NEWEST_OFFSET_MAXIMUM)
                    }
                }
            },
            passwd: {
                strong: {
                    level: 2
                },
                minimum: {
                    length: 8
                }
            },
            user: {
                account: {
                    minimum: {
                        length: 6
                    }
                }
            },
            qrcode: {
                expire: parseInt(import.meta.env.VITE_FACTORY_QRCODE_EXPIRE)
            }
        }
    }),
    getters: {
        getUuid: state => {
            return state.uuid
        },
        isRoot: state => {
            return state.root
        },
        isMapping: state => {
            return state.mapping
        },
        getAccount: state => {
            return state.account
        },
        getName: state => {
            return state.name
        },
        getAvatarUrl: state => {
            return state.avatarUrl
        },
        getEmail: state => {
            return state.email
        },
        getExpireAt: state => {
            return state.expireAt
        },
        getPermission: state => {
            return state.permission
        },
        getChurch: state => {
            return state.church
        },
        getSoftware: state => {
            return state.software
        },
        getValidator: state => {
            return state.validator
        }
    },
    actions: {
        setMapping(mapping: boolean) {
            this.mapping = mapping
        },
        setName(name: string) {
            this.name = name
        },
        setEmail(email: string) {
            this.email = email
        },
        setAvatarUrl(avatar: string | null) {
            this.avatarUrl = avatar
        },
        setPermission(permission: AuthorizationSessionPermission[]) {
            this.permission = permission
        },
        setData(data: AuthorizationSession) {
            Object.keys(data).forEach((i: string)=>{
                if (this[i] !== undefined) this[i] = data[i]
            })
        }
    },
    persist: PiniaPersistConfiguration(PiniaEnum.AUTHORIZATION_SESSION)
})