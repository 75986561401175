/**
 * 枚舉
 * Tabler Icon
 * @author J
 * @since 2024-05-20 12:07:25
 */

export enum TablerIconEnum {

    /**
     * x
     */
    X,

    /**
     * BRAND_APPLE
     */
    BRAND_APPLE,

    /**
     * BRAND_ANDROID
     */
    BRAND_ANDROID,

    /**
     * settings-automation
     */
    SETTINGS_AUTOMATION,

    /**
     * broadcast
     */
    BROADCAST,

    /**
     * letter-o
     */
    LETTER_O,

    /**
     * letter-d
     */
    LETTER_D,

    /**
     * letter-w
     */
    LETTER_W,

    /**
     * letter-m
     */
    LETTER_M,

    /**
     * letter-y
     */
    LETTER_Y,

    /**
     * antenna-bars-5
     */
    ANTENNA_BARS_5,

    /**
     * copy
     */
    COPY,

    /**
     * dots
     */
    DOTS,

    /**
     * viewfinder
     */
    VIEW_FINDER,

    /**
     * bell
     */
    BELL,

    /**
     * repeat
     */
    REPEAT,

    /**
     * shield-lock
     */
    SHIELD_LOCK,

    /**
     * shield-check
     */
    SHIELD_CHECK,

    /**
     * history
     */
    HISTORY,

    /**
     * file-orientation
     */
    FILE_ORIENTATION,

    /**
     * book-upload
     */
    BOOK_UPLOAD,

    /**
     * link
     */
    LINK,

    /**
     * unlink
     */
    UNLINK,

    /**
     * trash
     */
    TRASH,

    /**
     * clock
     */
    CLOCK,

    /**
     * hourglass-empty
     */
    HOURGLASS_EMPTY,

    /**
     * flag
     */
    FLAG,

    /**
     * arrow-back-up
     */
    ARROW_BACK_UP,

    /**
     * flame
     */
    FLAME,

    /**
     * ban
     */
    BAN,

    /**
     * home
     */
    HOME,

    /**
     * star
     */
    STAR,

    /**
     * building
     */
    BUILDING,

    /**
     * id-badge2
     */
    ID_BADGE2,

    /**
     * user
     */
    USER,

    /**
     * user-check
     */
    USER_CHECK,

    /**
     * users-group
     */
    USERS_GROUP,

    /**
     * code
     */
    CODE,

    /**
     * code
     */
    TAG,

    /**
     * lock
     */
    LOCK,

    /**
     * plus
     */
    PLUS,

    /**
     * search
     */
    SEARCH,

    /**
     * check
     */
    CHECK,

    /**
     * minus
     */
    MINUS,

    /**
     * brand-youtube
     */
    BRAND_YOUTUBE,

    /**
     * photo
     */
    PHOTO,

    /**
     * paperclip
     */
    PAPERCLIP,

    /**
     * pencil
     */
    PENCIL,

    /**
     * pencil-off
     */
    PENCIL_OFF,

    /**
     * prompt
     */
    PROMPT,

    /**
     * gauge
     */
    GAUGE,

    /**
     * headset
     */
    HEADSET,

    /**
     * timeline
     */
    TIMELINE,

    /**
     * chart-line
     */
    CHART_LINE,

    /**
     * map-pin
     */
    MAP_PIN,

    /**
     * hand-stop
     */
    HAND_STOP,

    /**
     * loader-2
     */
    LOADER_2,

    /**
     * caret-up
     */
    CARET_UP,

    /**
     * caret-down
     */
    CARET_DOWN,

    /**
     * zoom-in
     */
    ZOOM_IN,

    /**
     * zoom-out
     */
    ZOOM_OUT,

    /**
     * zoom-exclamation
     */
    ZOOM_EXCLAMATION,

    /**
     * arrows-maximize
     */
    ARROWS_MAXIMIZE,

    /**
     * download
     */
    DOWNLOAD,

    /**
     * sun
     */
    SUN,

    /**
     * moon
     */
    MOON,

    /**
     * rotate-clockwise-2
     */
    ROTATE_CLOCKWISE_2,

    /**
     * square-check
     */
    SQUARE_CHECK,

    /**
     * baseline-density-medium
     */
    BASELINE_DENSITY_MEDIUM,

    /**
     * microphone
     */
    MICROPHONE,

    /**
     * message-circle
     */
    MESSAGE_CIRCLE,

    /**
     * chevron-right
     */
    CHEVRON_RIGHT,

    /**
     * arrow-left
     */
    ARROW_LEFT,

    /**
     * arrow-right
     */
    ARROW_RIGHT,

    /**
     * arrow-top
     */
    ARROW_TOP,

    /**
     * arrow-down
     */
    ARROW_DOWN,

    /**
     * arrow-bar-to-up
     */
    ARROW_BAR_TO_UP,

    /**
     * arrow-bar-left
     */
    ARROW_BAR_LEFT,

    /**
     * arrow-bar-right
     */
    ARROW_BAR_RIGHT,

    /**
     * arrow-narrow-down
     */
    ARROW_NARROW_DOWN,

    /**
     * exclamation-mark
     */
    EXCLAMATION_MARK,

    /**
     * player-play-filled
     */
    PLAYER_PLAY_FILLED,

    /**
     * adjustments-horizontal
     */
    ADJUSTMENTS_HORIZONTAL,

    /**
     * question-mark
     */
    QUESTION_MARK

}