/**
 * 循环管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建循环"
            }
        },
        field: {
            ref: "类型",
            subject: "名称",
            period: "周期"
        },
        holder: {
            subject: "请输入名称"
        },
        handler: {
            enable: {
                subject: "即将启用循环",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用循环",
                text: "请确认后执行",
                description: ""
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "循环"
            }
        },
        tip: {
            create: "建立后类型与目标无法修改, 请确认"
        },
        help: {
            church: "请谨慎使用指定教会功能",
            live: {
                subject: "点击查看说明",
                text: "部分文章无法设置循环"
            }
        },
        field: {
            church: "指定教会",
            ref: "种类",
            kind: "类型",
            subject: "名称",
            selection: {
                article: {
                    main: "主分类",
                    sub: "次分类"
                }
            },
            item: {
                subject: "标题",
                begin: {
                    article: "上架时间",
                    notification: "排程时间",
                    other: "开始时间"
                },
                end: {
                    article: "下架时间",
                    other: "结束时间"
                }
            },
            target: "项目"
        },
        holder: {
            church: "请选择指定教会",
            kind: "请选择类型",
            subject: "请输入名称",
            selection: {
                article: {
                    main: "请选择主分类",
                    sub: "请选择次分类"
                }
            },
            item: {
                subject: "未填写标题",
                begin: {
                    article: "未选择上架时间",
                    notification: "未选择排程时间",
                    other: "未选择开始时间"
                },
                end: {
                    article: "未选择下架时间",
                    other: "未选择结束时间"
                }
            },
            target: "请选择项目"
        },
        handler: {
            store: {
                subject: "即将创建循环",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择类型",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择主分类",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择次分类",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择项目",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            disabled: "请注意, 这笔循环已经停用",
            create: "建立后类型与目标无法修改"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "循环"
            },
            2: {
                subject: "历程纪录"
            },
            99: {
                subject: "相关资讯"
            },
        },
        summary: {
            history: {
                subject: "循环纪录"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            kind: "类型",
            subject: "名称",
            selection: {
                article: {
                    main: "主分类",
                    sub: "次分类"
                }
            },
            item: {
                subject: "标题",
                begin: {
                    article: "上架时间",
                    notification: "排程时间",
                    other: "开始时间"
                },
                end: {
                    article: "下架时间",
                    other: "结束时间"
                }
            },
            target: "项目"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            kind: "请选择类型",
            subject: "请输入名称",
            selection: {
                article: {
                    main: "请选择主分类",
                    sub: "请选择次分类"
                }
            },
            item: {
                subject: "未填写标题",
                begin: {
                    article: "未选择上架时间",
                    notification: "未选择排程时间",
                    other: "未选择开始时间"
                },
                end: {
                    article: "未选择下架时间",
                    other: "未选择结束时间"
                }
            },
            target: "请选择项目"
        },
        handler: {
            update: {
                subject: "即将更新循环内容",
                text: "请确认后执行",
                description: ""
            }
        }
    }

}


