import statistics from "@/language/modules/page/shepherd/dashboard/statistics/zhTW";

/**
 * 首頁
 */
export default {
    index: "控制面板",
    permission: "控制面板",
    statistics: statistics
}
