/**
 * App下載畫面
 */
export default {
    subject: "領袖的好幫手, 會友的好工具",
    text: "一個專屬教會的APP, 是教會的媒體與資訊發佈工具",
    description: "教會訊息、活動不漏接, 隨時隨地跟著讀經、禱告",
    button: {
        apple: "Apple用戶端",
        google: "Android用戶端",
    }
}
