import {	PersistedStateOptions} from "pinia-plugin-persistedstate";

/**
 * pinia持久化參數配置
 * @param {String} key 存儲到持久化的 name
 * @return persist
 * */
const piniaPersistConfig = (key: string) => {
	const persist: PersistedStateOptions = {
		key,
		storage: window.localStorage
	};
	return persist;
};

export default piniaPersistConfig;
