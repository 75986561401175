export default {
    recipient: {
        complete: {
            subject: "已送达",
            text: "送达时间 %04d-%02d-%02d %02d:%02d"
        },
        read: {
            subject: "已读取",
            yet: {
                subject: "未读取",
            }
        },
        broadcast: {
            subject: "群体推播无法编辑名单"
        },
        move: {
            left: "双击加入",
            right: "双击移除"
        },
        subject: "编辑推播名单",
        disable: {
            available: "请选择加入的项目",
            selected: "请选择移除的项目",
        },
        changed: "尚未异动项目",
        empty: "请至少选择<br />1位会友进行推播",
        not: {
            found: "无法搜索到<br />您指定条件的会友"
        },
        help: {
            available: `可选择 <b class="cgBase">%s</b> 个会友`,
            selected: `已选择 <b class="cgBase">%s</b> 个会友`
        },
        th: [
            "头像",
            "会友",
            "操作",
            "进度",
            "读取"
        ],
        status: [
            "未读",
            "已读"
        ],
        field: {
            keyword: "搜索名单",
            tag: "标签"
        },
        holder: {
            keyword: "搜索范围: 名字/邮件/编号",
            tag: "请选择标签"
        },
        handle: {
            close: {
                subject: "即将关闭编辑推播",
                text: `<span class="text-danger">异动项目将会清除, 请确认后执行</span>`
            }
        },
        tag: {
            all: "全部",
            uncategorized: "未分类",
            administrator: "管理员名单"
        }
    },
    status: [
        null,
        "排程中",
        "进行中",
        "已完成"
    ],
    type: {
      1: "指定",
      2: "全体"
    },
    destroy: [
        "删除",
        "推播进行中, 无法删除",
        "推播已经完成, 无法删除"
    ],
    exclude: {
        label: "排除机构",
        help: {
            subject: "选中的机构将会排除并不发送推播信息",
            all: {
                subject: "将不排除任何机构"
            }
        }
    }
}
