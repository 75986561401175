/**
 * 历程管理
 */
export default {
    subject: "历程管理",
    exception: {
        index: "拦截纪录",
        edit: "拦截内容",
        permission: "历程管理 - 拦截纪录"
    },
    access: {
        index: "存取纪录",
        edit: "存取内容",
        permission: "历程管理 - 存取纪录"
    },
    authorize: {
        index: "授权纪录",
        edit: "登入内容",
        permission: "历程管理 - 授权纪录"
    }
}
