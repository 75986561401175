import {Membership} from "@/language/modules/page/admin/event/v2/create/membership/zhTW";

export const Create: {
    [key: string]: any
} = {

    membership: Membership,

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "名單"
        },
        2 : {
            subject: "提醒"
        },
        3 : {
            subject: "循環"
        }
    },
    field: {
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        begin: {
            subject: "開始時間",
            holder: "請選擇開始時間"
        },
        end: {
            subject: "結束時間",
            holder: "請選擇結束時間"
        },
        content: {
            subject: "說明",
            holder: "請輸入說明"
        },
        location: {
            subject: "地點",
            holder: "請輸入地點"
        },
        note: {
            subject: "備註",
            holder: "請輸入備註"
        },
        description: {
            subject: "其他資訊",
            holder: "請輸入其他資訊"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    handler: {
        submit: {
            subject: "即將儲存內容",
            text: "請確認後執行"
        }
    },
    submit: {
        membership: {
            subject: "編輯名單"
        },
        cycle: {
            subject: "預覽排程"
        }
    },
    error: {
        1010: {
            "subject": "請輸入名稱",
            "text": "",
            "description": ""
        },
        1020: {
            "subject": "請選擇開始時間",
            "text": "",
            "description": ""
        },
        1030: {
            "subject": "請選擇結束時間",
            "text": ""
        },
        1031: {
            "subject": "結束時間必須大於開始時間",
            "text": ""
        },
        1040: {
            "subject": "請輸入說明",
            "text": ""
        },
        1050: {
            "subject": "請選擇狀態",
            "text": ""
        },
        2010: {
            "subject": "請至少選擇1位會友參與服事",
            "text": ""
        },
        3010: {
            "subject": "請完善提醒的參數設定",
            "text": ""
        },
        4010: {
            "subject": "請完善循環的參數設定",
            "text": ""
        }
    }

}