export default {
    column: {
        on: "已开启",
        off: "未开启"
    },
    editor: {
        help: {
            total: {
                on: `最多只能选择 <b class="ft-16">%s</b> 个选项, 还可以选择 <b class="ft-16">%s</b> 个选项`,
                off: `已达到选项上限 <b class="ft-16">%s</b> 个`
            },
            add: {
                custom: "创建自定义",
                email: "创建电子邮件"
            },
            maximum: {
                custom: `创建自定义最多仅能设置 <b class="cgBase">%s</b> 笔`,
                email: `寄送邮件名单最多仅能设置 <b class="cgBase">%s</b> 笔`
            }
        },
        field: {
            regular: "常用选项",
            custom: "自定义选项",
            method: "通知方式"
        },
        holder: {
            email: "请输入电子邮件"
        },
        description: {
            zero: "活动时间点",
            minute: "%s 分钟前",
            hour: "%s 小时前",
            day: "%s 日前",
            week: "%s 周前"
        },
        error: {
            1001: {
                "subject": "请指定通知方式",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请至少选择一名收件人",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入邮件地址",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "邮件格式错误",
                "text": "",
                "description": ""
            }
        }
    }
}
