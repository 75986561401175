<template>
  <span class="f6">
    <span
        @dblclick="emit(`dbclick`)"
        class="badge u"
        :style="getStyle"
        :class="props.appearance.concat([
            ( props.maxWidth ?? 0 ) > 0 ? `dr5` : ``,
            props.selection ? null : `not-selection`
        ])">
      {{getText}}
      <slot name="append" />
    </span>
    <span v-if="props.quantity > 0"
          class="f7 ft-11 badge badge-glow bg-danger"
          v-html="getNumberFormat(props.quantity)" />
  </span>
</template>

<script setup lang="ts">

import {useUtils} from "@/hook/useUtils"

import {computed,
  StyleValue} from "vue"

const {
  getNumberFormat
} = useUtils()

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "dbclick"): void
}>()

const props = withDefaults(defineProps<{
  //外觀
  appearance: any[]
  //文字
  text: string | number | null
  //顯示角標
  quantity?: number
  //是否可框選
  selection?: boolean
  //最大寬度
  maxWidth?: number | null
}>(),{
  appearance: () => [],
  text: null,
  selection: true,
  quantity: -1,
  maxWidth: 0
})

const getText = computed((): string | number => {
  return props.text === null ? `?` : props.text
})

const getStyle = computed((): StyleValue => {
  if (props.maxWidth == null) return {}
  else if (props.maxWidth > 0) return {
    maxWidth: `${props.maxWidth}px`
  }
  return {}
})

</script>

<style scoped lang="sass">
.badge
  &.u
    margin: 3px
    font-weight: 400
    cursor: pointer
    &.dr5
      overflow : hidden
      text-overflow : ellipsis
      white-space : nowrap
    &.with-quantity
      height: 30px
      line-height: 18px
    &.not-selection
      user-select: none
    &.badge-sm
      font-size: 12px
    &.badge-md
      font-size: 14px
    &.badge-lg
      font-size: 16px
.f6
  position: relative

  .f96
    min-width: 22px
    border-radius: 22px

</style>
