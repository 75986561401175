import {RouteRecordRaw} from "vue-router";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {PermissionEnum} from "@/v2/enumerate/permission";

const v2: boolean = (import.meta.env.VITE_VERSION as string) === 'v2'

const event: RouteRecordRaw[] = [{
	//服事 - 管理
	path: "events",
	name: RouteNameEnum.ADMIN_EVENT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 10001,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_EVENT,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.index',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/admin/event/index.vue" : "@/page/content/admin/event/index.vue"),
}, {
	//服事 - 新增
	path: "events/create",
	name: RouteNameEnum.ADMIN_EVENT_CREATE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			block: true
		},
		permission: 10002,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_EVENT,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.index',
			value: RouteNameEnum.ADMIN_EVENT
		}, {
			key: 'admin.event.create',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/admin/event/create/index.vue" : "@/page/content/admin/event/create.vue"),
}, {
	//服事 - 編輯
	path: "events/edit/:uuid",
	name: RouteNameEnum.ADMIN_EVENT_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 10004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_EVENT,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.index',
			value: RouteNameEnum.ADMIN_EVENT
		}, {
			key: 'admin.event.edit',
			value: null
		}]
	},
	// 🎉🎉 切入v2需要添加(範例) 🎉🎉
	component: () => import(v2 ? "@/v2/page/content/core/admin/event/edit/index.vue" : "@/page/content/admin/event/edit.vue"),
}, {
	//服事 - 群組 - 管理
	path: "events/team",
	name: RouteNameEnum.ADMIN_EVENT_TEAM,
	meta: {
		permission: 10011,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.team.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/event/team/index.vue"),
}, {
	//服事 - 群組 - 新增
	path: "events/team/create",
	name: RouteNameEnum.ADMIN_EVENT_TEAM_CREATE,
	meta: {
		permission: 10012,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.team.index',
			value: RouteNameEnum.ADMIN_EVENT_TEAM
		}, {
			key: 'admin.event.team.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/event/team/create.vue"),
}, {
	//服事 - 群組 - 編輯
	path: "events/team/edit/:uuid",
	name: RouteNameEnum.ADMIN_EVENT_TEAM_EDIT,
	meta: {
		permission: 10014,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.team.index',
			value: RouteNameEnum.ADMIN_EVENT_TEAM
		}, {
			key: 'admin.event.team.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/event/team/edit.vue"),
}, {
	//服事 - 群組 - 編輯 - 歷史紀錄
	path: "events/team/edit/:uuid/history",
	name: RouteNameEnum.ADMIN_EVENT_TEAM_EDIT_HISTORY,
	meta: {
		permission: 10014,
		breadcrumb: [{
			key: 'admin.event.subject',
			value: null
		}, {
			key: 'admin.event.team.index',
			value: RouteNameEnum.ADMIN_EVENT_TEAM
		}, {
			key: 'admin.event.team.edit',
			value: RouteNameEnum.ADMIN_EVENT_TEAM_EDIT
		}, {
			key: 'admin.event.team.history.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/event/team/history/index.vue"),
}];

export default event;
