import {Attend} from "@/language/modules/component/shepherd/v2/attend/zhCN"

export const v2: {
    [key: string]: any
} = {

    /**
     * 聚会
     */
    attend: Attend

}
