/**
 * 活動管理
 */
export default {
    subject: "活動管理",
    index: "活動清單",
    create: "活動新增",
    edit: {
        index: "活動內容",
        register: {
            index: "後台報名"
        },
        registered: {
            index: "報名清單",
            edit: {
                index: "報名內容"
            }
        }
    },
    permission: "活動管理 - 活動清單",
    category: {
        index: "分類管理",
        create: "分類新增",
        edit: "分類內容",
        permission: "活動管理 - 分類管理",
    }
}
