/**
 * value 对应资料库的CASE值, 别乱改
 */
export default [{
    value: 1,
    label: "等待上架"
}, {
    value: 2,
    label: "已上架"
}, {
    value: 3,
    label: "已下架"
}, {
    value: 99,
    label: "全部"
}]
