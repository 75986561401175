export default {
    editor: {
        enable: {
            subject: "啟用點名選項"
        },
        multiple: {
            subject: "選項可複選"
        },
        create: {
            subject: "新增點名選項"
        },
        holder: {
            subject: "請輸入選項名稱"
        },
        error: {
            1001: {
                subject: "請至少建立1筆選項"
            },
            1002: {
                subject: "請輸入選項名稱"
            }
        }
    }
}
