export const History: {
    [key: string]: any
} = {

    field: {
        recipient: {
            subject: "收件人"
        },
        read: {
            subject: "讀取",
            value: {
                0: {
                    subject: "未讀取",
                },
                1: {
                    subject: "已讀取"
                }
            }
        },
        status: {
            subject: "狀態",
            value: {
                0: {
                    subject: "準備中",
                },
                1: {
                    subject: "已送達"
                },
                2: {
                    subject: "無法推播"
                },
                9: {
                    subject: "用戶刪除"
                }
            }
        },
        created: {
            subject: "送達時間"
        }
    },
    search: {
        read: {
            subject: "讀取",
            holder: "請選擇讀取"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    }

}
