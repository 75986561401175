import home from "@/language/modules/nav/shepherd/home/zhTW";
import friend from "@/language/modules/nav/shepherd/friend/zhTW";
import client from "@/language/modules/nav/shepherd/client/zhTW";
import team from "@/language/modules/nav/shepherd/team/zhTW";
import setting from "@/language/modules/nav/shepherd/setting/zhTW";
import attend from "@/language/modules/nav/shepherd/attend/zhTW";

/**
 * 牧養系統
 */
export default {

    /**
     * 首頁
     */
    home: home,

    /**
     * 新朋友管理
     */
    friend: friend,

    /**
     * 會友管理
     */
    client: client,

    /**
     * 族群管理
     */
    team: team,

    /**
     * 聚會管理
     */
    attend: attend,

    /**
     * 參數管理
     */
    setting: setting

}
