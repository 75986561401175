/**
 * 循环管理
 */
export default {
    index: "循环管理",
    create: "循环创建",
    edit: "循环内容",
    permission: "循环管理",
    history: {
        index: "循环纪录",
        edit: "纪录内容"
    }
}
