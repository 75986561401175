export default {
    horizontal: {
        user: {
            robot: "模拟登入中",
            account: "帐户名称",
            timezone: "所在时区",
            version: {
                ui: "UI版本",
                core: "服务版本"
            },
            websocket: {
                communication: "通讯状态",
                status: {
                    connected: "连线成功",
                    disconnect: "目前断线"
                }
            },
            flush: "释放缓存",
            handler: {
                flush: {
                    subject: "即将手动释放缓存",
                    text: "请确认后执行"
                }
            }
        },
        billboard: {
            subject: "公告栏",
            unread: `您还有 <b class="ft-14 text-danger">%s</b> 笔公告未读取`,
            segue: "前往公告栏"
        },
        invitation: {
            subject: "教会邀请码"
        }
    }
}
