/**
 * 族群管理
 */
export default {
    index: "族群管理",
    permission: "族群管理",
    attend: {
        index: "聚会查询",
    },
    statistics: {
        index: "牧养跟进",
        attend: {
            index: "聚会纪录"
        },
        event: {
            index: "服事参与"
        },
        gender: {
            index: "男女比例"
        },
        year: {
            index: "年龄分布"
        },
        deacon: {
            index: "五重执事"
        },
        education: {
            index: "教育程度"
        },
        occupation: {
            index: "职业分布"
        },
        marital: {
            index: "婚姻状况"
        },
        baptized: {
            index: "受洗人数"
        }
    }
}
