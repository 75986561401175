import home from "@/language/modules/nav/admin/home/zhCN";
import user from "@/language/modules/nav/admin/user/zhCN";
import event from "@/language/modules/nav/admin/event/zhCN";
import church from "@/language/modules/nav/admin/church/zhCN";
import client from "@/language/modules/nav/admin/client/zhCN";
import my from "@/language/modules/nav/admin/my/zhCN";
import live from "@/language/modules/nav/admin/live/zhCN";
import activity from "@/language/modules/nav/admin/activity/zhCN";
import course from "@/language/modules/nav/admin/course/zhCN";
import support from "@/language/modules/nav/admin/support/zhCN";
import question from "@/language/modules/nav/admin/question/zhCN";
import teach from "@/language/modules/nav/admin/teach/zhCN";
import cycle from "@/language/modules/nav/admin/cycle/zhCN";
import donate from "@/language/modules/nav/admin/donate/zhCN";
import soul from "@/language/modules/nav/admin/soul/zhCN";
import daily from "@/language/modules/nav/admin/daily/zhCN";
import gallery from "@/language/modules/nav/admin/gallery/zhCN";
import notification from "@/language/modules/nav/admin/notification/zhCN";
import billboard from "@/language/modules/nav/admin/billboard/zhCN";
import personal from "@/language/modules/nav/admin/personal/zhCN";

/**
 * 我的教会
 */
export default {

    /**
     * 首页
     */
    home: home,

    /**
     * 公告栏
     */
    billboard: billboard,

    /**
     * 管理员管理
     */
    user: user,

    /**
     * 教会管理
     */
    church: church,

    /**
     * 会友管理
     */
    client: client,

    /**
     * 我的教会文章管理
     */
    my: my,

    /**
     * 每日灵粮文章管理
     */
    daily: daily,

    /**
     * 心灵捕手文章管理
     */
    soul: soul,

    /**
     * 图库管理
     */
    gallery: gallery,

    /**
     * 推播管理
     */
    notification: notification,

    /**
     * 行事历管理
     */
    event: event,

    /**
     * 奉献管理
     */
    donate: donate,

    /**
     * 循环管理
     */
    cycle: cycle,

    /**
     * 教学管理
     */
    teach: teach,

    /**
     * 问题集管理
     */
    question: question,

    /**
     * 客服管理
     */
    support: support,

    /**
     * 历程管理
     */
    course: course,

    /**
     * 活动管理
     */
    activity: activity,

    /**
     * 直播管理
     */
    live: live,

    /**
     * 个人资讯
     */
    personal: personal

}
