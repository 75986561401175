export default {

    selector: {
        help: {
            tag: {
                subject: "总教会仅有全部与管理员名单"
            }
        }
    }

}
