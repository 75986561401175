export default {
    editor: {
        subject: `第 <b class="ft-20 text-danger">%s</b> 場`,
        canvas: {
            echelon: {
                create: {
                    subject: "新增場次",
                }
            }
        },
        help: {
            add: {
                custom: "新增場次"
            },
            message: "請依照升序方式編輯或刪除, 避免日期設定錯誤"
        },
        field: {
            subject: "場次名稱",
            begin: "開始時間",
            end: "結束時間",
            description: "描述",
            every: {
                subject: `第 <b class="m-l-5 m-r-5 ft-28">%s</b> 場`,
                text: "%s 至 %s"
            }
        },
        holder: {
            subject: "請輸入課程名稱",
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            description: "請輸入描述"
        },
        handler: {
            remove: {
                subject: "即將刪除場次",
                text: "請確認後執行"
            }
        },
        submit: {
            up: "上移",
            down: "下移",
            edit: "編輯",
            remove: "刪除"
        },
        error: {
            1001: {
                "subject": "請輸入場次名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            }
        }
    }
}
