/**
 * 枚舉
 * SweetAlert
 * @author J
 * @since 2024-04-30 07:34:25
 */

/**
 * 類型
 */
export enum SweetAlertEnum {

    /**
     * 顯示
     */
    SUCCESS,

    /**
     * 警告
     */
    WARNING,

    /**
     * 警告 - 單按鈕
     */
    WARNING_ONE,

    /**
     * 詢問
     */
    QUESTION

}

/**
 * 圖示
 */
export enum SweetAlertIconEnum {

    /**
     * 不顯示
     */
    NONE = "",

    /**
     * 成功
     */
    SUCCESS = "success",

    /**
     * 警告
     * handler
     */
    WARNING = "warning",

    /**
     * 提問
     * handler
     */
    QUESTION = "question"

}