/**
 * 本地資料庫
 * 設定
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {defineStore} from "pinia"
import {PiniaEnum} from "@/v2/enumerate/pinia";
import PiniaPersistConfiguration from "@/v2/configuration/pinia";
import {SettingFormTableOpenEnum, SettingLoginEnum, SettingThemeEnum} from "@/v2/enumerate/setting";
import {AdminChurchCaption} from "@/v2/api/interface/core/admin/church/caption";

export const SettingStore = defineStore({
    id: PiniaEnum.SETTING,
    state: (): {
        menu: {
            collapsed: {
                enable: boolean
            },
            overlay: {
                enable: boolean
            },
            caption: AdminChurchCaption
        }
        theme: SettingThemeEnum,
        region: string,
        authorized: SettingLoginEnum,
        form: {
            edit: {
                open: SettingFormTableOpenEnum
            }
        }
    } => ({
        menu: {
            collapsed: {
                enable: false
            },
            overlay: {
                enable: false
            },
            caption: {
                my: "",
                soul: "",
                daily: ""
            }
        },
        theme: SettingThemeEnum.LIGHT,
        region: "zhTW",
        authorized: SettingLoginEnum.PASSWD,
        form: {
            edit: {
                open: SettingFormTableOpenEnum.SELF
            }
        }
    }),
    getters: {
        isMenuCollapsed: state => {
            return state.menu.collapsed.enable
        },
        isMenuOverlay: state => {
            return state.menu.overlay.enable
        },
        getMenuCaption: state => {
            return state.menu.caption
        },
        isDarkTheme: state => {
            return state.theme === SettingThemeEnum.DARK
        },
        getRegion: state => {
            return state.region
        },
        getAuthorized: state => {
            return state.authorized
        },
        getFormTableOpen: state => {
            return state.form.edit.open
        }
    },
    actions: {
        setMenuCollapsed(enable: boolean) {
            this.menu.collapsed.enable = enable;
        },
        setMenuOverlay(enable: boolean) {
            this.menu.overlay.enable = enable;
        },
        setMenuCaption(caption: AdminChurchCaption) {
            this.menu.caption = caption;
        },
        setTheme(theme: SettingThemeEnum) {
            this.theme = theme;
        },
        setRegion(region: string) {
            this.region = region;
        },
        setAuthorized(authorized: SettingLoginEnum) {
            this.authorized = authorized;
        },
        setFormTableOpen(open: SettingFormTableOpenEnum) {
            this.form.edit.open = open;
        }
    },
    persist: PiniaPersistConfiguration(PiniaEnum.SETTING)
})