/**
 * value 對應資料庫的CASE值, 別亂改
 */
export default [{
    value: 1,
    label: "等待上架"
}, {
    value: 2,
    label: "已上架"
}, {
    value: 3,
    label: "已下架"
}, {
    value: 99,
    label: "全部"
}]
