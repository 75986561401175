export const Schedule: {
    [key: string]: any
} = {

    subject: "排程預覽",

    th: {
        0: {
            subject: "#"
        },
        1: {
            subject: "開始時間"
        },
        2: {
            subject: "結束時間"
        }
    },

    footer: {

        summary: {
            subject: `合計 <b class="ft-16 text-base">%s</b> 次循環排程`
        },

    }

}
