import {Membership} from "@/language/modules/page/admin/event/v2/edit/membership/zhCN";

export const Edit: {
    [key: string]: any
} = {

    membership: Membership,

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "名单"
        },
        2 : {
            subject: "提醒"
        },
        3 : {
            subject: "循环"
        },
        99: {
            subject: "相关资讯",
        }
    },
    field: {
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        begin: {
            subject: "开始时间",
            holder: "请选择开始时间"
        },
        end: {
            subject: "结束时间",
            holder: "请选择结束时间"
        },
        content: {
            subject: "说明",
            holder: "请输入说明"
        },
        location: {
            subject: "地点",
            holder: "请输入地点"
        },
        note: {
            subject: "备注",
            holder: "请输入备注"
        },
        description: {
            subject: "其他资讯",
            holder: "请输入其他资讯"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    alert: {
        readonly: {
            subject: "目前服事处于仅读取状态无法刷新"
        },
        disable: {
            subject: "目前服事处于停用状态"
        }
    },
    handler: {
        submit: {
            subject: "即将更新内容",
            text: "请确认后执行"
        }
    },
    submit: {
        membership: {
            subject: "编辑名单"
        },
        cycle: {
            subject: "预览排程"
        }
    },
    error: {
        1010: {
            "subject": "请输入名称",
            "text": "",
            "description": ""
        },
        1020: {
            "subject": "请选择开始时间",
            "text": "",
            "description": ""
        },
        1030: {
            "subject": "请选择结束时间",
            "text": ""
        },
        1031: {
            "subject": "结束时间必须大于开始时间",
            "text": ""
        },
        1040: {
            "subject": "请输入说明",
            "text": ""
        },
        1050: {
            "subject": "请选择状态",
            "text": ""
        },
        2010: {
            "subject": "请至少选择1位会友参与服事",
            "text": ""
        },
        3010: {
            "subject": "请完善提醒的参数设置",
            "text": ""
        },
        4010: {
            "subject": "请完善循环的参数设置",
            "text": ""
        }
    }

}