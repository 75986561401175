<template>
  <div class="content-header row">
    <div class="content-header-left col-12 mb-2">
      <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li v-for="(j, i) in getEntry"
                  class="breadcrumb-item">
                <span v-if="i === getEntry.length - 1 && i > 0"
                      v-html="getSubject(j)" />
                <router-link
                    v-else-if=" j.value !== null "
                    :to="Object.assign({
                        'name': j.value
                    }, getQuery(j))"
                    v-html="getSubject(j)" />
                <a v-else
                   v-html="getSubject(j)" />
              </li>
            </ol>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {sprintf} from "sprintf-js"

import {computed} from "vue"

import {useRoute} from "vue-router"

import {useI18n} from "vue-i18n"
import {useMenu} from "@/hook/useMenu"

interface RouteMetaRaw {
  key: string
  value: any
}

const route = useRoute()

const {
  t
} = useI18n()

const {
  getCaption
} = useMenu()

const getEntry = computed((): RouteMetaRaw[]=>{
  const d = route.meta.portal
  const breadcrumb: unknown = route.meta.breadcrumb
  return [{
    key: `${ d }.home.index`,
    value: `${ d === 'admin' ? `Admin` : `Shepherd` }Dashboard`
  }].concat(breadcrumb as RouteMetaRaw[])
})

/**
 * 轉換GET參數
 */
const getQuery = computed((): (e: RouteMetaRaw) => any => {
  return (e: RouteMetaRaw): any => {

    if ((route.name as string).startsWith("ShepherdTeam") && (e.value as string).startsWith("ShepherdTeam")) {

      const r: any = {}

      if (route.query.c) r.c = route.query.c
      if (route.query.p) r.q = route.query.p

      return {
        query: r
      }

    }

    return {}

  }
})

const getSubject = computed((): (e: RouteMetaRaw) => string => {
  return (e: RouteMetaRaw): string => {
    //MY
    if (e.key === 'admin.my.index') return sprintf(
        `%s%s`,
        getCaption.value.my,
        t(`component.breadcrumb.index.index`)
    )
    else if (e.key === 'admin.my.create') return t(`component.breadcrumb.index.create`)
    else if (e.key === 'admin.my.edit') return t(`component.breadcrumb.index.edit`)
    //SOUL
    else if (['admin.soul.subject', 'admin.soul.index'].includes(e.key)) return sprintf(
        `%s%s`,
        getCaption.value.soul,
        t(`component.breadcrumb.index.index`)
    )
    else if (e.key === 'admin.soul.create') return t(`component.breadcrumb.index.create`)
    else if (e.key === 'admin.soul.edit') return t(`component.breadcrumb.index.edit`)
    //SOUL-CATEGORY
    else if (e.key === 'admin.soul.category.index') return sprintf(
        `%s%s`,
        getCaption.value.soul,
        t(`component.breadcrumb.category.index`)
    )
    else if (e.key === 'admin.soul.category.create') return t(`component.breadcrumb.category.create`)
    else if (e.key === 'admin.soul.category.edit') return t(`component.breadcrumb.category.edit`)
    //DAILY
    else if (e.key === 'admin.daily.index') return sprintf(
        `%s%s`,
        getCaption.value.daily,
        t(`component.breadcrumb.index.index`)
    )
    else if (e.key === 'admin.daily.create') return t(`component.breadcrumb.index.create`)
    else if (e.key === 'admin.daily.edit') return t(`component.breadcrumb.index.edit`)
    return t(`nav.${e.key}`)
  }
})

</script>

<style scoped lang="sass">

@import '@/style/variables.sass'

.breadcrumb
  font-size: 1.1rem
  > li
    font-weight: 400
    &.breadcrumb-item
      &::before
        position: relative
        top: .1rem
    > a
      color: map-get($theme-color, "primary") !important
      font-weight: 500

</style>
