/**
 * 循環管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增循環"
            }
        },
        field: {
            ref: "類型",
            subject: "名稱",
            period: "週期"
        },
        holder: {
            subject: "請輸入名稱"
        },
        handler: {
            enable: {
                subject: "即將啟用循環",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用循環",
                text: "請確認後執行",
                description: ""
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "循環"
            }
        },
        tip: {
            create: "建立後類型與目標無法修改, 請確認"
        },
        help: {
            church: "請謹慎使用指定教會功能",
            live: {
                subject: "點擊查看說明",
                text: "部分文章無法設定循環"
            }
        },
        field: {
            church: "指定教會",
            ref: "種類",
            kind: "類型",
            subject: "名稱",
            selection: {
                article: {
                    main: "主分類",
                    sub: "次分類"
                }
            },
            item: {
                subject: "標題",
                begin: {
                    article: "上架時間",
                    notification: "排程時間",
                    other: "開始時間"
                },
                end: {
                    article: "下架時間",
                    other: "結束時間"
                }
            },
            target: "項目"
        },
        holder: {
            church: "請選擇指定教會",
            kind: "請選擇類型",
            subject: "請輸入名稱",
            selection: {
                article: {
                    main: "請選擇主分類",
                    sub: "請選擇次分類"
                }
            },
            item: {
                subject: "未填寫標題",
                begin: {
                    article: "未選擇上架時間",
                    notification: "未選擇排程時間",
                    other: "未選擇開始時間"
                },
                end: {
                    article: "未選擇下架時間",
                    other: "未選擇結束時間"
                }
            },
            target: "請選擇項目"
        },
        handler: {
            store: {
                subject: "即將新增循環",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇類型",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇主分類",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇次分類",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇項目",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            disabled: "請注意, 這筆循環已經停用",
            create: "建立後類型與目標無法修改"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "循環"
            },
            2: {
                subject: "歷程紀錄"
            },
            99: {
                subject: "相關資訊"
            },
        },
        summary: {
            history: {
                subject: "循環紀錄"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            kind: "類型",
            subject: "名稱",
            selection: {
                article: {
                    main: "主分類",
                    sub: "次分類"
                }
            },
            item: {
                subject: "標題",
                begin: {
                    article: "上架時間",
                    notification: "排程時間",
                    other: "開始時間"
                },
                end: {
                    article: "下架時間",
                    other: "結束時間"
                }
            },
            target: "項目"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            kind: "請選擇類型",
            subject: "請輸入名稱",
            selection: {
                article: {
                    main: "請選擇主分類",
                    sub: "請選擇次分類"
                }
            },
            item: {
                subject: "未填寫標題",
                begin: {
                    article: "未選擇上架時間",
                    notification: "未選擇排程時間",
                    other: "未選擇開始時間"
                },
                end: {
                    article: "未選擇下架時間",
                    other: "未選擇結束時間"
                }
            },
            target: "請選擇項目"
        },
        handler: {
            update: {
                subject: "即將更新循環內容",
                text: "請確認後執行",
                description: ""
            }
        }
    }

}


