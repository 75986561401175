export const Editor: {
    [key: string]: any
} = {

    subject: "選擇會友參與服事",

    submit: {
        maximum: {
            subject: "最多只能選擇%s位會友參與服事"
        },
        minimum: {
            subject: "請選擇至少%s位會友參與服事"
        }
    }

}