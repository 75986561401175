import {Membership} from "@/language/modules/page/admin/notification/v2/create/membership/zhTW";

export const Create: {
    [key: string]: any
} = {

    membership: Membership,

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "名單"
        }
    },
    field: {
        type: {
            subject: "類型",
            holder: "請選擇類型",
            help: "類型於建立之後不能變更"
        },
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        begin: {
            subject: "排程時間",
            holder: "請選擇排程時間"
        },
        content: {
            subject: "簡述",
            holder: "請輸入簡述"
        },
        description: {
            subject: "內容",
            holder: "請輸入內容"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    statistics: {
        history: {
            subject: "推播紀錄"
        }
    },
    alert: {
        0: {
            subject: `推播目前正在排程中`
        },
        1: {
            subject: `目前推播正在進行中無法修改, 進度 %s`
        },
        2: {
            subject: "推播已經全部完成無法修改"
        }
    },
    handler: {
        submit: {
            subject: "即將儲存內容",
            text: "請確認後執行"
        }
    },
    submit: {
        membership: {
            subject: "編輯名單"
        },
        cycle: {
            subject: "預覽排程"
        }
    },
    error: {
        1010: {
            "subject": "請選擇類型",
            "text": ""
        },
        1020: {
            "subject": "請選擇指定教會",
            "text": ""
        },
        1030: {
            "subject": "請選擇排除機構",
            "text": ""
        },
        1031: {
            "subject": "不能排除所有機構",
            "text": ""
        },
        1040: {
            "subject": "請輸入標題",
            "text": ""
        },
        1050: {
            "subject": "請選擇開始時間",
            "text": ""
        },
        1051: {
            "subject": "排程時間必須大於現在時間",
            "text": ""
        },
        1060: {
            "subject": "請輸入簡述",
            "text": ""
        },
        1070: {
            "subject": "請輸入內容",
            "text": ""
        },
        2010: {
            "subject": "請至少選擇1位會友進行推播",
            "text": ""
        }
    }

}