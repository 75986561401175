import billboard from "@/language/modules/page/admin/billboard/zhTW";
import dashboard from "@/language/modules/page/admin/dashboard/zhTW";
import user from "@/language/modules/page/admin/user/zhTW";
import church from "@/language/modules/page/admin/church/zhTW";
import client from "@/language/modules/page/admin/client/zhTW";
import cycle from "@/language/modules/page/admin/cycle/zhTW";
import article from "@/language/modules/page/admin/article/zhTW";
import gallery from "@/language/modules/page/admin/gallery/zhTW";
import notification from "@/language/modules/page/admin/notification/zhTW";
import event from "@/language/modules/page/admin/event/zhTW";
import donate from "@/language/modules/page/admin/donate/zhTW";
import teach from "@/language/modules/page/admin/teach/zhTW";
import course from "@/language/modules/page/admin/course/zhTW";
import support from "@/language/modules/page/admin/support/zhTW";
import live from "@/language/modules/page/admin/live/zhTW";
import activity from "@/language/modules/page/admin/activity/zhTW";
import personal from "@/language/modules/page/admin/personal/zhTW";

export default {

    /**
     * 公吿欄管理
     */
    billboard: billboard,

    /**
     * 控制面板
     */
    dashboard: dashboard,

    /**
     * 管理員管理
     */
    user: user,

    /**
     * 教會管理
     */
    church: church,

    /**
     * 會友管理
     */
    client: client,

    /**
     * 循環管理
     */
    cycle: cycle,

    /**
     * 文章管理
     */
    article: article,

    /**
     * 圖庫管理
     */
    gallery: gallery,

    /**
     * 推播管理
     */
    notification: notification,

    /**
     * 行事曆管理
     */
    event: event,

    /**
     * 奉獻管理
     */
    donate: donate,

    /**
     * 教學管理
     */
    teach: teach,

    /**
     * 歷程管理
     */
    course: course,

    /**
     * 客服管理
     */
    support: support,

    /**
     * 直播管理
     */
    live: live,

    /**
     * 活動管理
     */
    activity: activity,

    /**
     * 個人
     */
    personal: personal

}
