import {Create} from "@/language/modules/page/admin/support/v2/worker/create/zhCN"
import {Edit} from "@/language/modules/page/admin/support/v2/worker/edit/zhCN"

export const Worker: {
    [key: string]: any
} = {

    /**
     * 创建
     */
    create: Create,

    /**
     * 编辑
     */
    edit: Edit,

    field: {
        role: {
            subject: "角色"
        },
        type: {
            subject: "类型"
        },
        no: {
            subject: "单号"
        },
        subject: {
            subject: "标题"
        },
        summary: {
            account: {
                subject: "异常帐户"
            },
            attachment: {
                subject: "附件"
            },
            history: {
                subject: "历史纪录"
            }
        },
        expire: {
            subject: "超时"
        },
        back: {
            subject: "返工"
        },
        status: {
            subject: "状态"
        },
        created: {
            subject: "建立时间"
        }
    },
    search: {
        role: {
            subject: "角色",
            holder: "请输入角色"
        },
        type: {
            subject: "类型",
            holder: "请输入类型"
        },
        no: {
            subject: "单号",
            holder: "请输入单号"
        },
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            help: "同时搜索标题与描述"
        },
        begin: {
            subject: "开始时间(起)",
            holder: "请选择开始时间"
        },
        end: {
            subject: "结束时间(迄)",
            holder: "请选择结束时间"
        },
        creator: {
            subject: "建立人",
            holder: "请选择建立人"
        },
        editor: {
            subject: "上一次编辑人",
            holder: "请选择上一次编辑人"
        },
        expire: {
            subject: "超时",
            holder: "请选择超时"
        },
        back: {
            subject: "返工",
            holder: "请选择返工"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    }

}
