<template>
  <li class="nav-item dropdown dropdown-language">
    <el-dropdown placement="bottom-end">
      <a class="d-flex align-items-center nav-link">
        <country-flag :iso="getCurrentRegion.id" />
        <span class="m-l-10 selected-language"
              v-html="getCurrentRegion.value" />
      </a>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
              @click="setRegion(j.id)"
              v-for="j in getAvailableRegion">
            <a class="d-flex align-items-center">
              <country-flag :iso="j.id" />
              <span class="m-l-10 selected-language"
                    v-html="j.value" />
            </a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </li>
</template>

<script setup lang="ts">

import CountryFlag from "@/component/CountryFlag.vue"
import {useRegion} from "@/hook/useRegion"

const {
  setRegion,
  getCurrentRegion,
  getAvailableRegion
} = useRegion()

</script>

<style scoped>

</style>
