import {Edit} from "@/language/modules/page/admin/notification/v2/edit/automatic/edit/zhTW";

export const Automatic: {
    [key: string]: any
} = {

    edit: Edit,

    field: {
        recipient: {
            subject: "收件人"
        },
        id: {
            subject: "識別ID"
        },
        supplier: {
            subject: "支援廠商",
            value: {
                1: {
                    subject: "APNS",
                },
                2: {
                    subject: "FCM"
                }
            }
        },
        status: {
            subject: "狀態",
            value: {
                0: {
                    subject: "未對應",
                },
                1: {
                    subject: "已對應"
                }
            }
        },
        created: {
            subject: "建立時間"
        },
        stamp: {
            subject: "執行時間"
        },
        received: {
            subject: "生成時間"
        }
    },
    search: {
        supplier: {
            subject: "推播類型",
            holder: "請選擇推播類型"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    }

}
