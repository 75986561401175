export default {
    tabs: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "團報"
        },
        2: {
            subject: "表單"
        },
        99: {
            subject: "相關資訊"
        }
    },
    tip: {
        organization: {
            subject: `團體報名人數最少 <b class="ft-16">%s</b> 位, 最多 <b class="ft-16">%s</b> 位`,
            left: {
                subject: `, 還需要 <b class="ft-16">%s</b> 位報名`
            },
            satisfy: {
                minimum: {
                    subject: `, 最多還可以報名 <b class="ft-16">%s</b> 位`
                },
                negative: {
                    subject: `, 已經達到活動報名人數上限`
                },
                maximum: {
                    subject: `, 已達到團報最高人數限制`
                }
            }
        },
        summary: {
            subject: "報名總覽"
        }
    },
    form: {
        empty: "請先增加團體報名人員",
        rank: `合計 <b class="ft-16 text-success">%s</b> 位報名`,
        th: {
            0: "#",
            1: "姓名",
            2: "電子郵件",
            3: "操作"
        },
        field: {
            name: "姓名",
            email: "電子郵件"
        }
    },
    discount: {
        original: "原價",
        before: "%s 之前",
        between: "%s 至 %s"
    },
    canvas: {
        create: {
            subject: "新增團報人員"
        },
        edit: {
            subject: "編輯團報人員"
        }
    },
    field: {
        method: "報名方式",
        discount: "費用",
        echelon: "活動場次",
        payment: "支付狀態",
        organization: {
            name: "教會(機構)名稱",
            city: "教會(機構)所在城市",
            contact: {
                name: "聯絡人姓名",
                phone: "聯絡人電話",
                email: "聯絡人電子郵件"
            },
            zip: "郵遞區號",
            address: "通訊地址"
        },
    },
    holder: {
        organization: {
            name: "請輸入教會(機構)名稱",
            city: "請輸入教會(機構)所在城市",
            contact: {
                name: "請輸入聯絡人姓名",
                phone: "請輸入聯絡人電話",
                email: "請輸入聯絡人電子郵件"
            },
            zip: "請輸入郵遞區號",
            address: "請輸入通訊地址"
        }
    },
    handler: {
        store: {
            subject: "即將儲存後台報名",
            text: "請確認後執行",
            description: ""
        }
    },
    error: {
        1001: {
            "subject": "請選擇報名方式",
            "text": "",
            "description": ""
        },
        1002: {
            "subject": "異常發生",
            "text": "指定的報名方式不存在",
            "description": ""
        },
        1003: {
            "subject": "請選擇活動場次",
            "text": "",
            "description": ""
        },
        1004: {
            "subject": "異常發生",
            "text": "指定的活動場次不存在",
            "description": ""
        },
        1005: {
            "subject": "異常發生",
            "text": "指定的價格不存在",
            "description": ""
        },
        1006: {
            "subject": "請選擇支付狀態",
            "text": "",
            "description": ""
        },
        1007: {
            "subject": "異常發生",
            "text": "指定的付款狀態不存在",
            "description": ""
        },
        1998: {
            "subject": "總教會不能進行後台報名",
            "text": "即將返回報名內容頁面",
            "description": ""
        },
        1999: {
            "subject": "活動報名已經額滿",
            "text": "即將返回報名內容頁面",
            "description": ""
        },
        3001: {
            "subject": "請完善表單內容",
            "text": "",
            "description": ""
        },
        2001: {
            "subject": "請輸入教會(機構)名稱",
            "text": "",
            "description": ""
        },
        2002: {
            "subject": "請輸入教會(機構)所在城市",
            "text": "",
            "description": ""
        },
        2003: {
            "subject": "請輸入聯絡人姓名",
            "text": "",
            "description": ""
        },
        2004: {
            "subject": "請輸入聯絡人電話",
            "text": "",
            "description": ""
        },
        2005: {
            "subject": "請輸入聯絡人電子郵件",
            "text": "",
            "description": ""
        },
        2006: {
            "subject": "聯絡人電子郵件格式錯誤",
            "text": "",
            "description": ""
        },
        2007: {
            "subject": "請輸入郵遞區號",
            "text": "",
            "description": ""
        },
        2008: {
            "subject": "請輸入通訊地址",
            "text": "",
            "description": ""
        }
    }
}
