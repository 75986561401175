export const Group: {
    [key: string]: any
} = {
    selected: {
        subject: "已選擇族群",
        empty: {
            subject: "請選擇族群"
        },
        remove: {
            subject: "雙擊標籤快速移除",
            all: {
                subject: "清空",
            }
        }
    },
    handler: {
        clear: {
            subject: "即將清空已選擇族群",
            text: "請確認後執行",
        }
    }
}
