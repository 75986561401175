export default {
    integrate: {
        enable: {
            subject: "已啟用"
        }
    },
    preview: {
        publish: "發布於 %s．%s 人次瀏覽",
        empty: "請輸入文章內容",
        footer: {
            favorite: "收藏",
            size: "字級",
            comment: "前往留言"
        }
    },
    mindcatcher: {
        authorize: `分享文章由 <b class="text-black">%s</b> 授權使用`
    },
    type: {
        name: {
            1: "圖文",
            2: "影音",
            3: "MP3",
            4: "直播",
            5: "WEB",
        },
        disabled: "這個選項暫時無法使用",
        tip: {
            live: {
                disabled: "次分類為活動, 將無法使用直播類型"
            }
        }
    },
    schedule: [
        "排程中",
        "上架中",
        "已下架"
    ]
}
