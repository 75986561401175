/**
 * 枚舉
 * 路由名稱
 * @author J
 * @since 2024-04-30 07:34:25
 */

export enum RouteNameEnum {

    /**
     * 我的教會 - 登入後主頁
     */
    ADMIN = "AdminContent",

    /**
     * 我的教會 - 面板
     */
    ADMIN_DASHBOARD = "AdminDashboard",

    /**
     * 我的教會 - 我的教會
     */
    ADMIN_MY = "AdminMy",

    /**
     * 我的教會 - 我的教會 - 新增
     */
    ADMIN_MY_CREATE = "AdminMyCreate",

    /**
     * 我的教會 - 我的教會 - 內容
     */
    ADMIN_MY_EDIT = "AdminMyEdit",

    /**
     * 我的教會 - 我的教會 - 複製
     */
    ADMIN_MY_COPY = "AdminMyCopy",

    /**
     * 我的教會 - 我的教會 - 留言
     */
    ADMIN_MY_COMMENT = "AdminMyComment",

    /**
     * 我的教會 - 我的教會 - 推播
     */
    ADMIN_MY_NOTIFICATION = "AdminMyNotification",

    /**
     * 我的教會 - 我的教會 - 推播 - 建立
     */
    ADMIN_MY_NOTIFICATION_CREATE = "AdminMyNotificationCreate",

    /**
     * 我的教會 - 我的教會 - 推播 - 內容
     */
    ADMIN_MY_NOTIFICATION_EDIT = "AdminMyNotificationEdit",

    /**
     * 我的教會 - 推播通知
     */
    ADMIN_NOTIFICATION = "AdminNotification",

    /**
     * 我的教會 - 推播通知 - 建立
     */
    ADMIN_NOTIFICATION_CREATE = "AdminNotificationCreate",

    /**
     * 我的教會 - 推播通知 - 內容
     */
    ADMIN_NOTIFICATION_EDIT = "AdminNotificationEdit",

    /**
     * 我的教會 - 推播通知 - 內容 - 歷史紀錄
     */
    ADMIN_NOTIFICATION_EDIT_HISTORY = "AdminNotificationEditHistory",

    /**
     * 我的教會 - 推播通知 - 內容 - 自動化紀錄
     */
    ADMIN_NOTIFICATION_EDIT_AUTOMATIC = "AdminNotificationEditAutomatic",

    /**
     * 我的教會 - 推播通知 - 內容 - 自動化紀錄 - 內容
     */
    ADMIN_NOTIFICATION_EDIT_AUTOMATIC_EDIT = "AdminNotificationEditAutomaticEdit",

    /**
     * 我的教會 - 心靈捕手
     */
    ADMIN_SOUL = "AdminSoul",

    /**
     * 我的教會 - 心靈捕手 - 新增
     */
    ADMIN_SOUL_CREATE = "AdminSoulCreate",

    /**
     * 我的教會 - 心靈捕手 - 內容
     */
    ADMIN_SOUL_EDIT = "AdminSoulEdit",

    /**
     * 我的教會 - 心靈捕手 - 複製
     */
    ADMIN_SOUL_COPY = "AdminSoulCopy",

    /**
     * 我的教會 - 心靈捕手 - 留言
     */
    ADMIN_SOUL_COMMENT = "AdminSoulComment",

    /**
     * 我的教會 - 心靈捕手 - 推播
     */
    ADMIN_SOUL_NOTIFICATION = "AdminSoulNotification",

    /**
     * 我的教會 - 心靈捕手 - 推播 - 建立
     */
    ADMIN_SOUL_NOTIFICATION_CREATE = "AdminSoulNotificationCreate",

    /**
     * 我的教會 - 心靈捕手 - 推播 - 內容
     */
    ADMIN_SOUL_NOTIFICATION_EDIT = "AdminSoulNotificationEdit",

    /**
     * 我的教會 - 心靈捕手 - 分類
     */
    ADMIN_SOUL_CATEGORY = "AdminSoulCategory",

    /**
     * 我的教會 - 心靈捕手 - 分類 - 新增
     */
    ADMIN_SOUL_CATEGORY_CREATE = "AdminSoulCategoryCreate",

    /**
     * 我的教會 - 心靈捕手 - 分類 - 內容
     */
    ADMIN_SOUL_CATEGORY_EDIT = "AdminSoulCategoryEdit",

    /**
     * 我的教會 - 每日靈糧
     */
    ADMIN_DAILY = "AdminDaily",

    /**
     * 我的教會 - 每日靈糧 - 新增
     */
    ADMIN_DAILY_CREATE = "AdminDailyCreate",

    /**
     * 我的教會 - 每日靈糧 - 內容
     */
    ADMIN_DAILY_EDIT = "AdminDailyEdit",

    /**
     * 我的教會 - 每日靈糧 - 複製
     */
    ADMIN_DAILY_COPY = "AdminDailyCopy",

    /**
     * 我的教會 - 每日靈糧 - 留言
     */
    ADMIN_DAILY_COMMENT = "AdminDailyComment",

    /**
     * 我的教會 - 每日靈糧 - 推播
     */
    ADMIN_DAILY_NOTIFICATION = "AdminDailyNotification",

    /**
     * 我的教會 - 每日靈糧 - 推播 - 建立
     */
    ADMIN_DAILY_NOTIFICATION_CREATE = "AdminDailyNotificationCreate",

    /**
     * 我的教會 - 每日靈糧 - 推播 - 內容
     */
    ADMIN_DAILY_NOTIFICATION_EDIT = "AdminDailyNotificationEdit",

    /**
     * 我的教會 - 服事
     */
    ADMIN_EVENT = "AdminEvent",

    /**
     * 我的教會 - 服事 - 新增
     */
    ADMIN_EVENT_CREATE = "AdminEventCreate",

    /**
     * 我的教會 - 服事 - 內容
     */
    ADMIN_EVENT_EDIT = "AdminEventEdit",

    /**
     * 我的教會 - 服事 - 群組
     */
    ADMIN_EVENT_TEAM = "AdminEventTeam",

    /**
     * 我的教會 - 服事 - 群組 - 新增
     */
    ADMIN_EVENT_TEAM_CREATE = "AdminEventTeamCreate",

    /**
     * 我的教會 - 服事 - 群組 - 內容
     */
    ADMIN_EVENT_TEAM_EDIT = "AdminEventTeamEdit",

    /**
     * 我的教會 - 服事 - 群組 - 內容 - 紀錄
     */
    ADMIN_EVENT_TEAM_EDIT_HISTORY = "AdminEventTeamEditHistory",

    /**
     * 客服查詢 - 維修工單
     */
    ADMIN_SUPPORT_WORKER = "AdminSupportWorker",

    /**
     * 客服查詢 - 維修工單 - 新增
     */
    ADMIN_SUPPORT_WORKER_CREATE = "AdminSupportWorkerCreate",

    /**
     * 客服查詢 - 維修工單 - 編輯
     */
    ADMIN_SUPPORT_WORKER_EDIT = "AdminSupportWorkerEdit",

    /**
     * 客服查詢 - 維修工單 - 編輯 - 歷史紀錄
     */
    ADMIN_SUPPORT_WORKER_EDIT_HISTORY = "AdminSupportWorkerEditHistory",

    /**
     * 客服查詢 - 維修工單 - 編輯 - 歷史紀錄 - 建立
     */
    ADMIN_SUPPORT_WORKER_EDIT_HISTORY_CREATE = "AdminSupportWorkerEditHistoryCreate",

    /**
     * 客服查詢 - 維修工單 - 編輯 - 歷史紀錄 - 編輯
     */
    ADMIN_SUPPORT_WORKER_EDIT_HISTORY_EDIT = "AdminSupportWorkerEditHistoryEdit",

    /**
     * 客服查詢 - 維修工單 - 編輯 - 異動紀錄
     */
    ADMIN_SUPPORT_WORKER_EDIT_RECORD = "AdminSupportWorkerEditRecord",

    /**
     * 牧養系統 - 登入後主頁
     */
    SHEPHERD = "ShepherdContent",

    /**
     * 牧養系統 - 面板
     */
    SHEPHERD_DASHBOARD = "ShepherdDashboard",

    /**
     * 牧養系統 - 聚會
     */
    SHEPHERD_ATTEND = "ShepherdAttend",

    /**
     * 牧養系統 - 聚會 - 新增
     */
    SHEPHERD_ATTEND_CREATE = "ShepherdAttendCreate",

    /**
     * 牧養系統 - 聚會 - 內容
     */
    SHEPHERD_ATTEND_EDIT = "ShepherdAttendEdit",

    /**
     * 牧養系統 - 聚會 - 內容 - 排程
     */
    SHEPHERD_ATTEND_EDIT_SCHEDULE = "ShepherdAttendEditSchedule",

    /**
     * 牧養系統 - 聚會 - 內容 - 排程 - 內容
     */
    SHEPHERD_ATTEND_EDIT_SCHEDULE_EDIT = "ShepherdAttendEditScheduleEdit",

    /**
     * 牧養系統 - 聚會 - 內容 - 紀錄
     */
    SHEPHERD_ATTEND_EDIT_HISTORY = "ShepherdAttendEditHistory",

    /**
     * 牧養系統 - 聚會 - 內容 - 紀錄 - 內容
     */
    SHEPHERD_ATTEND_EDIT_HISTORY_EDIT = "ShepherdAttendEditHistoryEdit",

    /**
     * 牧養系統 - 聚會 - 分類
     */
    SHEPHERD_ATTEND_CATEGORY = "ShepherdAttendCategory",

    /**
     * 牧養系統 - 聚會 - 分類 - 新增
     */
    SHEPHERD_ATTEND_CATEGORY_CREATE = "ShepherdAttendCategoryCreate",

    /**
     * 牧養系統 - 聚會 - 分類 - 內容
     */
    SHEPHERD_ATTEND_CATEGORY_EDIT = "ShepherdAttendCategoryEdit",

    /**
     * 登入頁
     */
    LOGIN = "Login",

    /**
     * 登入頁 - index
     * 2.0後面有帶個index, 怕用戶有記在書籤裡面
     */
    LOGIN_INDEX = "LoginIndex",

    /**
     * 忘記密碼
     */
    FORGOT = "Forgot",

    /**
     * 忘記密碼 - 驗證碼
     */
    FORGOT_VERIFICATION = "ForgotVerification",

    /**
     * 忘記密碼 - 驗證碼 - 重設密碼
     */
    FORGOT_VERIFICATION_RESET = "ForgotVerificationReset",

    /**
     * 強迫綁定頁面
     */
    MAPPING = "Mapping",

    /**
     * App下載頁面
     */
    APP = "App",

    /**
     * 404頁面
     */
    NOT_FOUND = "NotFound"

}