/**
 * 个人
 */
export default {

    /**
     * 编辑
     */
    edit: {
        tip: {
            passwd: "如果不需要变更密码, 不用填写"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "密码"
            },
            99: {
                subject: "相关资讯"
            }
        },
        subject: {
            passwd: "变更密码"
        },
        field: {
            avatar: "头像",
            account: "帐户",
            name: "名字",
            email: "电子邮件",
            phone: "电话",
            passwd: {
                current: "目前当前密码",
                generate: "登入密码",
                validation: "确认登入密码"
            }
        },
        holder: {
            account: "请输入帐户",
            name: "请输入名字",
            email: "请输入电子邮件",
            phone: "请输入联络电话",
            passwd: {
                current: "请输入目前当前密码",
                generate: "请输入登入密码",
                validation: "请再次输入登入密码进行确认"
            }
        },
        handler: {
            update: {
                subject: "即将更新个人内容",
                text: "请确认后执行",
                description: "",
                passwd: {
                    subject: "即将更新个人内容",
                    text: `<b class="text-danger">刷新密码成功后将自动注销<br/>请您使用新密码登入, 请确认后执行</b>`,
                    description: ""
                }
            }
        },
        error: {
            1001: {
                subject: "请输入名字",
                text: ""
            },
            1002: {
                subject: "请输入电子邮件",
                text: ""
            },
            1003: {
                subject: "电子邮件格式错误",
                text: "请输入正确的电子邮件格式"
            },
            1004: {
                subject: "请输入电话",
                text: ""
            },
            2001: {
                subject: "请输入当前密码",
                text: ""
            },
            2002: {
                subject: "当前密码错误",
                text: ""
            },
            2003: {
                subject: "请输入登入密码",
                text: ""
            },
            2004: {
                subject: "登入密码强度不足",
                text: ""
            },
            2005: {
                subject: "请再输入登入密码进行确认",
                text: ""
            },
            2006: {
                subject: "密码确认失败",
                text: "两次密码输入不一致"
            }
        }
    }

}
