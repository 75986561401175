/**
 * 二維碼
 */
export enum QrcodeEnum {

    /**
     * 登入
     */
    LOGIN,

    /**
     * 二級驗證
     */
    FACTORY,

    /**
     * 安全性綁定
     */
    MAPPING

}

/**
 * 二維碼 - 請求類型
 */
export enum QrcodeRequestEnum {

    /**
     * 二級驗證
     */
    FACTORY = "factory",

    /**
     * 用戶綁定管理員
     */
    MAPPING = "mapping"

}

/**
 * 二維碼 - 狀態
 */
export enum QrcodeStatusEnum {

    /**
     * 已驗證
     */
    INSPECTION = 1,

    /**
     * 已授權
     */
    AUTHORIZED,

    /**
     * 超時
     */
    EXPIRE

}

/**
 * 二維碼 - 二級驗證 - 指令
 */
export enum QrcodeFactoryCommandEnum {

    /**
     * 驗證
     * UI顯示掃碼成功
     */
    INSPECTION = "factoryInspection",

    /**
     * 確認授權
     * 用戶端在App上按確認授權
     */
    AUTHORIZED = "factoryAuthorize",

    /**
     * 取消
     * 用戶端在App上取消
     */
    CANCEL = "factoryCancel"

}