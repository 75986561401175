import {computed} from "vue";
import {EnumVariantBadgeChurch} from "@/enum/variant";

/**
 * 狀態配色返回
 */
export const useBadgeVariant = () => {

    /**
     * 基本
     * 0停用, 1啟用
     */
    const getBasicStatus = computed((): (e: number) => string => {
        return (e) => {
            if (e === 0) return "badge-light-secondary"
            return "badge-light-success"
        }
    })

    /**
     * 用商數的方式顯示顏色
     */
    const getQuotient = computed((): (e: number) => string => {

        //商數有幾多少就要有幾排
        const quotient: number = 6

        return (e) => {
            if (e % quotient === 1) return "badge-light-success"
            else if (e % quotient === 2) return "badge-light-warning"
            else if (e % quotient === 3) return "badge-light-danger"
            else if (e % quotient === 4) return "badge-light-info"
            else if (e % quotient === 5) return "badge-light-dark"
            return "badge-light-base"
        }

    })

    /**
     * 內崁select轉inline badge配色
     */
    const getSelectToInline = computed((): string[] => {
        return ["badge-light-success"]
    })

    /**
     * 所屬教會內崁input轉inline badge配色
     */
    const getInputToChurchInline = computed((): (e: EnumVariantBadgeChurch) => string[] => {
        return (e) => {
            if (e === EnumVariantBadgeChurch.NO) return ["badge-light-info"]
            return ["badge-light-primary"]
        }
    })

    /**
     * 奉獻
     * 1待付款(非信用卡付款方式), 2付款成功, 3付款失敗, 4取消
     */
    const getAdminDonateStatus = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 1) return "badge-light-warning"
            else if (e === 2) return "badge-light-success"
            else if (e === 3) return "badge-light-danger"
            return "badge-light-secondary"
        }
    })

    /**
     * 歷程 - 授權 - 來源
     * 來源 1:Backend, 2:App
     */
    const getAdminCourseAuthorizeRole = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 1) return "badge-light-info"
            return "badge-light-primary"
        }
    })

    /**
     * 歷程 - 授權 - 方式
     * 方式 1:登入, 2:登出, 3:二級掃碼, 4:二級帳密
     */
    const getAdminCourseAuthorizeMethod = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 2) return "badge-light-danger"
            else if (e === 3) return "badge-light-info"
            else if (e === 4) return "badge-light-warning"
            return "badge-light-success"
        }
    })

    /**
     * 歷程 - 存取 - 來源
     * 來源 1:backend, 2:app
     */
    const getAdminCourseAccessRole = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 1) return "badge-light-info"
            return "badge-light-primary"
        }
    })

    /**
     * 歷程 - 存取 - 等級
     * 來源 1:INFO, 2:DEBUG, 3:WARNING, 4:ERROR
     */
    const getAdminCourseAccessLevel = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 2) return "badge-light-dark"
            else if (e === 3) return "badge-light-warning"
            else if (e === 4) return "badge-light-danger"
            return "badge-light-info"
        }
    })

    /**
     * 歷程 - 存取 - 存取方式
     * 來源 1:GET, 2: POST, 3:PUT, 4:DELETE, 5:OPTIONS
     */
    const getAdminCourseAccessMethod = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 1) return "badge-light-success"
            else if (e === 2) return "badge-light-primary"
            else if (e === 3) return "badge-light-warning"
            else if (e === 4) return "badge-light-danger"
            return "badge-light-info"
        }
    })

    /**
     * 歷程 - 攔截 - 來源
     * 來源 1:backend, 2:app
     */
    const getAdminCourseExceptionRole = getAdminCourseAccessRole

    /**
     * 歷程 - 攔截 - 等級
     * 來源 1:INFO, 2:DEBUG, 3:WARNING, 4:ERROR
     */
    const getAdminCourseExceptionLevel = getAdminCourseAccessLevel

    /**
     * 歷程 - 攔截 - 結果代碼
     */
    const getAdminCourseExceptionCode = computed((): (e: number) => string => {
        return (e) => {
            if (e === 200) return "badge-light-success"
            else if (e < 1000) return "badge-light-danger"
            return "badge-light-warning"
        }
    })

    /**
     * 歷程 - 攔截 - 回滾事務
     */
    const getAdminCourseExceptionRollback = computed((): (e: boolean) => string => {
        return (e) => {
            return e ? "badge-light-danger" : "badge-light-info"
        }
    })

    /**
     * 循環管理 - 循環類型
     */
    const getAdminCycleType = computed((): (e: number) => string => {
        return (e) => {
            if (e === 1) return "badge-light-info"
            else if (e === 2) return "badge-light-primary"
            return "badge-light-success"
        }
    })

    /**
     * 客服管理 - 請我們喝咖啡
     * 1待付款(非信用卡付款方式), 2付款成功, 3付款失敗, 4取消
     */
    const getAdminSupportCoffeeStatus = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 1) return "badge-light-warning"
            else if (e === 2) return "badge-light-success"
            else if (e === 3) return "badge-light-danger"
            return "badge-light-secondary"
        }
    })

    /**
     * 客服管理 - 意見與建議 - 層級
     * 1:一般, 2:總教會
     */
    const getAdminSupportFeedbackLevel = computed((): (e: number | null) => string => {
        return (e) => {
            if (e === 2) return "badge-light-danger"
            return "badge-light-secondary"
        }
    })

    /**
     * 聚會 - 週期
     * 1:每週, 2:單次, 3:每日
     */
    const getShepherdAttendCycle = computed((): (e: number) => string => {
        return (e) => {
            if (e === 1) return "badge-light-info"
            else if (e === 2) return "badge-light-primary"
            return "badge-light-success"
        }
    })

    /**
     * 聚會 - 週期
     * 0: 未開始, 1:進行中, 2: 已結束
     */
    const getShepherdAttendHistory = computed((): (e: number) => string => {
        return (e) => {
            if (e === 1) return "badge-light-success"
            else if (e === 2) return "badge-light-danger"
            else if (e === 3) return "badge-light-info"
            return "badge-light-secondary"
        }
    })

    /**
     * 新朋友 - 關係
     * 1:夫妻, 2:父母, 3:親子, 4:兄弟姐妹, 5:朋友, 6:其他
     */
    const getShepherdFriendRelationship = computed((): (e: number) => string => {
        return (e) => {
            return getQuotient.value(e)
        }
    })

    return {
        getQuotient,
        getBasicStatus,
        getSelectToInline,
        getInputToChurchInline,
        getAdminDonateStatus,
        getAdminCycleType,
        getAdminCourseAccessRole,
        getAdminCourseAccessLevel,
        getAdminCourseAuthorizeRole,
        getAdminCourseAuthorizeMethod,
        getAdminCourseAccessMethod,
        getAdminCourseExceptionRole,
        getAdminCourseExceptionLevel,
        getAdminCourseExceptionCode,
        getAdminCourseExceptionRollback,
        getAdminSupportCoffeeStatus,
        getAdminSupportFeedbackLevel,
        getShepherdAttendCycle,
        getShepherdAttendHistory,
        getShepherdFriendRelationship
    }

}
