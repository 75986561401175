/**
 * 枚舉
 * 返回代號
 * @author J
 * @since 2024-04-30 07:34:25
 */

export enum ReturnCodeEnum {

    /**
     * 正確
     */
    OK = 200,

    /**
     * Sentinel - 熔斷降級
     */
    SERVICE_CIRCUIT_BREAKING = 201,

    /**
     * Sentinel - 流量控制
     */
    SERVICE_FLOW_CONTROL = 202,

    /**
     * Sentinel - 熱點限制
     */
    SERVICE_PARAMETER_FLOW_CONTROL = 203,

    /**
     * Sentinel - 系統規則
     */
    SERVICE_RULE = 204,

    /**
     * Sentinel - 熱點限制
     */
    SERVICE_RULE_AUTHORIZATION = 205,

    /**
     * 匿名用戶
     * 沒登入
     */
    UNAUTHORIZED = 401,

    /**
     * 公司授權過期
     */
    COMPANY_AUTHORIZED_EXPIRE = 402,

    /**
     * 無存取權限
     * 有登入但沒有權限
     */
    FORBIDDEN = 403,

    /**
     * 頁面不存在
     */
    NOT_FOUND = 404,

    /**
     * METHOD不允許
     */
    METHOD_NOT_ALLOWED = 405,

    /**
     * 上傳的檔案容量超出限制
     */
    PAYLOAD_TOO_LARGE = 413,

    /**
     * 內部錯誤
     */
    INTERNAL_SERVER_ERROR = 500,

    /**
     * 錯誤匣道
     */
    BAD_GATEWAY = 502,

    /**
     * 連線超時
     */
    GATEWAY_TIMEOUT = 504,

    /**
     * FEIGN連線異常
     */
    FEIGN_CONNECT_ERROR = 700,

    /**
     * 二級驗證失敗
     */
    TWO_FACTORY_ERROR = 998,

    /**
     * 未知錯誤
     */
    UNKNOWN_ERROR = 998

}