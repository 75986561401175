export const History: {
    [key: string]: any
} = {
    action: {
        add: {
            subject: "创建",
            not: {
                allowed: {
                    subject: "无创建权限"
                }
            }
        },
        search: {
            subject: "搜索"
        }
    },
    footer: {
        subject: `已载入 <b class="text-justforyou-base ft-16">%s</b> 笔数据 共 <b class="text-justforyou-base ft-16">%s</b> 笔数据`,
        complete: {
            subject: "载入完成"
        }
    },
    readonly: {
        subject: "列表仅供读取"
    },
    empty: {
        subject: "找不到指定的资料"
    },
    off: {
        canvas: {
            statistics: {
                subject: "统计"
            },
            search: {
                subject: "搜索"
            }
        }
    }
}
