import category from "@/language/modules/page/admin/article/category/zhCN";

/**
 * 文章管理
 */
export default {

    /**
     * 分类
     */
    category: category,

    /**
     * 列表
     */
    index: {
        integrate: {
            badge: {
                subject: "文章整合"
            }
        },
        mindcatcher: {
            badge: {
                subject: "心灵捕手"
            }
        },
        summary: {
            comment: {
                subject: "查看留言纪录"
            },
            notification: {
                subject: "查看推播纪录"
            }
        },
        feature: {
            create: {
                subject: "创建文章"
            },
            mindcatcher: {
                subject: "分享连结"
            }
        },
        top: [
            "未置顶",
            "已置顶"
        ],
        field: {
            head: {
                church: "指定教会"
            },
            top: "置顶",
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "封面图",
            subject: "标题",
            category: "分类",
            kind: "类型",
            schedule: "排程",
            summary: {
                visit: "观看",
                social: "外连",
                comment: "留言",
                notification: "推播"
            },
            publish: {
                on: "上架时间",
                off: "下架时间"
            },
            created: "建立时间"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            top: "请选择置顶",
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            subject: "请输入标题",
            category: {
                subject: "请选择分类",
                empty: "请先选择指定教会"
            },
            kind: "请选择类型",
        },
        handler: {
            mindcatcher: {
                subject: "我的教会x心灵捕手",
                text: `<span class="text-success">请贴上您的分享连结</span>`,
                description: "请输入分享连结"
            },
            top: {
                enable: {
                    subject: "即将文章置顶",
                    text: "请确认后执行",
                    description: ""
                },
                disable: {
                    subject: "即将文章取消置顶",
                    text: "请确认后执行",
                    description: ""
                }
            },
            enable: {
                subject: "即将启用文章",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用文章",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除文章, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "图片"
            },
            2: {
                subject: "分享"
            },
            3: {
                subject: "活动"
            },
            4: {
                subject: "直播"
            }
        },
        tip: {
            live: "类型为直播, 无法使用活动次分类"
        },
        help: {
            church: "请谨慎使用指定教会功能",
            share: "前往设置分享描述"
        },
        field: {
            church: {
                my: {
                    subject: "指定教会",
                    text: ""
                },
                soul: {
                    subject: "指定教会",
                    text: "仅能指定自行维护心灵捕手的教会"
                },
                daily: {
                    subject: "指定教会",
                    text: "仅能指定自行维护每日灵粮的教会"
                }
            },
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            category: "分类",
            subject: "标题",
            publish: {
                on: "上架时间",
                off: "下架时间"
            },
            cycle: {
                kind: "循环方式",
                day: "循环日",
                date: "日期",
                time: "时间"
            },
            hyperlink: {
                media: "Youtube连结",
                music: "Google云端MP3",
                live: "直播连结",
                activity: "外部活动连结",
                web: "外部网站连结"
            },
            content: "内容",
            collection: "文章图片",
            description: "分享内文",
            status: "状态",
            share: "转发分享"
        },
        holder: {
            church: "请选择指定教会",
            kind: "请选择类型",
            category: {
                subject: "请选择分类",
                empty: "请先选择指定教会"
            },
            subject: "请输入标题",
            publish: {
                begin: "请选择上架时间",
                end: "请选择下架时间"
            },
            cycle: {
                kind: "请选择循环方式",
                day: "请选择循环日",
                date: "请选择日期",
                time: "请选择时间"
            },
            hyperlink: {
                media: "请输入Youtube连结",
                music: "请输入Google云端MP3",
                live: "请输入直播连结",
                activity: "请输入外部活动连结",
                web: "请输入外部网站连结"
            },
            content: "请输入内容",
            collection: "",
            description: "请输入分享内文",
            status: "请选择状态",
            share: "请选择转发分享"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            store: {
                subject: "即将创建文章",
                text: "请确认后执行",
                description: ""
            },
            copy: {
                subject: "即将复制文章",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择类型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择上架时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架时间必须大于上架时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请输入外部活动连结",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入Youtube连结",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入Google云端MP3",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请输入直播连结",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入外部网站连结",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请选择转发分享",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "请指定教会",
                "text": "",
                "description": ""
            },
            3001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "此文章无法复制",
                "text": "已经启用整合功能",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        comment: {
            feature: {
                calculate: {
                    subject: "重计留言数量"
                }
            },
            subject: "留言纪录",
            th: {
                0: "头像",
                1: "姓名",
                2: "内容",
                3: "时间",
            },
            delete: {
                empty: {
                    subject: "请先选择需要删除的留言"
                }
            },
            handler: {
                calculate: {
                    subject: "即将重新计算文章留言数量",
                    text: "请确认后执行",
                    description: ""
                },
                delete: {
                    subject: "即将删除文章留言",
                    text: "请确认后执行",
                    description: ""
                }
            }
        },
        tip: {
            disabled: "请注意, 这篇文章已经停用",
            live: "类型为直播, 无法使用活动次分类"
        },
        help: {
            share: "前往设置分享描述"
        },
        integrate: {
            live: {
                subject: "已启用直播文章整合, 无法编辑"
            },
            activity: {
                subject: "已启用活动文章整合, 无法编辑"
            },
            mindcatcher: {
                subject: "心灵捕手外部文章整合, 无法编辑"
            },
            hyperlink: "前往修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "图片"
            },
            2: {
                subject: "分享"
            },
            3: {
                subject: "活动"
            },
            4: {
                subject: "直播"
            },
            5: {
                subject: "统计资讯"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            comment: {
                subject: "留言纪录"
            },
            notification: {
                subject: "推播纪录"
            },
            visit: {
                subject: "观看次数"
            },
            social: {
                subject: "外连观看"
            },
            favorite: {
                subject: "收藏次数"
            }
        },
        field: {
            belong: {
                no: "所属教会编号",
                name: "所属教会名称"
            },
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            category: "分类",
            subject: "标题",
            publish: {
                on: "上架时间",
                off: "下架时间"
            },
            cycle: {
                kind: "循环",
                day: "循环日",
                date: "日期",
                time: "时间"
            },
            hyperlink: {
                media: "Youtube连结",
                music: "Google云端MP3",
                live: "直播连结",
                activity: "外部活动连结",
                web: "外部网站连结"
            },
            content: "内容",
            collection: "文章图片",
            description: "分享内文",
            status: "状态",
            share: "转发分享"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            kind: "请选择类型",
            category: "请选择分类",
            subject: "请输入标题",
            publish: {
                on: "请选择上架时间",
                off: "请选择下架时间"
            },
            cycle: {
                kind: "请选择循环方式",
                day: "请选择循环日",
                date: "请选择日期",
                time: "请选择时间"
            },
            hyperlink: {
                media: "请输入Youtube连结",
                music: "请输入Google云端MP3",
                live: "请输入直播连结",
                activity: "请输入外部活动连结",
                web: "请输入外部网站连结"
            },
            content: "请输入内容",
            collection: "",
            description: "请输入分享内文",
            status: "请选择状态",
            share: "请选择转发分享"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            update: {
                subject: "即将更新文章内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择类型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择上架时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架时间必须大于上架时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请输入外部活动连结",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入Youtube连结",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入Google云端MP3",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请输入直播连结",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入外部网站连结",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请选择转发分享",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            },
            3001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
