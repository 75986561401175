import category from "@/language/modules/page/admin/activity/category/zhCN";
import register from "@/language/modules/page/admin/activity/register/zhCN";
import registered from "@/language/modules/page/admin/activity/registered/zhCN";

/**
 * 活动管理
 */
export default {

    /**
     * 分类
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建活动"
            },
        },
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            subject: "标题",
            avatar: "封面图",
            quantity: "活动数量",
            category: "分类",
            integrate: "文章整合",
            registered: {
                subject: "已报名",
                text: "含未付费"
            },
            sort: "排序",
            created: "建立时间"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            integrate: "请选择文章整合",
            subject: "请输入名称",
            category: "请选择分类",
        },
        handler: {
            enable: {
                subject: "即将启用活动",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用活动",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除活动, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tip: {
            signature: "活动建立人员将会自动拥有本活动App签到功能, 可不指定其他协助签到人员"
        },
        help: {
            church: "请谨慎使用指定教会功能",
            discount: {
                enable: "已经开启报名优惠功能, 无法修改日期"
            }
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "报名"
            },
            2: {
                subject: "场次"
            },
            3: {
                subject: "回条"
            },
            4: {
                subject: "栏位"
            },
            5: {
                subject: "提醒"
            },
            6: {
                subject: "文章"
            },
            7: {
                subject: "图片"
            },
            8: {
                subject: "分享"
            },
            10: {
                subject: "签到"
            }
        },
        field: {
            keyword: "搜索名单",
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            church: "指定教会",
            category: "分类",
            subject: "标题",
            activity: {
                on: "活动开始时间",
                off: "活动结束时间"
            },
            respond: {
                avatar: {
                    subject: "封面图",
                    text: "(尺寸为 %sx%s 像素)",
                },
                description: "描述"
            },
            collection: "文章图片",
            description: "内容",
            status: "状态"
        },
        holder: {
            keyword: "搜索范围: 名字/邮件/会友编号",
            kind: "请选择类型",
            church: "请选择指定教会",
            category: "请选择分类",
            subject: "请输入名称",
            activity: {
                on: "请输入活动开始时间",
                off: "请输入活动结束时间"
            },
            respond: {
                description: "请输入内容"
            },
            collection: "",
            description: "请输入描述",
            status: "请选择状态"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            store: {
                subject: "即将创建活动",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入描述",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            },
            4002: {
                "subject": "请输入回条描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {

        /**
         * 后台报名
         */
        register: register,

        /**
         * 已报名
         */
        registered: registered,

        tip: {
            signature: "活动建立人员将会自动拥有本活动App签到功能, 可不指定其他协助签到人员",
            disabled: "请注意, 这个活动已经停用",
            backend: "请注意, 总教会登入无法使用后台报名",
            full: "请注意, 这个活动报名已经额满",
            summary: `请注意, 这个活动已经有 <b class="ft-15">%s</b> 名会友已付费报名, 部分栏位<b>无法修改</b>, 请注意`
        },
        help: {
            discount: {
                enable: "已经开启报名优惠功能, 无法修改日期"
            },
            activity: {
                register: {
                    expire: {
                        subject: "活动已经开始, 报名截止"
                    }
                }
            }
        },
        feature: {
            0: "下载二维码",
            1: "后台报名",
            2: "报到工具",
            3: "显示二维码",
            4: "复制报名网址",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "报名"
            },
            2: {
                subject: "场次"
            },
            3: {
                subject: "回条"
            },
            4: {
                subject: "栏位"
            },
            5: {
                subject: "提醒"
            },
            6: {
                subject: "文章"
            },
            7: {
                subject: "图片"
            },
            8: {
                subject: "分享"
            },
            9: {
                subject: "报名资讯"
            },
            10: {
                subject: "签到"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            url: {
                subject: "报名网址"
            },
            register: {
                subject: "后台报名"
            },
            summary: {
                subject: "报名清单",
                text: "含未付费"
            }
        },
        field: {
            keyword: "搜索名单",
            belong: {
                no: "所属教会编号",
                name: "所属教会名称"
            },
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            category: "分类",
            subject: "标题",
            activity: {
                on: "活动开始时间",
                off: "活动结束时间"
            },
            respond: {
                avatar: {
                    subject: "封面图",
                    text: "(尺寸为 %sx%s 像素)",
                },
                description: "描述"
            },
            collection: "文章图片",
            description: "内容",
            status: "状态"
        },
        holder: {
            keyword: "搜索范围: 名字/邮件/会友编号",
            belong: {
                no: "",
                name: ""
            },
            kind: "请选择类型",
            category: "请选择分类",
            subject: "请输入名称",
            activity: {
                on: "请输入活动开始时间",
                off: "请输入活动结束时间"
            },
            respond: {
                description: "请输入内容"
            },
            collection: "",
            description: "请输入描述",
            status: "请选择状态"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            update: {
                subject: "即将更新活动内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入描述",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            },
            4002: {
                "subject": "请输入回条描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
