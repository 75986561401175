import {Team} from "@/language/modules/page/admin/event/v2/edit/membership/team/zhCN";
import {Editor} from "@/language/modules/page/admin/event/v2/edit/membership/editor/zhCN";

export const Membership: {
    [key: string]: any
} = {

    team: Team,

    editor: Editor,

    field: {

        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            text: "同时搜索名字/邮件/会友编号"
        },

        team: {
            subject: "参与群组",
            holder: "请选择参与群组"
        }

    },
    empty: {
        subject: "请选择会友参与服事"
    },
    not: {
        found: {
            subject: "搜索不到指定的参与服事会友"
        }
    }
}