export default {
    type: {
        name: {
            1: "文章",
            2: "推播",
            3: "服事"
        },
        disabled: "這個選項暫時無法使用"
    },
    column: {
        once: "單次",
        day: "每日",
        week: "每週",
        month: "每月",
        year: "每年"
    },
    schedule: {
        subject: "排程預覽",
        text: `共計 <b class="ft-14 text-success">%s</b> 個排程`,
        th: {
            0: {
                subject: "#"
            },
            1: {
                subject: "開始時間"
            },
            2: {
                subject: "結束時間"
            }
        }
    },
    editor: {
        help: {
            begin: {
              weekday: {
                  subject: "首次起始日為星期%s, 不能取消"
              }
            },
            resource: {
                subject: "目前最長可定義 %s 年週期, 請妥善利用資源",
            },
            more: `如果範圍中的月份沒有第 <b class="text-success">%s</b> 天, 則會自動跳過`
        },
        field: {
            cycle: "方式",
            duration: "時長"
        },
        spinner: {
            day: ["每", "日"],
            week: ["每", "週"],
            month: ["每", "個月"],
            year: ["每", "年"]
        },
        duration: {
            specific: "總計次數"
        },
        method: {
            month: {
                day: {
                    at: "在第 %s 天重複",
                    last: "在最後一天重複"
                },
                week: {
                    at: "在第%s週的%s",
                    last: "在最後一個%s重複"
                },
                select: "選取希望重複的日期"
            },
            year: {
                day: {
                    at: "在 %s 的第 %s 天重複",
                    last: "在 %s 的最後一天重複"
                },
                week: {
                    at: "在 %s 的第%s週的%s重複",
                    last: "在 %s 的最後一個%s重複"
                },
                select: "選取希望在 %s 號重複的月份"
            }
        },
        error: {
            1001: {
                "subject": "請指定至少一天",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請指定選項",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請指定需要重複的日期",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請指定需要重複的月份",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請指定時長",
                "text": "",
                "description": ""
            }
        }
    }
}
