export const Edit: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本",
        },
        99: {
            subject: "相关资讯",
        }
    },
    field: {
        created: {
            subject: "建立时间",
            holder: ""
        },
        stamp: {
            subject: "执行时间",
            holder: ""
        },
        received: {
            subject: "生成时间",
            holder: ""
        }
    }

}
