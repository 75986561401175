export default {
    category: {
        create: "请先前往建立分类",
        ajax: {
            first: "请先选择指定教会",
            empty: "该教会尚未建立分类"
        }
    },
    option: {
        church: {
            all: "全部教会"
        },
        empty: {
            blank: "未选择",
            subject: "没有可用的选项",
            city: "请先选择所在区域",
            target: "请先选择类型"
        }
    }
}
