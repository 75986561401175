export default {
    header: {
        total: "全部 %s 筆公告",
        unread: "未讀取 %s 筆公告"
    },
    body: {
        field: {
            placeholder: "請輸入標題關鍵字查詢"
        },
        entries: {
            row: `%s・%s・共計 <b class="%s">%s</b> 次閱讀`
        }
    },
    footer: {
        total: {
            all: `全部 <b class="ft-16 %s">%s</b> 筆公告`,
            filter: `篩選出 <b class="ft-16 %s">%s</b> 筆公告`
        }
    },
    recipient: {
        th: {
            0: {
                subject: "#"
            },
            1: {
                subject: "管理員"
            },
            2: {
                subject: "閱讀時間"
            }
        },
        empty: "暫時還沒有管理員閱讀",
        not: {
            found: "無法搜尋到<br />您指定條件的管理員"
        }
    }
}
