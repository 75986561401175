export const Schedule: {
    [key: string]: any
} = {

    subject: "排程预览",

    th: {
        0: {
            subject: "#"
        },
        1: {
            subject: "开始时间"
        },
        2: {
            subject: "结束时间"
        }
    },

    footer: {

        summary: {
            subject: `合计 <b class="ft-16 text-base">%s</b> 次循环排程`
        },

    }

}
