import {RouteRecordRaw} from "vue-router";
import statistics from "@/language/modules/page/admin/dashboard/statistics/zhTW"
import {RouteNameEnum} from "@/v2/enumerate/route";


//從翻譯檔中, 取得所以可用的鍵值
const summaryKey = Object.keys(statistics).join("$|")
//將路徑組合成可以用的regex, 進行檢查, 不存在的跳轉404, 他的正規表達式已經包含^, 不需要再加
const summaryPath = `dashboard/statistics/:key(${summaryKey}$)`

const dashboard: RouteRecordRaw[] = [{
	path: "dashboard",
	name: RouteNameEnum.ADMIN_DASHBOARD,
	meta: {
		permission: 1001,
		breadcrumb: []
	},
	component: () => import("@/page/content/admin/dashboard/index.vue"),
}, {
	path: summaryPath,
	name: "AdminDashboardStatistics",
	meta: {
		permission: 1001,
		//在 router.beforeEach 會做替換的功能
		breadcrumb: []
	},
	component: () => import("@/page/content/admin/dashboard/statistics/index.vue"),
}];

export default dashboard;
