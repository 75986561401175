export default {
    editor: {
        help: {
            early: {
                subject: "需要早於直撥開始時間",
                activity: {
                    subject: "需要早於活動開始時間",
                }
            },
            async: {
                subject: "將自動同步直撥標題",
                activity: {
                    subject: "將自動同步文章標題",
                }
            },
            copy: "複製直播描述",
            share: "前往設定分享描述"
        },
        label: {
            live: "直播",
            on: "啟用",
            off: "關閉"
        },
        field: {
            mode: "整合",
            kind: "種類",
            category: "主分類",
            secondary: "次分類",
            subject: "標題",
            publish: {
                on: "上架時間",
                off: "下架時間"
            },
            hyperlink: {
                media: "Youtube連結",
                music: "Google雲端MP3"
            },
            status: "狀態",
            share: "轉發分享",
            content: "內容"
        },
        holder: {
            category: "請選擇主分類",
            secondary: "請選擇次分類",
            subject: "標題",
            publish: {
                on: "請選擇上架時間",
                off: "請選擇下架時間"
            },
            hyperlink: {
                media: "請輸入Youtube連結",
                music: "請輸入Google雲端MP3"
            },
            status: "請選擇狀態",
            share: "請選擇轉發分享",
            content: "請輸入內容"
        },
        error: {
            1001: {
                "subject": "請選擇主分類",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇次分類",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇上架時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "上架時間需要小於直播開始時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架時間必須大於上架時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請選擇轉發分享",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請選擇種類",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請輸入Youtube連結",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入Google雲端MP3",
                "text": "",
                "description": ""
            }
        }
    }
}
