/**
 * 會友管理
 */
export default {

    /**
     * 列表
     */
    index: {
        tag: {
            help: {
                empty: "請先選取指定會友",
                edit: "編輯個人標記",
                join: "附加標籤到指定會友",
                replace: "清除並附加標籤到指定會友",
                remove: "刪除指定會友的標籤",
                reset: "重設已輸入標籤值"
            }
        },
        download: {
            name: "檔案匯出_會友管理",
        },
        feature: {
            create: {
                subject: "新增會友"
            },
            tag: {
                on: "開啟編輯標籤",
                off: "關閉編輯標籤"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "頭像",
            name: "名字",
            email: "電子郵件",
            account: "帳號",
            commitment: "委身編號",
            keyword: "標籤"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            name: "請輸入名字",
            email: "請輸入電子郵件",
            belong: "請選擇所屬教會",
            empty: {
                commitment: "未填寫"
            },
            keyword: "請輸入標籤,可用逗號分隔連續輸入並按enter輸入"
        },
        handler: {
            enable: {
                subject: "即將啟用會友",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用會友",
                text: "請確認後執行",
                description: ""
            },
            tag: {
                store: {
                    subject: "即將對指定的會友加入標籤",
                    text: "請確認後執行",
                    description: ""
                },
                replace: {
                    subject: "即將對指定的會友取代標籤",
                    text: "請確認後執行",
                    description: ""
                },
                remove: {
                    subject: "即將對指定的會友移除標籤",
                    text: "請確認後執行",
                    description: ""
                }
            }
        },
        factory: {
            export: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將進行會友匯出, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            },
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將進行全部會友刪除, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            disabled: "請注意, 這名會友已經停用"
        },
        commitment: {
            empty: "未填寫"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "標籤"
            },
            2: {
                subject: "統計資訊"
            },
            99: {
                subject: "相關資訊"
            },
        },
        summary: {
            donate: {
                subject: "奉獻紀錄"
            },
            share: {
                subject: "分享次數"
            },
            visit: {
                subject: "文章觀看"
            },
            comment: {
                subject: "文章回覆"
            },
            social: {
                subject: "外連觀看"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            email: "電子郵件",
            name: "名字",
            title: "稱謂",
            birthday: "生日",
            phone: "聯絡電話",
            location: {
                country: "所在地區",
                city: "所在城市"
            },
            address: "聯絡地址",
            occupation: "職業",
            baptized: "受洗時間",
            commitment: "委身編號",
            created: "建立時間",
            accessed: "最後存取時間",
            deleted: "刪除時間",
            tag: {
                subject: "標籤"
            }
        },
        holder: {
            head: "未輸入",
            belong: {
                name: "",
                no: ""
            },
            email: "請輸入電子郵件",
            name: "請輸入名字",
            title: "未輸入稱謂",
            birthday: "未輸入生日",
            phone: "未輸入聯絡電話",
            location: {
                country: "未輸入所在地區",
                city: "未輸入所在城市"
            },
            address: "未輸入聯絡地址",
            occupation: "未輸入職業",
            baptized: "未輸入受洗時間",
            commitment: "請輸入委身編號",
            created: "",
            accessed: "",
        },
        handler: {
            update: {
                subject: "即將更新會友內容",
                text: "請確認後執行",
                description: ""
            }
        }
    }

}



