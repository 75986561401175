export default {
    advance: {
        charge: "需要收费",
        restrict: "限制人数",
        available: "报名上限",
        wait: "等待付款",
        paid: "报名完成",
        remain: "剩余名额"
    },
    edit: {
        help: {
            backend: "后台编辑"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "团报"
            },
            2: {
                subject: "表单"
            },
            3: {
                subject: "金流"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            group: {
                name: "教会(机构)名称",
                city: "教会(机构)所在城市",
                zip: "邮递区号",
                address: "通讯地址",
                contact: {
                    name: "联络人姓名",
                    phone: "联络人电话号码",
                    email: "联络人电子邮件",
                }
            },
            respond: "结果",
            avatar: "头像",
            qrcode: "报到二维码",
            echelon: {
                subject: "报名场次",
                begin: {
                    subject: "场次开始"
                },
                end: {
                    subject: "场次结束"
                },
                total: {
                    subject: `全部 <b>%s</b> 场`
                },
                edit: {
                    subject: "变更场次"
                }
            },
            external: "报名来源",
            organization: "报名方式",
            no: "订单号",
            name: "姓名",
            email: "电子邮件",
            paid: "已付款",
            creator: {
                subject: "报名人员",
                stamp: {
                    subject: "报名时间",
                }
            },
            status: "状态"
        },
        holder: {
            group: {
                name: "请输入教会(机构)名称",
                city: "请输入教会(机构)所在城市",
                zip: "请输入邮递区号",
                address: "请输入通讯地址",
                contact: {
                    name: "请输入联络人姓名",
                    phone: "请输入联络人电话号码",
                    email: "请输入联络人电子邮件",
                }
            },
            organization: "请选择报名方式",
            external: "请选择报名来源",
            no: "请输入订单号",
            name: "请输入姓名",
            email: "请输入电子邮件",
            paid: "请选择已付款",
            status: "请选择状态"
        },
        handler: {
            update: {
                subject: "即将更新报名内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择已付款",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            }
        }
    },
    field: {
        no: "订单号",
        begin: "建立日期(起)",
        end: "建立日期(迄)",
        avatar: "头像",
        organization: "报名方式",
        registered: {
            subject: "资讯",
            organization: {
                group: {
                    subject: "团体报名"
                },
                subject: "个人报名"
            },
            external: {
                0: {
                    subject: "App"
                },
                1: {
                    subject: "外部"
                },
                2: {
                    subject: "后台"
                }
            },
            paid: {
                not: {
                    subject: "未付款"
                },
                subject: "已付款"
            }
        },
        external: "报名来源",
        paid: "已付款",
        sort: "排序",
        created: "建立时间"
    },
    holder: {
        no: "请输入订单号",
        organization: "请选择报名方式",
        external: "请选择报名来源",
        paid: "请选择已付款",
        begin: "请选择建立日期(起)",
        end: "请选择建立日期(迄)"
    },
    factory: {
        paid: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将手动标记付款, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }
}
