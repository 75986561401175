/**
 * 客服管理
 */
export default {
    subject: "客服管理",
    worker: {
        index: "維修工單",
        create: "工單新增",
        edit: {
            subject: "工單內容",
            history: {
                index: "歷史紀錄",
                create: "紀錄新增",
                edit: "紀錄內容"
            },
            record: {
                index: "異動紀錄"
            }
        }
    },
    history: {
        index: "帳號紀錄",
        edit: "紀錄內容",
        permission: "客服管理 - 帳號紀錄"
    },
    feedback: {
        index: "意見與建議",
        edit: "反饋內容",
        permission: "客服管理 - 意見與建議"
    },
    coffee: {
        index: "請我們喝咖啡",
        edit: "贊助內容",
        permission: "客服管理 - 請我們喝咖啡"
    }
}
