/**
 * 鉤子
 * 工具
 * @author J
 * @since 2024-04-30 07:34:25
 */

import _ from "lodash";
import moment from "moment-timezone";
import {computed} from "vue";
import {sprintf} from "sprintf-js";
import {FormTableSearchDuringCombineKeyEnum} from "@/v2/enumerate/component/form/table";

/**
 * 實例
 */
export const useUtils = () => {

    /**
     *  產生隨機碼
     *  @param length 產生長度
     *  @param hash true產生複雜UUID
     */
    const getUuid = (
        length = 32,
        hash = false
    ) => {
        //容易混淆的字符oOLl,9gq,Vv,Uu,I1
        const chars = hash ?
            '0123456789abcdefghijklmnoposrtuvwxyzABCDEFGHIJKLMNOPOSRTUVWXYZ_-' :
            'abcdef0123456789';
        const maxPos = chars.length;
        let pwd = '';
        for (let i = 0; i < length; i++) pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        return pwd;
    }

    /**
     * 組合/最佳化 payload
     * @param source 來源
     * @param merge 合併值
     * @param field time 修正時間, ignore 忽略的key
     */
    const getMinify = (
        source: {
            [key: string]: any
        },
        merge?: {
            [key: string]: any
        },
        field?: {
            time?: string[],
            ignore?: string[]
        }
    ) => {

        //內建忽略欄位
        const ignoreField: string[] = [
            "seq",
            "uuid",
            // "belong",
            "creator",
            "editor"
        ]

        //內建時間欄位 + 搜尋起迄專用時間
        const timeField: string[] = [
            "begin",
            "end"
        ].concat([
            FormTableSearchDuringCombineKeyEnum.ON_BEGIN,
            FormTableSearchDuringCombineKeyEnum.ON_END,
            FormTableSearchDuringCombineKeyEnum.OFF_BEGIN,
            FormTableSearchDuringCombineKeyEnum.OFF_END,
            FormTableSearchDuringCombineKeyEnum.SUBMIT_BEGIN,
            FormTableSearchDuringCombineKeyEnum.SUBMIT_END
        ])

        //時間補正
        const parseTime = (e: any) => {

            //未定義或者空白, 直接返回原始值
            if (e === undefined || e === null) {
                return e
            }

            //將字串轉換為moment格式
            const m: moment.Moment = moment(e, moment.ISO_8601)

            //‼️‼️重要‼️‼️
            //formDatePicker.onOpen的時候
            //要檢查當下是不是空值, 空值就標記當他轉換過了, 否則會累加1次
            //導致下方處理不正確

            //處理時間偏移量 (傳下來時間都是GTM+8, 去掉+8, 再看用戶瀏覽器時區值進行調整)
            //先歸0再+8:00
            m.add((new Date().getTimezoneOffset() * 60) + 28800, 'seconds');

            //如果是日期, 返回日期即可
            if (moment(e, "YYYY-MM-DD", true).isValid()) {
                return m.format("YYYY-MM-DD");
            }
            //如果是不帶秒, 補秒上去
            else if (moment(e, "YYYY-MM-DD HH:mm", true).isValid()) {
                return m.format("YYYY-MM-DD HH:mm:00");
            }

            //預設返回
            return m.format("YYYY-MM-DD HH:mm:ss");

        }

        //合併結果
        //需要複製一份來源值, 否則會影響到畫面值
        const result: {
            [key: string]: any
        } = _.merge(
            _.cloneDeep(source),
            _.cloneDeep(merge) ?? {}
        )

        //時間補正
        timeField.concat(field?.time ?? []).every(i => {

            let resultValue: any

            //判斷是否為 nested宣告
            const q: string[] = i.split(".")

            //nested宣告, 紀錄當下object值
            if (q.length > 1) {

                const r: any = q.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, result as any)

                const time: any = parseTime(r)

                // 空物件就不塞進去了
                if (!!time) {
                    resultValue = q.reduceRight((acc, key) => ({ [key]: acc }), time as any)
                }

            }
            //鍵/值宣告
            else {

                resultValue = parseTime(result[i])

            }

            //取出值錯誤, 不往下進行, (every返回false, 等同continue)
            if (resultValue === undefined) {
                return false
            }

            //置入正確的排序值
            if (typeof resultValue === "object") _.merge(result, resultValue)
            else result[i] = resultValue

            //完成 (every返回true, 等同繼續進行)
            return true

        })

        // 轉換分類
        if (typeof result.category === 'object') {
            result.category = result.category.seq
        }

        //忽略的key
        return _.omit(
            result,
            ignoreField.concat(field?.ignore ?? [])
        )

    }

    /**
     * 複製
     * @param e 內文
     */
    const onCopy = (e: string | number | null) => {

        if (e === null) {
            return
        }
        else if (e === undefined) {
            return
        }
        else if (e === '') {
            return
        }

        //⚠️⚠️ 注意 ⚠️⚠️
        //Android, 不支援 navigator.clipboard.writeText
        //需要使用傳統方式, 處理

        const input = document.createElement("input");
        input.value = String(e);
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length)
        document.execCommand('Copy');
        document.body.removeChild(input);

    }

    /**
     * 頁籤 - 切換
     */
    const onTab = (
        component: any,
        tab: number,
        active: {
            [key: string]: any
        }
    ) => {
        (Array.isArray(component) ? component : [component]).forEach((i: {
            tab: number,
            key: string
        }) => {
            if (i.tab === tab) active.is[i.key].enable = true
        })
    }

    /**
     * 批量 - 呼叫 - 主動 - 暴露
     */
    const onExpose = (
        //元件
        component: any,
        //暴露方法
        expose: string
    ): any => (Array.isArray(component) ? component : [component])
        // 排除空的元件
        .filter(i=>{
            if (_.has(i, "ref") && !i.ref) {
                return false
            }
            return !!i
        })
        //遍歷元件
        .forEach((i: any) => {

            if (typeof i?.[expose] === "function") {
                i?.[expose]()
            }
            // 指定方式傳入並帶條件
            else if (_.has(i, "ref") && !!i.ref[expose] && i.condition === true) {
                i.ref[expose]()
            }

        })

    /**
     * 批量 - 呼叫 - 主動 - 暴露 - 取出值
     */
    const onExposeResult = (
        //元件
        component: any,
        //暴露方法
        expose?: string,
        //為computed
        computed?: boolean
    ): any => {

        // 指定為computed, 直接返回值
        if (computed ?? true) {
            return component?.[expose ?? "result"]
        }

        return component?.[expose ?? "result"]()
    }

    /**
     * 封存值
     * @param e 來源
     * @param d 目的
     */
    const onArchive = (
        e: {
            [key: string]: any
        },
        d: {
            [key: string]: any
        }
    ) => {

        //深複製緩存值
        const data: {
            [key: string]: any
        } = _.cloneDeep(e)

        // 陣列先清空, 用 source = [] 無法清空, 必須要用 source.length = 0
        if (Array.isArray(e) && Array.isArray(data)) d.length = 0
        //將深複製出的緩存值, 投入到field中
        Object.keys(data).forEach((i: string) => d[i] = data[i])

    }

    /**
     * 首字大寫
     * 傳入字串
     */
    const getCapitalizeFirstLetter = computed((): (e: string | null | undefined) => string => {
        return (e) => {
            if (!e) return "";
            return sprintf(
                "%s%s",
                e.charAt(0).toUpperCase(),
                e.slice(1)
            );
        }
    })

    return {
        getUuid,
        getMinify,
        getCapitalizeFirstLetter,
        onCopy,
        onTab,
        onExpose,
        onExposeResult,
        onArchive
    }

}
