export default {
    tabs: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "团报"
        },
        2: {
            subject: "表单"
        },
        99: {
            subject: "相关资讯"
        }
    },
    tip: {
        organization: {
            subject: `团体报名人数最少 <b class="ft-16">%s</b> 位, 最多 <b class="ft-16">%s</b> 位`,
            left: {
                subject: `, 还需要 <b class="ft-16">%s</b> 位报名`
            },
            satisfy: {
                minimum: {
                    subject: `, 最多还可以报名 <b class="ft-16">%s</b> 位`
                },
                negative: {
                    subject: `, 已经达到活动报名人数上限`
                },
                maximum: {
                    subject: `, 已达到团报最高人数限制`
                }
            }
        },
        summary: {
            subject: "报名总览"
        }
    },
    form: {
        empty: "请先增加团体报名人员",
        rank: `合计 <b class="ft-16 text-success">%s</b> 位报名`,
        th: {
            0: "#",
            1: "姓名",
            2: "电子邮件",
            3: "操作"
        },
        field: {
            name: "姓名",
            email: "电子邮件"
        }
    },
    discount: {
        original: "原价",
        before: "%s 之前",
        between: "%s 至 %s"
    },
    canvas: {
        create: {
            subject: "创建团报人员"
        },
        edit: {
            subject: "编辑团报人员"
        }
    },
    field: {
        method: "报名方式",
        discount: "费用",
        echelon: "活动场次",
        payment: "支付状态",
        organization: {
            name: "教会(机构)名称",
            city: "教会(机构)所在城市",
            contact: {
                name: "联络人姓名",
                phone: "联络人电话",
                email: "联络人电子邮件"
            },
            zip: "邮递区号",
            address: "通讯地址"
        },
    },
    holder: {
        organization: {
            name: "请输入教会(机构)名称",
            city: "请输入教会(机构)所在城市",
            contact: {
                name: "请输入联络人姓名",
                phone: "请输入联络人电话",
                email: "请输入联络人电子邮件"
            },
            zip: "请输入邮递区号",
            address: "请输入通讯地址"
        }
    },
    handler: {
        store: {
            subject: "即将保存后台报名",
            text: "请确认后执行",
            description: ""
        }
    },
    error: {
        1001: {
            "subject": "请选择报名方式",
            "text": "",
            "description": ""
        },
        1002: {
            "subject": "异常发生",
            "text": "指定的报名方式不存在",
            "description": ""
        },
        1003: {
            "subject": "请选择活动场次",
            "text": "",
            "description": ""
        },
        1004: {
            "subject": "异常发生",
            "text": "指定的活动场次不存在",
            "description": ""
        },
        1005: {
            "subject": "异常发生",
            "text": "指定的价格不存在",
            "description": ""
        },
        1006: {
            "subject": "请选择支付状态",
            "text": "",
            "description": ""
        },
        1007: {
            "subject": "异常发生",
            "text": "指定的付款状态不存在",
            "description": ""
        },
        1998: {
            "subject": "总教会不能进行后台报名",
            "text": "即将返回报名内容页面",
            "description": ""
        },
        1999: {
            "subject": "活动报名已经额满",
            "text": "即将返回报名内容页面",
            "description": ""
        },
        3001: {
            "subject": "请完善表单内容",
            "text": "",
            "description": ""
        },
        2001: {
            "subject": "请输入教会(机构)名称",
            "text": "",
            "description": ""
        },
        2002: {
            "subject": "请输入教会(机构)所在城市",
            "text": "",
            "description": ""
        },
        2003: {
            "subject": "请输入联络人姓名",
            "text": "",
            "description": ""
        },
        2004: {
            "subject": "请输入联络人电话",
            "text": "",
            "description": ""
        },
        2005: {
            "subject": "请输入联络人电子邮件",
            "text": "",
            "description": ""
        },
        2006: {
            "subject": "联络人电子邮件格式错误",
            "text": "",
            "description": ""
        },
        2007: {
            "subject": "请输入邮递区号",
            "text": "",
            "description": ""
        },
        2008: {
            "subject": "请输入通讯地址",
            "text": "",
            "description": ""
        }
    }
}
