import accept from "@/language/modules/page/admin/gallery/accept/zhTW";
import alarm from "@/language/modules/page/admin/gallery/alarm/zhTW";

/**
 * 圖庫管理
 */
export default {

    /**
     * 核准
     */
    accept: accept,

    /**
     * 檢舉
     */
    alarm: alarm,

    /**
     * 列表
     */
    index: {
        empty: "此分類下找不到圖片",
        disable: "請先選擇需要處理的圖片",
        summary: `全部 <b class="ft-16 cgBase">%s</b> 張圖片`,
        handler: {
            reload: {
                subject: "即將重新整理",
                text: "已選擇的項目將會重設, 請確認後執行",
                description: ""
            },
            destroy: {
                subject: "即將拒絕指定的圖片",
                text: "請確認後執行",
                description: ""
            }
        }
    }

}
