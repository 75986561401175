/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 二維碼 - 產生
 */
export enum EnumQrcodeMethod {

    /**
     * 二級驗證
     */
    FACTORY = "factory",

    /**
     * 用戶綁定管理員
     */
    MAPPING = "mapping"

}

/**
 * 二維碼 - 狀態
 */
export enum EnumQrcodeStatus {

    /**
     * 已驗證
     */
    INSPECTION = 1,

    /**
     * 已授權
     */
    AUTHORIZED,

    /**
     * 超時
     */
    EXPIRE,

}
