/**
 * 心灵捕手文章管理
 */
export default {
    index: "心灵捕手文章管理",
    create: "心灵捕手文章创建",
    edit: "心灵捕手文章内容",
    copy: "文章复制",
    mindcatcher: "文章导入",
    permission: "心灵捕手文章管理 - 文章管理",
    comment: {
        index: "留言纪录",
        permission: "心灵捕手文章管理 - 留言纪录"
    },
    notification: {
        index: "推播纪录",
        create: "推播创建",
        edit: "推播内容",
        permission: "心灵捕手文章管理 - 推播纪录"
    },
    category: {
        index: "心灵捕手分类管理",
        permission: "心灵捕手文章管理 - 分类管理",
    }
}
