/**
 * 設定 - 主題
 */
export enum SettingThemeEnum {

    /**
     * 淺色
     */
    LIGHT = 1,

    /**
     * 深色
     */
    DARK = 2

}

/**
 * 設定 - 登入方式
 */
export enum SettingLoginEnum {

    /**
     * 掃碼
     */
    QRCODE = 1,

    /**
     * 帳號密碼
     */
    PASSWD = 2

}

/**
 * 設定 - 表單 - 開啟方式
 */
export enum SettingFormTableOpenEnum {

    /**
     * 原始模式
     */
    SELF = "_self",

    /**
     * 新頁籤開啟
     */
    NEW_TAB = "_blank"

}