import axios, {AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {Configure} from "@/api/interface/configure";
import {Respond} from "@/api/interface/respond";
import {useSwalAlert} from "@/hook/useSwalAlert";
import {useMitt} from "@/hook/useMitt";
import {useRegion} from "@/hook/useRegion";
import {usePermission} from "@/hook/usePermission";
import {EnumHttpRequest} from "@/enum/http";

const config = {
    // 默認地址請求地址，可在 .env 開頭文件中修改
    baseURL: import.meta.env.VITE_BASE_URL as string,
    // 設置超時時間（10s）
    timeout: EnumHttpRequest.TIMEOUT as number,
    // 跨域時候允許攜帶憑證
    withCredentials: true
};

//VueEvent
const {
    setLoadingMask
} = useMitt()

//獲取自定義的參數
const getAxiosConfigure = (config: any): Configure.Axios => {
    return config.opts
};

//是否為正式區
const isProd: boolean = import.meta.env.PROD as boolean

//Bundle Name
const bundleName: string = import.meta.env.VITE_BUNDLE_NAME as string

class RequestHttp {
    service: AxiosInstance;
    public constructor(config: AxiosRequestConfig) {
        // 實例化axios
        this.service = axios.create(config);
        /**
         * 請求攔截器
         * 客戶端發送請求 -> [請求攔截器] -> 服務器
         * token校驗(JWT) : 接受服務器返回的token,存儲到vuex/pinia/本地儲存當中
         */
        this.service.interceptors.request.use(
            (config: AxiosRequestConfig) => {

                const {
                    getToken
                } = usePermission()

                const {
                    getCurrentRegion
                } = useRegion()

                //注入 - 存取 - 權杖
                if (getToken.value) config.headers!["Authorization"] = getToken.value

                //注入 - Bundle -  Name
                config.headers!["Bundle"] = bundleName

                //注入 - 語言 - 檔頭
                config.headers!["Accept-Language"] = getCurrentRegion.value.key

                const axiosConfigure: Configure.Axios = getAxiosConfigure(config)

                //啟動讀取遮罩
                if( axiosConfigure?.builtIn?.loadingMask !== false ) setLoadingMask(true)

                //執行指定的ajax前回調
                if (axiosConfigure?.completion?.begin) axiosConfigure.completion.begin(
                    axiosConfigure
                )

                return config;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );

        /**
         * 響應攔截器
         *  服務器換返回信息 -> [攔截統一處理] -> 客戶端JS獲取到信息
         */
        this.service.interceptors.response.use(
            //正確回應 (status === 200)
            async (response: AxiosResponse) => {

                //SweetAlert (不能放外圈)
                const {
                    showSuccess,
                    showWarning,
                    showErrorStatus
                } = useSwalAlert()

                //關閉讀取遮罩 (不用管buildIn.loadingMask關就對了)
                setLoadingMask(false)

                const {
                    data,
                    config
                } = response;

                const axiosConfigure: Configure.Axios = getAxiosConfigure(config)

                //執行指定的ajax完成後回調
                if (axiosConfigure?.completion?.success) axiosConfigure.completion.success(
                    response,
                    axiosConfigure
                )

                //全局錯誤信息攔截
                if (data.code && data.code != 200) {

                    //回應格式都有則提示
                    if (data.code && data.code >= 900 && data.message && data.message.subject ) {

                        showWarning({
                            subject: data.message.subject,
                            text: data.message.text,
                            okButtonText: axiosConfigure?.builtIn?.button?.ok,
                            confirmButtonText: axiosConfigure?.builtIn?.button?.confirm,
                            cancelButtonText: axiosConfigure?.builtIn?.button?.cancel,
                            completion: {
                                //按好之後捲動到錯誤
                                done: ()=>{
                                    if (axiosConfigure && axiosConfigure.completion?.done) axiosConfigure.completion!.done(data)
                                }
                            }
                        })

                    }
                    //如果缺錯誤訊息, 給 999
                    else await showErrorStatus(data.code)

                }
                //如果是直接使用訊息顯示 (必須不是全局錯誤)
                else if (axiosConfigure?.completion?.done) {

                    //內建執行
                    if (axiosConfigure?.builtIn?.done !== false) showSuccess({
                        subject: data.message.subject,
                        text: data.message.text,
                        okButtonText: axiosConfigure?.builtIn?.button?.ok,
                        confirmButtonText: axiosConfigure?.builtIn?.button?.confirm,
                        cancelButtonText: axiosConfigure?.builtIn?.button?.cancel,
                        completion: {
                            done: () => axiosConfigure!.completion!.done!(data)
                        }
                    })
                    //指定執行
                    else axiosConfigure!.completion!.done!(data)

                }

                //不論錯誤或都會執行
                if (axiosConfigure?.completion?.final) axiosConfigure.completion.final()

                //成功請求（在頁面上除非特殊情況，否則不用處理失敗邏輯）
                return response;

            },
            //失敗回應 (status !== 200)
            async (error: AxiosError) => {

                //SweetAlert (不能放外圈)
                //SweetAlert
                const {
                    showErrorStatus
                } = useSwalAlert()

                const {
                    response,
                    config
                } = error;

                //關閉讀取遮罩 (不用管buildIn.loadingMask關就對了)
                setLoadingMask(false)

                const axiosConfigure: Configure.Axios = getAxiosConfigure(config)

                //不執行內建的錯誤
                if (axiosConfigure?.builtIn?.failure===false) return false

                //執行指定的ajax錯誤後回調
                if (response?.status && axiosConfigure?.completion?.failure) axiosConfigure.completion?.failure(
                    response?.status,
                    axiosConfigure
                )

                //請求超時單獨判斷，因為請求超時沒有 response, 判504
                if (error.message.includes("timeout")) await showErrorStatus(504)
                //網路連線異常，因為請求超時沒有 response, 判502
                else if (error.message.includes("Network Error")) await showErrorStatus(502)
                //根據响應的錯誤碼, 進行顯示
                else if (Number.isInteger(response?.status)) await showErrorStatus(response!.status)
                //服務器結果都沒有返回(可能服務器錯誤可能客戶端斷網), 判500
                else if (!window.navigator.onLine) await showErrorStatus(500)

                //不論錯誤或都會執行
                if (axiosConfigure?.completion?.final) axiosConfigure.completion.final()

                //測試區顯示錯誤訊息
                if (!isProd) console.log(error)

                return false

            }

        );
    }

    /**
     * GET 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    get<T>(url: string, params?: object, _object = {}): Promise<Respond.Data<T>> {
        return this.service.get(url, { params, ..._object });
    }

    /**
     * GET 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    post<T>(url: string, params?: object, _object = {}): Promise<Respond.Data<T>> {
        return this.service.post(url, params, _object);
    }

    /**
     * PUT 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    put<T>(url: string, params?: object, _object = {}): Promise<Respond.Data<T>> {
        return this.service.put(url, params, _object);
    }

    /**
     * DELETE 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    delete<T>(url: string, params?: any, _object = {}): Promise<Respond.Data<T>> {
        return this.service.delete(url, { ..._object , data: params });
    }

}

export default new RequestHttp(config);
