export default {
    tip: {
        readonly: "總教會僅供查看資料, 無法新增修改"
    },
    mode: {
        on: "自行維護",
        off: "委託管理"
    },
    feature: {
        seq: {
            view: "前往查看",
            edit: "前往編輯",
        },
        friend: {
            convert: "轉入牧養會友",
            add: "標記為待轉入",
            clear: "標記為不轉入"
        },
        unauthorized: "未授權編輯",
        raise: "轉交愛無界團隊",
        copy: "複製",
        view: "查看",
        donate: "奉獻紀錄",
        edit: "編輯",
        more: "更多",
        enable: "啟用",
        disable: "停用",
        paid: "標示已付款",
        integrate: {
            not: {
                allow: "整合文章無法複製"
            }
        },
        comment: "留言內容",
        notification: {
            entry: "推播紀錄",
            create: "新增推播",
        },
        destroy: {
            event: "該群組下仍有 %s 個會友, 無法刪除",
            soul: "該分類下仍有 %s 篇文章, 無法刪除",
            activity: "該分類下仍有 %s 場活動, 無法刪除",
            live: "該分類下仍有 %s 場直播, 無法刪除",
            shepherd: {
                attend: "該分類下仍有 %s 個聚會, 無法刪除"
            },
            enable: "刪除",
            disable: "刪除前請先停用"
        },
        cycle: {
            history: "循環紀錄"
        },
        attend: {
            history: "聚會紀錄",
            schedule: "聚會排程"
        },
        simulation: "模擬登入",
        repair: "重建模擬"
    },
    field: {
        belong: "所屬教會",
        check: "選取",
        created: "建立時間",
        creator: "建立人員",
        updated: "最後編輯時間",
        editor: "最後編輯人員",
        accessed: "最後存取時間",
        prompt: "提醒",
        period: "循環",
        status: "狀態",
        view: "查看",
        edit: "操作",
    },
    holder: {
        status: "請選擇狀態",
    }
}
