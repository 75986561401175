export const Handler: {
    [key: string]: any
} = {
    session: {
        destroy: {
            subject: "即將登出系統",
            text: "請確認登出",
            description: ""
        }
    }
}
