/**
 * 新朋友
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增新朋友"
            }
        },
        text: {
            register: "自行註冊"
        },
        field: {
            head: {
                church: "指定教會"
            },
            avatar: "頭像",
            name: "名字",
            email: "電子郵件",
            from: "來源",
            relationship: "關係"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名字",
            email: "請輸入電子郵件"
        },
        handler: {
            add: {
                subject: `請確認將指定的會友<span class="text-danger">標記為待轉入</span>`,
                text: "請確認後執行"
            },
            clear: {
                subject: `請確認將指定的會友<span class="text-danger">標記為不轉入</span>`,
                text: "請確認後執行"
            },
            convert: {
                subject: `請確認將指定的會友<span class="text-danger">轉入至牧養會友</span>`,
                text: "請確認後執行"
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這名新朋友已經標記為不轉入"
        },
        commitment: {
            empty: "未填寫"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "來源"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            email: "電子郵件",
            name: "名字",
            title: "稱謂",
            birthday: "生日",
            phone: "聯絡電話",
            location: {
                country: "所在地區",
                city: "所在城市"
            },
            address: "聯絡地址",
            occupation: "職業",
            baptized: "受洗時間",
            commitment: "委身編號",
            created: "建立時間",
            relationship: "關係",
        },
        holder: {
            head: "未輸入",
            belong: {
                name: "",
                no: ""
            },
            email: "請輸入電子郵件",
            name: "請輸入名字",
            title: "未輸入稱謂",
            birthday: "未輸入生日",
            phone: "未輸入聯絡電話",
            location: {
                country: "未選擇所在地區",
                city: "未選擇所在城市"
            },
            address: "未輸入聯絡地址",
            occupation: "未輸入職業",
            baptized: "未輸入受洗時間",
            commitment: "請輸入委身編號",
            created: "",
            accessed: "",
        },
        handler: {
            add: {
                subject: `請確認將指定的會友<span class="text-danger">標記為待轉入</span>`,
                text: "請確認後執行"
            },
            clear: {
                subject: `請確認將指定的會友<span class="text-danger">標記為不轉入</span>`,
                text: "請確認後執行"
            },
            convert: {
                subject: `請確認將指定的會友<span class="text-danger">轉入至牧養會友</span>`,
                text: "請確認後執行"
            }
        }
    }

}
