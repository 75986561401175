<template>
  <div :class="[
       'main-menu',
       'menu-fixed',
       'menu-accordion',
       'menu-shadow',
       isCollapsed ? null : 'expanded',
       active.is.over && isCollapsed ? 'expanded' : null,
       'menu-light']"
       @mouseenter="onOver(true)"
       @mouseleave="onOver(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header">
        <ul class="nav navbar-nav flex-row justify-content-between">
          <li class="nav-item mr-auto">
            <router-link
                :to="{ name: getToggle}"
                class="navbar-brand router-link-active" target="_self">
              <span class="brand-logo">
                <img :src="getBrandLogo"
                     alt="logo" />
              </span>
            </router-link>
          </li>
          <li class="nav-item nav-toggle">
            <a v-if="!props.isMinimal"
               @click="setCollapsed(!isCollapsed)"
               class="cgBase nav-link modern-nav-toggle">
              <icon-sax v-if="isCollapsed"
                        class="ft-20"
                        type="sidebar-right" />
              <icon-sax v-else
                        class="ft-20"
                        type="sidebar-left" />
            </a>
            <a v-else
               @click="setOverlay(false)"
               class="cgBase nav-link modern-nav-toggle">
              <icon-sax class="ft-20"
                        type="x" />
            </a>
          </li>
        </ul>
      </slot>
    </div>
    <div :style="{
      display: active.is.top ? 'none' : 'block'
    }" class="shadow-bottom" />
    <el-scrollbar
        @scroll="onScroll"
        height="100%"
        class="ps-container main-menu-content scroll-area ps ps--active-y">
      <vertical-menu-item
          :items="getItem"
          class="navigation navigation-main" />
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"
import VerticalMenuItem from "@/component/menu/vertical/item/VerticalMenuItem.vue"

import {reactive, withDefaults, onMounted, computed} from "vue"
import {useNavigationItem} from "@/navigation"
import {useMenu} from "@/hook/useMenu"
import {useRoute} from "vue-router"
import {usePermission} from "@/hook/usePermission"
import {getCaption} from "@/api/module/caption"
import {useUser} from "@/hook/useUser"
import {EnumPortalName, EnumPortalRouter} from "@/enum/portal";

const {
  isCollapsed,
  setOverlay,
  setCollapsed
} = useMenu()

const {
  getUser,
  setUserCaption,
} = useUser()

const {
  getMenuItem
} = useNavigationItem()

const {
  isHead
} = usePermission()

const route = useRoute()

const props = withDefaults(defineProps<{
  //是否縮到sm以下
  isMinimal?: boolean
}>(),{
  isMinimal: false
})

//本地端控制參數
const active = reactive<{
  is: {
    //選單垂直移動距離
    top: boolean,
    //滑鼠是否正滑過的折疊選單
    over: boolean
  }
}>({
  is: {
    top: true,
    over: false
  }
})

//回傳滑鼠是否正滑過, 滑過展開功能表, 移除關閉功能表
const onOver = (isOver: boolean) => active.is.over = isOver

//選單捲動
const onScroll = (e?: any) => {
  if (e===undefined) active.is.top = true
  else active.is.top = e.scrollTop === 0
}

//返回切換系統的名稱
const getToggle = computed((): EnumPortalRouter => {
  return route.meta.portal === EnumPortalName.ADMIN ? EnumPortalRouter.ADMIN : EnumPortalRouter.SHEPHERD
})

//獲取項目
const getItem = computed(()=>{
  //總教會不出現的項目 (不受權限控制的頁面)
  if (isHead.value) return getMenuItem.value.filter(i=>![
    `Billboard`
  ].includes(i.route))
  return getMenuItem.value.filter(i=>{
    //心靈捕手自行維護, 不顯示左側選單
    if (getUser.value.church.delegate.soul && i.id === 6) return false
    //每日靈糧自行維護, 不顯示左側選單
    else if (getUser.value.church.delegate.daily && i.id === 7) return false
    return true
  })
})

//獲取機構圖
const getBrandLogo = computed(()=>{
  return "/img/organization/1/logo.png"
})

const initial = async () => {

  // v2不執行取得標題功能
  if (route.meta?.v2 ?? false) return

  const {
    data
  } = await getCaption()

  //注入用戶資訊
  if (data?.data) setUserCaption(data.data)

}

onMounted(()=>{
  onScroll()
  initial()
})

//主動暴露
defineExpose({
  initial
})

</script>

<style scoped lang="sass">
.nav
  .modern-nav-toggle
    margin-top: 1.7rem
    margin-right: .1rem
.main-menu
  .navbar-header
    padding-left: 22px !important
    padding-top: 0 !important
    z-index: 3
    .brand-logo
      > img
        border-radius: 0.375rem
</style>
