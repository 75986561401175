import attend from "@/language/modules/component/attend/zhCN"
import menu from "@/language/modules/component/menu/zhCN"
import remind from "@/language/modules/component/remind/zhCN"
import live from "@/language/modules/component/live/zhCN"
import cycle from "@/language/modules/component/cycle/zhCN"
import qrcode from "@/language/modules/component/qrcode/zhCN"
import billboard from "@/language/modules/component/billboard/zhCN"
import church from "@/language/modules/component/church/zhCN"
import chart from "@/language/modules/component/chart/zhCN"
import swal from "@/language/modules/component/swal/zhCN"
import shepherd from "@/language/modules/component/shepherd/zhCN"
import connect from "@/language/modules/component/connect/zhCN"
import client from "@/language/modules/component/client/zhCN"
import editor from "@/language/modules/component/editor/zhCN"
import avatar from "@/language/modules/component/avatar/zhCN"
import breadcrumb from "@/language/modules/component/breadcrumb/zhCN"
import article from "@/language/modules/component/article/zhCN"
import tag from "@/language/modules/component/tag/zhCN"
import upload from "@/language/modules/component/upload/zhCN"
import cropper from "@/language/modules/component/cropper/zhCN"
import lightbox from "@/language/modules/component/lightbox/zhCN"
import permission from "@/language/modules/component/permission/zhCN"
import theme from "@/language/modules/component/theme/zhCN"
import notification from "@/language/modules/component/notification/zhCN"
import event from "@/language/modules/component/event/zhCN"
import message from "@/language/modules/component/message/zhCN"
import input from "@/language/modules/component/input/zhCN"
import password from "@/language/modules/component/password/zhCN"
import select from "@/language/modules/component/select/zhCN"
import autocomplete from "@/language/modules/component/autocomplete/zhCN"
import form from "@/language/modules/component/form/zhCN"
import offCanvas from "@/language/modules/component/offCanvas/zhCN"
import table from "@/language/modules/component/table/zhCN"
import pagination from "@/language/modules/component/pagination/zhCN"
import sortable from "@/language/modules/component/sortable/zhCN"
import footer from "@/language/modules/component/footer/zhCN"
import choice from "@/language/modules/component/choice/zhCN"
import scroller from "@/language/modules/component/scroller/zhCN"
import activity from "@/language/modules/component/activity/zhCN"
import integrate from "@/language/modules/component/integrate/zhCN"
import recipient from "@/language/modules/component/recipient/zhCN"
import signature from "@/language/modules/component/signature/zhCN"
import support from "@/language/modules/component/support/zhCN"

export default {

    signature: signature,

    support: support,

    recipient: recipient,

    attend: attend,

    menu : menu,

    remind: remind,

    live: live,

    cycle: cycle,

    qrcode: qrcode,

    billboard: billboard,

    church: church,

    chart: chart,

    swal: swal,

    shepherd: shepherd,

    connect: connect,

    client: client,

    editor: editor,

    avatar: avatar,

    breadcrumb: breadcrumb,

    article: article,

    tag: tag,

    upload: upload,

    cropper: cropper,

    lightbox: lightbox,

    permission: permission,

    theme: theme,

    notification: notification,

    event: event,

    message: message,

    input: input,

    password: password,

    select: select,

    autocomplete: autocomplete,

    form: form,

    offCanvas: offCanvas,

    table: table,

    pagination: pagination,

    sortable: sortable,

    footer: footer,

    choice: choice,

    scroller: scroller,

    activity: activity,

    integrate: integrate

}
