<script setup lang="ts">

import VueEasyLightbox from "vue-easy-lightbox";

import {reactive} from "vue";
import {useCross} from "@/v2/hooks/cross";
import {TablerIconEnum} from "@/v2/enumerate/component/tabler";
import JTablerIcon from "@/v2/components/tabler/index.vue";

const {
  getLightBox,
  hideLightBox,
  onSaveLightBox
} = useCross()

/**
 * 本地 - 參數
 */
const active = reactive<{
  //初始化
  i18n: string
  //控制項
  is: {
    //幻燈箱
    lightbox: {
      //工具列
      toolbar: {
        //圖示
        icon: {
          //尺寸
          size: number
        }
      }
    }
  }
}>({
  i18n: "component.lightbox.v2",
  is: {
    lightbox: {
      toolbar: {
        icon: {
          size: 24
        }
      }
    }
  }
})

</script>

<template>
  <vue-easy-lightbox
      teleport="body"
      @hide="hideLightBox()"
      @on-index-change="(_, i)=>{ getLightBox.index=i }"
      :loop="true"
      :visible="getLightBox.show"
      :imgs="getLightBox.images"
      :index="getLightBox.index">
    <template #toolbar="{ toolbarMethods }">
      <div class="vel-toolbar">
        <el-tooltip
            class="box-item"
            :content="$t(`${active.i18n}.zoom.in.subject`)"
            placement="top">
          <div @click="toolbarMethods.zoomIn"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :name="TablerIconEnum.ZOOM_IN" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`${active.i18n}.zoom.out.subject`)"
            placement="top">
          <div @click="toolbarMethods.zoomOut"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :name="TablerIconEnum.ZOOM_OUT" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`${active.i18n}.resize.subject`)"
            placement="top">
          <div @click="toolbarMethods.resize"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :name="TablerIconEnum.ARROWS_MAXIMIZE" />
          </div>
        </el-tooltip>
        <el-tooltip
            v-if="getLightBox.download"
            class="box-item"
            :content="$t(`${active.i18n}.download.subject`)"
            placement="top">
          <div @click="onSaveLightBox"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :name="TablerIconEnum.DOWNLOAD" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`${active.i18n}.rotate.left.subject`)"
            placement="top">
          <div @click="toolbarMethods.rotateLeft()"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :style="{transform: `rotateY(180deg)`}"
                :name="TablerIconEnum.ROTATE_CLOCKWISE_2" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`${active.i18n}.rotate.right.subject`)"
            placement="top">
          <div @click="toolbarMethods.rotateRight()"
               class="toolbar-btn text-white d-flex justify-content-center align-items-center">
            <j-tabler-icon
                :size="active.is.lightbox.toolbar.icon.size"
                :name="TablerIconEnum.ROTATE_CLOCKWISE_2" />
          </div>
        </el-tooltip>
      </div>
    </template>
  </vue-easy-lightbox>
</template>

<style scoped lang="scss">

</style>