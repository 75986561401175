import http from "@/api";
import {Respond} from "@/api/interface/respond";
import {Mindcatcher} from "@/api/interface/mindcatcher";

/**
 * 心靈捕手 - 確認
 */
export const getMindcatcher = (params: Mindcatcher) => {
    return http.post<Respond.Basic>(`/api/mindcatcher`, params);
};

/**
 * 心靈捕手 - 取得 - 內容
 */
export const getMindcatcherEdit = (params: Mindcatcher) => {
    return http.post<Respond.Basic>(`/api/mindcatcher/edit`, params);
};
