export const Choose: {
    [key: string]: any
} = {

    enable: {
        subject: "啟用點名選項",
        multiple: {
            subject: "點名選項可複選"
        }
    },
    field: {
        name: {
            holder: "請輸入點名選項名稱"
        }
    },
    join: {
        subject: "新增點名選項"
    },
    submit: {
        enable: {
            subject: "啟用",
            reverse: {
                subject: "停用"
            }
        }
    },
    help: {
        picked: {
            subject: "已設定%s個點名選項",
            empty: {
                subject: "請設定點名選項",
            }
        }
    },
    error: {
        1001: {
            "subject": "請輸入點名選項名稱",
            "text": ""
        }
    }

}
