import {Tag} from "@/language/modules/component/form/v2/edit/tag/zhCN"
import {User} from "@/language/modules/component/form/v2/edit/user/zhCN"
import {Cycle} from "@/language/modules/component/form/v2/edit/cycle/zhCN"

export const Edit: {
    [key: string]: any
} = {

    tag: Tag,

    user: User,

    cycle: Cycle,

    error: {
        code: {
            subject: "请先清除表单错误"
        }
    },
    alert: {
        store: {
            subject: "您没有建立的权限"
        },
        update: {
            subject: "您没有刷新的权限",
            head: {
                subject: "总教会仅供查看资料, 无法刷新内容"
            }
        },
        cycle: {
            monitor: {
                modified: {
                    subject: "聚会时间已经变动, 请您留意"
                }
            },
            schedule: {
                last: {
                    complete: {
                        subject: "设置的所有循环均已完成, 将不会再继续生成相关单据与提醒"
                    }
                }
            }
        }
    },
    handler: {
        reset: {
            subject: "即将重设内容",
            text: "异动内容将不会保存, 请确认后执行"
        },
        entry: {
            subject: "即将返回列表",
            text: "异动内容将不会保存, 请确认后执行"
        },
        cycle: {
            monitor: {
                subject: "聚会时间已经变动",
                text: "建议您重新检视循环与提醒设置"
            }
        }
    }
}