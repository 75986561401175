/**
 * 奉献管理
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "奉献汇出_会友管理",
        },
        field: {
            head: {
                church: "指定教会"
            },
            begin: "奉献日期(起)",
            end: "奉献日期(迄)",
            avatar: "头像",
            name: "名字",
            order: "订单号",
            amount: "奉献金额",
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择奉献日期(起)",
            end: "请选择奉献日期(迄)",
            name: "请输入名字",
            order: "请输入订单号"
        },
        factory: {
            export: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将进行奉献汇出, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tabs: {
            0: {
                subject: "基本",
            },
            1: {
                subject: "回应"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            created: "建立时间",
            payed: "付款时间",
            order: "订单号",
            id: "会友ID",
            no: "会友编号",
            name: "会友姓名",
            account: "会友帐户",
            amount: "奉献金额",
            phone: "联络电话",
            payload: "结果",
            status: "金流状态"
        },
        holder: {
            phone: "未填写联络电话"
        }
    }

}
