import {Worker} from "@/language/modules/page/admin/support/v2/worker/zhCN"

export const v2: {
    [key: string]: any
} = {

    /**
     * 维修工单
     */
    worker: Worker,

}
