/**
 * 枚舉
 * WebSocket - 指令
 * @author J
 * @since 2024-04-30 07:34:25
 */

export enum WebSocketUrlEnum {

    /**
     * websocket
     */
    WEBSOCKET = 1,

    /**
     * qrcode
     */
    QRCODE = 2

}

export enum WebsocketCommandEnum {

    /**
     * 連接成功
     */
    CONNECTED = "CONNECTED"

}