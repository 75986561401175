import {NavigationItemRaw} from '@/navigation/interface'

const activity: NavigationItemRaw[] = [{
    id: 18,
    right: true,
    subject: 'admin.activity.subject',
    route: null,
    icon: 'save-2',
    children: [{
        id: 18001,
        right: true,
        subject: 'admin.activity.index',
        route: 'AdminActivity',
        attached: [
            'AdminActivityCreate',
            'AdminActivityEdit',
            'AdminActivityEditRegister',
            'AdminActivityEditRegistered',
            'AdminActivityEditRegisteredEdit'
        ]
    }, {
        id: 18011,
        right: true,
        subject: 'admin.activity.category.index',
        route: 'AdminActivityCategory',
        attached: [
            'AdminActivityCategoryCreate',
            'AdminActivityCategoryEdit'
        ]
    }],
    attached: []
}]

export default activity
