export default {

    /**
     * 列表
     */
    index: {
        field: {
            attend: "聚会",
            team: "族群",
            begin: "开始时间",
            end: "结束时间"
        },
        holder: {
            attend: "请选择聚会",
            team: "请选择族群",
            begin: "请输入开始时间",
            end: "请输入结束时间"
        }
    }

}
