/**
 * 问题集管理
 */
export default {
    subject: "问题集管理",
    group: {
        index: "问题群组",
        create: "问题集创建",
        edit: "问题集内容",
        permission: "问题集管理 - 问题群组",
    },
    question: {
        index: "问题设置",
        create: "问题设置创建",
        edit: "设置内容",
        permission: "问题集管理 - 问题设置"
    },
    option: {
        index: "回复选项",
        create: "回复选项创建",
        edit: "选项内容",
        permission: "问题集管理 - 回复选项"
    },
    form: {
        index: "表单纪录",
        edit: "纪录内容",
        permission: "问题集管理 - 表单纪录"
    }
}
