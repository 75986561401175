/**
 * 会友 - 进阶
 */
export default {
    tabs: {
        0: {
            subject: "批次委派"
        },
        1: {
            subject: "会友汇入"
        },
        2: {
            subject: "会友汇出"
        },
        3: {
            subject: "全部删除"
        }
    },
    step: {
        delegate: [{
            subject: "步骤1",
            text: "选择前往族群",
            description: "请先指定一个需要前往的族群"
        },{
            subject: "步骤2",
            text: "选择会友",
            description: "请选择需要前往的族群的会友"
        },{
            subject: "步骤3",
            text: "完成委派",
            description: ""
        }]
    },
    submit: {
        delegate: {
            prev: "上一步",
            next: {
                off: "请先选择族群",
                subject: "下一步"
            },
            accept: {
                off: "请先选择会友",
                subject: "确认委派"
            },
            reset: "重新委派"
        },
        import: {
            name: `汇入会友文档范例_完整`,
            template: "下载范例",
            off: "请先汇入会友资讯",
            refresh: "重新汇入",
            accept: "执行汇入",
        },
        export: {
            accept: "执行汇出",
        },
        destroy: {
            accept: "执行删除"
        }
    },
    field: {
        category: "类别",
        keyword: "关键字"
    },
    holder: {
        category: "请选择类别",
        keyword: "请输入关键字"
    },
    handler: {
        delegate: {
            subject: "即将进行批次委派",
            text: "请确认后执行",
            description: ""
        },
        import: {
            reset: {
                subject: "即将进行重新汇入",
                text: "请确认后执行",
                description: ""
            },
            upload: {
                subject: "即将进行会友汇入",
                text: "请确认后执行",
                description: ""
            }
        }
    },
    factory: {
        export: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将进行会友汇出, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        },
        destroy: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将进行全部会友删除, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }
}
