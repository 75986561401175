import {NavigationItemRaw} from '@/navigation/interface'

const team: NavigationItemRaw[] = [{
    id: 54,
    right: false,
    subject: 'shepherd.team.index',
    route: 'ShepherdTeam',
    icon: 'category',
    children: [],
    //無法歸類的放首頁
    attached: [
        "ShepherdTeamAttend",
        "ShepherdTeamStatistics",
        "ShepherdTeamStatisticsAttend",
        "ShepherdTeamStatisticsEvent",
        "ShepherdTeamStatisticsGender",
        "ShepherdTeamStatisticsYear",
        "ShepherdTeamStatisticsDeacon",
        "ShepherdTeamStatisticsEducation",
        "ShepherdTeamStatisticsOccupation",
        "ShepherdTeamStatisticsMarital",
        "ShepherdTeamStatisticsBaptized"
    ],
}]
export default team
