/**
 * 教學管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增教學"
            },
        },
        field: {
            avatar: "封面圖",
            name: "名稱",
            sort: "排序",
            created: "建立時間"
        },
        holder: {
            name: "請輸入名字"
        },
        handler: {
            enable: {
                subject: "即將啟用教學",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用教學",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除教學, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            name: "名稱",
        },
        holder: {
            name: "請輸入名稱"
        },
        handler: {
            store: {
                subject: "即將新增分類",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            disabled: "請注意, 這個教學已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "排序"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            name: "名稱",
        },
        holder: {
            name: "請輸入名稱"
        },
        handler: {
            update: {
                subject: "即將更新分類內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            }
        }
    }

}
