export default {
    horizontal: {
        user: {
            robot: "模擬登入中",
            account: "帳號名稱",
            timezone: "所在時區",
            version: {
                ui: "UI版本",
                core: "服務版本"
            },
            websocket: {
                communication: "通訊狀態",
                status: {
                    connected: "連線成功",
                    disconnect: "目前斷線"
                }
            },
            flush: "釋放快取",
            handler: {
                flush: {
                    subject: "即將手動釋放快取",
                    text: "請確認後執行"
                }
            }
        },
        billboard: {
            subject: "公告欄",
            unread: `您還有 <b class="ft-14 text-danger">%s</b> 筆公告未讀取`,
            segue: "前往公告欄"
        },
        invitation: {
            subject: "教會邀請碼"
        }
    }
}
