import {History} from "@/language/modules/page/admin/support/v2/worker/edit/history/zhTW"

export const Edit: {
    [key: string]: any
} = {

    /**
     * 歷史紀錄
     */
    history: History,

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "帳號"
        },
        2: {
            subject: "附件"
        },
        98: {
            subject: "統計資訊",
        },
        99: {
            subject: "相關資訊",
        }
    },
    action: {
        history: {
            create: {
                subject: "新增紀錄"
            }
        }
    },
    field: {
        no: {
            subject: "單號",
            holder: ""
        },
        sample: {
            subject: "樣本ID",
            holder: "請輸入樣本ID"
        },
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        role: {
            subject: "角色",
            holder: "請選擇角色"
        },
        type: {
            subject: "類型",
            holder: "請選擇類型"
        }
    },
    statistics: {
        history: {
            subject: "歷史紀錄"
        },
        record: {
            subject: "異動紀錄"
        }
    },
    alert: {
        readonly: {
            subject: "工單已經標示完成無法進行修改"
        }
    },
    handler: {
        submit: {
            subject: "即將更新工單",
            text: "請確認後執行"
        }
    },
    error: {
        1010: {
            "subject": "請選擇角色",
            "text": ""
        },
        1020: {
            "subject": "請輸入樣本ID",
            "text": ""
        },
        1021: {
            "subject": "樣本ID請輸入數字",
            "text": ""
        },
        1030: {
            "subject": "請選擇類型",
            "text": ""
        },
        1040: {
            "subject": "請輸入標題",
            "text": ""
        },
        1050: {
            "subject": "請輸入描述",
            "text": ""
        }
    }

}
