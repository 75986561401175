import {computed} from "vue";
import {SettingStore} from "@/store/modules/setting";
import {SettingState} from "@/store/interface/setting";
import {EnumTheme} from "@/enum/theme";
import {EnumUserSettingAuthorized, EnumUserSettingOpen} from "@/enum/user";
import {EnumWebsocketStatus} from "@/enum/websocket";

/**
 * 登入者資訊
 */

export const useSetting = () => {

    /**
     * 用戶 store
     */
    const settingStore = SettingStore()

    /**
     * 是否為深色主題
     */
    const isDarkTheme = computed((): boolean => {
        return settingStore.isDarkTheme
    })

    /**
     *  切換主體
     */
    const setTheme = async (value: EnumTheme) => {
        settingStore.setTheme(value)
        const body = document.getElementsByTagName("BODY")[0];
        //切換的時候, 加入一個暫時關閉 .form-control transition的功能
        //不然切換的時候會閃一下很醜
        body.classList.add("pv")
        value === EnumTheme.DARK ? body.classList.add("dark-layout") : body.classList.remove("dark-layout")
        setTimeout(()=>{
            //完成後在去掉暫時transition的功能
            body.classList.remove("pv")
        }, 50)
    }

    /**
     * 獲取 - 用戶 - 個人設定
     */
    const getSetting = computed((): SettingState => {
        return settingStore.getAll
    })

    /**
     * 更新 - 用戶 - 個人設定 - 列表 - 開啟模式
     */
    const setSettingEntriesEdit = (
        e: EnumUserSettingOpen
    ) => settingStore.setEntriesEdit(e)

    /**
     * 更新 - 用戶 - 個人設定 - 認證方式
     */
    const setSettingAuthorized = (
        e: EnumUserSettingAuthorized
    ) => settingStore.setAuthorized(e)


    /**
     * 設置 - websocket - 連接狀態
     */
    const setSettingWebsocketConnectStatus = (
        connected: EnumWebsocketStatus
    ) => settingStore.setWebsocketConnectStatus(connected)

    return {
        isDarkTheme,
        setTheme,
        getSetting,
        setSettingEntriesEdit,
        setSettingAuthorized,
        setSettingWebsocketConnectStatus
    }

}
