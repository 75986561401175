import http from "@/api";
import {AxiosDefaultOption,} from "@/api/interface/configure";
import {AdminChurch} from "@/api/interface/core/admin/church";
import {Respond} from "@/api/interface/respond";

/**
 * 教會 - 列表
 */
export const getAdminChurch = (
    params: any
) => {
    return http.post<Respond.Pagination<AdminChurch.Entry.Respond>>(`/api/admin/church`, params);
};

/**
 * 教會 - 列表 - 選項值
 */
export const getAdminChurchOption = () => {
    return http.get<Respond.Data<AdminChurch.Entry.Option>>(`/api/admin/church/option`, {}, {
        opts: AxiosDefaultOption
    });
};

/**
 * 教會 - 建立(權限確認)
 */
export const getAdminChurchCreate = () => {
    return http.get<Respond.Data<AdminChurch.Create.Field>>(`/api/admin/church/create`, {});
};

/**
 * 教會 - 建立 - 選項值
 */
export const getAdminChurchCreateOption = () => {
    return http.get<Respond.Data<AdminChurch.Edit.Option>>(`/api/admin/church/create/option`, {}, {
        opts: AxiosDefaultOption
    });
};

/**
 * 教會 - 建立 - 提交
 */
export const storeAdminChurchCreate = (
    params: any,
    completion: (e: Respond.Data<Respond.Store>) => void
) => {
    return http.put<Respond.Data<Respond.Store>>(`/api/admin/church/create`, params, {
        opts: {
            completion: {
                done: (e: Respond.Data<Respond.Store>) => completion(e)
            }
        }
    });
};

/**
 * 教會 - 編輯
 */
export const getAdminChurchEdit = (
    uuid?: any
) => {
    const request: string = uuid ? `/api/admin/church/edit/${uuid}` : `/api/admin/church/edit`
    return http.get<Respond.Data<AdminChurch.Edit.Field>>(request, {});
};

/**
 * 教會 - 編輯 - 選項值
 */
export const getAdminChurchEditOption = (
    uuid?: any
) => {
    const request: string = uuid ? `/api/admin/church/edit/${uuid}/option` : `/api/admin/church/edit/option`
    return http.get<Respond.Data<AdminChurch.Edit.Option>>(request, {}, {
        opts: AxiosDefaultOption
    });
};

/**
 * 教會 - 編輯 - 提交
 */
export const updateAdminChurchEdit = (
    uuid: any,
    params: any,
    completion: (e: Respond.Data<Respond.Update>) => void
) => {
    const url = uuid === undefined ? `/api/admin/church/edit` : `/api/admin/church/edit/${uuid}`
    return http.post<Respond.Data<Respond.Update>>(url, params, {
        opts: {
            completion: {
                done: (e: Respond.Data<Respond.Update>) => completion(e)
            }
        }
    });
};

/**
 * 重建模擬
 */
export const getAdminChurchRepair = (
    params: any,
    completion: (e: Respond.Data<any>) => void
) => {
    return http.post<Respond.Data<any>>(
        `/api/admin/church/repair`,
        params,
        {
            opts: {
                completion: {
                    done: (e: Respond.Data<any>) => completion(e)
                }
            }
        }
    );
};

/**
 * 模擬登入
 */
export const getAdminChurchSimulation = (
    params: any,
    completion: (e: Respond.Data<Respond.Simulation>) => void
) => {
    return http.post<Respond.Data<Respond.Simulation>>(
        `/api/admin/church/simulation`,
        params,
        {
            opts: {
                builtIn: {
                    done: false
                },
                completion: {
                    done: (e: Respond.Data<Respond.Simulation>) => completion(e)
                }
            }
        }
    );
};

/**
 * 返回管理介面
 */
export const getAdminChurchHead = (
    completion: (e: Respond.Data<Respond.Simulation>) => void
) => {
    return http.get<Respond.Data<Respond.Simulation>>(
        `/api/admin/church/head`,
        {},
        {
            opts: {
                builtIn: {
                    done: false
                },
                completion: {
                    done: (e: Respond.Data<Respond.Simulation>) => completion(e)
                }
            }
        }
    );
};

