import {RouteRecordRaw} from "vue-router";

const teach: RouteRecordRaw[] = [{
	//教學管理
	path: "teaches",
	name: "AdminTeach",
	meta: {
		permission: 12001,
		breadcrumb: [{
			key: 'admin.teach.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/teach/index.vue"),
}, {
	//教學新增
	path: "teaches/create",
	name: "AdminTeachCreate",
	meta: {
		permission: 12002,
		breadcrumb: [{
			key: 'admin.teach.index',
			value: "AdminTeach"
		}, {
			key: 'admin.teach.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/teach/create.vue"),
}, {
	//教學編輯
	path: "teaches/edit/:uuid",
	name: "AdminTeachEdit",
	meta: {
		permission: 12004,
		breadcrumb: [{
			key: 'admin.teach.index',
			value: "AdminTeach"
		}, {
			key: 'admin.teach.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/teach/edit.vue"),
}];

export default teach;
