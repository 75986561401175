export default {
    type: {
        name: {
            1: "文章",
            2: "推播",
            3: "服事"
        },
        disabled: "这个选项暂时无法使用"
    },
    column: {
        once: "单次",
        day: "每日",
        week: "每周",
        month: "每月",
        year: "每年"
    },
    schedule: {
        subject: "排程预览",
        text: `共计 <b class="ft-14 text-success">%s</b> 个排程`,
        th: {
            0: {
                subject: "#"
            },
            1: {
                subject: "开始时间"
            },
            2: {
                subject: "结束时间"
            }
        }
    },
    editor: {
        help: {
            begin: {
              weekday: {
                  subject: "首次起始日为星期%s, 不能取消"
              }
            },
            resource: {
                subject: "目前最长可定义 %s 年周期, 请妥善利用资源",
            },
            more: `如果范围中的月份没有第 <b class="text-success">%s</b> 天, 则会自动跳过`
        },
        field: {
            cycle: "方式",
            duration: "时长"
        },
        spinner: {
            day: ["每", "日"],
            week: ["每", "周"],
            month: ["每", "个月"],
            year: ["每", "年"]
        },
        duration: {
            specific: "总计次数"
        },
        method: {
            month: {
                day: {
                    at: "在第 %s 天重复",
                    last: "在最后一天重复"
                },
                week: {
                    at: "在第%s周的%s",
                    last: "在最后一个%s重复"
                },
                select: "选取希望重复的日期"
            },
            year: {
                day: {
                    at: "在 %s 的第 %s 天重复",
                    last: "在 %s 的最后一天重复"
                },
                week: {
                    at: "在 %s 的第%s周的%s重复",
                    last: "在 %s 的最后一个%s重复"
                },
                select: "选取希望在 %s 号重复的月份"
            }
        },
        error: {
            1001: {
                "subject": "请指定至少一天",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请指定选项",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请指定需要重复的日期",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请指定需要重复的月份",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请指定时长",
                "text": "",
                "description": ""
            }
        }
    }
}
