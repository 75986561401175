/**
 * 歷程管理
 */
export default {

    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "頭像",
            name: "名字",
            role: "來源",
            level: "等級",
            code: "結果代碼",
            exception: "攔截",
            module: "模組"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            name: "請輸入名字",
            empty: {
                email: "未輸入"
            },
            module: "請選擇模組"
        },
        handler: {
        }
    },

    /**
     * 編輯
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相關資訊"
            }
        },
        tip: {
            unknown: "此紀錄為未知來源"
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            seq: "流水號",
            name: "名字",
            account: "帳號",
            role: "來源",
            level: "等級",
            ref: {
                name: "攔截呼叫",
                church: "標的教會",
                seq: "標的物件流水號"
            },
            module: "攔截類",
            method: "攔截函式",
            code: "結果代碼",
            rollback: "回滾事務",
            subject: "標題",
            text: "副標題",
            description: "描述",
            payload: "提交參數",
            created: "建立時間"
        },
        holder: {
            subject: "無標題",
            text: "無副標題",
            description: "無描述"
        },
        handler: {
        }
    }

}
