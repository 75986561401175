export const Attachment: {
    [key: string]: any
} = {
    subject: "加入附件",
    selected: {
        subject: `已經選擇 <b class="text-danger ft-16">%s</b> 個附件`
    },
    th: {
        0: {
            subject: "類型"
        },
        1: {
            subject: "操作"
        }
    },
    field: {
        subject: "標題",
        description: "描述",
        mime: "類型",
        url: "附件網址"
    },
    holder: {
        subject: "請輸入標題",
        description: "請輸入描述",
        url: "請輸入附件網址"
    },
    empty: {
        subject: "請點擊新增進行加入附件"
    },
    complete: {
        not: {
            subject: "請先完善表單"
        }
    },
    help: {
        subject: "請輸入標題",
        url: {
            subject: "請輸入附件網址",
            illegal: {
                subject: "請輸入正確的附件網址"
            }
        }
    },
    handler: {
        delete: {
            subject: "即將刪除指定附件",
            text: ""
        }
    }
}
