export default {
    client: "會友人數",
    coming: "會友新增",
    delete: "會友刪除",
    publish: "上稿次數",
    notification: "發送推播",
    bible: "聖經開啟",
    favorite: "收藏次數",
    comment: "留言次數",
    share: "分享次數",
    visit: "文章觀看",
    social: "外連觀看",
    login: "登入次數"
}
