/**
 * 會友管理
 */
export default {
    index: "會友管理",
    create: "會友新增",
    edit: "會友內容",
    advance: {
        subject: "進階功能",
        permission: "會友管理 - 進階功能"
    },
    permission: "會友管理",
    attend: {
        index: "聚會紀錄",
        edit: "聚會內容"
    }
    // history: {
    //     course: "課程紀錄",
    //     reunion: "聚會紀錄"
    // }
}
