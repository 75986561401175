import {RouteRecordRaw} from "vue-router";
import {RouteNameEnum} from "@/v2/enumerate/route";

const dashboard: RouteRecordRaw[] = [{
	path: "dashboard",
	name: RouteNameEnum.SHEPHERD_DASHBOARD,
	meta: {
		permission: 51001,
		breadcrumb: []
	},
	component: () => import("@/page/content/shepherd/dashboard/index.vue"),
}];

export default dashboard;
