import {RouteRecordRaw} from "vue-router";
import {PermissionEnum} from "@/v2/enumerate/permission";
import {RouteNameEnum} from "@/v2/enumerate/route";

const v2: boolean = (import.meta.env.VITE_VERSION as string) === 'v2'

const attend: RouteRecordRaw[] = [{
	//聚會
	path: "attend",
	name: RouteNameEnum.SHEPHERD_ATTEND,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 55001,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.SHEPHERD_ATTEND,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/shepherd/attend/index.vue" : "@/page/content/shepherd/attend/index.vue"),
}, {
	//聚會 - 新增
	path: "attend/create",
	name: RouteNameEnum.SHEPHERD_ATTEND_CREATE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 55002,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.SHEPHERD_ATTEND,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: 'shepherd.attend.create',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/shepherd/attend/create/index.vue" : "@/page/content/shepherd/attend/create.vue"),
}, {
	//聚會 - 編輯
	path: "attend/edit/:uuid",
	name: RouteNameEnum.SHEPHERD_ATTEND_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 55004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.SHEPHERD_ATTEND,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: 'shepherd.attend.edit',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/shepherd/attend/edit/index.vue" : "@/page/content/shepherd/attend/edit.vue"),
}, {
	//聚會 - 聚會排程
	path: "attend/edit/:uuid/schedule",
	name: RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		permission: 55004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.SHEPHERD_ATTEND,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: "shepherd.attend.edit",
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT
		}, {
			key: 'shepherd.attend.schedule.index',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/shepherd/attend/edit/schedule/index.vue" : "@/page/content/shepherd/attend/schedule/index.vue"),
}/*, {
	//聚會 - 聚會紀錄 - 紀錄內容
	path: "attend/edit/:uuid/schedule/edit/:uid",
	name: RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE_EDIT,
	meta: {
		permission: 55004,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: "shepherd.attend.edit",
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT
		}, {
			key: 'shepherd.attend.schedule.index',
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE
		}, {
			key: 'shepherd.attend.schedule.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/schedule/edit.vue"),
}*/, {
	//聚會 - 聚會紀錄
	path: "attend/edit/:uuid/history",
	name: RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		// ✝️✝️ 總教會僅讀取標記 ✝️✝️
		head: {
			readonly: true
		},
		permission: 55004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.SHEPHERD_ATTEND,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: "shepherd.attend.edit",
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT
		}, {
			key: 'shepherd.attend.history.index',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/shepherd/attend/edit/history/index.vue" : "@/page/content/shepherd/attend/history/index.vue"),
}, {
	//聚會 - 聚會紀錄 - 紀錄內容
	path: "attend/edit/:uuid/history/edit/:uid",
	name: RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY_EDIT,
	meta: {
		permission: 55004,
		breadcrumb: [{
			key: 'shepherd.attend.subject',
			value: null
		}, {
			key: 'shepherd.attend.index',
			value: RouteNameEnum.SHEPHERD_ATTEND
		}, {
			key: "shepherd.attend.edit",
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT
		}, {
			key: 'shepherd.attend.history.index',
			value: RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY
		}, {
			key: 'shepherd.attend.history.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/history/edit.vue"),
}, {
	//聚會 - 分類
	path: "attend/category",
	name: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY,
	meta: {
		permission: 55011,
		breadcrumb: [{
			key: 'shepherd.attend.index',
			value: null
		}, {
			key: 'shepherd.attend.category.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/category/index.vue"),
}, {
	//聚會 - 類別 - 新增
	path: "attend/category/create",
	name: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_CREATE,
	meta: {
		permission: 55012,
		breadcrumb: [{
			key: 'shepherd.attend.index',
			value: null
		}, {
			key: 'shepherd.attend.category.index',
			value: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY
		}, {
			key: 'shepherd.attend.category.create',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/category/create.vue"),
}, {
	//聚會 - 類別 - 編輯
	path: "attend/category/edit/:uuid",
	name: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_EDIT,
	meta: {
		permission: 55014,
		breadcrumb: [{
			key: 'shepherd.attend.index',
			value: null
		}, {
			key: 'shepherd.attend.category.index',
			value: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY
		}, {
			key: 'shepherd.attend.category.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/category/edit.vue"),
}];

export default attend;
