export default {
    advance: {
        delegate: {
            done: {
                subject: "批次委派成功",
                text: `本次委派批次完成<br />共计 <b class="ft-16 text-success">%s</b> 位会友`
            },
            empty: "请至少选择<br />1位会友进行委派",
            mark: "已标注",
            status: [
                "不跟进",
                "未跟进",
                "已跟进"
            ]
        },
        import: {
            done: {
                subject: "会友汇入成功",
                text: `本次会友汇入完成<br />共计 <b class="ft-16 text-success">%s</b> 位会友`
            },
            th: [
                "姓名",
                "电子邮件",
                "称谓",
                "状态"
            ],
            status: "新朋友",
            subject: "请将汇入文件拖至此处",
            text: "或点击上传",
            description: `只能上传Excel文件(文档格式为xlsx)文件，且不超过<b class="text-danger opacity-75">10MB</b>容量`
        },
        export: {
            subject: "即将汇出全部会友",
            text: "请确认后执行"
        },
        destroy: {
            subject: "即将删除全部会友",
            text: "操作无法返回, 请确认后执行"
        }
    }
}
