/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */


/**
 * 頭像 - 選單 - 水平
 */
export enum EnumAvatarMenuHorizontal {

    /**
     * 頭像
     */
    HEAD = 1001

}

/**
 * 頭像 - 公告
 */
export enum EnumAvatarBillboard {

    /**
     * 列表
     */
    ENTRY = 2001,

    /**
     * 選單
     */
    MENU

}

/**
 * 頭像 - 我的教會
 */
export enum EnumAvatarAdmin {

    /**
     * 教會封面圖
     */
    CHURCH = 3001,
    /**
     * 上傳
     */
    UPLOAD,
    /**
     * 圖庫
     */
    GALLERY,
    /**
     * 管理員管理
     */
    USER,
    /**
     * 會友管理
     */
    CLIENT,
    /**
     * 標籤管理
     */
    TAG,
    /**
     * 我的教會
     */
    MY,
    /**
     * 心靈捕手
     */
    SOUL,
    /**
     * 心靈捕手分類
     */
    SOUL_CATEGORY,
    /**
     * 每日靈糧
     */
    DAILY,
    /**
     * 編輯推播名單
     */
    RECIPIENT,
    /**
     * 編輯推播名單
     */
    MEMBERSHIP,
    /**
     * 教學管理
     */
    TEACH,
    /**
     * 存取紀錄
     */
    COURSE_ACCESS,
    /**
     * 存取紀錄
     */
    COURSE_AUTHORIZE,
    /**
     * 攔截紀錄
     */
    COURSE_EXCEPTION,

}

/**
 * 頭像 - 牧養系統
 */
export enum EnumAvatarShepherd {

    /**
     * 新朋友
     */
    FRIEND = 4001

}
