/**
 * 直播管理
 */
export default {
    subject: "直播管理",
    index: "直播清單",
    create: "直播新增",
    edit: {
        subject: "直播內容",
        comment: {
            subject: "聊天室發言",
        },
        secure: {
            subject: "私密代禱",
        }
    },
    permission: "直播管理 - 直播清單",
    category: {
        index: "分類管理",
        create: "分類新增",
        edit: "分類內容",
        permission: "直播管理 - 分類管理",
    }
}
