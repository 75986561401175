/**
 * 鉤子
 * 授權 - 權杖
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {computed} from "vue";
import {AuthorizationSessionStore} from "@/v2/store/modules/authorization/session";
import {AuthorizationSession, AuthorizationSessionChurch, AuthorizationSessionPermission, AuthorizationSessionSoftware, AuthorizationSessionValidator} from "@/v2/api/interface/authorization/session";
import {PermissionCollectionEnum, PermissionEnum} from "@/v2/enumerate/permission";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {useRoute} from "vue-router";

/**
 * 實例
 */
export const useAuthorizationSession = () => {

    /**
     * 授權 - 權杖庫
     */
    const authorizationSessionStore = AuthorizationSessionStore()

    /**
     * 獲取 - uuid
     */
    const getUuid = computed((): string | null => authorizationSessionStore.getUuid)

    /**
     * 用戶與管理員是否對應
     * 是否二維碼綁定過
     */
    const isRoot = computed((): boolean => authorizationSessionStore.isRoot)

    /**
     * 用戶與管理員是否對應
     * 是否二維碼綁定過
     */
    const isMapping = computed((): boolean => authorizationSessionStore.isMapping)

    /**
     * 獲取 - 帳號
     */
    const getAccount = computed((): string | null => authorizationSessionStore.getAccount)

    /**
     * 獲取 - 名字
     */
    const getName = computed((): string | null => authorizationSessionStore.getName)

    /**
     * 獲取 - 頭像
     */
    const getAvatarUrl = computed((): string | null => authorizationSessionStore.getAvatarUrl)

    /**
     * 獲取 - 郵件
     */
    const getEmail = computed((): string | null => authorizationSessionStore.getEmail)

    /**
     * 逾期時間
     */
    const getExpireAt = computed((): number => authorizationSessionStore.getExpireAt)

    /**
     * 獲取 - 權限表
     */
    const getPermission = computed((): AuthorizationSessionPermission[] => authorizationSessionStore.getPermission)

    /**
     * 獲取 - 教會
     */
    const getChurch = computed((): AuthorizationSessionChurch => authorizationSessionStore.getChurch)

    /**
     * 獲取 - 軟體
     */
    const getSoftware = computed((): AuthorizationSessionSoftware => authorizationSessionStore.getSoftware)

    /**
     * 獲取 - 軟體
     */
    const getValidator = computed((): AuthorizationSessionValidator => authorizationSessionStore.getValidator)

    /**
     * 總教會登入
     */
    const isHead = computed((): boolean => authorizationSessionStore.getChurch.head);

    /**
     * 限制總教會僅讀取
     */
    const isHeadRestraint = computed((): boolean => isHead.value && ((useRoute().meta as any).head?.readonly ?? false));

    /**
     * 檢查 - 權限
     * ============================================
     * 用 router.group + permissionCollection
     * 進行權限判斷
     * ============================================
     */
    const isHasPermission = computed((): (
        permissionCollection: PermissionCollectionEnum
    ) => boolean => (
        permissionCollection
    ) => {

        const permission: PermissionEnum = (useRoute().meta as any).group ?? 0

        //取出組別
        const group: AuthorizationSessionPermission | undefined = getPermission.value
            .find(i=>i.group === permission)

        //組別不存在, 返回沒權限
        if (group === undefined) {
            return false
        }

        //二進制權限判斷
        return (group.value & permissionCollection) > 0

    })

    //
    // /**
    //  * 解藕 - 二進制權限 - 累加
    //  * 解成 [LIST, STORE....]
    //  * @param permission 權限累加值
    //  */
    // const getPermissionCollection = computed((): (
    //     permission: number
    // ) => PermissionCollectionEnum[] => {
    //
    //     return (
    //         permission
    //     ) => {
    //
    //         return Object.values(PermissionCollectionEnum)
    //             .filter(j => typeof j === "number")
    //             .map(j => j as PermissionCollectionEnum)
    //             .reduce((a: PermissionCollectionEnum[], b: PermissionCollectionEnum) => {
    //                 if ((permission & b) > 0) a.push(b)
    //                 return a
    //             }, [])
    //
    //     }
    //
    // })
    //
    // /**
    //  * 解藕 - 二進制權限 - 累加
    //  * 具有幾個權限 [LIST, STORE] => 2
    //  * @param permission 權限累加值
    //  */
    // const getPermissionCollectionLength = computed((): (
    //     permission: number
    // ) => number => {
    //
    //     return (
    //         permission
    //     ) => {
    //
    //         let count = 0;
    //         while (permission > 0) {
    //             if ((permission & 1) > 0) {
    //                 count++;
    //             }
    //             permission >>= 1;
    //         }
    //         return count;
    //
    //     }
    //
    // })

    /**
     * 檢查 - 路由 - 權限
     * =============================
     * 以router.name檢查是否有權限
     * 檢查通過會給回到原始的路由名稱
     * 基本上是給左側選單開合用的
     * =============================
     * @param permission 權限 (預設是LIST)
     * @param name 路由名稱
     */
    const isHasRoutePermission = computed((): (
        name: RouteNameEnum,
        permission: PermissionCollectionEnum
    ) => RouteNameEnum | null => (
        name,
        permission
    ) => {

        //服事
        if (name === RouteNameEnum.ADMIN_EVENT && isHasPermission.value(
            permission ?? PermissionCollectionEnum.LIST
        )) {
            return name
        }

        //二進制權限判斷
        return null

    })

    /**
     * 更新 - 安全性綁定
     */
    const setMapping = (
        mapping: boolean
    ) => authorizationSessionStore.setMapping(mapping)

    /**
     * 更新 - 名稱
     */
    const setName = (
        name: string
    ) => authorizationSessionStore.setName(name)

    /**
     * 更新 - 名稱
     */
    const setEmail = (
        email: string
    ) => authorizationSessionStore.setEmail(email)

    /**
     * 更新 - 頭像
     */
    const setAvatarUrl = (
        avatar: string | null
    ) => authorizationSessionStore.setAvatarUrl(avatar)

    /**
     * 更新 - 權限
     */
    const setPermission = (
        permission: AuthorizationSessionPermission[]
    ) => authorizationSessionStore.setPermission(permission)

    /**
     * 更新 - 全部
     */
    const setData = (
        data: AuthorizationSession
    ) => authorizationSessionStore.setData(data)

    return {
        getUuid,
        isRoot,
        isHead,
        isMapping,
        isHasPermission,
        isHasRoutePermission,
        isHeadRestraint,
        getAccount,
        getName,
        getAvatarUrl,
        getEmail,
        getExpireAt,
        getPermission,
        getChurch,
        getSoftware,
        getValidator,
        setMapping,
        setName,
        setEmail,
        setAvatarUrl,
        setPermission,
        setData
    }

}