export default {
    integrate: {
        enable: {
            subject: "已启用"
        }
    },
    preview: {
        publish: "发布于 %s．%s 人次浏览",
        empty: "请输入文章内容",
        footer: {
            favorite: "收藏",
            size: "字级",
            comment: "前往留言"
        }
    },
    mindcatcher: {
        authorize: `分享文章由 <b class="text-black">%s</b> 授权使用`
    },
    type: {
        name: {
            1: "图文",
            2: "影音",
            3: "MP3",
            4: "直播",
            5: "WEB",
        },
        disabled: "这个选项暂时无法使用",
        tip: {
            live: {
                disabled: "次分类为活动, 将无法使用直播类型"
            }
        }
    },
    schedule: [
        "排程中",
        "上架中",
        "已下架"
    ]
}
