import {RouteRecordRaw} from "vue-router";

const donate: RouteRecordRaw[] = [{
	//奉獻管理
	path: "dedications",
	name: "AdminDonate",
	meta: {
		permission: 11001,
		breadcrumb: [{
			key: 'admin.donate.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/donate/index.vue"),
}, {
	//奉獻編輯
	path: "dedications/edit/:uuid",
	name: "AdminDonateEdit",
	meta: {
		permission: 11004,
		breadcrumb: [{
			key: 'admin.donate.index',
			value: "AdminUser"
		}, {
			key: 'admin.donate.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/donate/edit.vue"),
}];

export default donate;
