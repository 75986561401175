import {Create} from "@/language/modules/page/admin/support/v2/worker/edit/history/create/zhCN"
import {Edit} from "@/language/modules/page/admin/support/v2/worker/edit/history/edit/zhCN"

export const History: {
    [key: string]: any
} = {

    /**
     * 创建
     */
    create: Create,

    /**
     * 编辑
     */
    edit: Edit,

    field: {
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            help: "同时搜索标题与描述"
        },
        begin: {
            subject: "开始时间(起)",
            holder: "请选择开始时间"
        },
        end: {
            subject: "结束时间(迄)",
            holder: "请选择结束时间"
        },
        invalid: {
            subject: "作废",
            holder: "请选择作废"
        },
        creator: {
            subject: "建立人",
            holder: "请选择建立人"
        },
        editor: {
            subject: "上一次编辑人",
            holder: "请选择上一次编辑人"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    alert: {
        readonly: {
            subject: "工单已经标示完成无法进行修改"
        }
    },
    handler: {
        destroy: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将作废纪录, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }

}
