export const Account: {
    [key: string]: any
} = {
    empty: {
        subject: "請輸入異常App帳號"
    },
    field: {
        account: "異常App帳號"
    },
    holder: {
        account: "請輸入正確的電子郵件格式, 按 Enter 可新增一筆"
    },
    format: {
        account: "請輸入正確的電子郵件格式"
    },
    th: {
        0: {
            subject: "帳號"
        },
        1: {
            subject: "刪除"
        }
    },
    handler: {
        delete: {
            subject: "即將刪除指定異常帳號",
            text: ""
        }
    }
}
