/**
 * 個人
 */
export default {

    /**
     * 編輯
     */
    edit: {
        tip: {
            passwd: "如果不需要變更密碼, 不用填寫"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "密碼"
            },
            99: {
                subject: "相關資訊"
            }
        },
        subject: {
            passwd: "變更密碼"
        },
        field: {
            avatar: "頭像",
            account: "帳號",
            name: "名字",
            email: "電子郵件",
            phone: "電話",
            passwd: {
                current: "目前當前密碼",
                generate: "登入密碼",
                validation: "確認登入密碼"
            }
        },
        holder: {
            account: "請輸入帳號",
            name: "請輸入名字",
            email: "請輸入電子郵件",
            phone: "請輸入聯絡電話",
            passwd: {
                current: "請輸入目前當前密碼",
                generate: "請輸入登入密碼",
                validation: "請再次輸入登入密碼進行確認"
            }
        },
        handler: {
            update: {
                subject: "即將更新個人內容",
                text: "請確認後執行",
                description: "",
                passwd: {
                    subject: "即將更新個人內容",
                    text: `<b class="text-danger">更新密碼成功後將自動登出<br/>請您使用新密碼登入, 請確認後執行</b>`,
                    description: ""
                }
            }
        },
        error: {
            1001: {
                subject: "請輸入名字",
                text: ""
            },
            1002: {
                subject: "請輸入電子郵件",
                text: ""
            },
            1003: {
                subject: "電子郵件格式錯誤",
                text: "請輸入正確的電子郵件格式"
            },
            1004: {
                subject: "請輸入電話",
                text: ""
            },
            2001: {
                subject: "請輸入當前密碼",
                text: ""
            },
            2002: {
                subject: "當前密碼錯誤",
                text: ""
            },
            2003: {
                subject: "請輸入登入密碼",
                text: ""
            },
            2004: {
                subject: "登入密碼強度不足",
                text: ""
            },
            2005: {
                subject: "請再輸入登入密碼進行確認",
                text: ""
            },
            2006: {
                subject: "密碼確認失敗",
                text: "兩次密碼輸入不一致"
            }
        }
    }

}
