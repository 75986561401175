import dashboard from "@/language/modules/page/shepherd/dashboard/zhTW";
import friend from "@/language/modules/page/shepherd/friend/zhTW";
import client from "@/language/modules/page/shepherd/client/zhTW";
import attend from "@/language/modules/page/shepherd/attend/zhTW";
import team from "@/language/modules/page/shepherd/team/zhTW";

export default {

    /**
     * 控制面板
     */
    dashboard: dashboard,

    /**
     * 新朋友
     */
    friend: friend,

    /**
     * 會友
     */
    client: client,

    /**
     * 聚會
     */
    attend: attend,

    /**
     * 族群
     */
    team: team

}
