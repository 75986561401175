import {defineStore} from "pinia";
import {BillboardState} from "@/store/interface/billboard";
import piniaPersistConfig from "@/config/piniaPersist";

const id: string = "BillboardState";

/**
 * 公告欄相關store
 */
export const BillboardStore = defineStore({
    id: id,
    state: (): BillboardState => ({
        summary: {
            unread: 0,
            message: []
        }
    }),
    getters: {
        /**
         * 獲取資料
         */
        getAll: state => {
            return {
                summary: state.summary
            }
        }
    },
    actions: {
        /**
         * 設定更新
         */
        setUpdate(e: BillboardState) {
            this.summary.unread = e.summary.unread
            this.summary.message = e.summary.message
        }
    },
    persist: piniaPersistConfig(id)
});
