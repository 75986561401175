export const v2: {
    [key: string]: any
} = {

    status: {
        authorize: {
            subject: "授权成功",
            text: "正在准备完成"
        },
        inspection: {
            subject: "验证成功",
            text: "请在App中进行确认"
        },
        expire: {
            subject: "二维码超时",
            text: "重新产生二维码"
        }
    },
    to: {
        qrcode: {
            subject: "请使用App扫描确认或切换至",
            text: "输入登入密码"
        },
        passwd: {
            subject: "请输入登入密码或切换至",
            text: "App扫描确认"
        }
    }
}
