import home from "@/language/modules/nav/shepherd/home/zhCN";
import friend from "@/language/modules/nav/shepherd/friend/zhCN";
import client from "@/language/modules/nav/shepherd/client/zhCN";
import team from "@/language/modules/nav/shepherd/team/zhCN";
import setting from "@/language/modules/nav/shepherd/setting/zhCN";
import attend from "@/language/modules/nav/shepherd/attend/zhCN";

/**
 * 牧养系统
 */
export default {

    /**
     * 首页
     */
    home: home,

    /**
     * 新朋友管理
     */
    friend: friend,

    /**
     * 会友管理
     */
    client: client,

    /**
     * 族群管理
     */
    team: team,

    /**
     * 聚会管理
     */
    attend: attend,

    /**
     * 参数管理
     */
    setting: setting

}
