export default {
    editor: {
        enable: {
            subject: "启用点名选项"
        },
        multiple: {
            subject: "选项可复选"
        },
        create: {
            subject: "创建点名选项"
        },
        holder: {
            subject: "请输入选项名称"
        },
        error: {
            1001: {
                subject: "请至少建立1笔选项"
            },
            1002: {
                subject: "请输入选项名称"
            }
        }
    }
}
