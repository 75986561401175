import {v2} from "@/language/modules/component/shepherd/v2/zhCN"

export default {
    v2: v2,
    client: {
        partake: {
            help: {
                lifted: {
                    empty: "请先选择需要加入的族群",
                    exist: "已经加入该族群"
                },
                archive: {
                    on: "已经设置不跟进该会友",
                    off: "重新跟进"
                }
            },
            empty: "该会友尚未加入任何族群",
            lifted: {
                remove: "移除族群",
                archive: "不跟进",
                on: "解除族群",
                off: "复原解除",
            },
            field: {
                increase: "加入新族群",
                team: "族群",
            },
            holder: {
                increase: "请选择需要加入的新族群",
                team: "请选择需要加入的族群",
            },
            handler: {
                archive: {
                    subject: "即将不跟进该名会友",
                    text: `所有群族将<span class="text-danger">全数解除及取消标注</span><br />若要再次进入群族被关心<br />请前往[不跟进会友]重新加入族群, 请确认后执行<br/><span class="ft-14 text-warning">请注意, 保存后才会生效</span>`
                }
            }
        }
    },
    team: {
        editor: {
            archive: {
                subject: "不跟进",
                text: "不跟进会友无法选择"
            },
            top: "最上层",
            tip: {
                readonly: "总教会仅供查看资料, 无法创建修改"
            },
            mode: {
                1: "族群成员",
                2: "托管人员"
            },
            move: {
                left: "双击加入",
                right: "双击移除"
            },
            subject: {
                head: "族群内容",
                add: "族群创建",
                edit: "族群编辑"
            },
            disable: {
                available: "请选择加入的项目",
                selected: "请选择移除的项目",
            },
            recipient: {
                minimum: `最少需要选择 %s 位族群成员`
            },
            manager: {
                maximum: `最多只能选择 %s 位托管人员`,
                minimum: `最少需要选择 %s 位托管人员`
            },
            changed: "尚未异动项目",
            empty: {
                recipient: "请至少选择<br />1位会友作为托管人员",
                manager: "请先选择族群成员<br />再进行托管人员选择"
            },
            not: {
                available: "族群中没有会友<br />请先加入会友到族群中",
                found: "无法搜索到<br />您指定条件的会友"
            },
            help: {
                available: `可选择 <b class="cgBase">%s</b> 个会友`,
                selected: {
                    recipient: `已选择 <b class="cgBase">%s</b> 位族群成员`,
                    manager: `已选择 <b class="cgBase">%s</b> 位托管人员`,
                }
            },
            th: [
                null,
                "会友",
            ],
            field: {
                manager: "托管人员",
                name: "族群名称",
                belong: "所属族群",
                keyword: "搜索名单"
            },
            holder: {
                manager: `请至少选择 <b class="ft-14 cgBase">1</b> 位托管人员`,
                maximum: `, 最多能选择 <b class="ft-14 cgBase">%s</b> 位托管人员`,
                name: "请输入族群名称",
                belong: "请选择所属族群",
                keyword: "搜索范围: 名字/邮件"
            },
            handler: {
                close: {
                    add: {
                        subject: "即将关闭族群创建",
                        text: `<span class="text-danger">异动项目将会清除, 请确认后执行</span>`
                    },
                    edit: {
                        subject: "即将关闭族群编辑",
                        text: `<span class="text-danger">异动项目将会清除, 请确认后执行</span>`
                    }
                },
                store: {
                    subject: "即将创建族群",
                    text: "请确认后执行",
                    description: ""
                },
                update: {
                    subject: "即将更新族群内容",
                    text: "请确认后执行",
                    description: ""
                }
            },
            error: {
                1001: {
                    "subject": "请输入族群名称",
                    "text": "",
                    "description": ""
                },
                1002: {
                    "subject": "请选择族群成员",
                    "text": `至少需要 <b class="ft-20 text-danger">1</b> 位族群成员`,
                    "description": ""
                },
                1003: {
                    "subject": "托管人员不足最低限制",
                    "text": `至少需要 <b class="ft-20 text-danger">1</b> 位托管人员`,
                    "description": ""
                },
                1004: {
                    "subject": "托管人员超过最高限制",
                    "text": `最多只能选择 <b class="ft-20 text-danger">%s</b> 位托管人员`,
                    "description": ""
                }
            }
        }
    },
    attend: {
        history: {
            recipient: {
                th: {
                    1: "到",
                    2: "头像",
                    3: "资讯",
                    4: "报到",
                    5: "查看"
                },
                vacation: {
                    subject: "本周无聚会"
                },
                rank: {
                    subject: "自评",
                    not: {
                        yet: {
                            subject: "未自评"
                        }
                    }
                },
                empty: "名单中没有成员",
                note: {
                    subject: "留言",
                    not: {
                        yet: {
                            subject: "未留言"
                        }
                    }
                },
                register: {
                    not: {
                        yet: {
                            subject: "未报到"
                        }
                    }
                },
                edit: {
                    view: "查看此会友资讯"
                }
            }
        }
    }
}
