/**
 * 外觀 - 角標 - 教會
 */
export enum EnumVariantBadgeChurch {

    /**
     * 編號
     */
    NO = 1,

    /**
     * 名稱
     */
    NAME

}
