export default {
    to: {
        qrcode: {
            subject: "請使用App掃描確認或切換至",
            text: "輸入登入密碼"
        },
        passwd: {
            subject: "請輸入登入密碼或切換至",
            text: "App掃描確認"
        }
    },
    status: {
        success: {
            subject: "掃碼成功",
            text: "請在App中進行確認"
        },
        expire: {
            subject: "二維碼超時",
            text: "重新產生二維碼"
        }
    },
    field: {
    }
}
