import {v2} from "@/language/modules/component/support/v2/zhTW"

export default {

    /**
     * v2
     */
    v2: v2

}
