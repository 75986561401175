import {NavigationItemRaw} from '@/navigation/interface'

const soul: NavigationItemRaw[] = [{
    id: 6,
    right: true,
    subject: 'admin.soul.index',
    route: null,
    icon: 'star',
    children: [{
        id: 6001,
        right: true,
        subject: 'admin.soul.index',
        route: 'AdminSoul',
        attached: [
            'AdminSoulCreate',
            'AdminSoulEdit',
            'AdminSoulCopy',
            'AdminSoulMindCatcher',
            'AdminSoulComment',
            'AdminSoulNotification',
            'AdminSoulNotificationCreate',
            'AdminSoulNotificationEdit'
        ]
    }, {
        id: 6011,
        right: true,
        subject: 'admin.soul.category.index',
        route: 'AdminSoulCategory',
        attached: [
            'AdminSoulCategoryCreate',
            'AdminSoulCategoryEdit'
        ]
    }],
    attached: []
}]

export default soul
