import {createVNode,
    render} from 'vue'

/**
 * 動態插入元件
 */
export const renderComponent = (
    el: any,
    component: any,
    props: any,
    appContext: any
) => {

    let vnode = createVNode(component, props)
    vnode.appContext = { ...appContext }
    render(vnode, el)

    return () => {
        // destroy vnode
        render(null, el)
    }

}
