/**
 * 統計 - 週期
 */
export enum SummaryPeriodEnum {

    /**
     * 天 (1D)
     */
    DAY = 1,

    /**
     * 週 (1W)
     */
    WEEK,

    /**
     * 月 (1M)
     */
    MONTH,

    /**
     * 季 (1Q)
     */
    SEASON,

    /**
     * 半年 (2Q)
     */
    HALF_YEAR,

    /**
     * 1年 (1Y)
     */
    YEAR,

    /**
     * 3年 (3Y)
     */
    THREE_YEAR

}