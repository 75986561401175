/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * Websocket - 指令
 */
export enum EnumWebsocketCommand {

    /**
     * 通用
     */
    CONNECTED = "commonConnected",

    /**
     * 取得公告欄資訊
     */
    BILLBOARD = "billboardSummary"

}

/**
 * Websocket - 類型
 */
export enum EnumWebsocketType {

    /**
     * 連接成功
     */
    CONNECTED,

    /**
     * 一般訊息
     */
    RECIPIENT,

    /**
     * 廣播訊息
     */
    BROADCAST

}

/**
 * Websocket - 狀態
 */
export enum EnumWebsocketStatus {

    /**
     * 已連接
     */
    CONNECTED = 1,

    /**
     * 斷線
     */
    DISCONNECTED = 0

}
