/**
 * 聚會 - 歷程
 */
export default {
    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教會"
            },
            begin: "開始時間",
            end: "結束時間"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            }
        }
    },
    edit: {
        help: {
            remind: {
                subject: "請參考提醒設定值",
            }
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相關資訊"
            }
        },
        boolean: {
            false: {
                subject: "否"
            },
            true: {
                subject: "是"
            }
        },
        remind: {
            0: {
                subject: "不發送",
                text: "不發送"
            },
            1: {
                subject: "等待發送",
                text: "正在準備發送"
            },
            2: {
                subject: "發送完成"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            created: "建立時間",
            begin: "開始時間",
            end: "結束時間",
            stamp: "預定生成時間",
            completed: "實際生成時間",
            beforehand: "預設提前生成",
            remind: {
                notification: {
                    status: "推送狀態",
                    stamp: "推送完成時間"
                },
                email: {
                    status: "寄送郵件狀態",
                    stamp: "寄送郵件完成時間"
                }
            },
            status: "狀態"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            stamp: "請選擇預定生成時間",
            status: "請選擇狀態"
        }

    }
}