/**
 * 推播管理
 */
export default {
    index: "推播管理",
    create: "创建推播",
    edit: {
        subject: "推播内容",
        automatic: {
            subject: "自动化纪录",
            edit: {
                subject: "纪录内容",
            }
        },
        history: {
            subject: "推播纪录"
        }
    },
    permission: "推播管理"
}
