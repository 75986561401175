<script setup lang="ts">

import qrcodeLoading from "@/asset/loading/Spinner-1s-200px.png"
import {QrcodeEnum} from "@/v2/enumerate/qrcode";
import {computed} from "vue";

/**
 * 傳入 - 參數
 */
const props = defineProps<{
  //模式
  mode: QrcodeEnum
  blob: string | null
}>()

/**
 * 取得 - 二維碼框 - 顯示圖
 */
const getCurrentImage = computed((): string => {
  //如果已經下傳圖下來, 用傳下來的blob
  if (props.blob) {
    return props.blob
  }
  //還沒的話, 用loading圖
  return qrcodeLoading
})

/**
 * 是否二維碼載入成功
 */
const isBlobLoaded = computed((): boolean => {
  return !["", null].includes(props.blob)
})

</script>

<template>
  <img class="p"
       :class="{'active': isBlobLoaded, 'swal-embedded': props.mode === QrcodeEnum.FACTORY}"
       :src="getCurrentImage"
       alt="" />
</template>

<style scoped lang="scss">
img {
  &.p {
    width: 150px;
    height: 150px;
    margin: 35px;
    &.active {
      width: 220px;
      height: 220px;
      margin: 0;
      box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%), 0 2px 4px 0 rgb(34 41 47 / 8%);
    }
    &.swal-embedded {
      width: 100px;
      height: 100px;
      &.active {
        width: 170px;
        height: 170px;
      }
    }
  }
}
.dark-layout {
  img {
    &.p {
      &.active {
        box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.75), 0 2px 4px 0 rgba(34, 41, 47, 0.75);
      }
    }
  }
}
</style>