import i18n from '@/language'
import moment, {DurationInputArg2} from 'moment-timezone'
import {sprintf} from "sprintf-js";
import {EnumMomentFormat, EnumMomentUnit} from "@/enum/moment";
import {computed} from "vue";
import {EnumSummaryPeriod} from "@/enum/summary";

/**
 * 日期工具
 */
export const useDate = () => {

    /**
     * 轉化為本地時間
     */
    const getLocal = (
        value: string,
        format: EnumMomentFormat | null = null
    ): string => {

        //將字串轉換為moment格式
        const m: moment.Moment = moment(value, moment.ISO_8601)

        //如果傳入不是日期格式, 直接返回空字串
        if (!m.isValid()) return ""

        //處理時間偏移量
        m.add(getSecondFromGMT.value - 28800, 'seconds');

        //返回格式化好的字串
        return m.format(format ?? EnumMomentFormat.DATE)

    }

    /**
     * 轉化為本地時間
     */
    const getTimeLocal = (
        value: number,
        format: EnumMomentFormat | null = null
    ): string => {

        //將字串轉換為moment格式
        const m: moment.Moment = moment(value * 1000)

        //處理時間偏移量
        m.add(getSecondFromGMT.value - 28800, 'seconds');

        //返回格式化好的字串
        return m.format(format ?? EnumMomentFormat.DATE)

    }

    /**
     * 轉化回GTM+8
     */
    const getRestoreGMT8 = (
        value: number,
        format: EnumMomentFormat | null = null
    ): string => {

        //將字串轉換為moment格式
        const m: moment.Moment = moment(value * 1000)

        //處理時間偏移量
        //先歸0再+8:00
        //m.add(getSecondFromGMT.value * -1 + 28800, 'seconds');
        m.add((new Date().getTimezoneOffset() * 60) + 28800, 'seconds');

        //返回格式化好的字串
        return m.format(format ?? EnumMomentFormat.DATE)

    }

    /**
     * 轉化為本地時間
     */
    const getChartLocal = (
        value: number,
        period: EnumSummaryPeriod
    ): string => {

        //將字串轉換為moment格式
        const m: moment.Moment = moment(value * 1000)

        //處理時間偏移量
        m.add(getSecondFromGMT.value - 28800, 'seconds');

        //天
        if (period === EnumSummaryPeriod.DAY) {

            const s: moment.Moment = moment(value * 1000)
            //處理時間偏移量
            s.add(getSecondFromGMT.value - 28800 + 3600 * 2 - 1, 'seconds')

            return sprintf(
                "%s - %s",
                m.format(EnumMomentFormat.CHART_DAY).replace(/-/g, '/'),
                s.format(EnumMomentFormat.CHART_DAY).replace(/-/g, '/')
            )

        }
        //週
        else if (period === EnumSummaryPeriod.WEEK) return m.format(EnumMomentFormat.CHART_WEEK).replace(/-/g, '/')

        //返回格式化好的字串
        return m.format(EnumMomentFormat.CHART).replace(/-/g, '/')

    }

    /**
     * 獲取目前預設時區的偏移量
     * +0000 -> 0
     * +0800 -> 28,800
     */
    const getSecondFromGMT = computed((): number => {
        return new Date().getTimezoneOffset() * 60 * -1
    })

    /**
     * 取得內建最早時間
     * 現在日期-50年 01/01 00:00:00
     */
    const getSystemMinimumDate = (): Date => {

        const r = new Date()
        r.setFullYear(r.getFullYear()-50)
        r.setMonth(0)
        r.setDate(1)
        r.setHours(0)
        r.setMinutes(0)
        r.setSeconds(0)

        return r

    }

    /**
     * 取得內建最晚時間
     * 現在日期+50年 12/31 23:59:59
     */
    const getSystemMaximumDate = (): Date => {

        const r = new Date()
        r.setFullYear(r.getFullYear()+50)
        r.setMonth(11)
        r.setDate(31)
        r.setHours(23)
        r.setMinutes(59)
        r.setSeconds(59)

        return r

    }

    /**
     * 獲取當月的最後一天
     * @param date 傳入日期
     */
    const getLastDay = (
        date: Date
    ): number => {
        return moment(date).daysInMonth()
    }

    /**
     * 獲取當月的最後一天
     * @param date 傳入日期
     * @param format 格式化條件
     */
    const getStringLastDay = (
        date: string,
        format?: EnumMomentFormat | null
    ): number => {
        const _format: string = format == null ? EnumMomentFormat.DATE : format as string
        return moment(getStringToDate(date), _format).daysInMonth()
    }

    /**
     * 產生最小日期
     * 如果傳入值 < 系統最小日期, 限制為系統最小日期, 傳入值 == null, 直接給系統最小日期
     * @param date 傳入日期
     * @param format 格式化條件
     */
    const getMinimumDate = (
        date: string | null,
        format?: EnumMomentFormat | null
    ): Date => {

        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format

        //初始化 最早日期
        const r = getSystemMinimumDate()

        //如果傳入null, 則直接給系統預設最早日期
        if (date === null) return r

        const d = getStringToDate(date, _format)
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)

        //如果傳入日期字串, 比預設系統日期大, 則回傳傳入日期
        if (d.getTime() > r.getTime()) return d

        return r

    }

    /**
     * 產生最大日期
     * 如果傳入值 > 系統最大日期, 限制為系統最大日期, 傳入值 == null, 直接給系統最大日期
     * @param date 傳入日期
     * @param format 格式化條件
     */
    const getMaximumDate = (
        date: string | null,
        format?: EnumMomentFormat | null
    ): Date => {

        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format

        //初始化 最晚日期 (現在日期+50年)
        const r = getSystemMaximumDate()

        //如果傳入null, 則直接給系統預設最晚日期
        if (date === null) return r

        const d = getStringToDate(date, _format)
        d.setHours(23)
        d.setMinutes(59)
        d.setSeconds(59)

        //如果傳入日期字串, 比預設系統日期小, 則回傳傳入日期
        if (d.getTime() < r.getTime()) return d

        return r

    }

    /**
     * 將時間格式化成字串
     * @param date 傳入時間
     * @param format 格式化條件
     */
    const getFormatDate = (
        date: Date,
        format?: EnumMomentFormat | null
    ): string => {

        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format
        return moment(date).format(_format);

    }

    /**
     * 字串轉stamp
     * @param string 傳入字串
     * @param format 格式化條件
     */
    const getFromStamp = (
        string: number,
        format?: EnumMomentFormat | null
    ): string => {

        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format
        return moment.unix(string).format(_format);

    }

    /**
     * 字串轉stamp
     * 如果傳入空白, 則給現在的
     * @param string 傳入字串
     */
    const getStringToStamp = (
        string: string | null
    ): number => {
        if (!string) return new Date().getTime() / 1000
        if (moment(string, EnumMomentFormat.DATETIME, true).isValid()) return new Date(string).getTime() / 1000
        else if (moment(string, EnumMomentFormat.DATETIME_WITHOUT_SECOND, true).isValid()) return new Date(`${string}:00`).getTime() / 1000
        return 0
    }

    /**
     * 字串轉日期
     * @param string 傳入字串
     * @param format 格式化條件
     */
    const getStringToDate = (
        string: string | null,
        format?: EnumMomentFormat | null
    ): Date => {

        if (!string) return new Date()
        const _format: string = format == null ? EnumMomentFormat.DATE : format as string
        return moment(string, _format).toDate()

    }

    /**
     * 獲取現在時間
     * @param drift 偏移 - 天數
     * @param unit 偏移 - 單位
     * @param format 格式化條件
     */
    const getNowDate = (
        drift?: number | null,
        unit?: DurationInputArg2 | null,
        format?: EnumMomentFormat | null
    ): string => {

        const _value: number = drift == null ? 0 : drift as number
        const _unit: DurationInputArg2 = unit == null ? EnumMomentUnit.DAY as DurationInputArg2 : unit as DurationInputArg2
        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format
        return moment(new Date()).add(_value, _unit).format(_format)

    }

    /**
     * 獲取指定時間偏移量
     * @param date 傳入日期
     * @param drift 偏移天數
     * @param unit 單位
     */
    const getDriftDate = (
        date: Date,
        drift?: number | null,
        unit?: string | null
    ): Date => {

        const _value: number = drift == null ? 0 : drift as number
        const _unit: DurationInputArg2 = unit == null ? EnumMomentUnit.DAY as DurationInputArg2 : unit as DurationInputArg2
        return moment(date).add(_value, _unit).toDate()

    }

    /**
     * 格式化日期+n個步進
     * @param date 傳入日期值字串
     * @param step 步進數量
     */
    const getPickerDriftDate = (
        date: string | null,
        step: number = 1
    ): string | null => {
        if (!date) return null
        const r: Date = getStringToDate(date, EnumMomentFormat.DATE)
        return moment(r).add(step, EnumMomentUnit.DAY).format(EnumMomentFormat.DATE)
    }

    /**
     * 格式化時間+n個步進 (回傳)
     * @param time 傳入值時間字串
     * @param step 步進數量
     * @param format
     * @param format 格式化方式
     */
    const getPickerDriftTime = (
        time: string | null,
        step: number = 1,
        format: EnumMomentFormat = EnumMomentFormat.DATETIME_WITHOUT_SECOND
    ): string | null => {
        if (!time) return null
        const r: Date = getStringToDate(time, format)
        return moment(r).add(step*5, EnumMomentUnit.MINUTE).format(format)
    }

    /**
     * 回傳星期n
     * 星期天為0的轉換
     * 傳入是[0, 1, 2, 4, 5, 6], Calendar是[7, 1, 2, 3, 4, 5, 6]
     * @param e 日期字串
     */
    const getWeekDayZeroLeading = (e: string | null) : number => {
        const r: Date = getStringToDate(e, EnumMomentFormat.DATETIME)
        return r.getDay()
    }

    /**
     * 顯示多久之前
     * @param second 傳入秒數
     * @param format 格式化條件
     */
    const getPrevious = (
        second: number,
        format?: EnumMomentFormat | null
    ): string => {

        const _format: EnumMomentFormat = format == null ? EnumMomentFormat.DATE : format

        //導入i18n
        const {
            //@ts-ignore
            t
        } = i18n.global

        const unit = {
            just: 60,
            minute: 60*60,
            hour: 60*60*24,
            day: 60*60*24*7
        }

        const now: number = new Date().getTime() / 1000 - second

        //剛剛
        if (now < unit.just) {
            return t(`ago.just`)
        }
        //n分鐘前
        else if (now < unit.minute) {
            return sprintf(t(`ago.minute`), Math.ceil((now - unit.just) / unit.just))
        }
        //n小時前
        else if (now < unit.hour) {
            return sprintf(t(`ago.hour`), Math.ceil((now - unit.minute) / unit.minute))
        }
        //n天前
        else if (now < unit.day) {
            return sprintf(t(`ago.day`), Math.ceil((now - unit.hour) / unit.hour))
        }

        //顯示日期
        return moment.unix(second).format(_format)

    }

    /**
     * 驗證字串是否為時間格式
     * 支援
     */
    const isValid = (
        string: string | null
    ): boolean => {
        if (string === null) return false
        else if ( moment(string, EnumMomentFormat.DATE, true).isValid() ) return true
        else if ( moment(string, EnumMomentFormat.DATETIME, true).isValid() ) return true
        else if ( moment(string, EnumMomentFormat.DATETIME_WITHOUT_SECOND, true).isValid() ) return true
        return false
    }

    return {
        isValid,
        getLocal,
        getRestoreGMT8,
        getTimeLocal,
        getChartLocal,
        getLastDay,
        getNowDate,
        getPrevious,
        getDriftDate,
        getFromStamp,
        getFormatDate,
        getMinimumDate,
        getMaximumDate,
        getStringToDate,
        getStringToStamp,
        getStringLastDay,
        getPickerDriftDate,
        getPickerDriftTime,
        getWeekDayZeroLeading,
        getSecondFromGMT
    }

}
