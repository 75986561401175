<script setup lang="ts">

import {QrcodeEnum} from "@/v2/enumerate/qrcode";
import {sprintf} from "sprintf-js";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {computed} from "vue";

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "change"): void
}>()

/**
 * 傳入 - 參數
 */
const props = defineProps<{
  //模式
  mode: QrcodeEnum
  organization: {
    [key: string]: any
  }
  active: {
    //語言
    i18n: string
    //密碼模式
    passwd: boolean
  }
}>()

/**
 * 切換 - 模式
 */
const onChange = () => {

  //切換 - 模式
  props.active.passwd = !props.active.passwd

  //呼叫 - 變更
  emit('change')

}

/**
 * 取得 - 標題
 */
const getSubject = computed(()=>{
  if (props.mode === QrcodeEnum.MAPPING) {
    return `${props.active.i18n}.mapping.subject`
  }
  return `${props.active.i18n}.login.subject`
})

/**
 * 取得 - 副標題
 */
const getText = computed(()=>{
  if (props.mode === QrcodeEnum.MAPPING) {
    return `${props.active.i18n}.mapping.text`
  }
  return `${props.active.i18n}.login.text`
})

/**
 * 取得 - 切換 - 前置
 */
const getChangePrefix = computed(()=>{
  if (props.active.passwd) {
    return `${props.active.i18n}.to.passwd`
  }
  return `${props.active.i18n}.to.qrcode`
})

</script>

<template>
  <!-- 登入或安全性綁定 -->
  <div v-if="props.mode !== QrcodeEnum.FACTORY"
       class="mt-2 d-flex d-flex-column justify-content-center">
    <p class="m-0"
       v-html="sprintf($t(getSubject), props.organization.name)" />
    <div class="text-center m-t-10">
      <!-- 提示前往下載App -->
      <router-link
          target="_blank"
          :to="{name: RouteNameEnum.APP}"
          class="logo badge badge-light-muted cursor-pointer">
        <img v-if="!!props.organization.uuid"
             :src="`/img/organization/${props.organization.seq}/logo.png`"
             alt="">
        <span class="ft-12 text-secondary m-l-5"
              v-html="sprintf($t(getText), props.organization.name)" />
      </router-link>
    </div>
  </div>
  <!-- 二級驗證 -->
  <div v-else
       class="mt-2">
    <small class="text-muted text-center">
      <span v-html="$t(`${getChangePrefix}.subject`)" />
      <span @click="onChange"
            class="text-hyperlink cursor hand"
            v-html="$t(`${getChangePrefix}.text`)" />
    </small>
  </div>
</template>

<style scoped lang="scss">
.logo {
  border-radius: 35px;
  padding: 10px 15px;
  > span {
    position: relative;
    top: 1px;
  }
  > img {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
}
</style>