export const Submit: {
    [key: string]: any
} = {
    status: {
        enable: "启用",
        disable: "停用",
    },
    download: "下载",
    recipient: "编辑名单",
    approved: "核准",
    reject: "拒绝",
    preview: "预览",
    copy: "复制",
    insert: "插入",
    back: "返回",
    join: "加入",
    remove: "移除",
    select: "选择",
    attachment: "附件",
    home: "返回首页",
    toEntry: "回列表",
    reLogin: "重新登入",
    login: "登入",
    forgot: {
        found: "找回密码",
        verification: "确认送出",
        reset: "重设密码"
    },
    waiting: "登入中",
    success: "登入成功...请稍候",
    ok: "好",
    top: "置顶",
    save: "储存",
    search: "搜寻",
    query: "查询",
    close: "关闭",
    accept: "确定",
    refresh: "刷新",
    reload: "重新读取",
    upload: "上传",
    clear: "清除",
    reset: "重设",
    cancel: "取消",
    reply: "回复",
    push: "推送",
    send: "送出",
    add: "加入",
    edit: "编辑",
    delete: "删除",
    done: "完成",
    fetch: "立即更新",
    relocation: "重新整理",
    replace: "取代",
    examine: "查看"
}
