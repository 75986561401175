/**
 * 行事历管理
 */
export default {
    subject: "行事历管理",
    copy: "复制服事",
    create: "服事创建",
    edit: "服事内容",
    index: "服事清单",
    permission: "行事历管理 - 服事清单",
    team: {
        index: "服事群组",
        create: "群组创建",
        edit: "群组内容",
        permission: "行事历管理 - 服事群组",
        history: {
            index: "历史纪录",
        }
    }
}
