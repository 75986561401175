import {NavigationItemRaw} from '@/navigation/interface'

const client: NavigationItemRaw[] = [{
    id: 4,
    right: true,
    subject: 'admin.client.index',
    route: 'AdminClient',
    icon: 'profile-2user',
    children: [],
    attached: [
        'AdminClientCreate',
        'AdminClientEdit',
        'AdminClientDonate',
        'AdminClientDonateEdit',
    ]
}]

export default client
