/**
 * 客服管理 - 帐户纪录
 */
export default {

    /**
     * 列表
     */
    index: {
        tabs: [{
            subject: "查询"
        }],
        help: {
            query: "请输入完整的电子邮件",
            disabled: "会友已经停用",
            destroy: "会友已于 <b>%s</b> 自行删除",
            church: {
                disabled: "教会已经停用",
                destroy: "教会已经删除",
                organization: {
                    disabled: "机构已经停用",
                    destroy: "机构已经删除",
                }
            }
        },
        notification: {
            status: {
                waiting: "排程中",
                progress: "发送中",
                complete: "已送达"
            }
        },
        result: {
            success: '查询到 <b class="ft-18 text-success">%s</b> 笔纪录',
            empty: "查询不到任何结果"
        },
        field: {
            notification: {
                subject: '最后 <b class="text-success ft-18">10</b> 笔推播通知',
                text: '(依照预定推播时间降幂排序)',
                empty: "没有推播通知纪录",
                field: {
                    id: "推播ID",
                    schedule: "排定发送时间",
                    delivery: "送达时间"
                }
            },
            device: {
                supplier: "装置支援商",
                brand: "装置制造商",
                model: "装置型号",
                os: "装置操作系统",
                version: "装置App版本",
                token: {
                    subject: "登入权杖",
                    text: "(sys_token.token)",
                    push: {
                        subject: "推播识别码",
                        text: "(core_admin_client_notification.key)",
                    }
                },
            },
            belong: {
                organization: {
                    id: "所属机构ID",
                    name: "所属机构名称",
                },
                id: "所属教会ID",
                no: "所属教会编号",
                name: "所属教会名称",
            },
            id: "会友ID",
            no: "会友编号",
            name: "会友姓名",
            account: "会友帐户",
            email: "电子邮件",
            avatar: "头像",
            status: "状态",
            created: "建立时间",
            updated: "刷新时间"
        },
        holder: {
            email: "请输入完整的电子邮件进行查询"
        },
        error: {
            1001: {
                "subject": "请输入电子邮件",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "电子邮件格式错误",
                "text": "请输入正确的电子邮件格式",
                "description": ""
            }
        }
    }

}
