/**
 * 教会管理
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "文档汇出_教会管理",
        },
        feature: {
            create: {
                subject: "创建教会"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "主图",
            no: "编号",
            name: "名称",
            client: "会友人数",
            soul: "心灵捕手状态",
            daily: "每日灵粮状态"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            no: "请输入编号",
            name: "请输入名称",
            soul: "请选择心灵捕手状态",
            daily: "请选择每日灵粮状态"
        },
        handler: {
            enable: {
                subject: "即将启用教会",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用教会",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            export: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将进行教会汇出, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "联络人"
            },
            2: {
                subject: "奉献"
            },
            3: {
                subject: "模块"
            },
        },
        help: {
            church: "请谨慎使用指定教会功能",
        },
        field: {
            name: "名称",
            no: "编号",
            location: {
                country: "所在地区",
                city: "所在城市",
            },
            address: "地址",
            contact: [{
                subject: "主要联络人",
                name: "姓名",
                phone: "联络电话",
                email: "电子邮件"
            }, {
                subject: "次要联络人",
                name: "姓名",
                phone: "联络电话",
                email: "电子邮件"
            }],
            timezone: {
                subject: [
                    "时区设置",
                    "时区与机构设置"
                ],
                zone: "所在时区",
                organization: "机构"
            },
            control: {
                subject: "控制项",
                my: {
                    subject: "我的教会标题名称",
                    text: "(尺寸为 %sx%s 像素)",
                    avatar: "标题图"
                },
                daily: {
                    mode: "系统模式",
                    subject: "每日灵粮标题名称",
                    text: "(尺寸为 %sx%s 像素)",
                    avatar: "标题图"
                },
                soul: {
                    mode: "系统模式",
                    subject: "心灵捕手标题名称",
                },
                query: "查询长度"
            },
            donate: {
                mode: "奉献方式",
                newebpay: {
                    merchant: "蓝新金流Merchant ID",
                    key: "蓝新金流Hash KEY",
                    iv: "蓝新金流Hash IV"
                },
                hyperlink: {
                    text: "奉献连结文字",
                    url: "奉献连结",
                    description: "奉献描述内容"
                }
            },
            other: {
                subject: "其他设置",
                offset: "App首页最新消息笔数",
                period: "查询区间",
                invitation: {
                    customize: "邀请码模式",
                    code: "教会邀请码"
                },
            }
        },
        holder: {
            name: "请输入名称",
            no: "请输入编号",
            location: {
                country: "请选择所在地区",
                city: "请选择所在城市"
            },
            address: "请输入地址",
            contact: [{
                name: "请输入姓名",
                phone: "请输入联络电话",
                email: "请输入电子邮件"
            }, {
                name: "请输入姓名",
                phone: "请输入联络电话",
                email: "请输入电子邮件"
            }],
            timezone: {
                zone: "请选择时区",
                organization: "请选择机构"
            },
            control: {
                my: {
                    subject: "请输入我的教会标题名称",
                },
                daily: {
                    subject: "请输入每日灵粮标题名称",
                },
                soul: {
                    subject: "请输入心灵捕手标题名称",
                },
                query: "请选择查询长度"
            },
            donate: {
                newebpay: {
                    merchant: "请输入蓝新金流Merchant ID",
                    key: "请输入蓝新金流Hash KEY",
                    iv: "请输入蓝新金流Hash IV"
                },
                hyperlink: {
                    text: "请输入奉献连结文字",
                    url: "请输入奉献连结",
                    description: "请输入奉献描述内容"
                }
            },
            other: {
                offset: "请选择App首页最新消息笔数",
                period: "请选择查询区间",
                invitation: {
                    code: [
                        "请点击图示产生随机邀请码",
                        "请点击图示自行输入邀请码"
                    ]
                }
            }
        },
        handler: {
            store: {
                subject: "即将创建教会",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "异常发生",
                "text": "无法停用目前登入的教会",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择所在区域",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择所在城市",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择所在时区",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择机构",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入我的教会标题名称",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入心灵捕手标题名称",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请选择心灵捕手系统模式",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入每日灵粮标题名称",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请选择每日灵粮系统模式",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请输入地址",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "请输入编号",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "编号已经存在",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "请选择查询长度",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "请选择邀请码模式",
                "text": "",
                "description": ""
            },
            1018: {
                "subject": "请选择App首页最新消息笔数",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请输入主要联络人姓名",
                "text": "",
                "description": ""
            },
            2002: {
                "subject": "请输入主要联络人联络电话",
                "text": "",
                "description": ""
            },
            2003: {
                "subject": "请输入主要联络人电子邮件",
                "text": "",
                "description": ""
            },
            2004: {
                "subject": "主要联络人电子邮件格式错误",
                "text": "请输入正确的电子邮件格式",
                "description": ""
            },
            2005: {
                "subject": "次要联络人电子邮件格式错误",
                "text": "请输入正确的电子邮件格式",
                "description": ""
            },
            3001: {
                "subject": "请选择奉献系统模式",
                "text": "",
                "description": ""
            },
            3002: {
                "subject": "请输入蓝新金流Merchant ID",
                "text": "",
                "description": ""
            },
            3003: {
                "subject": "请输入蓝新金流Hash KEY",
                "text": "",
                "description": ""
            },
            3004: {
                "subject": "请输入蓝新金流Hash IV",
                "text": "",
                "description": ""
            },
            3005: {
                "subject": "请输入奉献连结",
                "text": "",
                "description": ""
            },
            3006: {
                "subject": "请输入奉献连结文字",
                "text": "",
                "description": ""
            },
            3007: {
                "subject": "请输入奉献描述内容",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            disabled: "请注意, 这间教会已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "联络人"
            },
            2: {
                subject: "奉献"
            },
            3: {
                subject: "模块"
            },
            99: {
                subject: "相关资讯"
            }
        },
        generate: [
            "随机产生",
            "自行输入",
            "清除邀请码"
        ],
        field: {
            name: "名称",
            no: "编号",
            location: {
                country: "所在地区",
                city: "所在城市"
            },
            address: "地址",
            description: {
                subject: "简介标题",
                text: "简介内容"
            },
            hyperlink: {
                text: "连结文字",
                url: "连结网址"
            },
            contact: [{
                subject: "主要联络人",
                name: "姓名",
                phone: "联络电话",
                email: "电子邮件"
            }, {
                subject: "次要联络人",
                name: "姓名",
                phone: "联络电话",
                email: "电子邮件"
            }],
            timezone: {
                subject: [
                    "时区设置",
                    "时区与机构设置"
                ],
                zone: "所在时区",
                organization: "机构"
            },
            control: {
                subject: "控制项",
                my: {
                    subject: "我的教会标题名称",
                    text: "(尺寸为 %sx%s 像素)",
                    avatar: "标题图"
                },
                daily: {
                    mode: "系统模式",
                    subject: "每日灵粮标题名称",
                    text: "(尺寸为 %sx%s 像素)",
                    avatar: "标题图"
                },
                soul: {
                    mode: "系统模式",
                    subject: "心灵捕手标题名称",
                }
            },
            donate: {
                mode: "奉献方式",
                newebpay: {
                    merchant: "蓝新金流Merchant ID",
                    key: "蓝新金流Hash KEY",
                    iv: "蓝新金流Hash IV"
                },
                hyperlink: {
                    text: "奉献连结文字",
                    url: "奉献连结",
                    description: "奉献描述内容"
                }
            },
            other: {
                subject: "其他设置",
                offset: "App首页最新消息笔数",
                period: "查询区间",
                invitation: {
                    customize: "邀请码模式",
                    code: "教会邀请码"
                }
            }
        },
        holder: {
            name: "请输入名称",
            no: "请输入编号",
            location: {
                country: "请选择所在地区",
                city: "请选择所在城市"
            },
            address: "请输入地址",
            description: {
                subject: "请输入简介标题",
                text: "请输入简介内容"
            },
            hyperlink: {
                text: "请输入连结文字",
                url: "请输入连结网址"
            },
            contact: [{
                name: "请输入姓名",
                phone: "请输入联络电话",
                email: "请输入电子邮件"
            }, {
                name: "请输入姓名",
                phone: "请输入联络电话",
                email: "请输入电子邮件"
            }],
            timezone: {
                zone: "请选择时区",
                organization: "请选择机构"
            },
            control: {
                my: {
                    subject: "请输入我的教会标题名称",
                },
                daily: {
                    subject: "请输入每日灵粮标题名称",
                },
                soul: {
                    subject: "请输入心灵捕手标题名称",
                },
            },
            donate: {
                newebpay: {
                    merchant: "请输入蓝新金流Merchant ID",
                    key: "请输入蓝新金流Hash KEY",
                    iv: "请输入蓝新金流Hash IV"
                },
                hyperlink: {
                    text: "请输入奉献连结文字",
                    url: "请输入奉献连结",
                    description: "请输入奉献描述内容"
                }
            },
            other: {
                offset: "请选择App首页最新消息笔数",
                period: "请选择查询区间",
                invitation: {
                    code: [
                        "请点击图示产生随机邀请码",
                        "请点击图示自行输入邀请码"
                    ]
                }
            }
        },
        handler: {
            invitation: {
                clear: {
                    subject: "即将清除教会邀请码",
                    text: `<span class="text-danger">清除后保存, 将无法使用教会邀请码功能<br />请确认后执行</span>`,
                    description: ""
                }
            },
            update: {
                subject: "即将更新教会内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "异常发生",
                "text": "无法停用目前登入的教会",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择所在区域",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择所在城市",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择所在时区",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择机构",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入我的教会标题名称",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入心灵捕手标题名称",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请选择心灵捕手系统模式",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入每日灵粮标题名称",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请选择每日灵粮系统模式",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请输入地址",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "请选择查询长度",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "请选择邀请码模式",
                "text": "",
                "description": ""
            },
            1018: {
                "subject": "请选择App首页最新消息笔数",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请输入主要联络人姓名",
                "text": "",
                "description": ""
            },
            2002: {
                "subject": "请输入主要联络人联络电话",
                "text": "",
                "description": ""
            },
            2003: {
                "subject": "请输入主要联络人电子邮件",
                "text": "",
                "description": ""
            },
            2004: {
                "subject": "主要联络人电子邮件格式错误",
                "text": "请输入正确的电子邮件格式",
                "description": ""
            },
            2005: {
                "subject": "次要联络人电子邮件格式错误",
                "text": "请输入正确的电子邮件格式",
                "description": ""
            },
            3001: {
                "subject": "请选择奉献系统模式",
                "text": "",
                "description": ""
            },
            3002: {
                "subject": "请输入蓝新金流Merchant ID",
                "text": "",
                "description": ""
            },
            3003: {
                "subject": "请输入蓝新金流Hash KEY",
                "text": "",
                "description": ""
            },
            3004: {
                "subject": "请输入蓝新金流Hash IV",
                "text": "",
                "description": ""
            },
            3005: {
                "subject": "请输入奉献连结",
                "text": "",
                "description": ""
            },
            3006: {
                "subject": "请输入奉献连结文字",
                "text": "",
                "description": ""
            },
            3007: {
                "subject": "请输入奉献描述内容",
                "text": "",
                "description": ""
            }
        }
    }

}


