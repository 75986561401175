
import {
    computed
} from "vue";

import {
    UserStore
} from "@/store/modules/user";

import {
    UserState
} from "@/store/interface/user";
import {Caption} from "@/api/interface/caption";
import {SettingStore} from "@/v2/store/modules/setting";

/**
 * 登入者資訊
 */

export const useUser = () => {

    /**
     * 用戶 store
     */
    const userStore = UserStore()

    /**
     * 獲取 - 用戶 - 資訊
     */
    const getUser = computed((): UserState => {
        return userStore.getAll
    })

    /**
     * 更新 - 用戶 - 資訊
     */
    const setUser = (
        e: UserState
    ) => {

        userStore.setAll(e)

        // 暫時先寫兩邊
        if (e.caption) SettingStore().setMenuCaption({
            my: e.caption.my ?? "",
            soul: e.caption.soul ?? "",
            daily: e.caption.daily ?? ""
        })

    }

    /**
     * 更新 - 用戶 - 頭像
     */
    const setUserAvatar = (
        e: string
    ) => userStore.setAvatar(e)

    /**
     * 更新 - 用戶 - 資訊
     */
    const setUserCaption = (
        e: Caption.Result
    ) => userStore.setCaption(e)

    /**
     * 更新 - 用戶 - 管理員對應
     */
    const setUserMapping = (
        e: boolean
    ) => userStore.setMapping(e)

    /**
     * 更新 - 用戶 - 資訊
     */
    const setUserChurchInvitation = (
        e: string | null
    ) => userStore.setChurchInvitation(e)

    return {
        getUser,
        setUser,
        setUserAvatar,
        setUserCaption,
        setUserMapping,
        setUserChurchInvitation,
    }

}
