/**
 * api - 傳輸層
 * 教會 - 標題
 * @author J
 * @since 2024-06-28 14:57:25
 */

import http from "@/v2/api";
import {Basic} from "@/v2/api/interface";
import {AdminChurchCaption} from "@/v2/api/interface/core/admin/church/caption";

/**
 * 基礎 - 路徑
 */
const baseUri: string = "/api/admin/church/v2/caption"

/**
 * 取得
 */
export const getAdminChurchCaption = () => {

    return http.get<Basic.Data<AdminChurchCaption>>(
        `${baseUri}`,
        {},
        {
            opts: {
                default: {
                    failure: false
                }
            }
        }
    )

};
