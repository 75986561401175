export default {
    category: {
        create: "請先前往建立分類",
        ajax: {
            first: "請先選擇指定教會",
            empty: "該教會尚未建立分類"
        }
    },
    option: {
        church: {
            all: "全部教會"
        },
        empty: {
            blank: "未選擇",
            subject: "沒有可用的選項",
            city: "請先選擇所在區域",
            target: "請先選擇類型"
        }
    }
}
