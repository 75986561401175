import {RouteRecordRaw} from "vue-router";

const cycle: RouteRecordRaw[] = [{
	//循環管理
	path: "cycle",
	name: "AdminCycle",
	meta: {
		permission: 13001,
		breadcrumb: [{
			key: 'admin.cycle.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/cycle/index.vue"),
}, {
	//循環新增
	path: "cycle/create",
	name: "AdminCycleCreate",
	meta: {
		permission: 13002,
		breadcrumb: [{
			key: 'admin.cycle.index',
			value: "AdminCycle"
		}, {
			key: 'admin.cycle.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/cycle/create.vue"),
}, {
	//循環編輯
	path: "cycle/edit/:uuid",
	name: "AdminCycleEdit",
	meta: {
		permission: 13004,
		breadcrumb: [{
			key: 'admin.cycle.index',
			value: "AdminCycle"
		}, {
			key: 'admin.cycle.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/cycle/edit.vue"),
}, {
	//循環紀錄
	path: "cycle/edit/:uuid/history",
	name: "AdminCycleHistory",
	meta: {
		permission: 5011,
		breadcrumb: [{
			key: 'admin.cycle.index',
			value: "AdminCycle"
		}, {
			key: 'admin.cycle.edit',
			value: "AdminCycleEdit"
		}, {
			key: 'admin.cycle.history.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/cycle/history/index.vue"),
}];

export default cycle;
