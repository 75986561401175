export const Create: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "附件"
        }
    },
    field: {
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        release: {
            subject: "发布App",
            holder: "请选择发布App"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    handler: {
        submit: {
            subject: "即将创建纪录",
            text: "请确认后执行"
        }
    },
    error: {
        1010: {
            "subject": "请选择状态",
            "text": ""
        },
        1020: {
            "subject": "请输入标题",
            "text": ""
        },
        1030: {
            "subject": "请输入描述",
            "text": ""
        },
        1040: {
            "subject": "请选择发布App",
            "text": ""
        }
    }

}
