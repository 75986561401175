import {Group} from "@/language/modules/component/shepherd/v2/attend/group/zhTW"
import {Choose} from "@/language/modules/component/shepherd/v2/attend/choose/zhTW"

export const Attend: {
    [key: string]: any
} = {

    group: Group,

    choose: Choose

}
