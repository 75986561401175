export default {
    help: [
        "温馨提示",
        "若您从 WORD文档/其他软体/其他网页转载 进行复制贴上, 容易造成App显示介面的跑版或不显示等状况",
        "建议您先将内容复制到「记事本」, 再从「记事本」中贴至内文中, 请您注意使用"
    ],
    disable: "请输入连结文字与网址",
    subject: {
        insert: "插入连结",
        edit: "编辑连结"
    },
    release: "释放连结",
    hyperlink: "前往连结",
    link: "插入连结",
    clear: "清空内文",
    summary: {
        "empty": `<span class="a1">未输入文字</span>`,
        "total": `合计约 <b class="cgBase">%s</b> 个文字`
    },
    field: {
        hyperlink: {
            url: "连结网址",
            text: "连结文字"
        },
    },
    holder: {
        hyperlink: {
            url: "请输入连结网址",
            text: "请输入连结文字"
        },
    }
}
