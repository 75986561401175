export default {
    editor: {
        help: {
            early: {
                subject: "需要早于直拨开始时间",
                activity: {
                    subject: "需要早于活动开始时间",
                }
            },
            async: {
                subject: "将自动同步直拨标题",
                activity: {
                    subject: "将自动同步文章标题",
                }
            },
            copy: "复制直播描述",
            share: "前往设置分享描述"
        },
        label: {
            live: "直播",
            on: "启用",
            off: "关闭"
        },
        field: {
            mode: "整合",
            kind: "种类",
            category: "主分类",
            secondary: "次分类",
            subject: "标题",
            publish: {
                on: "上架时间",
                off: "下架时间"
            },
            hyperlink: {
                media: "Youtube连结",
                music: "Google云端MP3"
            },
            status: "状态",
            share: "转发分享",
            content: "内容"
        },
        holder: {
            category: "请选择主分类",
            secondary: "请选择次分类",
            subject: "标题",
            publish: {
                on: "请选择上架时间",
                off: "请选择下架时间"
            },
            hyperlink: {
                media: "请输入Youtube连结",
                music: "请输入Google云端MP3"
            },
            status: "请选择状态",
            share: "请选择转发分享",
            content: "请输入内容"
        },
        error: {
            1001: {
                "subject": "请选择主分类",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择次分类",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择上架时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "上架时间需要小于直播开始时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架时间必须大于上架时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请选择转发分享",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请选择种类",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请输入Youtube连结",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入Google云端MP3",
                "text": "",
                "description": ""
            }
        }
    }
}
