/**
 * 枚舉
 * Pinia
 * @author J
 * @since 2024-04-30 07:34:25
 */

export enum PiniaEnum {

    /**
     * 設定
     */
    SETTING = "v2.setting",

    /**
     * 授權 - 權杖
     */
    AUTHORIZATION = "v2.authorization",

    /**
     * 授權 - 權杖
     */
    AUTHORIZATION_SESSION = "v2.authorization.session"

}