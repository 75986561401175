export const Cycle: {
    [key: string]: any
} = {

    subject: "循环资讯",

    item: {
        0: {
            subject: "循环代号"
        },
        1: {
            subject: "提醒代号"
        },
        2: {
            subject: "最后循环开始时间"
        }
    }

}