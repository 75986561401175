import echelon from "@/language/modules/component/activity/echelon/zhTW"
import remind from "@/language/modules/component/activity/remind/zhTW"
import register from "@/language/modules/component/activity/register/zhTW"
import condition from "@/language/modules/component/activity/condition/zhTW"

export default {

    /**
     * 場次
     */
    echelon: echelon,

    /**
     * 報名
     */
    register: register,

    /**
     * 條件
     */
    condition: condition,

    /**
     * 提醒
     */
    remind: remind

}
