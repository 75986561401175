<template>
  <div ref="component"
       :class="[
         props.class,
         `mb-${props.bottom}`
       ]"
       class="form-component">
    <label v-if="isShowLabel"
           class="form-label">
      <span>
        <span class="label-text"
              v-html="getLabel" />
        <span v-if="$slots.text"
                    class="help">
          <slot name="text" />
        </span>
        <span v-else-if="props.text"
              class="text-muted label-description ft-12 m-l-3"
              v-html="props.text" />
        <b v-if="props.must"
           class="must cgRed ft-18 m-l-5">*</b>
      </span>
      <span v-if="$slots.help"
            class="help">
        <slot name="help" />
      </span>
    </label>
    <el-tooltip
        :visible="active.is.error.enable"
        :popper-class="[
            `is-error is-error-from-input`,
            props.parameter.error > 0 ? `messenger error-${props.parameter.error}` : null
        ]"
        placement="top-end">
      <template #content>
        <span @click="props.parameter.error = 0"
            v-html="$t(`${props.prefix}.error.${props.parameter?.error}.subject`)" />
      </template>
      <el-input v-if="props.value === null && props.name"
                v-model="props.source[props.name]"
                @click="isStaticTooltip ? props.parameter.error = 0 : null"
                @blur="emit(`blur`)"
                @keyup.enter="emit(`submit`)"
                @keyup.stop.prevent.enter="onEnter"
                @compositionstart="onCompositionStart"
                @compositionend="onCompositionEnd"
                :placeholder="getHolder"
                :readonly="props.readonly || !!props.belong"
                :clearable="props.clearable && !(props.readonly || !!props.belong)"
                :class="[
                    props.variant,
                    props.isRight ? `text-right` : null,
                    props.readonly || !!props.belong ? `readonly` : null,
                    props.copy ? `is-copy` : null,
                    $slots.append ? `is-append` : null]"
                :maxlength="props.maxLength.toString()"
                type="text"
                autocomplete="off"
                :size="props.size">
        <template v-if="props.copy" #append>
          <el-tooltip
              class="box-item"
              :content="$t(`component.input.feature.clone`)"
              placement="top-start">
            <icon-sax @click="onClone"
                      class="ft-20 cursor hand"
                      type="copy"
                      :hover="false"/>
          </el-tooltip>
        </template>
        <template v-if="$slots.append"
                  #append>
          <slot name="append" />
        </template>
        <template v-if="$slots.prepend"
                  #prepend>
          <slot name="prepend" />
        </template>
      </el-input>
      <!-- props.value 必須有值, 才會顯示badge, 否則不顯示badge + 顯示placeholder -->
      <el-input v-else
                :placeholder="getHolder"
                :readonly="props.readonly || !!props.belong"
                :class="[
                    props.isRight ? `text-right` : null,
                    props.readonly || !!props.belong ? `readonly` : null,
                    props.variant
                ]"
                :value="props.isInlineBadge || !!props.belong ? null : props.value"
                type="text"
                autocomplete="off"
                :maxlength="props.maxLength.toString()"
                :size="props.size">
        <template
            v-if="isShowInlineBadge"
            #prefix>
          <j-badge
              :appearance="['badge-md', 'm-l--3'].concat(getInlineBadgeAppearance)"
              :text="getInlineBadgeText" />
        </template>
        <template
            v-if="props.copy"
            #append>
          <el-tooltip
              class="box-item"
              :content="$t(`component.input.feature.clone`)"
              placement="top-start">
            <icon-sax @click="onClone"
                      class="ft-20 cursor hand"
                      type="copy"
                      :hover="false"/>
          </el-tooltip>
        </template>
        <template v-if="$slots.append"
                  #append>
          <slot name="append" />
        </template>
      </el-input>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">

import _ from "lodash"

import JBadge from "@/component/Badge.vue"
import IconSax from "@/component/IconSax.vue"

import {
  computed, nextTick, reactive,
  ref, watch
} from "vue"

import {ElNotification} from 'element-plus'

import {useStaticTooltip} from "@/hook/useStaticTooltip"

import {useI18n} from "vue-i18n"
import {EnumMomentFormat} from "@/enum/moment";
import moment from "moment-timezone";
import {useDate} from "@/hook/useDate";
import {useBadgeVariant} from "@/hook/useBadgeVariant";
import {EnumVariantBadgeChurch} from "@/enum/variant";

const {
  t
} = useI18n()

const {
  getLocal
} = useDate()

const component = ref()

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "submit"): void
  (e: "enter"): void
  (e: "blur"): void
}>()

const props = withDefaults(defineProps<{
  //必填提示(*)
  must?: boolean
  //僅讀取
  readonly?: boolean
  //標題
  label?: string | null
  //副標題
  text?: string | null
  //提示字詞
  holder?: string | null
  //僅顯示文字
  value?: any
  //鍵值
  name?: string
  //傳入值
  source?: object
  //語言前置
  prefix?: string
  //錯誤列控制
  position?: number[]
  //控制參數
  parameter?: {
    position?: number[]
    error: number
  }
  //錯誤數字範圍
  error?: number[]
  //傳入的外框外觀
  class?: string | null
  //外框底部添加高度
  bottom?: number
  //可清除
  clearable?: boolean
  //複製功能
  copy?: boolean
  //input外觀
  variant?: string | null
  //尺寸
  size?: any
  //所屬教會
  belong?: "name" | "no" | null
  //所屬教會顯示鍵
  belongKey?: "belong" | "delegate"
  //為顯示時間
  isTime?: boolean
  //是否為表單最後一個空間, 如果是的話 tooltip 往上移
  isLastOne?: boolean
  //內崁標籤
  isInlineBadge?: boolean
  //內崁標籤配色
  inlineBadgeVariant?: string[]
  //最長長度
  maxLength?: number
  //靠右書寫
  isRight?: boolean
}>(), {
  must: false,
  readonly: false,
  clearable: false,
  prefix: "",
  label: null,
  text: null,
  holder: null,
  value: null,
  position: () => [],
  parameter: () => {
    return {
      position: [],
      error: 0
    }
  },
  source: () => { return {} },
  error: () => [],
  class: null,
  bottom: 1,
  copy: false,
  variant: null,
  size: `large`,
  belong: null,
  //所屬教會顯示鍵
  belongKey: "belong",
  isTime: false,
  isLastOne: false,
  isInlineBadge: false,
  inlineBadgeVariant: () => [],
  maxLength: 200,
  isRight: false,
})

const {
  getInputToChurchInline
} = useBadgeVariant()

const {
  isStaticTooltip
} = useStaticTooltip({
  active: props.parameter,
  prefix: props.prefix
})

const active = reactive<{
  is: {
    //輸入法
    ime: {
      //延遲閥
      threshold: number
      //啟用
      enable: boolean
    }
    error: {
      enable: boolean
    }
  }
}>({
  is: {
    ime: {
      threshold: 250,
      enable: false
    },
    error: {
      enable: false
    }
  }
})

//複製功能
const onClone = () => ElNotification({
  type: 'success',
  dangerouslyUseHTMLString: true,
  title: t(`component.message.clone.subject`),
  message: t(`component.message.clone.text`),
})

/**
 * 輸入法 - 開始
 */
const onCompositionStart = () => active.is.ime.enable = true

/**
 * 輸入法 - 結束
 * 需要稍微延遲一下, 否則選完字會馬上送出
 */
const onCompositionEnd = () => setTimeout(
    () => active.is.ime.enable = false,
    active.is.ime.threshold
)

/**
 * 送出
 */
const onEnter = () => {

  //避免輸入法問題
  if (active.is.ime.enable) return

  //呼叫 - 外部
  emit(`enter`)

}

const isShowStaticTooltip = computed(() :boolean =>{
  const r: boolean = isStaticTooltip.value && props.error.includes(props.parameter.error)
  const q: number[] = props.position!
  if (q.length === 0) return r
  return r && _.isEqual(q, props.parameter!.position)
})

/**
 * 顯示 - 標題
 */
const isShowLabel = computed((): boolean => {

  if (!!props.belong) return true
  return props.label !== null

})

/**
 * 顯示 - 內崁 - 徽章
 */
const isShowInlineBadge = computed((): boolean => {

  if (!!props.belong) return true
  return props.isInlineBadge && props.value

})

/**
 * 標題
 */
const getLabel = computed((): string | null => {
  if (!!props.belong) return t(`belong.${props.belongKey}.${props.belong}.subject`)
  return props.label
})

/**
 * 站位符
 */
const getHolder = computed((): string | null => {
  if (!!props.belong) return null
  return props.isInlineBadge && props.value ? null : props.holder
})

/**
 * 內崁 - 徽章 - 內文
 */
const getInlineBadgeText = computed((): string | null => {
  if (!!props.belong) return props.value
  return props.value
})

/**
 * 內崁 - 徽章 - 外觀
 */
const getInlineBadgeAppearance = computed((): string[] => {
  if (props.belong === "no") return getInputToChurchInline.value(EnumVariantBadgeChurch.NO)
  else if (props.belong === "name") return getInputToChurchInline.value(EnumVariantBadgeChurch.NAME)
  return props.inlineBadgeVariant ?? []
})

/**
 * 監控 - 錯誤 - 顯示
 * 因為可能會卡到tab的問題, 導致 el-tooltip 的 teleported 出錯
 * 需要經過 nextTicker() 就會正確了
 */
watch(()=>props.parameter.error, async () => {
  if (props.parameter.error > 0) await nextTick()
  active.is.error.enable = isShowStaticTooltip.value
})

/**
 * 修正本地時間
 * @param e 變更值
 */
watch(()=>props.source[props.name ?? ""], (e) =>{

  //必須是顯示時間+僅讀取
  if (props.isTime && props.readonly && e && props.name && moment(e, moment.ISO_8601).isValid()) {
    if (moment(e, "YYYY-MM-DD", true).isValid()) props.source[props.name!] = getLocal(e, EnumMomentFormat.DATE)
    else if (moment(e, "YYYY-MM-DD HH:mm", true).isValid()) props.source[props.name!] = getLocal(e, EnumMomentFormat.DATETIME_WITHOUT_SECOND)
    else props.source[props.name!] = getLocal(e, EnumMomentFormat.DATETIME)
  }

})

</script>

<style scoped lang="sass">
.help
  position: relative
  top: .01rem
</style>
