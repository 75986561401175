/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 頁面 - 錯誤
 */
export enum EnumPageError {

    /**
     * 未授權
     */
    UNAUTHORIZED = 1,

    /**
     * 找不到頁面
     */
    NOTFOUND

}

/**
 * 頁面 - 顯示
 */
export enum EnumPageDisplay {

    /**
     * 維修工單
     */
    WORKER = -99,

    /**
     * 不授權限管制的頁面
     */
    NO_RESTRICTION = -100,

    /**
     * 正常顯示
     */
    NORMAL = 1,

    /**
     * 無權限拒絕顯示
     */
    REJECT,

    /**
     * 登出不顯示
     */
    LOGOUT,

}
