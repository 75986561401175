/**
 * 圖庫管理
 */
export default {
    subject: "圖庫管理",
    index: "圖庫清單",
    accept: "圖庫核准",
    alarm : "圖庫檢舉",
    permission: {
        index: "圖庫管理 - 圖庫清單",
        accept: "圖庫管理 - 圖庫核准",
        alarm: "圖庫管理 - 圖庫檢舉"
    }
}
