import {createApp} from 'vue'
import $ from "jquery";
import "summernote";
import App from '@/App.vue'
import pinia from "@/store";
import i18n from "@/language";
import router from "@/router";
import BootstrapVue3 from 'bootstrap-vue-3';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import zhTw from 'element-plus/es/locale/lang/zh-tw';
import VueLazyload from 'vue-lazyload';
import vClickOutside from "click-outside-vue3"
import VueTablerIcons from "vue-tabler-icons"

/**
 *  pinia 要寫外面用import進來
 *  否則刷新畫面後store裡面的value會記不住
 */

import "bootstrap"
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import 'vue-loading-overlay/dist/css/index.css';
import 'element-plus/dist/index.css'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'
import 'summernote/dist/summernote-bs4.min.css'
import "vue3-tree-org/lib/vue3-tree-org.css"
import '@/style'
import '@/v2/style'

const app = createApp(App)

app.use(i18n)
   .use(router)
   .use(pinia)
   .use(BootstrapVue3)
   .use(VueLazyload)
   .use(VueTablerIcons)
   .use(vClickOutside)

//summernote 注入功能
app.component("html-editor", {
    template: `<div class="summernote" />`,
    props: {
        model: {
            required: true,
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    mounted() {

        const vm = this;
        const config: any = {
            //佔位符
            placeholder: this.placeholder,
            //禁用縮放
            disableResizeEditor: true,
            //禁止拖放
            disableDragAndDrop: true,
            //關閉tooltip
            tooltip: false,
            toolbar: [],
            callbacks: {},
        };

        config.callbacks = {
            onInit: () => {
                $(vm.$el).summernote("code", vm.model);
                if (vm.readonly) $(vm.$el).summernote('disable');
            },
            onFocus: () => {
                vm.$emit("focus");
            },
            onChange: () => {
                vm.$emit("update", $(vm.$el).summernote("code"));
            },
            onScroll: (e: any) => {
                vm.$emit("scroll", e.currentTarget.scrollTop);
            }
        };
        $(this.$el).summernote(config);
    }
})

const db = localStorage.getItem('SettingStore')
if( db !== null ) {
    const value = JSON.parse(db).region
    app.use(ElementPlus,{locale: value === 'zhTW' ? zhTw : zhCn})
}
else app.use(ElementPlus,{locale: zhTw})

//ElementPlusIcons
for (const [key, value] of Object.entries(ElementPlusIconsVue)) app.component(key, value)

app.mount('#app')

export default app
