/**
 * 忘記密碼 - 驗證碼 - 重設
 */
export default {

    subject: "重設登入密碼",
    feature: {
        back: "返回登入"
    },
    expire: {
        subject: `本次重設密碼令牌將在 <b class="ft-14 text-danger">%s</b> 秒後失效`,
        text: `本次重設密碼令牌已經失效`
    },
    field: {
        passwd: {
            create: "新密碼",
            confirm: "確認新密碼"
        }
    },
    holder: {
        passwd: {
            create: "請輸入新密碼",
            confirm: "請再輸入一次新密碼進行確認"
        }
    },
    handler: {
        success: {
            subject: "密碼重設成功",
            text: "請使用您設定的新密碼登入"
        },
        destroy: {
            subject: "是否確定放棄重設密碼",
            text: "確認後將返回登入頁面"
        }
    },
    error: {

        1000: {
            subject: "暫時無法使用",
            text: "請稍後再試或請聯絡管理人員"
        },
        1001: {
            subject: "請輸入新密碼",
            text: "",
            "description": ""
        },
        1002: {
            subject: "新密碼強度不足",
            text: "",
            "description": ""
        },
        1003: {
            subject: "請再輸入新密碼進行確認",
            text: "",
            "description": ""
        },
        1004: {
            subject: "新密碼確認失敗",
            text: "兩次密碼輸入不一致",
            "description": ""
        },
        9999: {
            subject: "重設密碼令牌已失效",
            text: "請重新進行忘記密碼程序"
        }

    }

}
