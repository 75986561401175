/**
 * 循環管理
 */
export default {
    index: "循環管理",
    create: "循環新增",
    edit: "循環內容",
    permission: "循環管理",
    history: {
        index: "循環紀錄",
        edit: "紀錄內容"
    }
}
