import {RouteRecordRaw} from "vue-router";

const team: RouteRecordRaw[] = [{
	path: "team",
	name: "ShepherdTeam",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/index.vue"),
}, {
	path: "team/attend",
	name: "ShepherdTeamAttend",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.attend.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/attend/index.vue"),
}, {
	path: "team/statistics/:uuid",
	name: "ShepherdTeamStatistics",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/index.vue"),
}, {
	path: "team/statistics/:uuid/attend",
	name: "ShepherdTeamStatisticsAttend",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.attend.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/attend/index.vue"),
}, {
	path: "team/statistics/:uuid/event",
	name: "ShepherdTeamStatisticsEvent",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.event.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/event/index.vue"),
}, {
	path: "team/statistics/:uuid/gender",
	name: "ShepherdTeamStatisticsGender",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.gender.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/gender/index.vue"),
}, {
	path: "team/statistics/:uuid/year",
	name: "ShepherdTeamStatisticsYear",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.year.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/year/index.vue"),
}, {
	path: "team/statistics/:uuid/deacon",
	name: "ShepherdTeamStatisticsDeacon",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.deacon.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/deacon/index.vue"),
}, {
	path: "team/statistics/:uuid/education",
	name: "ShepherdTeamStatisticsEducation",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.education.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/education/index.vue"),
}, {
	path: "team/statistics/:uuid/occupation",
	name: "ShepherdTeamStatisticsOccupation",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.occupation.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/occupation/index.vue"),
}, {
	path: "team/statistics/:uuid/marital",
	name: "ShepherdTeamStatisticsMarital",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.marital.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/marital/index.vue"),
}, {
	path: "team/statistics/:uuid/baptized",
	name: "ShepherdTeamStatisticsBaptized",
	meta: {
		permission: 54001,
		breadcrumb: [{
			key: 'shepherd.team.index',
			value: "ShepherdTeam"
		}, {
			key: 'shepherd.team.statistics.index',
			value: "ShepherdTeamStatistics"
		}, {
			key: 'shepherd.team.statistics.baptized.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/team/statistics/baptized/index.vue"),
}];

export default team;
