import {computed} from "vue";

/**
 * StaticTooltip 封裝
 */
export const useStaticTooltip = (
   props: {
       [key: string]: any
   }
) => {

    /**
     * 是否套用 StaticTooltip 顯示 錯誤
     */
    const isStaticTooltip = computed((): boolean => {
        return props.active !== undefined && props.prefix !== undefined
    })

    return {
        isStaticTooltip
    }

}
