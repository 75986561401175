/**
 * 管理員管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增管理員"
            }
        },
        field: {
            avatar: "頭像",
            name: "名字",
            account: "帳號",
            email: "電子郵件",
        },
        holder: {
            name: "請輸入名字",
            account: "請輸入帳號",
            email: "請輸入電子郵件",
            empty: {
                email: "未輸入"
            }
        },
        handler: {
            enable: {
                subject: "即將啟用管理員",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用管理員",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除管理員, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "權限"
            }
        },
        subject: {
            passwd: "設置密碼"
        },
        field: {
            account: "帳號",
            name: "名字",
            passwd: {
                generate: "登入密碼",
                validation: "確認登入密碼"
            },
            email: "電子郵件",
            phone: "聯絡電話"
        },
        holder: {
            account: "請輸入帳號",
            name: "請輸入名字",
            passwd: {
                generate: "請輸入登入密碼",
                validation: "請再次輸入登入密碼進行確認"
            },
            email: "請輸入電子郵件",
            phone: "請輸入聯絡電話",
            search: "請輸入關鍵字或權限代號, 進行權限名稱搜尋"
        },
        handler: {
            store: {
                subject: "即將新增管理員",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                subject: "請輸入帳號",
                text: "",
                "description": ""
            },
            1002: {
                subject: "帳號長度不符合規則",
                text: `至少需要 <b class="ft-20 text-danger">%s</b> 個字元`,
                "description": ""
            },
            1003: {
                subject: "帳號已經使用",
                text: "請更換一個帳號",
                "description": ""
            },
            1004: {
                subject: "請輸入名字",
                text: "",
                "description": ""
            },
            1005: {
                subject: "請輸入電子郵件",
                text: "",
                "description": ""
            },
            1006: {
                subject: "電子郵件格式錯誤",
                text: "請輸入正確的電子郵件格式",
                "description": ""
            },
            1007: {
                subject: "請輸入聯絡電話",
                text: "",
                "description": ""
            },
            1008: {
                subject: "請選擇狀態",
                text: "",
                "description": ""
            },
            1011: {
                subject: "請輸入登入密碼",
                text: "",
                "description": ""
            },
            1012: {
                subject: "登入密碼強度不足",
                text: "",
                "description": ""
            },
            1013: {
                subject: "請再輸入登入密碼進行確認",
                text: "",
                "description": ""
            },
            1014: {
                subject: "密碼確認失敗",
                text: "兩次密碼輸入不一致",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            passwd: "如果不需要變更密碼, 不用填寫",
            root: "系統帳號, 不得變更權限",
            disabled: "請注意, 這名管理員已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "權限"
            },
            99: {
                subject: "相關資訊"
            }
        },
        subject: {
            passwd: "變更密碼"
        },
        field: {
            avatar: "頭像",
            account: "帳號",
            name: "名字",
            passwd: {
                current: "目前當前密碼",
                generate: "登入密碼",
                validation: "確認登入密碼"
            },
            email: "電子郵件",
            phone: "聯絡電話"
        },
        holder: {
            account: "請輸入帳號",
            name: "請輸入名字",
            passwd: {
                current: "請輸入目前當前密碼",
                generate: "請輸入登入密碼",
                validation: "請再次輸入登入密碼進行確認"
            },
            email: "請輸入電子郵件",
            phone: "請輸入聯絡電話",
            search: "請輸入關鍵字或權限代號, 進行權限名稱搜尋"
        },
        handler: {
            update: {
                subject: "即將更新管理員內容",
                text: "請確認後執行",
                description: "",
                warning: {
                    subject: "即將更新管理員內容",
                    text: `<span class="text-danger ft18">您即將移除您編輯管理員權限的功能<br />移除後你將無法編輯管理員, 請確認後執行</span>`,
                    description: "",
                }
            }
        },
        error: {
            1001: {
                subject: "請輸入帳號",
                text: ""
            },
            1002: {
                subject: "帳號長度不符合規則",
                text: `至少需要 <b class="ft-20 text-danger">%s</b> 個字元`
            },
            1003: {
                subject: "帳號已經使用",
                text: "請更換一個帳號"
            },
            1004: {
                subject: "請輸入名字",
                text: ""
            },
            1005: {
                subject: "請輸入電子郵件",
                text: ""
            },
            1006: {
                subject: "電子郵件格式錯誤",
                text: "請輸入正確的電子郵件格式"
            },
            1007: {
                subject: "請輸入聯絡電話",
                text: ""
            },
            1008: {
                subject: "請選擇狀態",
                text: ""
            },
            1009: {
                subject: "請輸入當前密碼",
                text: ""
            },
            1010: {
                subject: "當前密碼錯誤",
                text: ""
            },
            1011: {
                subject: "請輸入登入密碼",
                text: ""
            },
            1012: {
                subject: "登入密碼強度不足",
                text: ""
            },
            1013: {
                subject: "請再輸入登入密碼進行確認",
                text: ""
            },
            1014: {
                subject: "密碼確認失敗",
                text: "兩次密碼輸入不一致"
            },
            9999: {
                subject: "即將返回首頁",
                text: "您已經自行移除管理員管理員權限"
            }
        }
    }

}
