/**
 * 心靈捕手文章管理
 */
export default {
    index: "心靈捕手文章管理",
    create: "心靈捕手文章新增",
    edit: "心靈捕手文章內容",
    copy: "文章複製",
    mindcatcher: "文章導入",
    permission: "心靈捕手文章管理 - 文章管理",
    comment: {
        index: "留言紀錄",
        permission: "心靈捕手文章管理 - 留言紀錄"
    },
    notification: {
        index: "推播紀錄",
        create: "推播新增",
        edit: "推播內容",
        permission: "心靈捕手文章管理 - 推播紀錄"
    },
    category: {
        index: "心靈捕手分類管理",
        permission: "心靈捕手文章管理 - 分類管理",
    }
}
