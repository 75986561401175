import {NavigationItemRaw} from '@/navigation/interface'

const billboard: NavigationItemRaw[] = [{
    id: 17,
    right: true,
    subject: 'admin.billboard.index',
    route: 'AdminBillboard',
    icon: 'briefcase',
    children: [],
    attached: [
        'AdminBillboardCreate',
        'AdminBillboardEdit'
    ]
}, {
    id: 17,
    right: false,
    subject: 'billboard.index',
    route: 'Billboard',
    icon: 'briefcase',
    children: [],
    attached: [
        'BillboardEdit'
    ]
}]

export default billboard
