/**
 * 图库管理 - 检举
 */
export default {
    empty: "此分类下找不到图片",
    disable: "请先选择需要处理的图片",
    submit: {
        reject: "核实检举",
        revoke: "撤销检举",
    },
    summary: `全部 <b class="ft-16 cgBase">%s</b> 张图片`,
    handler: {
        reload: {
            subject: "即将重新整理",
            text: "已选择的项目将会重设, 请确认后执行",
            description: ""
        },
        reject: {
            subject: "即将核实检举指定的图片",
            text: "请确认后执行",
            description: ""
        },
        revoke: {
            subject: "即将撤销检举指定的图片",
            text: "请确认后执行",
            description: ""
        }
    }
}
