/**
 * 聚会管理
 */
export default {
    subject: "聚会管理",
    index: "聚会清单",
    create: "聚会创建",
    edit: "聚会内容",
    permission: "聚会管理 - 聚会清单",
    schedule: {
        index: "聚会排程",
        edit: "排程内容"
    },
    history: {
        index: "聚会纪录",
        edit: "纪录内容"
    },
    category: {
        index: "分类管理",
        create: "分类创建",
        edit: "分类内容",
        permission: "聚会管理 - 分类管理"
    }
}
