import {NavigationItemRaw} from '@/navigation/interface'

const user: NavigationItemRaw[] = [{
    id: 53,
    right: true,
    subject: 'shepherd.client.index',
    route: 'ShepherdClient',
    icon: 'profile-2user',
    children: [],
    attached: [
        "ShepherdClientEdit",
        "ShepherdClientAdvance",
        "ShepherdClientCourse",
        "ShepherdClientAttend",
        "ShepherdClientAttendEdit"
    ]
}]

export default user
