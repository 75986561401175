export default {
    belong: {
        no: {
            subject: "所屬教會編號"
        },
        name: {
            subject: "所屬教會名稱"
        }
    },
    delegate: {
        no: {
            subject: "執行教會編號"
        },
        name: {
            subject: "執行教會名稱"
        }
    }
}
