import advance from "@/language/modules/page/shepherd/client/advance/zhCN";
import attend from "@/language/modules/page/shepherd/client/attend/zhCN";

/**
 * 会友
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "文档汇出_会友管理",
        },
        more: {
          on: "更多选项",
          off: "收起选项"
        },
        help: {
            manager: "为该族群的托管人员",
            stranger: "未跟进",
            archive: "不跟进"
        },
        feature: {
            create: {
                subject: "创建会友"
            },
            advance: {
                subject: "进阶功能"
            }
        },
        advance: {
            track: "已跟进会友",
            stranger: "未跟进会友",
            mark: "已标注会友",
            archive: "不跟进会友"
        },
        field: {
            head: {
                church: "指定教会"
            },
            avatar: "头像",
            name: "名字",
            email: "电子邮件",
            team: "族群",
            mark: "加注",
            connect: "连结",
            title: "称谓",
            country: "所在区域",
            city: "所在城市",
            occupation: "职业",
            education: "教育程度",
            deacon: "五重执事",
            marital: "婚姻状况"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名字",
            email: "请输入电子邮件",
            team: "请选择需要筛选的族群",
            title: "请选择称谓",
            country: "请选择所在区域",
            city: "请选择所在城市",
            occupation: "请选择职业",
            education: "请选择教育程度",
            deacon: "请选择五重执事",
            marital: "请选择婚姻状况"
        },
        handler: {
            enable: {
                subject: "即将启用会友",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用会友",
                text: "请确认后执行",
                description: ""
            },
            mark: {
                add: {
                    subject: "即将对此会友进行加注",
                    text: "请确认后执行",
                    description: ""
                },
                remove: {
                    subject: "即将对此会友移除加注",
                    text: "请确认后执行",
                    description: ""
                }
            }
        },
        factory: {
            export: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将进行会友汇出, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            },
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除会友, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 编辑
     */
    edit: {

        /**
         * 聚会
         */
        attend: attend,

        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
            disabled: "请注意, 这名会友已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "族群"
            },
            2: {
                subject: "相关历程"
            },
            99: {
                subject: "相关资讯"
            }
        },
        history: {
            course: {
                subject: "课程"
            },
            reunion: {
                subject: "聚会"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            name: "名字",
            nickname: "英文名字/暱称",
            birthday: "生日",
            email: "电子邮件",
            deacon: "五重执事",
            title: "称谓",
            education: "教育程度",
            marital: "婚姻状况",
            occupation: "职业",
            mobile: "手机",
            contact: "其他电话",
            country: "所在区域",
            city: "所在城市",
            address: "居住地址",
            school: "最高学校名称",
            consort: {
                name: "配偶名字",
                contact: "配偶联络方式"
            },
            commitment: "委身编号",
            guardianship: {
                name: "监护人名字",
                relationship: "监护人关系",
                contact: "监护人联络方式",
            },
            church: {
                enter: {
                    date: "进入教会日期"
                },
                leave: {
                    date: "离开教会日期",
                    reason: "离开教会原因"
                }
            },
            baptized: {
                is: "是否受洗",
                date: "受洗日期",
                church: "受洗教会"
            },
            note: "备注"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            head: "未输入",
            name: "请输入名字",
            nickname: "请输入英文名字/暱称",
            birthday: "请选择生日",
            email: "请输入电子邮件",
            deacon: "请选择五重执事",
            title: "请选择称谓",
            education: "请选择教育程度",
            marital: "请选择婚姻状况",
            occupation: "请选择职业",
            mobile: "请输入手机",
            commitment: "请输入委身编号",
            contact: "请输入其他电话",
            country: "请选择所在区域",
            city: "请选择所在城市",
            address: "请输入居住地址",
            school: "请输入最高学校名称",
            consort: {
                name: "请输入配偶名字",
                contact: "请输入配偶联络方式"
            },
            guardianship: {
                name: "请输入监护人名字",
                relationship: "请输入监护人关系",
                contact: "请输入监护人联络方式",
            },
            church: {
                enter: {
                    date: "请选择进入教会日期"
                },
                leave: {
                    date: "请选择离开教会日期",
                    reason: "请输入离开教会原因"
                }
            },
            baptized: {
                is: "请填写是否受洗",
                date: "请选择受洗日期",
                church: "请输入受洗教会"
            },
            note: "请输入备注"
        },
        handler: {
            update: {
                subject: "即将更新会友内容",
                text: "请确认后执行",
                description: ""
            }
        }
    },

    /**
     * 进阶
     */
    advance: advance

}
