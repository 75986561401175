/**
 * 行事历管理
 */
export default {

    /**
     * 列表
     */
    index: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
        },
        feature: {
            create: {
                subject: "创建群组"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            name: "名称",
            quantity: "人数"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名称",
        },
        handler: {
            enable: {
                subject: "即将启用群组",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用群组",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除群组, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名单"
            }
        },
        field: {
            name: "名称",
            keyword: "搜索名单"
        },
        holder: {
            name: "请输入名称",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            store: {
                subject: "即将创建群组",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请至少选择1位人员参与服事",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
            disabled: "请注意, 这个群组已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名单"
            },
            2: {
                subject: "统计资讯"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            history: {
                subject: "历史纪录"
            }
        },
        field: {
            belong: {
                no: "所属教会ID",
                name: "所属教会名称"
            },
            name: "名称",
            keyword: "搜索名单"
        },
        holder: {
            head: "未输入",
            belong: {
                no: "",
                name: ""
            },
            name: "请输入名称",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            update: {
                subject: "即将更新群组内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请至少选择1位人员参与服事",
                "text": "",
                "description": ""
            }
        },
        /**
         * 历史纪录
         */
        history: {
            advance: {
                week: "周内创建",
                month: "月内创建",
                leave: "离开人数"
            },
            description: {
                created: "群组建立",
                partake: "加入群组",
                leave: "从群组中离开"
            },
            field: {
                begin: "开始时间(起)",
                end: "结束时间(迄)",
                description: "描述",
                created: "时间"
            },
            holder: {
                begin: "请选择开始时间(起)",
                end: "请选择结束时间(迄)",
            }
        }
    }

}
