/**
 * 枚舉
 * 跳轉
 * @author J
 * @since 2024-04-30 07:34:25
 */

export enum IntentEnum {

    /**
     * 首頁
     */
    HOME,

    /**
     * 重新載入
     */
    RELOAD,

    /**
     * 前往
     */
    PUSH,

    /**
     * 取代
     */
    REPLACE,

    /**
     * 前往附掛頁面
     */
    ATTACH,

    /**
     * 登入
     */
    LOGIN,

    /**
     * 登出
     */
    LOGOUT

}