/**
 * 枚舉
 * 表單 - 表格
 * @author J
 * @since 2024-05-19 22:27:25
 */

/**
 * 欄位
 */
export enum FormTableFieldEnum {

    /**
     * ID
     */
    ID,

    /**
     * ID - 長
     */
    ID_MIDDLE,

    /**
     * 所屬教會
     */
    BELONG,

    /**
     * 總教會發起的推播
     */
    NOTIFICATION_HEAD,

    /**
     * 單號
     */
    NO,

    /**
     * 分類
     */
    CATEGORY,

    /**
     * 頭像
     * 單傳入url
     */
    AVATAR,

    /**
     * 用戶格式
     * Basic.User
     */
    USER,

    /**
     * 統計
     */
    STATISTICS,

    /**
     * 進度
     */
    PROGRESS,

    /**
     * 建立時間
     */
    DATETIME,

    /**
     * 提醒
     */
    REMIND,

    /**
     * 循環
     */
    CYCLE,

    /**
     * 建立時間
     */
    STATUS,

    /**
     * 檢視
     */
    VIEW,

    /**
     * 編輯
     */
    EDIT

}

/**
 * 排序
 */
export enum FormTableSortEnum {

    /**
     * 不排序
     */
    NONE,

    /**
     * 升冪
     */
    ASC,

    /**
     * 降冪
     */
    DESC

}

/**
 * 排序
 */
export enum FormTableMoreEnum {

    /**
     * 複製
     */
    COPY,

    /**
     * 啟用
     */
    ENABLE,

    /**
     * 停用
     */
    DISABLE,

    /**
     * 刪除
     */
    DESTROY,

    /**
     * 插槽
     */
    SLOT

}

/**
 * 搜尋
 */
export enum FormTableSearchEnum {

    /**
     * 輸入框
     */
    INPUT,

    /**
     * 下拉選項
     */
    SELECT,

    /**
     * 日期
     */
    DATE,

    /**
     * 起/迄
     */
    DURING,

    /**
     * 關鍵字
     */
    KEYWORD,

    /**
     * 會友 - 關鍵字
     */
    KEYWORD_CLIENT,

    /**
     * 插槽
     */
    SLOT

}

/**
 * 搜尋 - 區間 - 主鍵
 */
export enum FormTableSearchDuringKeyEnum {

    /**
     * 開始時間
     */
    ON = "on",

    /**
     * 結束時間
     */
    OFF = "off",

    /**
     * 建立時間
     */
    SUBMIT = "submit"

}

/**
 * 搜尋 - 區間 - 次鍵
 */
export enum FormTableSearchDuringSubKeyEnum {

    /**
     * 起
     */
    BEGIN = "begin",

    /**
     * 迄
     */
    END = "end"

}

/**
 * 搜尋 - 區間 - 組合鍵
 */
export enum FormTableSearchDuringCombineKeyEnum {

    /**
     * 開始時間 - 起
     */
    ON_BEGIN = "on.begin",

    /**
     * 開始時間 - 迄
     */
    ON_END = "on.end",

    /**
     * 結束時間 - 起
     */
    OFF_BEGIN = "off.begin",

    /**
     * 結束時間 - 迄
     */
    OFF_END = "off.end",

    /**
     * 建立時間 - 起
     */
    SUBMIT_BEGIN = "submit.begin",

    /**
     * 建立時間 - 迄
     */
    SUBMIT_END = "submit.end"

}