export const v2: {
    [key: string]: any
} = {

    my: "我的教会",

    soul: "心灵捕手",

    daily: "每日灵粮"

}
