/**
 * 枚舉
 * 客服紀錄
 * @author J
 * @since 2024-06-28 14:57:25
 */

/**
 * 客服管理 - 維修工單 - 類型
 */
export enum AdminSupportWorkerTypeEnum {

    /**
     * 一般
     */
    NORMAL = 1,

    /**
     * 加急
     */
    EMERGENCY

}

/**
 * 客服管理 - 維修工單 - 返工
 */
export enum AdminSupportWorkerBackEnum {

    /**
     * 未返工
     */
    NO,

    /**
     * 曾經返工
     */
    YES

}

/**
 * 客服管理 - 維修工單 - 超時
 */
export enum AdminSupportWorkerExpireEnum {

    /**
     * 未返工
     */
    NO,

    /**
     * 曾經返工
     */
    YES

}

/**
 * 客服管理 - 維修工單 - 狀態
 */
export enum AdminSupportWorkerStatusEnum {

    /**
     * 開立
     */
    CREATE,

    /**
     * 補充資料
     */
    REPLENISH,

    /**
     * 回報完工
     */
    COMPLETE,

    /**
     * 完工確認
     */
    COMPLETE_ACCEPT,

    /**
     * 退回返工
     */
    BACK,

    /**
     * 回報返工
     */
    BACK_COMPLETE,

    /**
     * 返工確認
     */
    BACK_COMPLETE_ACCEPT,

}

/**
 * 客服管理 - 維修工單 - 附件 - 類型
 */
export enum AdminSupportWorkerMimeEnum {

    /**
     * 圖片
     * 掛lightbox
     */
    IMAGE = 1,

    /**
     * 檔案
     * 直接下載
     */
    FILE,

    /**
     * 連結
     * 另開視窗
     */
    LINK,

    /**
     * 影片
     * 另開視窗
     */
    YOUTUBE

}

/**
 * 客服管理 - 維修工單 - 歷史紀錄 - 需要發布App
 */
export enum AdminSupportWorkerHistoryReleaseEnum {

    /**
     * 不需要
     */
    NO_NEED = 0,

    /**
     * 需要
     */
    NEED

}