export default {
    header: {
        total: "全部 %s 笔公告",
        unread: "未读取 %s 笔公告"
    },
    body: {
        field: {
            placeholder: "请输入标题关键字查询"
        },
        entries: {
            row: `%s・%s・共计 <b class="%s">%s</b> 次阅读`
        }
    },
    footer: {
        total: {
            all: `全部 <b class="ft-16 %s">%s</b> 笔公告`,
            filter: `筛选出 <b class="ft-16 %s">%s</b> 笔公告`
        }
    },
    recipient: {
        th: {
            0: {
                subject: "#"
            },
            1: {
                subject: "管理员"
            },
            2: {
                subject: "阅读时间"
            }
        },
        empty: "暂时还没有管理员阅读",
        not: {
            found: "无法搜索到<br />您指定条件的管理员"
        }
    }
}
