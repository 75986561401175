/**
 * 圖庫管理 - 核准
 */
export default {
    empty: "此分類下找不到圖片",
    disable: "請先選擇需要處理的圖片",
    summary: `全部 <b class="ft-16 cgBase">%s</b> 張圖片`,
    handler: {
        reload: {
            subject: "即將重新整理",
            text: "已選擇的項目將會重設, 請確認後執行",
            description: ""
        },
        approved: {
            subject: "即將核准指定的圖片",
            text: "請確認後執行",
            description: ""
        },
        reject: {
            subject: "即將拒絕指定的圖片",
            text: "請確認後執行",
            description: ""
        }
    }
}
