export const Editor: {
    [key: string]: any
} = {

    subject: "收件人",

    option: {
        all: {
            subject: "全部"
        },
    },
    dblclick: {
        subject: "双击加入",
        remove: {
            subject: "双击移除",
        }
    },
    summary: {
        total: {
            subject: `可选 <b class="ft-15 text-base">%s</b> 名会友`,
            right: {
                subject: `已选 <b class="ft-15 text-base">%s</b> 名会友`,
            },
            filtered: {
                subject: `可选 <b class="ft-15 text-base">%s</b> 名会友, 筛选出 <b class="ft-15 text-base">%s</b> 名会友`,
                right: {
                    subject: `已选 <b class="ft-15 text-base">%s</b> 名会友, 筛选出 <b class="ft-15 text-base">%s</b> 名会友`,
                }
            }
        }
    },
    th: [
        "",
        "头像",
        "会友"
    ],
    handler: {
        modified: {
            subject: "即将退出选择编辑",
            text: "异动内容将不会保存, 请确认后执行"
        }
    }

}