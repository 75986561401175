export const Attachment: {
    [key: string]: any
} = {
    subject: "加入附件",
    selected: {
        subject: `已经选择 <b class="text-danger ft-16">%s</b> 个附件`
    },
    th: {
        0: {
            subject: "类型"
        },
        1: {
            subject: "操作"
        }
    },
    field: {
        subject: "标题",
        description: "描述",
        mime: "类型",
        url: "附件网址"
    },
    holder: {
        subject: "请输入标题",
        description: "请输入描述",
        url: "请输入附件网址"
    },
    empty: {
        subject: "请点击创建进行加入附件"
    },
    complete: {
        not: {
            subject: "请先完善表单"
        }
    },
    help: {
        subject: "请输入标题",
        url: {
            subject: "请输入附件网址",
            illegal: {
                subject: "请输入正确的附件网址"
            }
        }
    },
    handler: {
        delete: {
            subject: "即将删除指定附件",
            text: ""
        }
    }
}
