export const Team: {
    [key: string]: any
} = {

    field: {

        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            text: "同时搜索名字/邮件/会友编号"
        },

        team: {
            subject: "服事群组",
            holder: "请选择服事群组"
        }

    },
    help: {
        team: {
            empty: {
                subject: "请先建立服事群组"
            }
        }
    }

}