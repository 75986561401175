import http from "@/api";
import {Session} from "@/api/interface/session";
import {Respond} from "@/api/interface/respond";

/**
 * 連線權杖檢查
 */
export const getSession = () => {
    return http.get<Session.Result>(`/api/session`, {} , {
        opts: {
            builtIn: {
                loadingMask: false
            }
        }
    });
};

/**
 * 建立連線權杖
 */
export const createApp = (
    no: string
) => {
    return http.get<Respond.Data<Session.App>>(`/api/session/create/app/${no}`);
};

/**
 * 建立連線權杖
 */
export const createLogin = (
    no: string
) => {
    return http.get<Respond.Data<Session.Login>>(`/api/session/create/${no}`);
};

/**
 * 提交 - 連線權杖建立 (登入)
 */
export const storeSession = (
    params: any,
) => {
    return http.put<Respond.Data<Session.Store>>(`/api/session/store`, params, {
        opts: {
            builtIn: {
                failure: false
            }
        }
    });
};

/**
 * 提交 - 連線權杖銷毀 (登出)
 */
export const destroySession = () => {
    return http.delete<Respond.Basic>(`/api/session/destroy`, {}, {
        opts: {
            builtIn: {
                failure: false
            }
        }
    });
};
