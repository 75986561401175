/**
 * 控制
 * pinia
 * 本地資料庫持久化
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {PersistedStateOptions} from "pinia-plugin-persistedstate";

const PiniaPersistConfiguration = (key: string) => {
    const persist: PersistedStateOptions = {
        key,
        storage: window.localStorage
    };
    return persist;
};

export default PiniaPersistConfiguration;
