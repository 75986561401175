export default [{
    value: 0,
    label: "关闭奉献"
}, {
    value: 1,
    label: "线上奉献"
}, {
    value: 2,
    label: "奉献连结"
}]
