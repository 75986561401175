import {Search} from "@/language/modules/component/form/v2/table/search/zhCN"

export const Table: {
    [key: string]: any
} = {

    search: Search,

    action: {
        add: {
            subject: "创建",
            not: {
                allowed: {
                    subject: "无创建权限"
                }
            }
        },
        move: {
            top: {
                subject: "移动至顶部"
            },
            left: {
                subject: "移动至左方"
            },
            right: {
                subject: "移动至右方"
            }
        },
        search: {
            subject: "搜索"
        },
        statistics: {
            subject: "统计"
        },
        checkbox: {
            subject: "多选"
        },
        reset: {
            subject: "重设"
        },
        refresh: {
            subject: "刷新"
        },
        loading: {
            subject: "读取中"
        },
        download: {
            subject: "汇出",
            not: {
                allowed: {
                    subject: "无汇出权限"
                }
            }
        },
        pagination: {
            subject: `每页 <b>%s</b> 笔资料`,
        }
    },
    footer: {
        subject: `目前于 第 <b class="text-justforyou-base ft-16">%s</b> 页 共 <b class="text-justforyou-base ft-16">%s</b> 页 全部 <b class="text-justforyou-base ft-16">%s</b> 笔资料`
    },
    readonly: {
        subject: "列表仅供读取",
        head: {
            subject: "总教会仅供查看资料, 无法创建修改"
        }
    },
    empty: {
        subject: "找不到指定的资料"
    },
    field: {
        belong: {
            subject: "所属教会"
        },
        head: {
            subject: "主动",
            text: "由总教会发起的主动推播"
        },
        cycle: {
            subject: "循环",
            cycle: {
                1: {
                    subject: "单次"
                },
                2: {
                    subject: "每日"
                },
                3: {
                    subject: "每周"
                },
                4: {
                    subject: "每月"
                },
                5: {
                    subject: "每年"
                }
            }
        },
        remind: {
            subject: "提醒",
            status: {
                subject: "已设置%s笔提醒",
                disable: {
                    subject: "未设置提醒",
                }
            }
        },
        edit: {
            subject: "操作",
            view: {
                subject: "查看"
            }
        }
    },
    off: {
        canvas: {
            statistics: {
                subject: "统计"
            },
            search: {
                subject: "搜索"
            }
        }
    },
    permission: {
        view: {
            not: {
                allowed: {
                    subject: "无查看权限"
                }
            }
        },
        edit: {
            not: {
                allowed: {
                    subject: "无编辑权限"
                }
            }
        }
    },
    edit: {
        subject: "编辑",
        more: {
            subject: "更多",
            copy: {
                subject: "复制"
            },
            enable: {
                subject: "启用"
            },
            disable: {
                subject: "停用"
            },
            destroy: {
                subject: "删除"
            }
        },
        view: {
            subject: "检视",
        }
    }
}
