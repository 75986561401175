import {RouteRecordRaw} from "vue-router";

const daily: RouteRecordRaw[] = [{
	//每日靈糧文章管理
	path: "daily_spirituals",
	name: "AdminDaily",
	meta: {
		permission: 7001,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/index.vue"),
}, {
	//每日靈糧文章新增
	path: "daily_spirituals/create",
	name: "AdminDailyCreate",
	meta: {
		permission: 7002,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/create.vue"),
}, {
	//每日靈糧文章編輯
	path: "daily_spirituals/edit/:uuid",
	name: "AdminDailyEdit",
	meta: {
		permission: 7004,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/edit.vue"),
}, {
	//每日靈糧文章 - 留言紀錄
	path: "daily_spirituals/edit/:uuid/comment",
	name: "AdminDailyComment",
	meta: {
		permission: 7011,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.edit',
			value: "AdminDailyEdit"
		}, {
			key: 'admin.daily.comment.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/comment/index.vue"),
}, {
	//每日靈糧文章 - 推播紀錄
	path: "daily_spirituals/edit/:uuid/notifications",
	name: "AdminDailyNotification",
	meta: {
		permission: 7021,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.edit',
			value: "AdminDailyEdit"
		}, {
			key: 'admin.daily.notification.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/index.vue"),
}, {
	//每日靈糧文章 - 推播紀錄 - 新增
	path: "daily_spirituals/edit/:uuid/notifications/create",
	name: "AdminDailyNotificationCreate",
	meta: {
		permission: 7022,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.edit',
			value: "AdminDailyEdit"
		}, {
			key: 'admin.daily.notification.index',
			value: "AdminDailyNotification"
		}, {
			key: 'admin.daily.notification.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/create.vue"),
}, {
	//每日靈糧文章 - 推播紀錄 - 編輯
	path: "daily_spirituals/edit/:uuid/notifications/edit/:uid",
	name: "AdminDailyNotificationEdit",
	meta: {
		permission: 7024,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.edit',
			value: "AdminDailyEdit"
		}, {
			key: 'admin.daily.notification.index',
			value: "AdminDailyNotification"
		}, {
			key: 'admin.daily.notification.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/edit.vue"),
}, {
	//每日靈糧文章 - 複製
	path: "daily_spirituals/copy/:uuid",
	name: "AdminDailyCopy",
	meta: {
		permission: 7002,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.copy',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/copy/index.vue"),
}, {
	//每日靈糧文章 - 心靈捕手
	path: "daily_spirituals/mindcatcher/:uuid",
	name: "AdminDailyMindCatcher",
	meta: {
		permission: 7002,
		breadcrumb: [{
			key: 'admin.daily.index',
			value: "AdminDaily"
		}, {
			key: 'admin.daily.mindcatcher',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/mindcatcher/index.vue"),
}];

export default daily;
