import {Create} from "@/language/modules/page/admin/event/v2/create/zhTW"
import {Edit} from "@/language/modules/page/admin/event/v2/edit/zhTW"

export const v2: {
    [key: string]: any
} = {

    /**
     * 新增
     */
    create: Create,

    /**
     * 編輯
     */
    edit: Edit,

    field: {
        subject: {
            subject: "名稱",
        },
        summary: {
            partake: {
                subject: "參與人數"
            }
        },
        status: {
            subject: "狀態",
            value: {
                0: {
                    subject: "已停用",
                },
                1: {
                    subject: "啟用中"
                }
            }
        },
        begin: {
            subject: "開始時間"
        },
        created: {
            subject: "建立時間"
        }
    },
    search: {
        subject: {
            subject: "名稱",
            holder: "請輸入名稱"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    handler: {
        enable: {
            subject: "即將啟用服事",
            text: `請確認後執行`,
        },
        disable: {
            subject: "即將停用服事",
            text: `請確認後執行`,
        },
        destroy: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將刪除服事, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        }
    }

}
