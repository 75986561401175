import unsplash from "./unsplash/zhTW"
import portal from "./portal/zhTW"
import nav from "./nav/zhTW"
import bool from "./bool/zhTW"
import belong from "./belong/zhTW"
import page from "./page/zhTW"
import error from "./error/zhTW"
import submit from "./submit/zhTW"
import share from "./share/zhTW"
import month from "./month/zhTW"
import gender from "./gender/zhTW"
import entrust from "./entrust/zhTW"
import donate from "./donate/zhTW"
import handler from "./handler/zhTW"
import component from "./component/zhTW"
import shepherd from "./shepherd/zhTW"
import ago from "./ago/zhTW"
import caption from "./caption/zhTW"
import weekday from "./weekday/zhTW"
import datepicker from "./datepicker/zhTW"
import invitation from "./invitation/zhTW"
import schedule from "./schedule/zhTW"
import maintenance from "./maintenance/zhTW"
import select from "./select/zhTW"
import {Status} from "@/v2/i18n/modules/zhTW/status";

export default {

	/**
	 * 平台名稱
	 */
	portal: portal,

	/**
	 * 教會 - 三項 - 標題
	 */
	caption: caption,

	/**
	 * 功能表
	 */
	nav: nav,

	/**
	 * n月
	 */
	month: month,

	/**
	 * 星期n
	 */
	weekday: weekday,

	/**
	 * 元件
	 */
	component: component,

	/**
	 * 頁面
	 */
	page: page,

	/**
	 * 共用handler
	 */
	handler: handler,

	/**
	 * 共用錯誤
	 */
	error: error,

	/**
	 * 共用按鈕名稱
	 */
	submit: submit,

	/**
	 * 是否
	 */
	bool: bool,

	/**
	 * 所屬教會
	 */
	belong: belong,

	/**
	 * 顯示多久以前
	 */
	ago: ago,

	/**
	 * 顯示多久以前
	 */
	datepicker: datepicker,

	/**
	 * 維護中
	 */
	maintenance: maintenance,

	/**
	 * 選項
	 */
	select: select,

	/**
	 * 共用選項
	 */
	options: {

		/**
		 * 牧養選單
		 */
		...shepherd,

		/**
		 * 排程 (是否上架)
		 */
		schedule: schedule,

		/**
		 * 邀請碼模式
		 */
		invitation: invitation,

		/**
		 * 分享狀態
		 */
		share: share,

		/**
		 * 性別
		 */
		gender: gender,

		/**
		 * 運作模式
		 */
		entrust: entrust,

		/**
		 * 奉獻模式
		 */
		donate: donate,

		/**
		 * 圖庫
		 */
		unsplash: unsplash

	},

	status: Status

}
