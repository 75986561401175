export default {
    editor: {
        help: {
            notification: "推播至活动建立者",
            add: {
                email: "创建电子邮件"
            },
            maximum: {
                email: `寄送邮件名单最多仅能设置 <b class="cgBase">%s</b> 笔`
            }
        },
        subject: "递交报名后提醒的传达方式",
        field: {
            notification: "推播通知",
            email: "电子邮件"
        },
        holder: {
            email: "请输入电子邮件"
        },
        error: {
            1001: {
                "subject": "请指定通知方式",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请至少选择一名收件人",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入邮件地址",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "邮件格式错误",
                "text": "",
                "description": ""
            }
        }
    }
}
