import {Respond} from "@/api/interface/respond";
import i18n from "@/language";
import {useSwalAlert} from "@/hook/useSwalAlert";
import {AppContext} from "vue";
import {getAdminChurchHead, getAdminChurchRepair, getAdminChurchSimulation} from "@/api/module/core/admin/church";
import {usePermission} from "@/hook/usePermission";

/**
 * 模擬登入
 */
export const useSimulation = () => {

    //導入i18n
    const {
        //@ts-ignore
        t
    } = i18n.global

    const {
        showHandler,
        showTwoFactory
    } = useSwalAlert()

    const {
        updateToken
    } = usePermission()

    /**
     * 返回
     */
    const onBack = () => showHandler({
        subject: t(`handler.session.head.subject`),
        text: t(`handler.session.head.text`),
        completion: {
            success: async () => await getAdminChurchHead((e: Respond.Data<Respond.Simulation>) => {

                //如果返回異常, 不往下執行
                if (!e.result) return false

                //注入新的JWT
                updateToken(e.data!.token)

            })
        }
    })

    /**
     * 提交
     */
    const onSubmit = (
        params: any,
        appContext: AppContext
    ) => showTwoFactory({
        subject: t(`handler.session.simulation.subject`),
        text: t(`handler.session.simulation.text`),
        description: t(`handler.session.repair.description`),
        completion: {
            success: async () => await getAdminChurchSimulation(
                params,
                (e: Respond.Data<Respond.Simulation>) => {

                    //如果返回異常, 不往下執行
                    if (!e.result) return false

                    //注入新的JWT
                    updateToken(e.data!.token)

                }
            )
        }
    }, appContext)

    /**
     * 重建模擬
     */
    const onRepair = (
        params: any,
        appContext: AppContext
    ) => showTwoFactory({
        subject: t(`handler.session.repair.subject`),
        text: t(`handler.session.repair.text`),
        description: t(`handler.session.repair.description`),
        completion: {
            success: async () => await getAdminChurchRepair(
                params,
                () => {}
            )
        }
    }, appContext)

    return {
        onBack,
        onSubmit,
        onRepair
    }

}
