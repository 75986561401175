/**
 * 教會管理
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "檔案匯出_教會管理",
        },
        feature: {
            create: {
                subject: "新增教會"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "主圖",
            no: "編號",
            name: "名稱",
            client: "會友人數",
            soul: "心靈捕手狀態",
            daily: "每日靈糧狀態"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            no: "請輸入編號",
            name: "請輸入名稱",
            soul: "請選擇心靈捕手狀態",
            daily: "請選擇每日靈糧狀態"
        },
        handler: {
            enable: {
                subject: "即將啟用教會",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用教會",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            export: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將進行教會匯出, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "聯絡人"
            },
            2: {
                subject: "奉獻"
            },
            3: {
                subject: "模組"
            },
        },
        help: {
            church: "請謹慎使用指定教會功能",
        },
        field: {
            name: "名稱",
            no: "編號",
            location: {
                country: "所在地區",
                city: "所在城市",
            },
            address: "地址",
            contact: [{
                subject: "主要聯絡人",
                name: "姓名",
                phone: "聯絡電話",
                email: "電子郵件"
            }, {
                subject: "次要聯絡人",
                name: "姓名",
                phone: "聯絡電話",
                email: "電子郵件"
            }],
            timezone: {
                subject: [
                    "時區設定",
                    "時區與機構設定"
                ],
                zone: "所在時區",
                organization: "機構"
            },
            control: {
                subject: "控制項",
                my: {
                    subject: "我的教會標題名稱",
                    text: "(尺寸為 %sx%s 像素)",
                    avatar: "標題圖"
                },
                daily: {
                    mode: "系統模式",
                    subject: "每日靈糧標題名稱",
                    text: "(尺寸為 %sx%s 像素)",
                    avatar: "標題圖"
                },
                soul: {
                    mode: "系統模式",
                    subject: "心靈捕手標題名稱",
                },
                query: "查詢長度"
            },
            donate: {
                mode: "奉獻方式",
                newebpay: {
                    merchant: "藍新金流Merchant ID",
                    key: "藍新金流Hash KEY",
                    iv: "藍新金流Hash IV"
                },
                hyperlink: {
                    text: "奉獻連結文字",
                    url: "奉獻連結",
                    description: "奉獻描述內容"
                }
            },
            other: {
                subject: "其他設定",
                offset: "App首頁最新消息筆數",
                period: "查詢區間",
                invitation: {
                    customize: "邀請碼模式",
                    code: "教會邀請碼"
                },
            }
        },
        holder: {
            name: "請輸入名稱",
            no: "請輸入編號",
            location: {
                country: "請選擇所在地區",
                city: "請選擇所在城市"
            },
            address: "請輸入地址",
            contact: [{
                name: "請輸入姓名",
                phone: "請輸入聯絡電話",
                email: "請輸入電子郵件"
            }, {
                name: "請輸入姓名",
                phone: "請輸入聯絡電話",
                email: "請輸入電子郵件"
            }],
            timezone: {
                zone: "請選擇時區",
                organization: "請選擇機構"
            },
            control: {
                my: {
                    subject: "請輸入我的教會標題名稱",
                },
                daily: {
                    subject: "請輸入每日靈糧標題名稱",
                },
                soul: {
                    subject: "請輸入心靈捕手標題名稱",
                },
                query: "請選擇查詢長度"
            },
            donate: {
                newebpay: {
                    merchant: "請輸入藍新金流Merchant ID",
                    key: "請輸入藍新金流Hash KEY",
                    iv: "請輸入藍新金流Hash IV"
                },
                hyperlink: {
                    text: "請輸入奉獻連結文字",
                    url: "請輸入奉獻連結",
                    description: "請輸入奉獻描述內容"
                }
            },
            other: {
                offset: "請選擇App首頁最新消息筆數",
                period: "請選擇查詢區間",
                invitation: {
                    code: [
                        "請點擊圖示產生隨機邀請碼",
                        "請點擊圖示自行輸入邀請碼"
                    ]
                }
            }
        },
        handler: {
            store: {
                subject: "即將新增教會",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "異常發生",
                "text": "無法停用目前登入的教會",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇所在區域",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇所在城市",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇所在時區",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇機構",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入我的教會標題名稱",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入心靈捕手標題名稱",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請選擇心靈捕手系統模式",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入每日靈糧標題名稱",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請選擇每日靈糧系統模式",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請輸入地址",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "請輸入編號",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "編號已經存在",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "請選擇查詢長度",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "請選擇邀請碼模式",
                "text": "",
                "description": ""
            },
            1018: {
                "subject": "請選擇App首頁最新消息筆數",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請輸入主要聯絡人姓名",
                "text": "",
                "description": ""
            },
            2002: {
                "subject": "請輸入主要聯絡人聯絡電話",
                "text": "",
                "description": ""
            },
            2003: {
                "subject": "請輸入主要聯絡人電子郵件",
                "text": "",
                "description": ""
            },
            2004: {
                "subject": "主要聯絡人電子郵件格式錯誤",
                "text": "請輸入正確的電子郵件格式",
                "description": ""
            },
            2005: {
                "subject": "次要聯絡人電子郵件格式錯誤",
                "text": "請輸入正確的電子郵件格式",
                "description": ""
            },
            3001: {
                "subject": "請選擇奉獻系統模式",
                "text": "",
                "description": ""
            },
            3002: {
                "subject": "請輸入藍新金流Merchant ID",
                "text": "",
                "description": ""
            },
            3003: {
                "subject": "請輸入藍新金流Hash KEY",
                "text": "",
                "description": ""
            },
            3004: {
                "subject": "請輸入藍新金流Hash IV",
                "text": "",
                "description": ""
            },
            3005: {
                "subject": "請輸入奉獻連結",
                "text": "",
                "description": ""
            },
            3006: {
                "subject": "請輸入奉獻連結文字",
                "text": "",
                "description": ""
            },
            3007: {
                "subject": "請輸入奉獻描述內容",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            disabled: "請注意, 這間教會已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "聯絡人"
            },
            2: {
                subject: "奉獻"
            },
            3: {
                subject: "模組"
            },
            99: {
                subject: "相關資訊"
            }
        },
        generate: [
            "隨機產生",
            "自行輸入",
            "清除邀請碼"
        ],
        field: {
            name: "名稱",
            no: "編號",
            location: {
                country: "所在地區",
                city: "所在城市"
            },
            address: "地址",
            description: {
                subject: "簡介標題",
                text: "簡介內容"
            },
            hyperlink: {
                text: "連結文字",
                url: "連結網址"
            },
            contact: [{
                subject: "主要聯絡人",
                name: "姓名",
                phone: "聯絡電話",
                email: "電子郵件"
            }, {
                subject: "次要聯絡人",
                name: "姓名",
                phone: "聯絡電話",
                email: "電子郵件"
            }],
            timezone: {
                subject: [
                    "時區設定",
                    "時區與機構設定"
                ],
                zone: "所在時區",
                organization: "機構"
            },
            control: {
                subject: "控制項",
                my: {
                    subject: "我的教會標題名稱",
                    text: "(尺寸為 %sx%s 像素)",
                    avatar: "標題圖"
                },
                daily: {
                    mode: "系統模式",
                    subject: "每日靈糧標題名稱",
                    text: "(尺寸為 %sx%s 像素)",
                    avatar: "標題圖"
                },
                soul: {
                    mode: "系統模式",
                    subject: "心靈捕手標題名稱",
                }
            },
            donate: {
                mode: "奉獻方式",
                newebpay: {
                    merchant: "藍新金流Merchant ID",
                    key: "藍新金流Hash KEY",
                    iv: "藍新金流Hash IV"
                },
                hyperlink: {
                    text: "奉獻連結文字",
                    url: "奉獻連結",
                    description: "奉獻描述內容"
                }
            },
            other: {
                subject: "其他設定",
                offset: "App首頁最新消息筆數",
                period: "查詢區間",
                invitation: {
                    customize: "邀請碼模式",
                    code: "教會邀請碼"
                }
            }
        },
        holder: {
            name: "請輸入名稱",
            no: "請輸入編號",
            location: {
                country: "請選擇所在地區",
                city: "請選擇所在城市"
            },
            address: "請輸入地址",
            description: {
                subject: "請輸入簡介標題",
                text: "請輸入簡介內容"
            },
            hyperlink: {
                text: "請輸入連結文字",
                url: "請輸入連結網址"
            },
            contact: [{
                name: "請輸入姓名",
                phone: "請輸入聯絡電話",
                email: "請輸入電子郵件"
            }, {
                name: "請輸入姓名",
                phone: "請輸入聯絡電話",
                email: "請輸入電子郵件"
            }],
            timezone: {
                zone: "請選擇時區",
                organization: "請選擇機構"
            },
            control: {
                my: {
                    subject: "請輸入我的教會標題名稱",
                },
                daily: {
                    subject: "請輸入每日靈糧標題名稱",
                },
                soul: {
                    subject: "請輸入心靈捕手標題名稱",
                },
            },
            donate: {
                newebpay: {
                    merchant: "請輸入藍新金流Merchant ID",
                    key: "請輸入藍新金流Hash KEY",
                    iv: "請輸入藍新金流Hash IV"
                },
                hyperlink: {
                    text: "請輸入奉獻連結文字",
                    url: "請輸入奉獻連結",
                    description: "請輸入奉獻描述內容"
                }
            },
            other: {
                offset: "請選擇App首頁最新消息筆數",
                period: "請選擇查詢區間",
                invitation: {
                    code: [
                        "請點擊圖示產生隨機邀請碼",
                        "請點擊圖示自行輸入邀請碼"
                    ]
                }
            }
        },
        handler: {
            invitation: {
                clear: {
                    subject: "即將清除教會邀請碼",
                    text: `<span class="text-danger">清除後儲存, 將無法使用教會邀請碼功能<br />請確認後執行</span>`,
                    description: ""
                }
            },
            update: {
                subject: "即將更新教會內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "異常發生",
                "text": "無法停用目前登入的教會",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇所在區域",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇所在城市",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇所在時區",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇機構",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入我的教會標題名稱",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入心靈捕手標題名稱",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請選擇心靈捕手系統模式",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入每日靈糧標題名稱",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請選擇每日靈糧系統模式",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請輸入地址",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "請選擇查詢長度",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "請選擇邀請碼模式",
                "text": "",
                "description": ""
            },
            1018: {
                "subject": "請選擇App首頁最新消息筆數",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請輸入主要聯絡人姓名",
                "text": "",
                "description": ""
            },
            2002: {
                "subject": "請輸入主要聯絡人聯絡電話",
                "text": "",
                "description": ""
            },
            2003: {
                "subject": "請輸入主要聯絡人電子郵件",
                "text": "",
                "description": ""
            },
            2004: {
                "subject": "主要聯絡人電子郵件格式錯誤",
                "text": "請輸入正確的電子郵件格式",
                "description": ""
            },
            2005: {
                "subject": "次要聯絡人電子郵件格式錯誤",
                "text": "請輸入正確的電子郵件格式",
                "description": ""
            },
            3001: {
                "subject": "請選擇奉獻系統模式",
                "text": "",
                "description": ""
            },
            3002: {
                "subject": "請輸入藍新金流Merchant ID",
                "text": "",
                "description": ""
            },
            3003: {
                "subject": "請輸入藍新金流Hash KEY",
                "text": "",
                "description": ""
            },
            3004: {
                "subject": "請輸入藍新金流Hash IV",
                "text": "",
                "description": ""
            },
            3005: {
                "subject": "請輸入奉獻連結",
                "text": "",
                "description": ""
            },
            3006: {
                "subject": "請輸入奉獻連結文字",
                "text": "",
                "description": ""
            },
            3007: {
                "subject": "請輸入奉獻描述內容",
                "text": "",
                "description": ""
            }
        }
    }

}


