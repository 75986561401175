export const Create: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "選項"
        },
        2: {
            subject: "名單"
        },
        3 : {
            subject: "提醒"
        },
        4 : {
            subject: "循環"
        }
    },
    field: {
        category: {
            subject: "分類",
            holder: "請選擇分類",
            help: "請先建立聚會分類"
        },
        name: {
            subject: "名稱",
            holder: "請輸入名稱"
        },
        begin: {
            subject: "開始時間",
            holder: "請選擇開始時間",
            help: "首次聚會開始時間"
        },
        end: {
            subject: "結束時間",
            holder: "請選擇結束時間",
            help: "首次聚會結束時間"
        },
        should: {
            subject: "簽到工具顯示應到會友",
            holder: "請選擇簽到工具顯示應到會友"
        },
        register: {
            subject: "允許會友自行簽到",
            holder: "請選擇允許會友自行簽到",
            help: {
                subject: "會友可自行於牧養對話中簽到",
                off: {
                    subject: "需要透過簽到工具或小組長出示掃描會友二維碼進行簽到",
                }
            }
        },
        place: {
            subject: "地點",
            holder: "請輸入地點"
        },
        note: {
            subject: "備註",
            holder: "請輸入備註"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    statistics: {
        history: {
            subject: "聚會紀錄"
        },
        schedule: {
            subject: "聚會排程"
        }
    },
    alert: {
        readonly: {
            subject: "目前聚會處於僅讀取狀態無法更新"
        },
        disable: {
            subject: "目前聚會處於停用狀態"
        }
    },
    handler: {
        submit: {
            subject: "即將儲存內容",
            text: "請確認後執行"
        }
    },
    submit: {
        cycle: {
            subject: "預覽排程"
        }
    },
    error: {
        1010: {
            "subject": "請選擇分類",
            "text": "",
            "description": ""
        },
        1020: {
            "subject": "請輸入名稱",
            "text": "",
            "description": ""
        },
        1030: {
            "subject": "請選擇開始時間",
            "text": "",
            "description": ""
        },
        1040: {
            "subject": "請選擇結束時間",
            "text": ""
        },
        1041: {
            "subject": "結束時間必須大於開始時間",
            "text": ""
        },
        1050: {
            "subject": "請選擇簽到工具顯示應到會友",
            "text": ""
        },
        1060: {
            "subject": "請選擇自行簽到",
            "text": ""
        },
        1070: {
            "subject": "請選擇狀態",
            "text": ""
        },
        2010: {
            "subject": "請完善選項的參數設定",
            "text": ""
        },
        3010: {
            "subject": "請至少選擇1個族群",
            "text": ""
        },
        4010: {
            "subject": "請完善提醒的參數設定",
            "text": ""
        },
        5010: {
            "subject": "請完善循環的參數設定",
            "text": ""
        }
    }

}