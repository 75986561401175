/**
 * 公告栏
 */
export default {

    /**
     * 列表
     */
    index: {
        uncheck: "未读",
        empty: "暂时没有任何公告",
        not: {
            found: "找不到指定的公告"
        }
    },

    /**
     * 编辑
     */
    edit: {
        read: `%s・共计 <b class="%s">%s</b> 次阅读`
    }

}
