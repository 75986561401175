import {NavigationItemRaw} from '@/navigation/interface'

const daily: NavigationItemRaw[] = [{
    id: 7,
    right: true,
    subject: 'admin.daily.index',
    route: 'AdminDaily',
    icon: 'wifi',
    children: [],
    attached: [
        'AdminDailyCreate',
        'AdminDailyEdit',
        'AdminDailyCopy',
        'AdminDailyMindCatcher',
        'AdminDailyComment',
        'AdminDailyNotification',
        'AdminDailyNotificationCreate',
        'AdminDailyNotificationEdit'
    ]
}]

export default daily
