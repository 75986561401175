/**
 * 聚会 - 历程
 */
export default {
    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教会"
            },
            name: "族群名称",
            subject: "聚会标题",
            begin: "开始时间",
            end: "结束时间"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名字",
        }
    },
    edit: {
        tip: {
            yet: {
                subject: "聚会尚未开始"
            },
            ing: {
                subject: "聚会正在进行"
            },
            ended: {
                subject: "聚会已经结束"
            }
        },
        feature: {
            0: "下载报到介面二维码",
            1: "重制点名单",
            2: "前往报到介面",
            3: "显示报到介面二维码",
            4: "复制报到介面网址",
            11: "聚会已经结束, 无法重制点名单"
        },
        download: {
            subject: "%s_报到介面二维码",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名单"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            history: {
                subject: "聚会纪录"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            qrcode: {
                subject: "APP专用报到二维码",
                text: "点击下载"
            },
            name: "名称",
            category: "分类",
            begin: "开始时间",
            end: "结束时间",
            location: "地点",
            note: "备注",
            status: "状态"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            name: "请输入名称",
            category: "请选择分类",
            begin: "请选择开始时间",
            end: "请选择结束时间",
            location: "请输入地点",
            note: "请输入备注",
            status: "请选择状态"
        },
        handler: {
            rebuild: {
                subject: "重制点名单",
                text: `若您发现点名单成员不正确<br />可进行点名单重制, 请确认后执行`
            }
        }
    }
}
