import {computed} from "vue";
import {MenuStore} from "@/store/modules/menu";
import {UserStore} from "@/store/modules/user";
import {SettingStore} from "@/v2/store/modules/setting";


/**
 * menuStore 二次封裝
 */
export const useMenu = () => {

    const userStore = UserStore();

    const menuStore = MenuStore();

    /**
     * 設定折疊功能表
     */
    const setCollapsed = (value: boolean) => {

        menuStore.setCollapsed(value)

        // 暫時先寫兩邊
        SettingStore().setMenuCollapsed(value)

    }

    /**
     * 是否折疊功能表
     */
    const isCollapsed = computed((): boolean => {
        return menuStore.isCollapsed
    })

    /**
     * 設定浮動功能表
     */
    const setOverlay = (value: boolean) => {

        menuStore.setOverlay(value)

        // 暫時先寫兩邊
        SettingStore().setMenuOverlay(value)

    }

    /**
     * 是否打開浮動功能表
     */
    const isOverlay = computed((): boolean => {
        return menuStore.isOverlay
    })

    /**
     * 填Breadcrumb
     */
    const getCaption = computed(():{
        my: string | null,
        soul: string | null,
        daily: string | null,
    } => {
        return userStore.getAll.caption
    })

    return {
        isOverlay,
        isCollapsed,
        setOverlay,
        setCollapsed,
        getCaption
    }

}
