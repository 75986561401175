import category from "@/language/modules/page/admin/live/category/zhCN";

/**
 * 直播管理
 */
export default {

    /**
     * 分类
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建直播"
            },
        },
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            subject: "标题",
            avatar: "封面图",
            quantity: "直播数量",
            category: "分类",
            integrate: "文章整合",
            sort: "排序",
            created: "建立时间"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            integrate: "请选择文章整合",
            subject: "请输入名称",
            category: "请选择分类",
        },
        handler: {
            enable: {
                subject: "即将启用直播",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用直播",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除直播, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        help: {
            church: "请谨慎使用指定教会功能",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "文章"
            },
            2: {
                subject: "图片"
            },
            3: {
                subject: "分享"
            }
        },
        field: {
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            church: "指定教会",
            category: "分类",
            subject: "标题",
            hyperlink: "YouTube网址",
            activity: {
                on: "开始时间",
                off: "结束时间"
            },
            tab: {
                lobby: "标签 - 大厅",
                prayer: "标签 - 代祷",
                about: "标签 - 关于"
            },
            welcome: {
                lobby: "欢迎词 - 大厅",
                prayer: "欢迎词 - 代祷",
            },
            reply: {
                prayer: "回应词 - 代祷",
            },
            collection: "文章图片",
            description: "描述",
            status: "状态"
        },
        holder: {
            kind: "请选择类型",
            church: "请选择指定教会",
            category: "请选择分类",
            subject: "请输入名称",
            hyperlink: "请输入YouTube网址",
            activity: {
                on: "请输入开始时间",
                off: "请输入结束时间"
            },
            tab: {
                lobby: "请输入标签(大厅)",
                prayer: "请输入标签(代祷)",
                about: "请输入标签(关于)"
            },
            welcome: {
                lobby: "请输入欢迎词(大厅)",
                prayer: "请输入欢迎词(代祷)"
            },
            reply: {
                prayer: "请输入回应词(代祷)",
            },
            collection: "",
            description: "请输入描述",
            status: "请选择状态"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            store: {
                subject: "即将创建直播",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择类型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入标签(大厅)",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入标签(代祷)",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请输入标签(关于)",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入欢迎词(大厅)",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请输入欢迎词(代祷)",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请输入回应词(代祷)",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "请输入描述",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "请输入YouTube连结",
                "text": "",
                "description": ""
            },
            4001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        comment: {
            subject: "聊天室发言内容",
            th: {
                0: "头像",
                1: "姓名",
                2: "内容",
                3: "时间",
            },
            passenger: {
                subject: "访客"
            }
        },
        secure: {
            subject: "私密代祷内容",
            th: {
                0: "头像",
                1: "姓名",
                2: "内容",
                3: "时间",
            },
            passenger: {
                subject: "访客"
            },
            handler: {
                secure: {
                    subject: "您即将查看会友私密个人资讯",
                    text: "我将妥善与谨慎使用相关资讯",
                }
            }
        },
        tip: {
            disabled: "请注意, 这个直播已经停用"
        },
        feature: {
            0: "复制直播网址",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "文章"
            },
            2: {
                subject: "图片"
            },
            3: {
                subject: "分享"
            },
            4: {
                subject: "统计资讯"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            comment: {
                subject: "聊天室发言"
            },
            secure: {
                subject: "私密代祷"
            }
        },
        field: {
            belong: {
                no: "所属教会编号",
                name: "所属教会名称"
            },
            kind: "类型",
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            category: "分类",
            subject: "标题",
            hyperlink: "YouTube网址",
            activity: {
                on: "开始时间",
                off: "结束时间"
            },
            tab: {
                lobby: "标签 - 大厅",
                prayer: "标签 - 代祷",
                about: "标签 - 关于"
            },
            welcome: {
                lobby: "欢迎词 - 大厅",
                prayer: "欢迎词 - 代祷",
            },
            reply: {
                prayer: "回应词 - 代祷",
            },
            collection: "文章图片",
            description: "描述",
            status: "状态"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            kind: "请选择类型",
            category: "请选择分类",
            subject: "请输入名称",
            hyperlink: "请输入YouTube网址",
            activity: {
                on: "请输入开始时间",
                off: "请输入结束时间"
            },
            tab: {
                lobby: "请输入标签(大厅)",
                prayer: "请输入标签(代祷)",
                about: "请输入标签(关于)"
            },
            welcome: {
                lobby: "请输入欢迎词(大厅)",
                prayer: "请输入欢迎词(代祷)"
            },
            reply: {
                prayer: "请输入回应词(代祷)",
            },
            collection: "",
            description: "请输入描述",
            status: "请选择状态"
        },
        handler: {
            clear: {
                all: {
                    subject: "即将清除所有文章图片",
                    text: "请确认后执行",
                    description: ""
                }
            },
            update: {
                subject: "即将更新直播内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择类型",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入标签(大厅)",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入标签(代祷)",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请输入标签(关于)",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请输入欢迎词(大厅)",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请输入欢迎词(代祷)",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入回应词(代祷)",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入描述",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "请选择指定教会",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "请输入YouTube连结",
                "text": "",
                "description": ""
            },
            4001: {
                "subject": "请输入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
