import {Attend} from "@/language/modules/component/shepherd/v2/attend/zhTW"

export const v2: {
    [key: string]: any
} = {

    /**
     * 聚會
     */
    attend: Attend

}
