<template>
  <nav class="navbar header-navbar navbar navbar-shadow align-items-center navbar-light navbar-expand floating-nav">
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <!-- sm以下, 展開右側滑動選單 -->
        <ul v-if=" props.isMinimal " class="nav navbar-nav d-xl-none">
          <!-- 打開左側選單 -->
          <li @click="setOverlay(!isOverlay)"
              class="nav-item">
            <a class="d-flex align-items-center justify-content-center nav-link menu-toggle is-active">
              <icon-sax type="menu" />
            </a>
          </li>
        </ul>
        <!-- 切換系統選單 -->
        <j-menu-portal />
      </div>
      <ul class="rg nav navbar-nav align-items-center ms-auto">
        <!-- 邀請碼 -->
        <j-menu-invitation />
        <!-- 公告欄 -->
        <j-menu-billboard
            v-if="!isHead" />
        <!-- 切換主題 -->
        <j-menu-theme />
        <!-- 切換語系 -->
        <j-menu-region />
        <!-- 使用者資訊 -->
        <j-menu-user />
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"

import JMenuPortal from "@/component/menu/horizontal/item/HorizontalMenuPortal.vue"
import JMenuTheme from "@/component/menu/horizontal/item/HorizontalMenuTheme.vue"
import JMenuBillboard from "@/component/menu/horizontal/item/HorizontalMenuBillboard.vue"
import JMenuUser from "@/component/menu/horizontal/item/HorizontalMenuUser.vue"
import JMenuRegion from "@/component/menu/horizontal/item/HorizontalMenuRegion.vue"
import JMenuInvitation from "@/component/menu/horizontal/item/HorizontalMenuInvitation.vue"

import {withDefaults} from "vue"

import {useMenu} from "@/hook/useMenu"
import {usePermission} from "@/hook/usePermission"

const {
  isHead
} = usePermission()

const {
  isOverlay,
  setOverlay
} = useMenu()

const props = withDefaults(defineProps<{
  //是否縮到sm以下
  isMinimal?: boolean
}>(),{
  isMinimal: false
})

</script>

<style scoped lang="sass">
.rg
  li
    margin-left: 12px
    &:last-of-type
      margin-right: 3px
</style>
