import {NavigationItemRaw} from '@/navigation/interface'
import {RouteNameEnum} from "@/v2/enumerate/route";

const attend: NavigationItemRaw[] = [{
    id: 55,
    right: true,
    subject: 'shepherd.attend.subject',
    route: null,
    icon: 'audio',
    children: [{
        id: 55001,
        right: true,
        subject: 'shepherd.attend.index',
        route: RouteNameEnum.SHEPHERD_ATTEND,
        attached: [
            RouteNameEnum.SHEPHERD_ATTEND_CREATE,
            RouteNameEnum.SHEPHERD_ATTEND_EDIT,
            RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE,
            RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE_EDIT,
            RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY,
            RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY_EDIT
        ]
    }, {
        id: 55011,
        right: true,
        subject: 'shepherd.attend.category.index',
        route: RouteNameEnum.SHEPHERD_ATTEND_CATEGORY,
        attached: [
            RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_CREATE,
            RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_EDIT
        ]
    }],
    attached: []
}]

export default attend
