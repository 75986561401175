import reset from "@/language/modules/page/forgot/verification/reset/zhCN";

/**
 * 忘记密码 - 验证码
 */
export default {

    reset: reset,

    subject: "确认邮件验证码",
    text: "请输入电子邮件中的 6 位数字验证码",
    expire: {
        subject: `本次重设密码令牌将在 <b class="ft-14 text-danger">%s</b> 秒后失效`,
        text: `本次重设密码令牌已经失效`
    },
    feature: {
        back: "返回登入"
    },
    handler: {
        destroy: {
            subject: "是否确定放弃重设密码",
            text: "确认后将返回登入页面"
        }
    },
    error: {

        1000: {
            subject: "暂时无法使用",
            text: "请稍后再试或请联络管理人员"
        },
        1001: {
            subject: "验证码空白",
            text: "请输入您的验证码"
        },
        1002: {
            subject: "验证码长度异常",
            text: `验证码长度应为 <b class="ft-18 text-danger">6</b> 个数字`
        },
        1003: {
            subject: "验证码错误",
            text: "请确认您的验证码"
        },
        9999: {
            subject: "重设密码令牌已失效",
            text: "请重新进行忘记密码程序"
        }

    }

}
