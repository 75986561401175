import {Date} from "@/v2/i18n/modules/zhTW/date";
import {Status} from "@/v2/i18n/modules/zhTW/status";
import {Submit} from "@/v2/i18n/modules/zhTW/submit";
import {Option} from "@/v2/i18n/modules/zhTW/option";
import {Handler} from "@/v2/i18n/modules/zhTW/handler";

export const zhTW: {
    [key: string]: any
} = {

    date: Date,

    status: Status,

    submit: Submit,

    option: Option,

    handler: Handler

}