/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 時間 - 格式化 - 單位
 */
export enum EnumMomentFormat {

    /**
     * 圖表
     */
    CHART = "YYYY-MM-DD",

    /**
     * 圖表, 不帶年
     */
    CHART_DAY = "HH:mm",

    /**
     * 圖表, 不帶年
     */
    CHART_WEEK = "MM-DD",

    /**
     * 日期
     */
    DATE = "YYYY-MM-DD",

    /**
     * 日期帶時間
     */
    DATETIME = "YYYY-MM-DD HH:mm:ss",

    /**
     * 日期帶時間, 不帶秒數
     */
    DATETIME_WITHOUT_SECOND = "YYYY-MM-DD HH:mm",

}

/**
 * 時間 - 格式化 - 單位
 */
export enum EnumMomentUnit {

    /**
     * 年
     */
    YEAR = 'years',

    /**
     * 年
     */
    MONTH = 'months',

    /**
     * 天
     */
    DAY = 'days',

    /**
     * 小時
     */
    HOUR = 'hours',

    /**
     * 分鐘
     */
    MINUTE = 'minutes',

    /**
     * 秒
     */
    SECOND = 'seconds'

}
