<template>
  <div class="app-content reject-page content">
    <div class="content-wrapper">
      <div class="content-body">
        <div class="misc-wrapper">
          <router-link
              v-if="getUser.church.no"
              :to="getRouterLink"
              class="d-flex brand-logo">
            <img src="/img/icon/favicon.png" alt="logo" class="">
          </router-link>
          <a class="d-flex brand-logo" v-else>
            <img src="/img/icon/favicon.png" alt="logo" class="">
          </a>
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-2 text-dark"
                  v-html="$t(`error.${getCode}.subject`)" />
              <p class="ft-16 mb-1 text-muted"
                 v-html="$t(`error.${getCode}.text`)" />
              <p class="ft-16 mb-2 text-muted"
                 v-html="$t(`error.${getCode}.description`)" />
              <router-link
                  v-if="props.home && getUser.church.no"
                  :to="getRouterLink" class="btn btn-lg ft-16 bg-gradient-base mb-1 btn-sm-block waves-effect waves-float waves-light">
                {{$t(`submit.home`)}}
              </router-link>
              <div>
                <img v-if="props.type === EnumPageError.UNAUTHORIZED"
                     class="img-fluid" src="/img/error/not-authorized.svg" alt="Not authorized page">
                <img v-else
                     class="img-fluid" src="/img/error/page-not-found.svg" alt="Not authorized page">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed} from "vue"
import {useUser} from "@/hook/useUser"
import {EnumPageError} from "@/enum/page";
import {RouteNameEnum} from "@/v2/enumerate/route";

const {
  getUser
} = useUser()

const props = withDefaults(defineProps<{
  //錯誤類型
  type?: number
  //是否顯示返回
  home?: boolean
}>(),{
  type: EnumPageError.NOTFOUND,
  home: true
})

const getCode = computed(() :number =>  {
  if (props.type === EnumPageError.UNAUTHORIZED) return 401
  return 404
})

const getRouterLink = computed(()=>{
  return {
    name: RouteNameEnum.ADMIN_DASHBOARD,
    params: {
      id: getUser.value.church.no
    }
  }
})

</script>

<style scoped>

</style>
