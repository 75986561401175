<template>
  <footer :class="[
        'd-flex justify-content-between align-items-center',
        'footer',
        'footer-light',
        isMinimal ? ( isOverlay ? 'footer-open' : 'footer-hide' ) : ( isCollapsed ? 'footer-collapsed' : 'footer-expanded' ),
        'footer-static']">
    <div class="w-100 text-right">
      <span class="text-success">
        <span class="m-r-5 text-muted ks ft-13"
              v-html="getDescription" />
      </span>
    </div>
  </footer>
</template>

<script setup lang="ts">

import {sprintf} from "sprintf-js"

import {computed, withDefaults} from "vue"

import {useUser} from "@/hook/useUser"
import {useI18n} from "vue-i18n";

const {
  t
} = useI18n()

const {
  getUser
} = useUser()

const props = withDefaults(defineProps<{
  //是否縮到sm以下
  isMinimal: boolean
  isCollapsed: boolean
  isOverlay: boolean
}>(),{
  isMinimal: false,
  isCollapsed: false,
  isOverlay: false
})

const getDescription = computed((): string => {
  //
  return sprintf(
      t(`component.footer.description`),
      import.meta.env.VITE_SITE_SITE,
      import.meta.env.VITE_SITE_TEACH
  )
})

</script>

<style scoped lang="sass">
.dark-layout
  footer
    box-shadow: 0 -5px 15px 0 rgba(34, 41, 47, 0.75)
</style>
