export const Choose: {
    [key: string]: any
} = {

    enable: {
        subject: "启用点名选项",
        multiple: {
            subject: "点名选项可复选"
        }
    },
    field: {
        name: {
            holder: "请输入点名选项名称"
        }
    },
    join: {
        subject: "创建点名选项"
    },
    submit: {
        enable: {
            subject: "启用",
            reverse: {
                subject: "停用"
            }
        }
    },
    help: {
        picked: {
            subject: "已设置%s个点名选项",
            empty: {
                subject: "请设置点名选项",
            }
        }
    },
    error: {
        1001: {
            "subject": "请输入点名选项名称",
            "text": ""
        }
    }

}
