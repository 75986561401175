export const History: {
    [key: string]: any
} = {

    field: {
        name: {
            subject: "族群名称"
        },
        begin: {
            subject: "开始时间"
        },
        end: {
            subject: "结束时间"
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "未开始",
                },
                1: {
                    subject: "进行中"
                },
                2: {
                    subject: "已结束"
                },
                3: {
                    subject: "无聚会"
                }
            }
        },
        created: {
            subject: "建立时间"
        }
    },
    search: {
        name: {
            subject: "群族名称",
            holder: "请输入群族名称"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    }

}
