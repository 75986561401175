export const Create: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "帳號"
        },
        2: {
            subject: "附件"
        }
    },
    field: {
        sample: {
            subject: "樣本ID",
            holder: "請輸入樣本ID"
        },
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        role: {
            subject: "角色",
            holder: "請選擇角色"
        },
        type: {
            subject: "類型",
            holder: "請選擇類型"
        }
    },
    handler: {
        submit: {
            subject: "即將新增工單",
            text: "請確認後執行"
        }
    },
    error: {
        1010: {
            "subject": "請選擇角色",
            "text": ""
        },
        1020: {
            "subject": "請輸入樣本ID",
            "text": ""
        },
        1021: {
            "subject": "樣本ID請輸入數字",
            "text": ""
        },
        1030: {
            "subject": "請選擇類型",
            "text": ""
        },
        1040: {
            "subject": "請輸入標題",
            "text": ""
        },
        1050: {
            "subject": "請輸入描述",
            "text": ""
        }
    }

}
