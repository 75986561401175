export default {
    subject: "編輯個人標記",
    incorrect: "請先選擇需要移除的標記",
    selected: `已經選取 <b class="cgBase">%s</b> 個標記`,
    th: [
        null,
        "標記"
    ],
    handler: {
        destroy: {
            subject: "即將刪除指定個人標記",
            text: "刪除後無法復原, 請確認後執行",
            description: ""
        }
    }
}
