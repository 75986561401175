export const History: {
    [key: string]: any
} = {

    field: {
        recipient: {
            subject: "收件人"
        },
        read: {
            subject: "读取",
            value: {
                0: {
                    subject: "未读取",
                },
                1: {
                    subject: "已读取"
                }
            }
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "准备中",
                },
                1: {
                    subject: "已送达"
                },
                2: {
                    subject: "无法推播"
                },
                9: {
                    subject: "用户删除"
                }
            }
        },
        created: {
            subject: "送达时间"
        }
    },
    search: {
        read: {
            subject: "读取",
            holder: "请选择读取"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    }

}
