/**
 * 會友管理
 */
export default {
    index: "會友管理",
    create: "會友新增",
    edit: "會友內容",
    permission: "會友管理",
    donate: {
        index: "奉獻紀錄",
        edit: "奉獻內容",
        permission: "會友管理 - 奉獻紀錄"
    }
}
