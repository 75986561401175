import {Tag} from "@/language/modules/component/form/v2/edit/tag/zhTW"
import {User} from "@/language/modules/component/form/v2/edit/user/zhTW"
import {Cycle} from "@/language/modules/component/form/v2/edit/cycle/zhTW"

export const Edit: {
    [key: string]: any
} = {

    tag: Tag,

    user: User,

    cycle: Cycle,

    error: {
        code: {
            subject: "請先清除表單錯誤"
        }
    },
    alert: {
        store: {
            subject: "您沒有建立的權限"
        },
        update: {
            subject: "您沒有更新的權限",
            head: {
                subject: "總教會僅供查看資料, 無法更新內容"
            }
        },
        cycle: {
            monitor: {
                modified: {
                    subject: "聚會時間已經變動, 請您留意"
                }
            },
            schedule: {
                last: {
                    complete: {
                        subject: "設定的所有循環均已完成, 將不會再繼續生成相關單據與提醒"
                    }
                }
            }
        }
    },
    handler: {
        reset: {
            subject: "即將重設內容",
            text: "異動內容將不會保存, 請確認後執行"
        },
        entry: {
            subject: "即將返回列表",
            text: "異動內容將不會保存, 請確認後執行"
        },
        cycle: {
            monitor: {
                subject: "聚會時間已經變動",
                text: "建議您重新檢視循環與提醒設定"
            }
        }
    }
}