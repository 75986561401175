import verification from "@/language/modules/page/forgot/verification/zhCN";

/**
 * 忘记密码
 */
export default {

    verification: verification,

    subject: "忘记密码",
    feature: {
        back: "返回登入"
    },
    field: {
        account: "帐户",
        email: "电子邮件",
        captcha: "验证码"
    },
    holder: {
        account: "请输入管理员帐户",
        email: "请输入管理员电子邮件",
        captcha: "请输入图形中的文字"
    },
    error: {
        1000: {
            subject: "暂时无法使用",
            text: "请稍后再试或请联络管理人员"
        },
        1001: {
            subject: "请输入帐户",
            text: ""
        },
        1002: {
            subject: "帐户长度不符合规则",
            text: `至少需要 <b class="ft-20 text-danger">%s</b> 个字元`
        },
        1003: {
            subject: "请输入管理员电子邮件",
            text: ""
        },
        1004: {
            subject: "电子邮件格式错误",
            text: ""
        },
        1005: {
            subject: "请输入图形中的文字",
            text: ""
        },
        1006: {
            subject: "验证码应为6个字元",
            text: ""
        },
        1007: {
            subject: "验证码错误",
            text: ""
        },
        1008: {
            subject: "找不到指定的管理员",
            text: ""
        }
    }
}
