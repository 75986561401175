/**
 * 歷程管理
 */
export default {

    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "頭像",
            name: "名字",
            role: "來源",
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            name: "請輸入名字",
            empty: {
                email: "未輸入"
            }
        },
        handler: {
        }
    },

    /**
     * 編輯
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相關資訊"
            }
        },
        tip: {
            unknown: "此紀錄為未知來源"
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            seq: "流水號",
            name: "名字",
            account: "帳號",
            role: "來源",
            method: "存取方式",
            address: "遠端位置",
            uri: "存取路徑",
            created: "建立時間"
        },
        holder: {
        },
        handler: {
        }
    }

}
