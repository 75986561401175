import {RouteRecordRaw} from "vue-router";

const billboard: RouteRecordRaw[] = [{
	//公吿欄管理
	path: "billboard/admin",
	name: "AdminBillboard",
	meta: {
		permission: 17001,
		breadcrumb: [{
			key: 'admin.billboard.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/billboard/index.vue"),
}, {
	//公吿欄新增
	path: "billboard/admin/create",
	name: "AdminBillboardCreate",
	meta: {
		permission: 17002,
		breadcrumb: [{
			key: 'admin.billboard.index',
			value: "AdminBillboard"
		}, {
			key: 'admin.billboard.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/billboard/create.vue"),
}, {
	//公吿欄編輯
	path: "billboard/admin/edit/:uuid",
	name: "AdminBillboardEdit",
	meta: {
		permission: 17004,
		breadcrumb: [{
			key: 'admin.billboard.index',
			value: "AdminBillboard"
		}, {
			key: 'admin.billboard.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/billboard/edit.vue"),
}];

export default billboard;
