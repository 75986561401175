export default {
    editor: {
        subject: "栏位设置",
        canvas: {
            field: {
                create: {
                    subject: "创建栏位",
                    option: "创建选项"
                }
            }
        },
        tip: {
            not: {
                remove: "系统条件无法移除",
            },
            option: {
                empty: "请至少创建一笔选项",
                append: "附加输入框"
            }
        },
        help: {
            add: {
                custom: "创建栏位"
            }
        },
        kind: {
            1: "电子邮件",
            2: "文字区块",
            3: "文字",
            4: "是否",
            5: "复选",
            6: "单选",
            7: "日期",
            8: "姓名"
        },
        field: {
            detail: "栏位设置",
            option: "选项值",
            show: "显示",
            must: "必填",
            subject: "名称",
            description: "描述"
        },
        holder: {
            subject: "请输入显示名称",
            description: "请输入描述",
            option: "请输入选项值",
        },
        handler: {
            remove: {
                subject: "即将删除栏位",
                text: "请确认后执行"
            }
        },
        submit: {
            up: "上移",
            down: "下移",
            remove: "移除"
        },
        error: {
            1001: {
                "subject": "请至少选择一个显示栏位",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入选项值",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请至少创建一笔选项",
                "text": "",
                "description": ""
            }
        }
    }
}
