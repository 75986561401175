/**
 * 文章 - 分類 - 管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增分類"
            },
        },
        field: {
            head: {
                church: "指定教會",
            },
            avatar: "封面圖",
            name: "名稱",
            quantity: "文章數量",
            sort: "排序",
            created: "建立時間"
        },
        holder: {
            head: {
                church: "請選擇教會",
            },
            name: "請輸入名稱",
        },
        handler: {
            enable: {
                subject: "即將啟用分類",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用分類",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除分類, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            }
        },
        help: {
            church: "請謹慎使用指定教會功能",
        },
        field: {
            church: {
                subject: "指定教會",
                text: "僅能指定自行維護心靈捕手的教會"
            },
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            name: "名稱",
        },
        holder: {
            church: "請選擇指定教會",
            name: "請輸入名稱"
        },
        handler: {
            store: {
                subject: "即將新增分類",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請指定教會",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            disabled: "請注意, 這個分類已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "排序"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            belong: {
                no: "所屬教會ID",
                name: "所屬教會名稱"
            },
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            name: "名稱",
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            name: "請輸入名稱"
        },
        handler: {
            update: {
                subject: "即將更新分類內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            }
        }
    }

}
