/**
 * 推播管理
 */
export default {
    index: "推播管理",
    create: "新增推播",
    edit: {
        subject: "推播內容",
        automatic: {
            subject: "自動化紀錄",
            edit: {
                subject: "紀錄內容",
            }
        },
        history: {
            subject: "推播紀錄"
        }
    },
    permission: "推播管理"
}
