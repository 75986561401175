export const Handler: {
    [key: string]: any
} = {
    session: {
        destroy: {
            subject: "即将登出系统",
            text: "请确认登出",
            description: ""
        }
    }
}
