import {defineStore} from "pinia";
import {AuthState} from "@/store/interface/auth";
import piniaPersistConfig from "@/config/piniaPersist";

const id: string = "AuthState";

/**
 * 授權相關store
 */
export const AuthStore = defineStore({
	id: id,
	state: (): AuthState => ({
        token: null,
		permission: []
	}),
	getters: {
		//是否正常登入
		isLogin: state => {
			return ![null, ""].includes(state.token);
		},
		//獲取權杖
		getToken: state => {
			return state.token
		},
        //獲取權限
		getPermission: state => {
			return state.permission;
		}
	},
	actions: {
		//注入權杖
		setToken(token: string | null) {
			this.token = token;
		},
		//設定權限表
		setPermission(permission: number[]) {
			this.permission = permission;
		}
	},
	persist: piniaPersistConfig(id)
});
