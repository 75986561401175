export default {
    tip: {
        readonly: "总教会仅供查看资料, 无法创建修改"
    },
    mode: {
        on: "自行维护",
        off: "委托管理"
    },
    feature: {
        seq: {
            view: "前往查看",
            edit: "前往编辑",
        },
        friend: {
            convert: "转入牧养会友",
            add: "标记为待转入",
            clear: "标记为不转入"
        },
        unauthorized: "未授权编辑",
        raise: "转交爱无界团队",
        copy: "复制",
        view: "查看",
        donate: "奉献纪录",
        edit: "编辑",
        more: "更多",
        enable: "启用",
        disable: "停用",
        paid: "标示已付款",
        integrate: {
            not: {
                allow: "整合文章无法复制"
            }
        },
        comment: "留言内容",
        notification: {
            entry: "推播纪录",
            create: "创建推播",
        },
        destroy: {
            event: "该群组下仍有 %s 个会友, 无法删除",
            soul: "该分类下仍有 %s 篇文章, 无法删除",
            activity: "该分类下仍有 %s 场活动, 无法删除",
            live: "该分类下仍有 %s 场直播, 无法删除",
            shepherd: {
                attend: "该分类下仍有 %s 个聚会, 无法删除"
            },
            enable: "删除",
            disable: "删除前请先停用"
        },
        cycle: {
            history: "循环纪录"
        },
        attend: {
            history: "聚会纪录",
            schedule: "聚会排程"
        },
        simulation: "模拟登入",
        repair: "重建模拟"
    },
    field: {
        belong: "所属教会",
        check: "选取",
        created: "建立时间",
        creator: "建立人员",
        updated: "最后编辑时间",
        editor: "最后编辑人员",
        accessed: "最后存取时间",
        prompt: "提醒",
        period: "循环",
        status: "状态",
        view: "查看",
        edit: "操作",
    },
    holder: {
        status: "请选择状态",
    }
}
