/**
 * 聚會 - 分類
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增類別"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            name: "名稱",
            quantity: "聚會數量"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名稱",
        },
        handler: {
            enable: {
                subject: "即將啟用分類",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用分類",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除分類, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        help: {
            church: "請謹慎使用指定教會功能",
        },
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            church: "指定教會",
            name: "名稱",
            status: "狀態"
        },
        holder: {
            church: "請選擇指定教會",
            name: "請輸入名稱",
            status: "請選擇狀態"
        },
        handler: {
            store: {
                subject: "即將新增類別",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這個分類已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            belong: {
                no: "所屬教會編號",
                name: "所屬教會名稱"
            },
            name: "名稱",
            status: "狀態"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            name: "請輸入名稱",
            status: "請選擇狀態"
        },
        handler: {
            update: {
                subject: "即將更新類別內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            }
        }
    }

}
