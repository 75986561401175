/**
 * 教学管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建教学"
            },
        },
        field: {
            avatar: "封面图",
            name: "名称",
            sort: "排序",
            created: "建立时间"
        },
        holder: {
            name: "请输入名字"
        },
        handler: {
            enable: {
                subject: "即将启用教学",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用教学",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除教学, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            name: "名称",
        },
        holder: {
            name: "请输入名称"
        },
        handler: {
            store: {
                subject: "即将创建分类",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            disabled: "请注意, 这个教学已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "排序"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            avatar: {
                subject: "封面图",
                text: "(尺寸为 %sx%s 像素)",
            },
            name: "名称",
        },
        holder: {
            name: "请输入名称"
        },
        handler: {
            update: {
                subject: "即将更新分类内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择封面图",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            }
        }
    }

}
