import app from "@/language/modules/page/app/zhTW"
import mapping from "@/language/modules/page/mapping/zhTW"
import admin from "@/language/modules/page/admin/zhTW"
import login from "@/language/modules/page/login/zhTW"
import forgot from "@/language/modules/page/forgot/zhTW"
import billboard from "@/language/modules/page/billboard/zhTW"
import shepherd from "@/language/modules/page/shepherd/zhTW"

export default {

    /**
     * App下載畫面
     */
    app: app,

    /**
     * 用戶綁定管理員
     */
    mapping: mapping,

    /**
     * 登入畫面
     */
    login: login,

    /**
     * 登入畫面
     */
    forgot: forgot,

    /**
     * 公告欄
     */
    billboard: billboard,

    /**
     * 我的教會
     */
    admin: admin,

    /**
     * 牧養系統
     */
    shepherd: shepherd

}
