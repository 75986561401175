export const Edit: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "附件"
        },
        99: {
            subject: "相關資訊",
        }
    },
    field: {
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        release: {
            subject: "發佈App",
            holder: "請選擇發佈App"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    handler: {
        submit: {
            subject: "即將更新紀錄",
            text: "請確認後執行"
        }
    },
    error: {
        1010: {
            "subject": "請選擇狀態",
            "text": ""
        },
        1020: {
            "subject": "請輸入標題",
            "text": ""
        },
        1030: {
            "subject": "請輸入描述",
            "text": ""
        },
        1040: {
            "subject": "請選擇發佈App",
            "text": ""
        }
    }

}
