import category from "@/language/modules/page/admin/live/category/zhTW";

/**
 * 直播管理
 */
export default {

    /**
     * 分類
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增直播"
            },
        },
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            subject: "標題",
            avatar: "封面圖",
            quantity: "直播數量",
            category: "分類",
            integrate: "文章整合",
            sort: "排序",
            created: "建立時間"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            integrate: "請選擇文章整合",
            subject: "請輸入名稱",
            category: "請選擇分類",
        },
        handler: {
            enable: {
                subject: "即將啟用直播",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用直播",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除直播, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        help: {
            church: "請謹慎使用指定教會功能",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "文章"
            },
            2: {
                subject: "圖片"
            },
            3: {
                subject: "分享"
            }
        },
        field: {
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            church: "指定教會",
            category: "分類",
            subject: "標題",
            hyperlink: "YouTube網址",
            activity: {
                on: "開始時間",
                off: "結束時間"
            },
            tab: {
                lobby: "標籤 - 大廳",
                prayer: "標籤 - 代禱",
                about: "標籤 - 關於"
            },
            welcome: {
                lobby: "歡迎詞 - 大廳",
                prayer: "歡迎詞 - 代禱",
            },
            reply: {
                prayer: "回應詞 - 代禱",
            },
            collection: "文章圖片",
            description: "描述",
            status: "狀態"
        },
        holder: {
            kind: "請選擇類型",
            church: "請選擇指定教會",
            category: "請選擇分類",
            subject: "請輸入名稱",
            hyperlink: "請輸入YouTube網址",
            activity: {
                on: "請輸入開始時間",
                off: "請輸入結束時間"
            },
            tab: {
                lobby: "請輸入標籤(大廳)",
                prayer: "請輸入標籤(代禱)",
                about: "請輸入標籤(關於)"
            },
            welcome: {
                lobby: "請輸入歡迎詞(大廳)",
                prayer: "請輸入歡迎詞(代禱)"
            },
            reply: {
                prayer: "請輸入回應詞(代禱)",
            },
            collection: "",
            description: "請輸入描述",
            status: "請選擇狀態"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            store: {
                subject: "即將新增直播",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇類型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入標籤(大廳)",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入標籤(代禱)",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請輸入標籤(關於)",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入歡迎詞(大廳)",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請輸入歡迎詞(代禱)",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請輸入回應詞(代禱)",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "請輸入描述",
                "text": "",
                "description": ""
            },
            1016: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            },
            1017: {
                "subject": "請輸入YouTube連結",
                "text": "",
                "description": ""
            },
            4001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        comment: {
            subject: "聊天室發言內容",
            th: {
                0: "頭像",
                1: "姓名",
                2: "內容",
                3: "時間",
            },
            passenger: {
                subject: "訪客"
            }
        },
        secure: {
            subject: "私密代禱內容",
            th: {
                0: "頭像",
                1: "姓名",
                2: "內容",
                3: "時間",
            },
            passenger: {
                subject: "訪客"
            },
            handler: {
                secure: {
                    subject: "您即將查看會友私密個人資訊",
                    text: "我將妥善與謹慎使用相關資訊",
                }
            }
        },
        tip: {
            disabled: "請注意, 這個直播已經停用"
        },
        feature: {
            0: "複製直播網址",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "文章"
            },
            2: {
                subject: "圖片"
            },
            3: {
                subject: "分享"
            },
            4: {
                subject: "統計資訊"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            comment: {
                subject: "聊天室發言"
            },
            secure: {
                subject: "私密代禱"
            }
        },
        field: {
            belong: {
                no: "所屬教會編號",
                name: "所屬教會名稱"
            },
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            category: "分類",
            subject: "標題",
            hyperlink: "YouTube網址",
            activity: {
                on: "開始時間",
                off: "結束時間"
            },
            tab: {
                lobby: "標籤 - 大廳",
                prayer: "標籤 - 代禱",
                about: "標籤 - 關於"
            },
            welcome: {
                lobby: "歡迎詞 - 大廳",
                prayer: "歡迎詞 - 代禱",
            },
            reply: {
                prayer: "回應詞 - 代禱",
            },
            collection: "文章圖片",
            description: "描述",
            status: "狀態"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            kind: "請選擇類型",
            category: "請選擇分類",
            subject: "請輸入名稱",
            hyperlink: "請輸入YouTube網址",
            activity: {
                on: "請輸入開始時間",
                off: "請輸入結束時間"
            },
            tab: {
                lobby: "請輸入標籤(大廳)",
                prayer: "請輸入標籤(代禱)",
                about: "請輸入標籤(關於)"
            },
            welcome: {
                lobby: "請輸入歡迎詞(大廳)",
                prayer: "請輸入歡迎詞(代禱)"
            },
            reply: {
                prayer: "請輸入回應詞(代禱)",
            },
            collection: "",
            description: "請輸入描述",
            status: "請選擇狀態"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            update: {
                subject: "即將更新直播內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇類型",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入標籤(大廳)",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入標籤(代禱)",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請輸入標籤(關於)",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請輸入歡迎詞(大廳)",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入歡迎詞(代禱)",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入回應詞(代禱)",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入描述",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請輸入YouTube連結",
                "text": "",
                "description": ""
            },
            4001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
