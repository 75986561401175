export default {
    to: {
        qrcode: {
            subject: "请使用App扫描确认或切换至",
            text: "输入登入密码"
        },
        passwd: {
            subject: "请输入登入密码或切换至",
            text: "App扫描确认"
        }
    },
    status: {
        success: {
            subject: "扫码成功",
            text: "请在App中进行确认"
        },
        expire: {
            subject: "二维码超时",
            text: "重新产生二维码"
        }
    },
    field: {
    }
}
