import statistics from "@/language/modules/page/shepherd/dashboard/statistics/zhCN";

/**
 * 控制面板
 */
export default {
    help: {
        updated: "最后刷新时间",
        original: "预设区间",
        loading: "正在计算统计数据..."
    },
    field: {
        church: "指定教会",
        begin: "开始时间",
        end: "结束时间",
        statistics: statistics
    },
    holder: {
        church: "请选择指定教会"
    }
}
