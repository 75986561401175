/**
 * 族群管理
 */
export default {
    index: "族群管理",
    permission: "族群管理",
    attend: {
        index: "聚會查詢",
    },
    statistics: {
        index: "牧養跟進",
        attend: {
            index: "聚會紀錄"
        },
        event: {
            index: "服事參與"
        },
        gender: {
            index: "男女比例"
        },
        year: {
            index: "年齡分佈"
        },
        deacon: {
            index: "五重執事"
        },
        education: {
            index: "教育程度"
        },
        occupation: {
            index: "職業分佈"
        },
        marital: {
            index: "婚姻狀況"
        },
        baptized: {
            index: "受洗人數"
        }
    }
}
