import {RouteRecordRaw} from "vue-router";

const user: RouteRecordRaw[] = [{
	//管理員帳號管理
	path: "churches/users",
	name: "AdminUser",
	meta: {
		permission: 2001,
		breadcrumb: [{
			key: 'admin.user.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/user/index.vue"),
}, {
	//管理員新增
	path: "churches/users/create",
	name: "AdminUserCreate",
	meta: {
		permission: 2002,
		breadcrumb: [{
			key: 'admin.user.index',
			value: "AdminUser"
		}, {
			key: 'admin.user.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/user/create.vue"),
}, {
	//管理員編輯
	path: "churches/users/edit/:uuid",
	name: "AdminUserEdit",
	meta: {
		permission: 2004,
		breadcrumb: [{
			key: 'admin.user.index',
			value: "AdminUser"
		}, {
			key: 'admin.user.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/user/edit.vue"),
}];

export default user;
