/**
 * 会友管理
 */
export default {
    index: "会友管理",
    create: "会友创建",
    edit: "会友内容",
    permission: "会友管理",
    donate: {
        index: "奉献纪录",
        edit: "奉献内容",
        permission: "会友管理 - 奉献纪录"
    }
}
