import {AxiosResponse} from "axios";
import {EnumSortType} from "@/enum/sort";

/**
 * 設定值
 */
export namespace Configure {

    /**
     * 頁面
     */
    export namespace Page {

        /**
         * 頁面預設值定義
         */
        export interface Base {
            /**
             * i18n代碼前置
             */
            prefix: string;
            /**
             * 預設排序
             */
            order?: Order;
            /**
             * 還原search預設參數非null的
             */
            restore?: Restore;
            /**
             * 權限
             */
            permission? : {
                //新增權限代號
                create?: number;
                //編輯權限代號
                edit?: number;
                //刪除權限代號
                destroy?: number;
                //會出權限代號
                download?: number;
            }
            /**
             * 提交方式
             */
            submit?: string;
            /**
             * 跳轉頁面
             */
            page?: {
                //返回列表
                entry?: string;
                //新增
                create?: string;
                //編輯
                edit?: string
            }
        }

        /**
         * 還原預設參數非null的
         */
        export interface Restore {
            /**
             * 鍵 / 值
             */
            [key: string]: any;
        }

        /**
         * 預設排序
         */
        export interface Order {
            /**
             * 排序鍵
             */
            key: string;
            /**
             * 排序值
             */
            value: EnumSortType.ASC |
                EnumSortType.DESC |
                EnumSortType.NONE;
        }

    }

    /**
     * vue-easy-lightbox
     */
    export interface LightBox {
        /**
         * 標記可下載
         */
        download: boolean;
        /**
         * 是否打開顯示
         */
        show: boolean;
        /**
         * 圖片集
         */
        images: string[];
        /**
         * 目前開啟第n張
         */
        index: number
    }

    /**
     * axios額外自定義設定參數
     */
    export interface Axios {
        /**
         * 指定完成回調
         */
        completion?: {
            /**
             * 執行指定的ajax前回調
             */
            begin?: (config: Axios) => void;
            /**
             * 執行指定的ajax完成後回調
             */
            success?: (e: AxiosResponse,
                       config: Axios) => void;
            /**
             * 執行指定的ajax錯誤後回調
             */
            failure?: (e: number,
                       config: Axios) => void;
            /**
             * 直接使用訊息顯示 -> sweetalert 消失後執行
             */
            done?: (e: AxiosResponse) => void;
            /**
             * 不論錯誤或都會執行
             * @param e
             */
            final?: () => void;
        };
        /**
         * 是否執行內置功能
         */
        builtIn?: {
            /**
             * 讀取遮罩
             */
            loadingMask?: boolean;
            /**
             * 消失後回調
             */
            done?: boolean;
            /**
             * 錯誤回調
             */
            failure?: boolean;
            /**
             * 額外填寫按鈕 (填key name就可以了)
             */
            button?: {
                /**
                 * 好
                 */
                ok?: string;
                /**
                 * 確定
                 */
                confirm?: string;
                /**
                 * 取消
                 */
                cancel?: string;
            }
        }
    }

    /**
     * Websocket
     */
    export interface Websocket {
        config: {
            interval: number;
            reconnect: boolean;
            retry: boolean;
            debug: boolean;
            status: {
                refresh: boolean
            }
        };
        socket: any;
        completion: (e: any) => void;
        timer: any;
    }
}

/**
 * 共用取option設定值
 */
export const AxiosDefaultOption: Configure.Axios = {
    /**
     * 內建功能
     */
    builtIn: {
        /**
         * 讀取遮罩 - 不顯示
         */
        loadingMask: false,
        /**
         * 錯誤回調 - 不執行
         */
        failure: false
    }
}
