import Billboard from "@/router/modules/billboard";

import AdminDashboard from "@/router/modules/admin/dashboard";
import AdminUser from "@/router/modules/admin/user";
import AdminChurch from "@/router/modules/admin/church";
import AdminClient from "@/router/modules/admin/client";
import AdminMy from "@/router/modules/admin/my";
import AdminSoul from "@/router/modules/admin/soul";
import AdminDaily from "@/router/modules/admin/daily";
import AdminGallery from "@/router/modules/admin/gallery";
import AdminNotification from "@/router/modules/admin/notification";
import AdminEvent from "@/router/modules/admin/event";
import AdminDonate from "@/router/modules/admin/donate";
import AdminTeach from "@/router/modules/admin/teach";
import AdminCycle from "@/router/modules/admin/cycle";
import AdminQuestion from "@/router/modules/admin/question";
import AdminSupport from "@/router/modules/admin/support";
import AdminCourse from "@/router/modules/admin/course";
import AdminBillboard from "@/router/modules/admin/billboard";
import AdminActivity from "@/router/modules/admin/activity";
import AdminLive from "@/router/modules/admin/live";
import AdminPersonal from "@/router/modules/admin/personal";

import ShepherdDashboard from "@/router/modules/shepherd/dashboard";
import ShepherdTeam from "@/router/modules/shepherd/team";
import ShepherdClient from "@/router/modules/shepherd/client";
import ShepherdFriend from "@/router/modules/shepherd/friend";
import ShepherdAttend from "@/router/modules/shepherd/attend";

import {RouteRecordRaw} from "vue-router";

export const AdminRouters: RouteRecordRaw[] = [
    //1
    ...AdminDashboard,
    //00
    ...Billboard,
    //17
    ...AdminBillboard,
    //2
    ...AdminUser,
    //3
    ...AdminChurch,
    //4
    ...AdminClient,
    //5
    ...AdminMy,
    //6
    ...AdminSoul,
    //7
    ...AdminDaily,
    //8
    ...AdminGallery,
    //9
    ...AdminNotification,
    //10
    ...AdminEvent,
    //11
    ...AdminDonate,
    //12
    ...AdminTeach,
    //13
    ...AdminCycle,
    //14
    ...AdminQuestion,
    //15
    ...AdminSupport,
    //16
    ...AdminCourse,
    //18
    ...AdminActivity,
    //19
    ...AdminLive,
    //00
    ...AdminPersonal
]

export const ShepherdRouters: RouteRecordRaw[] = [
    //51
    ...ShepherdDashboard,
    //52
    ...ShepherdFriend,
    //53
    ...ShepherdClient,
    //54
    ...ShepherdTeam,
    //55
    ...ShepherdAttend
]
