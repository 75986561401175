export default {
    subject: "編輯標籤",
    help: "送出新增標籤",
    exit: "離開編輯",
    empty: "無可用標籤",
    menu: {
        on: "開啟選單",
        off: "關閉選單"
    },
    summary: {
        total: `篩選出 <span class="cgBase">%s</span> 個會友`,
        checked: `, 已選取 <span class="cgBase">%s</span> 個會友`,
    },
    status: [
        "已停用",
        "啟用中"
    ],
    off: {
        all: "請選擇先篩選會友",
        subject: "請先選擇標籤",
        join: "加入(請選擇標籤與指定會友)",
        remove: "移除(請選擇標籤與指定會友)"
    },
    field: {
        tag: "",
        keyword: "關鍵字"
    },
    th: {
        avatar: "頭像",
        name: "名字",
        email: "電子郵件",
        status: "狀態"
    },
    holder: {
        tag: "請輸入標籤名稱(可用逗號分隔)",
        keyword: "搜尋範圍: 名字/郵件/會友編號/標籤"
    },
    handler: {
        join: {
            subject: "即將批次加入標籤",
            text: "請確認後執行"
        },
        remove: {
            subject: "即將批次移除標籤",
            text: "請確認後執行"
        },
        dbclick: {
            subject: "即將移除指定標籤",
            text: "請確認後執行"
        },
        confirm: {
            subject: "即將移除標籤",
            text: "請確認後執行"
        }
    }
}
