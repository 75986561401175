/**
 * 注意 router / views / navigation 必須同時加入才行
 */

import App from '@/page/store/index.vue'
import Login from '@/page/login/index.vue'
import Forgot from '@/page/forgot/index.vue'
import ForgotVerification from '@/page/forgot/verification/index.vue'
import ForgotVerificationReset from '@/page/forgot/verification/reset/index.vue'
import Mapping from '@/page/mapping/index.vue'
import Content from '@/page/content/index.vue'
import NotFound from '@/page/404/index.vue'
import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {AdminRouters, ShepherdRouters} from "@/router/model";
import {EnumPortalName} from "@/enum/portal";
import {RouteNameEnum} from "@/v2/enumerate/route";

const routes: RouteRecordRaw[] = [{
	//登入頁面
	path: "/admin/:id",
	name: RouteNameEnum.LOGIN,
	component: Login,
}, {
	//登入頁面 (2.0後面有帶個index, 怕用戶有記在書籤裡面)
	path: "/admin/:id/index",
	name: RouteNameEnum.LOGIN_INDEX,
	component: Login,
}, {
	//忘記密碼
	path: "/admin/:id/forgot",
	name: RouteNameEnum.FORGOT,
	component: Forgot,
}, {
	//忘記密碼 - 驗證碼
	path: "/admin/:id/forgot/verification/:uuid",
	name: RouteNameEnum.FORGOT_VERIFICATION,
	component: ForgotVerification,
}, {
	//忘記密碼 - 驗證碼 - 重設密碼
	path: "/admin/:id/forgot/verification/:uuid/reset/:uid",
	name: RouteNameEnum.FORGOT_VERIFICATION_RESET,
	component: ForgotVerificationReset,
}, {
	//強迫綁定頁面
	path: "/admin/:id/mapping",
	name: RouteNameEnum.MAPPING,
	component: Mapping,
}, {
	//App下載頁面
	path: "/app/:id",
	name:  RouteNameEnum.APP,
	component: App,
}, {
	//登入後頁面
	path: "/admin/:id",
	name: RouteNameEnum.ADMIN,
	component: Content,
	meta: {
		portal: EnumPortalName.ADMIN
	},
	children: [
		...AdminRouters
	]
}, {
	//登入後頁面
	path: "/shepherd/:id",
	name: RouteNameEnum.SHEPHERD,
	component: Content,
	meta: {
		portal: EnumPortalName.SHEPHERD
	},
	children: [
		...ShepherdRouters
	]
}, {
	// 404頁面
	path: "/:pathMatch(.*)",
	name: RouteNameEnum.NOT_FOUND,
	component: NotFound,
}];

/**
 * 創建路由
 * 如果網址後面有個# 請改用 createWebHistory
 */
const router = createRouter({
	history: createWebHistory(),
	routes,
	strict: false,
	// 切換頁面，滾動到最頂部
	scrollBehavior: () => ({ left: 0, top: 0 })
});

export default router;
