export default {
    advance: {
        delegate: {
            done: {
                subject: "批次委派成功",
                text: `本次委派批次完成<br />共計 <b class="ft-16 text-success">%s</b> 位會友`
            },
            empty: "請至少選擇<br />1位會友進行委派",
            mark: "已標註",
            status: [
                "不跟進",
                "未跟進",
                "已跟進"
            ]
        },
        import: {
            done: {
                subject: "會友匯入成功",
                text: `本次會友匯入完成<br />共計 <b class="ft-16 text-success">%s</b> 位會友`
            },
            th: [
                "姓名",
                "電子郵件",
                "稱謂",
                "狀態"
            ],
            status: "新朋友",
            subject: "請將匯入文件拖至此處",
            text: "或點擊上傳",
            description: `只能上傳Excel文件(檔案格式為xlsx)文件，且不超過<b class="text-danger opacity-75">10MB</b>容量`
        },
        export: {
            subject: "即將匯出全部會友",
            text: "請確認後執行"
        },
        destroy: {
            subject: "即將刪除全部會友",
            text: "操作無法返回, 請確認後執行"
        }
    }
}
