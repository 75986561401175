import {Worker} from "@/language/modules/page/admin/support/v2/worker/zhTW"

export const v2: {
    [key: string]: any
} = {

    /**
     * 維修工單
     */
    worker: Worker,

}
