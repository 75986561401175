export default {
    submit: "上传",
    summary: `已选择 <b class="text-success ft-15">%s</b> 张图片, 最多可以上传 <b class="text-success ft-15">%s</b> 张图片`,
    run: {
        out: `已经选完 <b class="text-danger ft-15">%s</b> 张图片`
    },
    gallery: {
        subject: "图库",
        disabled: "请先选择图"
    },
    history: {
        subject: "历史图",
    },
    upload: {
        subject: "上传图",
        disabled: "图片处理中, 请稍候"
    },
    destroy: "清除全部",
    clear: "清除",
    handler: {
        cancel: {
            subject: "即将取消选择图",
            text: "已经编辑的内容将会重设, 请确认后执行"
        },
        clear: {
            subject: "即将清除已经设置的图",
            text: "已经编辑的内容将会重设, 请确认后执行"
        }
    }
}
