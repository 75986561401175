import {Create} from "@/language/modules/page/admin/notification/v2/create/zhTW"
import {Edit} from "@/language/modules/page/admin/notification/v2/edit/zhTW"

export const v2: {
    [key: string]: any
} = {

    /**
     * 新增
     */
    create: Create,

    /**
     * 編輯
     */
    edit: Edit,

    field: {
        type: {
            subject: "對象",
            value: {
                1: {
                    subject: "指定推播"
                },
                2: {
                    subject: "全體推播"
                }
            }
        },
        subject: {
            subject: "標題",
        },
        summary: {
            total: {
                subject: "推播數量"
            }
        },
        status: {
            subject: "狀態",
            progress: {
                subject: `<br/>進度 %s`,
            },
            value: {
                0: {
                    subject: "排程中",
                },
                1: {
                    subject: "發送中"
                },
                2: {
                    subject: "已完成"
                }
            }
        },
        begin: {
            subject: "排程時間"
        },
        created: {
            subject: "建立時間"
        }
    },
    search: {
        type: {
            subject: "對象",
            holder: "請選擇對象"
        },
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        on: {
            subject: "排程時間(起)",
            holder: "請選擇排程時間(起)",
            end: {
                subject: "排程時間(迄)",
                holder: "請選擇排程時間(迄)"
            }
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    handler: {
        destroy: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將刪除推播通知, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        }
    }

}
