import verification from "@/language/modules/page/forgot/verification/zhTW";

/**
 * 忘記密碼
 */
export default {

    verification: verification,

    subject: "忘記密碼",
    feature: {
        back: "返回登入"
    },
    field: {
        account: "帳號",
        email: "電子郵件",
        captcha: "驗證碼"
    },
    holder: {
        account: "請輸入管理員帳號",
        email: "請輸入管理員電子郵件",
        captcha: "請輸入圖形中的文字"
    },
    error: {
        1000: {
            subject: "暫時無法使用",
            text: "請稍後再試或請聯絡管理人員"
        },
        1001: {
            subject: "請輸入帳號",
            text: ""
        },
        1002: {
            subject: "帳號長度不符合規則",
            text: `至少需要 <b class="ft-20 text-danger">%s</b> 個字元`
        },
        1003: {
            subject: "請輸入管理員電子郵件",
            text: ""
        },
        1004: {
            subject: "電子郵件格式錯誤",
            text: ""
        },
        1005: {
            subject: "請輸入圖形中的文字",
            text: ""
        },
        1006: {
            subject: "驗證碼應為6個字元",
            text: ""
        },
        1007: {
            subject: "驗證碼錯誤",
            text: ""
        },
        1008: {
            subject: "找不到指定的管理員",
            text: ""
        }
    }
}
