import i18n from '@/language'
import Swal from 'sweetalert2'

import {
    getFactory
} from "@/api/module/factory"

import {
    useIntent
} from "@/hook/useIntent";

import {
    usePermission
} from "@/hook/usePermission";

import $ from "jquery"

import {
    useUser
} from "@/hook/useUser";

import {
    AppContext, reactive
} from "vue";

import {
    renderComponent
} from "@/hook/useComponent";

import QrcodeLogin from "@/component/qrocde/Login.vue";
import MindCatcherShare from "@/component/mindcatcher/Share.vue";
import {getMindcatcher} from "@/api/module/mindcatcher";
import {EnumMaintenance} from "@/enum/maintenance";
import {Router} from "vue-router";

/**
 * SweetAlert2 封裝
 */
export const useSwalAlert = () => {

    //導入i18n
    const {
        // @ts-ignore
        t
    } = i18n.global

    /**
     * 顯示成功
     */
    const showSuccess = (configure: SwalConfigure) => build(configure, "success")

    /**
     * 顯示下載成功
     */
    const showDownloadSuccess = () => build({
        subject: t(`component.message.download.subject`),
        text: t(`component.message.download.text`)
    }, "success")

    /**
     * 顯示失敗
     */
    const showWarning = (configure: SwalConfigure) => build(configure, "warningOne")

    /**
     * 顯示提問
     */
    const showHandler = (configure: SwalConfigure) => build(configure)

    /**
     * 顯示 status !== 200 的錯誤
     */
    const showErrorStatus = async (status: number) => {

        const {
            reloadIntent,
            homePage404Intent,
            replaceIntent
        } = useIntent()

        const {
            setToken
        } = usePermission()

        /**
         * 參數 - Swal - 控制
         */
        const configure: SwalConfigure = {
            subject: t(`error.${status}.subject`),
            text: t(`error.${status}.text`)
        }

        //無登入權杖
        if (status === 401) {

            //清除登入token
            await setToken(null)

            configure.completion = {
                failure: () => {
                    replaceIntent(`Login`)
                }
            }

            build(configure, "warningOne")

        }
        //找不到頁面, 回到首頁
        else if (status === 404) {

            configure.completion = {
                failure: () => {
                    homePage404Intent()
                }
            }

            build(configure, "warningOne")

        }
        //心靈捕手錯誤
        else if (status === 904) build(configure, "warningOne")
        //二級認證失敗
        else if (status === 998) build(configure, "warningOne")
        //找不到頁面, 回到首頁
        else {

            configure.confirmButtonText = `submit.relocation`
            configure.completion = {
                success: () => {
                    reloadIntent()
                }
            }

            build(configure)

        }

    }

    /**
     * 顯示二次驗證
     */
    const showTwoFactory = (
        configure: SwalConfigure,
        appContext: AppContext
    ) => {

        let destroyComp: (() => void) | null

        /**
         * 參數 - 本地
         */
        const active = reactive<{
            //回傳的密碼
            passwd: string;
            is: {
                //是否為qrcode 模式
                qrcode: boolean;
            }
        }>({
            passwd: "",
            is: {
                qrcode: true
            }
        })

        const {
            getUser
        } = useUser()

        Swal.fire({
            //標題
            title: configure.subject,
            //副標題
            html: configure.text,
            //顯示右上角關閉按鈕
            showCloseButton: false,
            //點外側關閉
            allowOutsideClick: false,
            //顯示取消按鈕
            showCancelButton: true,
            //強迫確認
            focusConfirm: false,
            //確認按鈕文字
            confirmButtonText: t(`submit.accept`),
            //取消按鈕的預設文字
            cancelButtonText: t('submit.cancel'),
            //輸入框的類型
            input: 'password',
            //自定義外觀
            customClass: {
                container: "two-factory",
                input: 'form-control form-control-lg',
            },
            //即將開啟
            willOpen() {

                //創建
                destroyComp = renderComponent(
                    $(`#swal2-html-container`)[0],
                    QrcodeLogin,
                    //接收$emit, 前面帶個on, close => onClose
                    {
                        //傳入前置參數
                        data: {
                            organization: {
                                uuid: getUser.value.church.organization,
                                name: null
                            },
                            available: true,
                            uuid: getUser.value.church.uuid,
                            name: getUser.value.church.name,
                            avatarUrl: null
                        },
                        //指定嵌入sweet alert
                        isSwal: true,
                        //指定取消時順便關閉swal
                        isCancelClose: true,
                        //頂部加塞
                        marginTop: 2,
                        //底部加塞
                        marginBottom: 0,
                        //密碼輸入時的占位符
                        placeholder: configure.description,
                        //監聽 - 切換輸入模式
                        onChangeMode: (e: boolean) => {

                            active.is.qrcode = e

                            active.passwd = ""

                            const d = $(".swal2-container.two-factory .swal2-confirm")
                            d.prop("disabled", true).addClass(`cursor-not-allowed`)

                            //如果切換到qrcode模式, 隱藏確定
                            if (active.is.qrcode) d.hide()
                            //如果切換到passwd模式, 顯示確定
                            else d.show()

                        },
                        //監聽 - 輸入密碼
                        onKeyup: (e: string) => {

                            //紀錄輸入的密碼
                            active.passwd = e

                            const disabled: boolean = active.passwd.length === 0

                            const d = $(".swal2-container.two-factory .swal2-confirm")
                            d.prop("disabled", disabled)

                            if (disabled) d.addClass(`cursor-not-allowed`)
                            else d.removeClass(`cursor-not-allowed`)

                        },
                        //監聽 - 授權成功
                        onAuthorize: () => setTimeout(()=>{
                            if (configure.completion?.success) configure.completion.success()
                        }, 0),
                        //監聽 - 密碼模式下, 按entry送出
                        onSubmit: (e: string) => {
                            //有輸入字元才送出
                            if (e.length > 0) $(".swal2-container.two-factory .swal2-confirm").trigger('click')
                        },
                        //監聽 - 手機按取消
                        onCancel: () => setTimeout(()=>Swal.close(), 0)
                    },
                    appContext
                )

            },
            //關閉完成
            didClose() {
                //銷毀
                destroyComp?.()
            },
            //開啟完成
            didOpen(e: HTMLElement) {

                //隱藏原始的輸入框, 不能移除, 否則 preConfirm 會報錯
                $(".swal2-input",$(e)).hide()

                const d = $(".swal2-container.two-factory .swal2-confirm")
                //先停用確認送出
                d.prop("disabled", true).addClass(`cursor-not-allowed`)
                //隱藏確認送出
                d.hide()

            },
            //提交前檢查
            preConfirm: () => {

                //正確返回輸入值, then中的result.value就會繼承值
                //返回false, then就不會執行
                if (active.is.qrcode) return true
                return active.passwd.length > 0

            }
        }).then(async (result) => {

            //密碼驗證才會跑到這邊來

            //按取消不往下執行
            if (!result.isConfirmed) return false

            //進行二級認證 (密碼方式才走這邊)
            const {
                data
            } = await getFactory({passwd: active.passwd})

            //認證失敗 - 不正確返回
            if (data?.result === undefined) {
                await showErrorStatus(998)
                return false
            }
            else if (!data?.result) {
                await showErrorStatus(998)
                return false
            }

            //執行回調
            setTimeout(()=>{
                if (configure.completion?.success) configure.completion.success()
            }, 0)

        })

    }

    /**
     * 顯示心靈捕手
     */
    const showMindCatcher = (
        configure: SwalConfigure,
        appContext: AppContext
    ) => {

        let destroyComp: (() => void) | null

        /**
         * 參數 - 本地
         */
        const active = reactive<{
            length: number
            key: string
        }>({
            length: 16,
            key: ""
        })

        Swal.fire({
            //圖
            imageUrl: new URL(
                `/src/asset/img/core/mc-logo.png`,
                import.meta.url
            ).href,
            //圖 - 寬
            imageWidth: 80,
            //圖 - 高
            imageHeight: 80,
            //標題
            title: configure.subject,
            //副標題
            html: configure.text,
            //顯示右上角關閉按鈕
            showCloseButton: false,
            //點外側關閉
            allowOutsideClick: false,
            //顯示取消按鈕
            showCancelButton: true,
            //強迫確認
            focusConfirm: false,
            //確認按鈕文字
            confirmButtonText: t(`submit.accept`),
            //取消按鈕的預設文字
            cancelButtonText: t('submit.cancel'),
            //輸入框的類型
            input: 'password',
            //自定義外觀
            customClass: {
                container: "two-factory mindcatcher",
                input: 'form-control form-control-lg',
            },
            //即將開啟
            willOpen() {

                //創建
                destroyComp = renderComponent(
                    $(`#swal2-html-container`)[0],
                    MindCatcherShare,
                    //接收$emit, 前面帶個on, close => onClose
                    {
                        //input的最長長度
                        maxLength: active.length,
                        //頂部加塞
                        marginTop: 2,
                        //底部加塞
                        marginBottom: 0,
                        //密碼輸入時的占位符
                        placeholder: configure.description,
                        //監聽 - 輸入密碼
                        onKeyup: (e: string) => {

                            //紀錄輸入的密碼
                            active.key = e

                            const disabled: boolean = active.key.length !== active.length

                            const d = $(".swal2-container.two-factory .swal2-confirm")
                            d.prop("disabled", disabled)

                            if (disabled) d.addClass(`cursor-not-allowed`)
                            else d.removeClass(`cursor-not-allowed`)

                        },
                        //監聽 - 密碼模式下, 按entry送出
                        onSubmit: (e: string) => {
                            //有輸入字元才送出
                            if (e.length !== active.length) return false
                            $(".swal2-container.two-factory .swal2-confirm").trigger('click')
                        }
                    },
                    appContext
                )

            },
            //關閉完成
            didClose() {
                //銷毀
                destroyComp?.()
            },
            //開啟完成
            didOpen(e: HTMLElement) {

                //隱藏原始的輸入框, 不能移除, 否則 preConfirm 會報錯
                $(".swal2-input",$(e)).hide()

                const d = $(".swal2-container.two-factory .swal2-confirm")
                //先停用確認送出
                d.prop("disabled", true).addClass(`cursor-not-allowed`)

            },
            //提交前檢查
            preConfirm: () => {

                return active.key.length === active.length

            }
        }).then(async (result) => {

            //密碼驗證才會跑到這邊來

            //按取消不往下執行
            if (!result.isConfirmed) return false

            //開始前
            if (configure.completion?.before) configure.completion.before()

            //進行二級認證 (密碼方式才走這邊)
            const {
                data
            } = await getMindcatcher({key: active.key})

            //認證失敗 - 不正確返回
            if (data?.result === undefined) {
                await showErrorStatus(904)
                return false
            }
            else if (!data?.result) {
                await showErrorStatus(904)
                return false
            }

            //執行回調
            setTimeout(()=>{
                if (configure.completion?.success) configure.completion.success(active.key)
            }, 0)

        })

    }

    /**
     * 維護中
     * @param e
     * @param r
     */
    const showMaintenance = (
        e: EnumMaintenance,
        r: Router
    ) => build({
        subject: t(`maintenance.${e}.subject`),
        text: t(`maintenance.${e}.text`),
        completion: {
            //完成顯示錯誤
            done: async () => await r.push({
                name: [
                    EnumMaintenance.SHEPHERD
                ].includes(e) ? `ShepherdDashboard` : `AdminDashboard`
            })
        }
    }, "warningOne")

    /**
     * 顯示swal
     * @param configure swal的設定值
     * @param type swal的類型
     */
    const build = (configure: SwalConfigure,
                   type?: string) => {

        let resultValue: boolean = false

        const d: any = {
            //圖示
            icon: configure.icon ?? 'question',
            //標題
            title: configure.subject,
            //副標題
            html: configure.text,
            //顯示右上角關閉按鈕
            showCloseButton: false,
            //點外側關閉
            allowOutsideClick: false,
            //顯示取消按鈕
            showCancelButton: true,
            //強迫確認
            focusConfirm: false,
            //確認按鈕的預設文字
            confirmButtonText: configure.confirmButtonText ? t(configure.confirmButtonText) : t('submit.accept'),
            //取消按鈕的預設文字
            cancelButtonText: configure.cancelButtonText ? t(configure.cancelButtonText) : t('submit.cancel'),
        }

        //強迫單按鈕方式[成功]
        if (type === "success") {
            //圖示
            d.icon = "success"
            //不顯示取消按鈕
            d.showCancelButton = false
            //確認按鈕文字
            d.confirmButtonText = getOkButtonText(configure)
        }
        //強迫單按鈕方式[警示]
        else if (type === "warningOne") {
            d.icon = "warning"
            //不顯示取消按鈕
            d.showCancelButton = false
            d.confirmButtonText = getOkButtonText(configure)
        }

        //注入swal整個關閉animated後的回調
        if (configure.completion?.done) d.didClose = () => {
            if (resultValue) configure.completion!.done!(resultValue)
        }

        //開始前回調
        if (configure.completion?.before) configure.completion.before()

        Swal.fire(d).then((result) => {

            resultValue = result.isConfirmed

            //強迫單按鈕方式[警示]+錯誤回調
            if ( type === "warningOne" && configure.completion?.failure) configure.completion.failure()
            //詢問+成功回調
            else if (resultValue && configure.completion?.success) configure.completion.success()
            //詢問+錯誤回調
            else if (configure.completion?.failure) configure.completion.failure()

        })

    }

    /**
     * 回傳確認按鈕文字
     * @param configure
     */
    const getOkButtonText = (configure: SwalConfigure): string => {
        return configure.okButtonText !== undefined ? t(configure.okButtonText) : t('submit.ok')
    }

    return {
        showSuccess,
        showWarning,
        showHandler,
        showTwoFactory,
        showErrorStatus,
        showMindCatcher,
        showDownloadSuccess,
        showMaintenance
    }

}

/**
 * 自定義Swal參數
 */
export interface SwalConfigure {
    //圖示
    icon?: string,
    //標題
    subject: string,
    //副標題
    text?: string,
    //描述
    description? : any,
    //傳入圖片
    image?: {
      url: string,
      width: number,
      height: number
    },
    //好按鈕的名稱
    okButtonText?: string,
    //確認按鈕的名稱
    confirmButtonText?: string,
    //取消按鈕的名稱
    cancelButtonText?: string,
    //回調
    completion?: {
        //開始前
        before?: () => void;
        //成功
        success?: (e?: any) => void;
        //失敗
        failure?: () => void;
        //整個關閉animated完成
        done?: (result?: boolean) => void;
    }
}
