export default {
    help: [
        "溫馨提示",
        "若您從 WORD檔案/其他軟體/其他網頁轉載 進行複製貼上, 容易造成App顯示介面的跑版或不顯示等狀況",
        "建議您先將內容複製到「記事本」, 再從「記事本」中貼至內文中, 請您注意使用"
    ],
    disable: "請輸入連結文字與網址",
    subject: {
        insert: "插入連結",
        edit: "編輯連結"
    },
    release: "釋放連結",
    hyperlink: "前往連結",
    link: "插入連結",
    clear: "清空內文",
    summary: {
        "empty": `<span class="a1">未輸入文字</span>`,
        "total": `合計約 <b class="cgBase">%s</b> 個文字`
    },
    field: {
        hyperlink: {
            url: "連結網址",
            text: "連結文字"
        },
    },
    holder: {
        hyperlink: {
            url: "請輸入連結網址",
            text: "請輸入連結文字"
        },
    }
}
