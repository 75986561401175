export default {
    recipient: {
        move: {
            left: "雙擊加入",
            right: "雙擊移除"
        },
        subject: "編輯簽到名單",
        disable: {
            available: "請選擇加入的項目",
            selected: "請選擇移除的項目",
        },
        changed: "尚未異動項目",
        empty: `請指定協助<br/>使用App簽到本活動的會友`,
        not: {
            found: "無法搜尋到<br />您指定條件的會友"
        },
        help: {
            available: `可選擇 <b class="cgBase">%s</b> 個會友`,
            selected: `已選擇 <b class="cgBase">%s</b> 個會友`
        },
        th: [
            "頭像",
            "會友",
            "操作",
            "資訊"
        ],
        field: {
            keyword: "搜尋名單",
            tag: "標籤"
        },
        holder: {
            keyword: "搜尋範圍: 名字/郵件/編號",
            tag: "請選擇標籤"
        },
        handle: {
            close: {
                subject: "即將關閉編輯推播",
                text: `<span class="text-danger">異動項目將會清除, 請確認後執行</span>`
            }
        },
        tag: {
            all: "全部",
            uncategorized: "未分類",
            administrator: "管理員名單"
        }
    }
}
