export default {
    //完整名稱
    full: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
    ],
    //短名稱
    short: [
        "日",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六"
    ],
}
