export default {
    session: {
        repair: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將進行重建模擬, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        },
        simulation: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將進行模擬登入, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        },
        head: {
            subject: "即將返回管理介面",
            text: "",
            description: ""
        },
        destroy: {
            subject: "即將登出系統",
            text: "請確認登出",
            description: ""
        }
    }
}
