export const Schedule: {
    [key: string]: any
} = {

    field: {
        begin: {
            subject: "開始時間"
        },
        end: {
            subject: "結束時間"
        },
        status: {
            subject: "狀態",
            value: {
                0: {
                    subject: "等待生成",
                },
                1: {
                    subject: "已生成"
                },
                2: {
                    subject: "生成逾期"
                }
            }
        },
        created: {
            subject: "建立時間"
        },
        stamp: {
            subject: "預計生成時間"
        },
        completed: {
            subject: "實際生成時間"
        },
        notified: {
            subject: "推送時間"
        },
        mailed: {
            subject: "寄送郵件時間"
        }
    },
    search: {
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    }

}
