export const Date: {
    [key: string]: any
} = {
    remaining: {
        day: {
            subject: "天"
        },
        hour: {
            subject: "時"
        },
        minute: {
            subject: "分"
        },
        second: {
            subject: "秒"
        }
    }
}
