export default {
    400: {
        subject: "连线权杖逾时",
        text: "Oops! 🤕🤕 请您重新登入系统",
        description: "请稍后再试或请联络管理人员"
    },
    401: {
        subject: "未授权的存取",
        text: "Oops! 😳😳 您可能没有存取的权限",
        description: "请稍后再试或请联络管理人员"
    },
    404: {
        subject: "页面不存在",
        text: "Oops! 😱😱 您访问的页面不存在或暂时不可用",
        description: "请稍后再试或请联络管理人员"
    },
    //METHOD不存在
    405: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 无法正确连结至服务器",
        description: "请稍后再试或请联络管理人员"
    },
    413: {
        subject: "这出了点小问题",
        text: "Oops! 🙁🙁 您上传的文档容量超出限制",
        description: "请稍后再试或请联络管理人员"
    },
    500: {
        subject: "这出了点小问题",
        text: "Oops! 🥺🥺 服务器好像罢工了",
        description: "请稍后再试或请联络管理人员"
    },
    //CORS错误
    502: {
        subject: "这出了点小问题",
        text: "Oops! 😵😵 无法正确连结至服务器",
        description: "请稍后再试或请联络管理人员"
    },
    //连线超时
    504: {
        subject: "这出了点小问题",
        text: "Oops! 😵‍💫😵‍💫 连结到服务器超出预期时间",
        description: "请稍后再试或请联络管理人员"
    },
    901: {
        subject: "上传文档发生错误",
        text: `单个文档超过 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    902: {
        subject: "上传文档发生错误",
        text: `文档合计超过 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    903: {
        subject: "产生预览图失败",
        text: "上传文档中包含不支援的图片编码",
        description: ""
    },
    904: {
        subject: "分享连结出现错误",
        text: "请确认您的分享连结是否正确",
        description: ""
    },
    998: {
        subject: "二级验证失败",
        text: "请确认您的登入密码是否正确",
        description: "请确认您的登入密码是否正确"
    },
    999: {
        subject: "这出了点小问题",
        text: "Oops! 😲😲 发生未知错误",
        description: "请稍后再试或请联络管理人员"
    }
}
