import {RouteRecordRaw} from "vue-router";

const client: RouteRecordRaw[] = [{
	//會友維護
	path: "client",
	name: "ShepherdClient",
	meta: {
		permission: 53001,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/index.vue"),
}, {
	//會友編輯
	path: "client/edit/:uuid",
	name: "ShepherdClientEdit",
	meta: {
		permission: 53004,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: "ShepherdClient"
		}, {
			key: 'shepherd.client.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/edit.vue"),
}, {
	//會友歷程 - 課程
	path: "client/edit/:uuid/course",
	name: "ShepherdClientCourse",
	meta: {
		permission: 53001,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: "ShepherdClient"
		}, {
			key: 'shepherd.client.edit',
			value: "ShepherdClientEdit"
		}, {
			key: 'shepherd.client.history.course',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/course/index.vue"),
}, {
	//會友歷程 - 聚會
	path: "client/edit/:uuid/attend",
	name: "ShepherdClientAttend",
	meta: {
		permission: 53004,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: "ShepherdClient"
		}, {
			key: 'shepherd.client.edit',
			value: "ShepherdClientEdit"
		}, {
			key: 'shepherd.client.attend.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/attend/index.vue"),
}, {
	//會友歷程 - 聚會 - 內容
	path: "client/edit/:uuid/attend/edit/:uid",
	name: "ShepherdClientAttendEdit",
	meta: {
		permission: 53004,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: "ShepherdClient"
		}, {
			key: 'shepherd.client.edit',
			value: "ShepherdClientEdit"
		}, {
			key: 'shepherd.client.attend.index',
			value: "ShepherdClientAttend"
		}, {
			key: 'shepherd.client.attend.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/attend/edit.vue"),
}, {
	//會友歷程 - 進階功能
	path: "client/advance",
	name: "ShepherdClientAdvance",
	meta: {
		permission: 53001,
		breadcrumb: [{
			key: 'shepherd.client.index',
			value: "ShepherdClient"
		}, {
			key: 'shepherd.client.advance.subject',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/client/advance/index.vue"),
}];

export default client;
