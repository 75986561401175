export default {
    editor: {
        subject: "欄位設定",
        canvas: {
            field: {
                create: {
                    subject: "新增欄位",
                    option: "新增選項"
                }
            }
        },
        tip: {
            not: {
                remove: "系統條件無法移除",
            },
            option: {
                empty: "請至少新增一筆選項",
                append: "附加輸入框"
            }
        },
        help: {
            add: {
                custom: "新增欄位"
            }
        },
        kind: {
            1: "電子郵件",
            2: "文字區塊",
            3: "文字",
            4: "是否",
            5: "複選",
            6: "單選",
            7: "日期",
            8: "姓名"
        },
        field: {
            detail: "欄位設定",
            option: "選項值",
            show: "顯示",
            must: "必填",
            subject: "名稱",
            description: "描述"
        },
        holder: {
            subject: "請輸入顯示名稱",
            description: "請輸入描述",
            option: "請輸入選項值",
        },
        handler: {
            remove: {
                subject: "即將刪除欄位",
                text: "請確認後執行"
            }
        },
        submit: {
            up: "上移",
            down: "下移",
            remove: "移除"
        },
        error: {
            1001: {
                "subject": "請至少選擇一個顯示欄位",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入選項值",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請至少新增一筆選項",
                "text": "",
                "description": ""
            }
        }
    }
}
