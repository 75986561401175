/**
 * 管理员管理
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建管理员"
            }
        },
        field: {
            avatar: "头像",
            name: "名字",
            account: "帐户",
            email: "电子邮件",
        },
        holder: {
            name: "请输入名字",
            account: "请输入帐户",
            email: "请输入电子邮件",
            empty: {
                email: "未输入"
            }
        },
        handler: {
            enable: {
                subject: "即将启用管理员",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用管理员",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除管理员, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "权限"
            }
        },
        subject: {
            passwd: "设置密码"
        },
        field: {
            account: "帐户",
            name: "名字",
            passwd: {
                generate: "登入密码",
                validation: "确认登入密码"
            },
            email: "电子邮件",
            phone: "联络电话"
        },
        holder: {
            account: "请输入帐户",
            name: "请输入名字",
            passwd: {
                generate: "请输入登入密码",
                validation: "请再次输入登入密码进行确认"
            },
            email: "请输入电子邮件",
            phone: "请输入联络电话",
            search: "请输入关键字或权限代号, 进行权限名称搜索"
        },
        handler: {
            store: {
                subject: "即将创建管理员",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                subject: "请输入帐户",
                text: "",
                "description": ""
            },
            1002: {
                subject: "帐户长度不符合规则",
                text: `至少需要 <b class="ft-20 text-danger">%s</b> 个字元`,
                "description": ""
            },
            1003: {
                subject: "帐户已经使用",
                text: "请更换一个帐户",
                "description": ""
            },
            1004: {
                subject: "请输入名字",
                text: "",
                "description": ""
            },
            1005: {
                subject: "请输入电子邮件",
                text: "",
                "description": ""
            },
            1006: {
                subject: "电子邮件格式错误",
                text: "请输入正确的电子邮件格式",
                "description": ""
            },
            1007: {
                subject: "请输入联络电话",
                text: "",
                "description": ""
            },
            1008: {
                subject: "请选择状态",
                text: "",
                "description": ""
            },
            1011: {
                subject: "请输入登入密码",
                text: "",
                "description": ""
            },
            1012: {
                subject: "登入密码强度不足",
                text: "",
                "description": ""
            },
            1013: {
                subject: "请再输入登入密码进行确认",
                text: "",
                "description": ""
            },
            1014: {
                subject: "密码确认失败",
                text: "两次密码输入不一致",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            passwd: "如果不需要变更密码, 不用填写",
            root: "系统帐户, 不得变更权限",
            disabled: "请注意, 这名管理员已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "权限"
            },
            99: {
                subject: "相关资讯"
            }
        },
        subject: {
            passwd: "变更密码"
        },
        field: {
            avatar: "头像",
            account: "帐户",
            name: "名字",
            passwd: {
                current: "目前当前密码",
                generate: "登入密码",
                validation: "确认登入密码"
            },
            email: "电子邮件",
            phone: "联络电话"
        },
        holder: {
            account: "请输入帐户",
            name: "请输入名字",
            passwd: {
                current: "请输入目前当前密码",
                generate: "请输入登入密码",
                validation: "请再次输入登入密码进行确认"
            },
            email: "请输入电子邮件",
            phone: "请输入联络电话",
            search: "请输入关键字或权限代号, 进行权限名称搜索"
        },
        handler: {
            update: {
                subject: "即将更新管理员内容",
                text: "请确认后执行",
                description: "",
                warning: {
                    subject: "即将更新管理员内容",
                    text: `<span class="text-danger ft18">您即将移除您编辑管理员权限的功能<br />移除后你将无法编辑管理员, 请确认后执行</span>`,
                    description: "",
                }
            }
        },
        error: {
            1001: {
                subject: "请输入帐户",
                text: ""
            },
            1002: {
                subject: "帐户长度不符合规则",
                text: `至少需要 <b class="ft-20 text-danger">%s</b> 个字元`
            },
            1003: {
                subject: "帐户已经使用",
                text: "请更换一个帐户"
            },
            1004: {
                subject: "请输入名字",
                text: ""
            },
            1005: {
                subject: "请输入电子邮件",
                text: ""
            },
            1006: {
                subject: "电子邮件格式错误",
                text: "请输入正确的电子邮件格式"
            },
            1007: {
                subject: "请输入联络电话",
                text: ""
            },
            1008: {
                subject: "请选择状态",
                text: ""
            },
            1009: {
                subject: "请输入当前密码",
                text: ""
            },
            1010: {
                subject: "当前密码错误",
                text: ""
            },
            1011: {
                subject: "请输入登入密码",
                text: ""
            },
            1012: {
                subject: "登入密码强度不足",
                text: ""
            },
            1013: {
                subject: "请再输入登入密码进行确认",
                text: ""
            },
            1014: {
                subject: "密码确认失败",
                text: "两次密码输入不一致"
            },
            9999: {
                subject: "即将返回首页",
                text: "您已经自行移除管理员管理员权限"
            }
        }
    }

}
