import {RouteRecordRaw} from "vue-router";

const church: RouteRecordRaw[] = [{
	//教會管理
	path: "churches",
	name: "AdminChurch",
	meta: {
		permission: 3001,
		breadcrumb: [{
			key: 'admin.church.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/church/index.vue"),
}, {
	//教會新增
	path: "churches/create",
	name: "AdminChurchCreate",
	meta: {
		permission: 3002,
		breadcrumb: [{
			key: 'admin.church.index',
			value: "AdminChurch"
		}, {
			key: 'admin.church.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/church/create.vue"),
}, {
	//教會編輯
	path: "churches/edit/:uuid",
	name: "AdminChurchEdit",
	meta: {
		permission: 3004,
		breadcrumb: [{
			key: 'admin.church.index',
			value: "AdminChurch"
		}, {
			key: 'admin.church.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/church/edit.vue"),
}];

export default church;
