export default {
    client: "会友人数",
    coming: "会友创建",
    delete: "会友删除",
    publish: "上稿次数",
    notification: "发送推播",
    bible: "圣经开启",
    favorite: "收藏次数",
    comment: "留言次数",
    share: "分享次数",
    visit: "文章观看",
    social: "外连观看",
    login: "登入次数"
}
