export default {
    column: {
        on: "已開啟",
        off: "未開啟"
    },
    editor: {
        help: {
            total: {
                on: `最多只能選擇 <b class="ft-16">%s</b> 個選項, 還可以選擇 <b class="ft-16">%s</b> 個選項`,
                off: `已達到選項上限 <b class="ft-16">%s</b> 個`
            },
            add: {
                custom: "新增自定義",
                email: "新增電子郵件"
            },
            maximum: {
                custom: `新增自定義最多僅能設定 <b class="cgBase">%s</b> 筆`,
                email: `寄送郵件名單最多僅能設定 <b class="cgBase">%s</b> 筆`
            }
        },
        field: {
            regular: "常用選項",
            custom: "自定義選項",
            method: "通知方式"
        },
        holder: {
            email: "請輸入電子郵件"
        },
        description: {
            zero: "活動時間點",
            minute: "%s 分鐘前",
            hour: "%s 小時前",
            day: "%s 日前",
            week: "%s 週前"
        },
        error: {
            1001: {
                "subject": "請指定通知方式",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請至少選擇一名收件人",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入郵件地址",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "郵件格式錯誤",
                "text": "",
                "description": ""
            }
        }
    }
}
