import piniaPersistConfig from "@/config/piniaPersist";

import {defineStore} from "pinia";
import {UserState} from "@/store/interface/user";
import {useDate} from "@/hook/useDate";
import {Caption} from "@/api/interface/caption";
import {EnumSummaryPeriod} from "@/enum/summary";

const id: string = "UserState";

const {
	getNowDate
} = useDate()

/**
 * 用戶相關store
 */
export const UserStore = defineStore({
	id: id,
	state: (): UserState => ({
		ref: null,
		uuid: null,
		mapping: false,
		name: null,
		avatarUrl: null,
		email: null,
		caption: {
			my: null,
			soul: null,
			daily: null
		},
		church: {
			head: false,
			simulation: null,
			uuid: null,
			no: null,
			name: null,
			organization: null,
			invitation: null,
			delegate: {
				soul: false,
				daily: false
			},
			timezone: {
				offset: 8,
				name: "GMT +08:00",
				zone: "+08:00"
			},
			query: {
				day: 90,
				begin: getNowDate(-30),
				end: getNowDate(),
				period: [
					EnumSummaryPeriod.DAY,
					EnumSummaryPeriod.WEEK,
					EnumSummaryPeriod.MONTH,
					EnumSummaryPeriod.SEASON
				]
			},
		},
		software: {
			version: null,
			build: null
		},
		validator: {
			church: {
				newest: {
					offset: {
						minimum: parseInt(import.meta.env.VITE_SITE_CHURCH_NEWEST_OFFSET_MINIMUM),
						maximum: parseInt(import.meta.env.VITE_SITE_CHURCH_NEWEST_OFFSET_MAXIMUM)
					}
				}
			},
			passwd: {
				strong: {
					level: 2
				},
				minimum: {
					length: 8
				}
			},
			user: {
				account: {
					minimum: {
						length: 6
					}
				}
			},
			qrcode: {
				expire: parseInt(import.meta.env.VITE_FACTORY_QRCODE_EXPIRE)
			}
		}
	}),
	getters: {
		getRef: state => {
			return state.ref
		},
		getUuid: state => {
			return state.uuid
		},
		getName: state => {
			return state.name
		},
		getEmail: state => {
			return state.email
		},
		getAll: state => {
			return state
		}
	},
	actions: {
		setChurchInvitation(e: string | null) {
			this.church.invitation = e
		},
		setMapping(e: boolean) {
			this.mapping = e
		},
		setAvatar(e: string) {
			this.avatarUrl = e
		},
		setCaption(e: Caption.Result) {
			this.caption.my = e.my
			this.caption.soul = e.soul
			this.caption.daily = e.daily
		},
		setAll(e: UserState) {
			Object.keys(e).forEach((i: string)=>{
				if (this[i] !== undefined) this[i] = e[i]
			})
		}
	},
	persist: piniaPersistConfig(id)
});
