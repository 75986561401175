export const v2: {
    [key: string]: any
} = {

    zoom: {
        in: {
            subject: "放大"
        },
        out: {
            subject: "縮小"
        }
    },
    resize: {
        subject: "還原縮放"
    },
    download: {
        subject: "下載"
    },
    rotate: {
        left: {
            subject: "逆時針旋轉"
        },
        right: {
            subject: "順時針旋轉"
        }
    }

}
