/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 權限設定 - 套用 - 角色
 */
export enum EnumRightSetRole {

    /**
     * 總教會ONLY
     */
    HEAD = 1,

    /**
     * 一般教會ONLY
     */
    NORMAL,

    /**
     * 總教會+一般教會都有
     */
    ALL,

}
