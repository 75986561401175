import {RouteRecordRaw} from "vue-router";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {PermissionEnum} from "@/v2/enumerate/permission";

const v2: boolean = (import.meta.env.VITE_VERSION as string) === 'v2'

const notification: RouteRecordRaw[] = [{
	// 推播 - 管理
	path: "notifications",
	name: RouteNameEnum.ADMIN_NOTIFICATION,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		permission: 9001,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/admin/notification/index.vue" : "@/page/content/admin/notification/index.vue"),
}, {
	// 推播 - 新增
	// ⚠️⚠️ 注意 ⚠️⚠️
	// 請注意傳輸框架的
	// create(/create) 與 edit(/${uuid}) 的路徑
	// 提交方法(put/post) 與 名稱修改 (store${name}Create)
	path: "notifications/create",
	name: RouteNameEnum.ADMIN_NOTIFICATION_CREATE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		permission: 9002,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: RouteNameEnum.ADMIN_NOTIFICATION
		}, {
			key: 'admin.notification.create',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/admin/notification/create/index.vue" : "@/page/content/admin/notification/create.vue"),
}, {
	// 推播 - 編輯
	path: "notifications/edit/:uuid",
	name: RouteNameEnum.ADMIN_NOTIFICATION_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: v2,
		permission: 9004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: RouteNameEnum.ADMIN_NOTIFICATION
		}, {
			key: 'admin.notification.edit.subject',
			value: null
		}]
	},
	component: () => import(v2 ? "@/v2/page/content/core/admin/notification/edit/index.vue" : "@/page/content/admin/notification/edit.vue"),
}, {
	// 推播 - 編輯 - 歷史紀錄
	path: "notifications/edit/:uuid/history",
	name: RouteNameEnum.ADMIN_NOTIFICATION_EDIT_HISTORY,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		permission: 9004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: RouteNameEnum.ADMIN_NOTIFICATION
		}, {
			key: 'admin.notification.edit.subject',
			value: RouteNameEnum.ADMIN_NOTIFICATION_EDIT
		}, {
			key: 'admin.notification.edit.history.subject',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/notification/edit/history/index.vue"),
}, {
	// 推播 - 編輯 - 自動化紀錄
	path: "notifications/edit/:uuid/automatic",
	name: RouteNameEnum.ADMIN_NOTIFICATION_EDIT_AUTOMATIC,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		permission: 9004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: RouteNameEnum.ADMIN_NOTIFICATION
		}, {
			key: 'admin.notification.edit.subject',
			value: RouteNameEnum.ADMIN_NOTIFICATION_EDIT
		}, {
			key: 'admin.notification.edit.automatic.subject',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/notification/edit/automatic/index.vue"),
}, {
	// 推播 - 編輯 - 自動化紀錄
	path: "notifications/edit/:uuid/automatic/:seq",
	name: RouteNameEnum.ADMIN_NOTIFICATION_EDIT_AUTOMATIC_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		permission: 9004,
		// ⚙️⚙️ v2 授權群組 ⚙️⚙️
		group: PermissionEnum.ADMIN_NOTIFICATION,
		breadcrumb: [{
			key: 'admin.notification.index',
			value: RouteNameEnum.ADMIN_NOTIFICATION
		}, {
			key: 'admin.notification.edit.subject',
			value: RouteNameEnum.ADMIN_NOTIFICATION_EDIT
		}, {
			key: 'admin.notification.edit.automatic.subject',
			value: RouteNameEnum.ADMIN_NOTIFICATION_EDIT_AUTOMATIC
		}, {
			key: 'admin.notification.edit.automatic.edit.subject',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/notification/edit/automatic/edit/index.vue"),
}];

export default notification;
