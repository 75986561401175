import billboard from "@/language/modules/page/admin/billboard/zhCN";
import dashboard from "@/language/modules/page/admin/dashboard/zhCN";
import user from "@/language/modules/page/admin/user/zhCN";
import church from "@/language/modules/page/admin/church/zhCN";
import client from "@/language/modules/page/admin/client/zhCN";
import cycle from "@/language/modules/page/admin/cycle/zhCN";
import article from "@/language/modules/page/admin/article/zhCN";
import gallery from "@/language/modules/page/admin/gallery/zhCN";
import notification from "@/language/modules/page/admin/notification/zhCN";
import event from "@/language/modules/page/admin/event/zhCN";
import donate from "@/language/modules/page/admin/donate/zhCN";
import teach from "@/language/modules/page/admin/teach/zhCN";
import course from "@/language/modules/page/admin/course/zhCN";
import support from "@/language/modules/page/admin/support/zhCN";
import live from "@/language/modules/page/admin/live/zhCN";
import activity from "@/language/modules/page/admin/activity/zhCN";
import personal from "@/language/modules/page/admin/personal/zhCN";

export default {

    /**
     * 公吿栏管理
     */
    billboard: billboard,

    /**
     * 控制面板
     */
    dashboard: dashboard,

    /**
     * 管理员管理
     */
    user: user,

    /**
     * 教会管理
     */
    church: church,

    /**
     * 会友管理
     */
    client: client,

    /**
     * 循环管理
     */
    cycle: cycle,

    /**
     * 文章管理
     */
    article: article,

    /**
     * 图库管理
     */
    gallery: gallery,

    /**
     * 推播管理
     */
    notification: notification,

    /**
     * 行事历管理
     */
    event: event,

    /**
     * 奉献管理
     */
    donate: donate,

    /**
     * 教学管理
     */
    teach: teach,

    /**
     * 历程管理
     */
    course: course,

    /**
     * 客服管理
     */
    support: support,

    /**
     * 直播管理
     */
    live: live,

    /**
     * 活动管理
     */
    activity: activity,

    /**
     * 个人
     */
    personal: personal

}
