/**
 * 族群
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            statistics: "牧養跟進",
            expand: {
                on: "展開全部統計",
                off: "收起全部統計"
            },
            root: "新增族群",
            add: "新增同階族群",
            children: "新增子族群",
            undo: "回上一階",
            architecture: {
                active: "組織圖",
                inactive: "無子族群供顯示組織圖"
            },
            hierarchy: {
                off: "無下一階",
                subject: "下一階"
            },
            edit: {
                subject: "編輯",
                text: "該族群缺少託管人員, 請盡快編輯設定"
            },
            attend: "查看聚會",
            view: "查看",
            destroy: {
                subject: "刪除",
                attend: `已設定聚會, 無法刪除<br/>請先刪除已設定聚會`,
                children: `已設定有子族群, 無法刪除<br/>請先刪除所有子族群`
            }
        },
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改"
        },
        help: {
            updated: "最後更新時間"
        },
        selection: "請先選擇指定教會",
        quantity: {
            attend: "聚會數量",
            children: "族群數量",
            manager: {
                current: "託管人員",
                recursive: "託管人員 - 合計"
            },
            partake: {
                current: "族群成員",
                recursive: "族群成員 - 合計"
            }
        },
        field: {
            church: "指定教會"
        },
        holder: {
            church: "請選擇指定教會"
        },
        factory: {
            reset: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將重設該教會牧養相關功能<br/>包括牧養對話群組與聚會等相關功能<br/>重設將無法恢復, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            },
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除族群, 刪除後牧養對話群組將會解散<br/>並無法恢復, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },
    statistics: {
        item: {
            attend: {
                subject: "聚會紀錄",
                field: {
                    subject: "族群",
                    total: "合計次數",
                    last: "最後更新時間"
                }
            },
            event: {
                subject: "服事參與",
                help: {
                    updated: "最後更新時間"
                },
                empty: {
                    subject: "暫無資料"
                },
                tip: {
                    team: `列表對象為群組第一層全教會各佔比，點選群組名稱可查閱下一級群組<br/><span class="text-danger">該牧區計算: 參與服事人數 / 總人數 = 全教會服事比例</span>`
                },
                header: {
                    team: {
                        subject: "統計服事對象"
                    },
                    temperature: {
                        subject: "溫度服事對象",
                        info: "30天以上未參加",
                        warning: "60天以上未參加",
                        danger: "90天以上未參加",
                        total: {
                            all: {
                                subject: `合計 <b class="text-success ft-15">%s</b> 位`
                            },
                            section: {
                                subject: `共 <b class="text-%s ft-15">%s</b> 位`
                            }
                        }
                    },
                    leave: {
                        subject: "離開服事對象",
                        total: {
                            all: {
                                subject: `合計 <b class="text-danger ft-15">%s</b> 位`
                            }
                        }
                    },
                    join: {
                        month: {
                            subject: `新增服事人數 <span class="ft-14">(月)</span>`,
                            total: {
                                all: {
                                    subject: `合計 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        },
                        week: {
                            subject: `新增服事人數 <span class="ft-14">(週)</span>`,
                            total: {
                                all: {
                                    subject: `合計 <b class="text-success ft-15">%s</b> 位`
                                }
                            }
                        }
                    },
                    event: {
                        team: {
                            subject: "參與群組",
                            total: {
                                all: {
                                    subject: `合計 <b class="text-info ft-15">%s</b> 位`
                                }
                            }
                        }
                    }
                },
                summary: {
                    total: {
                        subject: "總人數"
                    },
                    having: {
                        subject: "參與服事人數"
                    },
                    nothing: {
                        subject: "未參與服事人數"
                    },
                    percent: {
                        current: {
                            subject: `參與服事比例 <span class="ft-12">(%)</span>`
                        },
                        prev: {
                            subject: `前月參與服事比例 <span class="ft-12">(%)</span>`
                        }
                    },
                    join: {
                        month: {
                            subject: `新增服事人數 <span class="ft-12">(月)</span>`
                        },
                        week: {
                            subject: `新增服事人數 <span class="ft-12">(週)</span>`
                        }
                    },
                    leave: {
                        subject: "離開服事人數"
                    }
                }
            },
            gender: {
                subject: "男女比例",
                field: {
                    subject: "性別",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "弟兄",
                    2: "姐妹",
                    9999: "未登記"
                }
            },
            year: {
                subject: "年齡分佈",
                field: {
                    subject: "年齡",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    0: "0-9歲",
                    1: "10-19歲",
                    2: "20-29歲",
                    3: "30-39歲",
                    4: "40-49歲",
                    5: "50-59歲",
                    6: "60-69歲",
                    7: "70-79歲",
                    8: "80-89歲",
                    9: "90-99歲",
                    10: "100-109歲",
                    11: "110-119歲",
                    12: "120-129歲",
                    9999: "未登記"
                }
            },
            deacon: {
                subject: "五重執事",
                field: {
                    subject: "執事",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "使徒",
                    2: "先知",
                    3: "傳福音",
                    4: "牧師",
                    5: "教師",
                    9999: "未登記"
                }
            },
            education: {
                subject: "教育程度",
                field: {
                    subject: "程度",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "小學",
                    2: "中學",
                    3: "專科",
                    4: "高中",
                    5: "高職/高商",
                    6: "大學",
                    7: "碩士",
                    8: "博士",
                    9999: "未登記"
                }
            },
            occupation: {
                subject: "職業分佈",
                field: {
                    subject: "職業",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "行政經營",
                    2: "業務行銷",
                    3: "人事法務",
                    4: "財會金融",
                    5: "廣告美編",
                    6: "客戶服務",
                    7: "電腦硬體",
                    8: "資訊軟體",
                    9: "品管製造",
                    10: "技術服務",
                    11: "營建職業",
                    12: "傳播媒體",
                    13: "娛樂演藝",
                    14: "教育學術",
                    15: "醫療美容",
                    16: "保全警衛",
                    17: "家事服務",
                    18: "農林魚牧",
                    19: "慈善宗教",
                    20: "交通及物流服務",
                    21: "餐飲旅遊運動",
                    22: "美容美髮",
                    23: "軍公教",
                    56: "家管",
                    57: "兒童",
                    58: "待業",
                    59: "學生",
                    9999: "未登記"
                }
            },
            marital: {
                subject: "婚姻狀況",
                field: {
                    subject: "狀況",
                    quantity: "數量",
                    percent: "佔比",
                    total: "總和",
                    last: "最後更新時間"
                },
                option: {
                    1: "已婚",
                    2: "未婚",
                    3: "喪偶",
                    4: "再婚",
                    5: "離婚",
                    9999: "未登記"
                }
            },
            baptized: {
                subject: "受洗人數"
            }
        }
    }

}
