export default {

    selector: {
        help: {
            tag: {
                subject: "總教會僅有全部與管理員名單"
            }
        }
    }

}
