import {NavigationItemRaw} from '@/navigation/interface'

const cycle: NavigationItemRaw[] = [{
    id: 13,
    right: true,
    subject: 'admin.cycle.index',
    route: 'AdminCycle',
    icon: 'repeat',
    children: [],
    attached: [
        'AdminCycleCreate',
        'AdminCycleEdit',
        'AdminCycleHistory'
    ]
}]

export default cycle
