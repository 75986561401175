import admin from "@/language/modules/nav/admin/zhCN";
import billboard from "@/language/modules/nav/billboard/zhCN";
import shepherd from "@/language/modules/nav/shepherd/zhCN";

export default {

    /**
     * 公吿栏
     */
    billboard: billboard,

    /**
     * 我的教会
     */
    admin: admin,

    /**
     * 牧养系统
     */
    shepherd: shepherd,

    profile: "个人资料",

    head: "返回管理介面",

    logout: "注销系统"
}
