import category from "@/language/modules/page/admin/article/category/zhTW";

/**
 * 文章管理
 */
export default {

    /**
     * 分類
     */
    category: category,

    /**
     * 列表
     */
    index: {
        integrate: {
            badge: {
                subject: "文章整合"
            }
        },
        mindcatcher: {
            badge: {
                subject: "心靈捕手"
            }
        },
        summary: {
            comment: {
                subject: "查看留言紀錄"
            },
            notification: {
                subject: "查看推播紀錄"
            }
        },
        feature: {
            create: {
                subject: "新增文章"
            },
            mindcatcher: {
                subject: "分享連結"
            }
        },
        top: [
            "未置頂",
            "已置頂"
        ],
        field: {
            head: {
                church: "指定教會"
            },
            top: "置頂",
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "封面圖",
            subject: "標題",
            category: "分類",
            kind: "類型",
            schedule: "排程",
            summary: {
                visit: "觀看",
                social: "外連",
                comment: "留言",
                notification: "推播"
            },
            publish: {
                on: "上架時間",
                off: "下架時間"
            },
            created: "建立時間"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            top: "請選擇置頂",
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            subject: "請輸入標題",
            category: {
                subject: "請選擇分類",
                empty: "請先選擇指定教會"
            },
            kind: "請選擇類型",
        },
        handler: {
            mindcatcher: {
                subject: "我的教會x心靈捕手",
                text: `<span class="text-success">請貼上您的分享連結</span>`,
                description: "請輸入分享連結"
            },
            top: {
                enable: {
                    subject: "即將文章置頂",
                    text: "請確認後執行",
                    description: ""
                },
                disable: {
                    subject: "即將文章取消置頂",
                    text: "請確認後執行",
                    description: ""
                }
            },
            enable: {
                subject: "即將啟用文章",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用文章",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除文章, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "圖片"
            },
            2: {
                subject: "分享"
            },
            3: {
                subject: "活動"
            },
            4: {
                subject: "直播"
            }
        },
        tip: {
            live: "類型為直播, 無法使用活動次分類"
        },
        help: {
            church: "請謹慎使用指定教會功能",
            share: "前往設定分享描述"
        },
        field: {
            church: {
                my: {
                    subject: "指定教會",
                    text: ""
                },
                soul: {
                    subject: "指定教會",
                    text: "僅能指定自行維護心靈捕手的教會"
                },
                daily: {
                    subject: "指定教會",
                    text: "僅能指定自行維護每日靈糧的教會"
                }
            },
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            category: "分類",
            subject: "標題",
            publish: {
                on: "上架時間",
                off: "下架時間"
            },
            cycle: {
                kind: "循環方式",
                day: "循環日",
                date: "日期",
                time: "時間"
            },
            hyperlink: {
                media: "Youtube連結",
                music: "Google雲端MP3",
                live: "直播連結",
                activity: "外部活動連結",
                web: "外部網站連結"
            },
            content: "內容",
            collection: "文章圖片",
            description: "分享內文",
            status: "狀態",
            share: "轉發分享"
        },
        holder: {
            church: "請選擇指定教會",
            kind: "請選擇類型",
            category: {
                subject: "請選擇分類",
                empty: "請先選擇指定教會"
            },
            subject: "請輸入標題",
            publish: {
                begin: "請選擇上架時間",
                end: "請選擇下架時間"
            },
            cycle: {
                kind: "請選擇循環方式",
                day: "請選擇循環日",
                date: "請選擇日期",
                time: "請選擇時間"
            },
            hyperlink: {
                media: "請輸入Youtube連結",
                music: "請輸入Google雲端MP3",
                live: "請輸入直播連結",
                activity: "請輸入外部活動連結",
                web: "請輸入外部網站連結"
            },
            content: "請輸入內容",
            collection: "",
            description: "請輸入分享內文",
            status: "請選擇狀態",
            share: "請選擇轉發分享"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            store: {
                subject: "即將新增文章",
                text: "請確認後執行",
                description: ""
            },
            copy: {
                subject: "即將複製文章",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇類型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇上架時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架時間必須大於上架時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請輸入外部活動連結",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入Youtube連結",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入Google雲端MP3",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請輸入直播連結",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入外部網站連結",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請選擇轉發分享",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            },
            1015: {
                "subject": "請指定教會",
                "text": "",
                "description": ""
            },
            3001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "此文章無法複製",
                "text": "已經啟用整合功能",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        comment: {
            feature: {
                calculate: {
                    subject: "重計留言數量"
                }
            },
            subject: "留言紀錄",
            th: {
                0: "頭像",
                1: "姓名",
                2: "內容",
                3: "時間",
            },
            delete: {
                empty: {
                    subject: "請先選擇需要刪除的留言"
                }
            },
            handler: {
                calculate: {
                    subject: "即將重新計算文章留言數量",
                    text: "請確認後執行",
                    description: ""
                },
                delete: {
                    subject: "即將刪除文章留言",
                    text: "請確認後執行",
                    description: ""
                }
            }
        },
        tip: {
            disabled: "請注意, 這篇文章已經停用",
            live: "類型為直播, 無法使用活動次分類"
        },
        help: {
            share: "前往設定分享描述"
        },
        integrate: {
            live: {
                subject: "已啟用直播文章整合, 無法編輯"
            },
            activity: {
                subject: "已啟用活動文章整合, 無法編輯"
            },
            mindcatcher: {
                subject: "心靈捕手外部文章整合, 無法編輯"
            },
            hyperlink: "前往修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "圖片"
            },
            2: {
                subject: "分享"
            },
            3: {
                subject: "活動"
            },
            4: {
                subject: "直播"
            },
            5: {
                subject: "統計資訊"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            comment: {
                subject: "留言紀錄"
            },
            notification: {
                subject: "推播紀錄"
            },
            visit: {
                subject: "觀看次數"
            },
            social: {
                subject: "外連觀看"
            },
            favorite: {
                subject: "收藏次數"
            }
        },
        field: {
            belong: {
                no: "所屬教會編號",
                name: "所屬教會名稱"
            },
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            category: "分類",
            subject: "標題",
            publish: {
                on: "上架時間",
                off: "下架時間"
            },
            cycle: {
                kind: "循環",
                day: "循環日",
                date: "日期",
                time: "時間"
            },
            hyperlink: {
                media: "Youtube連結",
                music: "Google雲端MP3",
                live: "直播連結",
                activity: "外部活動連結",
                web: "外部網站連結"
            },
            content: "內容",
            collection: "文章圖片",
            description: "分享內文",
            status: "狀態",
            share: "轉發分享"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            kind: "請選擇類型",
            category: "請選擇分類",
            subject: "請輸入標題",
            publish: {
                on: "請選擇上架時間",
                off: "請選擇下架時間"
            },
            cycle: {
                kind: "請選擇循環方式",
                day: "請選擇循環日",
                date: "請選擇日期",
                time: "請選擇時間"
            },
            hyperlink: {
                media: "請輸入Youtube連結",
                music: "請輸入Google雲端MP3",
                live: "請輸入直播連結",
                activity: "請輸入外部活動連結",
                web: "請輸入外部網站連結"
            },
            content: "請輸入內容",
            collection: "",
            description: "請輸入分享內文",
            status: "請選擇狀態",
            share: "請選擇轉發分享"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            update: {
                subject: "即將更新文章內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇類型",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇上架時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "下架時間必須大於上架時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請輸入外部活動連結",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入Youtube連結",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入Google雲端MP3",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "請輸入直播連結",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入外部網站連結",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1013: {
                "subject": "請選擇轉發分享",
                "text": "",
                "description": ""
            },
            1014: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            },
            3001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
