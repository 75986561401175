<template>
  <li class="nav-item dropdown dropdown-user">
    <el-dropdown
        ref="dropdown"
        placement="bottom-end">
      <a class="nav-link dropdown-toggle dropdown-user-link">
        <div class="hd">
          <span class="avatar">
            <j-avatar
                v-if="isSimulation"
                :url="`/img/avatar/holder/robot.png`" />
            <j-avatar
                v-else
                :url="getUser.avatarUrl" />
          </span>
          <!-- 總教會不連接WS, 所以不顯示 -->
          <span v-if="!isHead"
                :class="getSetting.websocket.connected ? `active` : null"
                class="sts" />
        </div>
      </a>
      <template #dropdown>
        <el-dropdown-menu
            class="kg">
          <el-dropdown-item
              class="fg d-flex d-flex-column">
            <div v-if="isSimulation"
                 class="robot"
                 v-html="$t(`component.menu.horizontal.user.robot`)" />
            <small :class="isSimulation ? `hj` : null"
                   class="w-100 cg999 d-inline-block"
                   v-html="$t(`component.menu.horizontal.user.account`)" />
            <a class="w-100 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase m-l-10"
                          type="security-safe" />
              </div>
              <div v-html="getUser.name"
                    class="user-name m-l-10 m-r-8" />
            </a>
            <small class="w-100 cg999 d-inline-block"
                   v-html="$t(`component.menu.horizontal.user.timezone`)" />
            <a class="w-100 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase m-l-10"
                          type="clock" />
              </div>
              <div v-html="getUser.church.timezone.name"
                   class="user-name m-l-10 m-r-8" />
            </a>
          </el-dropdown-item>

          <el-dropdown-item
              v-if="!isHead"
              class="divider" />
          <el-dropdown-item
              v-if="!isHead"
              class="fg d-flex d-flex-column">

            <small class="w-100 cg999 d-inline-block"
                   v-html="$t(`component.menu.horizontal.user.websocket.communication`)" />
            <!-- 總教會不連接WS, 所以不顯示 -->
            <a v-if="!isHead"
               class="w-100 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase m-l-10"
                          type="link" />
              </div>
              <div class="user-name m-l-10 m-r-8">
                <b v-if="getSetting.websocket.connected"
                   class="text-success"
                   v-html="$t(`component.menu.horizontal.user.websocket.status.connected`)" />
                <b v-else
                   class="text-danger"
                   v-html="$t(`component.menu.horizontal.user.websocket.status.disconnect`)" />
              </div>
            </a>
          </el-dropdown-item>

          <el-dropdown-item
              class="divider" />
          <el-dropdown-item
              class="fg d-flex d-flex-column">

            <!-- UI版本 -->
            <small class="w-100 cg999 d-inline-block"
                   v-html="$t(`component.menu.horizontal.user.version.ui`)" />
            <a class="w-100 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase m-l-10"
                          type="monitor" />
              </div>
              <div v-html="getVersionUI"
                   class="user-name m-l-10 m-r-8" />
            </a>

            <!-- 系統版本 -->
            <small class="w-100 cg999 d-inline-block"
                   v-html="$t(`component.menu.horizontal.user.version.core`)" />
            <a class="w-100 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase m-l-10"
                          type="driver-2" />
              </div>
              <div v-html="getVersionCore"
                   class="user-name m-l-10 m-r-8" />
            </a>

          </el-dropdown-item>


          <el-dropdown-item
              class="divider" />

          <el-dropdown-item
              @click="closeDropDown"
              v-if="!isSimulation">
            <router-link :to="{name: `AdminPersonal`}" class="m-l-5 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase"
                          type="user-square" />
              </div>
              <span v-html="$t('nav.profile')"
                    class="m-l-5 m-r-8" />
            </router-link>
          </el-dropdown-item>

          <el-dropdown-item
              @click="onBack"
              v-else>
            <a class="m-l-5 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase"
                          type="shield-tick" />
              </div>
              <span v-html="$t('nav.head')"
                    class="m-l-5 m-r-8" />
            </a>
          </el-dropdown-item>

          <el-dropdown-item
              v-if="isHead"
              class="divider" />

          <el-dropdown-item
              @click="onFlushCache"
              v-if="isHead">
            <a class="m-l-5 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase"
                          type="unlimited" />
              </div>
              <span v-html="$t(`component.menu.horizontal.user.flush`)"
                    class="m-l-5 m-r-8" />
            </a>
          </el-dropdown-item>
          <el-dropdown-item
              class="divider" />
          <el-dropdown-item
              @click="logoutIntent">
            <a class="m-l-5 d-flex justify-content-start align-items-center">
              <div class="jd">
                <icon-sax :hover="false"
                          class="ft-20 cgBase"
                          type="logout" />
              </div>
              <span v-html="$t('nav.logout')"
                    class="m-l-5 m-r-8" />
            </a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </li>
</template>

<script setup lang="ts">

import JAvatar from "@/component/Avatar.vue"
import IconSax from "@/component/IconSax.vue"

import {computed, ref} from "vue"

import {useUser} from "@/hook/useUser"

import {useIntent} from "@/hook/useIntent"

import {usePermission} from "@/hook/usePermission"

import {useSwalAlert} from "@/hook/useSwalAlert"

import {useI18n} from "vue-i18n"

import {getAdminUserFlush} from "@/api/module/core/admin/user/flush"

import {useSetting} from "@/hook/useSetting"
import {sprintf} from "sprintf-js";
import {useSimulation} from "@/hook/useSimulation";

const {
  showHandler
} = useSwalAlert()

const {
  t
} = useI18n()

const {
  getSetting
} = useSetting()

const {
  getUser
} = useUser()

const {
  isHead,
  isSimulation
} = usePermission()

const {
  logoutIntent
} = useIntent()

const {
  onBack
} = useSimulation()

const dropdown = ref()

/**
 * 手動 - 關閉 - 右上方 - 下拉選單
 */
const closeDropDown = () => dropdown.value.handleClose()

/**
 * 手動 - 釋放 - 緩存
 */
const onFlushCache = () => showHandler({
  subject: t(`component.menu.horizontal.user.handler.flush.subject`),
  text: t(`component.menu.horizontal.user.handler.flush.text`),
  completion: {
    //呼叫儲存回調
    success: async () => await getAdminUserFlush()
  }
})

/**
 * 取得UI版本
 * 需要重新啟動才生效
 */
const getVersionUI = computed((): string => {

  const v: string[] = (__APP_VERSION__ as string).split(".")
  const build: string = v.pop() as string
  const version: string = v.join(".")

  return sprintf(
      "%s (%s)",
      version,
      build
  )

})

/**
 * 取得系統版本
 */
const getVersionCore = computed((): string => {
  return sprintf(
      "%s (%s)",
      getUser.value.software.version,
      getUser.value.software.build
  )
})

</script>

<style scoped lang="sass">
.hd
  position: relative
  .sts
    width: 8px
    height: 8px
    background-color: #F00
    position: absolute
    bottom: -2px
    right: -2px
    z-index: 3
    border-radius: 8px
    &.active
      background-color: #28c76f !important
.kg
  .el-dropdown-menu__item
    .user-name
      width: 100%
      display : inline-block
      overflow : hidden
      text-overflow : ellipsis
      white-space : nowrap
</style>
