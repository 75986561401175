/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 操作 - 平台 - 名稱
 */
export enum EnumPortalName {

    /**
     * 我的教會
     */
    ADMIN = 'admin',

    /**
     * 牧養系統
     */
    SHEPHERD = 'shepherd'

}

/**
 * 操作 - 平台 - 路由
 */
export enum EnumPortalRouter {

    /**
     * 我的教會
     */
    ADMIN = 'AdminDashboard',

    /**
     * 牧養系統
     */
    SHEPHERD = 'ShepherdDashboard'

}
