export default {
    editor: {
        subject: `第 <b class="ft-20 text-danger">%s</b> 场`,
        canvas: {
            echelon: {
                create: {
                    subject: "创建场次",
                }
            }
        },
        help: {
            add: {
                custom: "创建场次"
            },
            message: "请依照升序方式编辑或删除, 避免日期设置错误"
        },
        field: {
            subject: "场次名称",
            begin: "开始时间",
            end: "结束时间",
            description: "描述",
            every: {
                subject: `第 <b class="m-l-5 m-r-5 ft-28">%s</b> 场`,
                text: "%s 至 %s"
            }
        },
        holder: {
            subject: "请输入课程名称",
            begin: "请选择开始时间",
            end: "请选择结束时间",
            description: "请输入描述"
        },
        handler: {
            remove: {
                subject: "即将删除场次",
                text: "请确认后执行"
            }
        },
        submit: {
            up: "上移",
            down: "下移",
            edit: "编辑",
            remove: "删除"
        },
        error: {
            1001: {
                "subject": "请输入场次名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            }
        }
    }
}
