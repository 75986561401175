export default {
    status: {
        enable: "启用",
        disable: "停用",
    },
    simulation: "模拟登入",
    repair: "重建模拟",
    download: "下载",
    recipient: "编辑名单",
    approved: "核准",
    reject: "拒绝",
    preview: "预览",
    copy: "复制",
    insert: "插入",
    back: "返回",
    join: "加入",
    remove: "移除",
    select: "选择",
    attachment: "附件",
    home: "返回首页",
    toEntry: "回列表",
    reLogin: "重新登入",
    login: "登入",
    forgot: {
        found: "找回密码",
        verification: "确认提交",
        reset: "重设密码"
    },
    waiting: "登入中",
    success: "登入成功...请稍候",
    ok: "好",
    top: "置顶",
    toTop: "回到顶部",
    save: "保存",
    change: "变更",
    search: "搜索",
    query: "查询",
    close: "关闭",
    accept: "确定",
    refresh: "刷新",
    reload: "重新读取",
    upload: "上传",
    clear: "清除",
    reset: "重设",
    cancel: "取消",
    send: "提交",
    add: "加入",
    edit: "编辑",
    delete: "删除",
    done: "完成",
    fetch: "立即刷新",
    relocation: "重新整理",
    replace: "取代",
    schedule: "排程预览",
    view: "查看",
    segue: "前往"
}
