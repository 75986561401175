export default {
    length: "每頁 %s 項資料",
    summary: `目前顯示 第 <b class="cgBase">%s</b> 頁 共 <b class="cgBase">%s</b> 頁 全部共 <b class="cgBase">%s</b> 項資料`,
    first: "最前頁",
    prev: "上一頁",
    next: "下一頁",
    last: "最後頁",
    search: "搜尋",
    download: "匯出",
    increase: "新增",
    xhring: "資料讀取中",
    reset: "重設",
    refresh: "刷新",
    empty: {
        subject: "抱歉...\uD83D\uDE14",
        text: "沒有找到指定的資料",
        summary: `全部共 <b class="cgBase">0</b> 項資料`
    },
    loading: {
        subject: "正在讀取...請稍候"
    }
}
