import {v2} from "@/language/modules/page/admin/notification/v2/zhCN";

/**
 * 推播管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建推播"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            create: {
                begin: "建立日期(起)",
                end: "建立日期(迄)"
            },
            send: {
                begin: "排程日期(起)",
                end: "排程日期(迄)"
            },
            subject: "标题",
            quantity: "数量",
            schedule: "排程时间",
            ref: "类型",
            kind: "对象",
            progress: "进度"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            subject: "请输入标题",
            create: {
                begin: "请选择建立日期(起)",
                end: "请选择建立日期(迄)"
            },
            send: {
                begin: "请选择排程日期(起)",
                end: "请选择排程日期(迄)"
            },
            ref: "请选择类型",
            kind: "请选择对象"
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除推播, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tip: {
            kind: "请注意, 推播类型建立后将无法变更"
        },
        tabs: {
            0: {

                subject: "基本"
            },
            1: {
                subject: "名单"
            }
        },
        help: {
            church: "请谨慎使用指定教会功能",
        },
        option: {
            all: "全部会友名单"
        },
        field: {
            church: "指定教会",
            excluded: "排除机构",
            belong: {
                no: "所属教会ID",
                name: "所属教会名称"
            },
            kind: "对象",
            subject: "标题",
            schedule: "排程时间",
            text: "简述",
            content: "内容",
            keyword: "搜索名单"
        },
        holder: {
            church: "请选择指定教会",
            excluded: "选中的机构将不会发送推播信息",
            belong: {
                no: "",
                name: ""
            },
            kind: "请选择对象",
            subject: "请输入标题",
            schedule: "请选择排程时间",
            text: "请输入简述",
            content: "请输入内容",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            store: {
                subject: "即将创建推播内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择对象",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择排程时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入简述",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请指定教会",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": `请至少选择 <b class="ft-24 text-danger">1</b> 位会友进行推播`,
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            progress: "系统已经正在推播, 无法编辑或删除",
            complete: "系统已经推播完成, 无法编辑或删除"
        },
        tabs: {
            0: {

                subject: "基本"
            },
            1: {
                subject: "名单"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            belong: {
                no: "所属教会ID",
                name: "所属教会名称"
            },
            excluded: "排除机构",
            kind: "对象",
            subject: "标题",
            schedule: "排程时间",
            text: "简述",
            content: "内容",
            keyword: "搜索名单"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            excluded: "选中的机构将不会发送推播信息",
            kind: "请选择对象",
            subject: "请输入标题",
            schedule: "请选择排程时间",
            text: "请输入简述",
            content: "请输入内容",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            update: {
                subject: "即将更新推播内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择对象",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择排程时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入简述",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请指定教会",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": `请至少选择 <b class="ft-24 text-danger">1</b> 位会友进行推播`,
                "text": "",
                "description": ""
            }
        }
    }

}
