export const v2: {
    [key: string]: any
} = {

    all: {

        subject: "全部"

    }

}
