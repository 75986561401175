import http from "@/api"
import {Respond} from "@/api/interface/respond"
import {ForgotVerification} from "@/api/interface/core/forgot/verification";

/**
 * 忘記密碼 - 驗證碼
 */
export const getForgotVerificationEdit = (
    uuid: any
) => {
    return http.get<Respond.Data<ForgotVerification.Edit.Field>>(
        `/api/forgot/verification/edit/${uuid}`,
        {
        }, {
            opts: {
                builtIn: {
                    loadingMask: false,
                    failure: false
                }
            }
        }
    )
}

/**
 * 忘記密碼 - 驗證碼 - 提交
 */
export const storeForgotVerificationCreate = (
    uuid: any,
    params: any
) => {
    return http.put<Respond.Data<ForgotVerification.Store.Field>>(
        `/api/forgot/verification/edit/${uuid}`,
        params,
        {
        }
    )
}

/**
 * 忘記密碼 - 驗證碼 - 銷毀
 */
export const updateForgotVerificationDestroy = (
    uuid: any
) => {
    return http.delete<Respond.Data<Respond.Update>>(
        `/api/forgot/verification/edit/${uuid}`,
        {
        }, {
            opts: {
                builtIn: {
                    loadingMask: false,
                    failure: false
                }
            }
        }
    )
}
