/**
 * 直播管理
 */
export default {
    subject: "直播管理",
    index: "直播清单",
    create: "直播创建",
    edit: {
        subject: "直播内容",
        comment: {
            subject: "聊天室发言",
        },
        secure: {
            subject: "私密代祷",
        }
    },
    permission: "直播管理 - 直播清单",
    category: {
        index: "分类管理",
        create: "分类创建",
        edit: "分类内容",
        permission: "直播管理 - 分类管理",
    }
}
