export default {
    friend: "新朋友数量",
    client: {
        total: "会友人数",
        track: "已跟进会友",
        stranger: "未跟进会友",
        archive: "不跟进会友"
    },
    team: "族群数量",
    attend: "聚会数量"
}
