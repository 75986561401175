/**
 * 公吿栏管理
 */
export default {

    /**
     * 列表
     */
    index: {
        mode: {
            on: "置顶",
            off: "未置顶"
        },
        feature: {
            create: {
                subject: "创建公吿"
            }
        },
        field: {
            avatar: "头像",
            subject: "名称",
            top: "置顶",
            summary: {
                read: "已读取"
            }
        },
        holder: {
            subject: "请输入名称",
        },
        handler: {
            enable: {
                subject: "即将启用公告",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用公告",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除公告, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            subject: "标题",
            content: "内容",
            top: "置顶"
        },
        holder: {
            subject: "请输入标题",
            content: "请输入内容",
            top: "请选择是否置顶"
        },
        handler: {
            store: {
                subject: "即将创建公吿",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择是否置顶",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "阅读"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            subject: "标题",
            content: "内容",
            top: "置顶",
            keyword: "搜索名单"
        },
        holder: {
            subject: "请输入标题",
            content: "请输入内容",
            top: "请选择是否置顶",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            update: {
                subject: "即将更新公告内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入标题",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择是否置顶",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入内容",
                "text": "",
                "description": ""
            }
        }
    }

}
