import {v2} from "@/language/modules/component/support/v2/zhCN"

export default {

    /**
     * v2
     */
    v2: v2

}
