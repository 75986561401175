/**
 * 客服管理 - 請我們喝咖啡
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "請我們喝咖啡匯出_客服管理",
        },
        field: {
            head: {
                church: "指定教會"
            },
            begin: "贊助日期(起)",
            end: "贊助日期(迄)",
            avatar: "頭像",
            name: "名字",
            order: "訂單號",
            amount: "贊助金額",
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇贊助日期(起)",
            end: "請選擇贊助日期(迄)",
            name: "請輸入名字",
            order: "請輸入訂單號"
        },
        factory: {
            export: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將進行請我們喝咖啡匯出, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "回應"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            created: "建立時間",
            payed: "付款時間",
            order: "訂單號",
            id: "會友ID",
            no: "會友編號",
            name: "會友姓名",
            account: "會友帳號",
            amount: "贊助金額",
            phone: "聯絡電話",
            payload: "結果",
            status: "金流狀態"
        },
        holder: {
            phone: "未填寫聯絡電話"
        }
    }

}
