/**
 * 鉤子
 * 跨組件
 * @author J
 * @since 2024-05-10 07:34:25
 */

import {computed, reactive} from "vue";
import {LightBoxConfiguration} from "@/v2/configuration/lightbox";

/**
 * 本地 - 參數
 */
const active = reactive<{
    //讀取遮罩
    loading: boolean,
    //el-tab
    tab: {
        //切換延遲
        delay: number
    }
    //錯誤
    error: {
        //代號
        code: number
    }
    //視窗
    window: {
        //寬
        width: number,
        //高
        height: number
    }
    //容器
    container: {
        //寬
        width: number,
        //高
        height: number
    }
    //捲動
    scroll: {
        //與頂部距離
        top: number,
        //與左端距離
        left: number,
        //高度
        height: {
            //外
            outer: number,
            //內
            container: number
        },
        //快速 (不等渲染完成, 直接映射)
        fast: {
            //頂部
            top: number
        }
        //實例
        wrapRef: HTMLElement | null,
        //觸發標記
        trigger: boolean
    }
    //幻燈箱
    lightbox: LightBoxConfiguration
}>({
    loading: false,
    tab: {
        delay: 150
    },
    error: {
        code: 0
    },
    window: {
        width: 0,
        height: 0
    },
    container: {
        width: 0,
        height: 0
    },
    scroll: {
        top: 0,
        left: 0,
        height: {
            outer: 0,
            container: 0
        },
        fast: {
            //頂部
            top: 0
        },
        wrapRef: null,
        trigger: false,
    },
    lightbox: {
        download: false,
        show: false,
        images: [],
        index: 0
    }
})

/**
 * 實例
 */
export const useCross = () => {

    /**
     * 獲取 - 視窗 - 尺寸
     */
    const getWindowSize = computed((): {
        width: number,
        height: number
    } => active.window)

    /**
     * 獲取 - 容器 - 尺寸
     */
    const getContainerSize = computed((): {
        width: number,
        height: number
    } => active.container)

    /**
     * 獲取 - 容器 - 捲動
     */
    const getContainerScroll = computed((): {
        top: number
        left: number
        //高度
        height: {
            //外
            outer: number
            //內
            container: number
        }
        wrapRef: HTMLElement | null
    } => active.scroll)

    /**
     * 獲取 - 容器 - 捲動 - 頂部
     * 不等 nextTick()
     */
    const getContainerScrollTop = computed((): number => active.scroll.fast.top)

    /**
     * 獲取 - 錯誤 - 代碼
     */
    const getErrorCode = computed((): number => active.error.code)

    /**
     * 獲取 - 讀取遮罩
     */
    const isLoadingMask = computed((): boolean => active.loading)

    /**
     * 指定物件出現於Container中
     * @param e 指定DOM
     * @param offset 提早n個像素
     */
    const isAppearInContainer = computed((): (
        e: Element | null,
        offset?: number
    ) => boolean => {
        return (
            e,
            offset = 0
        ) => {

            if (!active.scroll.wrapRef) {
                return false
            }
            else if (!e) {
                return false
            }

            const domRect: {
                top: number,
                height: number
            } = e.getBoundingClientRect()

            return active.scroll.wrapRef.clientHeight >= (domRect.top + domRect.height + (offset * -1))

        }
    })

    /**
     * 設定 - 視窗 - 尺寸
     */
    const setWindowSize = () => {

        //紀錄 - 寬
        active.window.width = window.innerWidth
        //紀錄 - 高
        active.window.height = window.innerHeight

    }

    /**
     * 設定 - 容器 - 尺寸
     */
    const setContainerSize = (
        width: number,
        height: number
    ) => {

        //紀錄 - 寬
        active.container.width = width
        //紀錄 - 高
        active.container.height = height

    }

    /**
     * 設定 - 容器 - 捲動
     */
    const setContainerScroll = (
        top: number,
        left: number,
        wrapRef: HTMLElement | null
    ) => {

        //紀錄 - 頂
        active.scroll.top = top
        //紀錄 - 左
        active.scroll.left = left
        //紀錄 - 高度 - 外部
        active.scroll.height.outer = wrapRef?.clientHeight ?? 0
        //紀錄 - 高度 - 內部
        active.scroll.height.container = wrapRef?.scrollHeight ?? 0
        //捲軸 - 物件
        active.scroll.wrapRef = wrapRef

        //清除 - 容器 - 捲動
        active.scroll.trigger = false

    }

    /**
     * 設定 - 容器 - 捲動 - 定步
     * 不等 nextTick()
     */
    const setContainerScrollTop = (
        top: number
    ) => {
        //紀錄 - 快速 - 頂
        active.scroll.fast.top = top
    }

    /**
     * 設定 - 錯誤 - 代碼
     */
    const setErrorCode = (code: number) => active.error.code = code

    /**
     * 顯示 - 讀取遮罩
     */
    const showLoadingMask = () => active.loading = true

    /**
     * 隱藏 - 讀取遮罩
     */
    const hideLoadingMask = () => active.loading = false

    /**
     * el-tab切換過度時間
     */
    const getElementTabsDelay = computed((): number => active.tab.delay)

    /**
     * 獲取 - 幻燈箱 - 資訊
     */
    const getLightBox = computed((): LightBoxConfiguration => active.lightbox)

    /**
     * 顯示 - 幻燈箱
     * @param images 圖片陣列
     * @param download 是否可以下載
     * @param position 顯示第n張
     */
    const showLightBox = (
        images: string[],
        download?: boolean,
        position?: number
    ) => {

        active.lightbox.show = true
        active.lightbox.images = images
        active.lightbox.download = download ?? true
        active.lightbox.index = position ?? 0

    }

    /**
     * 隱藏 - 幻燈箱
     */
    const hideLightBox = () => {

        active.lightbox.show = false
        active.lightbox.images = []
        active.lightbox.download = false
        active.lightbox.index = 0

    }

    /**
     * 下載幻燈箱圖片
     */
    const onSaveLightBox = () => {

        const xhr = new XMLHttpRequest()

        xhr.onload = () => {

            const reader = new FileReader()

            reader.onloadend = () => {
                const linkSource = reader.result! as string
                const downloadLink = document.createElement('a')
                document.body.appendChild(downloadLink)
                downloadLink.href = linkSource
                downloadLink.download = `saved_image_${ new Date().getTime() }`
                downloadLink.click()
                setTimeout(() => downloadLink.remove(), 150)
            }

            reader.readAsDataURL(xhr.response)

        }

        xhr.open('GET', active.lightbox.images[active.lightbox.index])
        xhr.responseType = 'blob'
        xhr.send()

    }

    return {
        getErrorCode,
        setErrorCode,
        getWindowSize,
        setWindowSize,
        getContainerSize,
        setContainerSize,
        getContainerScroll,
        setContainerScroll,
        getContainerScrollTop,
        setContainerScrollTop,
        isAppearInContainer,
        isLoadingMask,
        showLoadingMask,
        hideLoadingMask,
        getLightBox,
        showLightBox,
        hideLightBox,
        onSaveLightBox,
        getElementTabsDelay
    }

}