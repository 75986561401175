export default {
    //分类
    category: [{
        "label": "全部",
        "value": 0,
    }, {
        "label": "基督信仰",
        "value": 1,
    }, {
        "label": "自然生态",
        "value": 2,
    }, {
        "label": "日常生活",
        "value": 3,
    }, {
        "label": "纹理图案",
        "value": 4,
    }, {
        "label": "人事物景",
        "value": 5,
    }, {
        "label": "艺术色彩",
        "value": 6,
    }, {
        "label": "健康食物",
        "value": 7,
    }, {
        "label": "动物植物",
        "value": 8,
    }, {
        "label": "街头摄影",
        "value": 9,
    }],
    //状态
    status: [{
        value: 0,
        label: "未核准"
    }, {
        value: 1,
        label: "已核准"
    }, {
        value: 2,
        label: "已拒绝"
    }]
}
