import {History} from "@/language/modules/page/shepherd/attend/v2/edit/history/zhCN";
import {Schedule} from "@/language/modules/page/shepherd/attend/v2/edit/schedule/zhCN";

export const Edit: {
    [key: string]: any
} = {

    history: History,

    schedule: Schedule,

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "选项"
        },
        2: {
            subject: "名单"
        },
        3 : {
            subject: "提醒"
        },
        4 : {
            subject: "循环"
        },
        98: {
            subject: "统计资讯",
        },
        99: {
            subject: "相关资讯",
        }
    },
    field: {
        category: {
            subject: "分类",
            holder: "请选择分类",
            help: "请先建立聚会分类"
        },
        name: {
            subject: "名称",
            holder: "请输入名称"
        },
        begin: {
            subject: "开始时间",
            holder: "请选择开始时间",
            help: "首次聚会开始时间"
        },
        end: {
            subject: "结束时间",
            holder: "请选择结束时间",
            help: "首次聚会结束时间"
        },
        should: {
            subject: "签到工具显示应到会友",
            holder: "请选择签到工具显示应到会友"
        },
        register: {
            subject: "允许会友自行签到",
            holder: "请选择允许会友自行签到",
            help: {
                subject: "会友可自行于牧养对话中签到",
                off: {
                    subject: "需要透过签到工具或小组长出示扫描会友二维码进行签到",
                }
            }
        },
        place: {
            subject: "地点",
            holder: "请输入地点"
        },
        note: {
            subject: "备注",
            holder: "请输入备注"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    statistics: {
        history: {
            subject: "聚会纪录"
        },
        schedule: {
            subject: "聚会排程"
        }
    },
    alert: {
        readonly: {
            subject: "目前聚会处于仅读取状态无法刷新"
        },
        disable: {
            subject: "目前聚会处于停用状态"
        }
    },
    handler: {
        submit: {
            subject: "即将更新内容",
            text: "请确认后执行"
        }
    },
    submit: {
        cycle: {
            subject: "预览排程"
        }
    },
    error: {
        1010: {
            "subject": "请选择分类",
            "text": "",
            "description": ""
        },
        1020: {
            "subject": "请输入名称",
            "text": "",
            "description": ""
        },
        1030: {
            "subject": "请选择开始时间",
            "text": "",
            "description": ""
        },
        1040: {
            "subject": "请选择结束时间",
            "text": ""
        },
        1041: {
            "subject": "结束时间必须大于开始时间",
            "text": ""
        },
        1050: {
            "subject": "请选择签到工具显示应到会友",
            "text": ""
        },
        1060: {
            "subject": "请选择自行签到",
            "text": ""
        },
        1070: {
            "subject": "请选择状态",
            "text": ""
        },
        2010: {
            "subject": "请完善选项的参数设置",
            "text": ""
        },
        3010: {
            "subject": "请至少选择1个族群",
            "text": ""
        },
        4010: {
            "subject": "请完善提醒的参数设置",
            "text": ""
        },
        5010: {
            "subject": "请完善循环的参数设置",
            "text": ""
        }
    }

}