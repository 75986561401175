import {RouteRecordRaw} from "vue-router";
import {EnumPageDisplay} from "@/enum/page";
import {RouteNameEnum} from "@/v2/enumerate/route";

const support: RouteRecordRaw[] = [{
	//客服查詢 - 帳號紀錄
	path: "support/history",
	name: "AdminSupportHistory",
	meta: {
		permission: 15001,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.history.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/support/history/index.vue"),
}, {
	//客服查詢 - 維修工單
	path: "support/worker",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/index.vue"),
}, {
	//客服查詢 - 維修工單 - 新增
	path: "support/worker/create",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_CREATE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.create',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/create/index.vue"),
}, {
	//客服查詢 - 維修工單 - 編輯
	path: "support/worker/:uuid",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.edit.subject',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/edit/index.vue"),
}, {
	//客服查詢 - 維修工單 - 編輯 - 歷史紀錄
	path: "support/worker/:uuid/history",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.edit.subject',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT
		}, {
			key: 'admin.support.worker.edit.history.index',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/edit/history/index.vue"),
}, {
	//客服查詢 - 維修工單 - 編輯 - 歷史紀錄 - 新增
	path: "support/worker/:uuid/history/create",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY_CREATE,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.edit.subject',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT
		}, {
			key: 'admin.support.worker.edit.history.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY
		}, {
			key: 'admin.support.worker.edit.history.create',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/edit/history/create/index.vue"),
}, {
	//客服查詢 - 維修工單 - 編輯 - 歷史紀錄 - 編輯
	path: "support/worker/:uuid/history/:uid",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY_EDIT,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.edit.subject',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT
		}, {
			key: 'admin.support.worker.edit.history.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY
		}, {
			key: 'admin.support.worker.edit.history.edit',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/edit/history/edit/index.vue"),
}, {
	//客服查詢 - 維修工單 - 編輯 - 異動紀錄
	path: "support/worker/:uuid/record",
	name: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_RECORD,
	meta: {
		// 🎉🎉 切入v2需要添加 🎉🎉
		v2: true,
		//不受權限管制的頁面
		permission: EnumPageDisplay.WORKER,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.worker.index',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER
		}, {
			key: 'admin.support.worker.edit.subject',
			value: RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT
		}, {
			key: 'admin.support.worker.edit.record.index',
			value: null
		}]
	},
	component: () => import("@/v2/page/content/core/admin/support/worker/edit/record/index.vue"),
}, {
	//客服查詢 - 意見與建議
	path: "support/feedback",
	name: "AdminSupportFeedback",
	meta: {
		permission: 15011,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.feedback.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/support/feedback/index.vue"),
}, {
	//客服查詢 - 意見與建議 - 編輯
	path: "support/feedback/edit/:uuid",
	name: "AdminSupportFeedbackEdit",
	meta: {
		permission: 15014,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.feedback.index',
			value: "AdminSupportFeedback"
		}, {
			key: 'admin.support.feedback.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/support/feedback/edit.vue"),
}, {
	//客服查詢 - 請我們喝咖啡
	path: "support/coffee",
	name: "AdminSupportCoffee",
	meta: {
		permission: 15021,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.coffee.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/support/coffee/index.vue"),
}, {
	//客服查詢 - 請我們喝咖啡 - 編輯
	path: "support/coffee/edit/:uuid",
	name: "AdminSupportCoffeeEdit",
	meta: {
		permission: 15024,
		breadcrumb: [{
			key: 'admin.support.subject',
			value: null
		}, {
			key: 'admin.support.coffee.index',
			value: "AdminSupportCoffee"
		}, {
			key: 'admin.support.coffee.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/support/coffee/edit.vue"),
}];

export default support;
