import {NavigationItemRaw} from '@/navigation/interface'

const support: NavigationItemRaw[] = [{
    id: 15,
    right: true,
    subject: 'admin.support.subject',
    route: null,
    icon: 'call-calling',
    children: [{
        id: 15001,
        right: true,
        subject: 'admin.support.history.index',
        route: 'AdminSupportHistory',
        attached: []
    }, {
        id: 15011,
        right: true,
        subject: 'admin.support.feedback.index',
        route: 'AdminSupportFeedback',
        attached: [
            'AdminSupportFeedbackEdit'
        ]
    }, {
        id: 15021,
        right: true,
        subject: 'admin.support.coffee.index',
        route: 'AdminSupportCoffee',
        attached: [
            'AdminSupportCoffeeEdit'
        ]
    }],
    attached: []
}]

export default support
