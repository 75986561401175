export default [{
    value: 0,
    label: "關閉奉獻"
}, {
    value: 1,
    label: "線上奉獻"
}, {
    value: 2,
    label: "奉獻連結"
}]
