/**
 * 每日靈糧文章管理
 */
export default {
    index: "每日靈糧文章管理",
    create: "每日靈糧文章新增",
    edit: "每日靈糧文章內容",
    copy: "文章複製",
    mindcatcher: "文章導入",
    permission: "每日靈糧文章管理",
    comment: {
        index: "留言紀錄",
        permission: "每日靈糧文章管理 - 留言紀錄"
    },
    notification: {
        index: "推播紀錄",
        create: "新增推播",
        edit: "推播內容",
        permission: "每日靈糧文章管理 - 推播紀錄"
    },
}
