/**
 * 行事曆管理
 */
export default {
    subject: "行事曆管理",
    copy: "複製服事",
    create: "服事新增",
    edit: "服事內容",
    index: "服事清單",
    permission: "行事曆管理 - 服事清單",
    team: {
        index: "服事群組",
        create: "群組新增",
        edit: "群組內容",
        permission: "行事曆管理 - 服事群組",
        history: {
            index: "歷史紀錄",
        }
    }
}
