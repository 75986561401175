/**
 * 忘记密码 - 验证码 - 重设
 */
export default {

    subject: "重设登入密码",
    feature: {
        back: "返回登入"
    },
    expire: {
        subject: `本次重设密码令牌将在 <b class="ft-14 text-danger">%s</b> 秒后失效`,
        text: `本次重设密码令牌已经失效`
    },
    field: {
        passwd: {
            create: "新密码",
            confirm: "确认新密码"
        }
    },
    holder: {
        passwd: {
            create: "请输入新密码",
            confirm: "请再输入一次新密码进行确认"
        }
    },
    handler: {
        success: {
            subject: "密码重设成功",
            text: "请使用您设置的新密码登入"
        },
        destroy: {
            subject: "是否确定放弃重设密码",
            text: "确认后将返回登入页面"
        }
    },
    error: {

        1000: {
            subject: "暂时无法使用",
            text: "请稍后再试或请联络管理人员"
        },
        1001: {
            subject: "请输入新密码",
            text: "",
            "description": ""
        },
        1002: {
            subject: "新密码强度不足",
            text: "",
            "description": ""
        },
        1003: {
            subject: "请再输入新密码进行确认",
            text: "",
            "description": ""
        },
        1004: {
            subject: "新密码确认失败",
            text: "两次密码输入不一致",
            "description": ""
        },
        9999: {
            subject: "重设密码令牌已失效",
            text: "请重新进行忘记密码程序"
        }

    }

}
