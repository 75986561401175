/**
 * 聚會管理
 */
export default {
    subject: "聚會管理",
    index: "聚會清單",
    create: "聚會新增",
    edit: "聚會內容",
    permission: "聚會管理 - 聚會清單",
    schedule: {
        index: "聚會排程",
        edit: "排程內容"
    },
    history: {
        index: "聚會紀錄",
        edit: "紀錄內容"
    },
    category: {
        index: "分類管理",
        create: "分類新增",
        edit: "分類內容",
        permission: "聚會管理 - 分類管理"
    }
}
