import {Schedule} from "@/language/modules/component/form/v2/cycle/schedule/zhCN"

export const Cycle: {
    [key: string]: any
} = {

    schedule: Schedule,

    field: {
        cycle: {
            subject: "方式"
        },
        duration: {
            subject: "时长"
        }
    },
    at: {
        subject: "每",
        text: {
            1: "日",
            2: "周",
            3: "个月",
            4: "年"
        },
        forever: {
            subject: "永远"
        },
        until: {
            subject: "直到"
        },
        repeat: {
            subject: "重复",
            text: "次"
        }
    },
    week: {
        begin: {
            subject: `首次起始日<br/>为星期%s不能取消`
        },
        day: [{
            subject: "日",
            text: "星期天"
        }, {
            subject: "一",
            text: "星期一"
        }, {
            subject: "二",
            text: "星期二"
        }, {
            subject: "三",
            text: "星期三"
        }, {
            subject: "四",
            text: "星期四"
        }, {
            subject: "五",
            text: "星期五"
        }, {
            subject: "六",
            text: "星期六"
        }]
    },
    method: {
        1: {
            subject: "在第%s天重复",
            year: {
                subject: "在 %s 的第%s天重复"
            }
        },
        2: {
            subject: "在第%s周的%s重复",
            year: {
                subject: "在 %s 的第%s周的%s重复"
            }
        },
        3: {
            subject: "在最后一个%s重复",
            year: {
                subject: "在 %s 的最后一个%s重复"
            }
        },
        4: {
            subject: "在最后一天重复",
            year: {
                subject: "在 %s 的最后一天重复"
            }
        },
        5: {
            subject: "选取希望重复的日期",
            year: {
                subject: "选取希望在%s号重复的月份",
            }
        }
    },
    month: [{
        subject: "1月",
    }, {
        subject: "2月",
    }, {
        subject: "3月",
    }, {
        subject: "4月",
    }, {
        subject: "5月",
    }, {
        subject: "6月",
    }, {
        subject: "7月",
    }, {
        subject: "8月",
    }, {
        subject: "9月",
    }, {
        subject: "10月",
    }, {
        subject: "11月",
    }, {
        subject: "12月",
    }],
    help: {
        duration: {
            subject: `目前最长可定义 %s 年周期, 请妥善利用资源`,
            until: {
                subject: `请选择重复结束时间`
            }
        }
    },
    error: {
        1001: {
            subject: "请选择条件"
        },
        1002: {
            subject: "请选取希望重复的日期"
        },
        1003: {
            subject: "请选取希望重复的月份"
        },
        1004: {
            subject: "请选择重复结束时间"
        }
    }

}
