import {NavigationItemRaw} from '@/navigation/interface'

const gallery: NavigationItemRaw[] = [{
    id: 8,
    right: true,
    subject: 'admin.gallery.subject',
    route: null,
    icon: 'gallery',
    children: [{
        id: 8001,
        right: true,
        subject: 'admin.gallery.index',
        route: 'AdminGallery',
        attached: []
    }, {
        id: 8011,
        right: true,
        subject: 'admin.gallery.accept',
        route: 'AdminGalleryAccept',
        attached: []
    }, {
        id: 8021,
        right: true,
        subject: 'admin.gallery.alarm',
        route: 'AdminGalleryAlarm',
        attached: []
    }],
    attached: []
}]

export default gallery
