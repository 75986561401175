/**
 * 枚舉
 *
 * ⚠️⚠️ 注意 ⚠️⚠️
 * 枚舉除了根namespace以外, 請都使用扁平的enum
 * 且名稱至少要兩個英文單字組成
 * 因為 namespace 跟 enum 的名稱在 typescript 必須是 unique 的
 */

/**
 * 統計 - 週期
 */
export enum EnumSummaryPeriod {

    /**
     * 天 (1D)
     */
    DAY = 1,

    /**
     * 週 (1W)
     */
    WEEK,

    /**
     * 月 (1M)
     */
    MONTH,

    /**
     * 季 (1Q)
     */
    SEASON,

    /**
     * 半年 (2Q)
     */
    HALF_YEAR,

    /**
     * 1年 (1Y)
     */
    YEAR,

    /**
     * 3年 (3Y)
     */
    THREE_YEAR

}
