import {Create} from "@/language/modules/page/admin/support/v2/worker/create/zhTW"
import {Edit} from "@/language/modules/page/admin/support/v2/worker/edit/zhTW"

export const Worker: {
    [key: string]: any
} = {

    /**
     * 新增
     */
    create: Create,

    /**
     * 編輯
     */
    edit: Edit,

    field: {
        role: {
            subject: "角色"
        },
        type: {
            subject: "類型"
        },
        no: {
            subject: "單號"
        },
        subject: {
            subject: "標題"
        },
        summary: {
            account: {
                subject: "異常帳號"
            },
            attachment: {
                subject: "附件"
            },
            history: {
                subject: "歷史紀錄"
            }
        },
        expire: {
            subject: "超時"
        },
        back: {
            subject: "返工"
        },
        status: {
            subject: "狀態"
        },
        created: {
            subject: "建立時間"
        }
    },
    search: {
        role: {
            subject: "角色",
            holder: "請輸入角色"
        },
        type: {
            subject: "類型",
            holder: "請輸入類型"
        },
        no: {
            subject: "單號",
            holder: "請輸入單號"
        },
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            help: "同時搜尋標題與描述"
        },
        begin: {
            subject: "開始時間(起)",
            holder: "請選擇開始時間"
        },
        end: {
            subject: "結束時間(迄)",
            holder: "請選擇結束時間"
        },
        creator: {
            subject: "建立人",
            holder: "請選擇建立人"
        },
        editor: {
            subject: "上一次編輯人",
            holder: "請選擇上一次編輯人"
        },
        expire: {
            subject: "超時",
            holder: "請選擇超時"
        },
        back: {
            subject: "返工",
            holder: "請選擇返工"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    }

}
