import {v2} from "@/language/modules/component/lightbox/v2/zhTW"

export default {
    v2: v2,
    zoom: {
        in: "放大",
        out: "縮小"
    },
    resize: "還原縮放",
    download: "下載",
    rotate: {
        left: "逆時針旋轉",
        right: "順時針旋轉"
    }
}
