/**
 * 会友管理
 */
export default {

    /**
     * 列表
     */
    index: {
        tag: {
            help: {
                empty: "请先选取指定会友",
                edit: "编辑个人标记",
                join: "附加标签到指定会友",
                replace: "清除并附加标签到指定会友",
                remove: "删除指定会友的标签",
                reset: "重设已输入标签值"
            }
        },
        download: {
            name: "文档汇出_会友管理",
        },
        feature: {
            create: {
                subject: "创建会友"
            },
            tag: {
                on: "开启编辑标签",
                off: "关闭编辑标签"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "头像",
            name: "名字",
            email: "电子邮件",
            account: "帐户",
            commitment: "委身编号",
            keyword: "标签"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            name: "请输入名字",
            email: "请输入电子邮件",
            belong: "请选择所属教会",
            empty: {
                commitment: "未填写"
            },
            keyword: "请输入标签,可用逗号分隔连续输入并按enter输入"
        },
        handler: {
            enable: {
                subject: "即将启用会友",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用会友",
                text: "请确认后执行",
                description: ""
            },
            tag: {
                store: {
                    subject: "即将对指定的会友加入标签",
                    text: "请确认后执行",
                    description: ""
                },
                replace: {
                    subject: "即将对指定的会友取代标签",
                    text: "请确认后执行",
                    description: ""
                },
                remove: {
                    subject: "即将对指定的会友移除标签",
                    text: "请确认后执行",
                    description: ""
                }
            }
        },
        factory: {
            export: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将进行会友汇出, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            },
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将进行全部会友删除, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            disabled: "请注意, 这名会友已经停用"
        },
        commitment: {
            empty: "未填写"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "标签"
            },
            2: {
                subject: "统计资讯"
            },
            99: {
                subject: "相关资讯"
            },
        },
        summary: {
            donate: {
                subject: "奉献纪录"
            },
            share: {
                subject: "分享次数"
            },
            visit: {
                subject: "文章观看"
            },
            comment: {
                subject: "文章回复"
            },
            social: {
                subject: "外连观看"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            email: "电子邮件",
            name: "名字",
            title: "称谓",
            birthday: "生日",
            phone: "联络电话",
            location: {
                country: "所在地区",
                city: "所在城市"
            },
            address: "联络地址",
            occupation: "职业",
            baptized: "受洗时间",
            commitment: "委身编号",
            created: "建立时间",
            accessed: "最后存取时间",
            deleted: "删除时间",
            tag: {
                subject: "标签"
            }
        },
        holder: {
            head: "未输入",
            belong: {
                name: "",
                no: ""
            },
            email: "请输入电子邮件",
            name: "请输入名字",
            title: "未输入称谓",
            birthday: "未输入生日",
            phone: "未输入联络电话",
            location: {
                country: "未输入所在地区",
                city: "未输入所在城市"
            },
            address: "未输入联络地址",
            occupation: "未输入职业",
            baptized: "未输入受洗时间",
            commitment: "请输入委身编号",
            created: "",
            accessed: "",
        },
        handler: {
            update: {
                subject: "即将更新会友内容",
                text: "请确认后执行",
                description: ""
            }
        }
    }

}



