import {NavigationItemRaw} from '@/navigation/interface'

const live: NavigationItemRaw[] = [{
    id: 19,
    right: true,
    subject: 'admin.live.subject',
    route: null,
    icon: 'radar',
    children: [{
        id: 19001,
        right: true,
        subject: 'admin.live.index',
        route: 'AdminLive',
        attached: [
            'AdminLiveCreate',
            'AdminLiveEdit',
            'AdminLiveEditComment',
            'AdminLiveEditSecure'
        ]
    }, {
        id: 19011,
        right: true,
        subject: 'admin.live.category.index',
        route: 'AdminLiveCategory',
        attached: [
            'AdminLiveCategoryCreate',
            'AdminLiveCategoryEdit'
        ]
    }],
    attached: []
}]

export default live
