import {NavigationItemRaw} from '@/navigation/interface'

const donate: NavigationItemRaw[] = [{
    id: 11,
    right: true,
    subject: 'admin.donate.index',
    route: 'AdminDonate',
    icon: 'heart',
    children: [],
    attached: [
        'AdminDonateEdit'
    ]
}]

export default donate
