/**
 * 图库管理 - 核准
 */
export default {
    empty: "此分类下找不到图片",
    disable: "请先选择需要处理的图片",
    summary: `全部 <b class="ft-16 cgBase">%s</b> 张图片`,
    handler: {
        reload: {
            subject: "即将重新整理",
            text: "已选择的项目将会重设, 请确认后执行",
            description: ""
        },
        approved: {
            subject: "即将核准指定的图片",
            text: "请确认后执行",
            description: ""
        },
        reject: {
            subject: "即将拒绝指定的图片",
            text: "请确认后执行",
            description: ""
        }
    }
}
