import admin from "@/language/modules/nav/admin/zhTW";
import billboard from "@/language/modules/nav/billboard/zhTW";
import shepherd from "@/language/modules/nav/shepherd/zhTW";

export default {

    /**
     * 公吿欄
     */
    billboard: billboard,

    /**
     * 我的教會
     */
    admin: admin,

    /**
     * 牧養系統
     */
    shepherd: shepherd,

    profile: "個人資料",

    head: "返回管理介面",

    logout: "登出系統"
}
