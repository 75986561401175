import http from "@/api";
import {Respond} from "@/api/interface/respond";
import {Factory} from "@/api/interface/factory";

/**
 * 二級認證
 */
export const getFactory = (params: Factory) => {
    return http.post<Respond.Basic>(`/api/factory`, params);
};
