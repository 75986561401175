import router from "@/router/router"
import NProgress from "@/config/nprogress"
import Swal from 'sweetalert2'

import {usePermission} from "@/hook/usePermission"
import {getSession} from "@/api/module/session"
import {useUser} from "@/hook/useUser"
import {RouteParams} from "vue-router"
import {useMitt} from "@/hook/useMitt"
import {useSetting} from "@/hook/useSetting"
import {EnumUserSettingAuthorized} from "@/enum/user"
import {EnumPortalName, EnumPortalRouter} from "@/enum/portal"
import {EnumMaintenance} from "@/enum/maintenance";
import {useMaintenance} from "@/hook/useMaintenance";
import {useSwalAlert} from "@/hook/useSwalAlert";

const {
    setLoadingMask,
    setVisibleChangeSelect
} = useMitt()

const {
    isShepherd
} = useMaintenance()

const {
    showMaintenance
} = useSwalAlert()

//不管制是否登入頁面
const UnregulatedPage: string[] = [
    //登入
    'Login',
    //登入 - (舊版帶index)
    'LoginIndex',
    //忘記密碼
    'Forgot',
    //忘記密碼 - 驗證碼
    'ForgotVerification',
    //忘記密碼 - 驗證碼 - 重設密碼
    'ForgotVerificationReset'
]

//公告頁面的名字
const BillboardPage: string[] =[
    'Billboard',
    'BillboardEdit'
]

/**
 * 路由攔截
 */
router.beforeEach(async (to, _, next) => {

    //標記要呼叫關閉DatePicker下拉選單
    setVisibleChangeSelect(true)

    //關閉讀取遮罩
    setLoadingMask(false)

    //移動頁面之前先關閉Swal
    Swal.close()

    // v2頁面, 將body lock 住
    if ((to.meta?.v2 ?? false)) {
        document.body.classList.add('v2-lock')
    }
    // 非v2頁面, 執行舊版邏輯
    else {

        // 移除v2的body lock
        document.body.classList.remove('v2-lock')

        //404, 登入頁面, App下載頁面不檢查
        if (![
            'NotFound',
            'App'
        ].concat(UnregulatedPage).includes(to.name as string)) {

            const {
                isLogin
            } = usePermission()

            //如果沒有連線權杖, 非登入頁面, 直接強迫跳轉到登入頁面
            //避免使用者提示連線權杖逾時, 按重新整理不會回到登入頁面
            if (!isLogin.value) {

                if (!UnregulatedPage.includes(to.name as string)) {

                    await router.replace({
                        name: "Login",
                        params: {
                            id: to.params.id
                        }
                    })

                }

            }
            //權限檢查
            else {

                const {
                    setSettingAuthorized
                } = useSetting()

                const {
                    setPermission
                } = usePermission()

                const {
                    getUser,
                    setUser
                } = useUser()

                const {
                    data
                } = await getSession()

                if (data?.result) {

                    //注入用戶資訊
                    setUser(data.data)

                    //注入權限資料
                    setPermission(data.data.permission)

                    //如果標記已經綁定, 強迫二維碼登入
                    if (data.data.mapping) setSettingAuthorized(EnumUserSettingAuthorized.QRCODE)

                    //教會no
                    const churchNo = to.fullPath.split("/")[2]

                    //路由參數
                    const routeParams: RouteParams = to.params
                    routeParams.id = getUser.value.church.no as string

                    //如果還沒綁管理員 (如果是模擬登入不強迫)
                    if (!getUser.value.mapping && !getUser.value.church.simulation && to.name !== 'Mapping') await router.replace({
                        name: `Mapping`,
                        params: routeParams
                    })
                    //如果網址列的church.no對不上, 則跳轉至正確位置
                    else if (churchNo !== getUser.value.church.no) await router.replace({
                        name: to.name as string,
                        params: routeParams
                    })


                }

            }

        }

        //如果是公告+公告頁面
        if (BillboardPage.includes(to.name as string)) {

            const {
                isHead,
            } = usePermission()

            //總教會跳轉至首頁
            if (isHead.value) await router.replace({
                name: to.meta.portal === EnumPortalName.ADMIN ? EnumPortalRouter.ADMIN : EnumPortalRouter.SHEPHERD,
                params: to.params
            })

        }

        //控制面板 - 各項統計, 動態修改breadcrumb
        if (to.name === `AdminDashboardStatistics`) to.meta.breadcrumb = [{
            key: `admin.home.statistics.${to.params.key}`,
            value: null
        }]

        // 牧養相關頁面 - 維護模式 (總教會除外)
        if (isShepherd.value(to)) showMaintenance(
            EnumMaintenance.SHEPHERD,
            router
        )

    }


    // 開始進度條
    NProgress.start()

    // 開始往下路由
    return next()

})

router.afterEach(() => {

    // 淡出初始化遮罩
    loadingEffect()

    // 完成進度條
    NProgress.done()

})

/**
 * 淡出初始化遮罩
 */
const loadingEffect = () => setTimeout(()=>{

    //獲取讀取遮罩DOM
    const appLoading = document.getElementById('loading')

    //找不到不往下執行
    if (!appLoading) return

    //初始化透明度
    let opacity: number = 1

    //如果本來的讀取遮罩DOM的style沒有透明度, 先補上去
    if (!appLoading.style.opacity) {
        appLoading.style.opacity = String(opacity)
    }

    //淡出方法
    const fadeEffect = setInterval(() => {

        //如果還沒透明到底
        if (opacity > 0) {
            appLoading.style.opacity = String(opacity)
        }
        //透明到底
        else {

            //移除讀取遮罩
            appLoading.remove()
            //移除body上的初始化標記
            document.getElementsByTagName("body")[0].classList.remove("initial")
            //清除淡出方法週期
            clearInterval(fadeEffect)

        }

        //遞減透明度
        opacity -= 0.1

    }, 20)

}, 50)

export default router
