import attend from "@/language/modules/component/attend/zhTW"
import menu from "@/language/modules/component/menu/zhTW"
import remind from "@/language/modules/component/remind/zhTW"
import live from "@/language/modules/component/live/zhTW"
import cycle from "@/language/modules/component/cycle/zhTW"
import qrcode from "@/language/modules/component/qrcode/zhTW"
import billboard from "@/language/modules/component/billboard/zhTW"
import church from "@/language/modules/component/church/zhTW"
import chart from "@/language/modules/component/chart/zhTW"
import swal from "@/language/modules/component/swal/zhTW"
import shepherd from "@/language/modules/component/shepherd/zhTW"
import connect from "@/language/modules/component/connect/zhTW"
import client from "@/language/modules/component/client/zhTW"
import editor from "@/language/modules/component/editor/zhTW"
import avatar from "@/language/modules/component/avatar/zhTW"
import breadcrumb from "@/language/modules/component/breadcrumb/zhTW"
import article from "@/language/modules/component/article/zhTW"
import tag from "@/language/modules/component/tag/zhTW"
import upload from "@/language/modules/component/upload/zhTW"
import cropper from "@/language/modules/component/cropper/zhTW"
import lightbox from "@/language/modules/component/lightbox/zhTW"
import permission from "@/language/modules/component/permission/zhTW"
import theme from "@/language/modules/component/theme/zhTW"
import notification from "@/language/modules/component/notification/zhTW"
import event from "@/language/modules/component/event/zhTW"
import message from "@/language/modules/component/message/zhTW"
import input from "@/language/modules/component/input/zhTW"
import password from "@/language/modules/component/password/zhTW"
import select from "@/language/modules/component/select/zhTW"
import autocomplete from "@/language/modules/component/autocomplete/zhTW"
import form from "@/language/modules/component/form/zhTW"
import offCanvas from "@/language/modules/component/offCanvas/zhTW"
import table from "@/language/modules/component/table/zhTW"
import pagination from "@/language/modules/component/pagination/zhTW"
import sortable from "@/language/modules/component/sortable/zhTW"
import footer from "@/language/modules/component/footer/zhTW"
import choice from "@/language/modules/component/choice/zhTW"
import scroller from "@/language/modules/component/scroller/zhTW"
import activity from "@/language/modules/component/activity/zhTW"
import integrate from "@/language/modules/component/integrate/zhTW"
import recipient from "@/language/modules/component/recipient/zhTW"
import signature from "@/language/modules/component/signature/zhTW"
import support from "@/language/modules/component/support/zhTW"

export default {

    signature: signature,

    support: support,

    recipient: recipient,

    attend: attend,

    menu : menu,

    remind: remind,

    live: live,

    cycle: cycle,

    qrcode: qrcode,

    billboard: billboard,

    church: church,

    chart: chart,

    swal: swal,

    shepherd: shepherd,

    connect: connect,

    client: client,

    editor: editor,

    avatar: avatar,

    breadcrumb: breadcrumb,

    article: article,

    tag: tag,

    upload: upload,

    cropper: cropper,

    lightbox: lightbox,

    permission: permission,

    theme: theme,

    notification: notification,

    event: event,

    message: message,

    input: input,

    password: password,

    select: select,

    autocomplete: autocomplete,

    form: form,

    offCanvas: offCanvas,

    table: table,

    pagination: pagination,

    sortable: sortable,

    footer: footer,

    choice: choice,

    scroller: scroller,

    activity: activity,

    integrate: integrate

}
