export default [{
    value: 0,
    label: "女性"
}, {
    value: 1,
    label: "男性"
}, {
    value: 99,
    label: "不願意透露"
}]
