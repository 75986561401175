import http from "@/api"
import {Respond} from "@/api/interface/respond"
import {ForgotVerificationReset} from "@/api/interface/core/forgot/verification/reset";

/**
 * 忘記密碼 - 驗證碼 - 重設密碼
 */
export const getForgotVerificationResetEdit = (
    uuid: any,
    uid: any
) => {
    return http.get<Respond.Data<ForgotVerificationReset.Edit.Field>>(
        `/api/forgot/verification/edit/${uuid}/reset/edit/${uid}`,
        {
        }, {
            opts: {
                builtIn: {
                    loadingMask: false,
                    failure: false
                }
            }
        }
    )
}

/**
 * 忘記密碼 - 驗證碼 - 重設密碼 - 提交
 */
export const storeForgotVerificationResetCreate = (
    uuid: any,
    uid: any,
    params: any
) => {
    return http.put<Respond.Data<ForgotVerificationReset.Store.Field>>(
        `/api/forgot/verification/edit/${uuid}/reset/edit/${uid}`,
        params,
        {
        }
    )
}

/**
 * 忘記密碼 - 驗證碼 - 重設密碼 - 銷毀
 */
export const updateForgotVerificationResetDestroy = (
    uuid: any,
    uid: any
) => {
    return http.delete<Respond.Data<ForgotVerificationReset.Store.Field>>(
        `/api/forgot/verification/edit/${uuid}/reset/edit/${uid}`,
        {
        }, {
            opts: {
                builtIn: {
                    loadingMask: false,
                    failure: false
                }
            }
        }
    )
}
