import unsplash from "./unsplash/zhCN"
import portal from "./portal/zhCN"
import nav from "./nav/zhCN"
import bool from "./bool/zhCN"
import belong from "./belong/zhCN"
import page from "./page/zhCN"
import error from "./error/zhCN"
import submit from "./submit/zhCN"
import share from "./share/zhCN"
import month from "./month/zhCN"
import gender from "./gender/zhCN"
import entrust from "./entrust/zhCN"
import donate from "./donate/zhCN"
import handler from "./handler/zhCN"
import component from "./component/zhCN"
import shepherd from "./shepherd/zhCN"
import ago from "./ago/zhCN"
import caption from "./caption/zhCN"
import weekday from "./weekday/zhCN"
import datepicker from "./datepicker/zhCN"
import invitation from "./invitation/zhCN"
import schedule from "./schedule/zhCN"
import maintenance from "./maintenance/zhCN"
import select from "./select/zhCN"
import {Status} from "@/v2/i18n/modules/zhTW/status";

export default {

	/**
	 * 平台名称
	 */
	portal: portal,

	/**
	 * 教会 - 三项 - 标题
	 */
	caption: caption,

	/**
	 * 功能表
	 */
	nav: nav,

	/**
	 * n月
	 */
	month: month,

	/**
	 * 星期n
	 */
	weekday: weekday,

	/**
	 * 元件
	 */
	component: component,

	/**
	 * 页面
	 */
	page: page,

	/**
	 * 共用handler
	 */
	handler: handler,

	/**
	 * 共用错误
	 */
	error: error,

	/**
	 * 共用按钮名称
	 */
	submit: submit,

	/**
	 * 是否
	 */
	bool: bool,

	/**
	 * 所属教会
	 */
	belong: belong,

	/**
	 * 显示多久以前
	 */
	ago: ago,

	/**
	 * 显示多久以前
	 */
	datepicker: datepicker,

	/**
	 * 维护中
	 */
	maintenance: maintenance,

	/**
	 * 选项
	 */
	select: select,

	/**
	 * 共用选项
	 */
	options: {

		/**
		 * 牧养选单
		 */
		...shepherd,

		/**
		 * 排程 (是否上架)
		 */
		schedule: schedule,

		/**
		 * 邀请码模式
		 */
		invitation: invitation,

		/**
		 * 分享状态
		 */
		share: share,

		/**
		 * 性别
		 */
		gender: gender,

		/**
		 * 运作模式
		 */
		entrust: entrust,

		/**
		 * 奉献模式
		 */
		donate: donate,

		/**
		 * 图库
		 */
		unsplash: unsplash

	},

	status: Status

}
