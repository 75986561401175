/**
 * 聚會 - 歷程
 */
export default {
    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教會"
            },
            name: "族群名稱",
            subject: "聚會標題",
            begin: "開始時間",
            end: "結束時間"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名字",
        }
    },
    edit: {
        tip: {
            yet: {
                subject: "聚會尚未開始"
            },
            ing: {
                subject: "聚會正在進行"
            },
            ended: {
                subject: "聚會已經結束"
            }
        },
        feature: {
            0: "下載報到介面二維碼",
            1: "重製點名單",
            2: "前往報到介面",
            3: "顯示報到介面二維碼",
            4: "複製報到介面網址",
            11: "聚會已經結束, 無法重製點名單"
        },
        download: {
            subject: "%s_報到介面二維碼",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名單"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            history: {
                subject: "聚會紀錄"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            qrcode: {
                subject: "APP專用報到二維碼",
                text: "點擊下載"
            },
            name: "名稱",
            category: "分類",
            begin: "開始時間",
            end: "結束時間",
            location: "地點",
            note: "備註",
            status: "狀態"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            name: "請輸入名稱",
            category: "請選擇分類",
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            location: "請輸入地點",
            note: "請輸入備註",
            status: "請選擇狀態"
        },
        handler: {
            rebuild: {
                subject: "重製點名單",
                text: `若您發現點名單成員不正確<br />可進行點名單重製, 請確認後執行`
            }
        }
    }
}
