import {NavigationItemRaw} from '@/navigation/interface'

const course: NavigationItemRaw[] = [{
    id: 16,
    right: true,
    subject: 'admin.course.subject',
    route: null,
    icon: 'notification-1',
    children: [{
        id: 16001,
        right: true,
        subject: 'admin.course.exception.index',
        route: 'AdminCourseException',
        attached: [
            'AdminCourseExceptionEdit'
        ]
    }, {
        id: 16011,
        right: true,
        subject: 'admin.course.access.index',
        route: 'AdminCourseAccess',
        attached: [
            'AdminCourseAccessEdit'
        ]
    }, {
        id: 16021,
        right: true,
        subject: 'admin.course.authorize.index',
        route: 'AdminCourseAuthorize',
        attached: [
            'AdminCourseAuthorizeEdit'
        ]
    }],
    attached: []
}]

export default course
