export const Tag: {
    [key: string]: any
} = {

    field: {

        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            text: "同時搜尋名字/郵件/會友編號"
        },

        tag: {
            subject: "標籤",
            holder: "請選擇標籤"
        }

    }

}