export default {
    advance: {
        charge: "需要收費",
        restrict: "限制人數",
        available: "報名上限",
        wait: "等待付款",
        paid: "報名完成",
        remain: "剩餘名額"
    },
    edit: {
        help: {
            backend: "後台編輯"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "團報"
            },
            2: {
                subject: "表單"
            },
            3: {
                subject: "金流"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            group: {
                name: "教會(機構)名稱",
                city: "教會(機構)所在城市",
                zip: "郵遞區號",
                address: "通訊地址",
                contact: {
                    name: "聯絡人姓名",
                    phone: "聯絡人電話號碼",
                    email: "聯絡人電子郵件",
                }
            },
            respond: "結果",
            avatar: "頭像",
            qrcode: "報到二維碼",
            echelon: {
                subject: "報名場次",
                begin: {
                    subject: "場次開始"
                },
                end: {
                    subject: "場次結束"
                },
                total: {
                    subject: `全部 <b>%s</b> 場`
                },
                edit: {
                    subject: "變更場次"
                }
            },
            external: "報名來源",
            organization: "報名方式",
            no: "訂單號",
            name: "姓名",
            email: "電子郵件",
            paid: "已付款",
            creator: {
                subject: "報名人員",
                stamp: {
                    subject: "報名時間",
                }
            },
            status: "狀態"
        },
        holder: {
            group: {
                name: "請輸入教會(機構)名稱",
                city: "請輸入教會(機構)所在城市",
                zip: "請輸入郵遞區號",
                address: "請輸入通訊地址",
                contact: {
                    name: "請輸入聯絡人姓名",
                    phone: "請輸入聯絡人電話號碼",
                    email: "請輸入聯絡人電子郵件",
                }
            },
            organization: "請選擇報名方式",
            external: "請選擇報名來源",
            no: "請輸入訂單號",
            name: "請輸入姓名",
            email: "請輸入電子郵件",
            paid: "請選擇已付款",
            status: "請選擇狀態"
        },
        handler: {
            update: {
                subject: "即將更新報名內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇已付款",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            }
        }
    },
    field: {
        no: "訂單號",
        begin: "建立日期(起)",
        end: "建立日期(迄)",
        avatar: "頭像",
        organization: "報名方式",
        registered: {
            subject: "資訊",
            organization: {
                group: {
                    subject: "團體報名"
                },
                subject: "個人報名"
            },
            external: {
                0: {
                    subject: "App"
                },
                1: {
                    subject: "外部"
                },
                2: {
                    subject: "後台"
                }
            },
            paid: {
                not: {
                    subject: "未付款"
                },
                subject: "已付款"
            }
        },
        external: "報名來源",
        paid: "已付款",
        sort: "排序",
        created: "建立時間"
    },
    holder: {
        no: "請輸入訂單號",
        organization: "請選擇報名方式",
        external: "請選擇報名來源",
        paid: "請選擇已付款",
        begin: "請選擇建立日期(起)",
        end: "請選擇建立日期(迄)"
    },
    factory: {
        paid: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將手動標記付款, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        }
    }
}
