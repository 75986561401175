export const Account: {
    [key: string]: any
} = {
    empty: {
        subject: "请输入异常App帐户"
    },
    field: {
        account: "异常App帐户"
    },
    holder: {
        account: "请输入正确的电子邮件格式, 按 Enter 可创建一笔"
    },
    format: {
        account: "请输入正确的电子邮件格式"
    },
    th: {
        0: {
            subject: "帐户"
        },
        1: {
            subject: "删除"
        }
    },
    handler: {
        delete: {
            subject: "即将删除指定异常帐户",
            text: ""
        }
    }
}
