/**
 * 历程管理
 */
export default {

    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "头像",
            name: "名字",
            role: "来源",
            level: "等级",
            code: "结果代码",
            exception: "拦截",
            module: "模块"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            name: "请输入名字",
            empty: {
                email: "未输入"
            },
            module: "请选择模块"
        },
        handler: {
        }
    },

    /**
     * 编辑
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相关资讯"
            }
        },
        tip: {
            unknown: "此纪录为未知来源"
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            seq: "流水号",
            name: "名字",
            account: "帐户",
            role: "来源",
            level: "等级",
            ref: {
                name: "拦截呼叫",
                church: "标的教会",
                seq: "标的物件流水号"
            },
            module: "拦截类",
            method: "拦截函式",
            code: "结果代码",
            rollback: "回滚事务",
            subject: "标题",
            text: "副标题",
            description: "描述",
            payload: "提交参数",
            created: "建立时间"
        },
        holder: {
            subject: "无标题",
            text: "无副标题",
            description: "无描述"
        },
        handler: {
        }
    }

}
