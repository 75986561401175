export const Schedule: {
    [key: string]: any
} = {

    field: {
        begin: {
            subject: "开始时间"
        },
        end: {
            subject: "结束时间"
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "等待生成",
                },
                1: {
                    subject: "已生成"
                },
                2: {
                    subject: "生成逾期"
                }
            }
        },
        created: {
            subject: "建立时间"
        },
        stamp: {
            subject: "预计生成时间"
        },
        completed: {
            subject: "实际生成时间"
        },
        notified: {
            subject: "推送时间"
        },
        mailed: {
            subject: "寄送邮件时间"
        }
    },
    search: {
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    }

}
