export default {
    editor: {
        form: {
            1: {
                holder: "請輸入電子郵件"
            },
            2: {
                holder: "請輸入%s",
            },
            3: {
                holder: "請輸入%s",
            },
            4: {
                active: "是",
                inactive: "否"
            },
            5: {
                holder: "請輸入%s的描述"
            },
            6: {
                holder: "請選擇%s的描述"
            },
            7: {
                holder: "請選擇%s"
            },
            8: {
                holder: "請輸入姓名"
            },
            rank: `第 <b class="ft-16 text-success">%s</b> 位報名人員`,
            validator: {
                1: {
                    "subject": "必填"
                },
                2: {
                    "subject": "電子郵件格式錯誤"
                },
                3: {
                    "subject": "請至少選擇一個選項"
                },
            }
        },
        canvas: {
            discount: {
                not: {
                    allow: "請依照順序編輯"
                },
                create: {
                    subject: "新增報名優惠"
                },
                edit: {
                    subject: "編輯報名優惠"
                }
            }
        },
        tip: {
            edit: "請依照升序方式編輯或刪除, 避免日期與優惠價格設定錯誤"
        },
        help: {
            add: {
                custom: "新增團報"
            },
            discount: {
                custom: "新增報名優惠",
                maximum: `最多僅能建立 <b class="cgBase">%s</b> 筆報名優惠`,
                not: {
                    next: "優惠價格已經達到最高值, 無法再繼續設定優惠價格"
                }
            },
            external: "若關閉外部報名, 僅能用App報名並且無法使用團體報名"
        },
        enable: {
            external: {
                on: "開啟",
                off: "關閉"
            },
            charge: {
                on: "需收費",
                off: "免費"
            },
            restrict: {
                on: "需限制",
                off: "不限制"
            },
            personal: {
                on: "開啟",
                off: "關閉"
            },
            group: {
                on: "開啟",
                off: "關閉"
            },
            qrcode: {
                on: "開啟",
                off: "關閉"
            },
            discount: {
                on: "開啟",
                off: "關閉"
            },
            payment: {
                on: "開啟",
                off: "關閉"
            }
        },
        field: {
            external: {
                subject: "外部報名"
            },
            restrict: {
                subject: "人數上限",
                field: "人數"
            },
            charge: {
                subject: "費用",
                field: "金額"
            },
            qrcode: {
                subject: "掃碼報到"
            },
            method: {
                subject: "報名方式",
                personal: {
                    subject: "個人報名"
                },
                group: {
                    subject: "團體報名",
                    minimum: "團體最少報名人數",
                    maximum: "團體最多報名人數"
                }
            },
            discount: {
                th: "優惠內容",
                subject: "報名優惠",
                price: "優惠價格",
                begin: "開始時間",
                end: "結束時間",
                description: {
                    subject: "描述內容",
                    text: "可以直接編輯"
                }
            },
            payment: {
                subject: "線上支付",
                yet: {
                    subject: "未付款"
                },
                paid: {
                    subject: "已付款"
                }
            }
        },
        text: {
            discount: {
                first: `<span class="text-danger">(包含往前日期)</span>`
            }
        },
        method: {
            note: {
                subject: "溫馨提示",
                text: `人數上限未達團體報名最低限制 <b class="text-danger ft-16">%s</b> 人, 無法使用團體報名, 僅能使用個人報名`
            }
        },
        payment: {
            note: {
                subject: "備註"
            },
            free: {
                subject: "溫馨提示",
                text: "已經設定活動免費, 無法啟用線上支付功能"
            },
            newebpay: {
                subject: "溫馨提示",
                text: "貴教會未開啟線上奉獻功能, 無法啟用線上支付功能"
            },
            description: {
                1: {
                    subject: `當報名費用低於 <b class="text-success ft-16">30</b> 或 超過 <b class="text-success ft-16">20,000</b> 時, 將無法使用 <b class="text-success">超商代碼繳費</b> 方式支付`
                },
                2: {
                    subject: `當報名費用低於 <b class="text-success ft-16">20</b> 或 超過 <b class="text-success ft-16">40,000</b> 時, 將無法使用 <b class="text-success">超商條碼繳費</b> 方式支付`
                },
                3: {
                    subject: `當報名費用超過 <b class="text-success ft-16">49,999</b> 時, 將無法使用 <b class="text-success">WebATM</b>, <b class="text-success">ATM轉帳</b> 方式支付`
                },
                4: {
                    subject: `<b class="text-success">WebATM</b>, <b class="text-success">ATM轉帳</b> 支付方式, 僅支援 <b class="text-success">台灣銀行</b> 與 <b class="text-success">華南銀行</b> 之銀行戶頭`
                },
                5: {
                    subject: `以上單位均為 <b class="text-success">新臺幣</b>`
                }
            }
        },
        holder: {
            restrict: "請輸入人數",
            charge: "請輸入金額",
            method: {
                group: {
                    minimum: "請輸入團體最少報名人數",
                    maximum: "請輸入團體最多報名人數"
                }
            },
            discount: {
                price: "請輸入優惠價格",
                begin: "請選擇開始時間",
                end: "請選擇結束時間",
                description: "請輸入描述內容"
            }
        },
        handler: {
            remove: {
                subject: "即將刪除優惠價格",
                text: "請確認後執行"
            },
            charge: {
                off: {
                    subject: "即將清空優惠價格設定",
                    text: "您已經有設定優惠價格, 請確認後執行"
                }
            }
        },
        echelon: {
            edit: {
                subject: "變更場次",
                sort: {
                    subject: `第 <b class="ft-22">%s</b> 場`
                },
                between: {
                    subject: `%s 至 %s`
                },
                handler: {
                    update: {
                        subject: "即將變更場次",
                        text: "請確認後執行"
                    }
                }
            }
        },
        error: {
            1001: {
                "subject": "請輸入金額",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請至少加入一筆報名優惠",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入開始時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入結束時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入優惠價格",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請輸入人數",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請至少選擇一種報名方式",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "優惠價格設定異常",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請輸入最少報名人數",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "低於最少報名人數限制",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "請輸入最多報名人數",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "高於報名限制人數",
                "text": "",
                "description": ""
            }
        }
    }
}
