export default {
    recipient: {
        move: {
            left: "双击加入",
            right: "双击移除"
        },
        subject: "编辑签到名单",
        disable: {
            available: "请选择加入的项目",
            selected: "请选择移除的项目",
        },
        changed: "尚未异动项目",
        empty: `请指定协助<br/>使用App签到本活动的会友`,
        not: {
            found: "无法搜索到<br />您指定条件的会友"
        },
        help: {
            available: `可选择 <b class="cgBase">%s</b> 个会友`,
            selected: `已选择 <b class="cgBase">%s</b> 个会友`
        },
        th: [
            "头像",
            "会友",
            "操作",
            "资讯"
        ],
        field: {
            keyword: "搜索名单",
            tag: "标签"
        },
        holder: {
            keyword: "搜索范围: 名字/邮件/编号",
            tag: "请选择标签"
        },
        handle: {
            close: {
                subject: "即将关闭编辑推播",
                text: `<span class="text-danger">异动项目将会清除, 请确认后执行</span>`
            }
        },
        tag: {
            all: "全部",
            uncategorized: "未分类",
            administrator: "管理员名单"
        }
    }
}
