/**
 * 鉤子
 * 跳轉
 * ❗️❗️ 危險 ❗️❗️
 * 在 hook 裡面的, 所有的 t(), 都沒問題, 可以用的
 * 但是檢查器會一直抓錯, 都加上 @ts-ignore
 * 沒加build的時候還會出現, 加了就全都沒這問題
 * error TS2589: Type instantiation is excessively deep and possibly infinite.
 * @author J
 * @since 2024-04-30 07:34:25
 */


import {useAuthorization} from "@/v2/hooks/authorization";
import {IntentEnum} from "@/v2/enumerate/intent";
import router from "@/router";
import {useSweetAlert} from "@/v2/hooks/sweetalert";
import {updateAuthorizationSessionDestroy} from "@/v2/api/module/authorization/session";
import i18n from "@/v2/i18n";
import {RouteNameEnum} from "@/v2/enumerate/route";

/**
 * 實例
 */
export const useIntent = () => {

    //導入i18n
    const {
        // @ts-ignore
        t
    } = i18n.global

    /**
     * 控制面板路由
     */
    const routeLogin: string = `Login`;

    /**
     * 控制面板路由
     */
    const routeDashboard: RouteNameEnum = router.currentRoute.value.meta.portal === 'admin' ?
        RouteNameEnum.ADMIN_DASHBOARD:
        RouteNameEnum.SHEPHERD_DASHBOARD;

    /**
     * 更新 - 授權 - 權杖
     * @param token JWT
     */
    const onToken = async (token: string | null) => {

        const {
            isLogin,
            setToken
        } = useAuthorization()

        //注入新的JWT
        setToken(token)

        //跳轉回控制面板
        setTimeout(() => onLaunch(isLogin.value ? IntentEnum.HOME : IntentEnum.REPLACE), 0)

    }

    /**
     * 啟動
     * @param intent 跳轉方式
     * @param route 指定路由
     */
    const onLaunch = async (intent: IntentEnum,
                            route?: string | null) => {

        /**
         * 首頁
         */
        if (intent === IntentEnum.HOME) {

            await router.push({
                name: routeDashboard
            })

        }
        /**
         * 前往
         */
        else if (intent === IntentEnum.PUSH && route) {

            await router.push({
                name: route
            })

        }
        /**
         * 重新整理
         */
        else if (intent === IntentEnum.RELOAD) {

            window.location.reload()

        }
        /**
         * 前往附掛頁面
         */
        else if (intent === IntentEnum.ATTACH) {

            const attach: any = router.currentRoute.value.meta?.attach
            await router.push({
                name: attach ?? routeDashboard
            })

        }
        /**
         * 登入
         */
        else if (intent === IntentEnum.LOGIN) {

            await router.replace({
                name: routeLogin
            })

        }
        /**
         * 登出
         */
        else if (intent === IntentEnum.LOGOUT) {

            const {
                showHandler
            } = useSweetAlert()

            showHandler({
                //@ts-ignore
                subject: t(`handler.session.destroy.subject`),
                //@ts-ignore
                text: t(`handler.session.destroy.text`),
                completion: {
                    success: async () => {

                        //執行登出
                        await updateAuthorizationSessionDestroy()

                        //銷毀token
                        await onToken(null)

                    }
                }
            })

        }
        /**
         * 置換
         */
        else if (intent === IntentEnum.REPLACE) {

            await router.replace({
                name: route ?? routeLogin
            })

        }

    }

    return {
        onToken,
        onLaunch
    }

}