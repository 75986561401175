import {RouteRecordRaw} from "vue-router";

const my: RouteRecordRaw[] = [{
	//我的教會文章 - 管理
	path: "my_churches",
	name: "AdminMy",
	meta: {
		permission: 5001,
		breadcrumb: [{
			key: 'admin.my.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/index.vue"),
}, {
	//我的教會文章 - 新增
	path: "my_churches/create",
	name: "AdminMyCreate",
	meta: {
		permission: 5002,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/create.vue"),
}, {
	//我的教會文章 - 編輯
	path: "my_churches/edit/:uuid",
	name: "AdminMyEdit",
	meta: {
		permission: 5004,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/edit.vue"),
}, {
	//我的教會文章 - 留言紀錄
	path: "my_churches/edit/:uuid/comment",
	name: "AdminMyComment",
	meta: {
		permission: 5011,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.edit',
			value: "AdminMyEdit"
		}, {
			key: 'admin.my.comment.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/comment/index.vue"),
}, {
	//我的教會文章 - 推播紀錄
	path: "my_churches/edit/:uuid/notifications",
	name: "AdminMyNotification",
	meta: {
		permission: 5021,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.edit',
			value: "AdminMyEdit"
		}, {
			key: 'admin.my.notification.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/index.vue"),
}, {
	//我的教會文章 - 推播紀錄 - 新增
	path: "my_churches/edit/:uuid/notifications/create",
	name: "AdminMyNotificationCreate",
	meta: {
		permission: 5022,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.edit',
			value: "AdminMyEdit"
		}, {
			key: 'admin.my.notification.index',
			value: "AdminMyNotification"
		}, {
			key: 'admin.my.notification.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/create.vue"),
}, {
	//我的教會文章 - 推播紀錄 - 編輯
	path: "my_churches/edit/:uuid/notifications/edit/:uid",
	name: "AdminMyNotificationEdit",
	meta: {
		permission: 5024,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.edit',
			value: "AdminMyEdit"
		}, {
			key: 'admin.my.notification.index',
			value: "AdminMyNotification"
		}, {
			key: 'admin.my.notification.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/edit.vue"),
}, {
	//我的教會文章 - 複製
	path: "my_churches/copy/:uuid",
	name: "AdminMyCopy",
	meta: {
		permission: 5002,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.copy',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/copy/index.vue"),
}, {
	//我的教會文章 - 心靈捕手
	path: "my_churches/mindcatcher/:uuid",
	name: "AdminMyMindCatcher",
	meta: {
		permission: 5002,
		breadcrumb: [{
			key: 'admin.my.index',
			value: "AdminMy"
		}, {
			key: 'admin.my.mindcatcher',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/mindcatcher/index.vue"),
}];

export default my;
