export default {

    /**
     * 列表
     */
    index: {
        field: {
            attend: "聚會",
            team: "族群",
            begin: "開始時間",
            end: "結束時間"
        },
        holder: {
            attend: "請選擇聚會",
            team: "請選擇族群",
            begin: "請輸入開始時間",
            end: "請輸入結束時間"
        }
    }

}
