import {NavigationItemRaw} from '@/navigation/interface'

const dashboard: NavigationItemRaw[] = [{
    id: 1,
    right: false,
    subject: 'admin.home.index',
    route: 'AdminDashboard',
    icon: 'monitor',
    children: [],
    //無法歸類的放首頁
    attached: [
        "AdminPersonal",
        "AdminDashboardStatistics"
    ],
}]
export default dashboard
