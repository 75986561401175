export const Remind: {
    [key: string]: any
} = {

    field: {
        common: {
            subject: "常用選項"
        },
        custom: {
            subject: "自定義選項",
            join: {
                subject: "新增自定義選項"
            }
        },
        notify: {
            subject: "通知方式",
            email: {
                holder: "請輸入電子郵件",
                join: {
                    subject: "新增電子郵件"
                }
            }
        }
    },
    at: [{
        subject: "活動時間點"
    }, {
        subject: "%s 分鐘前",
    }, {
        subject: "%s 小時前",
    }, {
        subject: "%s 天前",
    }, {
        subject: "%s 週前",
    }],
    beforehand: {
        subject: "系統內建提醒無法取消"
    },
    help: {
        picked: {
            subject: `已選%s個選項`,
            email: {
                subject: `已設定%s個電子郵件`,
            }
        },
        selected: {
            subject: `最多只能選擇 <b class="ft-16">%s</b> 個選項, 還可以選擇 <b class="ft-16">%s</b> 個選項`,
            full: {
                subject: `已達到選項上限 <b class="ft-16">%s</b> 個`
            }
        },
        custom: {
            subject: `自定義選項最多僅能設定 %s 筆`
        },
        notify: {
            email: {
                subject: `郵件名單最多僅能設定 %s 筆`,
                empty: {
                    subject: "請輸入電子郵件"
                }
            }
        }
    },
    error: {
        1001: {
            subject: "請輸入正確的電子郵件"
        }
    }

}
