/**
 * 行事曆管理
 */
export default {

    /**
     * 列表
     */
    index: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
        },
        feature: {
            create: {
                subject: "新增群組"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            name: "名稱",
            quantity: "人數"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名稱",
        },
        handler: {
            enable: {
                subject: "即將啟用群組",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用群組",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除群組, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名單"
            }
        },
        field: {
            name: "名稱",
            keyword: "搜尋名單"
        },
        holder: {
            name: "請輸入名稱",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            store: {
                subject: "即將新增群組",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請至少選擇1位人員參與服事",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這個群組已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名單"
            },
            2: {
                subject: "統計資訊"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            history: {
                subject: "歷史紀錄"
            }
        },
        field: {
            belong: {
                no: "所屬教會ID",
                name: "所屬教會名稱"
            },
            name: "名稱",
            keyword: "搜尋名單"
        },
        holder: {
            head: "未輸入",
            belong: {
                no: "",
                name: ""
            },
            name: "請輸入名稱",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            update: {
                subject: "即將更新群組內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請至少選擇1位人員參與服事",
                "text": "",
                "description": ""
            }
        },
        /**
         * 歷史紀錄
         */
        history: {
            advance: {
                week: "周內新增",
                month: "月內新增",
                leave: "離開人數"
            },
            description: {
                created: "群組建立",
                partake: "加入群組",
                leave: "從群組中離開"
            },
            field: {
                begin: "開始時間(起)",
                end: "結束時間(迄)",
                description: "描述",
                created: "時間"
            },
            holder: {
                begin: "請選擇開始時間(起)",
                end: "請選擇結束時間(迄)",
            }
        }
    }

}
