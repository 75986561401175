import {Tag} from "@/language/modules/page/admin/notification/v2/edit/membership/tag/zhTW";
import {Editor} from "@/language/modules/page/admin/notification/v2/edit/membership/editor/zhTW";

export const Membership: {
    [key: string]: any
} = {

    tag: Tag,

    editor: Editor,

    field: {

        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            text: "同時搜尋名字/郵件/會友編號"
        },

        tag: {
            subject: "標籤",
            holder: "請選擇標籤"
        }

    },
    empty: {
        subject: "請選擇會友進行推播通知"
    },
    not: {
        found: {
            subject: "搜尋不到指定的推播通知會友"
        }
    }
}