import {Schedule} from "@/language/modules/component/form/v2/cycle/schedule/zhTW"

export const Cycle: {
    [key: string]: any
} = {

    schedule: Schedule,

    field: {
        cycle: {
            subject: "方式"
        },
        duration: {
            subject: "時長"
        }
    },
    at: {
        subject: "每",
        text: {
            1: "日",
            2: "週",
            3: "個月",
            4: "年"
        },
        forever: {
            subject: "永遠"
        },
        until: {
            subject: "直到"
        },
        repeat: {
            subject: "重複",
            text: "次"
        }
    },
    week: {
        begin: {
            subject: `首次起始日<br/>為星期%s不能取消`
        },
        day: [{
            subject: "日",
            text: "星期天"
        }, {
            subject: "一",
            text: "星期一"
        }, {
            subject: "二",
            text: "星期二"
        }, {
            subject: "三",
            text: "星期三"
        }, {
            subject: "四",
            text: "星期四"
        }, {
            subject: "五",
            text: "星期五"
        }, {
            subject: "六",
            text: "星期六"
        }]
    },
    method: {
        1: {
            subject: "在第%s天重複",
            year: {
                subject: "在 %s 的第%s天重複"
            }
        },
        2: {
            subject: "在第%s週的%s重複",
            year: {
                subject: "在 %s 的第%s週的%s重複"
            }
        },
        3: {
            subject: "在最後一個%s重複",
            year: {
                subject: "在 %s 的最後一個%s重複"
            }
        },
        4: {
            subject: "在最後一天重複",
            year: {
                subject: "在 %s 的最後一天重複"
            }
        },
        5: {
            subject: "選取希望重複的日期",
            year: {
                subject: "選取希望在%s號重複的月份",
            }
        }
    },
    month: [{
        subject: "1月",
    }, {
        subject: "2月",
    }, {
        subject: "3月",
    }, {
        subject: "4月",
    }, {
        subject: "5月",
    }, {
        subject: "6月",
    }, {
        subject: "7月",
    }, {
        subject: "8月",
    }, {
        subject: "9月",
    }, {
        subject: "10月",
    }, {
        subject: "11月",
    }, {
        subject: "12月",
    }],
    help: {
        duration: {
            subject: `目前最長可定義 %s 年週期, 請妥善利用資源`,
            until: {
                subject: `請選擇重複結束時間`
            }
        }
    },
    error: {
        1001: {
            subject: "請選擇條件"
        },
        1002: {
            subject: "請選取希望重複的日期"
        },
        1003: {
            subject: "請選取希望重複的月份"
        },
        1004: {
            subject: "請選擇重複結束時間"
        }
    }

}
