export default {
    //是否受洗
    baptized: [{
        value: 0,
        label: "否"
    }, {
        value: 1,
        label: "是"
    }]
}
