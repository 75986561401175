import {Automatic} from "@/language/modules/page/admin/notification/v2/edit/automatic/zhCN";
import {History} from "@/language/modules/page/admin/notification/v2/edit/history/zhCN";
import {Membership} from "@/language/modules/page/admin/notification/v2/edit/membership/zhCN";

export const Edit: {
    [key: string]: any
} = {

    membership: Membership,

    history: History,

    automatic: Automatic,

    tab: {
        0: {
            subject: "基本",
        },
        1: {
            subject: "名单"
        },
        98: {
            subject: "统计资讯",
        },
        99: {
            subject: "相关资讯",
        }
    },
    field: {
        type: {
            subject: "类型",
            holder: "请选择类型",
            help: "类型于建立之后不能变更"
        },
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        begin: {
            subject: "排程时间",
            holder: "请选择排程时间"
        },
        content: {
            subject: "简述",
            holder: "请输入简述"
        },
        description: {
            subject: "内容",
            holder: "请输入内容"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    statistics: {
        automatic: {
            subject: "自动化纪录"
        },
        history: {
            subject: "推播纪录"
        }
    },
    alert: {
        0: {
            subject: `推播目前正在排程中`
        },
        1: {
            subject: `目前推播正在进行中无法修改, 进度 %s`
        },
        2: {
            subject: "推播已经全部完成无法修改"
        }
    },
    handler: {
        submit: {
            subject: "即将更新内容",
            text: "请确认后执行"
        }
    },
    submit: {
        membership: {
            subject: "编辑名单"
        },
        cycle: {
            subject: "预览排程"
        }
    },
    error: {
        1010: {
            "subject": "请选择类型",
            "text": ""
        },
        1030: {
            "subject": "请选择排除机构",
            "text": ""
        },
        1031: {
            "subject": "不能排除所有机构",
            "text": ""
        },
        1040: {
            "subject": "请输入标题",
            "text": ""
        },
        1050: {
            "subject": "请选择开始时间",
            "text": ""
        },
        1051: {
            "subject": "排程时间必须大于现在时间",
            "text": ""
        },
        1060: {
            "subject": "请输入简述",
            "text": ""
        },
        1070: {
            "subject": "请输入内容",
            "text": ""
        },
        2010: {
            "subject": "请至少选择1位会友进行推播",
            "text": ""
        }
    }

}