import statistics from "@/language/modules/page/admin/dashboard/statistics/zhTW";

/**
 * 控制面板
 */
export default {
    help: {
        updated: "最後更新時間",
        original: "預設區間",
        loading: "正在計算統計數據..."
    },
    field: {
        church: "指定教會",
        begin: "開始時間",
        end: "結束時間",
        statistics: statistics
    },
    holder: {
        church: "請選擇指定教會"
    }
}
