export default {
    recipient: {
        complete: {
            subject: "已送達",
            text: "送達時間 %04d-%02d-%02d %02d:%02d"
        },
        read: {
            subject: "已讀取",
            yet: {
                subject: "未讀取",
            }
        },
        broadcast: {
            subject: "群體推播無法編輯名單"
        },
        move: {
            left: "雙擊加入",
            right: "雙擊移除"
        },
        subject: "編輯推播名單",
        disable: {
            available: "請選擇加入的項目",
            selected: "請選擇移除的項目",
        },
        changed: "尚未異動項目",
        empty: "請至少選擇<br />1位會友進行推播",
        not: {
            found: "無法搜尋到<br />您指定條件的會友"
        },
        help: {
            available: `可選擇 <b class="cgBase">%s</b> 個會友`,
            selected: `已選擇 <b class="cgBase">%s</b> 個會友`
        },
        th: [
            "頭像",
            "會友",
            "操作",
            "進度",
            "讀取"
        ],
        status: [
            "未讀",
            "已讀"
        ],
        field: {
            keyword: "搜尋名單",
            tag: "標籤"
        },
        holder: {
            keyword: "搜尋範圍: 名字/郵件/編號",
            tag: "請選擇標籤"
        },
        handle: {
            close: {
                subject: "即將關閉編輯推播",
                text: `<span class="text-danger">異動項目將會清除, 請確認後執行</span>`
            }
        },
        tag: {
            all: "全部",
            uncategorized: "未分類",
            administrator: "管理員名單"
        }
    },
    status: [
        null,
        "排程中",
        "進行中",
        "已完成"
    ],
    type: {
      1: "指定",
      2: "全體"
    },
    destroy: [
        "刪除",
        "推播進行中, 無法刪除",
        "推播已經完成, 無法刪除"
    ],
    exclude: {
        label: "排除機構",
        help: {
            subject: "選中的機構將會排除並不發送推播訊息",
            all: {
                subject: "將不排除任何機構"
            }
        }
    }
}
