import {v2} from "@/language/modules/page/admin/event/v2/zhTW"
import team from "@/language/modules/page/admin/event/team/zhTW"

/**
 * 行事曆管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 群組
     */
    team: team,

    /**
     * 列表
     */
    index: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
        },
        feature: {
            create: {
                subject: "新增服事"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            subject: "名稱",
            start: "開始時間",
            begin: {
                begin: "開始日期(起)",
                end: "開始日期(迄)"
            },
            end: {
                begin: "結束日期(起)",
                end: "結束日期(迄)"
            },
            create: {
                begin: "建立日期(起)",
                end: "建立日期(迄)"
            },
            quantity: "參與人數"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            subject: "請輸入名稱",
            begin: {
                begin: "請選擇開始日期(起)",
                end: "請選擇開始日期(迄)"
            },
            end: {
                begin: "請選擇結束日期(起)",
                end: "請選擇結束日期(迄)"
            },
            create: {
                begin: "請選擇建立日期(起)",
                end: "請選擇建立日期(迄)"
            },
        },
        handler: {
            enable: {
                subject: "即將啟用服事",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用服事",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除服事, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1 : {
                subject: "名單"
            },
            2: {
                subject: "提醒"
            }
        },
        field: {
            subject: "標題",
            begin: "開始時間",
            end: "結束時間",
            text: "說明",
            location: "地點",
            note: "備註",
            description: "其他資訊",
            keyword: "搜尋名單"
        },
        holder: {
            subject: "請輸入標題",
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            text: "請輸入說明",
            location: "請輸入地點",
            note: "請輸入備註",
            description: "請輸入其他資訊",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            store: {
                subject: "即將新增服事",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入說明",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請至少選擇1位人員參與服事",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這個服事已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名單"
            },
            2 : {
                subject: "提醒"
            },
            98: {
                subject: "循環資訊"
            },
            99: {
                subject: "相關資訊"
            }
        },
        detail: {
            cycle: {
                subject: "循環代號"
            },
            remind: {
                subject: "提醒代號"
            }
        },
        field: {
            belong: {
                no: "所屬教會ID",
                name: "所屬教會名稱"
            },
            subject: "標題",
            begin: "開始時間",
            end: "結束時間",
            text: "說明",
            location: "地點",
            note: "備註",
            description: "其他資訊",
            keyword: "搜尋名單"
        },
        holder: {
            head: "未輸入",
            belong: {
                no: "",
                name: ""
            },
            subject: "請輸入標題",
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            text: "請輸入說明",
            location: "請輸入地點",
            note: "請輸入備註",
            description: "請輸入其他資訊",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            update: {
                subject: "即將更新服事內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入說明",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "請至少選擇1位人員參與服事",
                "text": "",
                "description": ""
            }
        }
    }

}

