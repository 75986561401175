export default {
    friend: "新朋友數量",
    client: {
        total: "會友人數",
        track: "已跟進會友",
        stranger: "未跟進會友",
        archive: "不跟進會友"
    },
    team: "族群數量",
    attend: "聚會數量"
}
