/**
 * api - 傳輸層
 * 授權 - 權杖
 * @author J
 * @since 2024-04-30 07:34:25
 */

import http from "@/v2/api"
import {Basic} from "@/v2/api/interface";
import {AuthorizationSession} from "@/v2/api/interface/authorization/session";

/**
 * 基礎 - 路徑
 */
const baseUri: string = "/api/authorization/session"

/**
 * 取得
 */
export const getAuthorizationSession = () => {

    return http.get<Basic.Data<AuthorizationSession>>(
        `${baseUri}`
    )
}

/**
 * 銷毀
 */
export const updateAuthorizationSessionDestroy = () => {
    return http.delete<Basic.Result>(
        `${baseUri}/destroy`,
        {},
        {
            opts: {
                default: {
                    failure: false
                }
            }
        }
    )
}