import http from "@/api";
import {Caption} from "@/api/interface/caption";
import {Respond} from "@/api/interface/respond";

/**
 * 預設標題
 */
export const getCaption = () => {
    return http.get<Respond.Data<Caption.Result>>(`/api/caption`, {} , {
        opts: {
            builtIn: {
                loadingMask: false
            }
        }
    });
};
