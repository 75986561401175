import {Editor} from "@/language/modules/component/form/v2/membership/editor/zhTW"

export const Membership: {
    [key: string]: any
} = {

    editor: Editor,

    option: {
        all: {
            subject: "全部"
        },
    },
    th: [
        "頭像",
        "會友",
        "操作"
    ]

}