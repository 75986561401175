import {RouteRecordRaw} from "vue-router";
import {EnumPageDisplay} from "@/enum/page";

const personal: RouteRecordRaw[] = [{
	//個人帳號管理
	path: "churches/users/personal",
	name: "AdminPersonal",
	meta: {
		//不受權限管制的頁面
		permission: EnumPageDisplay.NO_RESTRICTION,
		breadcrumb: [{
			key: 'admin.personal.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/personal/edit.vue"),
}];

export default personal;
