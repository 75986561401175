export const History: {
    [key: string]: any
} = {

    field: {
        name: {
            subject: "族群名稱"
        },
        begin: {
            subject: "開始時間"
        },
        end: {
            subject: "結束時間"
        },
        status: {
            subject: "狀態",
            value: {
                0: {
                    subject: "未開始",
                },
                1: {
                    subject: "進行中"
                },
                2: {
                    subject: "已結束"
                },
                3: {
                    subject: "無聚會"
                }
            }
        },
        created: {
            subject: "建立時間"
        }
    },
    search: {
        name: {
            subject: "群族名稱",
            holder: "請輸入群族名稱"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    }

}
