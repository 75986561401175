import {	defineStore} from "pinia";
import {	MenuState} from "@/store/interface/menu";
import piniaPersistConfig from "@/config/piniaPersist";

const id: string = "MenuState";

/**
 * 選單相關store
 */
export const MenuStore = defineStore({
	id: id,
	state: (): MenuState => ({
		collapsed: false,
		overlay: false
	}),
	getters: {
		isCollapsed: state => {
			return state.collapsed
		},
		isOverlay: state => {
			return state.overlay
		}
	},
	actions: {
		setCollapsed(collapsed: boolean) {
			this.collapsed = collapsed;
		},
		setOverlay(overlay: boolean) {
			this.overlay = overlay;
		}
	},
	persist: piniaPersistConfig(id)
});
