export const Group: {
    [key: string]: any
} = {
    selected: {
        subject: "已选择族群",
        empty: {
            subject: "请选择族群"
        },
        remove: {
            subject: "双击标签快速移除",
            all: {
                subject: "清空",
            }
        }
    },
    handler: {
        clear: {
            subject: "即将清空已选择族群",
            text: "请确认后执行",
        }
    }
}
