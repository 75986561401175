/**
 * 客服管理 - 帳號紀錄
 */
export default {

    /**
     * 列表
     */
    index: {
        tabs: [{
            subject: "查詢"
        }],
        help: {
            query: "請輸入完整的電子郵件",
            disabled: "會友已經停用",
            destroy: "會友已於 <b>%s</b> 自行刪除",
            church: {
                disabled: "教會已經停用",
                destroy: "教會已經刪除",
                organization: {
                    disabled: "機構已經停用",
                    destroy: "機構已經刪除",
                }
            }
        },
        notification: {
            status: {
                waiting: "排程中",
                progress: "發送中",
                complete: "已送達"
            }
        },
        result: {
            success: '查詢到 <b class="ft-18 text-success">%s</b> 筆紀錄',
            empty: "查詢不到任何結果"
        },
        field: {
            notification: {
                subject: '最後 <b class="text-success ft-18">10</b> 筆推播通知',
                text: '(依照預定推播時間降冪排序)',
                empty: "沒有推播通知紀錄",
                field: {
                    id: "推播ID",
                    schedule: "排定發送時間",
                    delivery: "送達時間"
                }
            },
            device: {
                supplier: "裝置支援商",
                brand: "裝置製造商",
                model: "裝置型號",
                os: "裝置操作系統",
                version: "裝置App版本",
                token: {
                    subject: "登入權杖",
                    text: "(sys_token.token)",
                    push: {
                        subject: "推播識別碼",
                        text: "(core_admin_client_notification.key)",
                    }
                },
            },
            belong: {
                organization: {
                    id: "所屬機構ID",
                    name: "所屬機構名稱",
                },
                id: "所屬教會ID",
                no: "所屬教會編號",
                name: "所屬教會名稱",
            },
            id: "會友ID",
            no: "會友編號",
            name: "會友姓名",
            account: "會友帳號",
            email: "電子郵件",
            avatar: "頭像",
            status: "狀態",
            created: "建立時間",
            updated: "更新時間"
        },
        holder: {
            email: "請輸入完整的電子郵件進行查詢"
        },
        error: {
            1001: {
                "subject": "請輸入電子郵件",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "電子郵件格式錯誤",
                "text": "請輸入正確的電子郵件格式",
                "description": ""
            }
        }
    }

}
