export default {
    help: {
        close: "关闭",
        reset: "重设"
    },
    tool: {
        illegal: "举报图片",
        refresh: "重设编辑",
        prev: {
            has: "上一张",
            no: "没有上一张了"
        },
        next: {
            has: "下一张",
            no: "没有下一张了"
        },
        cancel: "取消编辑"
    },
    feature: {
        text: "编辑文字",
        size: "文字大小",
        color: "文字颜色",
        position: "文字位置",
        vertical: "上下微调",
        horizontal: "左右微调"
    },
    position: [
        "左上",
        "右上",
        "置中",
        "左下",
        "右下"
    ],
    field: {
        text: {
            subject: "填充文字"
        },
        size: {
            subject: "文字大小"
        },
        color: {
            subject: "文字颜色"
        },
        position: {
            subject: "文字位置"
        },
        vertical: {
            subject: "垂直微调"
        },
        horizontal: {
            subject: "水平微调"
        }
    },
    holder: {
        text: {
            subject: "请输入填充文字"
        },
        size: {
            subject: "请选择文字大小"
        },
        color: {
            subject: "请选择文字颜色"
        },
        position: {
            subject: "请选择文字位置"
        },
        vertical: {
            subject: "请选择垂直微调"
        },
        horizontal: {
            subject: "请选择水平微调"
        }
    }
}
