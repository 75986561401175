/**
 * App下载画面
 */
export default {
    subject: "领袖的好帮手, 会友的好工具",
    text: "一个专属教会的APP, 是教会的媒体与资讯发布工具",
    description: "教会信息、活动不漏接, 随时随地跟著读经、祷告",
    button: {
        apple: "Apple用户端",
        google: "Android用户端",
    }
}
