import {Create} from "@/language/modules/page/shepherd/attend/v2/create/zhCN"
import {Edit} from "@/language/modules/page/shepherd/attend/v2/edit/zhCN"

export const v2: {
    [key: string]: any
} = {

    /**
     * 创建
     */
    create: Create,

    /**
     * 编辑
     */
    edit: Edit,

    field: {
        name: {
            subject: "名称",
        },
        summary: {
            team: {
                subject: "族群数量"
            }
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "已停用",
                },
                1: {
                    subject: "启用中"
                }
            }
        },
        begin: {
            subject: "开始时间"
        },
        created: {
            subject: "建立时间"
        },
    },
    more: {
        history: {
            subject: "聚会纪录"
        },
        schedule: {
            subject: "聚会排程"
        }
    },
    search: {
        category: {
            subject: "分类",
            holder: "请选择分类",
            help: "请先选择所属教会"
        },
        name: {
            subject: "名称",
            holder: "请输入名称"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    handler: {
        enable: {
            subject: "即将启用聚会",
            text: `请确认后执行`,
        },
        disable: {
            subject: "即将停用聚会",
            text: `请确认后执行`,
        },
        destroy: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将删除聚会, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }

}
