/**
 * 聚会 - 历程
 */
export default {
    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教会"
            },
            begin: "开始时间",
            end: "结束时间"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            }
        }
    },
    edit: {
        help: {
            remind: {
                subject: "请参考提醒设置值",
            }
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相关资讯"
            }
        },
        boolean: {
            false: {
                subject: "否"
            },
            true: {
                subject: "是"
            }
        },
        remind: {
            0: {
                subject: "不发送",
                text: "不发送"
            },
            1: {
                subject: "等待发送",
                text: "正在准备发送"
            },
            2: {
                subject: "发送完成"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            created: "建立时间",
            begin: "开始时间",
            end: "结束时间",
            stamp: "预定生成时间",
            completed: "实际生成时间",
            beforehand: "预设提前生成",
            remind: {
                notification: {
                    status: "推送状态",
                    stamp: "推送完成时间"
                },
                email: {
                    status: "寄送邮件状态",
                    stamp: "寄送邮件完成时间"
                }
            },
            status: "状态"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            begin: "请选择开始时间",
            end: "请选择结束时间",
            stamp: "请选择预定生成时间",
            status: "请选择状态"
        }

    }
}