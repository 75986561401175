export default {
    session: {
        repair: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将进行重建模拟, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        },
        simulation: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将进行模拟登入, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        },
        head: {
            subject: "即将返回管理介面",
            text: "",
            description: ""
        },
        destroy: {
            subject: "即将注销系统",
            text: "请确认注销",
            description: ""
        }
    }
}
