import {RouteRecordRaw} from "vue-router";
import {EnumPageDisplay} from "@/enum/page";

const billboard: RouteRecordRaw[] = [{
	//公吿欄
	path: "billboard",
	name: "Billboard",
	meta: {
		//不受權限管制的頁面
		permission: EnumPageDisplay.NO_RESTRICTION,
		breadcrumb: [{
			key: 'billboard.index',
			value: null
		}]
	},
	component: () => import("@/page/content/billboard/index.vue"),
}, {
	//公吿欄 - 內容
	path: "billboard/content/:uuid",
	name: "BillboardEdit",
	meta: {
		//不受權限管制的頁面
		permission: EnumPageDisplay.NO_RESTRICTION,
		breadcrumb: [{
			key: 'billboard.index',
			value: "Billboard"
		}, {
			key: 'billboard.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/billboard/edit.vue"),
}];

export default billboard;
