<template>
  <li class="nav-item d-lg-block theme">
    <el-dropdown ref="menu"
                 v-if="getUserBillboard.summary.message.length > 0"
                 placement="bottom-end">
      <a class="ks d-flex align-items-center justify-content-center">
        <icon-sax
            class="ft-22 m-r-5 text-secondary"
            type="briefcase" />
        <div v-if="getUnread > 0" class="ty">
          <span v-html="getUnread" />
        </div>
      </a>
      <template #dropdown>
        <el-dropdown-menu class="billboard">
          <el-dropdown-item class="fg d-flex d-flex-column">
            <small class="w-100 ft-14 text-secondary d-inline-block"
                   v-html="$t(`component.menu.horizontal.billboard.subject`)" />
          </el-dropdown-item>
          <el-dropdown-item class="divider" />
          <el-dropdown-item class="py"
                            @click="onExit"
                            v-for="j in getUserBillboard.summary.message">
            <router-link :to="{name: `BillboardEdit`, params: {uuid: j.uuid}}"
                         class="billboard w-100 d-flex justify-content-start align-items-center">
              <div class="w-100 d-flex justify-content-between align-items-start">
                <j-avatar
                    :type="EnumAvatarBillboard.MENU"
                    :url="j.avatarUrl" />
                <div class="content-data">
                  <p class="ft-14 m-b-3 text-dark"
                     v-html="j.subject" />
                  <p class="h p-clear ft-12 m-b-3 text-muted"
                     v-html="j.content" />
                  <p class="ft-12 m-b-0 text-muted"
                     v-html="getPrevious(j.timestamp, EnumMomentFormat.DATETIME_WITHOUT_SECOND)" />
                </div>
              </div>
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item @click="onExit"
                            class="fg sss d-flex d-flex-column">
            <router-link :to="{name: `Billboard`}"
                         class="ks w-100 d-flex align-items-center justify-content-center">
              <div v-html="sprintf($t(`component.menu.horizontal.billboard.unread`), getUnread)"
                   class="w-100 text-secondary ft-12 m-t-5 text-center" />
            </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-tooltip
        v-else
        class="box-item"
        :content="$t(`component.menu.horizontal.billboard.subject`)"
        placement="bottom-end">
      <router-link :to="{name: `Billboard`}"
                   class="ks d-flex align-items-center justify-content-center">
        <icon-sax
            class="ft-22 m-r-5 text-secondary"
            type="briefcase" />
      </router-link>
    </el-tooltip>
  </li>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"
import JAvatar from "@/component/Avatar.vue"

import {sprintf} from "sprintf-js"
import {computed, ref} from "vue"
import {useUserBillboard} from "@/hook/useUserBillboard"
import {useDate} from "@/hook/useDate"
import {EnumAvatarBillboard} from "@/enum/avatar";
import {EnumMomentFormat} from "@/enum/moment";

const {
  getUserBillboard
} = useUserBillboard()

const {
  getPrevious
} = useDate()

const menu = ref()

//獲取獲取未讀取數量
const getUnread = computed((): number =>{
  return getUserBillboard.value.summary.unread
})

//點擊關閉
const onExit = () => menu.value.handleClose()

</script>

<style scoped lang="sass">
.ks
  position: relative
  .ty
    width: 23px
    position: absolute
    right: -6px
    top: -7px
    display: flex
    align-items: center
    justify-items: start
    padding-left: 3px
    > span
      line-height: 12px
      background-color: #F00
      color: #FFF
      font-size: .9rem
      padding: .2rem .3rem
      border-radius: .5rem
      text-align: center

.el-dropdown-menu__item
  &.fg
    padding: 10px 16px !important
  > a
    &.billboard
      margin: 1rem !important
      .content-data
        margin-left: 1.75rem
        width: 100%
        > p
          width: 180px
          overflow : hidden
          text-overflow : ellipsis
          white-space : nowrap
          &.h
            height: 22px
            overflow: hidden
            margin-top: 5px
</style>
