export const History: {
    [key: string]: any
} = {
    action: {
        add: {
            subject: "新增",
            not: {
                allowed: {
                    subject: "無新增權限"
                }
            }
        },
        search: {
            subject: "搜尋"
        }
    },
    footer: {
        subject: `已載入 <b class="text-justforyou-base ft-16">%s</b> 筆數據 共 <b class="text-justforyou-base ft-16">%s</b> 筆數據`,
        complete: {
            subject: "載入完成"
        }
    },
    readonly: {
        subject: "列表僅供讀取"
    },
    empty: {
        subject: "找不到指定的資料"
    },
    off: {
        canvas: {
            statistics: {
                subject: "統計"
            },
            search: {
                subject: "搜尋"
            }
        }
    }
}
