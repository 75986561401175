import {v2} from "@/language/modules/component/qrcode/v2/zhTW"

export default {

    v2: v2,

    login: {
        subject: "請使用%sApp掃碼登入",
        text: "前往下載%sApp"
    },
    mapping: {
        subject: "請使用%sApp掃碼進行綁定",
        text: "前往下載%sApp"
    },
    status: {
        authorize: {
            subject: "授權成功",
            text: "正在準備完成"
        },
        inspection: {
            subject: "驗證成功",
            text: "請在App中進行確認"
        },
        expire: {
            subject: "二維碼超時",
            text: "重新產生二維碼"
        }
    }
}
