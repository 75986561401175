import {Field} from "@/api/interface/field";
import {nextTick} from "vue";
import moment from "moment-timezone";
import _ from "lodash";

/**
 * 工具
 */
export const useUtils = () => {

    /**
     *  產生隨機碼
     *  @param length 產生長度
     *  @param hash true產生複雜UUID
     */
    const getUuid = (
        length = 32,
        hash = false
    ) => {
        //容易混淆的字符oOLl,9gq,Vv,Uu,I1
        const chars = hash ?
            '0123456789abcdefghijklmnoposrtuvwxyzABCDEFGHIJKLMNOPOSRTUVWXYZ_-':
            'abcdef0123456789';
        const maxPos = chars.length;
        let pwd = '';
        for (let i = 0; i < length; i++) pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        return pwd;
    }

    /**
     * 格式化數字, 帶千位符號
     * @param value 傳入值
     */
    const getNumberFormat = (
        value: number
    ) => {
        const formatter = new Intl.NumberFormat()
        return formatter.format(value)
    }

    /**
     * 錯誤代碼變化翻tab
     *
     */
    const getErrorTab = (
        code: number
    ): number => {
        if (code<2000) return 0
        else if (code<3000) return 1
        else if (code<4000) return 2
        else if (code<5000) return 3
        else if (code<6000) return 4
        else if (code<7000) return 5
        else if (code<8000) return 6
        else if (code<9000) return 7
        else if (code<10000) return 8
        return 0
    }

    /**
     * 下載檔案
     * @param name 檔案名稱
     * @param blob excel的blob (base64)
     */
    const getDownloadSave = async (
        name: string,
        blob: string
    ) => {

        const base64ToBlob = async (base64, type = 'application/octet-stream') =>
            fetch(`data:${type};base64,${base64}`).then(res => res.blob())

        const data = await base64ToBlob(
            blob,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream'
        )

        const link = document.createElement('a')
        link.download = decodeURIComponent(name+"_"+getUuid(16))
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href) // 释放URL 对象
        document.body.removeChild(link)

    }

    /**
     * 返回排序格式
     */
    const getSort = (
        field: Field[]
    ): any => {

        //處理排序欄位
        const sort: any = {}
        field.filter((i: Field)=>i.sortable===true).forEach((i: Field)=>{
            const id = i.id.split(".").map((j,i)=> j.split("").map((v,k)=>{ return k===0 && i > 0 ? v.toUpperCase() : v }).join(""))
            return sort[id.join("")] = i.order
        })

        return sort

    }

    /**
     * js版本 getOuterHeight
     */
    const getHeight = (
        dom: HTMLElement | null | undefined
    ): number => {
        return dom?.getBoundingClientRect().height ?? 0
    }

    /**
     * 自動focus第一個input
     */
    const setFirstFocus = async (
        target?: HTMLElement
    ) => {

        await nextTick()

        const e = target ? target : document.querySelector("body")
        setTimeout(() => {
            const r: HTMLElement | null = e!.querySelector(`input.el-input__inner`)
            //且第一個物件不能是下拉選單(防只form-select有下filterable, 直接就展開了)
            if (r && r!.closest(".el-select") === null) r!.focus()
        }, 100)

    }

    /**
     * 監控 - 日期選項 - 起迄相反互換
     * @param object 監控值
     * @param begin 鍵名稱 - 開始
     * @param end 鍵名稱 - 結束
     */
    const setDateBetween = (
        object: any,
        begin: string = 'begin',
        end: string = 'end'
    ) => {
        //如果監控值空白, 則不處理
        if (!object) return false
        //如果傳入key名稱都不存在(或者其中一個值為空), 則不處理
        if (!object[begin] || !object[end]) return false
        //互換起訖
        else if (object[end] < object[begin]) [object[end], object[begin]] = [object[begin], object[end]]
    }

    /**
     * 是否為完整html空白
     * @param html html字串
     */
    const isHtmlBlank = (
        html: string | null
    ): boolean => {

        if (html === "") return true
        else if (html === null) return true
        const tmp = document.createElement("DIV")
        tmp.innerHTML = html as string
        const d = tmp.textContent?.replace(/\s+/, '') ||
            tmp.innerText?.replace(/\s+/, '') ||
            ""
        return d.length === 0
    }

    /**
     * 驗證電子郵件格式
     * @param e true: 正確, false: 錯誤
     */
    const isValidEmail = (e: string | null): boolean => {
        if (!e) return false
        return new RegExp(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z]+$/).test(e)
    }

    /**
     * 下載圖片
     * @param name 檔案名稱
     * @param url 圖片網址
     */
    const getDownloadImage = async (
        name: string,
        url: string
    ) => {

        const xhr = new XMLHttpRequest();

        xhr.onload = () => {

            const reader = new FileReader()
            reader.onloadend = () => {

                const linkSource = reader.result! as string
                const downloadLink = document.createElement('a')
                document.body.appendChild(downloadLink)
                downloadLink.href = linkSource
                downloadLink.download = `${name}_${ new Date().getTime() }.png`
                downloadLink.click()
                downloadLink.remove()

            }

            reader.readAsDataURL(xhr.response)

        }

        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();

    }

    /**
     * 組合/最佳化 payload
     * @param e 來源
     * @param d 合併值
     * @param field time 修正時間, ignore 忽略的key
     */
    const getMinify = (
        e: {
            [key: string]: any
        },
        d?: {
            [key: string]: any
        },
        field?: {
            time?: string[],
            ignore?: string[]
        }
    ) => {

        /**
         * 內建忽略欄位
         */
        const ignoreField: string[] = [
            "creator",
            "editor"
        ]

        //時間補正
        const parseTime = (e: any) => {

            if (e === undefined) return e

            //將字串轉換為moment格式
            const m: moment.Moment = moment(e, moment.ISO_8601)

            //處理時間偏移量 (傳下來時間都是GTM+8, 去掉+8, 再看用戶瀏覽器時區值進行調整)
            //先歸0再+8:00
            m.add((new Date().getTimezoneOffset() * 60) + 28800, 'seconds');

            //如果是日期, 返回日期即可
            if (moment(e, "YYYY-MM-DD", true).isValid()) return m.format("YYYY-MM-DD");
            //如果是不帶秒, 補秒上去
            else if (moment(e, "YYYY-MM-DD HH:mm", true).isValid()) return m.format("YYYY-MM-DD HH:mm:00");

            //預設返回
            return m.format("YYYY-MM-DD HH:mm:ss");

        }

        //合併結果
        const result: {
            [key: string]: any
        } = _.merge(
            e,
            d ?? {}
        )

        //時間補正
        if (field?.time) field.time.every(i => {

            let resultValue: any

            //判斷是否為 nested宣告
            const q: string[] = i.split(".")

            //nested宣告, 紀錄當下object值
            if (q.length > 1) {
                const r: any = q.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, result as any)
                resultValue = q.reduceRight((acc, key) => ({ [key]: acc }), parseTime(r) as any)
            }
            //鍵/值宣告
            else resultValue = parseTime(result[i])

            //取出值錯誤, 不往下進行, (every返回false, 等同continue)
            if (resultValue === undefined) return false

            //置入正確的排序值
            if (typeof resultValue === "object") _.merge(result, resultValue)
            else result[i] = resultValue

            //完成 (every返回true, 等同繼續進行)
            return true

        })

        //忽略的key
        return _.omit(
            result,
            ignoreField.concat(field?.ignore ?? [])
        )

    }

    return {
        isHtmlBlank,
        isValidEmail,
        getUuid,
        getSort,
        getHeight,
        getNumberFormat,
        getErrorTab,
        getDownloadSave,
        setFirstFocus,
        setDateBetween,
        getDownloadImage,
        getMinify
    }

}
