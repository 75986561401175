import home from "@/language/modules/nav/admin/home/zhTW";
import user from "@/language/modules/nav/admin/user/zhTW";
import event from "@/language/modules/nav/admin/event/zhTW";
import church from "@/language/modules/nav/admin/church/zhTW";
import client from "@/language/modules/nav/admin/client/zhTW";
import my from "@/language/modules/nav/admin/my/zhTW";
import live from "@/language/modules/nav/admin/live/zhTW";
import activity from "@/language/modules/nav/admin/activity/zhTW";
import course from "@/language/modules/nav/admin/course/zhTW";
import support from "@/language/modules/nav/admin/support/zhTW";
import question from "@/language/modules/nav/admin/question/zhTW";
import teach from "@/language/modules/nav/admin/teach/zhTW";
import cycle from "@/language/modules/nav/admin/cycle/zhTW";
import donate from "@/language/modules/nav/admin/donate/zhTW";
import soul from "@/language/modules/nav/admin/soul/zhTW";
import daily from "@/language/modules/nav/admin/daily/zhTW";
import gallery from "@/language/modules/nav/admin/gallery/zhTW";
import notification from "@/language/modules/nav/admin/notification/zhTW";
import billboard from "@/language/modules/nav/admin/billboard/zhTW";
import personal from "@/language/modules/nav/admin/personal/zhTW";

/**
 * 我的教會
 */
export default {

    /**
     * 首頁
     */
    home: home,

    /**
     * 公告欄
     */
    billboard: billboard,

    /**
     * 管理員管理
     */
    user: user,

    /**
     * 教會管理
     */
    church: church,

    /**
     * 會友管理
     */
    client: client,

    /**
     * 我的教會文章管理
     */
    my: my,

    /**
     * 每日靈糧文章管理
     */
    daily: daily,

    /**
     * 心靈捕手文章管理
     */
    soul: soul,

    /**
     * 圖庫管理
     */
    gallery: gallery,

    /**
     * 推播管理
     */
    notification: notification,

    /**
     * 行事曆管理
     */
    event: event,

    /**
     * 奉獻管理
     */
    donate: donate,

    /**
     * 循環管理
     */
    cycle: cycle,

    /**
     * 教學管理
     */
    teach: teach,

    /**
     * 問題集管理
     */
    question: question,

    /**
     * 客服管理
     */
    support: support,

    /**
     * 歷程管理
     */
    course: course,

    /**
     * 活動管理
     */
    activity: activity,

    /**
     * 直播管理
     */
    live: live,

    /**
     * 個人資訊
     */
    personal: personal

}
