import {NavigationItemRaw} from '@/navigation/interface'

const church: NavigationItemRaw[] = [{
    id: 3,
    right: true,
    subject: 'admin.church.index',
    route: 'AdminChurch',
    icon: 'home',
    children: [],
    attached: [
        'AdminChurchCreate',
        'AdminChurchEdit'
    ]
}]

export default church
