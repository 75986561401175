export default {
    belong: {
        no: {
            subject: "所属教会编号"
        },
        name: {
            subject: "所属教会名称"
        }
    },
    delegate: {
        no: {
            subject: "执行教会编号"
        },
        name: {
            subject: "执行教会名称"
        }
    }
}
