import {History} from "@/language/modules/page/admin/support/v2/worker/edit/history/zhCN"

export const Edit: {
    [key: string]: any
} = {

    /**
     * 历史纪录
     */
    history: History,

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "帐户"
        },
        2: {
            subject: "附件"
        },
        98: {
            subject: "统计资讯",
        },
        99: {
            subject: "相关资讯",
        }
    },
    action: {
        history: {
            create: {
                subject: "创建纪录"
            }
        }
    },
    field: {
        no: {
            subject: "单号",
            holder: ""
        },
        sample: {
            subject: "样本ID",
            holder: "请输入样本ID"
        },
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        role: {
            subject: "角色",
            holder: "请选择角色"
        },
        type: {
            subject: "类型",
            holder: "请选择类型"
        }
    },
    statistics: {
        history: {
            subject: "历史纪录"
        },
        record: {
            subject: "异动纪录"
        }
    },
    alert: {
        readonly: {
            subject: "工单已经标示完成无法进行修改"
        }
    },
    handler: {
        submit: {
            subject: "即将更新工单",
            text: "请确认后执行"
        }
    },
    error: {
        1010: {
            "subject": "请选择角色",
            "text": ""
        },
        1020: {
            "subject": "请输入样本ID",
            "text": ""
        },
        1021: {
            "subject": "样本ID请输入数字",
            "text": ""
        },
        1030: {
            "subject": "请选择类型",
            "text": ""
        },
        1040: {
            "subject": "请输入标题",
            "text": ""
        },
        1050: {
            "subject": "请输入描述",
            "text": ""
        }
    }

}
