export default {
    //分類
    category: [{
        "label": "全部",
        "value": 0,
    }, {
        "label": "基督信仰",
        "value": 1,
    }, {
        "label": "自然生態",
        "value": 2,
    }, {
        "label": "日常生活",
        "value": 3,
    }, {
        "label": "紋理圖案",
        "value": 4,
    }, {
        "label": "人事物景",
        "value": 5,
    }, {
        "label": "藝術色彩",
        "value": 6,
    }, {
        "label": "健康食物",
        "value": 7,
    }, {
        "label": "動物植物",
        "value": 8,
    }, {
        "label": "街頭攝影",
        "value": 9,
    }],
    //狀態
    status: [{
        value: 0,
        label: "未核准"
    }, {
        value: 1,
        label: "已核准"
    }, {
        value: 2,
        label: "已拒絕"
    }]
}
