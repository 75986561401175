import category from "@/language/modules/page/admin/activity/category/zhTW";
import register from "@/language/modules/page/admin/activity/register/zhTW";
import registered from "@/language/modules/page/admin/activity/registered/zhTW";

/**
 * 活動管理
 */
export default {

    /**
     * 分類
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增活動"
            },
        },
        field: {
            head: {
                church: "指定教會"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            subject: "標題",
            avatar: "封面圖",
            quantity: "活動數量",
            category: "分類",
            integrate: "文章整合",
            registered: {
                subject: "已報名",
                text: "含未付費"
            },
            sort: "排序",
            created: "建立時間"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            begin: "請選擇建立日期(起)",
            end: "請選擇建立日期(迄)",
            integrate: "請選擇文章整合",
            subject: "請輸入名稱",
            category: "請選擇分類",
        },
        handler: {
            enable: {
                subject: "即將啟用活動",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用活動",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除活動, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tip: {
            signature: "活動建立人員將會自動擁有本活動App簽到功能, 可不指定其他協助簽到人員"
        },
        help: {
            church: "請謹慎使用指定教會功能",
            discount: {
                enable: "已經開啟報名優惠功能, 無法修改日期"
            }
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "報名"
            },
            2: {
                subject: "場次"
            },
            3: {
                subject: "回條"
            },
            4: {
                subject: "欄位"
            },
            5: {
                subject: "提醒"
            },
            6: {
                subject: "文章"
            },
            7: {
                subject: "圖片"
            },
            8: {
                subject: "分享"
            },
            10: {
                subject: "簽到"
            }
        },
        field: {
            keyword: "搜尋名單",
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            church: "指定教會",
            category: "分類",
            subject: "標題",
            activity: {
                on: "活動開始時間",
                off: "活動結束時間"
            },
            respond: {
                avatar: {
                    subject: "封面圖",
                    text: "(尺寸為 %sx%s 像素)",
                },
                description: "描述"
            },
            collection: "文章圖片",
            description: "內容",
            status: "狀態"
        },
        holder: {
            keyword: "搜尋範圍: 名字/郵件/會友編號",
            kind: "請選擇類型",
            church: "請選擇指定教會",
            category: "請選擇分類",
            subject: "請輸入名稱",
            activity: {
                on: "請輸入活動開始時間",
                off: "請輸入活動結束時間"
            },
            respond: {
                description: "請輸入內容"
            },
            collection: "",
            description: "請輸入描述",
            status: "請選擇狀態"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            store: {
                subject: "即將新增活動",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入描述",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            },
            4002: {
                "subject": "請輸入回條描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {

        /**
         * 後台報名
         */
        register: register,

        /**
         * 已報名
         */
        registered: registered,

        tip: {
            signature: "活動建立人員將會自動擁有本活動App簽到功能, 可不指定其他協助簽到人員",
            disabled: "請注意, 這個活動已經停用",
            backend: "請注意, 總教會登入無法使用後台報名",
            full: "請注意, 這個活動報名已經額滿",
            summary: `請注意, 這個活動已經有 <b class="ft-15">%s</b> 名會友已付費報名, 部分欄位<b>無法修改</b>, 請注意`
        },
        help: {
            discount: {
                enable: "已經開啟報名優惠功能, 無法修改日期"
            },
            activity: {
                register: {
                    expire: {
                        subject: "活動已經開始, 報名截止"
                    }
                }
            }
        },
        feature: {
            0: "下載二維碼",
            1: "後台報名",
            2: "報到工具",
            3: "顯示二維碼",
            4: "複製報名網址",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "報名"
            },
            2: {
                subject: "場次"
            },
            3: {
                subject: "回條"
            },
            4: {
                subject: "欄位"
            },
            5: {
                subject: "提醒"
            },
            6: {
                subject: "文章"
            },
            7: {
                subject: "圖片"
            },
            8: {
                subject: "分享"
            },
            9: {
                subject: "報名資訊"
            },
            10: {
                subject: "簽到"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            url: {
                subject: "報名網址"
            },
            register: {
                subject: "後台報名"
            },
            summary: {
                subject: "報名清單",
                text: "含未付費"
            }
        },
        field: {
            keyword: "搜尋名單",
            belong: {
                no: "所屬教會編號",
                name: "所屬教會名稱"
            },
            kind: "類型",
            avatar: {
                subject: "封面圖",
                text: "(尺寸為 %sx%s 像素)",
            },
            category: "分類",
            subject: "標題",
            activity: {
                on: "活動開始時間",
                off: "活動結束時間"
            },
            respond: {
                avatar: {
                    subject: "封面圖",
                    text: "(尺寸為 %sx%s 像素)",
                },
                description: "描述"
            },
            collection: "文章圖片",
            description: "內容",
            status: "狀態"
        },
        holder: {
            keyword: "搜尋範圍: 名字/郵件/會友編號",
            belong: {
                no: "",
                name: ""
            },
            kind: "請選擇類型",
            category: "請選擇分類",
            subject: "請輸入名稱",
            activity: {
                on: "請輸入活動開始時間",
                off: "請輸入活動結束時間"
            },
            respond: {
                description: "請輸入內容"
            },
            collection: "",
            description: "請輸入描述",
            status: "請選擇狀態"
        },
        handler: {
            clear: {
                all: {
                    subject: "即將清除所有文章圖片",
                    text: "請確認後執行",
                    description: ""
                }
            },
            update: {
                subject: "即將更新活動內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇封面圖",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請輸入描述",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "請選擇指定教會",
                "text": "",
                "description": ""
            },
            4002: {
                "subject": "請輸入回條描述",
                "text": "",
                "description": ""
            },
            9001: {
                "subject": "請輸入分享描述",
                "text": "",
                "description": ""
            }
        }
    }

}
