/**
 * 活动管理
 */
export default {
    subject: "活动管理",
    index: "活动清单",
    create: "活动创建",
    edit: {
        index: "活动内容",
        register: {
            index: "后台报名"
        },
        registered: {
            index: "报名清单",
            edit: {
                index: "报名内容"
            }
        }
    },
    permission: "活动管理 - 活动清单",
    category: {
        index: "分类管理",
        create: "分类创建",
        edit: "分类内容",
        permission: "活动管理 - 分类管理",
    }
}
