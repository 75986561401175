import {v2} from "@/language/modules/page/admin/event/v2/zhCN"
import team from "@/language/modules/page/admin/event/team/zhCN"

/**
 * 行事历管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 群组
     */
    team: team,

    /**
     * 列表
     */
    index: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
        },
        feature: {
            create: {
                subject: "创建服事"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            subject: "名称",
            start: "开始时间",
            begin: {
                begin: "开始日期(起)",
                end: "开始日期(迄)"
            },
            end: {
                begin: "结束日期(起)",
                end: "结束日期(迄)"
            },
            create: {
                begin: "建立日期(起)",
                end: "建立日期(迄)"
            },
            quantity: "参与人数"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            subject: "请输入名称",
            begin: {
                begin: "请选择开始日期(起)",
                end: "请选择开始日期(迄)"
            },
            end: {
                begin: "请选择结束日期(起)",
                end: "请选择结束日期(迄)"
            },
            create: {
                begin: "请选择建立日期(起)",
                end: "请选择建立日期(迄)"
            },
        },
        handler: {
            enable: {
                subject: "即将启用服事",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用服事",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除服事, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1 : {
                subject: "名单"
            },
            2: {
                subject: "提醒"
            }
        },
        field: {
            subject: "标题",
            begin: "开始时间",
            end: "结束时间",
            text: "说明",
            location: "地点",
            note: "备注",
            description: "其他资讯",
            keyword: "搜索名单"
        },
        holder: {
            subject: "请输入标题",
            begin: "请选择开始时间",
            end: "请选择结束时间",
            text: "请输入说明",
            location: "请输入地点",
            note: "请输入备注",
            description: "请输入其他资讯",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            store: {
                subject: "即将创建服事",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入说明",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请至少选择1位人员参与服事",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
            disabled: "请注意, 这个服事已经停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "名单"
            },
            2 : {
                subject: "提醒"
            },
            98: {
                subject: "循环资讯"
            },
            99: {
                subject: "相关资讯"
            }
        },
        detail: {
            cycle: {
                subject: "循环代号"
            },
            remind: {
                subject: "提醒代号"
            }
        },
        field: {
            belong: {
                no: "所属教会ID",
                name: "所属教会名称"
            },
            subject: "标题",
            begin: "开始时间",
            end: "结束时间",
            text: "说明",
            location: "地点",
            note: "备注",
            description: "其他资讯",
            keyword: "搜索名单"
        },
        holder: {
            head: "未输入",
            belong: {
                no: "",
                name: ""
            },
            subject: "请输入标题",
            begin: "请选择开始时间",
            end: "请选择结束时间",
            text: "请输入说明",
            location: "请输入地点",
            note: "请输入备注",
            description: "请输入其他资讯",
            keyword: "搜索范围: 名字/邮件/会友编号"
        },
        handler: {
            update: {
                subject: "即将更新服事内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入说明",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": "请至少选择1位人员参与服事",
                "text": "",
                "description": ""
            }
        }
    }

}

