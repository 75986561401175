import http from "@/api";
import {Respond} from "@/api/interface/respond";

/**
 * 管理員 - 釋放緩存
 */
export const getAdminUserFlush = () => {
    return http.get<Respond.Data<Respond.Store>>(`/api/admin/user/flush`, {}, {
        opts: {
            completion: {
                //需要指定done, 才會返回swal, 沒要指定就全給空的
                done: () => {}
            }
        }
    });
};

