<template>
  <div :class="active.loaded ? null : `hidden`"
       class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static">
    <!-- BEGIN: Content-->
    <div v-if="active.data.available && active.data.organization.uuid"
         class="app-content content m-b-0">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <a class="brand-logo">
                <img class="logo-img"
                     alt="image"
                     :src="getLogo">
              </a>
              <div class="kp d-none d-lg-flex align-items-center">
                <div class="x2" />
                <div :style="{
                  backgroundImage: `url(${ getBackground })`
                }" class="x1" />
              </div>
              <div class="xp d-flex align-items-center auth-bg px-2">
                <div class="col-12 col-sm-8 col-md-6 col-lg-12 mx-auto">
                  <h2 class="text-center card-title fw-bold mb-1 cgBase">
                    {{active.data.name}} - {{$t(`${configure.prefix}.subject`)}}
                  </h2>
                  <form @submit.prevent="onSubmit"
                        class="auth-login-form mt-2">
                    <!-- 別移除, 讓form可以自動送出 -->
                    <button class="hidden" />
                    <form-password
                        name="create"
                        :parameter="active"
                        :prefix="configure.prefix"
                        :error="[1001, 1002]"
                        :source="field"
                        :label="$t(`${configure.prefix}.field.passwd.create`)"
                        :holder="$t(`${configure.prefix}.holder.passwd.create`)" />
                    <form-password
                        name="confirm"
                        :parameter="active"
                        :prefix="configure.prefix"
                        :error="[1003, 1004]"
                        :source="field"
                        :label="$t(`${configure.prefix}.field.passwd.confirm`)"
                        :holder="$t(`${configure.prefix}.holder.passwd.confirm`)" />
                    <button
                        v-if="active.success === 0"
                        v-html=" $t(`submit.${ active.xhring === true ? 'waiting' : 'forgot.reset' }`) "
                        :disabled="active.xhring === true || active.expire.value < 1"
                        @click="onSubmit"
                        type="button"
                        :class="active.xhring === true ? 'xhring' : null"
                        class="btn btn-tm login bg-gradient-base w-100 mt-2">
                    </button>
                    <button
                        v-else
                        v-html=" $t('submit.success') "
                        disabled
                        type="button"
                        class="xhring btn btn-tm login bg-gradient-base w-100 mt-2">
                    </button>
                  </form>
                </div>
                <div class="ff col-12 col-sm-8 col-md-6 col-lg-12 text-dark">
                  <div v-if="active.expire.value > 0"
                       class="text-muted mb-1 ft-11"
                       v-html="sprintf($t(`${configure.prefix}.expire.subject`), active.expire.value)" />
                  <div v-else
                       class="text-danger mb-1 ft-11 opacity-50"
                       v-html="$t(`${configure.prefix}.expire.text`)" />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                      <el-tooltip
                          v-if="isDarkTheme"
                          class="box-item"
                          :content="$t(`component.theme.dark`)"
                          placement="top-start">
                        <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                  class="d-inline-block cursor hand ft-20"
                                  type="sun" />
                      </el-tooltip>
                      <el-tooltip
                          v-else
                          @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                          class="box-item"
                          :content="$t(`component.theme.light`)"
                          placement="top-start">
                        <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                  class="text-secondary d-inline-block cursor hand ft-20"
                                  type="moon" />
                      </el-tooltip>
                      <el-dropdown popper-class="light-language"
                                   placement="top-start">
                        <icon-sax class="text-secondary ur d-inline-block cursor hand m-l-10 ft-20"
                                  type="global" />
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                                @click="setRegion(j.id)"
                                v-for="j in getAllRegion">
                              <a class="d-flex align-items-center">
                                <country-flag :iso="j.id" />
                                <span :class="getCurrentRegion.id === j.id ? `text-dark` : `text-muted`"
                                      class="m-l-10 ft-12 selected-language"
                                      v-html="j.value" />
                              </a>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                    <div>
                      <el-tooltip
                          class="box-item"
                          :content="$t(`${configure.prefix}.feature.back`)"
                          placement="top-end">
                        <icon-sax @click="onDestroy"
                                  class="text-secondary d-inline-block cursor hand m-l-10 ft-20"
                                  type="arrow-right" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
    <error404 :home="false"
              v-else />
  </div>
</template>

<script setup lang="ts">

/**
 * 因為管理員電子郵件最早期是空白, 也沒辦法下唯一值
 * 所以可能會造成電子郵件相同, 洗密碼會洗錯人
 * 需要用帳號+電子郵件去對應, 發電子郵件即可
 */

import IconSax from "@/component/IconSax.vue"
import Error404 from "@/component/Error.vue"
import CountryFlag from "@/component/CountryFlag.vue"

import {Configure} from "@/api/interface/configure"
import {Session} from "@/api/interface/session"
import {computed, onMounted, reactive} from "vue"
import {useRoute, useRouter} from "vue-router"
import {createLogin} from "@/api/module/session"
import {useSetting} from "@/hook/useSetting"
import {usePermission} from "@/hook/usePermission"
import {useRegion} from "@/hook/useRegion"
import {EnumTheme} from "@/enum/theme";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {useI18n} from "vue-i18n";
import {useSwalAlert} from "@/hook/useSwalAlert";
import FormPassword from "@/component/form/FormPassword.vue";
import {ForgotVerificationReset} from "@/api/interface/core/forgot/verification/reset";
import {getForgotVerificationResetEdit, storeForgotVerificationResetCreate, updateForgotVerificationResetDestroy} from "@/api/module/core/forgot/verification/reset";
import {sprintf} from "sprintf-js";
import {RouteNameEnum} from "@/v2/enumerate/route";

const route = useRoute()
const router = useRouter()

const {
  t
} = useI18n()

const {
  showWarning,
  showSuccess,
  showHandler
} = useSwalAlert()

const {
  isLogin
} = usePermission()

const {
  setTheme,
  isDarkTheme
} = useSetting()

const {
  setRegion,
  getAllRegion,
  getCurrentRegion
} = useRegion()

/**
 * 參數 - 頁面 - 配置
 */
const configure = reactive<Configure.Page.Base>({
  //i18n代碼前置
  prefix: `page.forgot.verification.reset`,
})

/**
 * 參數 - 本地
 */
const active = reactive<{
  loaded: boolean
  error: number
  success: number
  xhring: boolean
  //登入頁面資訊
  data: Session.Login
  //權杖過期倒數
  expire: {
    timer: any,
    value: number
  }
}>({
  loaded: false,
  error: 0,
  success: 0,
  xhring: false,
  data: {
    organization: {
      seq: null,
      uuid: null,
      name: null
    },
    available: true,
    name: null,
    uuid: null,
    avatarUrl: null
  },
  expire: {
    timer: null,
    value: 0
  }
})

/**
 * 欄位
 */
const field = reactive<ForgotVerificationReset.Create.Field>({
  create: null,
  confirm: null
})

/**
 * 銷毀
 */
const onDestroy = async () => showHandler({
  subject: t(`${configure.prefix}.handler.destroy.subject`),
  text: t(`${configure.prefix}.handler.destroy.text`),
  completion: {
    //呼叫儲存回調
    success: async () => {

      await updateForgotVerificationResetDestroy(
          route.params.uuid,
          route.params.uid
      )

      await router.push({
        name: `Login`
      })

    }
  }
})

/**
 * 提交
 */
const onSubmit = async () => {

  //創建效驗
  const v$ = useVuelidate<ForgotVerificationReset.Create.Field>({
    create: {
      required
    },
    confirm: {
      required
    }
  }, field)

  //效驗 - 新密碼
  v$.value.create.$touch()
  if (v$.value.create.required.$invalid) {
    active.error = 1001
    return false
  }

  //效驗 - 新密碼 - 強度 1002 (後端會丟下來)

  //效驗 - 新密碼
  v$.value.confirm.$touch()
  if (v$.value.confirm.required.$invalid) {
    active.error = 1003
    return false
  }

  //效驗 - 密碼 - 兩次密碼不相符
  if (field.create !== field.confirm) {

    showWarning({
      subject: t(`${configure.prefix}.error.1004.subject`),
      text: t(`${configure.prefix}.error.1004.text`),
      completion: {
        //完成顯示錯誤
        done: ()=> active.error = 1004
      }
    })
    return false

  }

  //標示 - 進行中
  active.xhring = true

  const {
    data
  } = await storeForgotVerificationResetCreate(
      route.params.uuid,
      route.params.uid,
      field
  )

  //無法登入
  if (data === undefined) {

    showWarning({
      subject: t(`${configure.prefix}.error.1000.subject`),
      text: t(`${configure.prefix}.error.1000.text`),
      completion: {
        //完成顯示錯誤
        done: ()=> active.xhring = false
      }
    })

    return false

  }
  //異常失敗
  else if (Number.isInteger(data?.code) && data?.code! < 1000) {

    showWarning({
      subject: data?.message?.subject ?? "",
      text: data?.message?.text ?? "",
      completion: {
        //完成顯示錯誤
        done: ()=> active.xhring = false
      }
    })

    return false

  }
  //指定訊息失敗
  else if (Number.isInteger(data?.code) && data?.code! >= 1000) {

    active.error = data?.code!
    active.xhring = false
    return false

  }
  //正常
  else showSuccess({
    subject: t(`${configure.prefix}.handler.success.subject`),
    text: t(`${configure.prefix}.handler.success.text`),
    completion: {
      //完成顯示錯誤
      done: async () => await router.push({
        name: `Login`
      })
    }
  })

}

//獲取Logo
const getLogo = computed((): string => {
  if (active.data.organization.seq) return `/img/organization/${active.data.organization.seq}/logo.png`
  return "/img/organization/1/logo.png"
})

//獲取背景圖
const getBackground = computed(()=>{
  if (active.data.avatarUrl) return active.data.avatarUrl
  return "/img/avatar/holder/my.jpg"
})

//存取API
const fetch = async () => {

  const {
    data
  } = await createLogin(
      route.params.id as string
  )

  //返回錯誤不往下進行
  if (data?.data === undefined) return false

  active.data.organization = data.data.organization
  active.data.available = data.data.available
  active.data.name = data.data.name
  active.data.uuid = data.data.uuid
  active.data.avatarUrl = data.data.avatarUrl

  //先存取教會資訊 - 在存取重設資訊

  const {
    data: e
  } = await getForgotVerificationResetEdit(
      route.params.uuid as string,
      route.params.uid as string,
  )

  //返回錯誤不往下進行或者已超時
  if (e?.data === undefined || e.data.expire === 0) {

    active.data.organization.seq = null
    active.data.organization.uuid = null
    active.data.organization.name = null
    active.data.available = false

    //可以打開頁面了
    active.loaded = true

    return false

  }

  //登記 - 超時
  active.expire.value = e.data.expire

  //啟動 - 超時 - 倒數
  active.expire.timer = setInterval(()=> {

    active.expire.value -= 1

    //超時則停止
    if(active.expire.value <= 0) {

      clearInterval(active.expire.timer);

      showWarning({
        subject: t(`${configure.prefix}.error.9999.subject`),
        text: t(`${configure.prefix}.error.9999.text`),
        completion: {
          //完成顯示錯誤
          done: ()=> router.replace({
            name: "Login"
          })
        }
      })

    }

  }, 1000);

  //可以打開頁面了
  active.loaded = true

}

/**
 * 生命週期 - 掛載完成
 */
onMounted(() => {

  //如果已經登入, 前往控制面板
  if (isLogin.value) router.replace({
    name: RouteNameEnum.ADMIN_DASHBOARD
  })
  //未登入
  else fetch()

})

</script>

<style scoped lang="sass">

@import '@/style/core/authentication.css'

.ur
  position: relative
  top: 2px

.auth-inner
  overflow: hidden
  position: relative
  .kp
    width: calc(100% - 33.33333%)
  .xp
    z-index: 1
    position: absolute
    top: 0
    bottom: 0
    right: 0
    padding: 2.5rem !important
    width: 33.33333%
  .ff
    padding: 1.5rem 2.5rem !important
    position: absolute
    margin-right: auto
    margin-left: auto
    left: 0
    right: 0
    bottom: 0
    text-align: right

@media (max-width: 1500px)
  .auth-inner
    .kp
      width: calc(100% - 40%)
    .xp
      width: 40%

@media (max-width: 1200px)
  .auth-inner
    .kp
      width: calc(100% - 45%)
    .xp
      width: 45%

@media (max-width: 991px)
  .auth-inner
    .kp
      width: 0
    .xp
      width: 100%
    .ff
      padding: 1.5rem !important
      margin-right: 0
      margin-left: 0
      width: 100%

.qrcode
  height: 377.85px

.brand-logo
  img.logo-img
    width: 48px
    height: 48px
    border-radius: 0.375rem

.auth-wrapper
  .auth-bg
    background-color: #FFF
  &.auth-cover
    .brand-logo
      z-index: 4
      left: 1rem !important
.d-none
  position: relative
  .x1, .x2
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
  .x1
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    z-index: 1
  .x2
    background-color: rgba(255, 255, 255, 0.55)
    z-index: 2

.dark-layout
  .auth-wrapper
    .auth-bg
      background-color: #343d55 !important
  .d-none
    .x2
      background-color: rgba(255, 255, 255, 0.25)
</style>
