/**
 * 問題集管理
 */
export default {
    subject: "問題集管理",
    group: {
        index: "問題群組",
        create: "問題集新增",
        edit: "問題集內容",
        permission: "問題集管理 - 問題群組",
    },
    question: {
        index: "問題設置",
        create: "問題設置新增",
        edit: "設置內容",
        permission: "問題集管理 - 問題設置"
    },
    option: {
        index: "回覆選項",
        create: "回覆選項新增",
        edit: "選項內容",
        permission: "問題集管理 - 回覆選項"
    },
    form: {
        index: "表單紀錄",
        edit: "紀錄內容",
        permission: "問題集管理 - 表單紀錄"
    }
}
