import feedback from "@/language/modules/page/admin/support/feedback/zhTW";
import coffee from "@/language/modules/page/admin/support/coffee/zhTW";
import history from "@/language/modules/page/admin/support/history/zhTW";
import {v2} from "@/language/modules/page/admin/support/v2/zhTW";

/**
 * 客服管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 意見與建議
     */
    feedback: feedback,

    /**
     * 請我們喝咖啡
     */
    coffee: coffee,

    /**
     * 帳號紀錄
     */
    history: history

}
