export default {
    length: "每页 %s 项资料",
    summary: `目前显示 第 <b class="cgBase">%s</b> 页 共 <b class="cgBase">%s</b> 页 全部共 <b class="cgBase">%s</b> 项资料`,
    first: "最前页",
    prev: "上一页",
    next: "下一页",
    last: "最后页",
    search: "搜索",
    download: "汇出",
    increase: "创建",
    xhring: "资料读取中",
    reset: "重设",
    refresh: "刷新",
    empty: {
        subject: "抱歉...\uD83D\uDE14",
        text: "没有找到指定的资料",
        summary: `全部共 <b class="cgBase">0</b> 项资料`
    },
    loading: {
        subject: "正在读取...请稍候"
    }
}
