import {Editor} from "@/language/modules/component/form/v2/membership/editor/zhCN"

export const Membership: {
    [key: string]: any
} = {

    editor: Editor,

    option: {
        all: {
            subject: "全部"
        },
    },
    th: [
        "头像",
        "会友",
        "操作"
    ]

}