import http from "@/api"
import {Respond} from "@/api/interface/respond"
import {Forgot} from "@/api/interface/core/forgot";

/**
 * 忘記密碼 - 提交
 */
export const storeForgotCreate = (
    params: any
) => {

    return http.put<Respond.Data<Forgot.Store.Field>>(
        `/api/forgot`,
        params, {
            opts: {
                builtIn: {
                    failure: false
                }
            }
        }
    );

}
