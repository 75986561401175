export default {
    help: {
        close: "關閉",
        reset: "重設"
    },
    tool: {
        illegal: "舉報圖片",
        refresh: "重設編輯",
        prev: {
            has: "上一張",
            no: "沒有上一張了"
        },
        next: {
            has: "下一張",
            no: "沒有下一張了"
        },
        cancel: "取消編輯"
    },
    feature: {
        text: "編輯文字",
        size: "文字大小",
        color: "文字顏色",
        position: "文字位置",
        vertical: "上下微調",
        horizontal: "左右微調"
    },
    position: [
        "左上",
        "右上",
        "置中",
        "左下",
        "右下"
    ],
    field: {
        text: {
            subject: "填充文字"
        },
        size: {
            subject: "文字大小"
        },
        color: {
            subject: "文字顏色"
        },
        position: {
            subject: "文字位置"
        },
        vertical: {
            subject: "垂直微調"
        },
        horizontal: {
            subject: "水平微調"
        }
    },
    holder: {
        text: {
            subject: "請輸入填充文字"
        },
        size: {
            subject: "請選擇文字大小"
        },
        color: {
            subject: "請選擇文字顏色"
        },
        position: {
            subject: "請選擇文字位置"
        },
        vertical: {
            subject: "請選擇垂直微調"
        },
        horizontal: {
            subject: "請選擇水平微調"
        }
    }
}
