export const Remind: {
    [key: string]: any
} = {

    field: {
        common: {
            subject: "常用选项"
        },
        custom: {
            subject: "自定义选项",
            join: {
                subject: "创建自定义选项"
            }
        },
        notify: {
            subject: "通知方式",
            email: {
                holder: "请输入电子邮件",
                join: {
                    subject: "创建电子邮件"
                }
            }
        }
    },
    at: [{
        subject: "活动时间点"
    }, {
        subject: "%s 分钟前",
    }, {
        subject: "%s 小时前",
    }, {
        subject: "%s 天前",
    }, {
        subject: "%s 周前",
    }],
    beforehand: {
        subject: "系统内建提醒无法取消"
    },
    help: {
        picked: {
            subject: `已选%s个选项`,
            email: {
                subject: `已设置%s个电子邮件`,
            }
        },
        selected: {
            subject: `最多只能选择 <b class="ft-16">%s</b> 个选项, 还可以选择 <b class="ft-16">%s</b> 个选项`,
            full: {
                subject: `已达到选项上限 <b class="ft-16">%s</b> 个`
            }
        },
        custom: {
            subject: `自定义选项最多仅能设置 %s 笔`
        },
        notify: {
            email: {
                subject: `邮件名单最多仅能设置 %s 笔`,
                empty: {
                    subject: "请输入电子邮件"
                }
            }
        }
    },
    error: {
        1001: {
            subject: "请输入正确的电子邮件"
        }
    }

}
