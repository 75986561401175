export const Editor: {
    [key: string]: any
} = {

    subject: "收件人",

    option: {
        all: {
            subject: "全部"
        },
    },
    dblclick: {
        subject: "雙擊加入",
        remove: {
            subject: "雙擊移除",
        }
    },
    summary: {
        total: {
            subject: `可選 <b class="ft-15 text-base">%s</b> 名會友`,
            right: {
                subject: `已選 <b class="ft-15 text-base">%s</b> 名會友`,
            },
            filtered: {
                subject: `可選 <b class="ft-15 text-base">%s</b> 名會友, 篩選出 <b class="ft-15 text-base">%s</b> 名會友`,
                right: {
                    subject: `已選 <b class="ft-15 text-base">%s</b> 名會友, 篩選出 <b class="ft-15 text-base">%s</b> 名會友`,
                }
            }
        }
    },
    th: [
        "",
        "頭像",
        "會友"
    ],
    handler: {
        modified: {
            subject: "即將退出選擇編輯",
            text: "異動內容將不會保存, 請確認後執行"
        }
    }

}