<template>
  <li v-if="getUser.church.invitation"
      class="nav-item d-lg-block theme">
    <el-tooltip
        :content="$t(`component.menu.horizontal.invitation.subject`)"
        class="box-item"
        placement="bottom-end">
      <j-badge
          :appearance="['badge-sm', 'badge-light-base', 'm-r-7']"
          :text="getUser.church.invitation" />
    </el-tooltip>
  </li>
</template>

<script setup lang="ts">

import JBadge from "@/component/Badge.vue"
import {useUser} from "@/hook/useUser"

const {
  getUser
} = useUser()

</script>

<style scoped lang="sass">
</style>
