export const User: {
    [key: string]: any
} = {
    0: {
        subject: "建立人員",
        holder: "由系統自動產生",
        stamp: {
            subject: "建立時間",
            holder: ""
        }
    },
    1: {
        subject: "上次編輯人員",
        holder: "內容未經過編輯",
        stamp: {
            subject: "上次編輯時間",
            holder: "內容未經過編輯"
        }
    }
}