import {v2} from "@/language/modules/page/shepherd/attend/v2/zhCN"
import category from "@/language/modules/page/shepherd/attend/category/zhCN";
import history from "@/language/modules/page/shepherd/attend/history/zhCN";
import schedule from "@/language/modules/page/shepherd/attend/schedule/zhCN";

/**
 * 聚会
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 分类
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建聚会"
            }
        },
        field: {
            head: {
                church: "指定教会"
            },
            name: "名称",
            category: "分类",
            cycle: "循环",
            team: "族群数量"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名称",
            category: "请选择分类",
            cycle: "请选择循环方式",
        },
        handler: {
            enable: {
                subject: "即将启用聚会",
                text: "请确认后执行",
                description: ""
            },
            disable: {
                subject: "即将停用聚会",
                text: "请确认后执行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二级验证",
                text: `<span class="cgRed">即将删除聚会, 请确认后执行</span>`,
                description: "请输入您目前的登入密码"
            }
        }
    },

    /**
     * 创建
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "选项"
            },
            2: {
                subject: "名单"
            },
            3: {
                subject: "提醒"
            },
            4: {
                subject: "循环"
            }
        },
        help: {
            church: "请谨慎使用指定教会功能",
            register: {
                off: "需要透过签到工具或小组长出示/扫描会友二维码进行签到",
                on: "会友可自行于牧养对话中签到"
            },
        },
        field: {
            church: "指定教会",
            name: "名称",
            category: "分类",
            begin: {
                subject: "开始时间",
                text: "首次"
            },
            end: {
                subject: "结束时间",
                text: "首次"
            },
            location: "地点",
            note: "备注",
            register: "允许会友自行签到",
            should: "签到工具显示应到会友",
            status: "状态"
        },
        holder: {
            church: "请选择指定教会",
            name: "请输入名称",
            category: {
                subject: "请选择分类",
                empty: "请先选择指定教会"
            },
            begin: "请选择开始时间",
            end: "请选择结束时间",
            location: "请输入地点",
            note: "请输入备注",
            register: "请选择是否允许会友自行签到",
            should: "请选择签到工具是否显示应到会友",
            status: "请选择状态"
        },
        handler: {
            store: {
                subject: "即将创建聚会",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择是否允许会友自行签到",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请选择签到工具是否显示应到会友",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
            disabled: "请注意, 这个聚会已经停用"
        },
        feature: {
            0: "下载报到介面二维码",
            2: "前往报到介面",
            3: "显示报到介面二维码",
            4: "复制报到介面网址"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "选项"
            },
            2: {
                subject: "名单"
            },
            3: {
                subject: "提醒"
            },
            4: {
                subject: "循环"
            },
            5: {
                subject: "统计资讯"
            },
            98: {
                subject: "循环资讯"
            },
            99: {
                subject: "相关资讯"
            }
        },
        summary: {
            schedule: {
                subject: "聚会排程"
            },
            history: {
                subject: "聚会纪录"
            }
        },
        detail: {
            cycle: {
                subject: "循环代号"
            },
            remind: {
                subject: "提醒代号"
            }
        },
        help: {
            register: {
                off: "需要透过签到工具或小组长出示/扫描会友二维码进行签到",
                on: "会友可自行于牧养对话中签到"
            },
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            name: "名称",
            category: "分类",
            begin: {
                subject: "开始时间",
                text: "首次"
            },
            end: {
                subject: "结束时间",
                text: "首次"
            },
            location: "地点",
            note: "备注",
            register: "允许会友自行签到",
            should: "签到工具显示应到会友",
            status: "状态"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            name: "请输入名称",
            category: "请选择分类",
            begin: "请选择开始时间",
            end: "请选择结束时间",
            location: "请输入地点",
            note: "请输入备注",
            register: "请选择是否允许会友自行签到",
            should: "请选择签到工具是否显示应到会友",
            status: "请选择状态"
        },
        handler: {
            update: {
                subject: "即将更新聚会内容",
                text: "请确认后执行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "请选择分类",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请输入名称",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请选择开始时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请选择结束时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "结束时间必须大于开始时间",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请选择是否允许会友自行签到",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "请选择签到工具是否显示应到会友",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 历程
     */
    history: history,

    /**
     * 排程
     */
    schedule: schedule

}
