import {v2} from "@/language/modules/component/shepherd/v2/zhTW"

export default {
    v2: v2,
    client: {
        partake: {
            help: {
                lifted: {
                    empty: "請先選擇需要加入的族群",
                    exist: "已經加入該族群"
                },
                archive: {
                    on: "已經設定不跟進該會友",
                    off: "重新跟進"
                }
            },
            empty: "該會友尚未加入任何族群",
            lifted: {
                remove: "移除族群",
                archive: "不跟進",
                on: "解除族群",
                off: "復原解除",
            },
            field: {
                increase: "加入新族群",
                team: "族群",
            },
            holder: {
                increase: "請選擇需要加入的新族群",
                team: "請選擇需要加入的族群",
            },
            handler: {
                archive: {
                    subject: "即將不跟進該名會友",
                    text: `所有群族將<span class="text-danger">全數解除及取消標註</span><br />若要再次進入群族被關心<br />請前往[不跟進會友]重新加入族群, 請確認後執行<br/><span class="ft-14 text-warning">請注意, 儲存後才會生效</span>`
                }
            }
        }
    },
    team: {
        editor: {
            archive: {
                subject: "不跟進",
                text: "不跟進會友無法選擇"
            },
            top: "最上層",
            tip: {
                readonly: "總教會僅供查看資料, 無法新增修改"
            },
            mode: {
                1: "族群成員",
                2: "託管人員"
            },
            move: {
                left: "雙擊加入",
                right: "雙擊移除"
            },
            subject: {
                head: "族群內容",
                add: "族群新增",
                edit: "族群編輯"
            },
            disable: {
                available: "請選擇加入的項目",
                selected: "請選擇移除的項目",
            },
            recipient: {
                minimum: `最少需要選擇 %s 位族群成員`
            },
            manager: {
                maximum: `最多只能選擇 %s 位託管人員`,
                minimum: `最少需要選擇 %s 位託管人員`
            },
            changed: "尚未異動項目",
            empty: {
                recipient: "請至少選擇<br />1位會友作為託管人員",
                manager: "請先選擇族群成員<br />再進行託管人員選擇"
            },
            not: {
                available: "族群中沒有會友<br />請先加入會友到族群中",
                found: "無法搜尋到<br />您指定條件的會友"
            },
            help: {
                available: `可選擇 <b class="cgBase">%s</b> 個會友`,
                selected: {
                    recipient: `已選擇 <b class="cgBase">%s</b> 位族群成員`,
                    manager: `已選擇 <b class="cgBase">%s</b> 位託管人員`,
                }
            },
            th: [
                null,
                "會友",
            ],
            field: {
                manager: "託管人員",
                name: "族群名稱",
                belong: "所屬族群",
                keyword: "搜尋名單"
            },
            holder: {
                manager: `請至少選擇 <b class="ft-14 cgBase">1</b> 位託管人員`,
                maximum: `, 最多能選擇 <b class="ft-14 cgBase">%s</b> 位託管人員`,
                name: "請輸入族群名稱",
                belong: "請選擇所屬族群",
                keyword: "搜尋範圍: 名字/郵件"
            },
            handler: {
                close: {
                    add: {
                        subject: "即將關閉族群新增",
                        text: `<span class="text-danger">異動項目將會清除, 請確認後執行</span>`
                    },
                    edit: {
                        subject: "即將關閉族群編輯",
                        text: `<span class="text-danger">異動項目將會清除, 請確認後執行</span>`
                    }
                },
                store: {
                    subject: "即將新增族群",
                    text: "請確認後執行",
                    description: ""
                },
                update: {
                    subject: "即將更新族群內容",
                    text: "請確認後執行",
                    description: ""
                }
            },
            error: {
                1001: {
                    "subject": "請輸入族群名稱",
                    "text": "",
                    "description": ""
                },
                1002: {
                    "subject": "請選擇族群成員",
                    "text": `至少需要 <b class="ft-20 text-danger">1</b> 位族群成員`,
                    "description": ""
                },
                1003: {
                    "subject": "託管人員不足最低限制",
                    "text": `至少需要 <b class="ft-20 text-danger">1</b> 位託管人員`,
                    "description": ""
                },
                1004: {
                    "subject": "託管人員超過最高限制",
                    "text": `最多只能選擇 <b class="ft-20 text-danger">%s</b> 位託管人員`,
                    "description": ""
                }
            }
        }
    },
    attend: {
        history: {
            recipient: {
                th: {
                    1: "到",
                    2: "頭像",
                    3: "資訊",
                    4: "報到",
                    5: "查看"
                },
                vacation: {
                    subject: "本週無聚會"
                },
                rank: {
                    subject: "自評",
                    not: {
                        yet: {
                            subject: "未自評"
                        }
                    }
                },
                empty: "名單中沒有成員",
                note: {
                    subject: "留言",
                    not: {
                        yet: {
                            subject: "未留言"
                        }
                    }
                },
                register: {
                    not: {
                        yet: {
                            subject: "未報到"
                        }
                    }
                },
                edit: {
                    view: "查看此會友資訊"
                }
            }
        }
    }
}
