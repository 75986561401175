/**
 * 客服管理 - 意见与建议
 */
export default {

    /**
     * 列表
     */
    index: {
        text: {
            group: "管理团队"
        },
        field: {
            head: {
                church: "指定教会"
            },
            avatar: "头像",
            name: "名字",
            content: "内容",
            level: "层级"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名字",
            level: "请选择层级"
        },
        handler: {
            raise: {
                subject: "即将反馈至爱无界管理团队",
                text: "请认后执行",
                description: "",
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            unread: "请注意, 这个反馈还未标示已读取",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相关资讯"
            }
        },
        text: {
            group: "管理团队"
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            level: "层级",
            id: "会友ID",
            no: "会友编号",
            name: "会友姓名",
            account: "会友帐户",
            content: "反馈内容",
            created: "建立时间",
            status: "状态"
        },
        holder: {
        },
        handler: {
            update: {
                subject: "即将更新反馈内容",
                text: "请确认后执行",
                description: "",
                raise: {
                    subject: "即将更新反馈内容",
                    text: `<b class="ft-20 text-danger">即将反馈至爱无界管理团队, 请认后执行</b>`,
                    description: "",
                }
            }
        },
        error: {
            1001: {
                "subject": "请选择层级",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请选择状态",
                "text": "",
                "description": ""
            },
        }
    }

}
