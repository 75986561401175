import access from "@/language/modules/page/admin/course/access/zhCN";
import authorize from "@/language/modules/page/admin/course/authorize/zhCN";
import exception from "@/language/modules/page/admin/course/exception/zhCN";

/**
 * 历程管理
 */
export default {

    /**
     * 存取纪录
     */
    access: access,

    /**
     * 授权纪录
     */
    authorize: authorize,

    /**
     * 拦截纪录
     */
    exception: exception

}
