export const Progress: {
    [key: string]: any
} = {
    cost : {
        subject: "耗用时间"
    },
    remaining: {
        subject: "剩余时间",
        expire : {
            subject: "已经超时",
        }
    }
}
