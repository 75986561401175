import _ from "lodash"

import router from "@/router"
import {reactive, Ref} from "vue";
import {useUtils} from "@/hook/useUtils";
import {Configure} from "@/api/interface/configure";
import {WebsocketData} from "@/api/interface/websocket";
import {useSetting} from "@/hook/useSetting";
import {useUserBillboard} from "@/hook/useUserBillboard";
import {EnumWebsocketCommand, EnumWebsocketStatus} from "@/enum/websocket";
import {getBillboardStatistics} from "@/api/module/billboard/statistics";

export const useWebsocket = (
    retry: boolean = true
) => {

    const route = router.currentRoute.value

    const {
        getUuid
    } = useUtils()

    const {
        setSettingWebsocketConnectStatus
    } = useSetting()

    const {
        setUserBillboard
    } = useUserBillboard()

    const debug: boolean = (import.meta.env.VITE_SOCKET_DEBUG as string).toLowerCase() === "true"

    /**
     * 參數 - 本地 - websocket
     */
    const active = reactive<Configure.Websocket>({
        config: {
            interval: 45 * 1000,
            reconnect: true,
            retry: retry,
            debug: debug,
            status: {
                refresh: false
            }
        },
        timer: null,
        socket: null,
        completion: () => {},
    })

    //連線
    const initial = (url: string) => {

        //清除計時器
        if (active.timer !== null) clearInterval(active.timer)

        //每次重連都換uuid, 比較不容易連上之後無反應
        active.socket = new WebSocket(`${url}?uuid=${getUuid(8, true)}`)

        //連線成功
        active.socket.onopen = () => {

            if (active.config.debug) console.info("Websocket was connected");

            active.timer = setInterval(() => {
                if (active.config.debug) console.info('Websocket was send heartbeat')
                //傳送心跳, 否則 nginx 會把你踢斷(預設60s)
                if (active.socket.readyState === 1) active.socket.send("heartbeat")
            }, active.config.interval)

            //更新狀態
            if (active.config.status.refresh) setSettingWebsocketConnectStatus(EnumWebsocketStatus.CONNECTED)

        }

        //收到訊息執行
        active.socket.onmessage = (e: any) => {
            const d = JSON.parse(e.data)
            //需要有訊息類型 + 內文
            if (Number.isInteger(d.type) && d.content !== undefined && active.completion !== null) active.completion(d)
        }

        //發生錯誤嘗試重連
        active.socket.onerror = () => {

            if (active.config.debug) console.error("Websocket was error");

            //更新狀態
            if (active.config.status.refresh) setSettingWebsocketConnectStatus(EnumWebsocketStatus.DISCONNECTED)

        }

        //發生斷線
        active.socket.onclose = () => {

            if (active.config.debug) console.warn("Websocket was disconnect");

            //更新狀態
            if (active.config.status.refresh) setSettingWebsocketConnectStatus(EnumWebsocketStatus.DISCONNECTED)

            //嘗試重連
            if (active.config.retry) reconnect(url)

        }

    }

    //嘗試重新連線
    const reconnect = (url: string) => setTimeout(()=>initial(url), 1500)

    //呼叫取值
    const onFetch = (
        page: Ref
    ) => {

        //非總教會, 獲取是否有公告欄訊息
        getBillboardStatistics().then(r => {

            //如果沒返回正確, 不往下執行
            if (r.data?.result !== true) return false

            //投入資料
            setUserBillboard(r.data!.data)

            //在列表頁面
            if (route.name === `Billboard`) page.value.initial()

            //在內容頁面
            else if (route.name === `BillboardEdit`) page.value.initial(false)

        })

    }

    /**
     * 總連線
     * @param page 目前頁面的 ref
     * @param url socket連線網址
     * @param delay fetch延遲執行時間 (單位: 毫秒)
     */
    const setWebsocket = (
        page: Ref,
        url: string,
        delay: number
    ) => {

        //先清除連接狀態
        setSettingWebsocketConnectStatus(EnumWebsocketStatus.DISCONNECTED)

        setWebsocketConnect(url, {
            status: {
                refresh: true
            }
        }, (e: WebsocketData) => {

            //合格觸發信號
            const successCommand: string[] = [
                //連接成功
                EnumWebsocketCommand.CONNECTED,
                //獲取公告欄
                EnumWebsocketCommand.BILLBOARD
            ]

            //如果符合格的觸發訊號, 呼叫取值
            if (successCommand.includes(e.content.command)) setTimeout(() => onFetch(page), delay)

        })
    }

    /**
     * 手動連線
     * @param url socket連線網址
     * @param config 傳入 - 參數
     * @param completion
     */
    const setWebsocketConnect = (
        url: string,
        config: any,
        completion: (e: any) => void
    ) => {

        //注入回調
        active.completion = completion

        //注入設定值
        active.config = Object.assign(_.cloneDeep(active.config), config)

        //嘗試連接
        initial(url)

    }

    //手動關閉
    const setWebsocketDisconnect = () => {
        //關閉重連機制
        active.config.retry = false
        //清除計時器
        if (active.timer !== null) clearInterval(active.timer)
        if (active.socket) active.socket.close()
    }

    return {
        setWebsocket,
        setWebsocketConnect,
        setWebsocketDisconnect
    }

}
