/**
 * 注意 router / views / navigation 必須同時加入才行
 */

import {
    NavigationItemRaw
} from "@/navigation/interface";

import {
    computed
} from "vue";

import {
    useRoute
} from "vue-router";

import AdminDashboard from "@/navigation/modules/admin/dashboard";
import AdminUser from "@/navigation/modules/admin/user";
import AdminChurch from "@/navigation/modules/admin/church";
import AdminClient from "@/navigation/modules/admin/client";
import AdminMy from "@/navigation/modules/admin/my";
import AdminSoul from "@/navigation/modules/admin/soul";
import AdminDaily from "@/navigation/modules/admin/daily";
import AdminGallery from "@/navigation/modules/admin/gallery";
import AdminNotification from "@/navigation/modules/admin/notification";
import AdminEvent from "@/navigation/modules/admin/event";
import AdminDonate from "@/navigation/modules/admin/donate";
import AdminTeach from "@/navigation/modules/admin/teach";
import AdminCycle from "@/navigation/modules/admin/cycle";
import AdminQuestion from "@/navigation/modules/admin/question";
import AdminSupport from "@/navigation/modules/admin/support";
import AdminCourse from "@/navigation/modules/admin/course";
import AdminBillboard from "@/navigation/modules/admin/billboard";
import AdminActivity from "@/navigation/modules/admin/activity";
import AdminLive from "@/navigation/modules/admin/live";

import ShepherdDashboard from "@/navigation/modules/shepherd/dashboard";
import ShepherdTeam from "@/navigation/modules/shepherd/team";
import ShepherdClient from "@/navigation/modules/shepherd/client";
import ShepherdFriend from "@/navigation/modules/shepherd/friend";
import ShepherdAttend from "@/navigation/modules/shepherd/attend";

export const useNavigationItem = () => {

    const route = useRoute();

    const adminNavigationItem: NavigationItemRaw[] = [
        //1
        ...AdminDashboard,
        //17
        ...AdminBillboard,
        //3
        ...AdminChurch,
        //2
        ...AdminUser,
        //4
        ...AdminClient,
        //5
        ...AdminMy,
        //6
        ...AdminSoul,
        //7
        ...AdminDaily,
        //8
        ...AdminGallery,
        //9
        ...AdminNotification,
        //10
        ...AdminEvent,
        //11
        ...AdminDonate,
        //12
        ...AdminTeach,
        //13
        ...AdminCycle,
        //14
        ...AdminQuestion,
        //15
        ...AdminSupport,
        //16
        ...AdminCourse,
        //18
        ...AdminActivity,
        //19
        ...AdminLive
    ]

    //透過指定的序列排序
    const adminSortList = [1, 17, 3, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 18, 19, 14, 15, 16]
    adminNavigationItem.sort((a, b) => {
        return adminSortList.indexOf(a.id) - adminSortList.indexOf(b.id)
    });

    const shepherdNavigationItem: NavigationItemRaw[] = [
        //51
        ...ShepherdDashboard,
        //52
        ...ShepherdFriend,
        //53
        ...ShepherdClient,
        //54
        ...ShepherdTeam,
        //55
        ...ShepherdAttend
    ]

    //透過指定的序列排序
    const shepherdSortList = [51, 52, 53, 54, 55]
    shepherdNavigationItem.sort((a, b) => {
        return shepherdSortList.indexOf(a.id) - shepherdSortList.indexOf(b.id)
    });

    /**
     * 返回指定系統的權限表
     */
    const getMenuItem = computed((): NavigationItemRaw[]=>{
        return route.meta.portal === 'admin' ?
            adminNavigationItem:
            shepherdNavigationItem
    })

    return {
        getMenuItem
    }

}
