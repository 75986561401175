import {NavigationItemRaw} from '@/navigation/interface'

const my: NavigationItemRaw[] = [{
    id: 5,
    right: true,
    subject: 'admin.my.index',
    route: 'AdminMy',
    icon: 'cup',
    children: [],
    attached: [
        'AdminMyCreate',
        'AdminMyEdit',
        'AdminMyCopy',
        'AdminMyMindCatcher',
        'AdminMyComment',
        'AdminMyNotification',
        'AdminMyNotificationCreate',
        'AdminMyNotificationEdit'
    ]
}]

export default my
