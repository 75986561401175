import {v2} from "@/language/modules/caption/v2/zhCN"

export default {

    /**
     * v2
     */
    v2: v2

}
