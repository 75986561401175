/**
 * 鉤子
 * WebSocket
 * @author J
 * @since 2024-04-30 07:34:25
 */

import _ from "lodash"
import {computed, reactive} from "vue";
import {WebSocketConfiguration} from "@/v2/configuration/websocket";
import {useUtils} from "@/v2/hooks/utils";
import {WebsocketCommandEnum} from "@/v2/enumerate/websocket";
import {sprintf} from "sprintf-js";
import {Basic} from "@/v2/api/interface";

/**
 * 實例
 */
export const useWebsocket = (
    retry: boolean = true
) => {

    /**
     * 是否為正式區
     */
    const production: boolean = import.meta.env.PROD as boolean

    /**
     * 開啟偵錯
     */
    const debug: boolean = (import.meta.env.VITE_SOCKET_DEBUG as string).toLowerCase() === "true"

    const {
        getUuid
    } = useUtils()

    /**
     * 參數 - 本地 - websocket
     */
    const active = reactive<WebSocketConfiguration>({
        config: {
            interval: 45 * 1000,
            retry: retry,
            debug: debug
        },
        online: false,
        timer: null,
        socket: null,
        completion: {
        }
    })

    //連線
    const initial = (
        url: string
    ) => {

        //清除計時器
        if (active.timer !== null) clearInterval(active.timer)

        //每次重連都換uuid, 比較不容易連上之後無反應
        active.socket = new WebSocket(sprintf(
            `%s?uuid=%s`,
            url,
            getUuid(16, true)
        ))

        //連線成功
        active.socket.onopen = () => {

            if (active.config.debug) console.info("WebSocket was connected");

            active.timer = setInterval(() => {
                if (active.config.debug) console.info('WebSocket was send heartbeat')
                //傳送心跳, 否則 nginx 會把你踢斷(預設60s)
                if (active.socket.readyState === 1) active.socket.send("heartbeat")
            }, active.config.interval)

            //更新狀態
            active.online = true

            //呼叫連線成功回調
            if (active.completion.connect) active.completion.connect!()

        }

        //收到訊息執行
        active.socket.onmessage = (e: any) => {

            const d: Basic.Websocket = JSON.parse(e.data) as Basic.Websocket

            //需要有訊息類型 + 內文
            if (Number.isInteger(d.type) && d.content !== undefined && active.completion.message && Object.keys(WebsocketCommandEnum).includes(d.content.command)) {
                if (!production) console.log("websocket.message", d)
                if (active.completion.message) active.completion.message(d)
            }

        }

        //發生錯誤嘗試重連
        active.socket.onerror = () => {

            if (active.config.debug) console.error("WebSocket was error");

            //更新狀態
            active.online = false

        }

        //發生斷線
        active.socket.onclose = () => {

            if (active.config.debug) console.warn("WebSocket was disconnect");

            //更新狀態
            active.online = false

            //嘗試重連
            if (active.config.retry) reconnect(url)

        }

    }

    //嘗試重新連線
    const reconnect = (url: string) => setTimeout(()=>initial(url), 1500)

    /**
     * 手動連線
     * @param url socket連線網址
     * @param config 傳入 - 參數
     * @param completion connect=連線成功回調, message=收到訊息回調
     */
    const setConnect = (
        url: string,
        config: any,
        completion?: {
            connect?: () => void,
            message?: (e: Basic.Websocket) => void
        }
    ) => {

        //注入 - 回調 - 連線成功
        if (completion?.connect) active.completion.connect = completion.connect

        //注入 - 回調 - 收到訊息
        if (completion?.message) active.completion.message = completion.message

        //注入設定值
        active.config = Object.assign(_.cloneDeep(active.config), config)

        //嘗試連接
        initial(url)

    }

    /**
     * 手動關閉
     */
    const setDisconnect = () => {
        //關閉重連機制
        active.config.retry = false
        //清除計時器
        if (active.timer !== null) clearInterval(active.timer)
        if (active.socket) active.socket.close()
    }

    /**
     * 是否連上線
     */
    const isOnline = computed((): boolean => active.online)

    return {
        isOnline,
        setConnect,
        setDisconnect
    }

}