import {Create} from "@/language/modules/page/admin/notification/v2/create/zhCN"
import {Edit} from "@/language/modules/page/admin/notification/v2/edit/zhCN"

export const v2: {
    [key: string]: any
} = {

    /**
     * 创建
     */
    create: Create,

    /**
     * 编辑
     */
    edit: Edit,

    field: {
        type: {
            subject: "对象",
            value: {
                1: {
                    subject: "指定推播"
                },
                2: {
                    subject: "全体推播"
                }
            }
        },
        subject: {
            subject: "标题",
        },
        summary: {
            total: {
                subject: "推播数量"
            }
        },
        status: {
            subject: "状态",
            progress: {
                subject: `<br/>进度 %s`,
            },
            value: {
                0: {
                    subject: "排程中",
                },
                1: {
                    subject: "发送中"
                },
                2: {
                    subject: "已完成"
                }
            }
        },
        begin: {
            subject: "排程时间"
        },
        created: {
            subject: "建立时间"
        }
    },
    search: {
        type: {
            subject: "对象",
            holder: "请选择对象"
        },
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        on: {
            subject: "排程时间(起)",
            holder: "请选择排程时间(起)",
            end: {
                subject: "排程时间(迄)",
                holder: "请选择排程时间(迄)"
            }
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    handler: {
        destroy: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将删除推播通知, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }

}
