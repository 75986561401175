export const v2: {
    [key: string]: any
} = {

    my: "我的教會",

    soul: "心靈捕手",

    daily: "每日靈糧"

}
