/**
 * 新朋友
 */
export default {

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "创建新朋友"
            }
        },
        text: {
            register: "自行注册"
        },
        field: {
            head: {
                church: "指定教会"
            },
            avatar: "头像",
            name: "名字",
            email: "电子邮件",
            from: "来源",
            relationship: "关系"
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            name: "请输入名字",
            email: "请输入电子邮件"
        },
        handler: {
            add: {
                subject: `请确认将指定的会友<span class="text-danger">标记为待转入</span>`,
                text: "请确认后执行"
            },
            clear: {
                subject: `请确认将指定的会友<span class="text-danger">标记为不转入</span>`,
                text: "请确认后执行"
            },
            convert: {
                subject: `请确认将指定的会友<span class="text-danger">转入至牧养会友</span>`,
                text: "请确认后执行"
            }
        }
    },

    /**
     * 编辑
     */
    edit: {
        tip: {
            readonly: "总教会仅供查看资料, 无法创建修改",
            disabled: "请注意, 这名新朋友已经标记为不转入"
        },
        commitment: {
            empty: "未填写"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "来源"
            },
            99: {
                subject: "相关资讯"
            }
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            email: "电子邮件",
            name: "名字",
            title: "称谓",
            birthday: "生日",
            phone: "联络电话",
            location: {
                country: "所在地区",
                city: "所在城市"
            },
            address: "联络地址",
            occupation: "职业",
            baptized: "受洗时间",
            commitment: "委身编号",
            created: "建立时间",
            relationship: "关系",
        },
        holder: {
            head: "未输入",
            belong: {
                name: "",
                no: ""
            },
            email: "请输入电子邮件",
            name: "请输入名字",
            title: "未输入称谓",
            birthday: "未输入生日",
            phone: "未输入联络电话",
            location: {
                country: "未选择所在地区",
                city: "未选择所在城市"
            },
            address: "未输入联络地址",
            occupation: "未输入职业",
            baptized: "未输入受洗时间",
            commitment: "请输入委身编号",
            created: "",
            accessed: "",
        },
        handler: {
            add: {
                subject: `请确认将指定的会友<span class="text-danger">标记为待转入</span>`,
                text: "请确认后执行"
            },
            clear: {
                subject: `请确认将指定的会友<span class="text-danger">标记为不转入</span>`,
                text: "请确认后执行"
            },
            convert: {
                subject: `请确认将指定的会友<span class="text-danger">转入至牧养会友</span>`,
                text: "请确认后执行"
            }
        }
    }

}
