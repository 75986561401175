export const Team: {
    [key: string]: any
} = {

    field: {

        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            text: "同時搜尋名字/郵件/會友編號"
        },

        team: {
            subject: "服事群組",
            holder: "請選擇服事群組"
        }

    },
    help: {
        team: {
            empty: {
                subject: "請先建立服事群組"
            }
        }
    }

}