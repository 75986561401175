export const Editor: {
    [key: string]: any
} = {

    subject: "选择会友参与服事",

    submit: {
        maximum: {
            subject: "最多只能选择%s位会友参与服事"
        },
        minimum: {
            subject: "请选择至少%s位会友参与服事"
        }
    }

}