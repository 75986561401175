import {Edit} from "@/language/modules/component/form/v2/edit/zhTW"
import {Table} from "@/language/modules/component/form/v2/table/zhTW"
import {History} from "@/language/modules/component/form/v2/history/zhTW"
import {Membership} from "@/language/modules/component/form/v2/membership/zhTW"
import {Cycle} from "@/language/modules/component/form/v2/cycle/zhTW"
import {Remind} from "@/language/modules/component/form/v2/remind/zhTW"

export const v2: {
    [key: string]: any
} = {

    edit: Edit,

    table: Table,

    history: History,

    membership: Membership,

    cycle: Cycle,

    remind: Remind

}
