export default {
    submit: "上傳",
    summary: `已選擇 <b class="text-success ft-15">%s</b> 張圖片, 最多可以上傳 <b class="text-success ft-15">%s</b> 張圖片`,
    run: {
        out: `已經選完 <b class="text-danger ft-15">%s</b> 張圖片`
    },
    gallery: {
        subject: "圖庫",
        disabled: "請先選擇圖"
    },
    history: {
        subject: "歷史圖",
    },
    upload: {
        subject: "上傳圖",
        disabled: "圖片處理中, 請稍候"
    },
    destroy: "清除全部",
    clear: "清除",
    handler: {
        cancel: {
            subject: "即將取消選擇圖",
            text: "已經編輯的內容將會重設, 請確認後執行"
        },
        clear: {
            subject: "即將清除已經設定的圖",
            text: "已經編輯的內容將會重設, 請確認後執行"
        }
    }
}
