import i18n from "@/language";

import {
    computed
} from "vue";

import {
    SettingStore
} from "@/store/modules/setting";

import {
    RegionRaw
} from "@/api/interface/region";

/**
 * 切換語言功能
 */
export const useRegion = () => {

    const settingStore = SettingStore()

    /**
     * 可用語言
     */
    const available: RegionRaw[] = [{
        id: "zhTW",
        key: "zh-TW",
        value: "繁體中文",
    }, {
        id: "zhCN",
        key: "zh-CN",
        value: "简体中文"
    }]

    /**
     * 獲取現在的語系
     */
    const getCurrentRegion = computed((): RegionRaw => {
        const r: RegionRaw | undefined = available.find((i: RegionRaw)=>i.id===settingStore.getRegion)
        if (r === undefined) return available[0]
        return r
    })

    /**
     * 返回可用語系 (除了現在的以外)
     */
    const getAvailableRegion = computed((): RegionRaw[] => {
        return available.filter((i: RegionRaw)=>i.id!==settingStore.getRegion)
    })

    /**
     * 返回全部語系 (除了現在的以外)
     */
    const getAllRegion = computed((): RegionRaw[] => {
        return available
    })

    /**
     * 獲取目前語系 watch 用
     */
    const getRegion = computed((): string => {
        return settingStore.getRegion
    })

    /**
     *  設定語系
     */
    const setRegion = (id: string) => {

        //確認語系存在
        const r: RegionRaw | undefined = available.find((i: RegionRaw) => i.id === id)

        //如果找不到正確語系, 不往下處理
        if (r === undefined) return false

        //修改store
        settingStore.setRegion(id)

        //修改App的原始属性
        i18n.global.locale.value = id

        //修改頁面屬性
        document.querySelector('html')!.setAttribute('lang', getCurrentRegion.value.key);

    }

    return {
        getRegion,
        setRegion,
        getAllRegion,
        getCurrentRegion,
        getAvailableRegion
    }

}
