/**
 * 鉤子
 * SweetAlert2
 * ❗️❗️ 危險 ❗️❗️
 * 在 hook 裡面的, 所有的 t(), 都沒問題, 可以用的
 * 但是檢查器會一直抓錯, 都加上 @ts-ignore
 * 沒加build的時候還會出現, 加了就全都沒這問題
 * error TS2589: Type instantiation is excessively deep and possibly infinite.
 * @author J
 * @since 2024-04-30 07:34:25
 */

import $ from "jquery";

import Swal from "sweetalert2"
import JQrcode from "@/v2/components/qrcode/index.vue";
import {ReturnCodeEnum} from "@/v2/enumerate/return/code";
import {SweetAlertConfiguration} from "@/v2/configuration/sweetalert";
import {SweetAlertEnum, SweetAlertIconEnum} from "@/v2/enumerate/sweetalert";
import {useIntent} from "@/v2/hooks/intent";
import {IntentEnum} from "@/v2/enumerate/intent";
import {useAuthorization} from "@/v2/hooks/authorization";
import i18n from "@/language";
import {AppContext, reactive} from "vue";
import {renderComponent} from "@/hook/useComponent";
import {getFactory} from "@/api/module/factory";
import {QrcodeEnum} from "@/v2/enumerate/qrcode";

/**
 * 返回 - 首頁 - 狀態碼
 */
const isHomeCode: ReturnCodeEnum[] = [
    //找不到頁面, 回到首頁
    ReturnCodeEnum.NOT_FOUND,
    //沒權限 (有登入)
    ReturnCodeEnum.FORBIDDEN
]

/**
 * 只提示 - 點擊 - 沒反應 - 狀態碼
 */
const isJustCode: ReturnCodeEnum[] = [
    //FEIGN連線異常 (可能剛好微服務在重啟, 保留畫面值, 讓用戶端可以重輸入)
    ReturnCodeEnum.FEIGN_CONNECT_ERROR,
    //內部錯誤 (有可能只是瞬間沒反應, 保留畫面值, 讓用戶端可以重輸入)
    ReturnCodeEnum.INTERNAL_SERVER_ERROR,
    //二級認證失敗
    ReturnCodeEnum.TWO_FACTORY_ERROR
]

/**
 * 未授權 - 強迫登出 - 狀態碼
 */
const isUnauthorizedCode: ReturnCodeEnum[] = [
    //匿名用戶
    ReturnCodeEnum.UNAUTHORIZED,
    //授權 - 公司 - 過期
    ReturnCodeEnum.COMPANY_AUTHORIZED_EXPIRE
]

/**
 * 實例
 */
export const useSweetAlert = () => {

    //導入i18n
    const {
        // @ts-ignore
        t
    } = i18n.global

    /**
     * 顯示成功
     */
    const showSuccess = (configure: SweetAlertConfiguration) => build(
        configure,
        SweetAlertEnum.SUCCESS,
        SweetAlertEnum.SUCCESS
    )

    /**
     * 顯示失敗
     */
    const showWarning = (configure: SweetAlertConfiguration) => build(
        configure,
        SweetAlertEnum.WARNING_ONE,
        SweetAlertEnum.WARNING_ONE
    )

    /**
     * 顯示提問
     */
    const showHandler = (configure: SweetAlertConfiguration) => build(
        configure,
        SweetAlertEnum.QUESTION,
        SweetAlertEnum.QUESTION
    )

    /**
     * 顯示 status !== 200 的錯誤
     */
    const showErrorStatus = (code: number) => {

        const {
            onLaunch
        } = useIntent()

        /**
         * 參數 - Swal - 控制
         */
        const configure: SweetAlertConfiguration = {
            //@ts-ignore
            subject: t(`status.code.${code}.subject`),
            //@ts-ignore
            text: t(`status.code.${code}.text`)
        }

        //只提示點擊沒反應
        if (isJustCode.includes(code)) {

            //增加自選重新整理功能
            configure.icon = SweetAlertIconEnum.WARNING
            configure.confirmButtonText = 'submit.reload'
            configure.completion = {
                success: () => onLaunch(IntentEnum.RELOAD)
            }
            build(configure, code, SweetAlertEnum.QUESTION)

        }
        //需要返回首頁的
        else if (isHomeCode.includes(code)) {

            configure.confirmButtonText = `submit.relocation`
            configure.completion = {
                success: () => onLaunch(IntentEnum.HOME)
            }
            build(configure, code, SweetAlertEnum.WARNING_ONE)

        }
        //未授權
        else if (isUnauthorizedCode.includes(code)) {

            const {
                setToken
            } = useAuthorization()

            //直接先清掉, 這樣用戶重新整理也回首頁去了
            setToken(null)

            //按好才回首頁
            configure.completion = {
                success: () => onLaunch(IntentEnum.LOGIN)
            }

            build(configure, code, SweetAlertEnum.WARNING_ONE)

        }
        //其他 (點了刷新頁面)
        else {

            configure.confirmButtonText = `submit.relocation`
            configure.completion = {
                success: () => onLaunch(IntentEnum.RELOAD)
            }

            build(configure, code, SweetAlertEnum.WARNING_ONE)

        }

    }

    /**
     * 驗證失敗提示
     */
    const showValidateFailure = (active: {
                                     i18n: string,
                                     error: {
                                         code: number
                                     }
                                 },
                                 code: number) => {

        const configure: SweetAlertConfiguration = {
            subject: t(`${active.i18n}.error.${code}.subject`),
            text: t(`${active.i18n}.error.${code}.text`),
            completion: {
                //完成顯示錯誤
                hidden: ()=> active.error.code = code
            }
        }

        build(
            configure,
            SweetAlertEnum.WARNING_ONE,
            SweetAlertEnum.WARNING_ONE
        )

    }

    /**
     * 顯示二次驗證
     */
    const showTwoFactory = (
        configure: SweetAlertConfiguration,
        appContext: AppContext
    ) => {

        let destroyComp: (() => void) | null

        //參數 - 本地
        const active = reactive<{
            //回傳的密碼
            passwd: string
            is: {
                //是否為qrcode 模式
                qrcode: boolean
            }
        }>({
            passwd: "",
            is: {
                qrcode: true
            }
        })

        Swal.fire({
            //標題
            title: configure.subject,
            //副標題
            html: configure.text,
            //顯示右上角關閉按鈕
            showCloseButton: false,
            //點外側關閉
            allowOutsideClick: false,
            //顯示取消按鈕
            showCancelButton: true,
            //強迫確認
            focusConfirm: false,
            //確認按鈕文字
            confirmButtonText: t(`submit.accept`),
            //取消按鈕的預設文字
            cancelButtonText: t('submit.cancel'),
            //輸入框的類型
            input: 'password',
            //自定義外觀
            customClass: {
                container: "two-factory",
                input: 'form-control form-control-lg',
            },
            //即將開啟
            willOpen() {

                //創建
                destroyComp = renderComponent(
                    $(`#swal2-html-container`)[0],
                    JQrcode,
                    //接收$emit, 前面帶個on, close => onClose
                    {
                        //模式
                        mode: QrcodeEnum.FACTORY,
                        //指定取消時順便關閉swal
                        isCancelClose: true,
                        //頂部加塞
                        marginTop: 2,
                        //底部加塞
                        marginBottom: 0,
                        //密碼輸入時的占位符
                        placeholder: configure.description,
                        //監聽 - 切換輸入模式
                        onChangeMode: (e: boolean) => {

                            active.is.qrcode = e

                            active.passwd = ""

                            const d = $(".swal2-container.two-factory .swal2-confirm")
                            d.prop("disabled", true).addClass(`cursor-not-allowed`)

                            //如果切換到qrcode模式, 隱藏確定
                            if (active.is.qrcode) d.hide()
                            //如果切換到passwd模式, 顯示確定
                            else d.show()

                        },
                        //監聽 - 輸入密碼
                        onKeyup: (e: string) => {

                            //紀錄輸入的密碼
                            active.passwd = e

                            const disabled: boolean = active.passwd.length === 0

                            const d = $(".swal2-container.two-factory .swal2-confirm")
                            d.prop("disabled", disabled)

                            if (disabled) d.addClass(`cursor-not-allowed`)
                            else d.removeClass(`cursor-not-allowed`)

                        },
                        //監聽 - 授權成功
                        onAuthorize: () => setTimeout(()=>{
                            if (configure.completion?.success) configure.completion.success()
                        }, 0),
                        //監聽 - 密碼模式下, 按entry送出
                        onSubmit: (e: string) => {
                            //有輸入字元才送出
                            if (e.length > 0) $(".swal2-container.two-factory .swal2-confirm").trigger('click')
                        },
                        //監聽 - 手機按取消
                        onCancel: () => setTimeout(()=>Swal.close(), 0)
                    },
                    appContext
                )

            },
            //關閉完成
            didClose() {
                //銷毀
                destroyComp?.()
            },
            //開啟完成
            didOpen(e: HTMLElement) {

                //隱藏原始的輸入框, 不能移除, 否則 preConfirm 會報錯
                $(".swal2-input",$(e)).hide()

                const d = $(".swal2-container.two-factory .swal2-confirm")
                //先停用確認送出
                d.prop("disabled", true).addClass(`cursor-not-allowed`)
                //隱藏確認送出
                d.hide()

            },
            //提交前檢查
            preConfirm: () => {

                //正確返回輸入值, then中的result.value就會繼承值
                //返回false, then就不會執行
                if (active.is.qrcode) {
                    return true
                }
                return active.passwd.length > 0

            }
        }).then(async (result) => {

            //密碼驗證才會跑到這邊來

            //按取消不往下執行
            if (!result.isConfirmed) {
                return false
            }

            //進行二級認證 (密碼方式才走這邊)
            const {
                data
            } = await getFactory({passwd: active.passwd})

            //認證失敗 - 不正確返回
            if (data?.result === undefined || !data?.result) {
                showErrorStatus(ReturnCodeEnum.TWO_FACTORY_ERROR)
                return false
            }

            //執行回調
            setTimeout(()=>{
                if (configure.completion?.success) configure.completion.success()
            }, 0)

        })

    }

    /**
     * 顯示sweetalert
     * @param configure sweetalert的設定值
     * @param code sweetalert的代碼
     * @param type sweetalert的類型
     */
    const build = (configure: SweetAlertConfiguration,
                   code: number,
                   type?: SweetAlertEnum) => {

        // 有同樣錯誤性質的sweetalert已經存在, 就不再重複顯示
        if (document.querySelector(`.sweetalert-container-${code}`)) {
            return
        }

        //結果值
        let resultValue: boolean = false

        //預設參數
        const params: {
            [key: string]: any
        } = {
            //標題
            title: configure.subject,
            //副標題
            html: configure.text,
            //顯示右上角關閉按鈕
            showCloseButton: false,
            //點外側關閉
            allowOutsideClick: false,
            //顯示取消按鈕
            showCancelButton: true,
            //不允許ESC關閉
            allowEscapeKey: false,
            //強迫確認
            focusConfirm: false,
            //確認按鈕的預設文字
            //@ts-ignore
            confirmButtonText: configure.confirmButtonText ? t(configure.confirmButtonText) : t('submit.accept'),
            //取消按鈕的預設文字
            //@ts-ignore
            cancelButtonText: configure.cancelButtonText ? t(configure.cancelButtonText) : t('submit.cancel')
        }

        //一般 - 詢問
        if (type === SweetAlertEnum.QUESTION) {

            //圖示
            params.icon = "question"

        }
        //警示 - 詢問
        else if (type === SweetAlertEnum.WARNING) {

            params.icon = "warning"

        }
        //強迫單按鈕方式[成功]
        else if (type === SweetAlertEnum.SUCCESS) {

            //圖示
            params.icon = "success"
            //不顯示取消按鈕
            params.showCancelButton = false
            //確認按鈕文字
            params.confirmButtonText = getAcceptButtonText(configure)

        }
        //強迫單按鈕方式[警示]
        else if (type === SweetAlertEnum.WARNING_ONE) {

            params.icon = "warning"
            //不顯示取消按鈕
            params.showCancelButton = false
            params.confirmButtonText = getAcceptButtonText(configure)

        }

        //有指定預設圖片, 使用預設圖片 (需要抓undefined)
        if (configure.icon !== undefined) params.icon = configure.icon

        //即將顯示
        if (configure.completion?.show) params.willOpen = () => {
            configure.completion!.show!()
        }

        //顯示完成
        if (configure.completion?.shown) params.didOpen = () => {
            configure.completion!.shown!()
        }

        //即將隱藏
        if (configure.completion?.hide) params.willClose = () => {
            configure.completion!.hide!()
        }

        //隱藏完成
        if (configure.completion?.hidden) params.didClose = () => {
            configure.completion!.hidden!()
        }

        //指定顯示錯誤代號
        params.customClass = {
            container: `sweetalert-container-${code}`
        }

        Swal.fire(params).then((result) => {

            resultValue = result.isConfirmed

            //強迫單按鈕方式[警示]+錯誤回調
            if (type === SweetAlertEnum.WARNING_ONE && configure.completion?.failure) configure.completion.failure()
            //詢問+成功回調
            else if (resultValue && configure.completion?.success) configure.completion.success()
            //詢問+錯誤回調
            else if (configure.completion?.failure) configure.completion.failure()

        })

    }

    /**
     * 回傳確認按鈕文字
     * @param configure
     */
    const getAcceptButtonText = (configure: SweetAlertConfiguration): string => {
        //@ts-ignore
        return configure.acceptButtonText !== undefined ? t(configure.acceptButtonText) : t('submit.ok')
    }

    return {
        showSuccess,
        showWarning,
        showHandler,
        showErrorStatus,
        showValidateFailure,
        showTwoFactory
    }

}