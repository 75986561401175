import {NavigationItemRaw} from '@/navigation/interface'

const teach: NavigationItemRaw[] = [{
    id: 12,
    right: true,
    subject: 'admin.teach.index',
    route: 'AdminTeach',
    icon: 'lamp',
    children: [],
    attached: [
        'AdminTeachCreate',
        'AdminTeachEdit'
    ]
}]

export default teach
