import {Create} from "@/language/modules/page/admin/event/v2/create/zhCN"
import {Edit} from "@/language/modules/page/admin/event/v2/edit/zhCN"

export const v2: {
    [key: string]: any
} = {

    /**
     * 创建
     */
    create: Create,

    /**
     * 编辑
     */
    edit: Edit,

    field: {
        subject: {
            subject: "名称",
        },
        summary: {
            partake: {
                subject: "参与人数"
            }
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "已停用",
                },
                1: {
                    subject: "启用中"
                }
            }
        },
        begin: {
            subject: "开始时间"
        },
        created: {
            subject: "建立时间"
        }
    },
    search: {
        subject: {
            subject: "名称",
            holder: "请输入名称"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    },
    handler: {
        enable: {
            subject: "即将启用服事",
            text: `请确认后执行`,
        },
        disable: {
            subject: "即将停用服事",
            text: `请确认后执行`,
        },
        destroy: {
            subject: "安全性二级验证",
            text: `<span class="cgRed">即将删除服事, 请确认后执行</span>`,
            description: "请输入您目前的登入密码"
        }
    }

}
