import {RouteRecordRaw} from "vue-router";

const gallery: RouteRecordRaw[] = [{
	path: "gallery",
	name: "AdminGallery",
	meta: {
		permission: 8001,
		breadcrumb: [{
			key: 'admin.gallery.subject',
			value: null
		}, {
			key: 'admin.gallery.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/gallery/index.vue"),
},
{
	path: "gallery/accept",
	name: "AdminGalleryAccept",
	meta: {
		permission: 8011,
		breadcrumb: [{
			key: 'admin.gallery.subject',
			value: null
		}, {
			key: 'admin.gallery.accept',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/gallery/accept/index.vue"),
},
{
	path: "gallery/alarm",
	name: "AdminGalleryAlarm",
	meta: {
		permission: 8021,
		breadcrumb: [{
			key: 'admin.gallery.subject',
			value: null
		}, {
			key: 'admin.gallery.alarm',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/gallery/alarm/index.vue"),
}];

export default gallery;
