export default {
    name: "名称",
    empty: "搜索不到指定的权限",
    head: "仅总教会",
    portal: [
        "我的教会",
        "牧养系统"
    ],
    feature: [{
        "subject": "总览"
    }, {
        "subject": "创建"
    }, {
        "subject": "保存"
    }, {
        "subject": "编辑"
    }, {
        "subject": "刷新"
    }, {
        "subject": "删除"
    }, {
        "subject": "汇出"
    }]
}
