export const Create: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本"
        },
        1: {
            subject: "帐户"
        },
        2: {
            subject: "附件"
        }
    },
    field: {
        sample: {
            subject: "样本ID",
            holder: "请输入样本ID"
        },
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        role: {
            subject: "角色",
            holder: "请选择角色"
        },
        type: {
            subject: "类型",
            holder: "请选择类型"
        }
    },
    handler: {
        submit: {
            subject: "即将创建工单",
            text: "请确认后执行"
        }
    },
    error: {
        1010: {
            "subject": "请选择角色",
            "text": ""
        },
        1020: {
            "subject": "请输入样本ID",
            "text": ""
        },
        1021: {
            "subject": "样本ID请输入数字",
            "text": ""
        },
        1030: {
            "subject": "请选择类型",
            "text": ""
        },
        1040: {
            "subject": "请输入标题",
            "text": ""
        },
        1050: {
            "subject": "请输入描述",
            "text": ""
        }
    }

}
