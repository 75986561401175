import {Edit} from "@/language/modules/page/admin/notification/v2/edit/automatic/edit/zhCN";

export const Automatic: {
    [key: string]: any
} = {

    edit: Edit,

    field: {
        recipient: {
            subject: "收件人"
        },
        id: {
            subject: "识别ID"
        },
        supplier: {
            subject: "支援厂商",
            value: {
                1: {
                    subject: "APNS",
                },
                2: {
                    subject: "FCM"
                }
            }
        },
        status: {
            subject: "状态",
            value: {
                0: {
                    subject: "未对应",
                },
                1: {
                    subject: "已对应"
                }
            }
        },
        created: {
            subject: "建立时间"
        },
        stamp: {
            subject: "执行时间"
        },
        received: {
            subject: "生成时间"
        }
    },
    search: {
        supplier: {
            subject: "推播类型",
            holder: "请选择推播类型"
        },
        status: {
            subject: "状态",
            holder: "请选择状态"
        }
    }

}
