<template>
  <div ref="component"
       :class="[
         props.class,
         `mb-${props.bottom}`
       ]"
       class="form-component">
    <label class="form-label">
      <span>
        <span class="label-text"
              v-html="props.label" />
        <span v-if="props.text"
              class="text-muted label-description ft-12 m-l-3"
              v-html="props.text" />
        <b v-if="props.must"
           class="must cgRed ft-18 m-l-5">*</b>
      </span>
      <span v-if="$slots.help"
            class="help">
        <slot name="help" />
      </span>
    </label>
    <div :class="[
         'input-group-captcha',
         'input-group',
         'input-group-merge']">
      <el-tooltip
          :visible="active.is.error.enable"
          :popper-class="[
            `is-error is-error-from-captcha`,
            props.parameter.error > 0 ? `messenger error-${props.parameter.error}` : null
          ]"
          placement="top-end">
        <template #content>
        <span @click="props.parameter.error = 0"
              v-html="$t(`${props.prefix}.error.${props.parameter?.error}.subject`)" />
        </template>
        <el-input v-model="props.source[props.name]"
                  @click="isStaticTooltip ? props.parameter.error = 0 : null"
                  @keyup.enter="emit(`submit`)"
                  :placeholder="props.holder"
                  :readonly="props.readonly"
                  :class="[
                    props.variant,
                    props.readonly ? `readonly` : null,
                    props.copy ? `is-copy` : null,
                    $slots.append ? `is-append` : null]"
                  :maxlength="props.maxLength.toString()"
                  type="text"
                  autocomplete="off"
                  :size="props.size" />
      </el-tooltip>
      <span class="input-group-text cursor-pointer captcha">
        <img alt="image"
             @click="onGenerate"
             :src="getCaptcha" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">

import {useStaticTooltip} from "@/hook/useStaticTooltip"

import {
  computed, nextTick,
  reactive, watch
} from "vue"

import {useUtils} from "@/hook/useUtils"

const {
  getUuid
} = useUtils()

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "submit"): void
}>()

const props = withDefaults(defineProps<{
  //必填提示(*)
  must?: boolean
  //僅讀取
  readonly?: boolean
  //標題
  label?: string | null
  //副標題
  text?: string | null
  //提示字詞
  holder?: string | null
  //鍵值
  name: string
  //傳入值
  source: object
  //語言前置
  prefix?: string
  //控制參數
  parameter?: {
    error: number
  }
  //錯誤數字範圍
  error?: number[]
  //傳入的外框外觀
  class?: string | null
  //外框底部添加高度
  bottom?: number
  //複製功能
  copy?: boolean
  //input外觀
  variant?: string | null
  //尺寸
  size?: any
  //最長長度
  maxLength?: number
}>(), {
  must: false,
  readonly: false,
  prefix: "",
  label: null,
  text: null,
  holder: null,
  parameter: () => {
    return {
      error: 0
    }
  },
  source: () => { return {} },
  error: () => [],
  class: null,
  bottom: 1,
  copy: false,
  variant: null,
  size: `large`,
  maxLength: parseInt(import.meta.env.VITE_SITE_CAPTCHA_LENGTH as string)
})

const {
  isStaticTooltip
} = useStaticTooltip({
  active: props.parameter,
  prefix: props.prefix
})

/**
 * 參數 - 本地
 */
const active = reactive<{
  uuid: string
  is: {
    error: {
      enable: boolean
    }
  }
}>({
  uuid: getUuid(16, true),
  is: {
    error: {
      enable: false
    }
  }
})

/**
 * 重新產生 - 驗證碼
 */
const onGenerate = () => active.uuid = getUuid(16, true)

/**
 * 獲取 - 驗證碼 - 網址
 */
const getCaptcha = computed((): string=>{
  return `${import.meta.env.VITE_BASE_URL}secure/captcha?${active.uuid}`
})

/**
 * 監控 - 錯誤 - 顯示
 * 因為可能會卡到tab的問題, 導致 el-tooltip 的 teleported 出錯
 * 需要經過 nextTicker() 就會正確了
 */
watch(()=>props.parameter.error, async () => {
  if (props.parameter.error > 0) await nextTick()
  active.is.error.enable = isStaticTooltip.value && props.error.includes(props.parameter.error)
})

/**
 * 主動暴露
 */
defineExpose({
  onGenerate
})

</script>

<style scoped lang="sass">
.input-group-captcha
  position: relative
  .captcha
    position: absolute
    right: 0
    top: 0
    bottom: 0

</style>
