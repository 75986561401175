export default {
    name: "名稱",
    empty: "搜尋不到指定的權限",
    head: "僅總教會",
    portal: [
        "我的教會",
        "牧養系統"
    ],
    feature: [{
        "subject": "總覽"
    }, {
        "subject": "新增"
    }, {
        "subject": "儲存"
    }, {
        "subject": "編輯"
    }, {
        "subject": "更新"
    }, {
        "subject": "刪除"
    }, {
        "subject": "匯出"
    }]
}
