export const Cycle: {
    [key: string]: any
} = {

    subject: "循環資訊",

    item: {
        0: {
            subject: "循環代號"
        },
        1: {
            subject: "提醒代號"
        },
        2: {
            subject: "最後循環開始時間"
        }
    }

}