/**
 * 我的教会文章管理
 */
export default {
    index: "我的教会文章管理",
    create: "我的教会文章创建",
    edit: "我的教会文章内容",
    copy: "文章复制",
    mindcatcher: "文章导入",
    permission: "我的教会文章管理",
    comment: {
        index: "留言纪录",
        permission: "我的教会文章管理 - 留言纪录"
    },
    notification: {
        index: "推播纪录",
        create: "推播创建",
        edit: "推播内容",
        permission: "我的教会文章管理 - 推播纪录"
    },
}
