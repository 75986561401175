export const Tag: {
    [key: string]: any
} = {

    field: {

        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            text: "同时搜索名字/邮件/会友编号"
        },

        tag: {
            subject: "标签",
            holder: "请选择标签"
        }

    }

}