/**
 * 历程管理
 */
export default {

    /**
     * 列表
     */
    index: {
        field: {
            head: {
                church: "指定教会"
            },
            begin: "建立日期(起)",
            end: "建立日期(迄)",
            avatar: "头像",
            name: "名字",
            role: "来源",
        },
        holder: {
            head: {
                church: "请选择指定教会"
            },
            begin: "请选择建立日期(起)",
            end: "请选择建立日期(迄)",
            name: "请输入名字",
            empty: {
                email: "未输入"
            }
        },
        handler: {
        }
    },

    /**
     * 编辑
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相关资讯"
            }
        },
        tip: {
            unknown: "此纪录为未知来源"
        },
        field: {
            belong: {
                name: "所属教会名称",
                no: "所属教会ID"
            },
            avatar: "头像",
            seq: "流水号",
            name: "名字",
            account: "帐户",
            role: "来源",
            method: "存取方式",
            address: "远端位置",
            uri: "存取路径",
            created: "建立时间"
        },
        holder: {
        },
        handler: {
        }
    }

}
