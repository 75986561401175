import reset from "@/language/modules/page/forgot/verification/reset/zhTW";

/**
 * 忘記密碼 - 驗證碼
 */
export default {

    reset: reset,

    subject: "確認郵件驗證碼",
    text: "請輸入電子郵件中的 6 位數字驗證碼",
    expire: {
        subject: `本次重設密碼令牌將在 <b class="ft-14 text-danger">%s</b> 秒後失效`,
        text: `本次重設密碼令牌已經失效`
    },
    feature: {
        back: "返回登入"
    },
    handler: {
        destroy: {
            subject: "是否確定放棄重設密碼",
            text: "確認後將返回登入頁面"
        }
    },
    error: {

        1000: {
            subject: "暫時無法使用",
            text: "請稍後再試或請聯絡管理人員"
        },
        1001: {
            subject: "驗證碼空白",
            text: "請輸入您的驗證碼"
        },
        1002: {
            subject: "驗證碼長度異常",
            text: `驗證碼長度應為 <b class="ft-18 text-danger">6</b> 個數字`
        },
        1003: {
            subject: "驗證碼錯誤",
            text: "請確認您的驗證碼"
        },
        9999: {
            subject: "重設密碼令牌已失效",
            text: "請重新進行忘記密碼程序"
        }

    }

}
