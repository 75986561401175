import {NavigationItemRaw} from '@/navigation/interface'

const user: NavigationItemRaw[] = [{
    id: 52,
    right: true,
    subject: 'shepherd.friend.index',
    route: 'ShepherdFriend',
    icon: 'user-search',
    children: [],
    attached: [
        "ShepherdFriendEdit"
    ]
}]

export default user
