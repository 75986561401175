/**
 * 登入畫面
 */
export default {
    subject: "APP管理系統",
    feature: {
        authorized: {
            qrcode: "掃碼登入",
            passwd: "帳密登入"
        },
        forgot: "忘記密碼"
    },
    field: {
        account: "登入帳號",
        passwd: "登入密碼",
        captcha: "驗證碼"
    },
    holder: {
        account: "請輸入登入帳號",
        passwd: "請輸入登入密碼",
        captcha: "請輸入圖形中的文字"
    },
    error: {
        1000: {
            subject: "暫時無法使用",
            text: "請稍後再試或請聯絡管理人員"
        },
        1001: {
            subject: "請輸入登入帳號",
            text: ""
        },
        1002: {
            subject: "請輸入登入密碼",
            text: ""
        },
        1003: {
            subject: "請輸入圖形中的文字",
            text: ""
        },
        1004: {
            subject: "驗證碼應為6個字元",
            text: ""
        },
        1005: {
            subject: "驗證碼錯誤",
            text: ""
        }
    }
}
