import {Create} from "@/language/modules/page/admin/support/v2/worker/edit/history/create/zhTW"
import {Edit} from "@/language/modules/page/admin/support/v2/worker/edit/history/edit/zhTW"

export const History: {
    [key: string]: any
} = {

    /**
     * 新增
     */
    create: Create,

    /**
     * 編輯
     */
    edit: Edit,

    field: {
        subject: {
            subject: "標題",
            holder: "請輸入標題"
        },
        description: {
            subject: "描述",
            holder: "請輸入描述"
        },
        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            help: "同時搜尋標題與描述"
        },
        begin: {
            subject: "開始時間(起)",
            holder: "請選擇開始時間"
        },
        end: {
            subject: "結束時間(迄)",
            holder: "請選擇結束時間"
        },
        invalid: {
            subject: "作廢",
            holder: "請選擇作廢"
        },
        creator: {
            subject: "建立人",
            holder: "請選擇建立人"
        },
        editor: {
            subject: "上一次編輯人",
            holder: "請選擇上一次編輯人"
        },
        status: {
            subject: "狀態",
            holder: "請選擇狀態"
        }
    },
    alert: {
        readonly: {
            subject: "工單已經標示完成無法進行修改"
        }
    },
    handler: {
        destroy: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將作廢紀錄, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        }
    }

}
