export default {
    editor: {
        form: {
            1: {
                holder: "请输入电子邮件"
            },
            2: {
                holder: "请输入%s",
            },
            3: {
                holder: "请输入%s",
            },
            4: {
                active: "是",
                inactive: "否"
            },
            5: {
                holder: "请输入%s的描述"
            },
            6: {
                holder: "请选择%s的描述"
            },
            7: {
                holder: "请选择%s"
            },
            8: {
                holder: "请输入姓名"
            },
            rank: `第 <b class="ft-16 text-success">%s</b> 位报名人员`,
            validator: {
                1: {
                    "subject": "必填"
                },
                2: {
                    "subject": "电子邮件格式错误"
                },
                3: {
                    "subject": "请至少选择一个选项"
                },
            }
        },
        canvas: {
            discount: {
                not: {
                    allow: "请依照顺序编辑"
                },
                create: {
                    subject: "创建报名优惠"
                },
                edit: {
                    subject: "编辑报名优惠"
                }
            }
        },
        tip: {
            edit: "请依照升序方式编辑或删除, 避免日期与优惠价格设置错误"
        },
        help: {
            add: {
                custom: "创建团报"
            },
            discount: {
                custom: "创建报名优惠",
                maximum: `最多仅能建立 <b class="cgBase">%s</b> 笔报名优惠`,
                not: {
                    next: "优惠价格已经达到最高值, 无法再继续设置优惠价格"
                }
            },
            external: "若关闭外部报名, 仅能用App报名并且无法使用团体报名"
        },
        enable: {
            external: {
                on: "开启",
                off: "关闭"
            },
            charge: {
                on: "需收费",
                off: "免费"
            },
            restrict: {
                on: "需限制",
                off: "不限制"
            },
            personal: {
                on: "开启",
                off: "关闭"
            },
            group: {
                on: "开启",
                off: "关闭"
            },
            qrcode: {
                on: "开启",
                off: "关闭"
            },
            discount: {
                on: "开启",
                off: "关闭"
            },
            payment: {
                on: "开启",
                off: "关闭"
            }
        },
        field: {
            external: {
                subject: "外部报名"
            },
            restrict: {
                subject: "人数上限",
                field: "人数"
            },
            charge: {
                subject: "费用",
                field: "金额"
            },
            qrcode: {
                subject: "扫码报到"
            },
            method: {
                subject: "报名方式",
                personal: {
                    subject: "个人报名"
                },
                group: {
                    subject: "团体报名",
                    minimum: "团体最少报名人数",
                    maximum: "团体最多报名人数"
                }
            },
            discount: {
                th: "优惠内容",
                subject: "报名优惠",
                price: "优惠价格",
                begin: "开始时间",
                end: "结束时间",
                description: {
                    subject: "描述内容",
                    text: "可以直接编辑"
                }
            },
            payment: {
                subject: "线上支付",
                yet: {
                    subject: "未付款"
                },
                paid: {
                    subject: "已付款"
                }
            }
        },
        text: {
            discount: {
                first: `<span class="text-danger">(包含往前日期)</span>`
            }
        },
        method: {
            note: {
                subject: "温馨提示",
                text: `人数上限未达团体报名最低限制 <b class="text-danger ft-16">%s</b> 人, 无法使用团体报名, 仅能使用个人报名`
            }
        },
        payment: {
            note: {
                subject: "备注"
            },
            free: {
                subject: "温馨提示",
                text: "已经设置活动免费, 无法启用线上支付功能"
            },
            newebpay: {
                subject: "温馨提示",
                text: "贵教会未开启线上奉献功能, 无法启用线上支付功能"
            },
            description: {
                1: {
                    subject: `当报名费用低于 <b class="text-success ft-16">30</b> 或 超过 <b class="text-success ft-16">20,000</b> 时, 将无法使用 <b class="text-success">超商代码缴费</b> 方式支付`
                },
                2: {
                    subject: `当报名费用低于 <b class="text-success ft-16">20</b> 或 超过 <b class="text-success ft-16">40,000</b> 时, 将无法使用 <b class="text-success">超商条码缴费</b> 方式支付`
                },
                3: {
                    subject: `当报名费用超过 <b class="text-success ft-16">49,999</b> 时, 将无法使用 <b class="text-success">WebATM</b>, <b class="text-success">ATM转帐</b> 方式支付`
                },
                4: {
                    subject: `<b class="text-success">WebATM</b>, <b class="text-success">ATM转帐</b> 支付方式, 仅支援 <b class="text-success">台湾银行</b> 与 <b class="text-success">华南银行</b> 之银行户头`
                },
                5: {
                    subject: `以上单位均为 <b class="text-success">新台币</b>`
                }
            }
        },
        holder: {
            restrict: "请输入人数",
            charge: "请输入金额",
            method: {
                group: {
                    minimum: "请输入团体最少报名人数",
                    maximum: "请输入团体最多报名人数"
                }
            },
            discount: {
                price: "请输入优惠价格",
                begin: "请选择开始时间",
                end: "请选择结束时间",
                description: "请输入描述内容"
            }
        },
        handler: {
            remove: {
                subject: "即将删除优惠价格",
                text: "请确认后执行"
            },
            charge: {
                off: {
                    subject: "即将清空优惠价格设置",
                    text: "您已经有设置优惠价格, 请确认后执行"
                }
            }
        },
        echelon: {
            edit: {
                subject: "变更场次",
                sort: {
                    subject: `第 <b class="ft-22">%s</b> 场`
                },
                between: {
                    subject: `%s 至 %s`
                },
                handler: {
                    update: {
                        subject: "即将变更场次",
                        text: "请确认后执行"
                    }
                }
            }
        },
        error: {
            1001: {
                "subject": "请输入金额",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "请至少加入一笔报名优惠",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "请输入开始时间",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "请输入结束时间",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "请输入优惠价格",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "请输入人数",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "请至少选择一种报名方式",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "优惠价格设置异常",
                "text": "",
                "description": ""
            },
            1009: {
                "subject": "请输入最少报名人数",
                "text": "",
                "description": ""
            },
            1010: {
                "subject": "低于最少报名人数限制",
                "text": "",
                "description": ""
            },
            1011: {
                "subject": "请输入最多报名人数",
                "text": "",
                "description": ""
            },
            1012: {
                "subject": "高于报名限制人数",
                "text": "",
                "description": ""
            }
        }
    }
}
