<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"

import {sprintf} from "sprintf-js"
import {onMounted, reactive, computed, watch, nextTick} from "vue"
import {useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import {NavigationItemRaw, NavigationItemChildRaw} from "@/navigation/interface"
import {useMenu} from "@/hook/useMenu"
import {usePermission} from "@/hook/usePermission"
import {useIntent} from "@/hook/useIntent"
import {useUserBillboard} from "@/hook/useUserBillboard"
import {useSimulation} from "@/hook/useSimulation";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {useWorker} from "@/v2/hooks/worker";

const {
  getUserBillboard
} = useUserBillboard()

const {
  isHead,
  isHasRight,
  isSimulation
} = usePermission()

const {
  logoutIntent
} = useIntent()

const {
  onBack
} = useSimulation()

const {
  isCollapsed,
  isOverlay,
  setOverlay,
  getCaption
} = useMenu()

const {
  isEnable: isWorkerEnable,
  getPage: getWorkerPage
} = useWorker()

const {
  t
} = useI18n()

const route = useRoute()

const props = defineProps<{
  //傳入的選單項目
  items: NavigationItemRaw[]
}>()

//本地端控制參數
const active = reactive<{
  id: number[]
}>({
  id: [],
})

//計算需要展開的id
const getItem = () => {

  active.id = props.items.reduce<number[]>((acc: any, current: any) => {
    let o: number[] = []
    if ( current.route === route.name ) o.push(current.id)
    if ( current.children.length > 0 ) {
      const u = current.children.find((i: { route: string })=> i.route === route.name )
      if( u !== undefined ){
        o.push(current.id)
        o.push(u.id)
      }
      //第二層使用 attached 功能
      else {
        for (const v of current.children){
          if (v.attached.includes(route.name)){
            o.push(current.id)
            o.push(v.id)
          }
        }
      }
    }
    //第一層使用 attached 功能
    else if (current.attached.includes(route.name)) {
      o.push(current.id)
    }
    return acc.concat(o)
  }, [])

}

//展開子項目
const onExpand = (e: NavigationItemRaw) => {
  //如果一樣的點擊是取消
  active.id.includes(e.id) ? active.id = active.id.filter(i=>i!==e.id) : active.id.push(e.id)
}

//當有前往路由時, 縮起其他expand的父資料夾
const onShrink = (
    e: NavigationItemRaw,
    k: NavigationItemChildRaw | null
) => {

  //關閉浮動功能表
  setOverlay(false)

  //如果點的是目前的則不動作
  if(route.name === k?.route) return false

  //清空已經打開的
  active.id = []

  //push目前點擊的id
  if (e.id > 0) active.id.push(e.id)

}

//當有前往路由時, 縮起其他expand的父資料夾
const onShrinkWorker = (
    e: NavigationItemRaw,
    k: string
) => {

  //關閉浮動功能表
  setOverlay(false)

  //如果點的是目前的則不動作
  if(route.name === k) return false

  //清空已經打開的
  active.id = []

  //push目前點擊的id
  if (e.id > 0) active.id.push(e.id)

}

/**
 * 註記外觀
 * lg 最大畫面
 * md 最大畫面 + 折疊左側
 * sm 最小畫面 + 展開左側
 */
const getVariant = computed((): string => {
  if (isOverlay.value) return `sm`
  else if (isCollapsed.value) return `md`
  else return "lg"
})

//權限表
const getRight = computed<NavigationItemRaw[]>(()=>{
  return props.items.filter(i=>{
    //如果不管制權限的直接放行
    if (!i.right) return true
    return isHasRight.value(i.id)
  })
})

//獲取名稱
const getSubject = computed(() => (e: NavigationItemRaw | NavigationItemChildRaw): string => {

  //我的教會
  if(e.route === RouteNameEnum.ADMIN_MY) return sprintf(
      `%s%s`,
      getCaption.value.my ?? t(`caption.v2.my`),
      t(`component.breadcrumb.index.index`)
  )
  //心靈捕手
  else if(e.route === RouteNameEnum.ADMIN_SOUL || e.subject === "admin.soul.index") return sprintf(
      `%s%s`,
      getCaption.value.soul ?? t(`caption.v2.soul`),
      t(`component.breadcrumb.index.index`)
  )
  //心靈捕手 - 分類
  else if(e.route === RouteNameEnum.ADMIN_SOUL_CATEGORY) return sprintf(
      `%s%s`,
      getCaption.value.soul ?? t(`caption.v2.soul`),
      t(`component.breadcrumb.category.index`)
  )
  //每日靈糧
  else if(e.route === RouteNameEnum.ADMIN_DAILY) return sprintf(
      `%s%s`,
      getCaption.value.daily ?? t(`caption.v2.daily`),
      t(`component.breadcrumb.index.index`)
  )

  return t(`nav.${e.subject}`)

})

//計算有權限的子功能
const getChild = computed(()=>{
  return (e: NavigationItemRaw): NavigationItemChildRaw[] => {
    return e.children.filter(i=>isHasRight.value(i.id))
  }
})

//檢查是否有子項目被點開
const isGroup = computed(()=>{
  return (e: NavigationItemRaw): boolean => {
    return e.children.filter( i => active.id.includes( i.id ) ).length > 0
  }
})

//展開的子項目
const isSub = computed(()=>{
  return (e: NavigationItemRaw,
          k: boolean): boolean => {
    if (e.id === 15 && isWorkerEnable.value && isHead && getWorkerPage.value.includes(route.name as RouteNameEnum)) return true
    if (k) return active.id.includes(e.id)
    return active.id.includes(e.id) && e.children.length > 0
  }
})

//計算是否展開父控件與子控件上色
const isOpen = computed(()=>{
  return (e: NavigationItemRaw | NavigationItemChildRaw): boolean => {
    return (route.name === e.route || e.attached.includes(route.name as string) ) && active.id.includes( e.id )
  }
})

//是否顯示公告欄紅點
const isBillboardBadge = computed((): (e: number) => boolean => {
  return (e) => {
    //必須是公告欄 + 非總教會 + 未讀取訊息數量 > 0
    return e === 17 && !isHead.value && getUserBillboard.value.summary.unread > 0
  }
})

//路由變化監聽 (需要跳線程)
watch(()=>route.fullPath, async () => {

  await nextTick()

  //計算需要展開的id
  getItem()

})

//掛載頁面就取值
onMounted(()=>{
  //執行展開計算
  getItem()
})

</script>

<template>
  <ul>
    <li v-for="(j, d) in getRight"
        :key="d"
        :class="[
           isOpen(j) ? 'active' : null,
           j.route ? null : 'has-sub',
           isGroup(j) ? 'sidebar-group-active': null,
           isSub(j, false)  ? 'open' : null,
           'nav-item']">
      <router-link v-if="j.route"
                   class="d-flex left-slider align-items-center"
                   :class="[getVariant]"
                   @click="onShrink(j, j)"
                   :to="{
                      name: j.route,
                      params: route.params
                   }">
        <icon-sax :hover="false"
                  :type="j.icon"
                  class="ft-20" />
        <span v-html="getSubject(j)"
              class="menu-title text-truncate" />
        <span v-if="isBillboardBadge(j.id)"
              class="qs" />
      </router-link>
      <a @click="onExpand(j)"
         v-else-if="getChild(j).length > 0"
         class="d-flex left-slider-sub align-items-center"
         :class="[getVariant]">
        <icon-sax :hover="false"
                  :type="j.icon"
                  class="ft-20" />
        <span v-html="getSubject(j)"
              class="menu-title text-truncate" />
      </a>
      <ul v-if="getChild(j).length > 0 || (isWorkerEnable && isHead)"
          class="menu-content">
        <li v-if="j.id === 15 && isWorkerEnable && isHead"
            :class="{'active': getWorkerPage.includes($route.name as RouteNameEnum)}">
          <router-link class="d-flex align-items-center"
                       @click="onShrinkWorker(j, 'AdminSupperWorker')"
                       :to="{
                          name: 'AdminSupportWorker'
                       }">
            <icon-sax :hover="false"
                      type="circle"
                      class="ft-20" />
            <span v-html="`報修工單`"
                  class="menu-title text-truncate" />
          </router-link>
        </li>
        <li :class="isOpen(v) ? 'active' : null"
            :key="k"
            v-for="(v, k) in j.children.filter(i=>isHasRight(i.id))">
          <router-link class="d-flex align-items-center"
                       @click="onShrink(j, v)"
                       :to="{
                          name: v.route,
                          params: route.params
                       }">
            <icon-sax :hover="false"
                      type="circle"
                      class="ft-20" />
            <span v-html="getSubject(v)"
                  class="menu-title text-truncate" />
          </router-link>
        </li>
      </ul>
    </li>
    <li v-if="!isSimulation"
        @click="logoutIntent"
        class="nav-item">
      <a class="d-flex left-slider align-items-center"
         :class="[getVariant]">
        <icon-sax :hover="false"
                  type="logout"
                  class="ft-20" />
        <span v-html="$t('nav.logout')"
              class="menu-title text-truncate" />
      </a>
    </li>
    <li v-else
        @click="onBack"
        class="nav-item">
      <a class="d-flex left-slider align-items-center"
         :class="[getVariant]">
        <icon-sax :hover="false"
                  type="shield-tick"
                  class="ft-20" />
        <span v-html="$t('nav.head')"
              class="menu-title text-truncate" />
      </a>
    </li>
  </ul>
</template>

<style scoped lang="sass">

@import "@/style/variables.sass"

.vertical-layout
  .main-menu
    .menu-content
      display: none
    &.expanded
      li
        &.open
          .menu-content
            display: block

  &.vertical-overlay-menu
    li
      &.open
        .menu-content
          display: block

.vertical-layout
  .main-menu
    .navigation > li
      margin-bottom: 8px
      //因為他在做hover的時候有個 transform: translateX 的特效
      //變成會往下抖動, 加個 transform: translateY 就不會了
      > a
        transform: translateY(0)

      > .menu-content
        li
          margin-top: 8px
          a
            background: #FFF !important
            padding: 10px 15px 10px 35px !important
            transform: translateY(0)
            i
              margin-top: 0 !important

      &.has-sub
        &.open
          a
            border-radius: .2rem
            background: whitesmoke
            color: #565360
            box-shadow: none
            &::after
              right: 15px !important

          li
            margin-left: 15px
            margin-right: 15px
            box-shadow: unset
            background: unset

            a
              background: transparent !important
              padding-left: 20px !important

            &.active
              a
                background: linear-gradient(118deg, map-get($theme-color, "primary"), map-get($theme-color, "lite")) !important
                box-shadow: 0 0 10px 1px map-get($theme-button, "shadow")
                color: #FFFFFF

      &:not(.has-sub)
        &.active
          a
            background: linear-gradient(118deg, map-get($theme-color, "primary"), map-get($theme-color, "lite"))
            box-shadow: 0 0 10px 1px map-get($theme-button, "shadow")
      a
        border-radius: .2rem !important
        user-select: none
        > i
          margin-top: -4px !important

.main-menu.menu-light .navigation > li > a
  margin: 0 14px

.vertical-overlay-menu .main-menu .navigation > li > a > svg, .vertical-overlay-menu .main-menu .navigation > li > a
  margin: 0 11px
  > i
    width: 30px

//二階icon/subject調整
.vertical-layout
  //全開
  .main-menu
    .navigation
      > li
        > .menu-content
          li
            a
              //subject
              span
                &.menu-title
                  margin-left: 0
  //全開+折疊
  &.menu-collapsed
    .main-menu
      .navigation
        > li
          > .menu-content
            li
              a
                //subject
                span
                  &.menu-title
                    margin-left: -1px

  //最小畫面自動隱藏+打開
  &.menu-open
    .main-menu
      .navigation
        > li
          > .menu-content
            li
              a
                //icon
                i
                  margin-left: -10px
                //subject
                span
                  &.menu-title
                    margin-left: 7px
</style>

