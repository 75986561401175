/**
 * api
 * 封裝
 * @author J
 * @since 2024-04-30 07:34:25
 */

import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios"
import JSONbig from 'json-bigint';
import {ReturnCodeEnum} from "@/v2/enumerate/return/code";
import {useCross} from "@/v2/hooks/cross";
import {AxiosConfiguration} from "@/v2/configuration/axios";
import {useRegion} from "@/v2/hooks/region";
import {useAuthorization} from "@/v2/hooks/authorization";
import {useSweetAlert} from "@/v2/hooks/sweetalert";
import {useIntent} from "@/v2/hooks/intent";
import {Basic} from "@/v2/api/interface";
import {IntentEnum} from "@/v2/enumerate/intent";
import i18n from "@/v2/i18n";

const {
    setErrorCode,
    showLoadingMask,
    hideLoadingMask
} = useCross()

/**
 * 已知的的錯誤代碼
 */
const statusCode: number[] = [
    ReturnCodeEnum.UNKNOWN_ERROR,
    ReturnCodeEnum.OK,
    ReturnCodeEnum.SERVICE_CIRCUIT_BREAKING,
    ReturnCodeEnum.SERVICE_FLOW_CONTROL,
    ReturnCodeEnum.SERVICE_PARAMETER_FLOW_CONTROL,
    ReturnCodeEnum.SERVICE_RULE,
    ReturnCodeEnum.SERVICE_RULE_AUTHORIZATION,
    ReturnCodeEnum.UNAUTHORIZED,
    ReturnCodeEnum.COMPANY_AUTHORIZED_EXPIRE,
    ReturnCodeEnum.FORBIDDEN,
    ReturnCodeEnum.NOT_FOUND,
    ReturnCodeEnum.INTERNAL_SERVER_ERROR,
    ReturnCodeEnum.FEIGN_CONNECT_ERROR
]

const config = {
    // 默認地址請求地址，可在 .env 開頭文件中修改
    baseURL: import.meta.env.VITE_BASE_URL as string,
    // 設置超時時間（10s）
    timeout: 30 * 1000,
    // 跨域時候允許攜帶憑證
    withCredentials: true,
    // 使用 json-bigint 解析響應
    transformResponse: [(data: any) => {
        try {
            return JSONbig.parse(data);
        } catch (e) {
            return data;
        }
    }]
}

//是否為正式區
const production: boolean = import.meta.env.PROD as boolean

//獲取自定義的參數
const getAxiosConfigure = (config: any): AxiosConfiguration => {
    return config.opts
}

class RequestHttp {
    service: AxiosInstance
    public constructor(config: AxiosRequestConfig) {

        // 實例化axios
        this.service = axios.create(config)

        /**
         * 請求攔截器
         * 客戶端發送請求 -> [請求攔截器] -> 服務器
         * token校驗(JWT) : 接受服務器返回的token,存儲到vuex/pinia/本地儲存當中
         */
        this.service.interceptors.request.use(
            (config: AxiosRequestConfig) => {

                const {
                    getToken
                } = useAuthorization()

                const {
                    getCurrentRegion
                } = useRegion()

                //注入 - 存取 - 權杖
                if (getToken.value) config.headers!["Authorization"] = getToken.value

                //注入 - 語言 - 檔頭
                config.headers!["Accept-Language"] = getCurrentRegion.value.key

                //取得設定值
                const axiosConfigure: AxiosConfiguration = getAxiosConfigure(config)

                //啟動讀取遮罩
                if (axiosConfigure?.default?.loading?.mask === true) showLoadingMask()

                //執行指定的ajax前回調
                if (axiosConfigure?.completion?.begin) axiosConfigure.completion.begin(
                    axiosConfigure
                )

                return config

            },
            (error: AxiosError) => {
                return Promise.reject(error)
            }
        )

        /**
         * 響應攔截器
         * 服務器換返回信息 -> [攔截統一處理] -> 客戶端JS獲取到信息
         */
        this.service.interceptors.response.use(
            //正確回應 (status === 200)
            //@ts-ignore
            async (response: AxiosResponse) => {

                //SweetAlert (i18n問題, 不能放外圈)
                const {
                    showSuccess,
                    showWarning,
                    showErrorStatus
                } = useSweetAlert()

                const {
                    onLaunch
                } = useIntent()

                //導入i18n
                const {
                    // @ts-ignore
                    t
                } = i18n.global

                const {
                    data,
                    config
                } = response

                const axiosConfigure: AxiosConfiguration = getAxiosConfigure(config)

                //關閉讀取遮罩
                hideLoadingMask()

                //執行指定的ajax完成後回調
                if (axiosConfigure?.completion?.success) axiosConfigure.completion.success(
                    response,
                    axiosConfigure
                )

                //不執行內建的錯誤
                if (axiosConfigure?.default?.failure === false) {
                    return response
                }

                //全局錯誤信息攔截
                if (data.code != 200) {

                    //外部注入公司阻擋(不給建立相關項目), 返回到附掛頁面
                    if (data.code === 9000) showWarning({
                        subject: data.subject,
                        text: data.text,
                        completion: {
                            hidden: () => onLaunch(IntentEnum.ATTACH)
                        }
                    })
                    //系統訊息 1000,  1001, 2001 等等
                    //直接顯示, 標題/副標題可能會空, 但是這是後端要處理的
                    else if (data.code >= 1000) showWarning({
                        subject: data.subject,
                        text: data.text,
                        completion: {
                            hidden: () => setErrorCode(data.code)
                        }
                    })
                    //如果有回應標題, 且不在已知的的錯誤代碼中 (>=1000, 屬於未知)
                    else if (!statusCode.includes(data.code) && data.subject) showWarning({
                        subject: data.subject,
                        text: data.text,
                        acceptButtonText: axiosConfigure?.default?.button?.accept,
                        confirmButtonText: axiosConfigure?.default?.button?.confirm,
                        cancelButtonText: axiosConfigure?.default?.button?.cancel,
                        completion: {
                            hidden: () => {

                                //如果有指定回調, 執行指定錯誤回調
                                if (axiosConfigure && axiosConfigure.completion?.failure) axiosConfigure.completion!.failure(
                                    data.code,
                                    data
                                )
                                //指定返回代碼, 返回附掛頁面
                                else if ([
                                    ReturnCodeEnum.UNAUTHORIZED,
                                    ReturnCodeEnum.FORBIDDEN,
                                    ReturnCodeEnum.NOT_FOUND
                                ].includes(data.code)) onLaunch(IntentEnum.ATTACH)

                            }
                        }
                    })
                    //已知錯誤代碼攔截
                    else showErrorStatus(data.code)

                }
                //如果是直接使用訊息顯示 (必須不是全局錯誤)
                else if (axiosConfigure?.completion?.done) {

                    //內建執行
                    if (axiosConfigure?.default?.done !== false) showSuccess({
                        subject: t(`status.code.200.subject`),
                        text: t(`status.code.200.text`),
                        acceptButtonText: axiosConfigure?.default?.button?.accept,
                        confirmButtonText: axiosConfigure?.default?.button?.confirm,
                        cancelButtonText: axiosConfigure?.default?.button?.cancel,
                        completion: {
                            hidden: () => axiosConfigure!.completion!.done!(data)
                        }
                    })
                    //指定執行
                    else axiosConfigure!.completion!.done!(data)

                }

                //不論錯誤或都會執行
                if (axiosConfigure?.completion?.final) axiosConfigure.completion.final()

                //成功請求（在頁面上除非特殊情況，否則不用處理失敗邏輯）
                return response

            },
            //失敗回應 (status !== 200)
            async (error: AxiosError) => {

                //SweetAlert (i18n問題, 不能放外圈)
                const {
                    showErrorStatus
                } = useSweetAlert()

                const {
                    response,
                    config
                } = error

                //關閉讀取遮罩
                hideLoadingMask()

                //轉換參數
                const axiosConfigure: AxiosConfiguration = getAxiosConfigure(config)

                //不執行內建的錯誤
                if (!(axiosConfigure?.default?.failure ?? true)) {
                    return false
                }

                //執行指定的ajax錯誤後回調
                if (response?.status && axiosConfigure?.completion?.failure) axiosConfigure.completion?.failure(
                    response?.status,
                    axiosConfigure
                )

                //請求超時單獨判斷，因為請求超時沒有 response, 判504
                if (error.message.includes("timeout")) showErrorStatus(ReturnCodeEnum.GATEWAY_TIMEOUT)
                //網路連線異常，因為請求超時沒有 response, 判502
                else if (error.message.includes("Network Error")) showErrorStatus(ReturnCodeEnum.BAD_GATEWAY)
                //根據响應的錯誤碼, 進行顯示
                else if (Number.isInteger(response?.status)) showErrorStatus(response!.status)
                //服務器結果都沒有返回(可能服務器錯誤可能客戶端斷網), 判500
                else if (!window.navigator.onLine) showErrorStatus(ReturnCodeEnum.INTERNAL_SERVER_ERROR)

                //不論錯誤或都會執行
                if (axiosConfigure?.completion?.final) axiosConfigure.completion.final()

                //測試區顯示錯誤訊息
                if (!production) console.log({
                    'code': error.code,
                    'name': error.name,
                    'message': error.message,
                })

                return false

            }

        )
    }

    /**
     * GET 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    get<T>(url: string, params?: object, _object = {}): Promise<Basic.Data<T>> {
        return this.service.get(url, { params, ..._object })
    }

    /**
     * GET 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    post<T>(url: string, params?: object, _object = {}): Promise<Basic.Data<T>> {
        return this.service.post(url, params, _object)
    }

    /**
     * PUT 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    put<T>(url: string, params?: object, _object = {}): Promise<Basic.Data<T>> {
        return this.service.put(url, params, _object)
    }

    /**
     * DELETE 存取
     * @param url 存取網址
     * @param params 存取參數
     * @param _object config
     */
    delete<T>(url: string, params?: any, _object = {}): Promise<Basic.Data<T>> {
        return this.service.delete(url, { ..._object , data: params })
    }

}

export default new RequestHttp(config)