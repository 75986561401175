<template>
  <div :class="[
        `mt-${props.marginTop}`,
        `mb-${props.marginBottom}`
       ]"
       ref="component"
       class="qrcode d-flex-column d-flex justify-content-center align-items-center">
    <div v-if="!active.passwd"
         class="s5">
      <img class="p"
           :class="[
               isBlobLoaded ? `active` : null,
               props.isSwal ? `swal-embedded` : null
           ]"
           :src="getImage" alt="">
      <div v-if="active.status === EnumQrcodeStatus.EXPIRE"
           class="s7 d-flex d-flex-column justify-content-center align-items-center">
        <a class="avatar delegate bg-light-danger float-start waves-effect waves-float waves-light">
          <div class="avatar-content">
            <icon-sax type="security-safe" />
          </div>
        </a>
        <p class="ft-13 m-b-3 text-danger"
           v-html="$t(`component.qrcode.status.expire.subject`)" />
        <small @click="fetch"
               class="cursor hand text-hyperlink"
               v-html="$t(`component.qrcode.status.expire.text`)" />
      </div>
      <div v-else-if="active.status === EnumQrcodeStatus.INSPECTION"
           class="s7 d-flex d-flex-column justify-content-center align-items-center">
        <a class="avatar delegate bg-light-success float-start waves-effect waves-float waves-light">
          <div class="avatar-content">
            <icon-sax type="shield-tick" />
          </div>
        </a>
        <p class="ft-13 m-b-3 text-success"
           v-html="$t(`component.qrcode.status.inspection.subject`)" />
        <small class="text-secondary"
               v-html="$t(`component.qrcode.status.inspection.text`)" />
      </div>
      <div v-else-if="active.status === EnumQrcodeStatus.AUTHORIZED"
           class="s7 d-flex d-flex-column justify-content-center align-items-center">
        <a class="avatar delegate bg-light-success float-start waves-effect waves-float waves-light">
          <div class="avatar-content">
            <icon-sax type="check" />
          </div>
        </a>
        <p class="ft-13 m-b-3 text-success"
           v-html="$t(`component.qrcode.status.authorize.subject`)" />
        <small class="text-secondary"
               v-html="$t(`component.qrcode.status.authorize.text`)" />
      </div>
    </div>
    <div v-else
         class="s5 w-100">
      <form-password
          name="passwd"
          @keyup="onKeyUp"
          @keydown.stop.prevent.enter="emit(`submit`, field.passwd)"
          :bottom="0"
          :source="field"
          :holder="props.placeholder" />
    </div>
    <div v-if="!props.isSwal"
         class="mt-2 d-flex d-flex-column justify-content-center">
      <p class="m-0"
         v-html="sprintf(
             $t(getSubject),
             props.data.organization.name)" />
      <div class="text-center m-t-10">
        <router-link
            target="_blank"
            :to="{name: `App`}"
            class="logo badge badge-light-muted cursor-pointer">
          <img v-if="props.data.organization.uuid"
               :src="`/img/organization/${props.data.organization.seq}/logo.png`"
               alt="">
          <span class="ft-12 text-secondary m-l-5"
                v-html="sprintf(
                    $t(getText),
                    props.data.organization.name)" />
        </router-link>
      </div>
    </div>
    <div class="mt-2" v-else>
      <small v-if="!active.passwd"
             class="text-muted text-center">
        {{$t(`component.swal.to.qrcode.subject`)}}
        <span @click="onChangeMode"
              class="text-hyperlink cursor hand"
              v-html="$t(`component.swal.to.qrcode.text`)" />
      </small>
      <small v-else
             class="text-muted text-center">
        {{$t(`component.swal.to.passwd.subject`)}}
        <span @click="onChangeMode"
              class="text-hyperlink cursor hand"
              v-html="$t(`component.swal.to.passwd.text`)" />
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">

import $ from "jquery"

import IconSax from "@/component/IconSax.vue"
import FormPassword from "@/component/form/FormPassword.vue"
import factoryLoading from "@/asset/loading/Spinner-1s-200px.png"
import {sprintf} from "sprintf-js"
import {computed, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue"
import {WebsocketData} from "@/api/interface/websocket"
import {Session} from "@/api/interface/session"
import {useUtils} from "@/hook/useUtils"
import {useWebsocket} from "@/hook/useWebsocket"
import {usePermission} from "@/hook/usePermission"
import {useRouter} from "vue-router"
import {useUser} from "@/hook/useUser"
import {useSetting} from "@/hook/useSetting"
import {EnumQrcodeMethod, EnumQrcodeStatus} from "@/enum/qrcode";
import {EnumUserSettingAuthorized} from "@/enum/user";

const {
  getUser,
  setUserMapping
} = useUser()

const {
  isLogin,
  getToken,
  setToken
} = usePermission()

const {
  setSettingAuthorized
} = useSetting()

const {
  getUuid
} = useUtils()

const {
  setWebsocketConnect,
  setWebsocketDisconnect
} = useWebsocket()

/**
 * 獲取網址的類型
 */
enum Url {

  /**
   * websocket
   */
  WEBSOCKET = 1,

  /**
   * qrcode
   */
  QRCODE = 2

}

const router = useRouter()

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "submit", passwd: string): void
  (e: "cancel"): void
  (e: "authorize"): void
  (e: "keyup", passwd: string): void
  (e: "changeMode", mode: boolean): void
}>()

const component = ref()

const props = withDefaults(defineProps<{
  //傳入前置參數
  data: Session.Login
  //授權完成延遲關閉 (為了看特效, 不需要可以設定0)
  delay?: number
  //是否為管理員綁定
  isMapping?: boolean
  //是否為嵌入sweet alert
  isSwal?: boolean
  //取消時順便關閉swal
  isCancelClose?: boolean
  //頂部加塞
  marginTop?: number
  //底部加塞
  marginBottom?: number
  //改為密碼輸入時的占位符
  placeholder?: string
}>(), {
  data: () => {
    return {
      organization: {
        uuid: null,
        name: null
      },
      available: true,
      uuid: null,
      name: null,
      avatarUrl: null
    }
  },
  delay: 550,
  isMapping: false,
  isSwal: false,
  isCancelClose: false,
  marginTop: 0,
  marginBottom: 2,
  placeholder: ""
})

const field = reactive<{
  passwd: string
}>({
  passwd: ""
})

const active = reactive<{
  //狀態
  status: EnumQrcodeStatus | null
  //是否為密碼輸入模式, 需要 props.isSwal === true 才有效
  passwd: boolean
  //超時計數器
  timer: any
  //連線權證
  token: string | null
  //二維碼超時時間
  expire: number
  //二維碼base64內容
  blob: string | null
}>({
  status: null,
  passwd: false,
  timer: null,
  token: null,
  expire: parseInt(import.meta.env.VITE_FACTORY_QRCODE_EXPIRE),
  blob: null
})

//密碼監聽
const onKeyUp = () => emit(`keyup`, field.passwd)

//切換模式監聽
const onChangeMode = () => {
  active.passwd = !active.passwd
  emit("changeMode", !active.passwd)
}

//掃碼檢驗成功, UI顯示掃碼成功
const inspection = () => active.status = EnumQrcodeStatus.INSPECTION

//用戶端在App上按確認授權 (完成準備跳轉)
const authorize = (e: WebsocketData) => {

  //顯示登入成功
  active.status = EnumQrcodeStatus.AUTHORIZED

  setTimeout(()=>{

    //如果是綁定
    if (props.isMapping) {

      //強迫登入改為Qrcode預設
      setSettingAuthorized(EnumUserSettingAuthorized.QRCODE)

      //修改為用戶與管理員對應成功
      setUserMapping(true)

    }
    //如果是二級驗證
    else setToken(e.content.token!)

    //非 sweet alert 崁入, 跳轉
    if (!props.isSwal) router.push({
      name: `AdminDashboard`,
    })
    //sweet alert 崁入, 呼叫 emit
    else emit(`authorize`)

  }, props.delay)

}

//取消
const cancel = () => {

  //如果是 sweet alert 崁入 + 指定手機取消關閉, 傳送關閉訊號
  if (props.isSwal && props.isCancelClose) emit(`cancel`)
  //其他狀況, 呼叫超時即可
  else expire()

}

//設定超時
const expire = () => {

  //清除計時器
  clearInterval(active.timer)

  //狀態 - 已經超時
  active.status = EnumQrcodeStatus.EXPIRE

}

//獲取二維碼
const fetch = () => {

  //清除blob (讓讀取轉動重轉)
  active.blob = null

  //清除狀態
  active.status = null

  //清除計時器
  clearInterval(active.timer)

  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      //填入二維碼的blob
      active.blob = reader.result as string
      //監控超時
      active.timer = setInterval(()=>expire(), active.expire)
    }
    reader.readAsDataURL(xhr.response)
  }

  const token = getToken.value

  xhr.open('POST', getUrl(Url.QRCODE))
  xhr.responseType = 'blob'
  xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8")
  if (isLogin.value) xhr.setRequestHeader("Authorization", token as string)
  xhr.send(JSON.stringify({
    "method": props.isMapping ? EnumQrcodeMethod.MAPPING : EnumQrcodeMethod.FACTORY,
    "token": active.token,
    "uuid": props.data.uuid,
    "organization": props.data.organization.uuid
  }))

}

//獲取網址
const getUrl = (
    e: Url
) => {
  if (e === Url.QRCODE) return `${import.meta.env.VITE_BASE_URL as string}qrcode/factory`
  return `${import.meta.env.VITE_SOCKET_URL as string}websocket/notify/${active.token}`
}

//獲取圖像內容
const getImage = computed((): string => {
  if (active.blob) return active.blob
  return factoryLoading
})

const getSubject = computed(()=>{
  if (props.isMapping) return `component.qrcode.mapping.subject`
  return `component.qrcode.login.subject`
})

const getText = computed(()=>{
  if (props.isMapping) return `component.qrcode.mapping.text`
  return `component.qrcode.login.text`
})

//是否二維碼載入成功
const isBlobLoaded = computed((): boolean =>{
  return !["", null].includes(active.blob)
})

//切換到密碼輸入的時候, 自動focus密碼框
watch(()=>active.passwd, async (e: boolean) => {
  if (e) {
    await nextTick()
    $(".el-input__inner", $(component.value)).focus()
  }
})

onMounted(async () => {

  active.token = getUuid(32, true)

  setWebsocketConnect(getUrl(Url.WEBSOCKET), {}, (e: WebsocketData) => {

    //掃碼檢驗成功, UI顯示掃碼成功
    if (e.content.command === "factoryInspection") inspection()
    //用戶端在App上按確認授權
    else if (e.content.command === "factoryAuthorize") authorize(e)
    //用戶端在App上取消
    else if (e.content.command === "factoryCancel") cancel()

  })

  //首次獲取驗證二維碼
  setTimeout(() => fetch(), 300)

})

onUnmounted(()=>{

  //清除計時器
  if (active.timer) clearInterval(active.timer)

  //切斷連線
  setWebsocketDisconnect()

})

</script>

<style scoped lang="sass">
.qrcode
  .s5
    position: relative
    .s7
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      background-color: rgba(255, 255, 255, .975)
      .avatar
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 15px
        width: 52px
        height: 52px
    img
      &.p
        width: 150px
        height: 150px
        margin: 35px
        &.active
          width: 220px
          height: 220px
          margin: 0
          box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%), 0 2px 4px 0 rgb(34 41 47 / 8%)
        &.swal-embedded
          width: 100px
          height: 100px
          &.active
            width: 170px
            height: 170px
  .logo
    border-radius: 35px
    padding: 10px 15px
    > span
      position: relative
      top: 1px
    > img
      width: 20px
      height: 20px
      border-radius: 3px

.dark-layout
  .qrcode
    .s5
      .s7
        background-color: rgba(40, 48, 70, 0.98)
      img
        &.p
          &.active
            box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.75), 0 2px 4px 0 rgba(34, 41, 47, 0.75)
</style>
