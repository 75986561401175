import _ from "lodash"

import {
    computed
} from "vue";

import {
    BillboardStore
} from "@/store/modules/billboard";

import {
    BillboardState
} from "@/store/interface/billboard";
import {BillboardStatistics} from "@/api/interface/billboard/statistics";

/**
 * 公告欄
 */

export const useUserBillboard = () => {

    /**
     * 公告欄 store
     */
    const billboardStore = BillboardStore()

    /**
     * 獲取 - 用戶 - 公告欄 - 資訊
     */
    const getUserBillboard = computed((): BillboardStatistics => {
        return billboardStore.getAll
    })

    /**
     * 更新 - 用戶 - 公告欄 - 資訊
     */
    const setUserBillboard = (
        e: any
    ) => billboardStore.setUpdate(e)

    /**
     * 更新 - 用戶 - 公告欄 - 讀取
     */
    const setUserBillboardRead = (
        uuid: any
    ) => {

        const r = _.cloneDeep(getUserBillboard.value)
        r.summary.unread = r.summary.unread - 1
        r.summary.message = r.summary.message.filter(i=>i.uuid !== uuid)
        setUserBillboard(r)

    }

    return {
        getUserBillboard,
        setUserBillboard,
        setUserBillboardRead
    }

}
