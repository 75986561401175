/**
 * 客服管理 - 意見與建議
 */
export default {

    /**
     * 列表
     */
    index: {
        text: {
            group: "管理團隊"
        },
        field: {
            head: {
                church: "指定教會"
            },
            avatar: "頭像",
            name: "名字",
            content: "內容",
            level: "層級"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名字",
            level: "請選擇層級"
        },
        handler: {
            raise: {
                subject: "即將反饋至愛無界管理團隊",
                text: "請認後執行",
                description: "",
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            unread: "請注意, 這個反饋還未標示已讀取",
        },
        tabs: {
            0: {
                subject: "基本"
            },
            99: {
                subject: "相關資訊"
            }
        },
        text: {
            group: "管理團隊"
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            level: "層級",
            id: "會友ID",
            no: "會友編號",
            name: "會友姓名",
            account: "會友帳號",
            content: "反饋內容",
            created: "建立時間",
            status: "狀態"
        },
        holder: {
        },
        handler: {
            update: {
                subject: "即將更新反饋內容",
                text: "請確認後執行",
                description: "",
                raise: {
                    subject: "即將更新反饋內容",
                    text: `<b class="ft-20 text-danger">即將反饋至愛無界管理團隊, 請認後執行</b>`,
                    description: "",
                }
            }
        },
        error: {
            1001: {
                "subject": "請選擇層級",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
        }
    }

}
