import {v2} from "@/language/modules/page/admin/notification/v2/zhTW";

/**
 * 推播管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增推播"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            create: {
                begin: "建立日期(起)",
                end: "建立日期(迄)"
            },
            send: {
                begin: "排程日期(起)",
                end: "排程日期(迄)"
            },
            subject: "標題",
            quantity: "數量",
            schedule: "排程時間",
            ref: "類型",
            kind: "對象",
            progress: "進度"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            subject: "請輸入標題",
            create: {
                begin: "請選擇建立日期(起)",
                end: "請選擇建立日期(迄)"
            },
            send: {
                begin: "請選擇排程日期(起)",
                end: "請選擇排程日期(迄)"
            },
            ref: "請選擇類型",
            kind: "請選擇對象"
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除推播, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tip: {
            kind: "請注意, 推播類型建立後將無法變更"
        },
        tabs: {
            0: {

                subject: "基本"
            },
            1: {
                subject: "名單"
            }
        },
        help: {
            church: "請謹慎使用指定教會功能",
        },
        option: {
            all: "全部會友名單"
        },
        field: {
            church: "指定教會",
            excluded: "排除機構",
            belong: {
                no: "所屬教會ID",
                name: "所屬教會名稱"
            },
            kind: "對象",
            subject: "標題",
            schedule: "排程時間",
            text: "簡述",
            content: "內容",
            keyword: "搜尋名單"
        },
        holder: {
            church: "請選擇指定教會",
            excluded: "選中的機構將不會發送推播訊息",
            belong: {
                no: "",
                name: ""
            },
            kind: "請選擇對象",
            subject: "請輸入標題",
            schedule: "請選擇排程時間",
            text: "請輸入簡述",
            content: "請輸入內容",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            store: {
                subject: "即將新增推播內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇對象",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇排程時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入簡述",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請指定教會",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": `請至少選擇 <b class="ft-24 text-danger">1</b> 位會友進行推播`,
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            progress: "系統已經正在推播, 無法編輯或刪除",
            complete: "系統已經推播完成, 無法編輯或刪除"
        },
        tabs: {
            0: {

                subject: "基本"
            },
            1: {
                subject: "名單"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            belong: {
                no: "所屬教會ID",
                name: "所屬教會名稱"
            },
            excluded: "排除機構",
            kind: "對象",
            subject: "標題",
            schedule: "排程時間",
            text: "簡述",
            content: "內容",
            keyword: "搜尋名單"
        },
        holder: {
            belong: {
                no: "",
                name: ""
            },
            excluded: "選中的機構將不會發送推播訊息",
            kind: "請選擇對象",
            subject: "請輸入標題",
            schedule: "請選擇排程時間",
            text: "請輸入簡述",
            content: "請輸入內容",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            update: {
                subject: "即將更新推播內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇對象",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇排程時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入簡述",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請指定教會",
                "text": "",
                "description": ""
            },
            2001: {
                "subject": `請至少選擇 <b class="ft-24 text-danger">1</b> 位會友進行推播`,
                "text": "",
                "description": ""
            }
        }
    }

}
