/**
 * 枚舉
 * 權限
 * @author J
 * @since 2024-05-23 09:24:03
 */

/**
 * 權限
 * ⚠️⚠️ 注意 ⚠️⚠️
 * rawValue 不能改順序, 只能往下加
 */
export enum PermissionEnum {

    /**
     * 推播通知管理
     */
    ADMIN_NOTIFICATION = 900,

    /**
     * 服事管理
     */
    ADMIN_EVENT = 1000,

    /**
     * 聚會管理
     */
    SHEPHERD_ATTEND = 5500

}

/**
 * 權限 - 集合
 * ⚠️⚠️ 注意 ⚠️⚠️
 * rawValue 不能改順序, 只能往下加
 * =================================================================================================
 * 使用方式
 * a & b > 0 代表有權限
 * [a]是累加權限值, [b]為指定的權限值
 * [1(總覽)+2(新增)] & 2(是否有新增) > 0 代表有權限
 * [1(總覽)+2(新增)] & 4(是否有儲存) == 0 代表沒有權限
 * [1(總覽)+4(新增)] & 2(是否有新增) == 0 代表沒有權限
 */
export enum PermissionCollectionEnum {

    /**
     * 總覽
     */
    LIST = 1,

    /**
     * 新增
     */
    CREATE = 2,

    /**
     * 儲存
     */
    STORE = 4,

    /**
     * 編輯
     */
    EDIT = 8,

    /**
     * 更新
     */
    UPDATE = 16,

    /**
     * 刪除
     */
    DESTROY = 32,

    /**
     * 匯出
     */
    DOWNLOAD = 64

}