import piniaPersistConfig from "@/config/piniaPersist";
import {defineStore} from "pinia";
import {SettingState} from "@/store/interface/setting";
import {EnumTheme} from "@/enum/theme";
import {EnumUserSettingAuthorized, EnumUserSettingOpen} from "@/enum/user";
import {EnumWebsocketStatus} from "@/enum/websocket";

const id: string = "SettingStore";

/**
 * 用戶設置
 */
export const SettingStore = defineStore({
    id: id,
    state: (): SettingState => ({
        theme: EnumTheme.LIGHT,
        region: "zhTW",
        websocket: {
            connected: 0
        },
        authorized: EnumUserSettingAuthorized.PASSWD,
        entries: {
            edit: EnumUserSettingOpen.SELF
        }
    }),
    getters: {
        isDarkTheme: state => {
            return state.theme === EnumTheme.DARK
        },
        getRegion: state => {
            return state.region
        },
        getAll: state => {
            return state
        }
    },
    actions: {
        setTheme(theme: EnumTheme) {
            this.theme  = theme
        },
        setRegion(region: string) {
            this.region = region;
        },
        setWebsocketConnectStatus(e: EnumWebsocketStatus) {
            this.websocket.connected = e
        },
        setAuthorized(e: EnumUserSettingAuthorized) {
            this.authorized = e
        },
        setEntriesEdit(e: EnumUserSettingOpen) {
            this.entries.edit = e
        },
    },
    persist: piniaPersistConfig(id)
});
