/**
 * 客服管理
 */
export default {
    subject: "客服管理",
    worker: {
        index: "维修工单",
        create: "工单创建",
        edit: {
            subject: "工单内容",
            history: {
                index: "历史纪录",
                create: "纪录创建",
                edit: "纪录内容"
            },
            record: {
                index: "异动纪录"
            }
        }
    },
    history: {
        index: "帐户纪录",
        edit: "纪录内容",
        permission: "客服管理 - 帐户纪录"
    },
    feedback: {
        index: "意见与建议",
        edit: "反馈内容",
        permission: "客服管理 - 意见与建议"
    },
    coffee: {
        index: "请我们喝咖啡",
        edit: "赞助内容",
        permission: "客服管理 - 请我们喝咖啡"
    }
}
