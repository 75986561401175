<template>
  <router-view />
</template>
<script setup lang="ts">

import {useMenu} from "@/hook/useMenu"

import {onMounted} from "vue"

const {
  setOverlay
} = useMenu()

onMounted(()=>setOverlay(false))

</script>
