import advance from "@/language/modules/page/shepherd/client/advance/zhTW";
import attend from "@/language/modules/page/shepherd/client/attend/zhTW";

/**
 * 會友
 */
export default {

    /**
     * 列表
     */
    index: {
        download: {
            name: "檔案匯出_會友管理",
        },
        more: {
          on: "更多選項",
          off: "收起選項"
        },
        help: {
            manager: "為該族群的託管人員",
            stranger: "未跟進",
            archive: "不跟進"
        },
        feature: {
            create: {
                subject: "新增會友"
            },
            advance: {
                subject: "進階功能"
            }
        },
        advance: {
            track: "已跟進會友",
            stranger: "未跟進會友",
            mark: "已標註會友",
            archive: "不跟進會友"
        },
        field: {
            head: {
                church: "指定教會"
            },
            avatar: "頭像",
            name: "名字",
            email: "電子郵件",
            team: "族群",
            mark: "加註",
            connect: "連結",
            title: "稱謂",
            country: "所在區域",
            city: "所在城市",
            occupation: "職業",
            education: "教育程度",
            deacon: "五重執事",
            marital: "婚姻狀況"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名字",
            email: "請輸入電子郵件",
            team: "請選擇需要篩選的族群",
            title: "請選擇稱謂",
            country: "請選擇所在區域",
            city: "請選擇所在城市",
            occupation: "請選擇職業",
            education: "請選擇教育程度",
            deacon: "請選擇五重執事",
            marital: "請選擇婚姻狀況"
        },
        handler: {
            enable: {
                subject: "即將啟用會友",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用會友",
                text: "請確認後執行",
                description: ""
            },
            mark: {
                add: {
                    subject: "即將對此會友進行加註",
                    text: "請確認後執行",
                    description: ""
                },
                remove: {
                    subject: "即將對此會友移除加註",
                    text: "請確認後執行",
                    description: ""
                }
            }
        },
        factory: {
            export: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將進行會友匯出, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            },
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除會友, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 編輯
     */
    edit: {

        /**
         * 聚會
         */
        attend: attend,

        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這名會友已經停用"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "族群"
            },
            2: {
                subject: "相關歷程"
            },
            99: {
                subject: "相關資訊"
            }
        },
        history: {
            course: {
                subject: "課程"
            },
            reunion: {
                subject: "聚會"
            }
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            avatar: "頭像",
            name: "名字",
            nickname: "英文名字/暱稱",
            birthday: "生日",
            email: "電子郵件",
            deacon: "五重執事",
            title: "稱謂",
            education: "教育程度",
            marital: "婚姻狀況",
            occupation: "職業",
            mobile: "手機",
            contact: "其他電話",
            country: "所在區域",
            city: "所在城市",
            address: "居住地址",
            school: "最高學校名稱",
            consort: {
                name: "配偶名字",
                contact: "配偶聯絡方式"
            },
            commitment: "委身編號",
            guardianship: {
                name: "監護人名字",
                relationship: "監護人關係",
                contact: "監護人聯絡方式",
            },
            church: {
                enter: {
                    date: "進入教會日期"
                },
                leave: {
                    date: "離開教會日期",
                    reason: "離開教會原因"
                }
            },
            baptized: {
                is: "是否受洗",
                date: "受洗日期",
                church: "受洗教會"
            },
            note: "備註"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            head: "未輸入",
            name: "請輸入名字",
            nickname: "請輸入英文名字/暱稱",
            birthday: "請選擇生日",
            email: "請輸入電子郵件",
            deacon: "請選擇五重執事",
            title: "請選擇稱謂",
            education: "請選擇教育程度",
            marital: "請選擇婚姻狀況",
            occupation: "請選擇職業",
            mobile: "請輸入手機",
            commitment: "請輸入委身編號",
            contact: "請輸入其他電話",
            country: "請選擇所在區域",
            city: "請選擇所在城市",
            address: "請輸入居住地址",
            school: "請輸入最高學校名稱",
            consort: {
                name: "請輸入配偶名字",
                contact: "請輸入配偶聯絡方式"
            },
            guardianship: {
                name: "請輸入監護人名字",
                relationship: "請輸入監護人關係",
                contact: "請輸入監護人聯絡方式",
            },
            church: {
                enter: {
                    date: "請選擇進入教會日期"
                },
                leave: {
                    date: "請選擇離開教會日期",
                    reason: "請輸入離開教會原因"
                }
            },
            baptized: {
                is: "請填寫是否受洗",
                date: "請選擇受洗日期",
                church: "請輸入受洗教會"
            },
            note: "請輸入備註"
        },
        handler: {
            update: {
                subject: "即將更新會友內容",
                text: "請確認後執行",
                description: ""
            }
        }
    },

    /**
     * 進階
     */
    advance: advance

}
