/**
 * 國際化
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {createI18n} from "vue-i18n"
import {zhTW} from "@/v2/i18n/modules/zhTW";
import {zhCN} from "@/v2/i18n/modules/zhCN";

const fallbackLocale: string = import.meta.env.VITE_FALLBACK_LOCALE

/**
 * 獲取localStorage中記住的語系
 * 因為pinia需要套Composition API使用, 否則會報錯
 * 所以寫個方法直接從localStorage取
 */
const getLocale = (): string => {
    const db = localStorage.getItem('setting')
    if (db===null) {
        return fallbackLocale
    }
    return JSON.parse(db).region
}

const i18n= createI18n({
    //關閉 `Recommend not using HTML messages to avoid XSS.` 警告
    warnHtmlMessage: false,
    //切換語系失敗, 預設的語言
    fallbackLocale: fallbackLocale,
    //要使用compositionAPI, 需要設定false
    legacy: false,
    //預設語系
    locale: getLocale(),
    //註冊全局$t方法
    globalInjection: true,
    //載入的語言檔案
    messages: {
        zhTW,
        zhCN
    }
});

export default i18n