export default {
    category: {
        index: "分类管理",
        edit: "分类内容",
        create: "分类创建"
    },
    index : {
        index: "文章管理",
        edit: "文章内容",
        create: "文章创建"
    }
}
