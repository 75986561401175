import {computed} from "vue";
import {RouteNameEnum} from "@/v2/enumerate/route";
import {TablerIconEnum} from "@/v2/enumerate/component/tabler";
import {AdminSupportWorkerMimeEnum, AdminSupportWorkerStatusEnum} from "@/v2/enumerate/core/admin/support/worker";

/**
 * 實例
 */
export const useWorker = () => {

    /**
     * 維護工單的頁面
     */
    const getPage = computed((): RouteNameEnum[] => [
        RouteNameEnum.ADMIN_SUPPORT_WORKER,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_CREATE,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY_CREATE,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_HISTORY_EDIT,
        RouteNameEnum.ADMIN_SUPPORT_WORKER_EDIT_RECORD
    ])

    /**
     * 取得 - 附件 - 類型 - 圖示
     */
    const getMimeBadge = computed((): (e: AdminSupportWorkerMimeEnum | null) => TablerIconEnum => {
        return (e) => {
            if (e === AdminSupportWorkerMimeEnum.FILE) {
                return TablerIconEnum.PAPERCLIP
            }
            else if (e === AdminSupportWorkerMimeEnum.IMAGE) {
                return TablerIconEnum.PHOTO
            }
            else if (e === AdminSupportWorkerMimeEnum.LINK) {
                return TablerIconEnum.LINK
            }
            else if (e === AdminSupportWorkerMimeEnum.YOUTUBE) {
                return TablerIconEnum.BRAND_YOUTUBE
            }
            return TablerIconEnum.QUESTION_MARK
        }
    })

    /**
     * 取得 - 狀態 - 圖示
     */
    const getStatusBadge = computed((): (e: AdminSupportWorkerStatusEnum) => TablerIconEnum => (e) => {

        //回報類
        if ([
            AdminSupportWorkerStatusEnum.COMPLETE,
            AdminSupportWorkerStatusEnum.BACK_COMPLETE
        ].includes(e)) {
            return TablerIconEnum.FLAG
        }
        //確認類
        else if ([
            AdminSupportWorkerStatusEnum.COMPLETE_ACCEPT,
            AdminSupportWorkerStatusEnum.BACK_COMPLETE_ACCEPT
        ].includes(e)) {
            return TablerIconEnum.CHECK
        }
        //返工
        else if (e === AdminSupportWorkerStatusEnum.BACK) {
            return TablerIconEnum.ARROW_BACK_UP
        }
        //補充資料
        else if (e === AdminSupportWorkerStatusEnum.REPLENISH) {
            return TablerIconEnum.BOOK_UPLOAD
        }

        //其他 (新增)
        return TablerIconEnum.PLUS

    })

    /**
     * 啟用
     */
    const isEnable = computed((): boolean => (import.meta.env.VITE_SUPPORT_WORKER as string) === 'true')

    /**
     * 為返工系列
     */
    const isBackStatus = computed((): (e: AdminSupportWorkerStatusEnum) => boolean => (e) => [
        AdminSupportWorkerStatusEnum.BACK,
        AdminSupportWorkerStatusEnum.BACK_COMPLETE,
        AdminSupportWorkerStatusEnum.BACK_COMPLETE_ACCEPT
    ].includes(e))

    /**
     * 為回報系列
     */
    const isReportStatus = computed((): (e: AdminSupportWorkerStatusEnum | null) => boolean => (e) => {

        if (e === null) {
            return false
        }

        return [
            AdminSupportWorkerStatusEnum.COMPLETE,
            AdminSupportWorkerStatusEnum.BACK_COMPLETE
        ].includes(e)

    })

    return {
        getPage,
        getMimeBadge,
        getStatusBadge,
        isEnable,
        isBackStatus,
        isReportStatus
    }

}