export const v2: {
    [key: string]: any
} = {

    zoom: {
        in: {
            subject: "放大"
        },
        out: {
            subject: "缩小"
        }
    },
    resize: {
        subject: "还原缩放"
    },
    download: {
        subject: "下载"
    },
    rotate: {
        left: {
            subject: "逆时针旋转"
        },
        right: {
            subject: "顺时针旋转"
        }
    }

}
