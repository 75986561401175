/**
 * 本地資料庫
 * 授權
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {defineStore} from "pinia"
import {PiniaEnum} from "@/v2/enumerate/pinia";
import {Authorization} from "@/v2/api/interface/authorization";
import PiniaPersistConfiguration from "@/v2/configuration/pinia";

export const AuthorizationStore = defineStore({
    id: PiniaEnum.AUTHORIZATION,
    state: (): Authorization => ({
        token: null
    }),
    getters: {
        //是否正常登入
        isLogin: state => {
            return ![null, ""].includes(state.token)
        },
        getToken: state => {
            return state.token
        }
    },
    actions: {
        setToken(token: string | null) {
            this.token = token
        },
        setData(value: Authorization) {
            Object.keys(value).forEach((i: string)=>{
                if (this[i] !== undefined) this[i] = value[i]
            })
        }
    },
    persist: PiniaPersistConfiguration(PiniaEnum.AUTHORIZATION)
})