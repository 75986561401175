<template>
  <li @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
      class="nav-item d-lg-block theme">
    <el-tooltip
        :content="$t(`component.theme.${ isDarkTheme ? `light` : `dark` }`)"
        class="box-item"
        placement="bottom-end">
      <a class="d-flex align-items-center justify-content-center">
        <icon-sax
            class="ft-22 text-secondary"
            :type="isDarkTheme ? `sun` : `moon`" />
      </a>
    </el-tooltip>
  </li>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"

import {useSetting} from "@/hook/useSetting"
import {EnumTheme} from "@/enum/theme";

const {
  isDarkTheme,
  setTheme
} = useSetting()

</script>

<style scoped lang="sass">
</style>
