/**
 * 公告欄
 */
export default {

    /**
     * 列表
     */
    index: {
        uncheck: "未讀",
        empty: "暫時沒有任何公告",
        not: {
            found: "找不到指定的公告"
        }
    },

    /**
     * 編輯
     */
    edit: {
        read: `%s・共計 <b class="%s">%s</b> 次閱讀`
    }

}
