/**
 * 枚舉
 * 讀取 - 遮罩
 * @author J
 * @since 2024-07-01 19:29:25
 */

/**
 * 類型
 */
export enum LoadingMaskModeEnum {

    /**
     * 鎖屏
     */
    FULL,

    /**
     * 手動
     */
    MANUAL,

    /**
     * 靜態
     */
    STATIC

}

/**
 * 尺寸
 */
export enum LoadingMaskSizeEnum {

    /**
     * 大號
     * 預設值
     */
    LARGE = 64,

    /**
     * 中號
     */
    MIDDLE = 32,

    /**
     * 小號
     */
    SMALL = 16

}
