<script setup lang="ts">

import JTablerIcon from "@/v2/components/tabler/index.vue";
import {TablerIconEnum} from "@/v2/enumerate/component/tabler";
import {computed, reactive} from "vue";
import {QrcodeStatusEnum} from "@/v2/enumerate/qrcode";

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "refresh"): void
}>()

/**
 * 傳入 - 參數
 */
const props = defineProps<{
  //語言
  i18n: string
  //狀態
  status: QrcodeStatusEnum | null
}>()

/**
 * 本地 - 參數
 */
const active = reactive<{
  is: {
    qrcode: {
      icon: {
        size: number
      }
    }
  }
}>({
  is: {
    qrcode: {
      icon: {
        size: 28
      }
    }
  }
})

/**
 * 取得 - 圖示
 */
const getIcon = computed((): TablerIconEnum => {

  if (props.status === QrcodeStatusEnum.INSPECTION) {
    return TablerIconEnum.SHIELD_CHECK
  }
  else if (props.status === QrcodeStatusEnum.AUTHORIZED) {
    return TablerIconEnum.CHECK
  }
  return TablerIconEnum.SHIELD_LOCK

})

/**
 * 取得 - 前置
 */
const getPrefix = computed((): string => {

  if (props.status === QrcodeStatusEnum.INSPECTION) {
    return `${props.i18n}.status.inspection`
  }
  else if (props.status === QrcodeStatusEnum.AUTHORIZED) {
    return `${props.i18n}.status.authorize`
  }
  return `${props.i18n}.status.expire`

})

</script>

<template>
  <div v-if="props.status !== null"
       class="s7 d-flex d-flex-column justify-content-center align-items-center">
    <a class="avatar delegate bg-light-danger float-start waves-effect waves-float waves-light">
      <div class="avatar-content">
        <j-tabler-icon
            :size="active.is.qrcode.icon.size"
            :name="getIcon" />
      </div>
    </a>
    <p class="ft-13 m-b-3"
       :class="props.status === QrcodeStatusEnum.EXPIRE ? 'text-danger' : 'text-success'"
       v-html="$t(`${getPrefix}.subject`)" />
    <!-- 超時 - 點擊刷新 -->
    <small v-if="props.status === QrcodeStatusEnum.EXPIRE"
           @click="emit('refresh')"
           class="cursor hand text-hyperlink"
           v-html="$t(`${getPrefix}.text`)" />
    <!-- 其他 - 提示 -->
    <small v-else
           class="text-secondary"
           v-html="$t(`${getPrefix}.text`)" />
  </div>
</template>

<style scoped lang="scss">
.s7 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .975);
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    width: 52px;
    height: 52px;
  }
}
.dark-layout {
  .s7 {
    background-color: rgba(40, 48, 70, 0.98);
  }
}
</style>