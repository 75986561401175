import app from "@/language/modules/page/app/zhCN"
import mapping from "@/language/modules/page/mapping/zhCN"
import admin from "@/language/modules/page/admin/zhCN"
import login from "@/language/modules/page/login/zhCN"
import forgot from "@/language/modules/page/forgot/zhCN"
import billboard from "@/language/modules/page/billboard/zhCN"
import shepherd from "@/language/modules/page/shepherd/zhCN"

export default {

    /**
     * App下载画面
     */
    app: app,

    /**
     * 用户绑定管理员
     */
    mapping: mapping,

    /**
     * 登入画面
     */
    login: login,

    /**
     * 登入画面
     */
    forgot: forgot,

    /**
     * 公告栏
     */
    billboard: billboard,

    /**
     * 我的教会
     */
    admin: admin,

    /**
     * 牧养系统
     */
    shepherd: shepherd

}
