/**
 * 会友管理
 */
export default {
    index: "会友管理",
    create: "会友创建",
    edit: "会友内容",
    advance: {
        subject: "进阶功能",
        permission: "会友管理 - 进阶功能"
    },
    permission: "会友管理",
    attend: {
        index: "聚会纪录",
        edit: "聚会内容"
    }
    // history: {
    //     course: "课程纪录",
    //     reunion: "聚会纪录"
    // }
}
