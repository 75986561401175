/**
 * 图库管理
 */
export default {
    subject: "图库管理",
    index: "图库清单",
    accept: "图库核准",
    alarm : "图库检举",
    permission: {
        index: "图库管理 - 图库清单",
        accept: "图库管理 - 图库核准",
        alarm: "图库管理 - 图库检举"
    }
}
