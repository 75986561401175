<template>
  <div ref="component"
       :class="[
         props.class,
         `mb-${props.bottom}`
       ]"
       class="form-component">
    <label v-if="props.label !== null"
           class="form-label">
      <span>
        <span class="label-text"
              v-html="props.label" />
        <span v-if="props.text"
              class="text-muted label-description ft-12 m-l-3"
              v-html="props.text" />
        <b v-if="props.must"
           class="must cgRed ft-18 m-l-5">*</b>
      </span>
      <span v-if="$slots.help"
            class="help">
        <slot name="help" />
      </span>
    </label>
    <el-tooltip
        :visible="active.is.error.enable"
        :popper-class="[
          `is-error is-error-from-password`,
          props.parameter.error > 0 ? `messenger error-${props.parameter.error}` : null
        ]"
        placement="top-end">
      <template #content>
        <span @click="props.parameter.error = 0"
              v-html="$t(`${props.prefix}.error.${props.parameter?.error}.subject`)" />
      </template>
      <el-input v-model="props.source[props.name]"
                @click="isStaticTooltip ? props.parameter.error = 0 : null"
                :placeholder="props.holder"
                :type="active.show ? `text` : `password`"
                autocomplete="off"
                clear-icon="Close"
                size="large">
        <template #append>
          <el-tooltip
              class="box-item"
              :content="$t(`component.password.feature.${ active.show ? `hide` : `show` }`)"
              placement="top-start">
            <icon-sax class="ft-20 cursor hand"
                      @click="active.show = !active.show"
                      :hover="false"
                      :type="active.show ? `eye-off` : `eye`" />
          </el-tooltip>
        </template>
      </el-input>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"

import {
  nextTick, reactive,
  ref, watch
} from "vue"
import {useStaticTooltip} from "@/hook/useStaticTooltip"

const component = ref()

const props = withDefaults(defineProps<{
  //必填(*)
  must?: boolean
  //標題
  label?: string | null
  //副標題
  text?: string | null
  //提示字詞
  holder?: string | null
  //鍵值
  name: string
  //傳入值
  source: object
  //語言前置
  prefix?: string
  //控制參數
  parameter?: {
    error: number
  }
  //錯誤數字範圍
  error?: number[]
  //傳入的外框外觀
  class?: string | null
  //外框底部添加高度
  bottom?: number
}>(), {
  must: false,
  prefix: "",
  label: null,
  text: null,
  holder: null,
  parameter: () => {
    return {
      error: 0
    }
  },
  source: () => { return {} },
  error: () => [],
  class: null,
  bottom: 1,
})

const {
  isStaticTooltip
} = useStaticTooltip({
  active: props.parameter,
  prefix: props.prefix
})

//本地端控制參數
const active = reactive<{
  show: boolean
  is: {
    error: {
      enable: boolean
    }
  }
}>({
  show: false,
  is: {
    error: {
      enable: false
    }
  }
})

/**
 * 監控 - 錯誤 - 顯示
 * 因為可能會卡到tab的問題, 導致 el-tooltip 的 teleported 出錯
 * 需要經過 nextTicker() 就會正確了
 */
watch(()=>props.parameter.error, async () => {
  if (props.parameter.error > 0) await nextTick()
  active.is.error.enable = isStaticTooltip.value && props.error.includes(props.parameter.error)
})

</script>

<style scoped lang="sass">
</style>
