import {Group} from "@/language/modules/component/shepherd/v2/attend/group/zhCN"
import {Choose} from "@/language/modules/component/shepherd/v2/attend/choose/zhCN"

export const Attend: {
    [key: string]: any
} = {

    group: Group,

    choose: Choose

}
