import access from "@/language/modules/page/admin/course/access/zhTW";
import authorize from "@/language/modules/page/admin/course/authorize/zhTW";
import exception from "@/language/modules/page/admin/course/exception/zhTW";

/**
 * 歷程管理
 */
export default {

    /**
     * 存取紀錄
     */
    access: access,

    /**
     * 授權紀錄
     */
    authorize: authorize,

    /**
     * 攔截紀錄
     */
    exception: exception

}
