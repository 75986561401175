import {RouteRecordRaw} from "vue-router";

const friend: RouteRecordRaw[] = [{
	//新朋友維護
	path: "friend",
	name: "ShepherdFriend",
	meta: {
		permission: 52001,
		breadcrumb: [{
			key: 'shepherd.friend.index',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/friend/index.vue"),
}, {
	//新朋友編輯
	path: "friend/edit/:uuid",
	name: "ShepherdFriendEdit",
	meta: {
		permission: 52004,
		breadcrumb: [{
			key: 'shepherd.friend.index',
			value: "ShepherdFriend"
		}, {
			key: 'shepherd.friend.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/shepherd/friend/edit.vue"),
}];

export default friend;
