import {computed} from "vue";
import {RouteLocationNormalizedLoaded} from "vue-router";
import {usePermission} from "@/hook/usePermission";
import {useUser} from "@/hook/useUser";
import {EnumPageDisplay} from "@/enum/page";

/**
 * 控制是否顯示頁面
 */
export const useShow = (
    route: RouteLocationNormalizedLoaded
) => {

    const {
        isHead,
        isLogin,
        isHasRight
    } = usePermission()

    const {
        getUser
    } = useUser()

    /**
     * 是否顯示內文
     */
    const isDisplay = computed((): number => {

        // 維修工單
        if (isHead.value && route!.meta.permission === EnumPageDisplay.WORKER) return EnumPageDisplay.NORMAL
        // 不受權限管制的頁面
        else if (route!.meta.permission === EnumPageDisplay.NO_RESTRICTION) return EnumPageDisplay.NORMAL

        //非總教會 -> 教會管理 - 列表 轉 教會管理 - 編輯
        const r: number = route!.name === "AdminChurch" && getUser.value.church.no !== '0000' ?
            3004:
            route!.meta.permission as number

        // 如果被登出, 不顯示內文
        if (!isLogin.value) return EnumPageDisplay.LOGOUT
        // 沒權限不顯示
        else if (!isHasRight.value(r)) return EnumPageDisplay.REJECT
        // 剩下都正常可以顯示
        return EnumPageDisplay.NORMAL
    })

    return {
        isDisplay
    }

}
