export default [{
    value: 0,
    label: "委托管理"
}, {
    value: 1,
    label: "自行维护"
}, {
    value: 99,
    label: "全部"
}]
