import feedback from "@/language/modules/page/admin/support/feedback/zhCN";
import coffee from "@/language/modules/page/admin/support/coffee/zhCN";
import history from "@/language/modules/page/admin/support/history/zhCN";
import {v2} from "@/language/modules/page/admin/support/v2/zhCN";

/**
 * 客服管理
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 意见与建议
     */
    feedback: feedback,

    /**
     * 请我们喝咖啡
     */
    coffee: coffee,

    /**
     * 帐户纪录
     */
    history: history

}
