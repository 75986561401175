<template>
  <div :class="[
        `mt-${props.marginTop}`,
        `mb-${props.marginBottom}`
       ]"
       ref="component"
       class="qrcode d-flex-column d-flex justify-content-center align-items-center">
    <div class="s5 w-100">
      <form-input
          name="key"
          @keyup="onKeyUp"
          @keydown.stop.prevent.enter="emit(`submit`, field.key)"
          :max-length="props.maxLength"
          :bottom="0"
          :source="field"
          :holder="props.placeholder" />
    </div>
  </div>
</template>

<script setup lang="ts">

import FormInput from "@/component/form/FormInput.vue"
import {nextTick, onMounted, reactive, ref} from "vue"
import $ from "jquery";

/**
 * 參數 - 外部 - 提交
 */
const emit = defineEmits<{
  (e: "submit", key: string): void
  (e: "cancel"): void
  (e: "keyup", key: string): void
}>()

const component = ref()

const props = withDefaults(defineProps<{
  //input的最長長度
  maxLength?: number,
  //頂部加塞
  marginTop?: number
  //底部加塞
  marginBottom?: number
  //改為密碼輸入時的占位符
  placeholder?: string
}>(), {
  maxLength: 16,
  marginTop: 0,
  marginBottom: 2,
  placeholder: ""
})

const field = reactive<{
  key: string
}>({
  key: ""
})

//密碼監聽
const onKeyUp = () => emit(`keyup`, field.key)

onMounted(async () => {
  await nextTick()
  setTimeout(()=>$(".el-input__inner", $(component.value)).focus(), 0)
})

</script>

<style scoped lang="sass">
.qrcode
  .s5
    position: relative
    .s7
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      background-color: rgba(255, 255, 255, .975)
      .avatar
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 15px
        width: 52px
        height: 52px
    img
      &.p
        width: 150px
        height: 150px
        margin: 35px
        &.active
          width: 220px
          height: 220px
          margin: 0
          box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%), 0 2px 4px 0 rgb(34 41 47 / 8%)
        &.swal-embedded
          width: 100px
          height: 100px
          &.active
            width: 170px
            height: 170px
  .logo
    border-radius: 35px
    padding: 10px 15px
    > span
      position: relative
      top: 1px
    > img
      width: 20px
      height: 20px
      border-radius: 3px

.dark-layout
  .qrcode
    .s5
      .s7
        background-color: rgba(40, 48, 70, 0.98)
      img
        &.p
          &.active
            box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.75), 0 2px 4px 0 rgba(34, 41, 47, 0.75)
</style>
