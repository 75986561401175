import {RouteRecordRaw} from "vue-router";

const soul: RouteRecordRaw[] = [{
	//心靈捕手文章管理
	path: "soul_hunters",
	name: "AdminSoul",
	meta: {
		permission: 6001,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: null
		}, {
			key: 'admin.soul.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/index.vue"),
}, {
	//心靈捕手文章新增
	path: "soul_hunters/create",
	name: "AdminSoulCreate",
	meta: {
		permission: 6002,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/create.vue"),
}, {
	//心靈捕手文章編輯
	path: "soul_hunters/edit/:uuid",
	name: "AdminSoulEdit",
	meta: {
		permission: 6004,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/edit.vue"),
}, {
	//心靈捕手文章分類管理
	path: "sub_categories",
	name: "AdminSoulCategory",
	meta: {
		permission: 6011,
		breadcrumb: [{
			key: 'admin.soul.subject',
			value: null
		}, {
			key: 'admin.soul.category.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/category/index.vue"),
}, {
	//心靈捕手文章分類新增
	path: "sub_categories/create",
	name: "AdminSoulCategoryCreate",
	meta: {
		permission: 6012,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: null
		}, {
			key: 'admin.soul.category.index',
			value: "AdminSoulCategory"
		}, {
			key: 'admin.soul.category.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/category/create.vue"),
}, {
	//心靈捕手文章分類編輯
	path: "sub_categories/edit/:uuid",
	name: "AdminSoulCategoryEdit",
	meta: {
		permission: 6014,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: null
		}, {
			key: 'admin.soul.category.index',
			value: "AdminSoulCategory"
		}, {
			key: 'admin.soul.category.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/category/edit.vue"),
}, {
	//心靈捕手文章 - 留言紀錄
	path: "sub_categories/edit/:uuid/comment",
	name: "AdminSoulComment",
	meta: {
		permission: 6021,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.edit',
			value: "AdminSoulEdit"
		}, {
			key: 'admin.soul.comment.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/comment/index.vue"),
}, {
	//心靈捕手文章 - 推播紀錄
	path: "soul_hunters/edit/:uuid/notifications",
	name: "AdminSoulNotification",
	meta: {
		permission: 6031,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.edit',
			value: "AdminSoulEdit"
		}, {
			key: 'admin.soul.notification.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/index.vue"),
}, {
	//心靈捕手文章 - 推播紀錄 - 新增
	path: "soul_hunters/edit/:uuid/notifications/create",
	name: "AdminSoulNotificationCreate",
	meta: {
		permission: 6032,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.edit',
			value: "AdminSoulEdit"
		}, {
			key: 'admin.soul.notification.index',
			value: "AdminSoulNotification"
		}, {
			key: 'admin.soul.notification.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/create.vue"),
}, {
	//心靈捕手文章 - 推播紀錄 - 編輯
	path: "soul_hunters/edit/:uuid/notifications/edit/:uid",
	name: "AdminSoulNotificationEdit",
	meta: {
		permission: 6034,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.edit',
			value: "AdminSoulEdit"
		}, {
			key: 'admin.soul.notification.index',
			value: "AdminSoulNotification"
		}, {
			key: 'admin.soul.notification.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/notification/edit.vue"),
}, {
	//心靈捕手文章 - 複製
	path: "soul_hunters/copy/:uuid",
	name: "AdminSoulCopy",
	meta: {
		permission: 6002,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.copy',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/copy/index.vue"),
}, {
	//心靈捕手文章 - 心靈捕手
	path: "soul_hunters/mindcatcher/:uuid",
	name: "AdminSoulMindCatcher",
	meta: {
		permission: 6002,
		breadcrumb: [{
			key: 'admin.soul.index',
			value: "AdminSoul"
		}, {
			key: 'admin.soul.mindcatcher',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/article/mindcatcher/index.vue"),
}];

export default soul;
