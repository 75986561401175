/**
 * 歷程管理
 */
export default {
    subject: "歷程管理",
    exception: {
        index: "攔截紀錄",
        edit: "攔截內容",
        permission: "歷程管理 - 攔截紀錄"
    },
    access: {
        index: "存取紀錄",
        edit: "存取內容",
        permission: "歷程管理 - 存取紀錄"
    },
    authorize: {
        index: "授權紀錄",
        edit: "登入內容",
        permission: "歷程管理 - 授權紀錄"
    }
}
