export const Edit: {
    [key: string]: any
} = {

    tab: {
        0: {
            subject: "基本",
        },
        99: {
            subject: "相關資訊",
        }
    },
    field: {
        created: {
            subject: "建立時間",
            holder: ""
        },
        stamp: {
            subject: "執行時間",
            holder: ""
        },
        received: {
            subject: "生成時間",
            holder: ""
        }
    }

}
