import {Progress} from "@/language/modules/component/support/v2/worker/progress/zhTW"
import {Account} from "@/language/modules/component/support/v2/worker/account/zhTW"
import {History} from "@/language/modules/component/support/v2/worker/history/zhTW"
import {Attachment} from "@/language/modules/component/support/v2/worker/attachment/zhTW"
import {Status} from "@/language/modules/component/support/v2/worker/status/zhTW"

export const Worker: {
    [key: string]: any
} = {

    /**
     * 進度
     */
    progress: Progress,

    /**
     * 帳號
     */
    account: Account,

    /**
     * 附件
     */
    attachment: Attachment,

    /**
     * 歷史紀錄
     */
    history: History,

    /**
     * 狀態
     */
    status: Status

}
