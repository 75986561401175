import {RouteRecordRaw} from "vue-router";

const question: RouteRecordRaw[] = [{
	//問答集管理 - 問題群組
	path: "question/group",
	name: "AdminQuestionGroup",
	meta: {
		permission: 14001,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.group.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/group/index.vue"),
}, {
	//問答集 - 問題群組 - 新增
	path: "question/group/create",
	name: "AdminQuestionGroupCreate",
	meta: {
		permission: 14002,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.index',
			value: "AdminQuestionGroup"
		}, {
			key: 'admin.question.group.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/group/create.vue"),
}, {
	//問答集 - 問題群組 - 編輯
	path: "question/group/edit/:uuid",
	name: "AdminQuestionGroupEdit",
	meta: {
		permission: 14004,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		},{
			key: 'admin.question.group.index',
			value: "AdminQuestion"
		}, {
			key: 'admin.question.group.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/group/edit.vue"),
}, {
	//問答集 - 問題設置
	path: "question/question",
	name: "AdminQuestionQuestion",
	meta: {
		permission: 14011,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.question.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/question/index.vue"),
}, {
	//問答集 - 問題設置 - 新增
	path: "question/question/create",
	name: "AdminQuestionQuestionCreate",
	meta: {
		permission: 14012,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.question.index',
			value: null
		}, {
			key: 'admin.question.question.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/question/create.vue"),
}, {
	//問答集 - 問題設置 - 編輯
	path: "question/question/edit/:uuid",
	name: "AdminQuestionQuestionEdit",
	meta: {
		permission: 14014,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.question.index',
			value: "AdminQuestionQuestion"
		}, {
			key: 'admin.question.question.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/question/edit.vue"),
}, {
	//問答集 - 回覆選項
	path: "question/option",
	name: "AdminQuestionOption",
	meta: {
		permission: 14021,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.option.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/option/index.vue"),
}, {
	//問答集 - 回覆選項 - 新增
	path: "question/option/create",
	name: "AdminQuestionOptionCreate",
	meta: {
		permission: 14022,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.option.index',
			value: "AdminQuestionOption"
		}, {
			key: 'admin.question.option.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/option/create.vue"),
}, {
	//問答集 - 回覆選項 - 編輯
	path: "question/option/edit/:uuid",
	name: "AdminQuestionOptionEdit",
	meta: {
		permission: 14024,
		breadcrumb: [{
			key: 'admin.question.index',
			value: null
		}, {
			key: 'admin.question.option.index',
			value: "AdminQuestionOption"
		}, {
			key: 'admin.question.option.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/option/edit.vue"),
}, {
	//問答集 - 表單紀錄
	path: "question/form",
	name: "AdminQuestionForm",
	meta: {
		permission: 14031,
		breadcrumb: [{
			key: 'admin.question.subject',
			value: null
		}, {
			key: 'admin.question.form.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/form/index.vue")
}, {
	//問答集選 - 表單紀錄 - 編輯
	path: "question/form/edit/:uuid",
	name: "AdminQuestionFormEdit",
	meta: {
		permission: 14034,
		breadcrumb: [{
			key: 'admin.question.index',
			value: null
		}, {
			key: 'admin.question.form.index',
			value: "AdminQuestionForm"
		}, {
			key: 'admin.question.form.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/question/form/edit.vue")
}];

export default question;
