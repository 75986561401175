<template>
  <div :class="active.loaded ? null : `hidden`"
       class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static">
    <!-- BEGIN: Content-->
    <div v-if="active.data.available && active.data.organization.uuid"
         class="app-content content m-b-0">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <a class="brand-logo">
                <img class="logo-img"
                     alt="image"
                     :src="getLogo">
              </a>
              <div class="kp d-none d-lg-flex align-items-center">
                <div class="x2" />
                <div :style="{
                  backgroundImage: `url(${ getBackground })`
                }" class="x1" />
              </div>
              <div class="xp d-flex align-items-center auth-bg px-2">
                <div class="col-12 col-sm-8 col-md-6 col-lg-12 mx-auto">
                  <h2 class="text-center card-title fw-bold mb-1 cgBase">
                    {{active.data.name}} - {{$t(`${configure.prefix}.subject`)}}
                  </h2>
                  <!-- 帳密登入表單 -->
                  <form v-if="active.mode === EnumUserSettingAuthorized.PASSWD"
                        @submit.prevent="onSubmit"
                        class="auth-login-form mt-2">
                    <!-- 別移除, 讓form可以自動送出 -->
                    <button class="hidden" />
                    <form-input
                        name="account"
                        :parameter="active"
                        :prefix="configure.prefix"
                        :error="[1001]"
                        :source="field"
                        :label="$t(`${configure.prefix}.field.account`)"
                        :holder="$t(`${configure.prefix}.holder.account`)" />
                    <form-password
                        name="passwd"
                        :parameter="active"
                        :prefix="configure.prefix"
                        :error="[1002]"
                        :source="field"
                        :label="$t(`${configure.prefix}.field.passwd`)"
                        :holder="$t(`${configure.prefix}.holder.passwd`)" />
                    <form-captcha
                        name="captcha"
                        :parameter="active"
                        :prefix="configure.prefix"
                        :error="[1003, 1004, 1005]"
                        :source="field"
                        :label="$t(`${configure.prefix}.field.captcha`)"
                        :holder="$t(`${configure.prefix}.holder.captcha`)" />
                    <div v-if="false">
                      <div class="form-check d-flex">
                        <input class="form-check-input" id="remember-me" type="checkbox" tabindex="3">
                        <label class="form-check-label" for="remember-me"> Remember Me</label>
                      </div>
                    </div>
                    <button
                        v-if="active.success === 0"
                        v-html=" $t(`submit.${ active.xhring === true ? 'waiting' : 'login' }`) "
                        :disabled="active.xhring === true"
                        @click="onSubmit"
                        type="button"
                        :class="active.xhring === true ? 'xhring' : null"
                        class="btn btn-tm login bg-gradient-base w-100 mt-2">
                    </button>
                    <button
                        v-else
                        v-html=" $t('submit.success') "
                        disabled
                        type="button"
                        class="xhring btn btn-tm login bg-gradient-base w-100 mt-2">
                    </button>
                  </form>
                  <!-- 掃碼登入 -->
                  <j-qrcode-login
                      v-else
                      :margin-bottom="0"
                      :margin-top="2"
                      :data="active.data" />
                </div>
                <div class="d-flex justify-content-between align-items-center ff col-12 col-sm-8 col-md-6 col-lg-12 text-dark">
                  <div class="d-flex justify-content-start align-items-center">
                    <el-tooltip
                        v-if="isDarkTheme"
                        class="box-item"
                        :content="$t(`component.theme.dark`)"
                        placement="top-start">
                      <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                class="d-inline-block cursor hand ft-20"
                                type="sun" />
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                        class="box-item"
                        :content="$t(`component.theme.light`)"
                        placement="top-start">
                      <icon-sax @click="setTheme(isDarkTheme ? EnumTheme.LIGHT : EnumTheme.DARK)"
                                class="text-secondary d-inline-block cursor hand ft-20"
                                type="moon" />
                    </el-tooltip>
                    <el-dropdown popper-class="light-language"
                                 placement="top-start">
                      <icon-sax class="text-secondary ur d-inline-block cursor hand m-l-10 ft-20"
                                type="global" />
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                              @click="setRegion(j.id)"
                              v-for="j in getAllRegion">
                            <a class="d-flex align-items-center">
                              <country-flag :iso="j.id" />
                              <span :class="getCurrentRegion.id === j.id ? `text-dark` : `text-muted`"
                                    class="m-l-10 ft-12 selected-language"
                                    v-html="j.value" />
                            </a>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                  <div>
                    <el-tooltip
                        class="box-item"
                        :content="$t(`${configure.prefix}.feature.forgot`)"
                        placement="top-end">
                      <router-link :to="{name: `Forgot`}">
                        <icon-sax class="text-secondary d-inline-block cursor hand m-l-10 ft-20"
                                  type="finger-scan" />
                      </router-link>
                    </el-tooltip>
                    <el-tooltip
                        v-if="active.mode === EnumUserSettingAuthorized.PASSWD"
                        class="box-item"
                        :content="$t(`${configure.prefix}.feature.authorized.qrcode`)"
                        placement="top-end">
                      <icon-sax @click="active.mode = EnumUserSettingAuthorized.QRCODE"
                                class="text-secondary d-inline-block cursor hand m-l-10 ft-20"
                                type="scan" />
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        class="box-item"
                        :content="$t(`${configure.prefix}.feature.authorized.passwd`)"
                        placement="top-end">
                      <icon-sax @click="active.mode = EnumUserSettingAuthorized.PASSWD"
                                class="text-secondary d-inline-block cursor hand m-l-10 ft-20"
                                type="password-check" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
    <error404 :home="false"
              v-else />
  </div>
</template>

<script setup lang="ts">

import _ from "lodash"

import IconSax from "@/component/IconSax.vue"
import Error404 from "@/component/Error.vue"
import FormInput from "@/component/form/FormInput.vue"
import FormPassword from "@/component/form/FormPassword.vue"
import FormCaptcha from "@/component/form/FormCaptcha.vue"
import JQrcodeLogin from "@/component/qrocde/Login.vue"
import CountryFlag from "@/component/CountryFlag.vue"

import {Configure} from "@/api/interface/configure"
import {Session} from "@/api/interface/session"
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import {computed, onMounted, reactive, watch} from "vue"
import {useRoute, useRouter} from "vue-router"
import {createLogin, storeSession} from "@/api/module/session"
import {useSetting} from "@/hook/useSetting"
import {usePermission} from "@/hook/usePermission"
import {useRegion} from "@/hook/useRegion"
import {useUtils} from "@/hook/useUtils"
import {EnumUserSettingAuthorized} from "@/enum/user";
import {EnumTheme} from "@/enum/theme";
import {useI18n} from "vue-i18n";
import {useSwalAlert} from "@/hook/useSwalAlert";
import {RouteNameEnum} from "@/v2/enumerate/route";

const route = useRoute()
const router = useRouter()

const {
  t
} = useI18n()

const {
  showWarning
} = useSwalAlert()

const {
  isLogin,
  setToken
} = usePermission()

const {
  setFirstFocus
} = useUtils()

const {
  isDarkTheme,
  setTheme,
  getSetting
} = useSetting()

const {
  setRegion,
  getAllRegion,
  getCurrentRegion
} = useRegion()

/**
 * 參數 - 頁面 - 配置
 */
const configure = reactive<Configure.Page.Base>({
  //i18n代碼前置
  prefix: `page.login`,
})

/**
 * 參數 - 本地
 */
const active = reactive<{
  loaded: boolean
  error: number
  success: number
  xhring: boolean
  //驗證碼長度
  captcha: number
  //登入頁面資訊
  data: Session.Login,
  //登入模式
  mode: EnumUserSettingAuthorized
}>({
  loaded: false,
  error: 0,
  success: 0,
  xhring: false,
  captcha: parseInt(import.meta.env.VITE_SITE_CAPTCHA_LENGTH as string),
  data: {
    organization: {
      seq: null,
      uuid: null,
      name: null
    },
    available: true,
    name: null,
    uuid: null,
    avatarUrl: null
  },
  mode: EnumUserSettingAuthorized.PASSWD
})

//輸入欄位
const field = reactive<Session.Submit>({
  account: null,
  passwd: null,
  captcha: null
})

const onSubmit = async () => {

  //創建效驗
  const v$ = useVuelidate<Session.Submit>({
    account: {
      required
    },
    passwd: {
      required
    },
    captcha: {
      required
    }
  }, field)

  //效驗 - 帳號
  v$.value.account.$touch()
  if (v$.value.account.$error) {
    active.error = 1001
    return false
  }

  //效驗 - 密碼
  v$.value.passwd.$touch()
  if (v$.value.passwd.$error) {
    active.error = 1002
    return false
  }

  //效驗 - 驗證碼
  v$.value.captcha.$touch()
  if (v$.value.captcha.$error) {
    active.error = 1003
    return false
  }

  //效驗 - 驗證碼 - 長度
  if (field.captcha!.length != active.captcha) {
    active.error = 1004
    return false
  }

  active.xhring = true

  const {
    data
  } = await storeSession({
    ...field,
    church: active.data.uuid
  })

  //無法登入
  if (data === undefined) {

    showWarning({
      subject: t(`${configure.prefix}.error.1000.subject`),
      text: t(`${configure.prefix}.error.1000.text`),
      completion: {
        //完成顯示錯誤
        done: ()=> active.xhring = false
      }
    })

    return false

  }
  //登入失敗
  else if (Number.isInteger(data?.code)) {

    if (data?.code! >= 1000) active.error = data?.code!
    active.xhring = false
    return false

  }
  //正常
  else {

    //登入成功
    setToken(data!.data!.token)

    await router.push({
      name: RouteNameEnum.ADMIN_DASHBOARD,
    })

  }

}

//獲取Logo
const getLogo = computed((): string => {
  if (active.data.organization.seq) return `/img/organization/${active.data.organization.seq}/logo.png`
  return "/img/organization/1/logo.png"
})

//獲取背景圖
const getBackground = computed(()=>{
  if (active.data.avatarUrl) return active.data.avatarUrl
  return "/img/avatar/holder/my.jpg"
})

//存取API
const fetch = async () => {

  const {
    data
  } = await createLogin(
      route.params.id as string
  )

  //返回錯誤不往下進行
  if (data?.data === undefined) return false

  active.data.organization = data.data.organization
  active.data.available = data.data.available
  active.data.name = data.data.name
  active.data.uuid = data.data.uuid
  active.data.avatarUrl = data.data.avatarUrl

  //可以打開頁面了
  active.loaded = true

}

//啟動
const initial = () => {
  fetch()
}

//自動focus第一個input
watch(()=>active.mode, (e: EnumUserSettingAuthorized) => {
  if (e === EnumUserSettingAuthorized.PASSWD) setFirstFocus()
})

onMounted(() => {

  //如果已經登入, 前往控制面板
  if (isLogin.value) router.replace({
    name: RouteNameEnum.ADMIN_DASHBOARD
  })
  //未登入
  else {

    //注入登入模式
    active.mode = _.cloneDeep(getSetting.value.authorized)

    //初始化
    initial()

  }

})

</script>

<style scoped lang="sass">

@import '@/style/core/authentication.css'

.ur
  position: relative
  top: 2px

.auth-inner
  overflow: hidden
  position: relative
  .kp
    width: calc(100% - 33.33333%)
  .xp
    z-index: 1
    position: absolute
    top: 0
    bottom: 0
    right: 0
    padding: 2.5rem !important
    width: 33.33333%
  .ff
    padding: 1.5rem 2.5rem !important
    position: absolute
    margin-right: auto
    margin-left: auto
    left: 0
    right: 0
    bottom: 0
    text-align: right

@media (max-width: 1500px)
  .auth-inner
    .kp
      width: calc(100% - 40%)
    .xp
      width: 40%

@media (max-width: 1200px)
  .auth-inner
    .kp
      width: calc(100% - 45%)
    .xp
      width: 45%

@media (max-width: 991px)
  .auth-inner
    .kp
      width: 0
    .xp
      width: 100%
    .ff
      padding: 1.5rem !important
      margin-right: 0
      margin-left: 0
      width: 100%

.qrcode
  height: 377.85px

.brand-logo
  img.logo-img
    width: 48px
    height: 48px
    border-radius: 0.375rem

.auth-wrapper
  .auth-bg
    background-color: #FFF
  &.auth-cover
    .brand-logo
      z-index: 4
      left: 1rem !important
.d-none
  position: relative
  .x1, .x2
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
  .x1
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    z-index: 1
  .x2
    background-color: rgba(255, 255, 255, 0.55)
    z-index: 2

.dark-layout
  .auth-wrapper
    .auth-bg
      background-color: #343d55 !important
  .d-none
    .x2
      background-color: rgba(255, 255, 255, 0.25)
</style>
