import {NavigationItemRaw} from '@/navigation/interface'

const dashboard: NavigationItemRaw[] = [{
    id: 51,
    right: false,
    subject: 'shepherd.home.index',
    route: 'ShepherdDashboard',
    icon: 'monitor',
    children: [],
    //無法歸類的放首頁
    attached: [],
}]
export default dashboard
