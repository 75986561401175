import {v2} from "@/language/modules/component/qrcode/v2/zhCN"

export default {

    v2: v2,

    login: {
        subject: "请使用%sApp扫码登入",
        text: "前往下载%sApp"
    },
    mapping: {
        subject: "请使用%sApp扫码进行绑定",
        text: "前往下载%sApp"
    },
    status: {
        authorize: {
            subject: "授权成功",
            text: "正在准备完成"
        },
        inspection: {
            subject: "验证成功",
            text: "请在App中进行确认"
        },
        expire: {
            subject: "二维码超时",
            text: "重新产生二维码"
        }
    }
}
