import {v2} from "@/language/modules/component/form/v2/zhCN"

export default {

    v2: v2,

    help: {
        must: `标记为 <b class="cgRed">*</b> 为必填栏位`
    },
    handler: {
        reset: {
            subject: "即将清除表单",
            text: `<span class="text-danger">已经填写的资料将会清除, 请确认后执行</span>`,
            description: ""
        }
    },
    select: {

        head: {

            church: {
                label: "指定教会",
                holder: "请选择指定教会",
                help: "请谨慎使用指定教会功能",
                broadcast: {
                    subject: "全部教会"
                }
            }

        }

    }

}
