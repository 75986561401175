export default {
    panel: {
        year: "選擇年",
        month: "選擇月"
    },
    prev: {
        year: "往前 1 年",
        month: "往前 1 個月",
        ten: {
            year: "往前 10 年"
        }
    },
    next: {
        year: "往後 1 年",
        month: "往後 1 個月",
        ten: {
            year: "往後 10 年"
        }
    },
    timer: {
        subject: "點擊使用滑桿模式",
        increase: {
            hour: "增加 1 小時",
            minute: "增加 %s 分鐘"
        },
        decrease: {
            hour: "減少 1 小時",
            minute: "減少 %s 分鐘"
        }
    },
    error: {
        1001: {
            "subject": "小於最早時間限制",
            "text": '最早時間限制為 <b class="ft-20 text-danger">%s</b><br />請您重新選擇',
            "description": ""
        },
        1002: {
            "subject": "大於最晚時間限制",
            "text": '最晚時間限制為 <b class="ft-20 text-danger">%s</b><br />請您重新選擇',
            "description": ""
        }
    },
    day: [
        "日",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
    ],
    month: [
        "01月",
        "02月",
        "03月",
        "04月",
        "05月",
        "06月",
        "07月",
        "08月",
        "09月",
        "10月",
        "11月",
        "12月",
    ]
}
