export default {
    subject: "编辑标签",
    help: "提交创建标签",
    exit: "离开编辑",
    empty: "无可用标签",
    menu: {
        on: "开启选单",
        off: "关闭选单"
    },
    summary: {
        total: `筛选出 <span class="cgBase">%s</span> 个会友`,
        checked: `, 已选取 <span class="cgBase">%s</span> 个会友`,
    },
    status: [
        "已停用",
        "启用中"
    ],
    off: {
        all: "请选择先筛选会友",
        subject: "请先选择标签",
        join: "加入(请选择标签与指定会友)",
        remove: "移除(请选择标签与指定会友)"
    },
    field: {
        tag: "",
        keyword: "关键字"
    },
    th: {
        avatar: "头像",
        name: "名字",
        email: "电子邮件",
        status: "状态"
    },
    holder: {
        tag: "请输入标签名称(可用逗号分隔)",
        keyword: "搜索范围: 名字/邮件/会友编号/标签"
    },
    handler: {
        join: {
            subject: "即将批次加入标签",
            text: "请确认后执行"
        },
        remove: {
            subject: "即将批次移除标签",
            text: "请确认后执行"
        },
        dbclick: {
            subject: "即将移除指定标签",
            text: "请确认后执行"
        },
        confirm: {
            subject: "即将移除标签",
            text: "请确认后执行"
        }
    }
}
