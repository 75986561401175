/**
 * 公吿欄管理
 */
export default {

    /**
     * 列表
     */
    index: {
        mode: {
            on: "置頂",
            off: "未置頂"
        },
        feature: {
            create: {
                subject: "新增公吿"
            }
        },
        field: {
            avatar: "頭像",
            subject: "名稱",
            top: "置頂",
            summary: {
                read: "已讀取"
            }
        },
        holder: {
            subject: "請輸入名稱",
        },
        handler: {
            enable: {
                subject: "即將啟用公告",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用公告",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除公告, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            }
        },
        field: {
            subject: "標題",
            content: "內容",
            top: "置頂"
        },
        holder: {
            subject: "請輸入標題",
            content: "請輸入內容",
            top: "請選擇是否置頂"
        },
        handler: {
            store: {
                subject: "即將新增公吿",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇是否置頂",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "閱讀"
            },
            99: {
                subject: "相關資訊"
            }
        },
        field: {
            subject: "標題",
            content: "內容",
            top: "置頂",
            keyword: "搜尋名單"
        },
        holder: {
            subject: "請輸入標題",
            content: "請輸入內容",
            top: "請選擇是否置頂",
            keyword: "搜尋範圍: 名字/郵件/會友編號"
        },
        handler: {
            update: {
                subject: "即將更新公告內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請輸入標題",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請選擇是否置頂",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請輸入內容",
                "text": "",
                "description": ""
            }
        }
    }

}
