/**
 * 鉤子
 * 授權
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {computed} from "vue"
import {AuthStore} from "@/store/modules/auth";
import {AuthorizationStore} from "@/v2/store/modules/authorization";

/**
 * 實例
 */
export const useAuthorization = () => {

    /**
     * 權限
     */
    const authorizationStore = AuthorizationStore()

    /**
     * 是否 - 登入
     */
    const isLogin = computed((): boolean => !["", null].includes(authorizationStore.getToken))

    /**
     * 獲取 - JWT
     */
    const getToken = computed((): string | null => authorizationStore.getToken)

    /**
     * 更新 - JWT
     */
    const setToken = (
        token: string | null
    ) => {

        authorizationStore.setToken(token)

        // 暫時先寫兩邊
        AuthStore().setToken(token)

    }

    return {
        isLogin,
        getToken,
        setToken
    }

}