import {Team} from "@/language/modules/page/admin/event/v2/create/membership/team/zhTW";
import {Editor} from "@/language/modules/page/admin/event/v2/create/membership/editor/zhTW";

export const Membership: {
    [key: string]: any
} = {

    team: Team,

    editor: Editor,

    field: {

        keyword: {
            subject: "關鍵字",
            holder: "請輸入關鍵字",
            text: "同時搜尋名字/郵件/會友編號"
        },

        team: {
            subject: "參與群組",
            holder: "請選擇參與群組"
        }

    },
    empty: {
        subject: "請選擇會友參與服事"
    },
    not: {
        found: {
            subject: "搜尋不到指定的參與服事會友"
        }
    }
}