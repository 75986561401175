/**
 * 會友 - 進階
 */
export default {
    tabs: {
        0: {
            subject: "批次委派"
        },
        1: {
            subject: "會友匯入"
        },
        2: {
            subject: "會友匯出"
        },
        3: {
            subject: "全部刪除"
        }
    },
    step: {
        delegate: [{
            subject: "步驟1",
            text: "選擇前往族群",
            description: "請先指定一個需要前往的族群"
        },{
            subject: "步驟2",
            text: "選擇會友",
            description: "請選擇需要前往的族群的會友"
        },{
            subject: "步驟3",
            text: "完成委派",
            description: ""
        }]
    },
    submit: {
        delegate: {
            prev: "上一步",
            next: {
                off: "請先選擇族群",
                subject: "下一步"
            },
            accept: {
                off: "請先選擇會友",
                subject: "確認委派"
            },
            reset: "重新委派"
        },
        import: {
            name: `匯入會友檔案範例_完整`,
            template: "下載範例",
            off: "請先匯入會友資訊",
            refresh: "重新匯入",
            accept: "執行匯入",
        },
        export: {
            accept: "執行匯出",
        },
        destroy: {
            accept: "執行刪除"
        }
    },
    field: {
        category: "類別",
        keyword: "關鍵字"
    },
    holder: {
        category: "請選擇類別",
        keyword: "請輸入關鍵字"
    },
    handler: {
        delegate: {
            subject: "即將進行批次委派",
            text: "請確認後執行",
            description: ""
        },
        import: {
            reset: {
                subject: "即將進行重新匯入",
                text: "請確認後執行",
                description: ""
            },
            upload: {
                subject: "即將進行會友匯入",
                text: "請確認後執行",
                description: ""
            }
        }
    },
    factory: {
        export: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將進行會友匯出, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        },
        destroy: {
            subject: "安全性二級驗證",
            text: `<span class="cgRed">即將進行全部會友刪除, 請確認後執行</span>`,
            description: "請輸入您目前的登入密碼"
        }
    }
}
