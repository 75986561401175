export const User: {
    [key: string]: any
} = {
    0: {
        subject: "建立人员",
        holder: "由系统自动产生",
        stamp: {
            subject: "建立时间",
            holder: ""
        }
    },
    1: {
        subject: "上次编辑人员",
        holder: "内容未经过编辑",
        stamp: {
            subject: "上次编辑时间",
            holder: "内容未经过编辑"
        }
    }
}