/**
 * 鉤子
 * 設定
 * @author J
 * @since 2024-04-30 07:34:25
 */

import {computed} from "vue";
import {SettingStore} from "@/v2/store/modules/setting";
import {SettingFormTableOpenEnum, SettingLoginEnum, SettingThemeEnum} from "@/v2/enumerate/setting";
import {MenuStore} from "@/store/modules/menu";
import {AdminChurchCaption} from "@/v2/api/interface/core/admin/church/caption";
import {UserStore} from "@/store/modules/user";

/**
 * 實例
 */
export const useSetting = () => {

    /**
     * 權限
     */
    const settingStore = SettingStore()

    /**
     * 獲取 - 選單 - 折疊 (md以上)
     */
    const isMenuCollapsed = computed((): boolean => settingStore.isMenuCollapsed)

    /**
     * 獲取 - 選單 - 折疊 (sm以下)
     */
    const isMenuOverlay = computed((): boolean => settingStore.isMenuOverlay)

    /**
     * 獲取 - 教會 - 三項 - 標題
     */
    const getMenuCaption = computed((): AdminChurchCaption => settingStore.getMenuCaption)

    /**
     * 獲取 - 為深色主題
     */
    const isDarkTheme = computed((): boolean => settingStore.isDarkTheme)

    /**
     * 獲取 - 語系
     */
    const getRegion = computed((): string => settingStore.getRegion)

    /**
     * 獲取 - 登入 - 方式
     */
    const getAuthorized = computed((): SettingLoginEnum => settingStore.getAuthorized)

    /**
     * 獲取 - 表單 - 表格 - 開啟方式
     */
    const getFormTableOpen = computed((): SettingFormTableOpenEnum => settingStore.getFormTableOpen)

    /**
     * 切換 - 選單 - 折疊 (md以上)
     */
    const toggleMenuCollapsed = () => {

        settingStore.setMenuCollapsed(!settingStore.isMenuCollapsed)

        // 暫時先寫兩邊
        MenuStore().setCollapsed(settingStore.isMenuCollapsed)

    }

    /**
     * 切換 - 選單 - 折疊 (sm以下)
     */
    const toggleMenuOverlay = () => {

        settingStore.setMenuOverlay(!settingStore.isMenuOverlay)

        // 暫時先寫兩邊
        MenuStore().setOverlay(settingStore.isMenuOverlay)

    }

    /**
     * 獲取 - 教會 - 三項 - 標題
     */
    const setMenuCaption = (e: AdminChurchCaption) => {

        settingStore.setMenuCaption(e)

        // 暫時先寫兩邊
        UserStore().setCaption({
            my: e.my,
            soul: e.soul,
            daily: e.daily
        })

    }

    /**
     *  切換主體
     */
    const setTheme = async (value: SettingThemeEnum) => {
        settingStore.setTheme(value)
        const body = document.getElementsByTagName("BODY")[0];
        //切換的時候, 加入一個暫時關閉 .form-control transition的功能
        //不然切換的時候會閃一下很醜
        body.classList.add("pv")
        value === SettingThemeEnum.DARK ? body.classList.add("dark-layout") : body.classList.remove("dark-layout")
        setTimeout(()=>{
            //完成後在去掉暫時transition的功能
            body.classList.remove("pv")
        }, 50)
    }

    /**
     * 設定 - 語系
     * @param e 語系
     */
    const setRegion = (e: string) => settingStore.setRegion(e)

    /**
     * 設定 - 登入 - 方式
     * @param e 登入方式
     */
    const setAuthorized = (e : SettingLoginEnum) => settingStore.setAuthorized(e )

    /**
     * 設定 - 表單 - 表格 - 開啟方式
     * @param e 開啟方式
     */
    const setFormTableOpen = (e: SettingFormTableOpenEnum) => settingStore.setFormTableOpen(e)

    return {
        isMenuCollapsed,
        isMenuOverlay,
        isDarkTheme,
        getMenuCaption,
        getRegion,
        getAuthorized,
        getFormTableOpen,
        toggleMenuCollapsed,
        toggleMenuOverlay,
        setMenuCaption,
        setTheme,
        setRegion,
        setAuthorized,
        setFormTableOpen
    }

}