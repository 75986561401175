export const Code: {
    [key: string]: any
} = {

    200: {
        subject: "操作成功",
        text: "",
        description: ""
    },
    201: {
        subject: "服務降級保護中",
        text: "服務目前處於降級保護的狀態, 請稍後再試",
        description: ""
    },
    202: {
        subject: "服務流控管制中",
        text: "服務目前處於流控管制的狀態, 請稍後再試",
        description: ""
    },
    203: {
        subject: "服務熱點限制中",
        text: "服務目前處於熱點限制的狀態, 請稍後再試",
        description: ""
    },
    204: {
        subject: "系統規則不滿足要求",
        text: "請稍後再試",
        description: ""
    },
    205: {
        subject: "授權規則不通過",
        text: "請稍後再試",
        description: ""
    },
    401: {
        subject: "連線權杖逾時或不正確",
        text: "Oops! 🤕🤕 請您重新登入系統",
        description: "請稍後再試或請聯絡管理人員"
    },
    402: {
        subject: "公司授權已經到期",
        text: "請向我們的銷售人員聯絡, 謝謝",
        description: ""
    },
    403: {
        subject: "無存取權限",
        text: "Oops! 😳😳 您可能沒有存取的權限",
        description: "請稍後再試或請聯絡管理人員"
    },
    404: {
        subject: "頁面不存在",
        text: "Oops! 😱😱 您訪問的頁面不存在或暫時不可用",
        description: "請稍後再試或請聯絡管理人員"
    },
    //METHOD不允許
    405: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 無法正確連結至伺服器",
        description: "請稍後再試或請聯絡管理人員"
    },
    413: {
        subject: "這出了點小問題",
        text: "Oops! 🙁🙁 您上傳的檔案容量超出限制",
        description: "請稍後再試或請聯絡管理人員"
    },
    500: {
        subject: "這出了點小問題",
        text: "Oops! 🥺🥺 伺服器好像罷工了",
        description: "請稍後再試或請聯絡管理人員"
    },
    //CORS錯誤
    502: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 無法正確連結至伺服器",
        description: "請稍後再試或請聯絡管理人員"
    },
    //服務不存在
    503: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 存取的服務存在異常",
        description: "請稍後再試或請聯絡管理人員"
    },
    //連線超時
    504: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 連結到伺服器超出預期時間",
        description: "請稍後再試或請聯絡管理人員"
    },
    //Feign異常
    700: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 系統模組連接異常",
        description: "請稍後再試或請聯絡管理人員"
    },
    //單檔案上傳大小限制
    901: {
        subject: "上傳檔案發生錯誤",
        text: `單個檔案超過 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    //合計檔案上傳大小限制
    902: {
        subject: "上傳檔案發生錯誤",
        text: `檔案合計超過 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    903: {
        subject: "產生預覽圖失敗",
        text: "上傳檔案中包含不支援的圖片編碼",
        description: ""
    },
    904: {
        subject: "分享連結出現錯誤",
        text: "請確認您的分享連結是否正確",
        description: ""
    },
    998: {
        subject: "二級驗證失敗",
        text: "請確認您的登入密碼是否正確",
        description: "請確認您的登入密碼是否正確"
    },
    999: {
        subject: "這出了點小問題",
        text: "Oops! 😲😲 發生未知錯誤",
        description: "請稍後再試或請聯絡管理人員"
    }

}