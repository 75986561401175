export const Editor: {
    [key: string]: any
} = {

    subject: "請選擇會友進行推播通知",

    submit: {
        maximum: {
            subject: "最多只能選擇%s位會友進行推播通知"
        },
        minimum: {
            subject: "請選擇至少%s位會友進行推播通知"
        }
    }

}