export default {
    clone: {
        "subject": "資料複製成功",
        "text": "右鍵貼上即可以直接使用",
        "description": ""
    },
    download: {
        "subject": "檔案下載成功",
        "text": "",
        "description": ""
    }
}
