import {NavigationItemRaw} from '@/navigation/interface'
import {RouteNameEnum} from "@/v2/enumerate/route";

const event: NavigationItemRaw[] = [{
    id: 10,
    right: true,
    subject: 'admin.event.subject',
    route: null,
    icon: 'clipboard',
    children: [{
        id: 10011,
        right: true,
        subject: 'admin.event.team.index',
        route: RouteNameEnum.ADMIN_EVENT_TEAM,
        attached: [
            RouteNameEnum.ADMIN_EVENT_TEAM_CREATE,
            RouteNameEnum.ADMIN_EVENT_TEAM_EDIT,
            RouteNameEnum.ADMIN_EVENT_TEAM_EDIT_HISTORY
        ]
    }, {
        id: 10001,
        right: true,
        subject: 'admin.event.index',
        route: RouteNameEnum.ADMIN_EVENT,
        attached: [
            RouteNameEnum.ADMIN_EVENT_CREATE,
            RouteNameEnum.ADMIN_EVENT_EDIT
        ]
    }],
    attached: []
}]

export default event
