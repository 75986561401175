<template>
  <!-- 正常顯示 -->
  <div v-if="isDisplay === EnumPageDisplay.NORMAL"
      :class="[
      'vertical-layout',
      'h-100',
      (isMinimal) ? 'vertical-overlay-menu' : null,
      (isMinimal && isOverlay) ? null : 'vertical-menu-modern',
      (isMinimal) ? (isOverlay ? 'menu-open' : 'menu-hide' ) : (isCollapsed ? 'menu-collapsed' : 'menu-expanded'),
      'navbar-floating',
      'footer-static']">
    <horizontal-menu
        :is-minimal="isMinimal" />
    <vertical-menu
        ref="vertical"
        :is-minimal="isMinimal" />
    <div @click="setOverlay(false)"
        :class="[(isOverlay ? 'show' : null), 'sidenav-overlay']" />
    <!-- 有個頂部倒漸層, 請勿移除此DOM物件 -->
    <div class="header-navbar-shadow" />
    <div :class="{'v2': $route.meta.v2}"
         class="app-content content">
      <!-- 正常顯示 -->
      <div v-if="$route.meta.v2"
           class="content-wrapper v2 p-0">
        <el-scrollbar
            always
            view-class="j"
            @scroll="onScroll"
            ref="scrollbar">
          <j-breadcrumb />
          <div class="content-body">
            <!-- 記得要加上key, 這樣他才會認為每一頁不同 -->
            <router-view
                v-if="!isDelegate && active.prepared"
                :key="$route.fullPath"
                v-slot="{Component}">
              <component
                  ref="page"
                  :is="Component" />
            </router-view>
          </div>
        </el-scrollbar>
      </div>
      <div v-else
           class="content-wrapper p-0">
        <j-breadcrumb />
        <div class="content-body">
          <!-- 記得要加上key, 這樣他才會認為每一頁不同 -->
          <router-view
              v-if="!isDelegate"
              :key="$route.fullPath"
              v-slot="{Component}">
            <component
                ref="page"
                :is="Component" />
          </router-view>
        </div>
      </div>
    </div>
    <j-footer :is-collapsed="isCollapsed"
              :is-minimal="isMinimal"
              :is-overlay="isOverlay" />
    <transition name="overlay">
      <loading :active="isLoadingMask"
               :can-cancel="false"
               :is-full-page="true" />
    </transition>
  </div>
  <!-- 沒有權限 -->
  <j-error-display
      :type="EnumPageError.UNAUTHORIZED"
      v-else-if="isDisplay === EnumPageDisplay.REJECT" />
  <!-- 這不能亂放, 要放在跟節點 -->
  <vue-easy-lightbox
      v-if="isDisplay === EnumPageDisplay.NORMAL"
      teleport="body"
      @hide="setLightBox(
        false,
        [],
        0
      )"
      @on-index-change="(i,j)=>{lightbox.index=j}"
      :loop="true"
      :visible="lightbox.show"
      :imgs="lightbox.images"
      :index="lightbox.index">
    <template #toolbar="{ toolbarMethods }">
      <div class="vel-toolbar">
        <el-tooltip
            class="box-item"
            :content="$t(`component.lightbox.zoom.in`)"
            placement="top">
          <div @click="toolbarMethods.zoomIn"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="zoom-in" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`component.lightbox.zoom.out`)"
            placement="top">
          <div @click="toolbarMethods.zoomOut"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="zoom-out" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`component.lightbox.resize`)"
            placement="top">
          <div @click="toolbarMethods.resize"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="maximize" />
          </div>
        </el-tooltip>
        <el-tooltip
            v-if="getLightBox.download"
            class="box-item"
            :content="$t(`component.lightbox.download`)"
            placement="top">
          <div @click="lightBoxDownload"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="disable" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`component.lightbox.rotate.left`)"
            placement="top">
          <div @click="toolbarMethods.rotateLeft()"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="rotate-left" />
          </div>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            :content="$t(`component.lightbox.rotate.right`)"
            placement="top">
          <div @click="toolbarMethods.rotateRight()"
               class="toolbar-btn">
            <icon-sax
                class="ft-20"
                type="rotate-right" />
          </div>
        </el-tooltip>
      </div>
    </template>
  </vue-easy-lightbox>
  <div id="datepicker-container" />
  <!-- 幻燈箱, 不能亂放, 要放在跟節點 -->
  <j-light-box v-if="$route.meta.v2" />
  <!-- 讀取遮罩 -->
  <j-loading-mask v-if="$route.meta.v2" />
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"
import Loading from "vue3-loading-overlay"
import VueEasyLightbox from 'vue-easy-lightbox'
import JBreadcrumb from '@/component/Breadcrumb.vue'
import JErrorDisplay from "@/component/Error.vue"
import VerticalMenu from "@/component/menu/vertical/VerticalMenu.vue"
import HorizontalMenu from "@/component/menu/horizontal/HorizontalMenu.vue"
import JFooter from "@/component/Footer.vue"

import JLightBox from "@/v2/components/lightbox/index.vue";
import JLoadingMask from "@/v2/components/loading/index.vue";

import {ref, computed, reactive, onMounted, onUnmounted, watch, nextTick} from "vue"
import {useMitt} from "@/hook/useMitt"
import {useShow} from "@/hook/useShow"
import {useRoute, useRouter} from "vue-router"
import {useMenu} from "@/hook/useMenu"
import {useRegion} from "@/hook/useRegion"
import {Configure} from "@/api/interface/configure"
import {useUser} from "@/hook/useUser"
import {useWebsocket} from "@/hook/useWebsocket"
import {usePermission} from "@/hook/usePermission"
import {EnumPageDisplay, EnumPageError} from "@/enum/page";
import {useCross} from "@/v2/hooks/cross";
import {useAuthorization} from "@/v2/hooks/authorization";
import {IntentEnum} from "@/v2/enumerate/intent";
import {useIntent} from "@/v2/hooks/intent";
import {getAuthorizationSession} from "@/v2/api/module/authorization/session";
import {useRequest} from "@/v2/hooks/request";
import {getAdminChurchCaption} from "@/v2/api/module/core/admin/church/caption";
import {useMaintenance} from "@/hook/useMaintenance";
import {EnumMaintenance} from "@/enum/maintenance";
import router from "@/router";
import {useSwalAlert} from "@/hook/useSwalAlert";

const page = ref()
const vertical = ref()
const route = useRoute()

const {
  getUser
} = useUser()

const {
  setWebsocket,
  setWebsocketDisconnect
} = useWebsocket()

const {
  isHead
} = usePermission()

const {
  setOverlay,
  isCollapsed,
  isOverlay
} = useMenu()

const {
  isLoadingMask,
  isReloadPage,
  setReloadPage
} = useMitt()

const {
  isDisplay
} = useShow(route)

const {
  getRegion
} = useRegion()

const {
  getLightBox,
  setLightBox
} = useMitt()

const {
  setWindowSize,
  setContainerSize,
  setContainerScroll,
  setContainerScrollTop
} = useCross()

const {
  isShepherd
} = useMaintenance()

const {
  showMaintenance
} = useSwalAlert()

/**
 * 參數 - 本地
 */
const active = reactive<{
  initial: boolean
  top: number
  width: number
  prepared: boolean
}>({
  initial: false,
  top: 0,
  width: 0,
  prepared: false
})

//直接注入lightBox參數
const lightbox = reactive<Configure.LightBox>(getLightBox.value)

//是否視窗縮到最小
const isMinimal = computed((): boolean=>{
  return active.width <= 1200
})

//視窗大小變動監聽
const resize = () => {
  active.width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth
  if ( active.width > 1200 ) setOverlay(false)
}

//ESC鍵關閉監聽
const keyup = (e: KeyboardEvent) => {
  if( e.key === "Escape" ) setOverlay(false)
}

//幻燈箱下載
const lightBoxDownload = async () => {
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const linkSource = reader.result! as string
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      downloadLink.href = linkSource
      downloadLink.download = `savedImage_${ new Date().getTime() }`
      downloadLink.click()

    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', lightbox.images[lightbox.index])
  xhr.responseType = 'blob'
  xhr.send()
}

/**
 * 檢查委託管理
 */
const isDelegate = computed((): boolean => {
  //託管心靈捕手
  if ((route.name as string).startsWith("AdminSoul") && getUser.value.church.delegate.soul) return true
  //託管每日靈糧
  else if ((route.name as string).startsWith("AdminDaily") && getUser.value.church.delegate.daily) return true
  return false
})

//掛載時
onMounted(async () => {

  //先清除連接狀態
  // await setWebsocketConnectStatus(Enum.Websocket.Status.DISCONNECTED)

  document.addEventListener("keyup", keyup)
  window.addEventListener("resize", resize)
  resize()
  scroll()

  //總教會不需要連接Websocket
  if (isHead.value) return false

  //fetch延遲執行時間 (單位: 毫秒)
  const socketFetchDelay: number = parseInt(import.meta.env.VITE_SOCKET_CONNECT_DELAY as string)

  //socket連線網址
  const socketUrl: string = `${import.meta.env.VITE_SOCKET_URL as string}websocket/notify/${getUser.value.uuid}`

  //呼叫總連線
  setWebsocket(
      page,
      socketUrl,
      socketFetchDelay
  )

})

//取消掛載時
onUnmounted(()=>{

  document.removeEventListener("keyup", keyup)
  window.removeEventListener("resize", resize)

  //總教會不需要連接Websocket
  if (isHead.value) return false

  //關閉socket.io
  setWebsocketDisconnect()

})

//重新抓option
watch(()=>getRegion.value, ()=>{
  if (typeof page.value.initial === 'function') page.value.initial()
  if (typeof vertical.value.initial === 'function') vertical.value.initial()
}, {deep: true})

//重刷頁面
watch(isReloadPage, (e)=>{
  if (e && typeof page.value.initial === 'function') {
    page.value.initial(true)
    setReloadPage(false)
  }
}, {deep: true})

/**
 * =============================================================================
 * v2
 * =============================================================================
 */

const headerHeight = ref(`0px`)
const footerHeight = ref(`0px`)
const scrollbar = ref()
const scrollbarWidth = ref(`0px`)

const {
  isLogin
} = useAuthorization()

const {
  onLaunch
} = useIntent()

/**
 * 路由 - 變化
 * 只有這樣才能監聽的到, 包含按上下頁
 */
const {
  currentRoute
} = useRouter()

/**
 * 視窗 - 捲動
 */
const onScroll = () => {

  // 非v2不往下進行
  if (!(route.meta?.v2 ?? false)) return

  // 不線程, 不等渲然, 直接紀錄
  setContainerScrollTop(scrollbar.value?.wrapRef.scrollTop ?? 0)

  // 跳線程
  setTimeout(async () => {

    //等待渲染
    await nextTick()

    //紀錄
    setContainerScroll(
        scrollbar.value?.wrapRef.scrollTop,
        scrollbar.value?.wrapRef.scrollLeft,
        scrollbar.value?.wrapRef
    )

  }, 0)

}

/**
 * 視窗 - 變化
 */
const onResize = () => setTimeout(() => {

  // 非v2不往下進行
  if (!(route.meta?.v2 ?? false)) return

  //等待渲染
  nextTick()

  //模擬捲動並紀錄
  onScroll()

  //設定視窗 - 寬高
  setWindowSize()

  //容器
  const pcContainer: HTMLElement | null = document.querySelector('.app-content.content')

  //找不到容器, 不往下進行
  if (!pcContainer) return

  //容器
  const pcContainerHeader: HTMLElement | null = pcContainer.querySelector('.content-header-left')

  //找不到容器, 不往下進行
  if (!pcContainerHeader) return

  //等待渲染
  nextTick()

  //計算樣式
  const computedStyle = window.getComputedStyle(pcContainer);

  //padding - 距離
  const paddingRect: {
    top: number,
    bottom: number,
    left: number,
    right: number
  } = {
    top: parseInt(computedStyle?.getPropertyValue('padding-top').replace('px', '') ?? 0),
    bottom: parseInt(computedStyle?.getPropertyValue('padding-bottom').replace('px', '') ?? 0),
    left: parseInt(computedStyle?.getPropertyValue('padding-left').replace('px', '') ?? 0),
    right: parseInt(computedStyle?.getPropertyValue('padding-right').replace('px', '') ?? 0),
  }

  headerHeight.value = `${paddingRect.top}px`
  footerHeight.value = `${paddingRect.bottom}px`

  //計算樣式
  const computedHeaderStyle = window.getComputedStyle(pcContainerHeader);

  //padding - 距離
  const marginHeaderRect: {
    bottom: number
  } = {
    bottom: parseInt(computedHeaderStyle?.getPropertyValue('margin-bottom').replace('px', '') ?? 0),
  }

  //容器 - 寬
  const containerWidth: number = (document.querySelector('footer.footer')?.clientWidth ?? 0)

  //設定 - 捲軸 - 寬
  scrollbarWidth.value = `${containerWidth}px`

  //容器 - 高
  const containerHeight: number = (pcContainer.querySelector('.content-wrapper')?.clientHeight ?? 0)
      - pcContainerHeader.clientHeight
      - paddingRect.bottom
      - marginHeaderRect.bottom

  //設定容器 - 寬高
  setContainerSize(
      containerWidth,
      containerHeight
  )

}, 0)

/**
 * 路由 - 變化 - 回調
 * 權限確認完成後, 才執行頁面內的初始化
 * 否則頁面內 onMounted 就執行初始化
 * 會變成 如果被登出 顯示被登出, 然後初始化又會再顯示一次登出
 * 會閃現不好看
 * JFY的結構需要跳個線程, 才能正確抓到
 */
const onUp = () => setTimeout(async () => {

  if (!(route.meta?.v2 ?? false)) return

  // 需要加一支key
  // 路由變化開始就先把頁面off掉
  // 原因請看 onSession 的完成回調部分
  active.prepared = false

  // 等待畫面渲染完成
  await nextTick()

  //未登入, 直接返回登入頁面
  if (!isLogin.value) {

    await onLaunch(IntentEnum.LOGIN)

  }
  //權限檢查
  else {

    //執行檢查
    await onSession()

  }

}, 0)

/**
 * 傳輸
 */
const {
  onCaption,
  onSession
} = useRequest(
    {
    },
    {
    },
    {
      caption: getAdminChurchCaption,
      session: getAuthorizationSession
    },
    {
      //呼叫控件對外暴露事件
      session: async () => {

        // 需要加一支key
        // 讓他拿完權限之後才on起來
        // 否則頁面會先畫好, onMounted會先執行
        // 導致在 onMounted 中的
        // 1. 檢查權限
        // 2. isHead
        // 需要透過 session 下來的都會抓到錯誤資料
        // 尤其是切換模擬教會時
        active.prepared = true

        // 等待畫面渲染完成
        await nextTick()

        // 檢查頁面是否有對外暴露
        if (!(page?.value?.up)) {
          console.error("❗️❗️❗️ 請設置頁面中對外暴露的啟動方法 (onUp) ❗️❗️❗️")
        }

        // 牧養相關頁面 - 維護模式 (總教會除外)
        if (isShepherd.value(route)) {

          showMaintenance(
              EnumMaintenance.SHEPHERD,
              router
          )

        }
        else {

          // 執行頁面中的對外暴露
          page?.value?.up()

          //取得 - 教會 - 三項 - 標題
          await onCaption()

        }

      }
    }
)

/**
 * 生命週期 - 掛起
 */
onMounted(() => {

  onResize()

  window.addEventListener('resize', onResize)

})

/**
 * 生命週期 - 掛起
 */
onUnmounted(() => window.removeEventListener('resize', onResize))

/**
 * 監聽 - 路由 - 變化
 */
watch(currentRoute, () => onUp())

/**
 * 監聽 - 路由 - 變化
 */
watch(currentRoute, () => onResize())

/**
 * 生命週期 - 掛起
 */
onMounted(() => onUp())

/**
 * 監聽 - 觸發 - 模式 - 變更
 */
watch(()=>isMinimal.value, () => onResize())

/**
 * 監聽 - 觸發 - 讀取 - 變更
 */
watch(()=>isCollapsed.value, () => onResize())

</script>

<style scoped lang="sass">

.content-wrapper
  &.v2
    height: calc(100vh - v-bind(headerHeight) - v-bind(footerHeight))
    //.content-header
    //  &.row
    //    margin: 0 auto
    //> .el-scrollbar
    //  width: v-bind(scrollbarWidth)

.overlay-enter-active,
.overlay-leave-active
  transition: opacity .35s

.overlay-enter,
.overlay-leave-to
  opacity: 0

.vel-toolbar
  i
    width: 100%
    height: 100%
</style>
