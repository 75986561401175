import {Attachment} from "@/language/modules/component/support/v2/worker/history/attachment/zhCN"

export const History: {
    [key: string]: any
} = {

    attachment: Attachment,

    field: {
        subject: {
            subject: "标题",
            holder: "请输入标题"
        },
        description: {
            subject: "描述",
            holder: "请输入描述"
        },
        attachment: {
            subject: "附件"
        },
        creator: {
            subject: "建立人员",
            holder: "",
            stamp: {
                subject: "建立时间",
                holder: ""
            }
        },
        editor: {
            subject: "上一次编辑人",
            holder: "",
            stamp: {
                subject: "上一次编辑时间",
                holder: ""
            }
        }
    },
    help: {
        freeze: {
            subject: "无法编辑",
            text: `非目前状态或者您不是建立人员<br/>不能进行编辑`
        },
        edit: {
            subject: "编辑",
        },
        release: {
            subject: "需要发布App",
        },
        invalid: {
            subject: "作废",
            text: "纪录已作废"
        }
    }
}
