export default {
    cascader: {
        subject: "請選擇族群",
        clear: {
            subject: "清除"
        },
        error: {
            1001: {
                "subject": "請至少選擇1個族群",
                "text": "",
                "description": ""
            }
        }
    }
}
