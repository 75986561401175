export default {
    period: {
        subject: "图表周期",
        value: [
            "天",
            "周",
            "月",
            "季",
            "半年",
            "年",
            "三年"
        ],
        short: [
            "1D",
            "1W",
            "1M",
            "1Q",
            "2Q",
            "1Y",
            "3Y"
        ]
    }
}
