export const Editor: {
    [key: string]: any
} = {

    subject: "请选择会友进行推播通知",

    submit: {
        maximum: {
            subject: "最多只能选择%s位会友进行推播通知"
        },
        minimum: {
            subject: "请选择至少%s位会友进行推播通知"
        }
    }

}