export const Progress: {
    [key: string]: any
} = {
    cost : {
        subject: "耗用時間"
    },
    remaining: {
        subject: "剩餘時間",
        expire : {
            subject: "已經超時",
        }
    }
}
