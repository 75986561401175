import {RouteRecordRaw} from "vue-router";

const activity: RouteRecordRaw[] = [{
	//活動管理 - 管理
	path: "activity",
	name: "AdminActivity",
	meta: {
		permission: 18001,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: null
		}, {
			key: 'admin.activity.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/index.vue"),
}, {
	//活動管理 - 新增
	path: "activity/create",
	name: "AdminActivityCreate",
	meta: {
		permission: 18002,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: "AdminActivity"
		}, {
			key: 'admin.activity.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/create.vue"),
}, {
	//活動管理 - 編輯
	path: "activity/edit/:uuid",
	name: "AdminActivityEdit",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: "AdminActivity"
		}, {
			key: 'admin.activity.edit.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/edit.vue"),
}, {
	//活動管理 - 編輯 - 後台報名
	path: "activity/edit/:uuid/register",
	name: "AdminActivityEditRegister",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: "AdminActivity"
		}, {
			key: 'admin.activity.edit.index',
			value: "AdminActivityEdit"
		}, {
			key: 'admin.activity.edit.register.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/register/create.vue"),
}, {
	//活動管理 - 編輯 - 報名清單
	path: "activity/edit/:uuid/registered",
	name: "AdminActivityEditRegistered",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: "AdminActivity"
		}, {
			key: 'admin.activity.edit.index',
			value: "AdminActivityEdit"
		}, {
			key: 'admin.activity.edit.registered.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/registered/index.vue"),
}, {
	//活動管理 - 編輯 - 報名清單 - 內容
	path: "activity/edit/:uuid/registered/edit/:uid?",
	name: "AdminActivityEditRegisteredEdit",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: "AdminActivity"
		}, {
			key: 'admin.activity.edit.index',
			value: "AdminActivityEdit"
		}, {
			key: 'admin.activity.edit.registered.index',
			value: "AdminActivityEditRegistered"
		}, {
			key: 'admin.activity.edit.registered.edit.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/registered/edit.vue"),
}, {
	//活動管理 - 分類管理
	path: "activity/category",
	name: "AdminActivityCategory",
	meta: {
		permission: 18011,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: null
		}, {
			key: 'admin.activity.category.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/category/index.vue"),
}, {
	//活動管理 - 分類新增
	path: "activity/category/create",
	name: "AdminActivityCategoryCreate",
	meta: {
		permission: 18012,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: null
		}, {
			key: 'admin.activity.category.index',
			value: "AdminActivityCategory"
		}, {
			key: 'admin.activity.category.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/category/create.vue"),
}, {
	//活動管理 - 分類編輯
	path: "activity/category/edit/:uuid",
	name: "AdminActivityCategoryEdit",
	meta: {
		permission: 18014,
		breadcrumb: [{
			key: 'admin.activity.subject',
			value: null
		}, {
			key: 'admin.activity.category.index',
			value: "AdminActivityCategory"
		}, {
			key: 'admin.activity.category.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/activity/category/edit.vue"),
}];

export default activity;
