export default {
    400: {
        subject: "連線權杖逾時",
        text: "Oops! 🤕🤕 請您重新登入系統",
        description: "請稍後再試或請聯絡管理人員"
    },
    401: {
        subject: "未授權的存取",
        text: "Oops! 😳😳 您可能沒有存取的權限",
        description: "請稍後再試或請聯絡管理人員"
    },
    404: {
        subject: "頁面不存在",
        text: "Oops! 😱😱 您訪問的頁面不存在或暫時不可用",
        description: "請稍後再試或請聯絡管理人員"
    },
    //METHOD不存在
    405: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 無法正確連結至伺服器",
        description: "請稍後再試或請聯絡管理人員"
    },
    413: {
        subject: "這出了點小問題",
        text: "Oops! 🙁🙁 您上傳的檔案容量超出限制",
        description: "請稍後再試或請聯絡管理人員"
    },
    500: {
        subject: "這出了點小問題",
        text: "Oops! 🥺🥺 伺服器好像罷工了",
        description: "請稍後再試或請聯絡管理人員"
    },
    //CORS錯誤
    502: {
        subject: "這出了點小問題",
        text: "Oops! 😵😵 無法正確連結至伺服器",
        description: "請稍後再試或請聯絡管理人員"
    },
    //連線超時
    504: {
        subject: "這出了點小問題",
        text: "Oops! 😵‍💫😵‍💫 連結到伺服器超出預期時間",
        description: "請稍後再試或請聯絡管理人員"
    },
    901: {
        subject: "上傳檔案發生錯誤",
        text: `單個檔案超過 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    902: {
        subject: "上傳檔案發生錯誤",
        text: `檔案合計超過 <b class="ft-20 text-danger">%s</b> %s限制`,
        description: ""
    },
    903: {
        subject: "產生預覽圖失敗",
        text: "上傳檔案中包含不支援的圖片編碼",
        description: ""
    },
    904: {
        subject: "分享連結出現錯誤",
        text: "請確認您的分享連結是否正確",
        description: ""
    },
    998: {
        subject: "二級驗證失敗",
        text: "請確認您的登入密碼是否正確",
        description: "請確認您的登入密碼是否正確"
    },
    999: {
        subject: "這出了點小問題",
        text: "Oops! 😲😲 發生未知錯誤",
        description: "請稍後再試或請聯絡管理人員"
    }
}
