export default {
    status: {
        enable: "啟用",
        disable: "停用",
    },
    simulation: "模擬登入",
    repair: "重建模擬",
    download: "下載",
    recipient: "編輯名單",
    approved: "核准",
    reject: "拒絕",
    preview: "預覽",
    copy: "複製",
    insert: "插入",
    back: "返回",
    join: "加入",
    remove: "移除",
    select: "選擇",
    attachment: "附件",
    home: "返回首頁",
    toEntry: "回列表",
    reLogin: "重新登入",
    login: "登入",
    forgot: {
        found: "找回密碼",
        verification: "確認送出",
        reset: "重設密碼"
    },
    waiting: "登入中",
    success: "登入成功...請稍候",
    ok: "好",
    top: "置頂",
    toTop: "回到頂部",
    save: "儲存",
    change: "變更",
    search: "搜尋",
    query: "查詢",
    close: "關閉",
    accept: "確定",
    refresh: "刷新",
    reload: "重新讀取",
    upload: "上傳",
    clear: "清除",
    reset: "重設",
    cancel: "取消",
    send: "送出",
    add: "加入",
    edit: "編輯",
    delete: "刪除",
    done: "完成",
    fetch: "立即更新",
    relocation: "重新整理",
    replace: "取代",
    schedule: "排程預覽",
    view: "查看",
    segue: "前往"
}
