import {Tag} from "@/language/modules/page/admin/notification/v2/edit/membership/tag/zhCN";
import {Editor} from "@/language/modules/page/admin/notification/v2/edit/membership/editor/zhCN";

export const Membership: {
    [key: string]: any
} = {

    tag: Tag,

    editor: Editor,

    field: {

        keyword: {
            subject: "关键字",
            holder: "请输入关键字",
            text: "同时搜索名字/邮件/会友编号"
        },

        tag: {
            subject: "标签",
            holder: "请选择标签"
        }

    },
    empty: {
        subject: "请选择会友进行推播通知"
    },
    not: {
        found: {
            subject: "搜索不到指定的推播通知会友"
        }
    }
}