export default {
    editor: {
        help: {
            notification: "推播至活動建立者",
            add: {
                email: "新增電子郵件"
            },
            maximum: {
                email: `寄送郵件名單最多僅能設定 <b class="cgBase">%s</b> 筆`
            }
        },
        subject: "遞交報名後提醒的傳達方式",
        field: {
            notification: "推播通知",
            email: "電子郵件"
        },
        holder: {
            email: "請輸入電子郵件"
        },
        error: {
            1001: {
                "subject": "請指定通知方式",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請至少選擇一名收件人",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請輸入郵件地址",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "郵件格式錯誤",
                "text": "",
                "description": ""
            }
        }
    }
}
