import router from "@/router"
import i18n from '@/language'

import {useMitt} from "@/hook/useMitt";
import {useSwalAlert} from "@/hook/useSwalAlert";
import {usePermission} from "@/hook/usePermission";
import {destroySession} from "@/api/module/session";

/**
 * 跳轉功能
 */
export const useIntent = () => {

    //導入i18n
    const {
        //@ts-ignore
        t
    } = i18n.global

    const {
        updateToken
    } = usePermission()

    const {
        showHandler
    } = useSwalAlert()

    /**
     * 前往導航頁
     */
    const homeIntent = async () => {

        await router.push({
            name: router.currentRoute.value.meta.portal === 'admin' ? 'AdminDashboard' : 'ShepherdDashboard',
        })

    }

    /**
     * AJAX 404 前往導航頁
     */
    const homePage404Intent = async () => {

        await router.replace({
            name: router.currentRoute.value.meta.portal === 'admin' ? 'AdminDashboard' : 'ShepherdDashboard',
        })

    }

    /**
     * 重刷本頁
     */
    const reloadIntent = async () => {

        const {
            setReloadPage
        } = useMitt()

        setReloadPage(true)

        window.location.reload()

    }

    /**
     * 前往
     */
    const pushIntent = async (route: string| undefined) => {

        if (route === undefined) return false

        await router.push({
            name: route,
        })

    }

    /**
     * 置換前往
     */
    const replaceIntent = async (route: string| undefined) => {

        if (route === undefined) return false

        await router.push({
            name: route,
        })

    }

    /**
     * 登出
     */
    const logoutIntent = () => showHandler({
        subject: t(`handler.session.destroy.subject`),
        text: t(`handler.session.destroy.text`),
        completion: {
            success: async () => {

                await destroySession()

                //銷毀token
                updateToken(null);

            }
        }
    })

    return {
        homeIntent,
        homePage404Intent,
        pushIntent,
        reloadIntent,
        replaceIntent,
        logoutIntent
    }

}
