import {v2} from "@/language/modules/page/shepherd/attend/v2/zhTW"
import category from "@/language/modules/page/shepherd/attend/category/zhTW";
import history from "@/language/modules/page/shepherd/attend/history/zhTW";
import schedule from "@/language/modules/page/shepherd/attend/schedule/zhTW";

/**
 * 聚會
 */
export default {

    /**
     * v2
     */
    v2: v2,

    /**
     * 分類
     */
    category: category,

    /**
     * 列表
     */
    index: {
        feature: {
            create: {
                subject: "新增聚會"
            }
        },
        field: {
            head: {
                church: "指定教會"
            },
            name: "名稱",
            category: "分類",
            cycle: "循環",
            team: "族群數量"
        },
        holder: {
            head: {
                church: "請選擇指定教會"
            },
            name: "請輸入名稱",
            category: "請選擇分類",
            cycle: "請選擇循環方式",
        },
        handler: {
            enable: {
                subject: "即將啟用聚會",
                text: "請確認後執行",
                description: ""
            },
            disable: {
                subject: "即將停用聚會",
                text: "請確認後執行",
                description: ""
            }
        },
        factory: {
            destroy: {
                subject: "安全性二級驗證",
                text: `<span class="cgRed">即將刪除聚會, 請確認後執行</span>`,
                description: "請輸入您目前的登入密碼"
            }
        }
    },

    /**
     * 新增
     */
    create: {
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "選項"
            },
            2: {
                subject: "名單"
            },
            3: {
                subject: "提醒"
            },
            4: {
                subject: "循環"
            }
        },
        help: {
            church: "請謹慎使用指定教會功能",
            register: {
                off: "需要透過簽到工具或小組長出示/掃描會友二維碼進行簽到",
                on: "會友可自行於牧養對話中簽到"
            },
        },
        field: {
            church: "指定教會",
            name: "名稱",
            category: "分類",
            begin: {
                subject: "開始時間",
                text: "首次"
            },
            end: {
                subject: "結束時間",
                text: "首次"
            },
            location: "地點",
            note: "備註",
            register: "允許會友自行簽到",
            should: "簽到工具顯示應到會友",
            status: "狀態"
        },
        holder: {
            church: "請選擇指定教會",
            name: "請輸入名稱",
            category: {
                subject: "請選擇分類",
                empty: "請先選擇指定教會"
            },
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            location: "請輸入地點",
            note: "請輸入備註",
            register: "請選擇是否允許會友自行簽到",
            should: "請選擇簽到工具是否顯示應到會友",
            status: "請選擇狀態"
        },
        handler: {
            store: {
                subject: "即將新增聚會",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇是否允許會友自行簽到",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請選擇簽到工具是否顯示應到會友",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 編輯
     */
    edit: {
        tip: {
            readonly: "總教會僅供查看資料, 無法新增修改",
            disabled: "請注意, 這個聚會已經停用"
        },
        feature: {
            0: "下載報到介面二維碼",
            2: "前往報到介面",
            3: "顯示報到介面二維碼",
            4: "複製報到介面網址"
        },
        tabs: {
            0: {
                subject: "基本"
            },
            1: {
                subject: "選項"
            },
            2: {
                subject: "名單"
            },
            3: {
                subject: "提醒"
            },
            4: {
                subject: "循環"
            },
            5: {
                subject: "統計資訊"
            },
            98: {
                subject: "循環資訊"
            },
            99: {
                subject: "相關資訊"
            }
        },
        summary: {
            schedule: {
                subject: "聚會排程"
            },
            history: {
                subject: "聚會紀錄"
            }
        },
        detail: {
            cycle: {
                subject: "循環代號"
            },
            remind: {
                subject: "提醒代號"
            }
        },
        help: {
            register: {
                off: "需要透過簽到工具或小組長出示/掃描會友二維碼進行簽到",
                on: "會友可自行於牧養對話中簽到"
            },
        },
        field: {
            belong: {
                name: "所屬教會名稱",
                no: "所屬教會ID"
            },
            name: "名稱",
            category: "分類",
            begin: {
                subject: "開始時間",
                text: "首次"
            },
            end: {
                subject: "結束時間",
                text: "首次"
            },
            location: "地點",
            note: "備註",
            register: "允許會友自行簽到",
            should: "簽到工具顯示應到會友",
            status: "狀態"
        },
        holder: {
            belong: {
                name: "",
                no: ""
            },
            name: "請輸入名稱",
            category: "請選擇分類",
            begin: "請選擇開始時間",
            end: "請選擇結束時間",
            location: "請輸入地點",
            note: "請輸入備註",
            register: "請選擇是否允許會友自行簽到",
            should: "請選擇簽到工具是否顯示應到會友",
            status: "請選擇狀態"
        },
        handler: {
            update: {
                subject: "即將更新聚會內容",
                text: "請確認後執行",
                description: ""
            }
        },
        error: {
            1001: {
                "subject": "請選擇分類",
                "text": "",
                "description": ""
            },
            1002: {
                "subject": "請輸入名稱",
                "text": "",
                "description": ""
            },
            1003: {
                "subject": "請選擇開始時間",
                "text": "",
                "description": ""
            },
            1004: {
                "subject": "請選擇結束時間",
                "text": "",
                "description": ""
            },
            1005: {
                "subject": "結束時間必須大於開始時間",
                "text": "",
                "description": ""
            },
            1006: {
                "subject": "請選擇狀態",
                "text": "",
                "description": ""
            },
            1007: {
                "subject": "請選擇是否允許會友自行簽到",
                "text": "",
                "description": ""
            },
            1008: {
                "subject": "請選擇簽到工具是否顯示應到會友",
                "text": "",
                "description": ""
            }
        }
    },

    /**
     * 歷程
     */
    history: history,

    /**
     * 排程
     */
    schedule: schedule

}
