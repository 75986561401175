import {NavigationItemRaw} from '@/navigation/interface'
import {RouteNameEnum} from "@/v2/enumerate/route";

const notification: NavigationItemRaw[] = [{
    id: 9,
    right: true,
    subject: 'admin.notification.index',
    route: RouteNameEnum.ADMIN_NOTIFICATION,
    icon: 'sound',
    children: [],
    attached: [
        RouteNameEnum.ADMIN_NOTIFICATION_CREATE,
        RouteNameEnum.ADMIN_NOTIFICATION_EDIT,
        RouteNameEnum.ADMIN_NOTIFICATION_EDIT_HISTORY,
        RouteNameEnum.ADMIN_NOTIFICATION_EDIT_AUTOMATIC,
        RouteNameEnum.ADMIN_NOTIFICATION_EDIT_AUTOMATIC_EDIT
    ]
}]

export default notification
