import {v2} from "@/language/modules/component/form/v2/zhTW"

export default {

    v2: v2,

    help: {
        must: `標記為 <b class="cgRed">*</b> 為必填欄位`
    },
    handler: {
        reset: {
            subject: "即將清除表單",
            text: `<span class="text-danger">已經填寫的資料將會清除, 請確認後執行</span>`,
            description: ""
        }
    },
    select: {

        head: {

            church: {
                label: "指定教會",
                holder: "請選擇指定教會",
                help: "請謹慎使用指定教會功能",
                broadcast: {
                    subject: "全部教會"
                }
            }

        }

    }

}
