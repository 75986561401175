<template>
  <div :class="active.loaded ? null : `hidden`">
    <div v-if="active.data.organization" class="web-page"
         :style="{
            backgroundImage: `url(${ getBackground })`
         }">
      <div class="row">
        <div class="col-lg-5 w-logo">
          <img alt=""
               :src="getSplash" />
        </div>
        <div class="col-lg-7 w-desc">
          <div class="app">
            <img alt=""
                 :src="getLogo" />
            <span class="text-black"
                  v-html="sprintf(`%sApp`, active.data.organization.name)"/>
          </div>
          <h1 class="subject m-b-15"
              v-html="$t(`${configure.prefix}.subject`)" />
          <h4 class="text"
              v-html="$t(`${configure.prefix}.text`)" />
          <h4 class="text"
              v-html="$t(`${configure.prefix}.description`)" />
          <div class="w-100 p">
            <img alt=""
                 class="qr"
                 :src="getQrcode" />
          </div>
          <div class="row h">
            <div class="col-lg-6 col-sm-12">
              <a v-html="$t(`${configure.prefix}.button.apple`)"
                 target="_blank"
                 :href="getStore(EnumSupplier.APPLE)"
                 class="btn btn-block bg-gradient-primary btn-lg" />
            </div>
            <div class="col-lg-6 col-sm-12">
              <a v-html="$t(`${configure.prefix}.button.google`)"
                 target="_blank"
                 :href="getStore(EnumSupplier.GOOGLE)"
                 class="btn btn-block bg-gradient-primary btn-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <error404 :home="false"
              v-else />
  </div>
</template>

<script setup lang="ts">

import Error404 from "@/component/Error.vue"

import {Session} from "@/api/interface/session"
import {sprintf} from "sprintf-js"
import {computed, onMounted, reactive} from "vue"
import {Configure} from "@/api/interface/configure"
import {createApp} from "@/api/module/session"
import {useRoute} from "vue-router"
import {EnumSupplier} from "@/enum/supplier"

const route = useRoute()

/**
 * 參數 - 本地
 */
const active = reactive<{
  data: Session.App
  loaded: boolean
}>({
  data: {
    organization: {
      seq: null,
      uuid: null,
      name: null
    },
    qrcode: null,
    store: {
      apple: null,
      google: null
    },
    name: null
  },
  loaded: false,
})

/**
 * 參數 - 頁面 - 配置
 */
const configure = reactive<Configure.Page.Base>({
  //i18n代碼前置
  prefix: `page.app`,
})

//獲取背景圖
const getBackground = computed((): string => {
  return "/img/bg/app/1117.jpg"
})

//獲取Logo圖
const getLogo = computed((): string => {
  if (active.data.organization.uuid) return `/img/organization/${active.data.organization.seq}/logo.png`
  return "/img/organization/1/logo.png"
})

//獲取Qrcode圖
const getQrcode = computed((): string => {
  return `data:image/pngbase64,${active.data.qrcode as string}`
})

//獲取App圖
const getSplash = computed((): string => {
  if (active.data.organization.uuid) return `/img/organization/${active.data.organization.seq}/splash.png`
  return "/img/organization/1/splash.png"
})

//獲取商店網址
const getStore = computed((): (e: EnumSupplier ) => string => {
  return (e) => {
    if (e === EnumSupplier.GOOGLE) return `https://play.google.com/store/apps/details?id=${active.data.store.google}&hl=zh_TW`
    return `https://apps.apple.com/tw/app/${active.data.store.apple}`
  }
})

const fetch = async () => {

  const {
    data
  } = await createApp(
      route.params.id as string
  )

  //返回錯誤不往下進行
  if (data?.data === undefined) return false

  //標示可以打開頁面了
  active.loaded = true

  active.data.name = data.data.name
  active.data.qrcode = data.data.qrcode
  active.data.store = data.data.store
  active.data.organization = data.data.organization

}

const initial = () => {
  fetch()
}

onMounted(()=>initial())

</script>

<style scoped lang="sass">
.web-page
  height: 100vh
  background-repeat: no-repeat
  background-attachment: fixed
  background-position: center
  background-size: cover
  > .row
    height: 100%
    .w-logo
      display: flex
      justify-content: end
      align-items: center
      padding-right: 120px
      height: 100%
      > img
        max-width: 380px
        width: 90%
    .w-desc
      display: flex
      justify-content: center
      align-items: start
      flex-direction: column
      > .app
        display: flex
        justify-content: start
        justify-items: center
        width: 100%
        margin-bottom: 30px
        font-size: 24px
        img
          margin-right: 5px
          width: 60px
          height: 60px
          border-radius: .5rem
        span
          line-height: 2.5
          margin-left: 5px
          font-weight: 550
      .subject, .text
        color: #186493
      h1
        font-size: 2.5rem
      h4
        font-size: 1.5rem
      img
        &.qr
          border: 1px solid #f1f1f1
          border-radius: .5rem
          width: 180px
          margin: 50px 0
      .h
        max-width: 650px
        width: 80%
        .btn-lg
          width: 100%
          padding: 1.5rem .75rem !important

@media (max-width: 992px)
  .web-page
    height: 100%
    > .row
      height: auto
      .w-logo
        justify-content: center
        padding-right: 0
        > img
          margin: 50px 0
          width: 250px
      .w-desc
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        .app
          width: auto
        .p
          text-align: center
        .h
          width: 100%
          padding-left: 2rem
          padding-right: 2rem
          padding-bottom: 3rem
          .btn-lg
            width: 100%
            padding: 1.8rem .75rem !important
            margin-top: 2rem
</style>
