import {Date} from "@/v2/i18n/modules/zhCN/date";
import {Status} from "@/v2/i18n/modules/zhCN/status";
import {Submit} from "@/v2/i18n/modules/zhCN/submit";
import {Option} from "@/v2/i18n/modules/zhCN/option";
import {Handler} from "@/v2/i18n/modules/zhCN/handler";

export const zhCN: {
    [key: string]: any
} = {

    date: Date,

    status: Status,

    submit: Submit,

    option: Option,

    handler: Handler

}