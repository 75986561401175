import {RouteRecordRaw} from "vue-router";

const live: RouteRecordRaw[] = [{
	//直播管理 - 管理
	path: "live",
	name: "AdminLive",
	meta: {
		permission: 18001,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: null
		}, {
			key: 'admin.live.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/index.vue"),
}, {
	//直播管理 - 新增
	path: "live/create",
	name: "AdminLiveCreate",
	meta: {
		permission: 18002,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: "AdminLive"
		}, {
			key: 'admin.live.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/create.vue"),
}, {
	//直播管理 - 編輯
	path: "live/edit/:uuid",
	name: "AdminLiveEdit",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: "AdminLive"
		}, {
			key: 'admin.live.edit.subject',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/edit.vue"),
}, {
	//直播管理 - 編輯 - 聊天室發言
	path: "live/edit/:uuid/comment",
	name: "AdminLiveEditComment",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: "AdminLive"
		}, {
			key: 'admin.live.edit.subject',
			value: "AdminLiveEdit"
		}, {
			key: 'admin.live.edit.comment.subject',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/comment/index.vue"),
}, {
	//直播管理 - 編輯 - 私密代禱
	path: "live/edit/:uuid/secure",
	name: "AdminLiveEditSecure",
	meta: {
		permission: 18004,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: "AdminLive"
		}, {
			key: 'admin.live.edit.subject',
			value: "AdminLiveEdit"
		}, {
			key: 'admin.live.edit.secure.subject',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/secure/index.vue"),
}, {
	//直播管理 - 分類管理
	path: "live/category",
	name: "AdminLiveCategory",
	meta: {
		permission: 18011,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: null
		}, {
			key: 'admin.live.category.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/category/index.vue"),
}, {
	//直播管理 - 分類新增
	path: "live/category/create",
	name: "AdminLiveCategoryCreate",
	meta: {
		permission: 18012,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: null
		}, {
			key: 'admin.live.category.index',
			value: "AdminLiveCategory"
		}, {
			key: 'admin.live.category.create',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/category/create.vue"),
}, {
	//直播管理 - 分類編輯
	path: "live/category/edit/:uuid",
	name: "AdminLiveCategoryEdit",
	meta: {
		permission: 18014,
		breadcrumb: [{
			key: 'admin.live.subject',
			value: null
		}, {
			key: 'admin.live.category.index',
			value: "AdminLiveCategory"
		}, {
			key: 'admin.live.category.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/live/category/edit.vue"),
}];

export default live;
