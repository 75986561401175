import http from "@/api";
import {Respond} from "@/api/interface/respond";
import {AxiosDefaultOption} from "@/api/interface/configure";
import {BillboardStatistics} from "@/api/interface/billboard/statistics";

/**
 * 獲取 - 未讀 - 統計
 */
export const getBillboardStatistics = () => {
    return http.get<Respond.Data<BillboardStatistics>>(`/api/billboard/statistics`, {}, {
        opts: AxiosDefaultOption
    });
};
