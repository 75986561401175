export default {
    subject: "编辑个人标记",
    incorrect: "请先选择需要移除的标记",
    selected: `已经选取 <b class="cgBase">%s</b> 个标记`,
    th: [
        null,
        "标记"
    ],
    handler: {
        destroy: {
            subject: "即将删除指定个人标记",
            text: "删除后无法复原, 请确认后执行",
            description: ""
        }
    }
}
