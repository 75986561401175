import {RouteRecordRaw} from "vue-router";

const course: RouteRecordRaw[] = [{
	//歷程 - 攔截
	path: "course/exception",
	name: "AdminCourseException",
	meta: {
		permission: 16001,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.exception.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/exception/index.vue"),
}, {
	//歷程 - 攔截 - 編輯
	path: "course/exception/edit/:uuid",
	name: "AdminCourseExceptionEdit",
	meta: {
		permission: 16004,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.exception.index',
			value: "AdminCourseException"
		}, {
			key: 'admin.course.exception.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/exception/edit.vue"),
}, {
	//歷程 - 存取
	path: "course/access",
	name: "AdminCourseAccess",
	meta: {
		permission: 16011,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.access.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/access/index.vue"),
}, {
	//歷程 - 存取 - 編輯
	path: "course/access/edit/:uuid",
	name: "AdminCourseAccessEdit",
	meta: {
		permission: 16014,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.access.index',
			value: "AdminCourseAccess"
		}, {
			key: 'admin.course.access.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/access/edit.vue"),
}, {
	//歷程 - 登入
	path: "course/authorize",
	name: "AdminCourseAuthorize",
	meta: {
		permission: 16011,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.authorize.index',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/authorize/index.vue"),
}, {
	//歷程 - 登入 - 編輯
	path: "course/authorize/edit/:uuid",
	name: "AdminCourseAuthorizeEdit",
	meta: {
		permission: 16014,
		breadcrumb: [{
			key: 'admin.course.subject',
			value: null
		}, {
			key: 'admin.course.authorize.index',
			value: "AdminCourseAuthorize"
		}, {
			key: 'admin.course.authorize.edit',
			value: null
		}]
	},
	component: () => import("@/page/content/admin/course/authorize/edit.vue"),
}];

export default course;
