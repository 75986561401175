/**
 * 登入画面
 */
export default {
    subject: "APP管理系统",
    feature: {
        authorized: {
            qrcode: "扫码登入",
            passwd: "帐密登入"
        },
        forgot: "忘记密码"
    },
    field: {
        account: "登入帐户",
        passwd: "登入密码",
        captcha: "验证码"
    },
    holder: {
        account: "请输入登入帐户",
        passwd: "请输入登入密码",
        captcha: "请输入图形中的文字"
    },
    error: {
        1000: {
            subject: "暂时无法使用",
            text: "请稍后再试或请联络管理人员"
        },
        1001: {
            subject: "请输入登入帐户",
            text: ""
        },
        1002: {
            subject: "请输入登入密码",
            text: ""
        },
        1003: {
            subject: "请输入图形中的文字",
            text: ""
        },
        1004: {
            subject: "验证码应为6个字元",
            text: ""
        },
        1005: {
            subject: "验证码错误",
            text: ""
        }
    }
}
