import {Search} from "@/language/modules/component/form/v2/table/search/zhTW"

export const Table: {
    [key: string]: any
} = {

    search: Search,

    action: {
        add: {
            subject: "新增",
            not: {
                allowed: {
                    subject: "無新增權限"
                }
            }
        },
        move: {
            top: {
                subject: "移動至頂部"
            },
            left: {
                subject: "移動至左方"
            },
            right: {
                subject: "移動至右方"
            }
        },
        search: {
            subject: "搜尋"
        },
        statistics: {
            subject: "統計"
        },
        checkbox: {
            subject: "多選"
        },
        reset: {
            subject: "重設"
        },
        refresh: {
            subject: "刷新"
        },
        loading: {
            subject: "讀取中"
        },
        download: {
            subject: "匯出",
            not: {
                allowed: {
                    subject: "無匯出權限"
                }
            }
        },
        pagination: {
            subject: `每頁 <b>%s</b> 筆資料`,
        }
    },
    footer: {
        subject: `目前於 第 <b class="text-justforyou-base ft-16">%s</b> 頁 共 <b class="text-justforyou-base ft-16">%s</b> 頁 全部 <b class="text-justforyou-base ft-16">%s</b> 筆資料`
    },
    readonly: {
        subject: "列表僅供讀取",
        head: {
            subject: "總教會僅供查看資料, 無法新增修改"
        }
    },
    empty: {
        subject: "找不到指定的資料"
    },
    field: {
        belong: {
            subject: "所屬教會"
        },
        head: {
            subject: "主動",
            text: "由總教會發起的主動推播"
        },
        cycle: {
            subject: "循環",
            cycle: {
                1: {
                    subject: "單次"
                },
                2: {
                    subject: "每日"
                },
                3: {
                    subject: "每週"
                },
                4: {
                    subject: "每月"
                },
                5: {
                    subject: "每年"
                }
            }
        },
        remind: {
            subject: "提醒",
            status: {
                subject: "已設定%s筆提醒",
                disable: {
                    subject: "未設定提醒",
                }
            }
        },
        edit: {
            subject: "操作",
            view: {
                subject: "查看"
            }
        }
    },
    off: {
        canvas: {
            statistics: {
                subject: "統計"
            },
            search: {
                subject: "搜尋"
            }
        }
    },
    permission: {
        view: {
            not: {
                allowed: {
                    subject: "無查看權限"
                }
            }
        },
        edit: {
            not: {
                allowed: {
                    subject: "無編輯權限"
                }
            }
        }
    },
    edit: {
        subject: "編輯",
        more: {
            subject: "更多",
            copy: {
                subject: "複製"
            },
            enable: {
                subject: "啟用"
            },
            disable: {
                subject: "停用"
            },
            destroy: {
                subject: "刪除"
            }
        },
        view: {
            subject: "檢視",
        }
    }
}
