<script setup lang="ts">

import Loading from 'vue-loading-overlay';
import {useCross} from "@/v2/hooks/cross";
import {LoadingMaskModeEnum, LoadingMaskSizeEnum} from "@/v2/enumerate/component/loading";

const {
  isLoadingMask
} = useCross()

/**
 * 傳入 - 參數
 */
const props = withDefaults(defineProps<{
  //模式
  mode?: LoadingMaskModeEnum
  //底色
  color?: string
  backgroundColor?: string
  //透明度
  opacity?: number
  // 尺寸 (FULL無效)
  size?: LoadingMaskSizeEnum
  //鎖住卷軸 (FULL無效)
  lockScroll?: boolean
  //全屏幕 (FULL無效)
  fullPage?: boolean
}>(),{
  mode: LoadingMaskModeEnum.FULL,
  color: '#DDA026',
  backgroundColor: '#FFF',
  opacity: 0.3,
  size: LoadingMaskSizeEnum.LARGE,
  lockScroll: false,
  fullPage: false
})

</script>

<template>
  <div class="vl-parent">
    <loading v-if="props.mode === LoadingMaskModeEnum.FULL"
             v-model:active="isLoadingMask"
             :opacity="props.opacity"
             :color="props.color"
             :background-color="props.backgroundColor"
             :lock-scroll="true"
             :is-full-page="true" />
    <loading v-else-if="props.mode === LoadingMaskModeEnum.MANUAL"
             v-model:active="isLoadingMask"
             :width="props.size"
             :height="props.size"
             :opacity="props.opacity"
             :color="props.color"
             :background-color="props.backgroundColor"
             :lock-scroll="props.lockScroll"
             :is-full-page="props.fullPage" />
    <loading v-else
             active
             :width="props.size"
             :height="props.size"
             :color="props.color"
             :background-color="props.backgroundColor"
             :opacity="props.opacity"
             :lock-scroll="props.lockScroll"
             :is-full-page="props.fullPage" />
  </div>
</template>