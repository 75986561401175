export const Attachment: {
    [key: string]: any
} = {
    editor: {
        subject: "附件内容",
        field: {
            mime: {
                subject: "类型",
            },
            subject: {
                subject: "标题",
                holder: ""
            },
            description: {
                subject: "描述",
                holder: ""
            },
            url: {
                subject: "网址",
                holder: ""
            }
        }
    }
}
