<template>
  <ul class="nav navbar-nav bookmark-icons">
    <li class="nav-item dropdown dropdown-project">
      <el-dropdown
          ref="body"
          popper-class="portal"
          placement="bottom-start">
        <a class="d-flex align-items-center nav-link">
          <icon-sax class="ft-20 text-dark"
                    type="transfer" />
          <span v-if="getToggle === EnumPortalName.ADMIN"
                v-html="$t('portal.shepherd')"
                class="m-l-10 selected-project cgBase" />
          <span v-else
                v-html="$t('portal.admin')"
                class="m-l-10 selected-project cgBase" />
        </a>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link
                  :to="{
                    name: getToggle === EnumPortalName.ADMIN ? EnumPortalRouter.ADMIN : EnumPortalRouter.SHEPHERD,
                    params: {
                      portal: getToggle,
                      id: route.params.id
                    }
                  }"
                  class="d-flex justify-content-start align-items-center">
                <div class="jd">
                  <icon-sax :hover="false"
                            class="ft-24 cgCCC"
                            type="circle" />
                </div>
                <span v-if="getToggle === EnumPortalName.ADMIN"
                      v-html="$t('portal.admin')"
                      class="m-l-5 m-r-8" />
                <span v-else
                      v-html="$t('portal.shepherd')"
                      class="m-l-5 m-r-8" />
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </li>
  </ul>
</template>

<script setup lang="ts">

import IconSax from "@/component/IconSax.vue"

import {computed, ref, watch} from "vue"
import {useRoute} from "vue-router"
import {EnumPortalName, EnumPortalRouter} from "@/enum/portal";

const body = ref()

const route = useRoute()

//返回切換系統的名稱
const getToggle = computed((): EnumPortalName => {
  return route.meta.portal === EnumPortalName.ADMIN ? EnumPortalName.SHEPHERD : EnumPortalName.ADMIN
})

watch(()=>route.name, ()=> body.value.handleClose(), {deep: true})

</script>

<style scoped>

</style>
